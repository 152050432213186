import { useSelector } from 'react-redux';
import { IMusselLineResource } from '../../entities/farms.entities';
import {
  defaultDateFormat,
  diffDays,
} from '../../util/toggleSecondMillisecond';
import { Subtitle, Title } from '../shared';
import { customFieldsValues } from '../../entities/util-functions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectAssessFields,
  SelectSeedingFields,
} from '../../store/extra/extra.selector';
import { labelRange } from '../../lib/common.helpers';
import { IInventorySum } from '../../entities/inventory.entities';
import './styles.scss';

const SeedInventoriesLabel = (ivt_seeds: IInventorySum[]) =>
  ivt_seeds.map(x => (
    <span key={x.inventory_id}>
      <span>
        {x.inventory_name} - {x.quantity}
      </span>
      <br />
    </span>
  ));

interface Props {
  lineData: IMusselLineResource;
}

const FarmLineInfoModal = ({ lineData }: Props) => {
  const lang = useSelector(selectLang);
  const mainSeed = lineData.growing_cycle?.main_seed;
  const toDateFormat = (v: any) => {
    if (!v) return '-';
    return defaultDateFormat(v);
  };
  const SpatLabel = () => {
    if (!lineData.growing_cycle?.main_seed.spat_storage) {
      return <span></span>;
    }
    const { seed_type } = lineData.growing_cycle.main_seed.spat_storage;
    return <div>{seed_type ?? '-'}</div>;
  };
  const assessment = lineData.growing_cycle?.last_assessment;
  const assessFields = SelectAssessFields(lineData.farm_id);
  const seedFields = SelectSeedingFields(lineData.farm_id);
  const assessValues = customFieldsValues(
    assessFields,
    assessment?.custom_values,
  );
  const seedValues = customFieldsValues(seedFields, mainSeed?.custom_values);

  return (
    <div className='farm-line-info-modal'>
      <div className='d-section'>
        <div className='info-card'>
          <Subtitle
            size={3}
            color='black'
            align='left'
            fontWeight={400}
            className='mb-4 mt-4'
          >
            {translate(lang, 'Length')}
          </Subtitle>
          <Subtitle size={4} color='black' align='left' fontWeight={500}>
            {lineData.length}
          </Subtitle>
        </div>
        <div className='info-card'>
          <Subtitle
            size={3}
            color='black'
            align='left'
            fontWeight={400}
            className='mb-4 mt-4'
          >
            {translate(lang, 'Backbone')}
          </Subtitle>
          <Subtitle size={4} color='black' align='left' fontWeight={500}>
            {lineData.backbone}
          </Subtitle>
        </div>
        {mainSeed ? (
          <>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Date seeded')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {defaultDateFormat(mainSeed?.planned_date_seed)}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Spat Size')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {labelRange(
                  mainSeed?.spat_size,
                  mainSeed?.spat_size_max ?? undefined,
                  'mm',
                )}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(
                  lang,
                  !lineData.growing_cycle
                    ? 'Harvest Date'
                    : 'Planned harvest date',
                )}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {toDateFormat(mainSeed?.planned_date_harvest)}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Submersion')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {mainSeed?.submersion ? `${mainSeed.submersion} m` : '-'}
              </Subtitle>
            </div>
            <div className='info-card' style={{ flex: '0 0 100%' }}>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Seed type')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                <SpatLabel />
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Spacing')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {mainSeed?.spacing ? `${mainSeed.spacing} mm` : '-'}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Density')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {labelRange(
                  mainSeed?.density,
                  mainSeed?.density_max ?? undefined,
                )}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Condition')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {mainSeed.spat_storage?.condition ?? '-'}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Drop')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {mainSeed?.drop ? `${mainSeed.drop} m` : '-'}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Inventories')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {lineData.growing_cycle
                  ? SeedInventoriesLabel(lineData.growing_cycle.inventories_sum)
                  : ''}
              </Subtitle>
            </div>
            {seedValues?.map(x => (
              <div key={x.id} className='info-card'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='mb-4 mt-4'
                >
                  {x.label}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {x.value !== undefined && x.value !== null
                    ? `${x.value} ${x.unit ?? ''}`
                    : '-'}
                </Subtitle>
              </div>
            ))}
          </>
        ) : (
          <div className='info-card' style={{ flex: '0 0 100%' }}>
            <Subtitle
              size={2}
              color='black'
              align='center'
              fontWeight={500}
              className='mb-4 mt-15'
            >
              {translate(
                lang,
                'Line is empty for %s days',
                lineData.line_idle ?? 0,
              )}
            </Subtitle>
          </div>
        )}
      </div>
      {assessment && (
        <>
          <Title
            size={6}
            color='black'
            align='left'
            fontWeight={500}
            className='mt-25'
          >
            {translate(lang, 'Last Assessment')}
          </Title>
          <div className='d-section'>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Date of assessment')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {toDateFormat(assessment.assessment_date)}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Author')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {assessment.user?.name ?? '-'}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Planned harvest date')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {toDateFormat(assessment.planned_date_harvest)}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {`${translate(lang, 'Size min')} (mm)`}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {assessment.shell_size.min}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {`${translate(lang, 'Size max')} (mm)`}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {assessment.shell_size.max}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {`${translate(lang, 'Size average')} (mm)`}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {assessment.shell_size.avg}
              </Subtitle>
            </div>
            {assessValues?.map(x => (
              <div key={x.id} className='info-card'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='mb-4 mt-4'
                >
                  {x.label}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {x.value}
                </Subtitle>
              </div>
            ))}
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Ready to harvest')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {diffDays(assessment.ready_harvest)}
              </Subtitle>
            </div>
            <div className='info-card'>
              <Subtitle
                size={3}
                color='black'
                align='left'
                fontWeight={400}
                className='mb-4 mt-4'
              >
                {translate(lang, 'Comment')}
              </Subtitle>
              <Subtitle size={4} color='black' align='left' fontWeight={500}>
                {assessment.comment}
              </Subtitle>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FarmLineInfoModal;
