import { ICustomField, ITag } from '../../entities/automation.entities';
import {
  IFarmResource,
  ISpatStorageResource,
} from '../../entities/farms.entities';
import { IInventoryResource } from '../../entities/inventory.entities';
import { IAccountSetting } from '../../entities/user.entities';
import { IUtilData } from '../../entities/utils.entities';
import { TExtraAction } from './extra.type';

interface IAccount {
  id: number;
  setting: IAccountSetting | null;
  type: 'operator' | 'grower';
}

interface IExtraState {
  farms?: IFarmResource[];
  spatStorages?: ISpatStorageResource[];
  inventories?: IInventoryResource[];
  utils?: IUtilData[];
  customFields?: ICustomField[];
  tags?: ITag[];
  accounts?: IAccount[];
}

const initialState: IExtraState = {};

const extraReducer = (
  state = initialState,
  action: TExtraAction,
): IExtraState => {
  switch (action.type) {
    case 'EXTRA/SET_EXTRA_FARMS': {
      return { ...state, farms: action.payload };
    }
    case 'EXTRA/SET_EXTRA_INVENTORIES': {
      return { ...state, inventories: action.payload };
    }
    case 'EXTRA/SET_EXTRA_SPAT_STORAGES': {
      return { ...state, spatStorages: action.payload };
    }
    case 'EXTRA/SET_EXTRA_UTILS': {
      return { ...state, utils: action.payload };
    }
    case 'EXTRA/SET_CUSTOM_FIELDS': {
      return { ...state, customFields: action.payload };
    }
    case 'EXTRA/SET_TAGS': {
      return { ...state, tags: action.payload };
    }
    case 'EXTRA/SET_ACCOUNTS': {
      return { ...state, accounts: action.payload };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default extraReducer;
