import { combineReducers, Dispatch } from 'redux';

import authReducer from './auth/auth.reducer';
import automationReducer from './automation/automation.reducer';
import farmsReducer from './farms/farms.reducer';
import uiReducer from './ui/ui.reducer';
import usersReducer from './users/users.reducer';
import utilsReducer from './utils/utils.reducer';
import tasksReducer from './tasks/tasks.reducer';
import subscriptReducer from './subscription/subscription.reducer';
import inventoryReducer from './inventories/inventories.reducer';
import extraReducer from './extra/extra.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  automation: automationReducer,
  farms: farmsReducer,
  ui: uiReducer,
  users: usersReducer,
  subscription: subscriptReducer,
  utils: utilsReducer,
  tasks: tasksReducer,
  inventories: inventoryReducer,
  extra: extraReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export type IThunkType = Dispatch<any>;
