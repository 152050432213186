import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { Button, Input, Subtitle, Title, TrashIcon } from '../shared';
import { translate } from '../../lib/lang.helper';
import { useMemo, useState } from 'react';
import { Modal, Table, Button as AntBtn } from 'antd';
import { selectSizedOysters } from '../../store/utils/utils.selector';
import { selectAccount } from '../../store/auth/auth.selector';
import { sendSingleRequest } from '../../apis';
import { updateAccountSetting } from '../../store/auth/auth.actions';
import { loadUtilsData } from '../../store/utils/utils.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';

interface IRow {
  id?: string | number;
  name: string;
  num_val?: number | null;
  type: string;
  size_min?: number;
  size_max?: number;
  price?: number;
}

type TUKey = 'name' | 'num_val' | 'size_min' | 'size_max' | 'price';

const columns = (lang: any): any => [
  {
    title: translate(lang, 'Name'),
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => <div data-name='name'>{name}</div>,
  },
  {
    title: translate(lang, 'Probability'),
    key: 'num_val',
    render: (x: IRow) => (
      <div data-name='num_val'>
        {x.num_val !== null && x.num_val !== undefined ? `${x.num_val} %` : '-'}
      </div>
    ),
  },
  {
    title: translate(lang, 'Min'),
    key: 'min',
    render: (x: IRow) => (
      <div>
        {x.size_min === undefined || x.size_min === null
          ? '-'
          : `${x.size_min} mm`}
      </div>
    ),
  },
  {
    title: translate(lang, 'Max'),
    key: 'max',
    render: (x: IRow) => (
      <div>
        {x.size_max === undefined || x.size_max === null
          ? '-'
          : `${x.size_max} mm`}
      </div>
    ),
  },
  {
    title: translate(lang, 'Price'),
    key: 'price',
    render: (x: IRow) => (
      <div>
        {x.price === undefined || x.price === null ? '-' : `$ ${x.price}`}
      </div>
    ),
    align: 'left',
  },
];

const OysterHarvestTypes = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const oysterFields = useSelector(selectSizedOysters);
  const onGrowWaste = useSelector(selectAccount)?.oyster_crops;

  const tableData = useMemo(
    () => [
      {
        id: 'grown',
        name: translate(lang, 'Grown ons'),
        type: 'oyster_harvest',
        num_val: onGrowWaste?.grow_ons,
        size_min: onGrowWaste?.grow_ons_json?.size_min,
        size_max: onGrowWaste?.grow_ons_json?.size_max,
        price: onGrowWaste?.grow_ons_json?.price,
      },
      {
        id: 'waste',
        name: translate(lang, 'Waste'),
        type: 'oyster_harvest',
        num_val: onGrowWaste?.waste,
        size_min: onGrowWaste?.waste_json?.size_min,
        size_max: onGrowWaste?.waste_json?.size_max,
        price: onGrowWaste?.waste_json?.price,
      },
      ...oysterFields.map(x => ({
        id: x.id,
        name: x.name,
        num_val: x.num_val,
        type: x.type,
        size_min: x.json?.size_min,
        size_max: x.json?.size_max,
        price: x.json?.price,
      })),
    ],
    [onGrowWaste, oysterFields],
  );

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<IRow[]>(tableData);
  const [disabled, setDisabled] = useState(false);

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        message,
        isMessageModal: true,
      }),
    );
  const updateField = (i: number, key: TUKey, value: string) => {
    const val =
      key === 'name' ? value : value.length <= 0 ? null : Number(value);
    const tmp = [...data];
    tmp[i] = { ...tmp[i], [key]: val };
    setData(tmp);
  };
  const addNewField = () => {
    setData([...data, { name: '', num_val: null, type: 'oyster_harvest' }]);
  };
  const removeField = (i: number) => {
    const tmp = [...data];
    tmp.splice(i, 1);
    setData(tmp);
  };
  const confirmClick = async () => {
    if (data.some(x => !x.name)) {
      showError(translate(lang, 'Please fill name'));
      return;
    }
    if (data.some(x => x.num_val !== undefined && x.num_val !== null)) {
      const sum = data.reduce((acc, x) => acc + (x.num_val || 0), 0);
      if (Math.abs(100 - sum) > 0.0001) {
        showError(
          translate(lang, 'All probability values should total to 100%'),
        );
        return;
      }
    }
    setDisabled(true);
    const deletedFields = oysterFields.filter(
      x => !data.some(y => y.id === x.id),
    );
    for (const d of deletedFields) {
      await sendSingleRequest({}, 'DELETE', `api/utils/${d.id}`, true);
    }
    const utils = data
      .filter(x => x.id !== 'grown' && x.id !== 'waste')
      .map(x => {
        const os = oysterFields.find(y => y.id === x.id);
        if (!os) {
          return {
            name: x.name,
            type: 'oyster_harvest',
            num_val: x.num_val,
            json: {
              size_min: x.size_min,
              size_max: x.size_max,
              price: x.price,
            },
          };
        } else {
          const tmp = {
            size_min: x.size_min,
            size_max: x.size_max,
            price: x.price,
          };
          return {
            ...os,
            name: x.name,
            num_val: x.num_val,
            json: os.json ? { ...os.json, ...tmp } : tmp,
          };
        }
      });
    const res = await sendSingleRequest(utils, 'POST', 'api/utils/bulk', true);
    if (!res.status) {
      showError(res.data?.message ?? 'Server error');
    }
    const oyster_crops = {
      ...onGrowWaste,
      grow_ons: data[0].num_val,
      waste: data[1].num_val,
      grow_ons_json: {
        size_min: data[0].size_min,
        size_max: data[0].size_max,
        price: data[0].price,
      },
      waste_json: {
        size_min: data[1].size_min,
        size_max: data[1].size_max,
        price: data[1].price,
      },
    };
    const r = await dispatch(updateAccountSetting({ oyster_crops }));
    if (r !== true) {
      showError(r ?? 'Server error');
    }
    await dispatch(loadUtilsData());
    setDisabled(false);
    setVisible(false);
  };
  const probSum =
    Math.round(data.reduce((acc, x) => acc + (x.num_val || 0), 0) * 100) / 100;

  const tableColumns = [
    {
      title: translate(lang, 'Name'),
      key: 'name',
      render: (pp: any, row: IRow, i: number) => (
        <Input
          label=''
          placeholder={translate(lang, 'Name')}
          type='text'
          value={row.name}
          onChange={e => updateField(i, 'name', e.target.value)}
          disabled={row.id === 'grown' || row.id === 'waste'}
        />
      ),
    },
    {
      title:
        translate(lang, 'Probability') +
        (Math.abs(100 - probSum) > 0.0001 ? ` (${probSum}%)` : ''),
      key: 'probability',
      render: (pp: any, row: IRow, i: number) => (
        <Input
          label=''
          placeholder={translate(lang, 'Probability')}
          type='number'
          value={row.num_val?.toString() ?? ''}
          onChange={e => updateField(i, 'num_val', e.target.value)}
          unit='%'
        />
      ),
    },
    {
      title: translate(lang, 'Min'),
      key: 'min',
      render: (pp: any, row: IRow, i: number) => (
        <Input
          label=''
          type='number'
          value={row.size_min?.toString() ?? ''}
          onChange={e => updateField(i, 'size_min', e.target.value)}
          unit='mm'
        />
      ),
    },
    {
      title: translate(lang, 'Max'),
      key: 'max',
      render: (pp: any, row: IRow, i: number) => (
        <Input
          label=''
          type='number'
          value={row.size_max?.toString() ?? ''}
          onChange={e => updateField(i, 'size_max', e.target.value)}
          unit='mm'
        />
      ),
    },
    {
      title: translate(lang, 'Price'),
      key: 'price',
      render: (pp: any, row: IRow, i: number) => (
        <Input
          label=''
          type='number'
          value={row.price?.toString() ?? ''}
          onChange={e => updateField(i, 'price', e.target.value)}
          unit='$'
        />
      ),
    },
    {
      title: '',
      key: 'action',
      render: (pp: any, row: IRow, i: number) =>
        row.id !== 'grown' && row.id !== 'waste' ? (
          <Button
            width='full'
            size={0}
            type='bordered'
            color='red'
            className='d-flex justify-content-center'
            onClick={() => removeField(i)}
          >
            <TrashIcon color='#cc0101' />
          </Button>
        ) : (
          <></>
        ),
    },
  ];

  return (
    <div className='mt-64 harvest-type-list mb-32'>
      <div className='d-flex mb-16 align-items-center justify-content-between'>
        <Title
          className='mb-16'
          size={6}
          color='black-3'
          align='default'
          fontWeight={500}
        >
          {translate(lang, 'Oyster harvest type List')}
        </Title>
      </div>
      <div className='d-flex white-card pt-12 pl-16 mb-8'>
        <div className='d-flex align-items-center'>
          <Button
            color='blue'
            size={3}
            width='small'
            type='fill'
            onClick={() => {
              setVisible(true);
              setData(tableData);
            }}
          >
            {translate(lang, 'Update data')}
          </Button>
        </div>
      </div>
      <Table
        rowKey='id'
        className={'table table--units table--is__cursor'}
        pagination={false}
        columns={columns(lang)}
        dataSource={tableData}
      />
      {visible && (
        <Modal
          visible={true}
          onCancel={() => setVisible(false)}
          footer={null}
          width={1200}
        >
          <div className='wrap'>
            <div className='d-flex align-items-center mb-18'>
              <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                {translate(lang, 'Add new')}
              </Subtitle>
            </div>
            <div className='mb-17'>
              <Table
                rowKey='id'
                className='table table--units table--small'
                pagination={false}
                columns={tableColumns}
                dataSource={data}
              />
              <AntBtn
                size='small'
                type='primary'
                ghost={true}
                onClick={addNewField}
              >
                {translate(lang, 'Add new')}
              </AntBtn>
            </div>
            <div className='modal-button d-flex justify-content-end'>
              <Button
                width={'small'}
                size={2}
                type='fill'
                color='green'
                className='rsp-btn ml-16'
                onClick={confirmClick}
                disabled={disabled}
              >
                {translate(lang, 'Confirm')}
              </Button>
            </div>
          </div>
          <ModalFeedbackView />
        </Modal>
      )}
    </div>
  );
};

export default OysterHarvestTypes;
