import { useState } from 'react';
import {
  Button,
  CloseIcon,
  Dropdown,
  DropdownMenu,
  Input,
  Subtitle,
  Title,
} from '../../components/shared';
import { Modal, Table } from 'antd';
import { ICustomField } from '../../entities/automation.entities';
import { useDispatch, useSelector } from 'react-redux';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { sendSingleRequest } from '../../apis';
import { loadCustomFields } from '../../store/automation/automation.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectCustomFields } from '../../store/automation/automation.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import './styles.scss';

const ActionLabel = (type: string) => {
  if (type === 'HARVEST') return 'Harvest';
  else if (type === 'ASSESSMENT') return 'Assessment';
  else return type;
};

interface IFieldForm {
  name: string;
  input_type: 'select' | 'input' | null;
  options: string | null;
  data_type: 'number' | 'string' | null;
  unit: string | null;
}

const inputOptions = (lang: TLang | undefined) => [
    { id: 'select', label: translate(lang, 'Dropdown'), value: 'select' },
    { id: 'input', label: translate(lang, 'Input'), value: 'input' },
  ],
  dtOptions = (lang: TLang | undefined) => [
    { id: 'number', label: translate(lang, 'Number'), value: 'number' },
    { id: 'string', label: translate(lang, 'Text'), value: 'string' },
  ];

interface ModalProps {
  type: string;
  onClose: () => void;
  data?: ICustomField;
  editId?: number;
}

const FieldModal = ({ type, onClose, data, editId }: ModalProps) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IFieldForm>({
    name: '',
    input_type: null,
    options: null,
    data_type: null,
    unit: null,
    ...data,
  });
  const updateForm = (key: keyof IFieldForm, val: any) =>
    setForm({ ...form, [key]: val });

  const confirmClick = async () => {
    let valid = { ...form };
    if (!valid.name) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, 'Please enter name'),
        }),
      );
      return;
    }
    if (valid.unit && valid.unit.length > 9) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, 'Maximum allowed unit length is %s', 9),
        }),
      );
      return;
    }
    if (valid.input_type === 'input' || !valid.input_type) {
      valid.input_type = null;
      valid.options = null;
    }
    if (valid.data_type === 'string' || !valid.data_type) {
      valid.data_type = null;
    }
    if (!valid.unit) {
      valid.unit = null;
    }
    setDisabled(true);
    const res = editId
      ? await sendSingleRequest(
          valid,
          'PUT',
          `api/custom-field/${editId}`,
          true,
        )
      : await sendSingleRequest(
          { type, ...valid },
          'POST',
          'api/custom-field',
          true,
        );
    if (res.status) {
      await dispatch(loadCustomFields());
      onClose();
    } else {
      setDisabled(false);
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, res.data?.message ?? 'Unknown error'),
        }),
      );
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(
              lang,
              `${editId ? 'Update Custom' : 'Add Custom'} ${ActionLabel(
                type,
              )} Field`,
            )}
          </Subtitle>
        </div>
        <Input
          label={translate(lang, 'Name')}
          className='mr-16 w-100 mb-17'
          type='text'
          value={form.name}
          onChange={e => updateForm('name', e.target.value)}
        />
        <div className='w-100 mr-16 mb-17'>
          <Dropdown
            label={translate(lang, 'Input type')}
            options={inputOptions(lang)}
            value={form.input_type ?? 'input'}
            onChange={v => updateForm('input_type', v)}
          />
        </div>
        {form.input_type === 'select' ? (
          <Input
            label={translate(lang, 'Options')}
            className='mr-16 w-100 mb-17'
            type='text'
            placeholder={translate(lang, '_options_by_comma')}
            value={form.options ?? ''}
            onChange={e => updateForm('options', e.target.value)}
          />
        ) : (
          <>
            <div className='w-100 mr-16 mb-17'>
              <Dropdown
                label={translate(lang, 'Data type')}
                options={dtOptions(lang)}
                value={form.data_type ?? 'string'}
                onChange={v => updateForm('data_type', v)}
              />
            </div>
            <Input
              label={translate(lang, 'Unit')}
              className='mr-16 w-100 mb-17'
              type='text'
              value={form.unit ?? ''}
              onChange={e => updateForm('unit', e.target.value)}
            />
          </>
        )}
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16 mt-24'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

interface TableProps {
  type: string;
  data: ICustomField[];
  onAddClick: (t: string) => void;
  onEditClick: (d: ICustomField) => void;
  onDeleteClick: (d: ICustomField) => void;
}

const FieldTable = ({
  type,
  data,
  onAddClick,
  onEditClick,
  onDeleteClick,
}: TableProps) => {
  const lang = useSelector(selectLang);

  const columns = [
    {
      title: translate(lang, 'Name'),
      key: 'name',
      render: (x: ICustomField) => <div>{x.name}</div>,
    },
    {
      title: translate(lang, 'Input type'),
      key: 'input_type',
      render: (x: ICustomField) => (
        <div>{x.input_type === 'select' ? 'Dropdown' : 'Input'}</div>
      ),
    },
    {
      title: translate(lang, 'Data type'),
      key: 'data_type',
      render: (x: ICustomField) => (
        <div>{x.data_type === 'number' ? 'Number' : 'Text'}</div>
      ),
    },
    {
      title: translate(lang, 'Unit'),
      key: 'unit',
      render: (x: ICustomField) => <div>{x.unit}</div>,
    },
    {
      title: '',
      key: 'more',
      render: (d: any) =>
        !!d.type ? (
          <div className='wrap'>
            <DropdownMenu
              data={d}
              column='isUtil'
              onEdit={() => onEditClick(d)}
              onDeleteRow={() => onDeleteClick(d)}
            />
          </div>
        ) : (
          <span></span>
        ),
    },
  ];

  return (
    <div className='mb-32'>
      <div className='d-flex mb-16 align-items-center justify-content-between'>
        <Title
          className='mb-16'
          size={6}
          color='black-3'
          align='default'
          fontWeight={500}
        >
          {translate(lang, 'Custom %s Fields', ActionLabel(type))}
        </Title>
      </div>
      <div className='d-flex white-card pt-12 pl-16 mb-8'>
        <div className='d-flex align-items-center'>
          <Button
            color='blue'
            size={3}
            width='small'
            type='fill'
            onClick={() => onAddClick(type)}
          >
            {translate(lang, 'Add Custom %s Field', ActionLabel(type))}
          </Button>
        </div>
      </div>
      <Table
        className='table table--units'
        rowKey='id'
        pagination={false}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

const CustomFieldSetting = () => {
  const dispatch = useDispatch<any>();
  const customFields = useSelector(selectCustomFields);
  const lang = useSelector(selectLang);

  const [addType, setAddType] = useState<string>();
  const [editItem, setEditItem] = useState<ICustomField>();

  const handleDelete = async (item: ICustomField) => {
    if (!window.confirm(translate(lang, 'Are you sure to delete this data?')))
      return;

    const res = await sendSingleRequest(
      {},
      'DELETE',
      `api/custom-field/${item.id}`,
      true,
    );
    if (res.status) {
      await dispatch(loadCustomFields());
      dispatch(
        showFeedback({
          type: 'success',
          isMessage: true,
          message: translate(lang, res.data?.message ?? 'Deleted successfully'),
        }),
      );
    } else {
      dispatch(
        showFeedback({
          type: 'error',
          isMessage: true,
          message: translate(lang, res.data?.message ?? 'Unknown error'),
        }),
      );
    }
  };

  return (
    <div className='container w-100'>
      <div
        className='content pb-32 pt-32'
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <FieldTable
          type='ASSESSMENT'
          data={customFields.filter(x => x.type === 'ASSESSMENT')}
          onAddClick={t => setAddType(t)}
          onEditClick={d => setEditItem(d)}
          onDeleteClick={d => handleDelete(d)}
        />
        <FieldTable
          type='HARVEST'
          data={customFields.filter(x => x.type === 'HARVEST')}
          onAddClick={t => setAddType(t)}
          onEditClick={d => setEditItem(d)}
          onDeleteClick={d => handleDelete(d)}
        />
        {addType ? (
          <FieldModal type={addType} onClose={() => setAddType(undefined)} />
        ) : editItem ? (
          <FieldModal
            type={editItem.type}
            data={editItem}
            editId={editItem.id}
            onClose={() => setEditItem(undefined)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CustomFieldSetting;
