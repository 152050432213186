import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { sendSingleRequest } from '../../apis';
import './styles.scss';
import { Input, Spinner } from '../../components/shared';

const TodayWindyPage = () => {
  const [windy, setWindy] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState('40.05243183880866');
  const [longitude, setLongitude] = useState('124.32115722715984');

  const loadData = useCallback(() => {
    setLoading(true);
    sendSingleRequest(
      {
        latitude,
        longitude,
      },
      'GET',
      'api/windy',
      true,
    ).then(r => {
      setLoading(false);
      if (r.status) {
        setWindy(r.data);
      } else {
        setWindy(null);
      }
    });
  }, [latitude, longitude]);

  useEffect(() => loadData(), [loadData]);

  return (
    <div style={{ marginTop: '30px', padding: '20px' }}>
      <div
        style={{
          margin: '30px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className='mr-17'>
          <Input
            type='number'
            label='Latitude'
            value={latitude}
            onChange={e => setLatitude(e.target.value)}
          />
        </div>
        <div className='ml-17'>
          <Input
            type='number'
            label='Longitude'
            value={longitude}
            onChange={e => setLongitude(e.target.value)}
          />
        </div>
      </div>
      {loading ? (
        <div style={{ margin: '30px' }}>
          <Spinner />
        </div>
      ) : (
        windy && (
          <div style={{ width: '100%', overflow: 'auto' }}>
            <table
              cellSpacing={1}
              style={{ border: '1px solid black', padding: '10px' }}
            >
              <tbody>
                <tr>
                  <th style={{ border: '1px solid black' }}>Date</th>
                  {windy.ts.map((x: any, i: number) => (
                    <td
                      key={i}
                      style={{ border: '1px solid black', textAlign: 'center' }}
                    >
                      {moment(x).format('MM-DD LTS')}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th style={{ border: '1px solid black' }}>
                    Temperature (°C)
                  </th>
                  {windy['temp-surface'].map((x: any, i: number) => (
                    <td
                      key={i}
                      style={{ border: '1px solid black', textAlign: 'center' }}
                    >
                      {Number(x - 273).toFixed(1)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th style={{ border: '1px solid black' }}>Precip (mm)</th>
                  {windy['past3hprecip-surface'].map((x: any, i: number) => (
                    <td
                      key={i}
                      style={{ border: '1px solid black', textAlign: 'center' }}
                    >
                      {Number(x * 1000).toFixed(2)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th style={{ border: '1px solid black' }}>Wind (m/s)</th>
                  {windy['wind_u-surface'].map((x: any, i: number) => (
                    <td
                      key={i}
                      style={{ border: '1px solid black', textAlign: 'center' }}
                    >
                      {Math.sqrt(
                        x * x +
                          windy['wind_v-surface'][i] *
                            windy['wind_v-surface'][i],
                      ).toFixed(2)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  );
};

export default TodayWindyPage;
