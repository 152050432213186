import { defaultDateFormat } from '../../../../util/toggleSecondMillisecond';
import Subtitle from '../../subtitle/Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccount,
  selectUserMeta,
} from '../../../../store/auth/auth.selector';
import { Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { sendSingleRequest } from '../../../../apis';
import { loadFarmsData } from '../../../../store/farms/farms.actions';
import { showFeedback } from '../../../../store/ui/ui.actions';
import useMenuHandler from '../useMenuHandler';
import LineFormModal from '../../../farm-modals/LineFormModal';
import {
  IOysterFarm,
  IOysterLineResource,
} from '../../../../entities/farms.entities';
import {
  calcDiffDays,
  compareString,
  labelNumber,
} from '../../../../lib/common.helpers';
import {
  amountDays,
  calcLineLastSize,
  getLineOysters,
  getLineStatus,
} from '../../../../lib/farm.helpers';
import { formatNumber } from '../../../../entities/util-functions';
import { selectLang } from '../../../../store/ui/ui.selector';
import { translate } from '../../../../lib/lang.helper';
import { SelectIsEditable } from '../../../../store/extra/extra.selector';
import DotsMenu from '../../dropdown-menu/DotsMenu';

interface Props {
  farmData: IOysterFarm;
  linesData: IOysterLineResource[];
  isChild?: boolean;
  onEditLineLocation: (line: IOysterLineResource) => void;
}

const OysterLinesTable = ({
  farmData,
  linesData,
  isChild,
  onEditLineLocation,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const userMeta = useSelector(selectUserMeta);
  const { redirectToLine } = useMenuHandler();
  const isEditable = SelectIsEditable(farmData.id);
  const hiddenColumns = useSelector(selectAccount)?.oyster_line_hidden_columns;

  const [editLine, setEditLine] = useState<IOysterLineResource>();

  const deleteLine = async (line: IOysterLineResource) => {
    if (!window.confirm('Are you sure you want to delete this line?')) return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/line/lines/${line.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Line deleted successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, response.data?.message ?? 'Unknown error'),
        }),
      );
    }
  };
  const changeTable = async (pagination: any, filter: any, sorter: any) => {
    const columnKey: any = sorter?.columnKey;
    const orders: any = sorter?.order;
    const data = { columnKey, orders, farmId: farmData.id };
    await sendSingleRequest(data, 'POST', 'api/farm/line-sorting', true);
  };

  const columns = [
    {
      title: 'Line',
      key: 'line_name',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        compareString(a.line_name, b.line_name),
      render: (x: IOysterLineResource) => <span>{x.line_name}</span>,
    },
    {
      title: 'Length',
      key: 'length',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        a.length - b.length,
      render: (x: IOysterLineResource) => <span>{`${x.length} m`}</span>,
    },
    {
      title: 'Date seeded',
      key: 'seed_date',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) => {
        if (a.growing_cycle) {
          return (
            Number(a.growing_cycle.main_seed.planned_date_seed) -
            Number(b.growing_cycle?.main_seed.planned_date_seed)
          );
        } else {
          return (a.line_idle ?? 0) - (b.line_idle ?? 0);
        }
      },
      render: (x: IOysterLineResource) =>
        x.growing_cycle ? (
          defaultDateFormat(x.growing_cycle.main_seed.planned_date_seed)
        ) : (
          <div className='d-flex flex-wrap'>
            <span className='min-width-74 pr-4'>Line empty</span>
            <Subtitle size={5} color='black' align='left' fontWeight={600}>
              {amountDays(x.line_idle)}
            </Subtitle>
          </div>
        ),
    },
    {
      title: 'Last assessment date',
      key: 'last_assessment',
      render: (line: IOysterLineResource) => {
        const x = line.growing_cycle?.last_assessment?.assessment_date;
        const d =
          getLineStatus(line, userMeta?.line_assess_expire_days) ===
          'REQUIRE_ASSESSMENT';
        return (
          <span className={d ? 'text-red' : ''}>
            {defaultDateFormat(x, '-')}
          </span>
        );
      },
    },
    {
      title: 'Avg.Size',
      key: 'condition',
      defaultSortOrder: null,
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        (calcLineLastSize(a)?.avg ?? 0) - (calcLineLastSize(b)?.avg ?? 0),
      render: (line: IOysterLineResource) =>
        line.growing_cycle ? (
          <span>{labelNumber(calcLineLastSize(line)?.avg, 'mm')}</span>
        ) : (
          ''
        ),
    },
    {
      title: 'Inventory',
      key: 'farming_method',
      render: (line: IOysterLineResource) => <span>{line.farming_method}</span>,
    },
    {
      title: 'Number of baskets',
      key: 'basket_count',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        a.basket_count - b.basket_count,
      render: (x: IOysterLineResource) =>
        x.growing_cycle ? (
          <span>
            {`${x.growing_cycle.current_basket_count}/${
              x.basket_count
            } (${formatNumber(
              (x.growing_cycle.current_basket_count * 100) / x.basket_count,
            )}%)`}
          </span>
        ) : (
          <span>{`-/${x.basket_count}`}</span>
        ),
    },
    {
      title: 'Batch seeded',
      key: 'spat_storage',
      render: (x: IOysterLineResource) => (
        <span>{x.growing_cycle?.main_seed.spat_storage?.source ?? '-'}</span>
      ),
    },
    {
      title: 'Days growth',
      key: 'growth_days',
      render: (d: IOysterLineResource) => (
        <span>
          {d.growing_cycle
            ? amountDays(
                calcDiffDays(d.growing_cycle.main_seed.planned_date_seed),
              )
            : '-'}
        </span>
      ),
      sorter: (a: IOysterLineResource, b: IOysterLineResource) => {
        if (a.growing_cycle) {
          return (
            Number(a.growing_cycle.main_seed.planned_date_seed) -
            Number(b.growing_cycle?.main_seed.planned_date_seed)
          );
        } else {
          return (a.line_idle ?? 0) - (b.line_idle ?? 0);
        }
      },
    },
    {
      title: 'Est amount',
      key: 'est_amount',
      render: (d: IOysterLineResource) => {
        const estAmt = getLineOysters(d);
        return <span>{estAmt ? `${formatNumber(estAmt)} dz` : '-'}</span>;
      },
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        (getLineOysters(a) ?? 0) - (getLineOysters(b) ?? 0),
    },
    {
      title: '',
      key: 'more',
      render: (d: IOysterLineResource) => (
        <div>
          {isEditable && (
            <DotsMenu
              items={[
                {
                  label: translate(lang, 'Edit'),
                  onClick: () => setEditLine(d),
                },
                {
                  label: translate(lang, 'Location'),
                  onClick: () => onEditLineLocation(d),
                },
                {
                  label: translate(lang, 'Delete'),
                  onClick: () => deleteLine(d),
                },
              ]}
            />
          )}
        </div>
      ),
    },
  ]
    .filter(x => !x.title || !hiddenColumns?.includes(x.title))
    .map(x => ({
      ...x,
      title: translate(lang, x.title),
    }));

  const topScrollRef = useRef<HTMLDivElement | null>(null);
  const bottomScrollRef = useRef<HTMLDivElement | null>(null);

  const handleTopScroll = () => {
    if (bottomScrollRef.current && topScrollRef.current) {
      bottomScrollRef.current.scrollLeft = topScrollRef.current.scrollLeft;
    }
  };
  const handleBottomScroll = () => {
    if (topScrollRef.current && bottomScrollRef.current) {
      topScrollRef.current.scrollLeft = bottomScrollRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    const scrollTop = window.document.getElementById('lines-table-scroll-top');
    const tables = window.document.getElementsByClassName('table--lines');
    if (scrollTop && tables.length > 0) {
      scrollTop.style.width = `${tables[0].clientWidth}px`;
    }
  }, [linesData]);

  return (
    <div>
      <div
        ref={topScrollRef}
        onScroll={handleTopScroll}
        style={{
          overflowX: 'auto',
          height: '20px',
          marginTop: '8px',
        }}
      >
        <div id='lines-table-scroll-top' style={{ width: '100%' }} />
      </div>
      <div
        ref={bottomScrollRef}
        onScroll={handleBottomScroll}
        style={{ overflowX: 'auto' }}
      >
        <Table
          rowKey={'id'}
          className={`table table--isFarm ${isChild ? 'table--is__child' : ''}`}
          pagination={false}
          columns={columns}
          dataSource={linesData}
          onRow={dataRow => ({
            onClick: () => redirectToLine(dataRow.farm_id, dataRow.id),
          })}
          onChange={changeTable}
        />
      </div>
      {editLine && (
        <LineFormModal
          visible={true}
          lineData={editLine}
          onClose={() => setEditLine(undefined)}
          title='Edit line details'
          farmData={farmData}
        />
      )}
    </div>
  );
};

export default OysterLinesTable;
