import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  IHarvestResource,
  IMusselHarvest,
  IOysterHarvest,
} from '../../entities/growing.entities';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { formatNumber } from '../../entities/util-functions';

interface Props {
  harvestData: IHarvestResource;
  onClickCell: (d: any) => void;
}

const HarvestCell = ({ harvestData, onClickCell }: Props) => {
  const lang = useSelector(selectLang);
  const farm = useSelector(selectFarmsData).find(
    x => x.id === harvestData.line.farm_id,
  );
  const line = farm?.lines.find(x => x.id === harvestData.line_id);

  const handleClick = (event: any) => {
    event.preventDefault();
    onClickCell(harvestData);
  };

  return (
    <div className='--entity --harvest' onClick={handleClick.bind(this)}>
      <div className='--header'>
        <span>{translate(lang, 'harvest')}</span>
      </div>
      <div className='--title'>
        <div>{`${farm?.name} - ${line?.line_name}`}</div>
        <div className='mt-7'>
          {farm?.type === 'MUSSEL'
            ? `${harvestData.season_name} - ${formatNumber(
                (harvestData as IMusselHarvest).amount,
              )} kg`
            : `${harvestData.season_name} - ${
                (harvestData as IOysterHarvest).amount_dz
              } dz`}
        </div>
      </div>
      <div className='--btn-bar'>
        <Link to={`/harvest/${harvestData.id}`} className='--view'>
          {translate(lang, 'View')}
        </Link>
      </div>
    </div>
  );
};

export default HarvestCell;
