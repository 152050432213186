import { IUtilsAction, IUtilsState } from './utils.type';

const initialState: IUtilsState = {
  farmUtils: [],
  pusher_key: '',
  pusher_cluster: '',
  server_env: 'production',
};

const utilsReducer = (
  state = initialState,
  action: IUtilsAction,
): IUtilsState => {
  switch (action.type) {
    case 'UTILS/SET_FARM_UTILS': {
      return {
        ...state,
        farmUtils: action.payload,
      };
    }
    case 'UTILS/SET_SYSTEM_SETTINGS': {
      return {
        ...state,
        pusher_key: action.payload.pusher_key,
        pusher_cluster: action.payload.pusher_cluster,
        server_env: action.payload.env,
      };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default utilsReducer;
