import { TModuleName } from '../entities/util-functions';
import ProfileRouter from './Profile/ProfileRouter';
import AddUsers from './users/AddUsers';
import FarmsRouter from './Farms/FarmsRouter';
import Notifications from './static/Notifications';
import AutomationPage from './Task/AutomationPage';
import Users from './users/Users';
import BudgetTotalPage from './budget/BudgetTotalPage';
import BudgetLog from './budget/BudgetLog';
import AccountMessagesPage from './AccountMessage/AccountMessagesPage';
import SpatStoragesPage from './SpatStorage/SpatStoragesPage';
import InventoriesPage from './Inventories/InventoriesPage';
import SummaryHarvestPage from './Summary/SummaryHarvestPage';
import LinesPage from './Lines/LinesPage';
import ReportPage from './Report/ReportPage';
import FarmsMap from './Farms/FarmsMap';
import HarvestDetailPage from './harvest/HarvestDetailPage';
import UserMessagesPage from './UserMessage/UserMessagesPage';
import QueuedDataPage from './QueuedData/QueuedDataPage';
import BoatsPage from './boat/BoatsPage';
import LineSpatsPage from './line-spat/LineSpatPage';
import ImportCyclesPage from './import/ImportCyclesPage';
import CalendarPage from './calendar/CalendarPage';
import TodayWindyPage from './Summary/TodayWindyPage';
import BudgetFarmPage from './budget/BudgetFarmPage';
import ImportLinesPage from './import/ImportLinesPage';
import ImportAssessmentsPage from './import/ImportAssessmentsPage';
import BoatMessagesPage from './boat-message/BoatMessagesPage';
import QRGeneratePage from './qr-code/QRGeneratePage';
import SummaryProcessPage from './Summary/SummaryProcessPage';
import QRScanPage from './qr-code/QRScanPage';
import SummaryPerformancePage from './Summary/SummaryPerformancePage';
import BiosecurityPage from './Summary/BiosecurityPage';
import HarvestStagesPage from './Summary/HarvestStagesPage';
import LineGroups from './LineGroup/LineGroups';
import TaskListPage from './Task/TaskListPage';
import CustomFieldSetting from './Profile/CustomFieldSetting';
import TagsPage from './Profile/TagsPage';
import SuggestPage from './suggest/SuggestPage';
import PaperFormPage from './QueuedData/PaperFormPage';
import ExternalFarmAccessPage from './external-account/ExternalFarmAccessPage';
import ImportTimeSheetPage from './import/ImportTimeSheetPage';
import HomePage from './home/HomePage';
import OysterSummaryPage from './Summary/OysterSummaryPage';
import OysterEstimatesPage from './Summary/OysterEstimatesPage';
import OysterCalenderPage from './Summary/OysterCalenderPage';
import HarvestPlannerPage from './calendar/HarvestPlannerPage';
import OysterLinesPage from './Summary/OysterLinesPage';

interface IRoute {
  module: TModuleName;
  route: {
    path: string;
    component: React.FC;
    exact: boolean;
    isAdmin?: boolean;
    isFinance?: boolean;
  };
}

export const normalRoutes: Array<IRoute> = [
  {
    module: 'home',
    route: { path: '/', component: HomePage, exact: true },
  },
  {
    module: 'profile',
    route: {
      path: '/profile/:id?',
      component: ProfileRouter,
      exact: false,
    },
  },
  {
    module: 'user',
    route: {
      path: '/users/add-user',
      component: AddUsers,
      exact: true,
      isAdmin: true,
    },
  },
  {
    module: 'user',
    route: {
      path: '/users',
      component: Users,
      exact: true,
    },
  },
  {
    module: 'farm',
    route: {
      path: '/farms',
      component: FarmsRouter,
      exact: false,
    },
  },
  {
    module: 'farm',
    route: {
      path: '/farms-map',
      component: FarmsMap,
      exact: true,
    },
  },
  {
    module: 'budget',
    route: {
      path: '/budget',
      component: BudgetTotalPage,
      exact: true,
      isFinance: true,
    },
  },
  {
    module: 'budget',
    route: {
      path: '/budget/:farmId',
      component: BudgetFarmPage,
      exact: true,
      isFinance: true,
    },
  },
  {
    module: 'budget',
    route: {
      path: '/budget-log',
      component: BudgetLog,
      exact: true,
      isFinance: true,
    },
  },
  {
    module: 'line',
    route: {
      path: '/line-group',
      component: LineGroups,
      exact: false,
    },
  },
  {
    module: 'task',
    route: {
      path: '/tasks',
      component: TaskListPage,
      exact: false,
    },
  },
  {
    module: 'calendar',
    route: {
      path: '/calendar',
      component: CalendarPage,
      exact: false,
    },
  },
  {
    module: 'calendar',
    route: {
      path: '/harvest-planner',
      component: HarvestPlannerPage,
      exact: false,
    },
  },
  {
    module: 'calendar',
    route: {
      path: '/suggest',
      component: SuggestPage,
      exact: false,
    },
  },
  {
    module: 'spat-storage',
    route: {
      path: '/spat-storage',
      component: SpatStoragesPage,
      exact: false,
    },
  },
  {
    module: 'message',
    route: {
      path: '/account-messages',
      component: AccountMessagesPage,
      exact: false,
    },
  },
  {
    module: 'message',
    route: {
      path: '/user-messages',
      component: UserMessagesPage,
      exact: false,
    },
  },
  {
    module: 'boat',
    route: {
      path: '/boat-messages',
      component: BoatMessagesPage,
      exact: false,
    },
  },
  {
    module: 'automation',
    route: {
      path: '/automation',
      component: AutomationPage,
      exact: false,
    },
  },
  {
    module: 'inventory',
    route: {
      path: '/inventory',
      component: InventoriesPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/summary/harvest',
      component: SummaryHarvestPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/summary/processing',
      component: SummaryProcessPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/summary/performance',
      component: SummaryPerformancePage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/summary/biosecurity',
      component: BiosecurityPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/summary/stages',
      component: HarvestStagesPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/oyster-summary/growth',
      component: OysterSummaryPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/oyster-summary/estimate',
      component: OysterEstimatesPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/oyster-summary/calender',
      component: OysterCalenderPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/oyster-summary/lines',
      component: OysterLinesPage,
      exact: false,
    },
  },
  {
    module: 'line',
    route: {
      path: '/harvest/:harvestId',
      component: HarvestDetailPage,
      exact: false,
    },
  },
  {
    module: 'line',
    route: {
      path: '/lines',
      component: LinesPage,
      exact: false,
    },
  },
  {
    module: 'report',
    route: {
      path: '/report',
      component: ReportPage,
      exact: false,
    },
  },
  {
    module: 'import',
    route: {
      path: '/import/cycles',
      component: ImportCyclesPage,
      exact: false,
    },
  },
  {
    module: 'import',
    route: {
      path: '/import/line-location',
      component: ImportLinesPage,
      exact: false,
    },
  },
  {
    module: 'import',
    route: {
      path: '/import/assessments',
      component: ImportAssessmentsPage,
      exact: false,
    },
  },
  {
    module: 'import',
    route: {
      path: '/import/time-sheet',
      component: ImportTimeSheetPage,
      exact: false,
    },
  },
  {
    module: 'validation',
    route: {
      path: '/queue',
      component: QueuedDataPage,
      exact: true,
    },
  },
  {
    module: 'validation',
    route: {
      path: '/queue/:id',
      component: PaperFormPage,
      exact: true,
    },
  },
  {
    module: 'boat',
    route: {
      path: '/boats',
      component: BoatsPage,
      exact: false,
    },
  },
  {
    module: 'automation',
    route: {
      path: '/custom-fields',
      component: CustomFieldSetting,
      exact: false,
    },
  },
  {
    module: 'automation',
    route: {
      path: '/tags',
      component: TagsPage,
      exact: false,
    },
  },
  {
    module: 'line',
    route: {
      path: '/line-spats',
      component: LineSpatsPage,
      exact: false,
    },
  },
  {
    module: 'home',
    route: {
      path: '/notifications',
      component: Notifications,
      exact: true,
    },
  },
  {
    module: 'home',
    route: {
      path: '/today-windy',
      component: TodayWindyPage,
      exact: true,
    },
  },
  {
    module: 'home',
    route: {
      path: '/qr-generate',
      component: QRGeneratePage,
      exact: true,
    },
  },
  {
    module: 'home',
    route: {
      path: '/qr-scan',
      component: QRScanPage,
      exact: true,
    },
  },
  {
    module: 'home',
    route: {
      path: '/external-farm-access',
      component: ExternalFarmAccessPage,
      exact: true,
    },
  },
];
