import { Dropdown, Menu } from 'antd';
import DotsIcon from '../DotsIcon';
import './styles.scss';

interface IMenu {
  label: string;
  onClick?: (d: any) => void;
}

interface Props {
  items: IMenu[];
  className?: string;
}

const DotsMenu = ({ className, items }: Props) => (
  <div
    className={`dropdown ${className ?? ''}`}
    onClick={e => e.stopPropagation()}
  >
    <Dropdown
      overlay={
        <Menu>
          {items.map((item, index) => (
            <Menu.Item key={index} onClick={item.onClick}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      }
      placement='bottomRight'
      trigger={['click']}
    >
      <div>
        <DotsIcon />
      </div>
    </Dropdown>
  </div>
);

export default DotsMenu;
