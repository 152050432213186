import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import AdminSecurityPage from './AdminSecurityPage';
import AdminUsersPage from './AdminUsersPage';
import AdminAccountsPage from './AdminAccountsPage';

const AdminRoute = () => {
  const profile = useSelector((state: RootState) => state.auth.profile);

  if (profile?.type !== 'admin') {
    return null;
  }
  return (
    <Switch>
      <Route path='/' component={AdminUsersPage} exact />
      <Route path={'/admin/users'} exact={true} component={AdminUsersPage} />
      <Route
        path='/profile/security'
        component={AdminSecurityPage}
        exact={false}
      />
      <Route
        path={'/admin/accounts'}
        exact={true}
        component={AdminAccountsPage}
      />
    </Switch>
  );
};

export default AdminRoute;
