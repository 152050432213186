import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { IMaintenanceResource } from '../../entities/farms.entities';
import { numberToMoneyStr } from '../../entities/util-functions';
import { parseImageUrlToFileItem } from '../../util/getBase64';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { Button, CloseIcon, Input, Subtitle } from '../shared';
import PicturesWall from '../shared/pictures-wall/PicturesWall';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectAllFarms } from '../../store/extra/extra.selector';
import './styles.scss';

interface Props {
  visible: boolean;
  maintenance: IMaintenanceResource;
  lineId: number;
  onClose: () => void;
}

const MaintenanceViewModal = ({
  visible,
  maintenance,
  lineId,
  onClose,
}: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectAllFarms);
  const farm = farmsData.find(x => x.lines.some(y => y.id === lineId));
  const line = farm?.lines.find(x => x.id === lineId);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap view-modal'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Maintenance Details')}:
          </Subtitle>
          <Subtitle
            className='ml-7'
            color='black-3'
            align='left'
            size={1}
            fontWeight={500}
          >
            {`${farm?.name} - ${line?.line_name}`}
          </Subtitle>
        </div>
        <div className='pt-16 pb-24'>
          <div className='info-card'>
            <div className='info-label'>
              {translate(lang, 'Maintenance date')}
            </div>
            <div className='info-value'>
              {defaultDateFormat(maintenance.maintain_date)}
            </div>
          </div>
          <div className='info-card'>
            <div className='info-label'>{translate(lang, 'Type')}</div>
            <div className='info-value' style={{ textTransform: 'capitalize' }}>
              {maintenance.type.split('_').join(' ').toLowerCase()}
            </div>
          </div>
          <div className='info-card'>
            <div className='info-label'>{translate(lang, 'Expenses')}</div>
            <div className='info-value' style={{ textTransform: 'capitalize' }}>
              {`$ ${numberToMoneyStr(
                maintenance.expense_items?.reduce((p, c) => p + c.amount, 0) ??
                  0,
              )}`}
            </div>
          </div>
          <div className='mt-7 mb-7'>
            <Input
              label={translate(lang, 'Comment')}
              type='textarea'
              value={maintenance.comment ?? ''}
              disabled={true}
            />
          </div>
          {!!maintenance.images && maintenance.images.length > 0 && (
            <div className='mt-7 mb-7'>
              <PicturesWall
                label={translate(lang, 'Images')}
                fileList={
                  maintenance.images.map(x => parseImageUrlToFileItem(x)) as any
                }
                handleChangeImages={() => {}}
                disabled={true}
              />
            </div>
          )}
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MaintenanceViewModal;
