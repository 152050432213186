import { useState } from 'react';
import { ILineResource } from '../../entities/farms.entities';
import { Modal } from 'antd';
import { CloseIcon } from '../shared';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  lineData: ILineResource;
}

const HarvestPredictButton = ({ lineData }: Props) => {
  const lang = useSelector(selectLang);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <button className='harvest-predict-btn' onClick={() => setVisible(true)}>
        {translate(lang, 'Calculate predicted harvest date')}
      </button>
      {visible && (
        <Modal
          visible={true}
          onCancel={() => setVisible(false)}
          footer={null}
          closable
          closeIcon={<CloseIcon />}
          width={400}
        >
          <div className='wrap text-center'>
            <div className='mb-16 mt-64'>
              {translate(
                lang,
                'Harvest prediction for %s line is on %s',
                lineData.line_name,
                defaultDateFormat(
                  lineData.growing_cycle?.predicted_date_harvest,
                ),
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default HarvestPredictButton;
