import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import { IInventoryManage } from '../../entities/inventory.entities';
import { inventoryLabel } from '../../lib/farm.helpers';

interface Props {
  floatsData: IInventoryManage;
  onShowFloats: (d: any) => void;
}

const InventorySeedCell = ({ floatsData, onShowFloats }: Props) => {
  const lang = useSelector(selectLang);
  const farm = useSelector(selectFarmsData).find(f =>
    f.lines.some(x => x.id === floatsData.line_id),
  );
  const line = farm?.lines.find(x => x.id === floatsData.line_id);
  const handleClick = (event: any) => {
    event.preventDefault();
    onShowFloats({ line, data: floatsData });
  };

  return (
    <div className='--entity --inventory_seed' onClick={handleClick.bind(this)}>
      <div className='--header'>
        <span>{translate(lang, 'floats')}</span>
      </div>
      <div className='--title' style={{ display: 'unset' }}>
        <div>{`${farm?.name} - ${line?.line_name}`}</div>
        <div>
          {floatsData.status === 'IN' ? '+' : '-'}
          {floatsData.quantity}, {inventoryLabel(floatsData.inventory)}
        </div>
      </div>
      <div className='--btn-bar'>
        <Link to={`/farms/${farm?.id}/${line?.id}`} className='--view'>
          {translate(lang, 'View')}
        </Link>
      </div>
    </div>
  );
};

export default InventorySeedCell;
