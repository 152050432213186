import {
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
  ISeaweedLineResource,
} from '../../../entities/farms.entities';
import { TBusinessType } from '../../../entities/general.entities';
import MusselCatchSpatModal from './MusselCatchSpatModal';
import OysterCatchSpatModal from './OysterCatchSpatModal';
import SeaweedCatchSpatModal from './SeaweedCatchSpatModal';

interface Props {
  visible: boolean;
  title: string;
  lineData: ILineResource;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  type: TBusinessType;
  data?: any;
  overConfirm?: (data: any) => Promise<any>;
}

const CatchSpatModal = ({
  visible,
  title,
  lineData,
  onCancel,
  onConfirm,
  type,
  data,
  overConfirm,
}: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselCatchSpatModal
        visible={visible}
        title={title}
        lineData={lineData as IMusselLineResource}
        onCancel={onCancel}
        onConfirm={onConfirm}
        data={data}
        overConfirm={overConfirm}
      />
    ) : type === 'OYSTER' ? (
      <OysterCatchSpatModal
        visible={visible}
        title={title}
        lineData={lineData as IOysterLineResource}
        onCancel={onCancel}
        onConfirm={onConfirm}
        data={data}
        overConfirm={overConfirm}
      />
    ) : type === 'SEAWEED' ? (
      <SeaweedCatchSpatModal
        visible={visible}
        title={title}
        lineData={lineData as ISeaweedLineResource}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    ) : (
      <></>
    )}
  </>
);

export default CatchSpatModal;
