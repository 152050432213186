import Paragrapgh from '../paragrapgh/Paragrapgh';
import InterestGrowth from './InterestGrowth';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import { randomKey } from '../../../lib/common.helpers';
import { IFarmCard } from '../../../entities/farms.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import './styles.scss';

interface Props {
  data: IFarmCard[];
  type?: string;
}

const TablesCard = ({ type, data }: Props) => {
  const lang = useSelector(selectLang);

  return (
    <div className='tables-card'>
      {(type === 'next-seed' || type === 'next-harvest') && (
        <Paragrapgh
          className='pb-4'
          size={3}
          color='black-2'
          align='default'
          fontWeight={400}
        >
          {translate(
            lang,
            type === 'next-seed' ? 'Next seeds' : 'Next Harvests',
          )}
        </Paragrapgh>
      )}
      {(type === 'next-seed' || type === 'next-harvest') && !data.length && (
        <Paragrapgh
          className='pb-4'
          size={1}
          color='default'
          align='default'
          fontWeight={500}
        >
          {translate(
            lang,
            type === 'next-seed'
              ? 'No planned seedings yet'
              : 'No planned harvests yet',
          )}
        </Paragrapgh>
      )}
      <div className='d-flex justify-content-between'>
        <div>
          {data.map((info: IFarmCard) => (
            <div className='tables-card__item' key={randomKey()}>
              {info.name && (
                <Paragrapgh
                  className='pt-16'
                  size={3}
                  color='black-2'
                  align='default'
                  fontWeight={400}
                >
                  {translate(lang, info.name)}
                </Paragrapgh>
              )}
              <Paragrapgh
                className='pb-4'
                size={1}
                color='default'
                align='default'
                fontWeight={500}
              >
                {info?.date
                  ? defaultDateFormat(Number(info?.date))
                  : info.value}
                {` ${info?.unit ?? ''}`}
              </Paragrapgh>
            </div>
          ))}
        </div>
        {data.length > 0 && data[0].interest && (
          <div className='pt-4'>
            <InterestGrowth
              interest={data[0].interest.interest}
              isGrow={data[0].interest.isGrow}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TablesCard;
