import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { Table } from 'antd';
import { selectMusselFarms } from '../../store/farms/farms.selector';
import { useEffect, useState } from 'react';
import {
  defaultDateFormat,
  toMillisecond,
} from '../../util/toggleSecondMillisecond';
import { translate } from '../../lib/lang.helper';
import { amountDays, calcLineLastSize } from '../../lib/farm.helpers';
import { Spinner, Subtitle, Title } from '../../components/shared';
import { formatNumber } from '../../entities/util-functions';
import { useHistory } from 'react-router-dom';
import { selectAccount } from '../../store/auth/auth.selector';
import { Bar } from 'react-chartjs-2';
import { sendSingleRequest } from '../../apis';
import { ChartData } from 'chart.js';
import {
  generateMonthlyRanges,
  generateWeeklyRanges,
} from '../../lib/form.helpers';
import { calcDiffDays, labelRange } from '../../lib/common.helpers';
import './styles.scss';

interface IRow {
  id: number | string;
  week: string;
  date: string;
  farm_id?: number;
  farm_name: string;
  farm_number: string;
  line_id?: number;
  line: string;
  last_date: string;
  last_size: string;
  line_length: string;
  age: string;
  last_harvest: string;
  estimate_weight: string;
  rowSpan?: number;
  total?: number;
}

interface ILineProp {
  farm_id: number;
  line_id: number;
  harvest_size: number;
  grow_speed: number;
}

const columns = (lang: any) => [
  {
    key: 'week',
    render: (x: IRow) =>
      x.rowSpan ? (
        <span>
          <div className='text-center'>{x.week}</div>
          <div>{x.date}</div>
        </span>
      ) : (
        ''
      ),
    width: 210,
  },
  {
    title: translate(lang, 'Farm'),
    key: 'farm',
    render: (x: IRow) => (
      <span>
        <div>{x.farm_name}</div>
        <div className='text-center'>{x.farm_number}</div>
      </span>
    ),
  },
  {
    title: translate(lang, 'Line'),
    key: 'line',
    render: (x: IRow) => <span>{x.line}</span>,
    width: 60,
  },
  {
    title: translate(lang, 'Last assessment date'),
    key: 'last_date',
    render: (x: IRow) => <span>{x.last_date}</span>,
  },
  {
    title: translate(lang, 'Last known size'),
    key: 'last_size',
    render: (x: IRow) => <span>{x.last_size}</span>,
  },
  {
    title: translate(lang, 'Longline length'),
    key: 'line_length',
    render: (x: IRow) => <span>{x.line_length}</span>,
  },
  {
    title: translate(lang, "Today's age"),
    key: 'age',
    render: (x: IRow) => <span>{x.age}</span>,
  },
  {
    title: translate(lang, 'Previous harvest'),
    key: 'last_harvest',
    render: (x: IRow) => <span>{x.last_harvest}</span>,
  },
  {
    title: translate(lang, 'Estimated harvest'),
    key: 'estimate_harvest',
    render: (x: IRow) => <span>{x.estimate_weight}</span>,
  },
  {
    title: translate(lang, 'Total'),
    key: 'total',
    render: (x: IRow) => (
      <span>{x.total ? `${formatNumber(x.total)} kg` : ''}</span>
    ),
  },
];

const HarvestPlannerPage = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectMusselFarms);
  const accountSetting = useSelector(selectAccount);

  const [rowsData, setRowsData] = useState<IRow[]>();
  const [chartData, setChartData] =
    useState<ChartData<'bar', number[], string>>();

  const totalSum = rowsData?.reduce((p, c) => p + (c.total ?? 0), 0) ?? 0;

  useEffect(() => {
    (async () => {
      const response = await sendSingleRequest(
        {},
        'GET',
        'api/farm/lines-props',
        true,
      );
      if (!response.status) {
        window.alert(response.data?.message ?? 'Server error');
        return;
      }
      const linesProps: ILineProp[] = response.data;
      const weekRanges = generateWeeklyRanges();
      const harvestSize = accountSetting?.harvest_min_size,
        growSpeed = accountSetting?.grow_speed;
      let result: IRow[] = [];
      for (const week of weekRanges) {
        let first = 0,
          cnt = 0,
          totalSum = 0;
        for (const farm of farmsData) {
          for (const line of farm.lines) {
            const cycle = line.growing_cycle;
            if (!cycle) continue;

            const prop = linesProps.find(
              x => x.farm_id === farm.id && x.line_id === line.id,
            );
            if (!prop && (!harvestSize || !growSpeed)) continue;

            const size = prop?.harvest_size
              ? prop?.harvest_size
              : harvestSize ?? 0;
            const speed = prop?.grow_speed ? prop?.grow_speed : growSpeed ?? 0;
            const shellSize = calcLineLastSize(line);
            if (!shellSize || speed === 0) continue;

            const lineLength = cycle.current_line_length;
            const sd =
              cycle.last_assessment?.assessment_date ??
              cycle.main_seed.planned_date_seed;
            const dt =
              (Math.max(0, size - shellSize.avg) / speed) * 30 * 86400000;
            const pd = toMillisecond(sd) + dt;

            if (week.start_date <= pd && pd <= week.finish_date) {
              const id = result.length + 1;
              const etaWeight = cycle.estimated_amount ?? null;
              result.push({
                id: id,
                week: week.week_label,
                date: week.day_label,
                farm_id: farm.id,
                line_id: line.id,
                farm_name: farm.name,
                farm_number: farm.farm_number,
                line: line.line_name,
                last_date:
                  defaultDateFormat(cycle.last_assessment?.assessment_date) ??
                  '-',
                last_size: labelRange(shellSize.min, shellSize.max, 'mm'),
                line_length: `${lineLength} m`,
                age: amountDays(
                  calcDiffDays(cycle.main_seed.planned_date_seed),
                ),
                last_harvest: line?.last_final_harvest
                  ? `${formatNumber(line.last_final_harvest.amount)} kg${
                      line.last_final_harvest.line_length
                        ? ` (${formatNumber(
                            line.last_final_harvest.line_length,
                          )} m)`
                        : ''
                    }`
                  : '-',
                estimate_weight: etaWeight
                  ? `${formatNumber(etaWeight)} kg`
                  : '-',
              });
              if (!first) first = id;
              if (etaWeight) {
                totalSum += etaWeight;
              }
              cnt++;
            }
          }
        }
        if (first) {
          let i = result.findIndex(x => x.id === first);
          result[i].rowSpan = cnt;
          result[result.length - 1].total = totalSum;
        } else {
          result.push({
            id: result.length + 1,
            week: week.week_label,
            date: week.day_label,
            farm_name: '',
            farm_number: '',
            line: '',
            last_date: '',
            last_size: '',
            line_length: '',
            age: '',
            last_harvest: '',
            estimate_weight: '',
            rowSpan: 1,
          });
        }
      }
      setRowsData(result);

      const monthRanges = generateMonthlyRanges();
      let chartValues: number[] = [];
      let chartLabels: string[] = [];
      for (const month of monthRanges) {
        let sum = 0;
        for (const farm of farmsData) {
          for (const line of farm.lines) {
            if (!line.growing_cycle) continue;
            const shellSize = calcLineLastSize(line);
            if (!shellSize) continue;

            const prop = linesProps.find(
              x => x.farm_id === farm.id && x.line_id === line.id,
            );
            if (!prop && (!harvestSize || !growSpeed)) continue;
            const sd =
              line.growing_cycle.last_assessment?.assessment_date ??
              line.growing_cycle.main_seed.planned_date_seed;
            const size = prop?.harvest_size
              ? prop?.harvest_size
              : harvestSize ?? 0;
            const speed = prop?.grow_speed ? prop?.grow_speed : growSpeed ?? 0;
            if (speed === 0) continue;
            const dt =
              (Math.max(0, size - shellSize.avg) / speed) * 30 * 86400000;
            const pd = toMillisecond(sd) + dt;

            if (month.start_date <= pd && pd <= month.finish_date) {
              sum += line.growing_cycle.estimated_amount ?? 0;
            }
          }
        }
        chartLabels.push(month.month_label);
        chartValues.push(sum);
      }
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: 'Planned harvest',
            data: chartValues,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      });
    })();
  }, [dispatch]);

  return (
    <div className='bg-secondary'>
      <div className='container harvest-planner-page pb-32'>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Harvest planner')}
          </Title>
        </div>
        {rowsData === undefined ? (
          <div className='loader'>
            <Spinner />
          </div>
        ) : (
          <div className='content mb-16'>
            {chartData !== undefined && (
              <div className='mb-24 pr-16 pl-16 pt-16 pb-16'>
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      x: {
                        beginAtZero: true,
                      },
                    },
                  }}
                  height={70}
                />
              </div>
            )}
            <Table
              rowKey={'id'}
              columns={columns(lang)}
              dataSource={rowsData}
              pagination={false}
              onRow={row => ({
                onClick: () =>
                  row.farm_id && row.line
                    ? history.push(`/farms/${row.farm_id}/${row.line_id}`)
                    : undefined,
                style: { cursor: 'pointer' },
              })}
              scroll={{ y: 600 }}
            />
            <div className='mr-32 mt-16 mb-7'>
              <Subtitle size={4} color='black' align='right' fontWeight={500}>
                {`${translate(lang, 'Total')}: ${formatNumber(totalSum)} kg`}
              </Subtitle>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HarvestPlannerPage;
