import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CheckboxButton,
  Title,
  Spinner,
  Datepicker,
  Dropdown,
  RadioButton,
} from '../../components/shared';
import rightIcon from '../../images/right-open-icon.png';
import downIcon from '../../images/down-close-icon.png';
import { downloadReport } from '../../apis';
import moment from 'moment';
import { showFeedback } from '../../store/ui/ui.actions';
import { Radio } from 'antd';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectAccount } from '../../store/auth/auth.selector';
import { selectBoats } from '../../store/users/users.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

type LabelType = 'FARM' | 'LINE';

interface RowItemProps {
  isCollapsed: boolean | null;
  level: LabelType;
  label: string;
  isChecked: boolean;
  isFullChecked: boolean;
  toggleCollapse: (p: any) => void;
  toggleCheck: (p: any) => void;
  onClickLabel?: (p: any) => void;
}

const RowItem: React.FC<RowItemProps> = ({
  isCollapsed,
  level,
  label,
  isChecked,
  isFullChecked,
  toggleCollapse,
  toggleCheck,
  onClickLabel,
}) => {
  const exp_cal_class =
    isCollapsed === null
      ? 'empty'
      : isCollapsed === true
      ? 'collapsed'
      : 'expanded';

  return (
    <div className='row-item'>
      <div className={`level-${level.toLowerCase()}`}></div>
      <div className={`exp-col ${exp_cal_class}`} onClick={toggleCollapse}>
        {isCollapsed !== null &&
          (isCollapsed ? (
            <img src={downIcon} alt='right-icon' />
          ) : (
            <img src={rightIcon} alt='down-icon' />
          ))}
      </div>
      <div className='label'>
        <span onClick={onClickLabel}>{label}</span>
      </div>
      <div className='checker'>
        <CheckboxButton
          checked={isChecked}
          isNegative={!isFullChecked}
          label=''
          onChange={toggleCheck}
        />
      </div>
    </div>
  );
};

type TReport =
  | 'FARM'
  | 'TASK'
  | 'SEEDING'
  | 'HARVEST'
  | 'ASSESSMENT'
  | 'BUDGET'
  | 'FLOAT'
  | 'INSURANCE'
  | 'TIME_SHEET'
  | 'PRODUCTIVITY';
type TPeriod = 'TODAY' | 'LAST_7' | 'LAST_30' | 'LAST_MONTH' | 'YEAR';

const reportTypeOptions = [
  {
    id: 'farm',
    value: 'FARM',
    label: 'Farm Overview',
  },
  {
    id: 'task',
    value: 'TASK',
    label: 'Task Report',
  },
  {
    id: 'seeding',
    value: 'SEEDING',
    label: 'Seeding Report',
  },
  {
    id: 'harvest',
    value: 'HARVEST',
    label: 'Harvest Report',
  },
  {
    id: 'assessment',
    value: 'ASSESSMENT',
    label: 'Assessment Report',
  },
  {
    id: 'float',
    value: 'FLOAT',
    label: 'Float Report',
  },
  {
    id: 'insurance',
    value: 'INSURANCE',
    label: 'Insurance Report',
  },
  {
    id: 'time_sheet',
    value: 'TIME_SHEET',
    label: 'Boat Timesheet',
  },
  {
    id: 'productivity',
    value: 'PRODUCTIVITY',
    label: 'Productivity Report',
  },
  {
    id: 'spat_storage',
    value: 'SPAT_STORAGE',
    label: 'Spats Report',
  },
  {
    id: 'spat_nursery',
    value: 'SPAT_NURSERY',
    label: 'Spat Nursery Report',
  }
];

const budgetOption = {
  id: 'budget',
  value: 'BUDGET',
  label: 'Budget Report',
};

const periodTypeOptions = [
  {
    id: 'today',
    value: 'TODAY',
    label: 'Today',
  },
  {
    id: 'last_7',
    value: 'LAST_7',
    label: 'Last 7 days',
  },
  {
    id: 'last_30',
    value: 'LAST_30',
    label: 'Last 30 days',
  },
  {
    id: 'last_month',
    value: 'LAST_MONTH',
    label: 'Last month',
  },
  {
    id: 'year',
    value: 'YEAR',
    label: 'Year to date',
  },
];

const harvestFinalOptions = [
  { id: 'final', value: 'final', label: 'Final harvest' },
  { id: 'non_final', value: 'non_final', label: 'Reseeding' },
  { id: 'all', value: 'all', label: 'All' },
];

const ReportPage = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const farms = useSelector(selectFarmsData);
  const boatsData = useSelector(selectBoats);
  const showBudget = useSelector(selectAccount)?.show_budget_menu;
  const reportTypes = showBudget
    ? [...reportTypeOptions, budgetOption]
    : reportTypeOptions;

  const [checkedFarmIds, setCheckedFarmIds] = useState<number[]>(
    farms.map(x => Number(x.id)),
  );
  const [checkedLineIds, setCheckedLineIds] = useState<number[]>(
    farms.reduce(
      (p, c) => [...p, ...c.lines.map(l => Number(l.id))],
      [] as number[],
    ),
  );
  const [boatIDs, setBoatIDs] = useState<number[]>(
    boatsData.map(x => x.id) || [],
  );
  const [isFarmCollapsed, setIsFarmCollapsed] = useState<any>({});
  const [previewLink, setPreviewLink] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    rangeFrom: new Date(new Date().getFullYear(), 0, 1).getTime(),
    rangeTo: Date.now(),
  });
  const updateDateRange = (key: string, val: number) => {
    setDateRange({ ...dateRange, [key]: val });
  };
  const [farmVisual, setFarmVisual] = useState(false);
  const [reportType, setReportType] = useState<TReport>('FARM');
  const [period, setPeriod] = useState<TPeriod>('YEAR');
  const [fileType, setFileType] = useState<'pdf' | 'xlsx'>('pdf');
  const [hvFinal, setHvFinal] = useState('all');

  const updatePeriod = (p: TPeriod) => {
    setPeriod(p);
    if (p === 'TODAY') {
      setDateRange({
        rangeFrom: moment().toDate().getTime(),
        rangeTo: moment().toDate().getTime(),
      });
    } else if (p === 'LAST_7') {
      setDateRange({
        rangeFrom: moment().subtract(7, 'd').toDate().getTime(),
        rangeTo: moment().toDate().getTime(),
      });
    } else if (p === 'LAST_30') {
      setDateRange({
        rangeFrom: moment().subtract(30, 'd').toDate().getTime(),
        rangeTo: moment().toDate().getTime(),
      });
    } else if (p === 'LAST_MONTH') {
      setDateRange({
        rangeFrom: moment()
          .subtract(1, 'month')
          .startOf('month')
          .toDate()
          .getTime(),
        rangeTo: moment()
          .subtract(1, 'month')
          .endOf('month')
          .toDate()
          .getTime(),
      });
    } else if (p === 'YEAR') {
      setDateRange({
        rangeFrom: moment().startOf('year').toDate().getTime(),
        rangeTo: moment().toDate().getTime(),
      });
    }
  };
  const isFarmChecked = (k: number) => {
    return checkedFarmIds.includes(k);
  };
  const isLineChecked = (k: number) => {
    return checkedLineIds.includes(k);
  };
  const isFarmFullChecked = (i: number) => {
    const lineIds = farms.find(t => t.id === i)?.lines.map(x => x.id);
    return (lineIds ?? []).every(k => isLineChecked(parseInt(k.toString())));
  };
  const toggleLineCheck = (f: number, k: number) => {
    const tmp = [...checkedLineIds];
    const i = tmp.findIndex(x => x === k);
    if (i < 0) {
      tmp.push(k);
      if (!checkedFarmIds.some(x => x === f)) {
        setCheckedFarmIds([...checkedFarmIds, f]);
      }
    } else {
      tmp.splice(i, 1);
      const farm = farms.find(x => x.id === f);
      if (!farm?.lines.some(x => tmp.some(y => y === x.id))) {
        setCheckedFarmIds(checkedFarmIds.filter(x => x !== f));
      }
    }
    setCheckedLineIds(tmp);
  };
  const toggleFarmCheck = (k: number) => {
    const tmp = [...checkedFarmIds];
    const i = tmp.findIndex(x => x === k);
    const lineIds =
      farms.find(x => x.id === k)?.lines.map(l => parseInt(l.id.toString())) ??
      [];
    if (i < 0) {
      tmp.push(k);
      setCheckedFarmIds(tmp);
      setCheckedLineIds([...checkedLineIds, ...lineIds]);
    } else {
      const lTmp = [...checkedLineIds];
      if (lineIds.every(l => checkedLineIds.findIndex(x => x === l) >= 0)) {
        tmp.splice(i, 1);
        setCheckedFarmIds(tmp);
        for (let x of lineIds)
          lTmp.splice(
            lTmp.findIndex(l => l === x),
            1,
          );
      } else {
        for (let x of lineIds.filter(l => lTmp.findIndex(x => x === l) < 0)) {
          lTmp.push(x);
        }
      }
      setCheckedLineIds(lTmp);
    }
  };
  const isCheckedFarmAll = () => {
    return farms.every(f => isFarmFullChecked(parseInt(f.id.toString())));
  };
  const toggleFarmCheckAll = () => {
    if (farms.every(f => isFarmFullChecked(parseInt(f.id.toString())))) {
      setCheckedFarmIds([]);
      setCheckedLineIds([]);
    } else {
      setCheckedFarmIds(farms.map(f => parseInt(f.id.toString())));
      const tmp = [];
      for (let f of farms) {
        const ls = f.lines.map(l => parseInt(l.id.toString()));
        tmp.push(...ls);
      }
      setCheckedLineIds(tmp);
    }
  };
  const toggleFarmCollapse = (farmId: number) => {
    let cf: any = { ...isFarmCollapsed };
    if (cf[farmId]) cf[farmId] = false;
    else cf[farmId] = true;
    setIsFarmCollapsed(cf);
  };
  const requestReport = async (type: 'download' | 'preview') => {
    if (type === 'preview' && /iPad|iPhone/.test(navigator.platform)) {
      alert('Sorry, iPhone or iPad does not support preview feature well');
    }
    setLoading(true);
    setPreviewLink(null);
    const mode =
      reportType === 'FARM'
        ? `farm-overview?type=${farmVisual ? 'visual' : 'normal'}`
        : reportType.toLowerCase();
    let data: any = {
      farms: checkedFarmIds,
      lines: checkedLineIds,
      boats: boatIDs,
      range_from:
        reportType === 'TASK'
          ? moment(dateRange.rangeFrom).format('yyyy-MM-DD')
          : Math.floor(dateRange.rangeFrom / 1000),
      range_to:
        reportType === 'TASK'
          ? moment(dateRange.rangeTo).add(1, 'day').format('yyyy-MM-DD')
          : Math.floor(dateRange.rangeTo / 1000),
      file_type: fileType,
    };
    if (reportType === 'HARVEST' && hvFinal !== 'all') {
      data.is_final = hvFinal === 'final';
    }
    try {
      const res = await downloadReport(data, mode);
      const blob = new Blob([res.data], { type: 'application/pdf' });
      let url = window.URL.createObjectURL(
        fileType === 'pdf' ? blob : res.data,
      );
      if (type === 'download') {
        let link = document.createElement('a');
        link.href = url;
        link.download = `report.${fileType}`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        setPreviewLink(url);
      }
    } catch (err: any) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, err?.message ?? 'Something went wrong.'),
        }),
      );
    }
    setLoading(false);
  };

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        <div className='report-page'>
          <div className='report--header d-flex justify-content-between align-items-center'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              {translate(lang, 'Report')}
            </Title>
          </div>
          <div className='report--body'>
            <div className='farm-line'>
              <div className='report-options'>
                <div className='flex-basis-50 mr-7'>
                  <Dropdown
                    label='Report type'
                    defaultValue={reportType}
                    options={reportTypes.map(x => ({
                      ...x,
                      label: translate(lang, x.label),
                    }))}
                    onChange={v => setReportType(v as any)}
                  />
                </div>
                {!['FLOAT', 'INSURANCE', 'FARM', 'SPAT_STORAGE', 'SPAT_NURSERY'].includes(
                  reportType,
                ) && (
                  <div className='flex-basis-50 ml-7'>
                    <Dropdown
                      label={translate(lang, 'Period')}
                      defaultValue={period}
                      options={periodTypeOptions}
                      onChange={v => updatePeriod(v as any)}
                    />
                  </div>
                )}
              </div>
              {!['FLOAT', 'INSURANCE', 'FARM', 'SPAT_STORAGE', 'SPAT_NURSERY'].includes(
                reportType,
              ) && (
                <div className='date-range'>
                  <Datepicker
                    label={`${
                      ['TIME_SHEET', 'ASSESSMENT'].includes(reportType)
                        ? 'Date'
                        : 'Seeded'
                    } from`}
                    defaultValue={dateRange.rangeFrom}
                    onChange={e =>
                      updateDateRange(
                        'rangeFrom',
                        e?.toDate().getTime() ?? Date.now(),
                      )
                    }
                    className='mr-7'
                  />
                  <Datepicker
                    label={`${
                      reportType === 'TIME_SHEET' ? 'Date' : 'Seeded'
                    } to`}
                    defaultValue={dateRange.rangeTo}
                    onChange={e =>
                      updateDateRange(
                        'rangeTo',
                        e?.toDate().getTime() ?? Date.now(),
                      )
                    }
                    className='ml-7'
                  />
                </div>
              )}
              {reportType === 'HARVEST' && (
                <div className='mb-17 ml-7 mr-7'>
                  <Dropdown
                    label={translate(lang, 'Harvest Type')}
                    options={harvestFinalOptions}
                    value={hvFinal}
                    onChange={v => setHvFinal(v)}
                  />
                </div>
              )}
              <div className='row-item' style={{ marginBottom: '17px' }}>
                <Radio.Group
                  className='d-flex justify-content-between w-100'
                  value={fileType}
                  onChange={e => setFileType(e.target.value)}
                >
                  <RadioButton value={'pdf'} label='PDF' />
                  <RadioButton value={'xlsx'} label='Excel' />
                </Radio.Group>
              </div>
              {['TIME_SHEET', 'PRODUCTIVITY'].includes(reportType) ? (
                boatsData.map(boat => (
                  <div key={boat.id}>
                    <RowItem
                      isCollapsed={null}
                      level='FARM'
                      label={`${boat.name} ${boat.reg_number}`}
                      isChecked={boatIDs.includes(boat.id)}
                      isFullChecked={boatIDs.includes(boat.id)}
                      toggleCollapse={() => null}
                      toggleCheck={() =>
                        setBoatIDs(
                          boatIDs.includes(boat.id)
                            ? boatIDs.filter(x => x !== boat.id)
                            : [...boatIDs, boat.id],
                        )
                      }
                      onClickLabel={() =>
                        setBoatIDs(
                          boatIDs.includes(boat.id)
                            ? boatIDs.filter(x => x !== boat.id)
                            : [...boatIDs, boat.id],
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <>
                  <RowItem
                    isCollapsed={null}
                    level='FARM'
                    label={translate(lang, 'Include farm visuals')}
                    isChecked={farmVisual}
                    isFullChecked={farmVisual}
                    toggleCollapse={() => null}
                    toggleCheck={() => setFarmVisual(!farmVisual)}
                    onClickLabel={() => setFarmVisual(!farmVisual)}
                  />
                  <RowItem
                    isCollapsed={null}
                    level='FARM'
                    label={translate(lang, 'Select all farms')}
                    isChecked={checkedFarmIds.length > 0}
                    isFullChecked={isCheckedFarmAll()}
                    toggleCollapse={() => null}
                    toggleCheck={() => toggleFarmCheckAll()}
                    onClickLabel={() => toggleFarmCheckAll()}
                  />
                  {farms.map(farm => (
                    <div key={farm.id}>
                      <RowItem
                        isCollapsed={
                          !!isFarmCollapsed[parseInt(farm.id.toString())]
                        }
                        level='FARM'
                        label={`${farm.name} - ${farm.farm_number}`}
                        isChecked={isFarmChecked(parseInt(farm.id.toString()))}
                        isFullChecked={isFarmFullChecked(
                          parseInt(farm.id.toString()),
                        )}
                        toggleCollapse={() =>
                          toggleFarmCollapse(parseInt(farm.id.toString()))
                        }
                        toggleCheck={() =>
                          toggleFarmCheck(parseInt(farm.id.toString()))
                        }
                        onClickLabel={() => {
                          toggleFarmCheck(parseInt(farm.id.toString()));
                        }}
                      />
                      {!!isFarmCollapsed[parseInt(farm.id.toString())] &&
                        farm.lines.length > 0 &&
                        farm.lines.map(line => (
                          <div key={line.id}>
                            <RowItem
                              label={translate(
                                lang,
                                'Line - %s',
                                line.line_name,
                              )}
                              isCollapsed={null}
                              level='LINE'
                              isChecked={isLineChecked(
                                parseInt(line.id.toString()),
                              )}
                              isFullChecked={true}
                              toggleCollapse={() => null}
                              toggleCheck={() =>
                                toggleLineCheck(
                                  parseInt(farm.id.toString()),
                                  parseInt(line.id.toString()),
                                )
                              }
                              onClickLabel={() =>
                                toggleLineCheck(
                                  parseInt(farm.id.toString()),
                                  parseInt(line.id.toString()),
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className='render-content'>
              <div className='render-header'>
                <Button
                  className='mr-17'
                  color='blue'
                  size={2}
                  width='wide'
                  type='fill'
                  disabled={fileType === 'xlsx'}
                  onClick={e => requestReport('preview')}
                >
                  {translate(lang, 'Preview')}
                </Button>
                <Button
                  className='ml-17 mr-17'
                  color='green'
                  size={2}
                  width='wide'
                  type='fill'
                  onClick={e => requestReport('download')}
                >
                  {translate(lang, 'Download')}
                </Button>
              </div>
              <div className='render-body'>
                {loading ? (
                  <div className='mt-64'>
                    <Spinner />
                  </div>
                ) : (
                  previewLink && (
                    <iframe
                      src={previewLink}
                      frameBorder={0}
                      className='preview-pdf'
                    ></iframe>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
