import Caret from '../shared/caret/Caret';
import Subtitle from '../shared/subtitle/Subtitle';
import Button from '../shared/button/Button';
import './styles.scss';

const zeroPad = (num: number) => String(num).padStart(2, '0');

const getYearRange = (year: number) => {
  if (new Date().getMonth() < 3) year -= 1;

  return `01/04/${zeroPad(year % 100)}-31/03/${zeroPad((year + 1) % 100)}`;
};

interface Props {
  year: number;
  onChange(e: number): void;
  disabledNext?: boolean;
  disabledPrev?: boolean;
}

const BudgetYearArrows = ({
  year,
  onChange,
  disabledNext,
  disabledPrev,
}: Props) => {
  const handleClickPrev = () => {
    onChange(year - 1);
  };
  const handleClickNext = () => {
    onChange(year + 1);
  };

  return (
    <div className='budget-year-button d-flex justify-content-between align-items-center white-card-small pt-3 pb-3'>
      <Button
        color='blue'
        size={3}
        width='default'
        type='transparent'
        className='mr-26'
        name='prev'
        onClick={handleClickPrev}
        disabled={disabledPrev}
        onlyIconDisabled
      >
        <Caret color='#5A607F' direction='left' />
      </Button>
      <div className='d-flex align-items-center'>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {year}
        </Subtitle>
        <Subtitle
          size={5}
          color='black-2'
          align='right'
          className='ml-7'
          fontWeight={500}
        >
          {getYearRange(year)}
        </Subtitle>
      </div>
      <Button
        color='blue'
        size={3}
        width='default'
        type='transparent'
        className='ml-26'
        name='next'
        onClick={handleClickNext}
        disabled={disabledNext}
        onlyIconDisabled
      >
        <Caret color='#5A607F' direction='right' />
      </Button>
    </div>
  );
};

export default BudgetYearArrows;
