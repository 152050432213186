import { IUsersAction, IUsersState } from './users.type';

const initialState: IUsersState = {
  users: [],
  boats: [],
};

const usersReducer = (
  state = initialState,
  action: IUsersAction,
): IUsersState => {
  switch (action.type) {
    case 'USERS/SET_USERS': {
      return { ...state, users: action.payload };
    }

    case 'USERS/SET_BOATS': {
      return { ...state, boats: action.payload };
    }

    case 'USERS/SET_NEW_ACCOUNT_MESSAGE': {
      return { ...state, newAccountMessage: action.payload };
    }

    case 'USERS/SET_NEW_USER_MESSAGE': {
      return { ...state, newUserMessage: action.payload };
    }

    case 'USERS/SET_NEW_BOAT_MESSAGE': {
      return { ...state, newBoatMessage: action.payload };
    }

    case 'AUTH/LOGOUT': {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default usersReducer;
