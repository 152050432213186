import { Modal, Pagination, Table } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  Dropdown,
  Input,
  ModalComponent,
  Spinner,
  Subtitle,
  Title,
} from '../../components/shared';
import { ILineSpatResource } from '../../entities/boat.entities';
import { IFarmResource, ILineResource } from '../../entities/farms.entities';
import ImageGallery from 'react-image-gallery';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { useWidth } from '../../util/useWidth';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import './styles.scss';

interface IImage {
  original: string;
  thumbnail: string;
}

const columns = [
  {
    key: 'farm',
    dataIndex: 'farm',
    title: 'Farm',
    render: (f: any) => <span>{f?.name}</span>,
  },
  {
    key: 'line',
    dataIndex: 'line',
    title: 'Line',
    render: (l: any) => <span>{l?.line_name}</span>,
  },
  {
    key: 'spat_count',
    dataIndex: 'spat_count',
    title: 'Spat count',
    render: (x: any) => <span>{x}</span>,
  },
  {
    key: 'user',
    dataIndex: 'user',
    title: 'Reported by',
    render: (x: any) => <span>{x.name}</span>,
  },
  {
    key: 'created_at',
    dataIndex: 'created_at',
    title: 'Created at',
    render: (x: any) => <span>{defaultDateFormat(x)}</span>,
  },
];

interface MobileProp {
  data: ILineSpatResource;
  onDelete: (d: any) => void;
  onOpenInfo: (d: any) => void;
  onOpenImage: (d: any) => void;
  lang: TLang | undefined;
}

const MobileCard = ({
  data,
  onDelete,
  onOpenInfo,
  onOpenImage,
  lang,
}: MobileProp) => (
  <div className='table-mobile__card'>
    <div className='mobile-spat-count'>
      {translate(lang, 'Spat count')}: {data.spat_count}
    </div>
    <div className='d-flex pb-16'>
      <div className='flex-basis-50'>
        <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
          {translate(lang, 'Farm')}
        </Subtitle>
        <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
          {data.farm?.name}
        </Subtitle>
      </div>
      <div className='flex-basis-50'>
        <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
          {translate(lang, 'Line')}
        </Subtitle>
        <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
          {data.line?.line_name}
        </Subtitle>
      </div>
    </div>
    <div className='d-flex pb-16'>
      <div className='flex-basis-50'>
        <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
          {translate(lang, 'Created at')}
        </Subtitle>
        <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
          {defaultDateFormat(data.created_at)}
        </Subtitle>
      </div>
      <div className='flex-basis-50'>
        <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
          {translate(lang, 'Reported by')}
        </Subtitle>
        <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
          {data.user?.name}
        </Subtitle>
      </div>
    </div>
    <div className='d-flex pb-16'>
      <div className='flex-basis-50'>
        <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
          {translate(lang, 'Comment')}
        </Subtitle>
        {data.comment ? (
          <div
            className='btn__modal'
            onClick={onOpenInfo.bind(this, data.comment)}
          >
            {translate(lang, 'View')}
          </div>
        ) : (
          <span></span>
        )}
      </div>
      <div className='flex-basis-50'>
        <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
          {translate(lang, 'Image')}
        </Subtitle>
        {data.images && data.images.length ? (
          <div
            className='btn__modal'
            onClick={onOpenImage.bind(this, data.images)}
          >
            {translate(lang, 'View')}
          </div>
        ) : (
          <span></span>
        )}
      </div>
    </div>
    <div className='action-btn'>
      <Button
        color='red'
        size={0}
        width='small'
        type='fill'
        className='ml-7'
        onClick={onDelete.bind(this, data.id)}
      >
        {translate(lang, 'Delete')}
      </Button>
    </div>
  </div>
);

const LineSpatsPage = () => {
  const dispatch = useDispatch<any>();
  const width = useWidth();

  const farmsData = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(false);
  const [spats, setSpats] = useState<ILineSpatResource[]>([]);
  const [selectedFarm, setSelectedFarm] = useState<IFarmResource>();
  const [selectedLine, setSelectedLine] = useState<ILineResource>();
  const [cntMin, setCntMin] = useState('');
  const [cntMax, setCntMax] = useState('');
  const [infoShow, setInfoShow] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [asPhotoModalVisible, setAsPhotoModalVisible] = useState(false);
  const [assessPhotos, setAssessPhotos] = useState<Array<IImage>>([]);
  const [pageOptions, setPageOptions] = useState({
    current: 1,
    pageSize: 20,
    total: spats.length ?? 0,
  });

  const handlePageChange = (pageOptions: any) => {
    setPageOptions(pageOptions);
  };
  const farmsOption = useMemo(() => {
    let tmp = [{ id: '0', value: '0', label: translate(lang, 'All Farms') }];
    tmp.push(
      ...farmsData.map((f: any) => ({
        id: f.id.toString(),
        value: f.id.toString(),
        label: f.name,
      })),
    );
    return tmp;
  }, [farmsData]);
  const linesOption = useMemo(() => {
    if (!selectedFarm) return null;
    let tmp = [{ id: '0', value: '0', label: translate(lang, 'All Lines') }];
    tmp.push(
      ...selectedFarm.lines.map(x => ({
        id: x.id.toString(),
        value: x.id.toString(),
        label: x.line_name,
      })),
    );
    return tmp;
  }, [selectedFarm]);

  const selectFarm = (v: string) => {
    setSelectedFarm(farmsData.find((x: any) => x.id === Number(v)));
    setSelectedLine(undefined);
  };
  const selectLine = (v: string) =>
    setSelectedLine(
      (selectedFarm?.lines.find(x => x.id === Number(v)) as any) ?? undefined,
    );

  const loadData = useCallback(() => {
    let filter: any = {};
    if (selectedFarm) filter.farm_id = selectedFarm.id;
    if (selectedLine) filter.line_id = selectedLine.id;
    if (cntMin && cntMin.length > 0) filter.spat_count_min = cntMin;
    if (cntMax && cntMax.length > 0) filter.spat_count_max = cntMax;

    setLoading(true);
    sendSingleRequest(filter, 'GET', 'api/farm/line/line-spats', true).then(
      r => {
        setLoading(false);
        if (r.status) {
          setSpats(r.data);
        } else {
          dispatch(
            showFeedback({
              isMessage: true,
              type: 'error',
              message: translate(
                lang,
                r.data?.message ?? 'Something went wrong',
              ),
            }),
          );
        }
      },
    );
  }, [selectedFarm, selectedLine, cntMin, cntMax, dispatch]);

  const openInfo = (comment: any) => {
    setInfoShow(true);
    setInfoText(
      translate(
        lang,
        comment && comment.length > 0 ? comment : 'No comments yet',
      ),
    );
  };
  const openImages = (images: string[]) => {
    setAsPhotoModalVisible(true);
    setAssessPhotos(
      images.map(image => {
        return {
          original: image,
          thumbnail: image,
        };
      }),
    );
  };
  const removeLineSpat = (spat_id: number) => {
    const y = window.confirm(
      translate(lang, 'Are you sure to delete this data?'),
    );
    if (!y) return;
    setLoading(true);
    sendSingleRequest(
      { spat_id },
      'DELETE',
      `api/farm/line/line-spat/${spat_id}`,
      true,
    ).then(r => {
      if (r.status) {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, 'Deleted successfully'),
          }),
        );
        loadData();
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, r.data?.message ?? 'Something went wrong'),
          }),
        );
        setLoading(false);
      }
    });
  };
  const spatColumns = useMemo(() => {
    let res = [
      ...columns.map(x => ({ ...x, title: translate(lang, x.title) })),
    ];
    res.push(
      {
        key: 'comment',
        dataIndex: 'comment',
        title: translate(lang, 'Comment'),
        render: (x: string) => (
          <div className='btn__modal' onClick={openInfo.bind(this, x)}>
            View
          </div>
        ),
      },
      {
        key: 'images',
        dataIndex: 'images',
        title: translate(lang, 'Images'),
        render: (x: any) =>
          x && x.length > 0 ? (
            <div className='btn__modal' onClick={openImages.bind(this, x)}>
              {translate(lang, 'View')}
            </div>
          ) : (
            <span></span>
          ),
      },
      {
        key: 'del_btn',
        dataIndex: '',
        title: translate(lang, 'Action'),
        render: (x: any) => (
          <Button
            color='red'
            size={0}
            width='small'
            type='fill'
            className='ml-7'
            onClick={removeLineSpat.bind(this, x.id)}
          >
            {translate(lang, 'Delete')}
          </Button>
        ),
      },
      {
        key: 'right',
        dataIndex: '',
        title: '',
        render: () => <span></span>,
      },
    );
    return res;
  }, [columns, spats]);

  useEffect(() => loadData(), [loadData]);

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        <div className='line-spats-page'>
          <div className='farms__header d-flex justify-content-between align-items-center'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              {translate(lang, 'Line Spats')}
            </Title>
          </div>
          <div className='mt-16'>
            <div className='mb-16 filter-options'>
              <div className='d-flex'>
                <Dropdown
                  label={translate(lang, 'Farm')}
                  defaultValue='0'
                  options={farmsOption}
                  onChange={v => selectFarm(v)}
                />
                {linesOption && (
                  <Dropdown
                    label={translate(lang, 'Line')}
                    className='ml-16'
                    defaultValue='0'
                    options={linesOption}
                    onChange={v => selectLine(v)}
                  />
                )}
              </div>
              <div className='d-flex'>
                <div className='mr-12'>
                  <Input
                    label={translate(lang, 'Spat count Min')}
                    type='number'
                    value={cntMin}
                    placeholder=''
                    onChange={e => setCntMin(e.target.value)}
                  />
                </div>
                <div className='ml-12'>
                  <Input
                    type='number'
                    label={translate(lang, 'Spat count Max')}
                    value={cntMax}
                    placeholder=''
                    onChange={e => setCntMax(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <div className='mt-24'>
                <Spinner />
              </div>
            ) : width > 768 ? (
              <div style={{ overflow: 'auto' }}>
                <Table
                  rowKey={'id'}
                  className='table table--isFarm'
                  pagination={pageOptions}
                  onChange={handlePageChange}
                  columns={spatColumns}
                  dataSource={spats}
                />
              </div>
            ) : (
              <div>
                {spats
                  .slice(
                    pageOptions.current - 1,
                    pageOptions.current + pageOptions.pageSize - 1,
                  )
                  .map(item => (
                    <MobileCard
                      key={item.id}
                      data={item}
                      onDelete={removeLineSpat}
                      onOpenInfo={openInfo}
                      onOpenImage={openImages}
                      lang={lang}
                    />
                  ))}
                <div className='paginator'>
                  <Pagination
                    total={pageOptions.total}
                    current={pageOptions.current}
                    pageSize={pageOptions.pageSize}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <ModalComponent
          title={translate(lang, 'Comment')}
          visible={infoShow}
          onCancel={() => setInfoShow(false)}
          type=''
          text={infoText}
        />
        {asPhotoModalVisible && (
          <Modal
            title={translate(lang, 'Photos')}
            centered
            visible={asPhotoModalVisible}
            onOk={() => setAsPhotoModalVisible(false)}
            onCancel={() => setAsPhotoModalVisible(false)}
            width={1000}
          >
            <ImageGallery items={assessPhotos} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default LineSpatsPage;
