import { useMemo, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Subtitle, Title } from '../../components/shared';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectAssessmentFields } from '../../store/automation/automation.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const LOCAL_PAGE_KEY = 'assessment_import_headers';

const assessmentOptions = [
  { id: 'farm_name', value: 'farm_name', label: 'Farm name' },
  { id: 'farm_number', value: 'farm_number', label: 'Farm number' },
  { id: 'line_name', value: 'line_name', label: 'Line name' },
  { id: 'line_length', value: 'line_length', label: 'Line length' },
  { id: 'backbone', value: 'backbone', label: 'Backbone length' },
  { id: 'season_name', value: 'season_name', label: 'Season' },
  { id: 'date_seed', value: 'date_seed', label: 'Date seeded' },
  { id: 'spat_source', value: 'spat_source', label: 'Spat Source' },
  { id: 'spat_amount', value: 'spat_amount', label: 'Spat amount' },
  { id: 'drop', value: 'drop', label: 'Drop' },
  { id: 'submersion', value: 'submersion', label: 'Submersion' },
  { id: 'spacing', value: 'spacing', label: 'Spacing' },
  { id: 'floats', value: 'floats', label: 'Floats' },
  { id: 'assessment_date', value: 'assessment_date', label: 'Assessment Date' },
  {
    id: 'planned_date_harvest',
    value: 'planned_date_harvest',
    label: 'Planned harvest date',
  },
  { id: 'size_min', value: 'size_min', label: 'Size.Min' },
  { id: 'size_max', value: 'size_max', label: 'Size.Max' },
  { id: 'size_avg', value: 'size_avg', label: 'Size.Avg' },
  { id: 'comment', value: 'comment', label: 'Comment' },
];

const uploadExcel = async (
  file: any,
  withFields = false,
  fields: any = null,
) => {
  try {
    const Authorization = {
      Authorization: `Bearer ${localStorage.getItem('marine-farm')}`,
    };
    const formData = new FormData();
    if (withFields && !!fields) {
      formData.append('with_fields', '1');
      for (let k in fields) {
        formData.append(k, fields[k]);
      }
    }
    formData.append('excel', file);

    return axios.post(
      `${process.env.REACT_APP_API_URL}api/import/assessment`,
      formData,
      {
        headers: {
          ...Authorization,
          Accept: '*',
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

interface IResult {
  total: number;
  failed: number;
  data: Array<any>;
}

const ImportAssessmentsPage = () => {
  const dispatch = useDispatch<any>();
  const customFields = useSelector(selectAssessmentFields);
  const lang = useSelector(selectLang);

  const [result, setResult] = useState<IResult>();
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [headers, setHeaders] = useState<string[]>();
  const [fields, setFields] = useState<any>();
  const [customValues, setCustomValues] = useState<any>();

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );
  const onFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
  };
  const submitFile = () => {
    if (!file) {
      showError('Please select a file');
      return;
    }
    setDisabled(true);
    uploadExcel(file, false)
      .then(res => {
        setDisabled(false);
        setHeaders(res.data);
        let saved: any = JSON.parse(
          localStorage.getItem(LOCAL_PAGE_KEY) ?? '{}',
        );
        for (let k in saved) {
          if (!res.data.includes(saved[k])) saved[k] = null;
        }
        setFields((prv: any) => (prv ? { ...prv, ...saved } : saved));
      })
      .catch(err => {
        setDisabled(false);
        showError(err?.message ?? 'Failed to upload');
      });
  };
  const getFieldKey = (val: string) => {
    for (let k in fields) {
      if (fields[k] === val) {
        return assessmentOptions.find(x => x.value === k)?.label;
      }
    }
    for (let k in customValues) {
      if (customValues[k] === val) {
        return customFields.find(x => x.id === Number(k))?.name;
      }
    }
    return '';
  };
  const handleUpdateField = (key: string, val: string) => {
    let tmp = { ...fields };
    for (let k in tmp) if (tmp[k] === val) tmp[k] = '';

    if (key && isNaN(+key)) tmp[key] = val;
    setFields(tmp);

    localStorage.setItem(LOCAL_PAGE_KEY, JSON.stringify(tmp));

    tmp = { ...customValues };
    for (let k in tmp) if (tmp[k] === val) tmp[k] = '';

    if (key && !isNaN(+key)) tmp[key] = val;
    setCustomValues(tmp);
  };
  const submitData = () => {
    if (!file) {
      showError('Please select a file');
      return;
    }
    if (!fields) {
      showError('Please select fields');
      return;
    }
    if (!fields.farm_name && !fields.farm_number) {
      showError('Farm name or farm number should be specified');
      return;
    }
    if (!fields.line_name) {
      showError('Line name should be specified');
      return;
    }
    if (!fields.assessment_date) {
      showError('Assessment date should be specified');
      return;
    }
    if (!fields.planned_date_harvest) {
      showError('Planned date harvest should be specified');
      return;
    }
    if (!fields.size_min || !fields.size_max || !fields.size_avg) {
      showError('Size.Min, Size.Max and Size.Avg should be specified');
      return;
    }
    setDisabled(true);
    uploadExcel(file, true, {
      ...fields,
      custom_fields: JSON.stringify(customValues),
    })
      .then(res => {
        setDisabled(false);
        setResult(res.data);
      })
      .catch(err => {
        setDisabled(false);
        showError(err?.message ?? 'Failed to import');
      });
  };

  const extractKeys = useMemo(() => {
    let res: string[] = [];
    if (!result) return res;
    result.data.forEach(x => res.push(...Object.keys(x)));
    res = res.filter((v, i, a) => a.indexOf(v) === i);
    return res;
  }, [result]);

  const assessOptions = useMemo(
    () => [
      { id: '-', value: '', label: '(Empty)' },
      ...assessmentOptions.filter(x => !fields || !fields[x.value]),
      ...customFields
        .filter(x => !customValues || !customValues[x.id])
        .map(x => ({
          id: x.id.toString(),
          value: x.id.toString(),
          label: x.name,
        })),
    ],
    [assessmentOptions, fields],
  );

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='pos-relative'>
        <div className='container pt-32 pb-32'>
          <Title size={5} color='black-3' align='default' fontWeight={700}>
            {translate(lang, 'Upload Assessments Excel file')}
          </Title>
          <div className='white-card mb-17 w-100'>
            <div className='d-flex'>
              <div className='mr-17'>
                <input
                  type='file'
                  className='input'
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  onChange={onFileChange}
                />
              </div>
              <div>
                <Button
                  width={'wide'}
                  size={2}
                  type='fill'
                  color='green'
                  className='ml-16'
                  disabled={disabled}
                  onClick={submitFile}
                >
                  {translate(lang, 'Import')}
                </Button>
              </div>
            </div>
            <div className='mt-17 mb-17'>
              {!!headers && (
                <>
                  <div className='d-section pl-7 pr-7'>
                    {headers.map((x, i) => (
                      <div key={i} className='w-45 mb-17'>
                        <Dropdown
                          label={x}
                          options={assessOptions}
                          value={getFieldKey(x)}
                          onChange={v => handleUpdateField(v, x)}
                          showSearch={true}
                        />
                      </div>
                    ))}
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      width='wide'
                      size={2}
                      type='fill'
                      color='green'
                      className='mt-17 w-45'
                      disabled={disabled}
                      onClick={submitData}
                    >
                      {translate(lang, 'OK')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          {result && (
            <div className='white-card mb-17 w-100'>
              <div>
                <div className='info-box'>Total: {result.total}</div>
                <div className='info-box'>
                  Success: {result.total - result.failed}
                </div>
                <div className='info-box'>Failed: {result.failed}</div>
              </div>
              <div className='mt-17'>
                <Subtitle
                  className='mb-17'
                  size={5}
                  color='black-3'
                  align='default'
                  fontWeight={700}
                >
                  Failed data
                </Subtitle>
                <div className='overflow-auto'>
                  <table className='ant-table w-100'>
                    <thead className='ant-table-thead'>
                      <tr>
                        {extractKeys.map((x, i) => (
                          <th className='ant-table-cell' key={i}>
                            <span>{x}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='ant-table-tbody'>
                      {result.data.map((row, i) => (
                        <tr key={i}>
                          {extractKeys.map((k, j) => (
                            <td className='ant-table-cell' key={`${i}-${j}`}>
                              <span>{row[k]}</span>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportAssessmentsPage;
