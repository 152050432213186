import React, {useState} from 'react';
import { Row, Col } from 'antd';
import { Modal, Tooltip } from 'antd';
import { Button, CloseIcon, MinusIcon, PlusIcon, Subtitle } from '../shared';

interface FluspySectionsModalProps {
    bucketsX: number; // Number of buckets horizontally
    bucketsY: number; // Number of buckets vertically
    divisions: number; // Number of divisions inside each bucket
    onCancel: () => void;
}

const FluspySectionsModal: React.FC<FluspySectionsModalProps> = ({ bucketsX, bucketsY, divisions, onCancel }) => {

    const [zoom, setZoom] = useState(1);

    const onNodeViewWheel = (e: React.WheelEvent<HTMLDivElement>) => {
      if (!e.shiftKey) return;
      const z = e.deltaY < 0 ? zoom - 0.1 : zoom + 0.1;
      setZoom(Math.max(0.1, z));
    };
  const renderBuckets = () => {
    const buckets = [];
    for (let y = 0; y <= bucketsY; y++) {
      buckets.push(
        <Row key={`row-${y}`} gutter={[8, 8]} justify="center" >
          {Array.from({ length: bucketsX - 1 }).map((_, x) => (
            <Col key={`col-${x}`} span={24 / bucketsX} style={{height:'fit-content'}}>
              <div className="bucket" style={{ height: `fit-content` }}>
                {Array.from({ length: divisions }).map((_, i) => (
                  <div key={`division-${i}`} className="division" />
                ))}
              </div>
            </Col>
          ))}
        </Row>
      );
    }
    return buckets;
  };

  return (
    <>
      <Modal
        visible={true}
        onCancel={onCancel}
        closable
        closeIcon={<CloseIcon />}
        width='full'
        style={{ maxWidth: 1440 }}
        footer={null}
        >
            <div className='d-flex align-items-center mb-16'>
            <Tooltip
                className='d-flex align-items-center'
                title='Use Shift + Scroll to zoom'
            >
                <div
                className='zoom-icon-btn'
                onClick={() => setZoom(Math.max(0.1, zoom - 0.1))}
                >
                <MinusIcon />
                </div>
                <div className='ml-7 mr-7'>
                <Subtitle
                    color='black-2'
                    align='center'
                    size={0}
                    fontWeight={600}
                >
                    {zoom.toFixed(1)}
                </Subtitle>
                </div>
                <div className='zoom-icon-btn' onClick={() => setZoom(zoom + 0.1)}>
                <PlusIcon />
                </div>
            </Tooltip>
            </div>
            <div className='node-view' onWheel={onNodeViewWheel} style={{ zoom }}>
                <div className="bucket-grid">{renderBuckets()}</div>
            </div>
        </Modal>
    </>
  );
};

export default FluspySectionsModal;

