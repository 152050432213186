import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { useWidth } from '../../util/useWidth';
import { Modal } from 'antd';
import { Button, CloseIcon, Input, Subtitle } from '../shared';
import PicturesWall from '../shared/pictures-wall/PicturesWall';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

interface Props {
  visible: boolean;
  title: string;
  lineId: number;
  onLoadLine: () => void;
  onClose: () => void;
}

const LineSpatModal = ({
  visible,
  title,
  lineId,
  onLoadLine,
  onClose,
}: Props) => {
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [spatCnt, setSpatCnt] = useState('');
  const [comment, setComment] = useState('');
  const [images, setImages] = useState([]);

  const addConfirm = () => {
    if (spatCnt.length <= 0) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, 'Please fill the value'),
        }),
      );
      return;
    }
    const form = {
      line_id: lineId,
      spat_count: spatCnt,
      comment,
      images,
    };
    setDisabled(true);
    sendSingleRequest(form, 'POST', `api/farm/line/line-spat`, true).then(r => {
      if (r.status) {
        onLoadLine();
        onClose();
      } else {
        setDisabled(false);
        dispatch(
          showFeedback({
            isMessageModal: true,
            type: 'error',
            message: translate(lang, r.data?.message ?? 'Something went wrong'),
          }),
        );
      }
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={700}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        <div className='pt-16'>
          <div className='pb-24'>
            <Input
              label={translate(lang, 'Spat count')}
              placeholder=''
              type='number'
              value={spatCnt}
              onChange={e => setSpatCnt(e.target.value)}
            />
          </div>
          <div className='pb-24'>
            <Input
              label={translate(lang, 'Comment')}
              placeholder=''
              type='textarea'
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </div>
          <div className='pb-24'>
            <PicturesWall
              label={translate(lang, 'Images')}
              fileList={images}
              handleChangeImages={img => setImages(img as any)}
            />
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          className='mr-32'
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='red'
          disabled={disabled}
          onClick={onClose}
        >
          {translate(lang, 'Close')}
        </Button>
        <Button
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='green'
          disabled={disabled}
          onClick={addConfirm}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default LineSpatModal;
