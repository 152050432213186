import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { IXeroInvoice } from '../../entities/subscription.entities';
import { formatNumber, numberToMoneyStr } from '../../entities/util-functions';
import { showFeedback } from '../../store/ui/ui.actions';
import { Button, Dropdown, Subtitle } from '../shared';
import { Link } from 'react-router-dom';
import { loadXeroContacts } from '../../store/subscription/subscription.actions';
import { updateUserMeta } from '../../store/auth/auth.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectProfileByFarmID,
  SelectUserMeta,
  SelectXeroContactsByFarmID,
} from '../../store/extra/extra.selector';

interface Props {
  farmID?: number;
  invoice: IXeroInvoice;
  onClose?: (d: any) => void;
  showButton: boolean;
}

const GrowerInvoiceCreateView = ({
  farmID,
  invoice,
  onClose,
  showButton,
}: Props) => {
  const dispatch = useDispatch<any>();

  const profile = SelectProfileByFarmID(farmID);
  const contacts = SelectXeroContactsByFarmID(farmID);
  const ContactID = SelectUserMeta(farmID)?.xero_contact_id;
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState<string>();
  const [connID, setConnID] = useState(
    !profile || !profile.xero_connects || profile.xero_connects.length <= 0
      ? null
      : profile.xero_connects[0].id,
  );
  const [contID, setContID] = useState(ContactID);

  const contOptions = useMemo(
    () =>
      !connID || !contacts || contacts.length <= 0
        ? null
        : contacts
            .find(x => x.connect.id === connID)
            ?.contacts.map(x => ({
              id: x.ContactID,
              value: x.ContactID,
              label: x.Name,
            })),
    [connID, contacts],
  );
  const connections =
    profile?.xero_connects?.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.client_id,
    })) ?? [];
  const subTotal = invoice.line_items.reduce(
    (p, c) => p + c.unit_price * c.quantity,
    0,
  );
  const taxFee =
    !profile?.account?.include_tax || !profile.account.tax
      ? 0
      : profile.account.tax.type === 'FIXED'
      ? profile.account.tax.value
      : (subTotal * profile.account.tax.value) / 100;
  const total = subTotal + taxFee;

  const updateContactID = (v: string) => {
    setContID(v);
    sendSingleRequest(
      { xero_contact_id: v },
      'POST',
      `api/user-meta`,
      true,
    ).then(() => dispatch(updateUserMeta()));
  };

  const createInvoice = () => {
    if (!contID) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, 'Please select a contact'),
        }),
      );
      return;
    }
    let data = { ...invoice, contact_id: contID };
    if (connID && connections.length > 1) {
      (data as any).account_xero_id = connID;
    }
    if (profile?.account?.include_tax) {
      data.account_code = profile.account.xero_tax_code;
    }
    setDisabled(true);
    sendSingleRequest(data, 'POST', 'api/xero-data/invoice', true).then(res => {
      setDisabled(false);
      if (res.status) {
        dispatch(
          showFeedback({
            isMessageModal: true,
            type: 'success',
            message: translate(lang, 'Invoice created successfully'),
          }),
        );
        setInvoiceLink(
          `https://go.xero.com/Accounts${
            invoice.type === 'ACCREC' ? 'Receivable' : 'Payable'
          }/Edit.aspx?InvoiceID=${res.data.InvoiceID}`,
        );
      } else {
        dispatch(
          showFeedback({
            isMessageModal: true,
            type: 'error',
            message: translate(
              lang,
              res.data?.message ?? 'Failed to create invoice',
            ),
          }),
        );
      }
    });
  };

  useEffect(() => {
    if (profile?.xero_connects && profile.xero_connects.length > 0) {
      dispatch(loadXeroContacts());
    }
  }, [profile?.xero_connects]);

  return (
    <>
      <div className='d-flex align-items-center mb-17'>
        <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
          {translate(lang, 'Does this look correct?')}
        </Subtitle>
      </div>
      <div className='section mb-0'>
        <div className='item-row strong'>
          <div className='item-cell'>
            <span>
              {translate(lang, 'Reference')}: {invoice.reference}
            </span>
          </div>
          <div className='item-cell'>
            {!!showButton && connections.length > 1 && (
              <Dropdown
                label={translate(lang, 'Xero connection')}
                className='mr-7'
                options={connections}
                value={connID?.toString()}
                onChange={v => setConnID(Number(v))}
              />
            )}
          </div>
          <div className='item-cell'>
            {!!profile && !!showButton && (
              <Dropdown
                options={contOptions ?? []}
                label={translate(lang, 'To')}
                value={contID ?? ''}
                onChange={v => updateContactID(v)}
                showSearch={true}
              />
            )}
          </div>
        </div>
      </div>
      <div className='section mb-0'>
        <div className='item-row strong'>
          <div className='item-cell'>{translate(lang, 'Item')}</div>
          <div className='item-cell'>{translate(lang, 'Description')}</div>
          <div className='item-cell'>{translate(lang, 'Quantity')}</div>
          <div className='item-cell'>{translate(lang, 'Unit Price')} ($)</div>
          <div className='item-cell'>{translate(lang, 'Amount')} ($)</div>
        </div>
        {invoice.line_items.map((x, i) => (
          <div className='item-row' key={i}>
            <div className='item-cell'>{x.item_name}</div>
            <div className='item-cell'>{x.description}</div>
            <div className='item-cell'>{formatNumber(x.quantity)}</div>
            <div className='item-cell'>{numberToMoneyStr(x.unit_price)}</div>
            <div className='item-cell'>
              {numberToMoneyStr(x.unit_price * x.quantity)}
            </div>
          </div>
        ))}
      </div>
      {!!profile?.account?.include_tax && (
        <>
          <div className='item-row strong mt-3'>
            <div className='item-cell mb-0'></div>
            <div className='item-cell mb-0'>{translate(lang, 'Subtotal')}</div>
            <div className='item-cell mb-0'>$ {numberToMoneyStr(subTotal)}</div>
          </div>
          <div className='item-row strong mt-0'>
            <div className='item-cell'></div>
            <div className='item-cell'>{`${profile.account.tax?.label} (${profile.account.tax?.value}%)`}</div>
            <div className='item-cell'>$ {numberToMoneyStr(taxFee)}</div>
          </div>
        </>
      )}
      <div className='item-row strong mb-32' style={{ fontSize: '17px' }}>
        <div className='item-cell'></div>
        <div className='item-cell'>{translate(lang, 'Total')}</div>
        <div className='item-cell'>$ {numberToMoneyStr(total)}</div>
      </div>
      {showButton && (
        <div className='modal-button d-flex justify-content-end align-items-center'>
          {!!profile && (
            <>
              {!profile?.xero_connects || profile.xero_connects.length <= 0 ? (
                <Link
                  className='button button--2 button--green button--small button--bordered'
                  to={'/profile/xero'}
                >
                  {translate(lang, 'Connect Xero')}
                </Link>
              ) : !invoiceLink ? (
                <Button
                  className='rsp-btn'
                  width='small'
                  size={2}
                  type='bordered'
                  color='green'
                  onClick={createInvoice}
                  disabled={disabled}
                >
                  {translate(
                    lang,
                    invoice.type === 'ACCPAY'
                      ? 'Create an expense in Xero'
                      : 'Create an expense invoice in Xero',
                  )}
                </Button>
              ) : (
                <a
                  href={invoiceLink}
                  target='_blank'
                  className='button button--2 button--green button--small button--bordered'
                >
                  {translate(
                    lang,
                    invoice.type === 'ACCPAY'
                      ? 'View expense in Xero'
                      : 'View expense invoice in Xero',
                  )}
                </a>
              )}
            </>
          )}
          <Button
            width='small'
            size={2}
            type='fill'
            color='red'
            className='rsp-btn ml-16'
            onClick={onClose}
            disabled={disabled}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      )}
    </>
  );
};

export default GrowerInvoiceCreateView;
