import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CheckboxButton,
  Dropdown,
  Input,
  Title,
} from '../../components/shared';
import {
  IBoatTime,
  IFactoryCost,
  ILevyFee,
} from '../../entities/user.entities';
import { updateAccountSetting } from '../../store/auth/auth.actions';
import { loadXeroAccounts } from '../../store/subscription/subscription.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { useWidth } from '../../util/useWidth';
import { selectXeroAccounts } from '../../store/subscription/subscription.selector';
import {
  selectAccount,
  selectXeroIsConnected,
} from '../../store/auth/auth.selector';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectBoats } from '../../store/users/users.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface TaxProps {
  tax: { type: string; label: string; value: number };
  setTax: (d: any) => void;
  taxCode: string;
  setTaxCode: (d: any) => void;
}

const TaxView = ({ tax, setTax, taxCode, setTaxCode }: TaxProps) => {
  const lang = useSelector(selectLang);
  const xeroAccounts = useSelector(selectXeroAccounts);
  const accounts = xeroAccounts.length <= 0 ? null : xeroAccounts[0].accounts;
  const options = accounts?.map(x => ({
    id: x.Code,
    value: x.Code,
    label: `${x.Code} - ${x.Name} (${x.TaxRate}%)`,
  }));
  const xeroConnected = !!useSelector(selectXeroIsConnected);

  const updateTax = (key: 'label' | 'value', val: any) => {
    if (key === 'value') {
      val = Number(val);
    }
    setTax({ ...tax, [key]: val });
  };
  const updateTaxCode = (code: string) => {
    const acc = accounts?.find(x => x.Code === code);
    if (acc) {
      setTax({ ...tax, label: acc.Name, value: Number(acc.TaxRate) });
    }
    setTaxCode(code);
  };

  return xeroConnected ? (
    <div className='mt-7 d-flex justify-content-between'>
      <Dropdown
        label={translate(lang, '_xero_tax_select')}
        value={taxCode}
        options={options ?? []}
        onChange={v => updateTaxCode(v)}
      />
    </div>
  ) : (
    <div className='mt-7 d-flex justify-content-between'>
      <Input
        label={translate(lang, 'Label')}
        type='text'
        value={tax.label}
        onChange={e => updateTax('label', e.target.value)}
      />
      <div className='ml-17 w-100'>
        <Input
          label={translate(lang, 'Value')}
          type='number'
          value={tax.value.toString()}
          onChange={e => updateTax('value', e.target.value)}
          unit={tax.type === 'PERCENT' ? '%' : '$'}
        />
      </div>
    </div>
  );
};

const FreqOption = [
  { id: 'M', label: 'Monthly', value: 'M' },
  { id: 'Y', label: 'Annually', value: 'Y' },
];

const RoundOption = [
  { id: '15', label: '15 mins', value: '15' },
  { id: '30', label: '30 mins', value: '30' },
  { id: '60', label: '1 h', value: '60' },
];

const LevyTypeOption = [
  { id: '$', label: '$', value: '$' },
  { id: '%', label: '%', value: '%' },
];

const FinanceSettings = () => {
  const width = useWidth();
  const dispatch = useDispatch<any>();

  const settings = useSelector(selectAccount);
  const farmsData = useSelector(selectFarmsData);
  const boatsData = useSelector(selectBoats);
  const lang = useSelector(selectLang);

  const [levyFees, setLevyFees] = useState<ILevyFee[]>([]);
  const [boatTimes, setBoatTimes] = useState<IBoatTime[]>([]);
  const [factoryCosts, setFactoryCosts] = useState<IFactoryCost[]>([]);
  const [includeTax, setIncludeTax] = useState(false);
  const [tax, setTax] = useState({
    type: 'PERCENT',
    label: '15% GST',
    value: 15,
  });
  const [taxCode, setTaxCode] = useState<string>();
  const [disable, setDisable] = useState(false);
  const [harLevy, setHarLevy] = useState<number>();

  const selectLevyFarm = (i: number, farm_id: string) => {
    let tmp = levyFees ? [...levyFees] : [];
    if (i >= tmp.length) return;
    tmp[i].farm_id = Number(farm_id);
    setLevyFees(tmp);
  };
  const changeCostValue = (type: 'L' | 'B' | 'F', i: number, v: string) => {
    if (type === 'L') {
      let tmp = levyFees ? [...levyFees] : [];
      if (i >= tmp.length) return;
      tmp[i].fee = Number(v);
      setLevyFees(tmp);
    } else if (type === 'B') {
      let tmp = boatTimes ? [...boatTimes] : [];
      if (i >= tmp.length) return;
      tmp[i].cost = Number(v);
      setBoatTimes(tmp);
    } else {
      let tmp = factoryCosts ? [...factoryCosts] : [];
      if (i >= tmp.length) return;
      tmp[i].unit_ton_price = Number(v);
      setFactoryCosts(tmp);
    }
  };
  const updateFrequency = (i: number, v: string) => {
    let tmp = levyFees ? [...levyFees] : [];
    tmp[i].frequency = v === 'M' ? 'M' : 'Y';
    setLevyFees(tmp);
  };
  const selectTimeBoat = (i: number, v: string) => {
    let tmp = boatTimes ? [...boatTimes] : [];
    tmp[i].boat_id = Number(v);
    setBoatTimes(tmp);
  };
  const updateRounding = (i: number, v: string) => {
    let tmp = boatTimes ? [...boatTimes] : [];
    tmp[i].rounding = Number(v) as any;
    setBoatTimes(tmp);
  };
  const addNewItem = (type: 'L' | 'B' | 'F') => {
    if (type === 'L') {
      let tmp = levyFees ? [...levyFees] : [];
      tmp.push({ farm_id: 0, fee: 0, frequency: 'M', type: '$' });
      setLevyFees(tmp);
    } else if (type === 'B') {
      let tmp = boatTimes ? [...boatTimes] : [];
      tmp.push({ boat_id: 0, cost: 0, rounding: 15 });
      setBoatTimes(tmp);
    } else {
      let tmp = factoryCosts ? [...factoryCosts] : [];
      tmp.push({ quality: '', unit_ton_price: 0 });
      setFactoryCosts(tmp);
    }
  };
  const deleteItem = (type: 'L' | 'B' | 'F', i: number) => {
    if (type === 'L') {
      let tmp = levyFees ? [...levyFees] : [];
      if (i >= tmp.length) return;
      tmp.splice(i, 1);
      setLevyFees(tmp);
    } else if (type === 'B') {
      let tmp = boatTimes ? [...boatTimes] : [];
      if (i >= tmp.length) return;
      tmp.splice(i, 1);
      setBoatTimes(tmp);
    } else {
      let tmp = factoryCosts ? [...factoryCosts] : [];
      if (i >= tmp.length) return;
      tmp.splice(i, 1);
      setFactoryCosts(tmp);
    }
  };
  const updateQuality = (i: number, v: string) => {
    let tmp = factoryCosts ? [...factoryCosts] : [];
    if (i >= tmp.length) return;
    tmp[i].quality = v;
    setFactoryCosts(tmp);
  };
  const updateLevyType = (i: number, v: string) => {
    let tmp = levyFees ? [...levyFees] : [];
    tmp[i].type = v as any;
    setLevyFees(tmp);
  };

  useEffect(() => dispatch(loadXeroAccounts()), [dispatch]);

  useEffect(() => {
    if (settings?.levy_fees && settings.levy_fees.length > 0) {
      setLevyFees(settings.levy_fees);
    } else {
      setLevyFees([{ farm_id: 0, fee: 0, frequency: 'M', type: '$' }]);
    }
    if (settings?.boat_times && settings.boat_times.length > 0) {
      setBoatTimes(settings.boat_times);
    } else {
      setBoatTimes([{ boat_id: 0, cost: 0, rounding: 15 }]);
    }
    if (settings?.factory_costs && settings.factory_costs.length > 0) {
      setFactoryCosts(settings.factory_costs);
    } else {
      setFactoryCosts([{ quality: '', unit_ton_price: 0 }]);
    }
    if (!!settings?.tax) {
      setTax(settings.tax);
    }
    if (settings?.xero_tax_code) {
      setTaxCode(settings.xero_tax_code);
    }
    if (settings?.harvest_levy) {
      setHarLevy(settings.harvest_levy);
    }
    setIncludeTax(settings?.include_tax ?? false);

    return () => {};
  }, [settings]);

  const farmsOption = [
    { id: '0', value: '0', label: translate(lang, 'Select farm') },
    ...farmsData.map(f => ({
      id: f.id.toString(),
      label: f.name,
      value: f.id.toString(),
    })),
  ];
  const boatsOption = [
    { id: '0', value: '0', label: translate(lang, 'Select boat') },
    ...boatsData
      .filter(x => (boatTimes?.findIndex(b => b.boat_id === x.id) ?? -1) < 0)
      .map(b => ({
        id: b.id.toString(),
        label: b.name,
        value: b.id.toString(),
      })),
  ];

  const saveSettings = () => {
    const levy_fees = levyFees?.filter(x => x.farm_id);
    const boat_times = boatTimes?.filter(x => x.boat_id);
    const factory_costs = factoryCosts?.filter(
      x => x.quality && x.quality.length > 0 && x.unit_ton_price > 0,
    );
    setDisable(true);
    let data: any = {
      levy_fees,
      boat_times,
      factory_costs,
      include_tax: includeTax,
      tax,
    };
    if (taxCode) {
      data.xero_tax_code = taxCode;
    }
    if (harLevy) {
      data.harvest_levy = harLevy;
    }
    dispatch(updateAccountSetting(data)).then((res: any) => {
      setDisable(false);
      dispatch(
        showFeedback({
          isMessage: true,
          type: res === true ? 'success' : 'error',
          message: translate(
            lang,
            res === true
              ? 'Saved successfully'
              : res?.message ?? 'Failed to update',
          ),
        }),
      );
    });
  };

  return (
    <div className='mt-32'>
      <div className='mb-16 mt-27 d-flex align-items-baseline'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Levy fees')}
        </Title>
        <Button
          className='ml-32'
          color='blue'
          size={5}
          width='small'
          type='fill'
          onClick={() => addNewItem('L')}
        >
          {translate(lang, 'Add new')}
        </Button>
      </div>
      <div className='ml-21'>
        {levyFees?.map((levy, i) => (
          <div
            className='d-flex align-items-baseline justify-content-between mb-17'
            key={i}
          >
            <Dropdown
              label={translate(lang, 'Select farm')}
              value={
                levy.farm_id
                  ? farmsData.find(x => x.id === levy.farm_id)?.name
                  : '0'
              }
              options={farmsOption}
              onChange={v => selectLevyFarm(i, v)}
            />
            <div className='ml-17 mr-17'>
              <Input
                type='number'
                label={`Fee (${levy.type ?? '$'})`}
                value={levy.fee.toString()}
                onChange={e => changeCostValue('L', i, e.target.value)}
              />
            </div>
            <Dropdown
              className='mr-17'
              label='$ / %'
              value={levy.type ?? '$'}
              options={LevyTypeOption}
              onChange={v => updateLevyType(i, v)}
            />
            <Dropdown
              className='mr-17'
              label={translate(lang, 'Frequency')}
              value={levy.frequency}
              options={FreqOption}
              onChange={v => updateFrequency(i, v)}
            />
            <div className='ml-17 d-flex align-items-center'>
              <Button
                color='red'
                size={5}
                width='small'
                type='fill'
                onClick={() => deleteItem('L', i)}
              >
                {translate(lang, 'Delete')}
              </Button>
            </div>
          </div>
        ))}
        <div className='mb-17'>
          <Input
            type='number'
            label={translate(lang, 'Harvest levy per ton')}
            value={harLevy?.toString() ?? ''}
            onChange={e =>
              setHarLevy(
                e.target.value.length <= 0 ? undefined : Number(e.target.value),
              )
            }
            unit='$'
          />
        </div>
      </div>
      <div className='mb-16 mt-27 d-flex align-items-baseline'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Boat time')}
        </Title>
        <Button
          className='ml-32'
          color='blue'
          size={5}
          width='small'
          type='fill'
          onClick={() => addNewItem('B')}
        >
          {translate(lang, 'Add new')}
        </Button>
      </div>
      <div className='ml-21'>
        {boatTimes?.map((bt, i) => (
          <div
            className='d-flex align-items-baseline justify-content-between mb-17'
            key={i}
          >
            <Dropdown
              label={translate(lang, 'Boat name')}
              value={
                bt.boat_id
                  ? boatsData.find(x => x.id === bt.boat_id)?.name
                  : '0'
              }
              options={boatsOption}
              onChange={v => selectTimeBoat(i, v)}
            />
            <div className='ml-17 mr-17'>
              <Input
                type='number'
                label={`${translate(lang, 'Cost per hour')} ($)`}
                value={bt.cost.toString()}
                onChange={e => changeCostValue('B', i, e.target.value)}
              />
            </div>
            <Dropdown
              className='mr-17'
              label={translate(lang, 'Rounding')}
              value={bt.rounding.toString()}
              options={RoundOption}
              onChange={v => updateRounding(i, v)}
            />
            <div className='ml-17 d-flex align-items-center'>
              <Button
                color='red'
                size={5}
                width='small'
                type='fill'
                onClick={() => deleteItem('B', i)}
              >
                {translate(lang, 'Delete')}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className='mb-16 mt-27 d-flex align-items-baseline'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Factory cost per ton')}
        </Title>
        <Button
          className='ml-32'
          color='blue'
          size={5}
          width='small'
          type='fill'
          onClick={() => addNewItem('F')}
        >
          {translate(lang, 'Add new')}
        </Button>
      </div>
      <div className='ml-21'>
        {factoryCosts?.map((fc, i) => (
          <div
            className='d-flex align-items-baseline justify-content-between mb-17'
            key={i}
          >
            <div className='mr-17'>
              <Input
                type='text'
                label={translate(lang, 'Quality')}
                value={fc.quality}
                onChange={e => updateQuality(i, e.target.value)}
              />
            </div>
            <div className='ml-17 mr-17'>
              <Input
                type='number'
                label={translate(lang, 'Price per ton')}
                value={fc.unit_ton_price.toString()}
                onChange={e => changeCostValue('F', i, e.target.value)}
              />
            </div>
            <div className='ml-17 d-flex align-items-center'>
              <Button
                color='red'
                size={5}
                width='small'
                type='fill'
                onClick={() => deleteItem('F', i)}
              >
                {translate(lang, 'Delete')}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className='mt-32 mb-32'>
        <CheckboxButton
          label={translate(lang, 'Include tax')}
          checked={includeTax}
          onChange={e => setIncludeTax(e.target.checked)}
        />
        {!!includeTax && (
          <TaxView
            tax={tax}
            setTax={setTax}
            taxCode={taxCode ?? ''}
            setTaxCode={setTaxCode}
          />
        )}
      </div>
      <div className='mt-16 d-flex'>
        <Button
          color='blue'
          size={1}
          width={width < 769 ? 'wide' : 'small'}
          onClick={saveSettings}
          type='fill'
          disabled={disable}
        >
          {translate(lang, 'Save')}
        </Button>
      </div>
    </div>
  );
};

export default FinanceSettings;
