import { Modal } from 'antd';
import { CloseIcon, Subtitle } from '../../shared';

interface Props {
  visible: boolean;
  title?: string;
  data?: any;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  onlyView?: boolean;
  warningMessage?: string;
}

const MusselBulkHarvestModal = ({
  visible,
  title,
  data,
  onCancel,
  onConfirm,
  onlyView,
  warningMessage,
}: Props) => (
  <Modal
    visible={visible}
    onCancel={onCancel}
    footer={null}
    closable
    closeIcon={<CloseIcon />}
    width={650}
  >
    <div className='wrap'>
      <div className='d-flex align-items-center mb-32'>
        <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
          {title ?? 'Bulk mussel harvest'}
        </Subtitle>
      </div>
      <div className='harvest-modal'></div>
    </div>
  </Modal>
);

export default MusselBulkHarvestModal;
