import { ITaskAction, ITaskState } from './tasks.type';

const initialState: ITaskState = {
  tasks: [],
};

const utilsReducer = (
  state = initialState,
  action: ITaskAction,
): ITaskState => {
  switch (action.type) {
    case 'TASKS/SET_TASK_DATA': {
      return { ...state, tasks: action.payload };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default utilsReducer;
