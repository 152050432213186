import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { Button, Title } from '../../components/shared';
import { checkRolePermission } from '../../entities/util-functions';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IInvite {
  email: string;
  role: string;
  register_url: string;
}

const columns = [
  {
    key: 'email',
    title: 'Email',
    render: (x: IInvite) => <span>{x.email}</span>,
  },
  {
    key: 'role',
    title: 'Role',
    render: (x: IInvite) => <span>{x.role}</span>,
  },
  {
    key: 'url',
    title: 'Active link',
    render: (x: IInvite) => <span>{x.register_url}</span>,
  },
  {
    key: 'btn',
    title: '',
    render: (x: IInvite) => (
      <Button
        width='small'
        size={0}
        type='fill'
        color='blue'
        onClick={() => {
          navigator.clipboard.writeText(x.register_url);
          alert('Sing up link is copied');
        }}
      >
        Copy
      </Button>
    ),
  },
  {
    key: 'right',
    title: '',
    render: () => <></>,
  },
];

const InvitedUsers = () => {
  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);

  const [invites, setInvites] = useState<IInvite[]>();

  useEffect(() => {
    const loadInvites = async () => {
      const res = await sendSingleRequest(
        {},
        'GET',
        'api/user/owner-pending-users',
        true,
      );
      if (res.status) setInvites(res.data);
    };
    if (
      checkRolePermission({ allowedRoles: ['owner', 'admin'] }, profile?.role)
    ) {
      loadInvites();
    }
  }, []);

  return checkRolePermission(
    { allowedRoles: ['owner', 'admin'] },
    profile?.role,
  ) ? (
    <div className='container'>
      <div className='users d-flex align-items-center'>
        <Title size={5} color='black' align='default' fontWeight={700}>
          {translate(lang, 'Pending Invited Users')}
        </Title>
      </div>
      <div className='mb-17'>
        <Table
          rowKey={'email'}
          className='table table--isFarm'
          pagination={false}
          columns={columns.map(x => ({
            ...x,
            title: translate(lang, x.title),
          }))}
          dataSource={invites}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default InvitedUsers;
