import { useDispatch, useSelector } from 'react-redux';
import { selectAccount } from '../../store/auth/auth.selector';
import { Modal } from 'antd';
import { Button, Input, Subtitle } from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import { selectSizedOysters } from '../../store/utils/utils.selector';
import { useState } from 'react';
import { showFeedback } from '../../store/ui/ui.actions';
import { loadUtilsData } from '../../store/utils/utils.actions';
import { sendSingleRequest } from '../../apis';
import { updateAccountSetting } from '../../store/auth/auth.actions';
import {
  calcOnGrowWastePercent,
  calcOysterPercent,
} from '../../lib/farm.helpers';
import './styles.scss';

interface IRow {
  id: string | number;
  name: string;
  value: number | undefined;
}

interface Props {
  visible: boolean;
  lineID: number;
  onClose: () => void;
}

const OysterLineProbModal = ({ visible, lineID, onClose }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const onGrowWaste = useSelector(selectAccount)?.oyster_crops;
  const oysterFields = useSelector(selectSizedOysters);

  const defaultValues = [
    {
      id: 'Grown ons',
      name: translate(lang, 'Grown ons'),
      value: calcOnGrowWastePercent(onGrowWaste, 'grow', lineID),
    },
    {
      id: 'Waste',
      name: translate(lang, 'Waste'),
      value: calcOnGrowWastePercent(onGrowWaste, 'waste', lineID),
    },
    ...oysterFields.map(x => ({
      id: x.id,
      name: x.name,
      value: calcOysterPercent(x, lineID),
    })),
  ];

  const [data, setData] = useState<IRow[]>(defaultValues);
  const [disabled, setDisabled] = useState(false);

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        message,
        isMessageModal: true,
      }),
    );
  const updateField = (id: number | string, value: string) => {
    const tmp = [...data];
    const i = tmp.findIndex(x => x.id === id);
    tmp[i].value = value.length <= 0 ? undefined : Number(value);
    setData(tmp);
  };
  const confirmClick = async () => {
    if (data.some(x => x.value === undefined || x.value === null)) {
      showError(translate(lang, 'Please enter probability'));
      return;
    }
    const sum = data.reduce((acc, x) => acc + (x.value || 0), 0);
    if (Math.abs(100 - sum) > 0.0001) {
      showError(translate(lang, 'All probability values should total to 100%'));
      return;
    }
    setDisabled(true);
    const utils = oysterFields.map(x => {
      const d = data.find(y => y.id === x.id);
      let json: any = x.json;
      if (!json || !json.lines) {
        json = { lines: { [lineID]: d?.value } };
      } else {
        json.lines[lineID] = d?.value;
      }
      return { ...x, json };
    });
    const res = await sendSingleRequest(utils, 'POST', 'api/utils/bulk', true);
    if (!res.status) {
      showError(res.data?.message ?? 'Server error');
    }
    let lines_grow_ons: any = onGrowWaste?.lines_grow_ons ?? {};
    lines_grow_ons[lineID] = data.find(x => x.id === 'Grown ons')?.value;
    let lines_waste: any = onGrowWaste?.lines_waste ?? {};
    lines_waste[lineID] = data.find(x => x.id === 'Waste')?.value;
    const oyster_crops = {
      ...onGrowWaste,
      lines_grow_ons,
      lines_waste,
    };
    const r = await dispatch(updateAccountSetting({ oyster_crops }));
    if (r !== true) {
      setDisabled(false);
      showError(r ?? 'Server error');
    } else {
      onClose();
      await dispatch(loadUtilsData());
    }
  };

  return (
    <Modal visible={visible} onCancel={onClose} footer={null}>
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Update line probabilities')}
          </Subtitle>
        </div>
        <div className='section-modal'>
          <div className='section'>
            <div className='item-row'>
              <div className='item-cell'>{translate(lang, 'Name')}</div>
              <div className='item-cell'>{translate(lang, 'Probability')}</div>
            </div>
            {data.map((row, i) => (
              <div className='item-row' key={i}>
                <div className='item-cell'>
                  <Input
                    label=''
                    placeholder={translate(lang, 'Name')}
                    type='text'
                    value={row.name}
                    disabled={true}
                  />
                </div>
                <div className='item-cell'>
                  <Input
                    label=''
                    placeholder={translate(lang, 'Probability')}
                    type='number'
                    value={row.value?.toString() ?? ''}
                    onChange={e => updateField(row.id, e.target.value)}
                    unit='%'
                  />
                </div>
              </div>
            ))}
            <div className='item-row'>
              <div className='item-cell'></div>
              <div className='item-cell'>
                {`${data.reduce((p, c) => p + (c.value ?? 0), 0)} %`}
              </div>
            </div>
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={confirmClick}
            disabled={disabled}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default OysterLineProbModal;
