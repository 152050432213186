import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';

interface ISingleAPIResponse {
  status: boolean;
  data: any;
}

(window as any).isRefreshingToken = false;
(window as any).lastAuthToken = '';
let glbResponse;

export const sendRequest = async (
  data: any,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  url: string,
  requireAuth = false,
) => {
  try {
    const Authorization = requireAuth
      ? {
          Authorization: `Bearer ${localStorage.getItem('marine-farm')}`,
        }
      : {};
    const req = {
      headers: {
        ...Authorization,
        Accept: '*',
      },
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
    };

    Object.assign(req, method === 'GET' ? { params: data } : { data });
    const response = await axios(req);

    if (response.status >= 200 && response.status < 299) {
      const resData = await response.data;
      return resData;
    }

    return false;
  } catch (e: any) {
    console.log(e);
    return e?.response?.data;
  }
};

export const sendSingleRequest = async (
  data: any,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  url: string,
  requireAuth = false,
): Promise<ISingleAPIResponse> => {
  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  for (let ws = 3; ws < 30; ws += 3) {
    try {
      await waitForRefreshToken();

      const Authorization = requireAuth
        ? {
            Authorization: `Bearer ${localStorage.getItem('marine-farm')}`,
          }
        : {};
      let req = {
        headers: {
          ...Authorization,
          Accept: '*',
        },
        method,
        url: `${process.env.REACT_APP_API_URL}${url}`,
      };

      Object.assign(req, method === 'GET' ? { params: data } : { data });
      let response = await axios(req);
      if (response.data.message === 'Unauthenticated.') {
        const r = await waitForRefreshToken();
        if (r && (window as any).lastAuthToken.length > 0) {
          req.headers.Authorization = `Bearer ${(window as any).lastAuthToken}`;
          response = await axios(req);
        } else if (r && (window as any).lastAuthToken.length <= 0) {
          window.location.href = window.location.origin + '/sign-in';
          return { status: false, data: null };
        } else {
          const auth: any = {
            access_token: localStorage.getItem('marine-farm') ?? undefined,
            refresh_token:
              localStorage.getItem('marine-farm-refresh') ?? undefined,
            id: localStorage.getItem('marine-farm-user_id') ?? undefined,
          };
          const responseRefresh = await refreshTokenAPI(auth);
          if (responseRefresh?.status === 'Success') {
            req.headers.Authorization = `Bearer ${responseRefresh.data.access_token}`;
            response = await axios(req);
          } else {
            window.location.href = window.location.origin + '/sign-in';
            return { status: false, data: null };
          }
        }
      }

      if (response.status >= 200 && response.status < 299) {
        const resData = await response.data;
        return {
          status: true,
          data: resData,
        };
      } else {
        const resData = await response.data;
        return {
          status: false,
          data: resData,
        };
      }
    } catch (e: any) {
      if (e?.response?.status === 429) {
        await delay(ws * 1000);
        continue;
      }
      console.log(e);
      return {
        status: false,
        data: e?.response?.data,
      };
    }
  }
  return { status: false, data: null };
};

export const sendMultipart = async (
  data: any,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  url: string,
  requireAuth = false,
  access_token = '',
): Promise<ISingleAPIResponse> => {
  try {
    await waitForRefreshToken();

    const Authorization = requireAuth
      ? {
          Authorization: `Bearer ${
            access_token
              ? `${access_token}`
              : localStorage.getItem('marine-farm')
          }`,
        }
      : {};
    const req = {
      headers: {
        ...Authorization,
        Accept: '*',
        'Content-Type': 'multipart/form-data',
      },
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
    };

    Object.assign(req, method === 'GET' ? { params: data } : { data });
    let response = await axios(req);

    if (response.data.message === 'Unauthenticated.') {
      const r = await waitForRefreshToken();
      if (r && (window as any).lastAuthToken.length > 0) {
        req.headers.Authorization = `Bearer ${(window as any).lastAuthToken}`;
        response = await axios(req);
      } else if (r && (window as any).lastAuthToken.length <= 0) {
        window.location.href = window.location.origin + '/sign-in';
        return { status: false, data: null };
      } else {
        const auth: any = {
          access_token: localStorage.getItem('marine-farm') ?? undefined,
          refresh_token:
            localStorage.getItem('marine-farm-refresh') ?? undefined,
          id: localStorage.getItem('marine-farm-user_id') ?? undefined,
        };
        const responseRefresh = await refreshTokenAPI(auth);
        if (responseRefresh?.status === 'Success') {
          req.headers.Authorization = `Bearer ${responseRefresh.data.access_token}`;
          response = await axios(req);
        } else {
          window.location.href = window.location.origin + '/sign-in';
          return { status: false, data: null };
        }
      }
    }

    if (response.status >= 200 && response.status < 299) {
      return {
        status: true,
        data: await response.data,
      };
    } else {
      return {
        status: false,
        data: await response.data,
      };
    }
  } catch (e: any) {
    return {
      status: false,
      data: e?.response?.data,
    };
  }
};

export const waitForRefreshToken = () => {
  return new Promise<boolean>(resolve => {
    function checkFlag(p: boolean) {
      if ((window as any).isRefreshingToken === false) {
        return resolve(p);
      } else window.setTimeout(() => checkFlag(true), 100);
    }
    checkFlag(false);
  });
};

const refreshTokenAPI = async (auth: any) => {
  try {
    (window as any).isRefreshingToken = true;
    (window as any).lastAuthToken = '';

    localStorage.removeItem('marine-farm');
    localStorage.removeItem('marine-farm-refresh');
    localStorage.removeItem('marine-farm-user_id');

    glbResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}api/refresh`,
      null,
      {
        headers: {
          Authorization: `Bearer ${auth.access_token}`,
          Refreshtoken: auth.refresh_token,
          User: auth.id,
          'Content-Type': 'application/json',
        },
      },
    );

    if (glbResponse.status === 200) {
      const data = await glbResponse.data;
      if (data?.status === 'Success') {
        localStorage.setItem('marine-farm', data?.data.access_token);
        localStorage.setItem('marine-farm-refresh', data?.data.refresh_token);
        localStorage.setItem('marine-farm-user_id', data?.user_id);
        (window as any).lastAuthToken = data?.data.access_token;
      }
      (window as any).isRefreshingToken = false;
      return data;
    }
    (window as any).isRefreshingToken = false;
    return false;
  } catch (e: any) {
    (window as any).isRefreshingToken = false;
    return e;
  }
};

export const downloadInvoice = async (id: string, access_token = '') => {
  try {
    const Authorization = {
      Authorization: `Bearer ${
        access_token ? `${access_token}` : localStorage.getItem('marine-farm')
      }`,
    };
    const req: AxiosRequestConfig = {
      headers: {
        ...Authorization,
        Accept: '*',
      },
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}api/subscription/invoices/download/${id}`,
      responseType: 'blob',
    };

    let response = await axios(req);

    if (response.data.message === 'Unauthenticated.') {
      const r = await waitForRefreshToken();
      if (r && (window as any).lastAuthToken.length > 0) {
        req.headers.Authorization = `Bearer ${(window as any).lastAuthToken}`;
        response = await axios(req);
      } else if (r && (window as any).lastAuthToken.length <= 0) {
        window.location.href = window.location.origin + '/sign-in';
        return { status: false, data: null };
      } else {
        const auth: any = {
          access_token: localStorage.getItem('marine-farm') ?? undefined,
          refresh_token:
            localStorage.getItem('marine-farm-refresh') ?? undefined,
          id: localStorage.getItem('marine-farm-user_id') ?? undefined,
        };
        const responseRefresh = await refreshTokenAPI(auth);
        if (responseRefresh?.status === 'Success') {
          req.headers.Authorization = `Bearer ${responseRefresh.data.access_token}`;
          response = await axios(req);
        } else {
          window.location.href = window.location.origin + '/sign-in';
          return { status: false, data: null };
        }
      }
    }

    const durl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = durl;
    link.setAttribute('download', 'invoice.pdf');
    document.body.appendChild(link);
    link.click();

    return false;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const downloadReport = async (data: any, mode: string) => {
  try {
    await waitForRefreshToken();

    const Authorization = {
      Authorization: `Bearer ${localStorage.getItem('marine-farm')}`,
    };
    const req = {
      headers: {
        ...Authorization,
        Accept: '*',
        'Content-Type': 'application/json',
      },
      method: 'POST' as Method,
      responseType: 'blob' as ResponseType,
      url: `${process.env.REACT_APP_API_URL}api/report/${mode}`,
    };
    Object.assign(req, { data });
    let response = await axios(req);

    if (response.data.message === 'Unauthenticated.') {
      const r = await waitForRefreshToken();
      if (r && (window as any).lastAuthToken.length > 0) {
        req.headers.Authorization = `Bearer ${(window as any).lastAuthToken}`;
        response = await axios(req);
      } else if (r && (window as any).lastAuthToken.length <= 0) {
        window.location.href = window.location.origin + '/sign-in';
        return { status: false, data: null };
      } else {
        const auth: any = {
          access_token: localStorage.getItem('marine-farm') ?? undefined,
          refresh_token:
            localStorage.getItem('marine-farm-refresh') ?? undefined,
          id: localStorage.getItem('marine-farm-user_id') ?? undefined,
        };
        const responseRefresh = await refreshTokenAPI(auth);
        if (responseRefresh?.status === 'Success') {
          req.headers.Authorization = `Bearer ${responseRefresh.data.access_token}`;
          response = await axios(req);
        } else {
          window.location.href = window.location.origin + '/sign-in';
          return { status: false, data: null };
        }
      }
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadPDF = async (
  data: any,
  method: 'POST' | 'GET',
  url: string,
) => {
  try {
    await waitForRefreshToken();

    const Authorization = {
      Authorization: `Bearer ${localStorage.getItem('marine-farm')}`,
    };
    const req = {
      headers: {
        ...Authorization,
        Accept: '*',
        'Content-Type': 'application/json',
      },
      method,
      responseType: 'blob' as ResponseType,
      url: `${process.env.REACT_APP_API_URL}${url}`,
    };
    Object.assign(req, { data });
    let response = await axios(req);

    if (response.data.message === 'Unauthenticated.') {
      const r = await waitForRefreshToken();
      if (r && (window as any).lastAuthToken.length > 0) {
        req.headers.Authorization = `Bearer ${(window as any).lastAuthToken}`;
        response = await axios(req);
      } else if (r && (window as any).lastAuthToken.length <= 0) {
        window.location.href = window.location.origin + '/sign-in';
        return { status: false, data: null };
      } else {
        const auth: any = {
          access_token: localStorage.getItem('marine-farm') ?? undefined,
          refresh_token:
            localStorage.getItem('marine-farm-refresh') ?? undefined,
          id: localStorage.getItem('marine-farm-user_id') ?? undefined,
        };
        const responseRefresh = await refreshTokenAPI(auth);
        if (responseRefresh?.status === 'Success') {
          req.headers.Authorization = `Bearer ${responseRefresh.data.access_token}`;
          response = await axios(req);
        } else {
          window.location.href = window.location.origin + '/sign-in';
          return { status: false, data: null };
        }
      }
    }

    return response;
  } catch (error) {
    throw error;
  }
};
