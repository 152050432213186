import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { ITimeRange } from '../../../entities/general.entities';
import { ISeaweedLineResource } from '../../../entities/farms.entities';
import { useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { Modal, Radio } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  DollarIcon,
  Feedback,
  Input,
  Paragrapgh,
  RadioButton,
  Subtitle,
  Title,
} from '../../shared';
import {
  defaultDateFormat,
  toMillisecond,
  toSecond,
} from '../../../util/toggleSecondMillisecond';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { isEmailFormat } from '../../../util/validation';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import { sendSingleRequest } from '../../../apis';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { showFeedback } from '../../../store/ui/ui.actions';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { selectLang } from '../../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../../lib/lang.helper';
import {
  SelectIsBudgetVisible,
  SelectProfileByFarmID,
  SelectSettingsByFarmID,
} from '../../../store/extra/extra.selector';

interface IFormData {
  is_final: boolean;
  is_full: boolean;
  season_name: string;
  amount: number | null;
  income: number | null;
  complete_date: number;
  company?: string;
  vessel?: string;
  harvest_number?: string;
  tank_area?: number;
  shell_length: number | null;
  shell_length_max: number | null;
  shell_condition?: string | null;
  comment?: string;
  images: any[];
  cost?: number;
  signature?: string;
  time_tracks?: ITimeRange[];
}

const defaultForm: IFormData = {
  is_final: true,
  is_full: true,
  complete_date: moment().toDate().getTime(),
  season_name: '',
  amount: null,
  income: null,
  shell_length: null,
  shell_length_max: null,
  images: [],
  time_tracks: [],
};

interface Props {
  visible: boolean;
  title?: string;
  lineData: ISeaweedLineResource;
  data: any;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  updateID?: number;
  onlyView?: boolean;
}

const SeaweedHarvestModal = ({
  visible,
  title,
  lineData,
  data,
  onCancel,
  onConfirm,
  updateID,
  onlyView,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const profile = SelectProfileByFarmID(lineData.farm_id);
  const friendEmails = SelectSettingsByFarmID(lineData.farm_id)?.friendEmails;
  const showBudget = SelectIsBudgetVisible(lineData.farm_id);

  const signatureCanvas = useRef<SignatureCanvas | null>();

  const [errorMsg, setErrorMsg] = useState<string>();
  const [errors, setErrors] = useState<any>();
  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    vessel: profile?.current_boat
      ? `${profile.current_boat.name} (${profile.current_boat.reg_number})`
      : undefined,
    company: profile?.company_name ?? undefined,
    is_final: lineData.growing_cycle?.last_harvest
      ? !!lineData.growing_cycle.last_harvest.is_final
      : defaultForm.is_final,
    tank_area:
      (lineData.growing_cycle?.total_seeded_tank_area ?? 0) -
      (lineData.growing_cycle?.total_harvested_tank_area ?? 0),
  });
  const [sigCanLoaded, setSigCanLoaded] = useState(false);
  const [reqPdf, setReqPdf] = useState(false);
  const [emails, setEmails] = useState(friendEmails?.join(',') ?? '');
  const [disabled, setDisabled] = useState(false);

  const {
    currentSeed,
    seededTankArea,
    harvestedTankArea,
    lastAssessment,
    lastHarvest,
  } = useMemo(
    () => ({
      currentSeed: lineData.growing_cycle?.main_seed,
      seededTankArea: lineData.growing_cycle?.total_seeded_tank_area ?? 0,
      harvestedTankArea: lineData.growing_cycle?.total_harvested_tank_area ?? 0,
      lastAssessment: lineData.growing_cycle?.last_assessment,
      lastHarvest: lineData.growing_cycle?.last_harvest,
    }),
    [lineData],
  );

  const updateForm = (key: keyof IFormData, value: any) => {
    if (
      [
        'amount',
        'income',
        'tank_area',
        'shell_length',
        'shell_length_max',
        'cost',
      ].includes(key)
    ) {
      value = value.length <= 0 ? undefined : Number(value);
      setFormData(prv => ({ ...prv, [key]: value }));
    } else {
      if (typeof value === 'string' && value.length <= 0) {
        value = undefined;
      }
      setFormData(prv => ({ ...prv, [key]: value }));
    }
    setErrors((prv: any) => ({ ...(prv ?? {}), [key]: null }));
  };
  const validForm = () => {
    if (!updateID && !lineData.growing_cycle) {
      setErrorMsg('_line_empty');
      return null;
    }
    if (!updateID && !currentSeed) {
      setErrorMsg('_line_empty');
      return null;
    }
    if (
      updateID &&
      !data.is_full !== !formData.is_full &&
      lineData?.growing_cycle?.last_harvest?.id !== updateID
    ) {
      setErrorMsg('_impossible_harvest_update');
      return null;
    }
    if (
      !updateID &&
      formData.is_full === false &&
      formData.tank_area &&
      seededTankArea - harvestedTankArea < formData.tank_area
    ) {
      const es = !formData.tank_area
        ? 'Please input area of harvested tank'
        : `Maximum available area of harvested tank is ${
            seededTankArea - harvestedTankArea
          }`;
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), tank_area: es }));
      return null;
    }
    if (
      !updateID &&
      formData.is_full &&
      formData.tank_area &&
      formData.tank_area !== seededTankArea - harvestedTankArea &&
      !window.confirm(
        `${
          seededTankArea - harvestedTankArea
        } m² are left but you are going to harvest ${
          formData.tank_area
        } m² as a full harvest. Are you sure?`,
      )
    ) {
      return null;
    }
    if (formData.amount === null || formData.amount <= 0) {
      const es = translate(
        lang,
        formData.amount === null
          ? 'Please enter harvested amount'
          : '_invalid_harvest_amount_length',
      );
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), amount: es }));
      return null;
    }
    if (
      formData.shell_length_max !== null &&
      (formData.shell_length === null ||
        formData.shell_length > formData.shell_length_max)
    ) {
      const es = translate(lang, 'Invalid shell length range');
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), shell_length: es }));
      return null;
    }
    if (emails && emails.split(',').some(x => !isEmailFormat(x))) {
      setErrorMsg('_invalid_emails');
      return null;
    }
    let res = {
      ...formData,
      seeding_id: data?.seeding_id ?? currentSeed?.id,
      season_name: data?.season_name ?? currentSeed?.season_name,
      line_id: lineData.id,
      complete_date: toSecond(formData.complete_date),
      require_pdf: reqPdf,
      request_emails: emails.split(','),
    };
    if (!res.income) res.income = 0;
    if (!updateID && signatureCanvas.current?.toDataURL()) {
      res.signature = signatureCanvas.current?.toDataURL() ?? undefined;
    } else {
      delete res.signature;
    }
    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        setErrorMsg('_invalid_time_range');
        return null;
      }
      res.time_tracks = tt;
    }
    setErrors(undefined);
    return res;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    const form = validForm();
    if (!form) return;

    setDisabled(true);
    const res = !updateID
      ? await sendSingleRequest(
          form,
          'POST',
          'api/farm/line/seaweed-harvest',
          true,
        )
      : await sendSingleRequest(
          form,
          'PUT',
          `api/farm/line/seaweed-harvest/${updateID}`,
          true,
        );
    if (res.status) {
      dispatch(loadFarmsData());
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
    } else {
      setErrorMsg(res.data?.message ?? 'Request failed');
      if (res.data.errors) setErrors(res.data.errors);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (data) {
      let tmp = { ...data };
      if (showBudget && data.expense_items && data.expense_items.length > 0) {
        tmp.cost = data.expense_items.reduce(
          (p: number, c: any) => p + c.amount,
          0,
        );
      }
      if (tmp.complete_date)
        tmp.complete_date = toMillisecond(tmp.complete_date);
      if (tmp.images) {
        tmp.images = tmp.images.map((x: any) =>
          typeof x === 'object' && x !== null ? x : parseImageUrlToFileItem(x),
        );
      }
      if (data.time_tracks) {
        tmp.time_tracks = data.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      setFormData(prv => ({ ...prv, ...tmp }));
    }
  }, [data, showBudget]);

  useEffect(() => {
    if (sigCanLoaded && data && data.signature) {
      signatureCanvas.current?.fromDataURL(data.signature);
    }
  }, [data, sigCanLoaded, signatureCanvas.current]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Seaweed harvest complete')}
          </Subtitle>
        </div>
        <div className='harvest-modal'>
          {!!errorMsg && (
            <Feedback
              message={translate(lang, errorMsg)}
              type={'error'}
              theme='light'
              position={10}
              isGlobal
              onClose={() => setErrorMsg(undefined)}
            />
          )}
          <div>
            {!!currentSeed && (
              <>
                <Paragrapgh
                  size={2}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='pb-25'
                >
                  {componentTranslate(
                    lang,
                    '_harvest_intro',
                    <span key={1} className='pr-6 pl-3 font-weight-500'>
                      {defaultDateFormat(currentSeed.planned_date_seed)}
                    </span>,
                    <span key={2} className='pl-4 font-weight-500'>
                      {defaultDateFormat(
                        lastAssessment?.planned_date_harvest ??
                          currentSeed.planned_date_harvest,
                      )}
                    </span>,
                  )}
                  <br />
                </Paragrapgh>
              </>
            )}
            <div className='pl-17 mb-32 mt-7 pr-17'>
              <Subtitle size={4} fontWeight={700} align='left' color='black-3'>
                {translate(lang, 'Have you harvested the entire line?')}
              </Subtitle>
              <Radio.Group
                onChange={e => updateForm('is_full', e.target.value === 'F')}
                value={formData.is_full ? 'F' : 'P'}
              >
                <RadioButton
                  label={translate(lang, 'Yes, the line is now empty')}
                  value='F'
                  className='mt-7'
                />
                <RadioButton
                  label={translate(lang, 'No, the crop is still there')}
                  value='P'
                  className='mt-7'
                />
              </Radio.Group>
            </div>
            <div className='mb-17'>
              {!!lastHarvest && lastHarvest.is_final == formData.is_final && (
                <div>
                  <Title size={6} color='black' align='center' fontWeight={500}>
                    {translate(
                      lang,
                      lastHarvest.is_final
                        ? `This is final harvest continuation`
                        : `This is intermediate harvest continuation`,
                    )}
                  </Title>
                </div>
              )}
              <div className='pl-17 mb-32 mt-7 pr-17'>
                <Radio.Group
                  onChange={e =>
                    updateForm('is_final', e.target.value === 'FINAL')
                  }
                  value={!formData.is_final ? 'MIDDLE' : 'FINAL'}
                >
                  <RadioButton
                    label={translate(
                      lang,
                      'Final harvest (goes to processing)',
                    )}
                    value='FINAL'
                    className='mt-7'
                  />
                  <RadioButton
                    label={translate(
                      lang,
                      'Intermediate harvest (goes to spat storage)',
                    )}
                    value='MIDDLE'
                    className='mt-7'
                  />
                </Radio.Group>
              </div>
            </div>
            <>
              <div className='mt-32 mb-17'>
                <div className={errors?.amount ? 'invalid-form' : ''}>
                  <Input
                    label={translate(lang, 'Harvest amount')}
                    type='number'
                    onChange={e => updateForm('amount', e.target.value)}
                    value={formData.amount?.toString() ?? ''}
                    unit='kg'
                  />
                  {!!errors?.amount && (
                    <div className='invalid-feedback'>{errors?.amount}</div>
                  )}
                </div>
              </div>
              {!!showBudget && (
                <div className='mb-17'>
                  <Input
                    label={translate(lang, 'Harvest income')}
                    type='number'
                    onChange={e => updateForm('income', e.target.value)}
                    value={formData.income?.toString() ?? ''}
                    unit={<DollarIcon />}
                  />
                </div>
              )}
              <div
                className={
                  errors?.complete_date ? 'invalid-form pb-24' : 'pb-24'
                }
              >
                <Datepicker
                  className='mb-17'
                  defaultValue={formData.complete_date}
                  label={translate(lang, 'Harvest complete date')}
                  onChange={e =>
                    e && updateForm('complete_date', e.toDate().getTime())
                  }
                />
                {!!errors?.complete_date && (
                  <div className='invalid-feedback'>
                    {errors?.complete_date}
                  </div>
                )}
              </div>
              {formData.is_final ? (
                <>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Company')}
                      type='text'
                      onChange={e => updateForm('company', e.target.value)}
                      value={formData.company ?? ''}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Vessel')}
                      type='text'
                      onChange={e => updateForm('vessel', e.target.value)}
                      value={formData.vessel ?? ''}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      type='text'
                      onChange={e =>
                        updateForm('harvest_number', e.target.value)
                      }
                      value={formData.harvest_number ?? ''}
                      label={`Harvest number${
                        lastHarvest?.harvest_number
                          ? ` (last value is ${lastHarvest.harvest_number})`
                          : ''
                      }`}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='pb-10 d-flex justify-content-between'>
                    <div className='w-100 mr-7'>
                      <Input
                        label={translate(lang, 'Shell length from')}
                        type='number'
                        onChange={e =>
                          updateForm('shell_length', e.target.value)
                        }
                        value={formData.shell_length?.toString() ?? ''}
                      />
                    </div>
                    <div className='w-100 ml-7'>
                      <Input
                        label={translate(lang, 'Shell length to')}
                        type='number'
                        onChange={e =>
                          updateForm('shell_length_max', e.target.value)
                        }
                        value={formData.shell_length_max?.toString() ?? ''}
                      />
                    </div>
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Shell condition')}
                      type='text'
                      onChange={e =>
                        updateForm('shell_condition', e.target.value)
                      }
                      value={formData.shell_condition ?? ''}
                    />
                  </div>
                </>
              )}
              <div
                className={errors?.tank_area ? 'invalid-form pb-10' : 'pb-10'}
              >
                <Input
                  type='number'
                  label={translate(lang, 'Area of harvested tank')}
                  onChange={e => updateForm('tank_area', e.target.value)}
                  value={formData.tank_area?.toString() ?? ''}
                  placeholder={
                    !updateID
                      ? `${seededTankArea} m² seeded, ${harvestedTankArea} m² harvested`
                      : ''
                  }
                />
                {!!errors?.tank_area && (
                  <div className='invalid-feedback'>{errors?.tank_area}</div>
                )}
              </div>
              {!!formData.time_tracks && (
                <div className='pb-7'>
                  <TimeTrackInput
                    data={formData.time_tracks}
                    onChange={t => updateForm('time_tracks', t)}
                  />
                </div>
              )}
              {!!formData.is_final && (
                <>
                  <div className='pb-10 d-flex justify-content-between'>
                    <div className='mr-7 w-100'>
                      <Input
                        label={translate(lang, 'Shell length from')}
                        type='number'
                        onChange={e =>
                          updateForm('shell_length', e.target.value)
                        }
                        value={formData.shell_length?.toString() ?? ''}
                      />
                    </div>
                    <div className='ml-7 w-100'>
                      <Input
                        label={translate(lang, 'Shell length to')}
                        type='number'
                        onChange={e =>
                          updateForm('shell_length_max', e.target.value)
                        }
                        value={formData.shell_length_max?.toString() ?? ''}
                      />
                    </div>
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Shell condition')}
                      type='text'
                      onChange={e =>
                        updateForm('shell_condition', e.target.value)
                      }
                      value={formData.shell_condition ?? ''}
                    />
                  </div>
                </>
              )}
              {!!showBudget && (
                <div className='pb-10'>
                  <Input
                    label={translate(lang, 'Cost')}
                    type='number'
                    onChange={e => updateForm('cost', e.target.value)}
                    value={formData.cost?.toString() ?? ''}
                    unit='$'
                  />
                </div>
              )}
              <div className='pb-10'>
                <Input
                  label={translate(lang, 'Comment')}
                  type='textarea'
                  onChange={e => updateForm('comment', e.target.value)}
                  value={formData.comment ?? ''}
                />
              </div>
              <div className='pb-10'>
                <PicturesWall
                  label={translate(lang, 'Images')}
                  fileList={formData.images}
                  handleChangeImages={v => updateForm('images', v)}
                />
              </div>
              <div className='pb-20'>
                <p className='mb-4 d-block paragrapgh paragrapgh--2 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
                  {translate(lang, 'Signature')}
                </p>
                <div
                  style={{
                    border: '1px solid #EFEFEF',
                    borderRadius: '4px',
                    height: '200px',
                  }}
                >
                  <SignatureCanvas
                    ref={r => {
                      signatureCanvas.current = r;
                      setSigCanLoaded(true);
                    }}
                    penColor='green'
                    canvasProps={{ width: 550, height: 200 }}
                  />
                </div>
              </div>
              {!updateID && (
                <div className='mb-27'>
                  <CheckboxButton
                    label={translate(
                      lang,
                      'Send me PDF of Harvest Declaration Form',
                    )}
                    checked={reqPdf}
                    onChange={e => setReqPdf(e.target.checked)}
                  />
                  {reqPdf && (
                    <div className='mt-17'>
                      <Input
                        label={translate(lang, 'Email list')}
                        placeholder={translate(
                          lang,
                          'Please enter emails separate by comma',
                        )}
                        type='text'
                        value={emails}
                        onChange={e => setEmails(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled || onlyView}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default SeaweedHarvestModal;
