import { useMemo } from 'react';
import { Marker } from 'react-google-maps';
import IMarker from './MarkersMap';

interface Item {
    points?: { lat: number; lng: number }[] | null;
    color?: string;
    basket_count?: number;
    total_seeded_basket_count?: number;
    seedings?: any;
    onMouseOver?: (position: { lat: number; lng: number }) => void;
    onMouseOut?: (position: { lat: number; lng: number }) => void;
}

const colors = [
  'blue',
  'orange',
  'yellow',
  'purple',
  'orange',
  'black',
]

function getStartingPoint(points: { lat: number; lng: number }[], direction: string|null|undefined) {
  const [point1, point2] = points;
  switch (direction) {
    case 'E':
      return point1.lng > point2.lng ? [point1, point2] : [point2, point1];
    case 'W':
      return point1.lng < point2.lng ? [point1, point2] : [point2, point1];
    case 'N':
      return point1.lat > point2.lat ? [point1, point2] : [point2, point1];
    case 'S':
      return point1.lat < point2.lat ? [point1, point2] : [point2, point1];
    default:
      return [point1, point2];
  }
}
  

const DottedLine = ({ item, direction }: { item: Item, direction: string|null|undefined }) => {
  const markers = useMemo(() => {
    if (!item.points || item.points.length < 2 || item.seedings === undefined) return [];
    
    const [start, end] = getStartingPoint(item.points, direction);
    
    const markerCount = item.total_seeded_basket_count ?? 0;

    const latStep = (end.lat - start.lat) / (markerCount - 1);
    const lngStep = (end.lng - start.lng) / (markerCount - 1);

    const points: { lat: number; lng: number; color: string }[] = [];
    if (item.seedings)
      item.seedings.map((seed: any, index:number) => {
        const colorIndex = index % colors.length;
        for (let j = 0; j < seed.basket_count; j++) {
          points.push({
            lat: start.lat + points.length * latStep,
            lng: start.lng + points.length * lngStep,
            color: colors[colorIndex],
          });
        }
    });
    return points;
  }, [item.points]);

  return (
    <>
      {markers.length > 0 && (
      <>
        <Marker
            position={{ lat: markers[0].lat, lng: markers[0].lng }}
            label={{
              text: 'STARTS',
              color: 'black',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
            icon={{
              path: 'M 0, 0 m -2, 0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0', // Adjust dot icon if needed
              scale: 1,
              fillColor: 'green',
              fillOpacity: 1,
              strokeWeight: 0,
            }}
          />
            <Marker
            position={{ lat: markers[markers.length - 1].lat, lng: markers[markers.length - 1].lng }}
            label={{
              text: 'ENDS',
              color: 'red',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
            icon={{
              path: 'M 0, 0 m -2, 0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0',
              scale: 1,
              fillColor: 'red',
              fillOpacity: 1,
              strokeWeight: 0,
            }}
          />
      </>

      )}

      
      {markers.map((position, index) => (
        <Marker
          key={index}
          position={{lat: position.lat, lng: position.lng}}
          icon={{
            path: 'M 0 0 h 4 v 4 h -4 Z',
            scale: 1.8, 
            fillColor: position['color'],
            fillOpacity: 1,
            strokeWeight: 0,
          }}
          onMouseOver={e =>
            item.onMouseOver
              ? item.onMouseOver({ lat: e.latLng.lat(), lng: e.latLng.lng() })
              : undefined
          }
          onMouseOut={e =>
            item.onMouseOut
              ? item.onMouseOut({ lat: e.latLng.lat(), lng: e.latLng.lng() })
              : undefined
          }
        />
      ))}
    </>
  );
};

export default DottedLine;