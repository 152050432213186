import { Button } from 'antd';
import { Title } from '../../components/shared';

const OnboardingPage = () => {
  const onClose = () => {
    const w: any = window;
    if (w.ReactNativeWebView) {
      w.ReactNativeWebView.postMessage('close-onboarding');
    }
  };

  return (
    <div className='container w-100'>
      <Title size={5} color='black-3' align='default' fontWeight={700}>
        Welcome!
      </Title>
      <Button onClick={onClose}>Close</Button>
    </div>
  );
};

export default OnboardingPage;
