export const transformFarmWithKey = (data: any = []): any => {
  const dataWithKey = data.map((farm: any, i: number) => {
    const newFarm = { ...farm };
    newFarm.key = i + 1;

    const newLines = farm?.lines?.map((line: any, y: number) => {
      const newLine = { ...line };
      newLine.key = y + 1;
      return newLine;
    });

    return { ...newFarm, lines: newLines };
  });

  return dataWithKey;
};

interface IValues {
  value?: number;
  isGrow?: boolean;
  interest?: number;
  isReverse?: boolean;
}

export const getInterest = (
  firstValue: number,
  secondValue: number | null,
): IValues => {
  let interest;
  let values: IValues = {};
  if (secondValue !== null && secondValue !== 0) {
    interest = ((firstValue - secondValue) / secondValue) * 100;
    values = {
      isGrow: !(secondValue > firstValue),
      interest: Number(Math.abs(interest).toFixed(2)),
      value: firstValue,
    };
  } else if (secondValue === 0) {
    values = {
      isGrow: !(+firstValue < 0),
      interest: +firstValue === 0 ? 0 : 100,
      value: firstValue,
    };
  } else if (secondValue === null) {
    values = {
      isGrow: true,
      interest: 0,
      value: firstValue,
    };
  } else {
    values = {
      value: firstValue,
    };
  }

  return values;
};
