import Security from '../Profile/Security';

const AdminSecurityPage = () => (
  <div className='bg-secondary min-height'>
    <div className='container'>
      <div className='queued-page-header justify-content-between align-items-center'></div>
      <div className='d-flex justify-content-center'>
        <Security />
      </div>
    </div>
  </div>
);

export default AdminSecurityPage;
