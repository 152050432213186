import { FC, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Menu, Pagination } from 'antd';
import Subtitle from '../subtitle/Subtitle';
import ModalComponent from '../modal/Modal';
import { IBudgetLog } from '../../../entities/budget.entities';
import { selectProfile } from '../../../store/auth/auth.selector';
import DotsIcon from '../DotsIcon';
import Paragrapgh from '../paragrapgh/Paragrapgh';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import './styles.scss';

interface IPage {
  current: number;
  pageSize: number;
  total: number;
}

interface Props {
  data: IBudgetLog[];
  onDeleteLog: (log: IBudgetLog) => void;
  pagination: IPage;
  onPagination: (page: number, pageSize?: number) => void;
}

const BudgetLogsMobile: FC<Props> = memo(
  ({ data, onDeleteLog, pagination, onPagination }) => {
    const profile = useSelector(selectProfile);

    const [viewLog, setViewLog] = useState<IBudgetLog>();

    return (
      <>
        <div className='table-mobile'>
          {data.length > 0 ? (
            <>
              {data.map(log => (
                <div key={log.id} className='table-mobile__card'>
                  <div className='table-mobile__card-dots'>
                    {!!profile?.edit_permission && (
                      <div className={'wwrap'}>
                        <div
                          className='dropdown'
                          onClick={e => e.stopPropagation()}
                        >
                          <Dropdown
                            overlay={
                              <Menu onClick={e => onDeleteLog(log)}>
                                <Menu.Item key='delete'>Delete</Menu.Item>
                              </Menu>
                            }
                            placement='bottomRight'
                            trigger={['click']}
                          >
                            <div>
                              <DotsIcon />
                            </div>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='d-flex pb-23'>
                    <div className='flex-basis-50'>
                      <Paragrapgh
                        size={3}
                        color='black-2'
                        align='left'
                        fontWeight={400}
                      >
                        Farm
                      </Paragrapgh>
                      <Paragrapgh
                        size={2}
                        color='black-5'
                        align='left'
                        fontWeight={400}
                      >
                        {log.farm_name}
                      </Paragrapgh>
                    </div>
                    <div className='flex-basis-50 ml-24'>
                      <Paragrapgh
                        size={3}
                        color='black-2'
                        align='left'
                        fontWeight={400}
                      >
                        Line
                      </Paragrapgh>
                      <Paragrapgh
                        size={2}
                        color='black-5'
                        align='left'
                        fontWeight={400}
                      >
                        {log.line_name}
                      </Paragrapgh>
                    </div>
                  </div>
                  <div className='d-flex pb-23'>
                    <div className='flex-basis-50'>
                      <Paragrapgh
                        size={3}
                        color='black-2'
                        align='left'
                        fontWeight={400}
                      >
                        Type
                      </Paragrapgh>
                      <Paragrapgh
                        size={2}
                        color='black-5'
                        align='left'
                        fontWeight={400}
                      >
                        {log.human_name}
                      </Paragrapgh>
                    </div>
                    <div className='flex-basis-50 ml-24'>
                      <Paragrapgh
                        size={3}
                        color='black-2'
                        align='left'
                        fontWeight={400}
                      >
                        Change
                      </Paragrapgh>
                      <Paragrapgh
                        size={2}
                        color='black-5'
                        align='left'
                        fontWeight={400}
                      >
                        <span>
                          {log.change.old} &#x2192;
                          <span className='font-weight-600'>
                            {log.change.new}
                          </span>
                        </span>
                      </Paragrapgh>
                    </div>
                  </div>
                  <div className='d-flex pb-23'>
                    <div className='flex-basis-50'>
                      <Paragrapgh
                        size={3}
                        color='black-2'
                        align='left'
                        fontWeight={400}
                      >
                        User
                      </Paragrapgh>
                      <Paragrapgh
                        size={2}
                        color='black-5'
                        align='left'
                        fontWeight={400}
                      >
                        {log.user_name}
                      </Paragrapgh>
                    </div>
                    <div className='flex-basis-50 ml-24'>
                      <Paragrapgh
                        size={3}
                        color='black-2'
                        align='left'
                        fontWeight={400}
                      >
                        Date
                      </Paragrapgh>
                      <Paragrapgh
                        size={2}
                        color='black-5'
                        align='left'
                        fontWeight={400}
                      >
                        {defaultDateFormat(log.date)}
                      </Paragrapgh>
                    </div>
                  </div>
                  <div className='d-flex'>
                    <div className='flex-basis-50'>
                      <Paragrapgh
                        size={3}
                        color='black-2'
                        align='left'
                        fontWeight={400}
                      >
                        Comment
                      </Paragrapgh>
                      <Paragrapgh
                        size={2}
                        color='black-5'
                        align='left'
                        fontWeight={400}
                      >
                        <span
                          className='btn__modal'
                          onKeyDown={() => undefined}
                          onClick={() => setViewLog(log)}
                        >
                          View
                        </span>
                      </Paragrapgh>
                    </div>
                  </div>
                </div>
              ))}
              <div className='w-100 pb-24 d-flex justify-content-end'>
                <Pagination
                  current={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={onPagination}
                />
              </div>
            </>
          ) : (
            <div className='table-mobile__not-data'>
              <Subtitle size={4} color='black-5' align='left' fontWeight={400}>
                No data available
              </Subtitle>
            </div>
          )}
        </div>
        <ModalComponent
          visible={!!viewLog}
          onCancel={() => setViewLog(undefined)}
          type=''
          title='Comment'
          text={viewLog?.comment ?? 'No comments yet'}
        />
      </>
    );
  },
);

export default BudgetLogsMobile;
