import { useState } from 'react';
import { ILineResource } from '../../entities/farms.entities';
import {
  Button,
  Datepicker,
  Dropdown,
  Input,
  RadioButton,
} from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMusselFarms,
  selectMusselSpats,
} from '../../store/farms/farms.selector';
import { Radio } from 'antd';
import { selectSeasons } from '../../store/utils/utils.selector';
import moment from 'moment';
import DateTimePicker from '../../components/shared/datepicker/DateTimePicker';
import { showFeedback } from '../../store/ui/ui.actions';
import { toSecTimeTracks } from '../../components/shared/input/TimeTrackInput';
import {
  loadFarmsData,
  loadSpatStorages,
} from '../../store/farms/farms.actions';
import { sendSingleRequest } from '../../apis';
import { useHistory } from 'react-router-dom';
import PicturesWall from '../../components/shared/pictures-wall/PicturesWall';
import { selectFloatInventories } from '../../store/inventories/inventories.selector';
import { loadInventories } from '../../store/inventories/inventories.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { getSpatSourceLabel } from '../../lib/farm.helpers';

const getJSTime = (t: number | undefined) =>
  t ? t * 1000 : moment().toDate().getTime();

interface IForm {
  season_name: string;
  spat_storage_id?: number;
  spat_amount?: number;
  planned_date_seed: number;
  planned_date_harvest: number;
  line_length?: number;
  spat_size?: number;
  spat_size_max?: number;
  density?: number;
  density_max?: number;
  density_unit: 'spat per m' | 'kgs per m';
  drop?: number;
  submersion?: number;
  spacing?: number;
  start_time: number;
  finish_time: number;
  comment?: string;
  images?: any[];
  float_id?: number;
  float_quantity?: number;
}

const extractForm = (data: any): IForm => {
  let result: IForm = {
    season_name: data.season_name ?? '',
    spat_storage_id: data.spat_storage_id ?? undefined,
    spat_amount: data.spat_amount ?? undefined,
    line_length: data.line_length ?? undefined,
    planned_date_seed: getJSTime(data.planned_date_seed),
    planned_date_harvest: getJSTime(data.planned_date_harvest),
    spat_size: data.spat_size ?? undefined,
    spat_size_max: data.spat_size_max ?? undefined,
    density: data.density ?? undefined,
    density_max: data.density_max ?? undefined,
    density_unit: data.density_unit ?? 'spat per m',
    drop: data.drop ?? undefined,
    submersion: data.submersion ?? undefined,
    spacing: data.spacing ?? undefined,
    start_time: getJSTime(data.start_time),
    finish_time: getJSTime(data.finish_time),
    comment: data.comment ?? undefined,
    images: data.images,
    float_id: data.float_id ?? undefined,
    float_quantity: data.float_quantity ?? undefined,
  };
  return result;
};

interface Props {
  formID: number;
  data: any;
  lineData: ILineResource;
}

const PaperSeedingForm = ({ formID, data, lineData }: Props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectMusselFarms);
  const spatStorages = useSelector(selectMusselSpats);
  const seasonData = useSelector(selectSeasons).map(x => ({
    id: x.id.toString(),
    label: x.name,
    value: x.name,
  }));
  const inventories = useSelector(selectFloatInventories);

  const [curFarm, setCurFarm] = useState(
    farmsData.find(x => x.id === lineData.farm_id),
  );
  const [curLine, setCurLine] = useState<ILineResource | undefined>(lineData);
  const [formData, setFormData] = useState<IForm>(extractForm(data));
  const [isSeasonNew, setIsSeasonNew] = useState<'old' | 'new'>(
    !data.season_name || seasonData.some(x => x.value === data.season_name)
      ? 'old'
      : 'new',
  );
  const [disabled, setDisabled] = useState(false);

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        isMessage: true,
        message: translate(lang, message),
      }),
    );
  const selectFarm = (v: string) => {
    const farm = farmsData.find(x => x.id === Number(v));
    setCurFarm(farm);
    setCurLine(undefined);
  };
  const selectLine = (v: string) => {
    const line = curFarm?.lines.find(x => x.id === Number(v));
    setCurLine(line);
  };
  const updateForm = (key: keyof IForm, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = curFarm?.lines.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: x.line_name,
  }));
  const availableWeight = formData.spat_storage_id
    ? spatStorages.find(x => x.id === Number(formData.spat_storage_id))
        ?.available_weight ?? -1
    : -1;
  const curFloat = inventories.find(x => x.id === formData.float_id);

  const confirmClick = async () => {
    if (!curFarm || !curLine) {
      showError('Please select farm and line');
      return;
    }
    if (!formData.season_name) {
      showError('Please enter season name');
      return;
    }
    if (!formData.spat_storage_id) {
      showError('Please select spat storage');
      return;
    }
    if (!formData.spat_amount) {
      showError('Please enter spat amount');
      return;
    }
    if (!formData.line_length) {
      showError('Please enter line length');
      return;
    }
    if (!formData.spat_size) {
      showError('Please enter spat size');
      return;
    }
    if (!formData.density) {
      showError('Please enter density');
      return;
    }
    const {
      start_time,
      finish_time,
      planned_date_seed,
      planned_date_harvest,
      float_id,
      float_quantity,
      ...params
    } = formData;
    const time_tracks = toSecTimeTracks([{ start_time, finish_time }]);
    if (time_tracks === false) {
      showError('_invalid_time_range');
      return;
    }
    if (float_id && (float_quantity === undefined || float_quantity === null)) {
      showError('Please enter float quantity');
      return;
    }
    if (
      float_id &&
      (float_quantity ?? 0) > (curFloat?.available_quantity ?? 0)
    ) {
      showError(
        translate(
          lang,
          'Maximum available float quantity is %s',
          curFloat?.available_quantity,
        ),
      );
      return;
    }
    const picked_inventories = float_quantity
      ? [{ id: float_id, quantity: float_quantity }]
      : undefined;
    const submitData = {
      ...params,
      id: formID,
      planned_date_seed: Math.floor(planned_date_seed / 1000),
      planned_date_harvest: Math.floor(planned_date_harvest / 1000),
      time_tracks,
      parent_id: curLine.growing_cycle?.main_seed.id,
      line_id: curLine.id,
      picked_inventories,
    };
    setDisabled(true);
    const res = await sendSingleRequest(
      submitData,
      'POST',
      'api/queue/confirm-seeding/mussel',
      true,
    );
    if (res.status) {
      await dispatch(loadFarmsData());
      await dispatch(loadSpatStorages());
      if (picked_inventories) {
        await dispatch(loadInventories());
      }
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(lang, 'Confirmed successfully'),
          type: 'success',
        }),
      );
      history.goBack();
    } else {
      setDisabled(false);
      showError(res.data?.message ?? 'Unknown error');
    }
  };

  return (
    <div>
      <div className='mb-17 d-flex'>
        <div className='w-100 mr-7'>
          <Dropdown
            label={translate(lang, 'Select farm')}
            options={farmOptions}
            value={curFarm?.id.toString()}
            onChange={v => selectFarm(v)}
          />
        </div>
        <div className='w-100 ml-7'>
          <Dropdown
            label={translate(lang, 'Select line')}
            options={lineOptions ?? []}
            value={curLine?.id.toString()}
            onChange={v => selectLine(v)}
          />
        </div>
      </div>
      <Radio.Group
        className='d-flex mt-32 mb-17'
        onChange={e => setIsSeasonNew(e.target.value)}
        value={isSeasonNew}
      >
        <RadioButton label={translate(lang, 'Existing season')} value='old' />
        <RadioButton
          className='ml-34'
          label={translate(lang, 'New season')}
          value='new'
        />
      </Radio.Group>
      <div className='mb-17'>
        {isSeasonNew === 'old' ? (
          <Dropdown
            label={translate(lang, 'Season name')}
            placeholder={translate(lang, 'Choose Season')}
            className='mr-16 w-100'
            onChange={value => updateForm('season_name', value)}
            options={seasonData}
            value={formData.season_name}
          />
        ) : (
          <Input
            type='text'
            value={formData.season_name}
            label={translate(lang, 'New Season')}
            onChange={e => updateForm('season_name', e.target.value)}
          />
        )}
      </div>
      <div className='mt-17'>
        <Dropdown
          className={`mb-${formData.spat_storage_id === null ? '16' : '5'}`}
          label={translate(lang, 'Spat')}
          value={getSpatSourceLabel(
            formData.spat_storage_id ?? 0,
            spatStorages,
          )}
          options={spatStorages
            .filter(x => Number(x.available_weight) > 0)
            .map(item => ({
              id: item.id.toString(),
              value: item.id.toString(),
              label: getSpatSourceLabel(item.id, spatStorages),
            }))}
          onChange={(value, evt) =>
            updateForm('spat_storage_id', Number(value))
          }
        />
        {!!formData.spat_storage_id && (
          <Input
            type='number'
            value={formData.spat_amount?.toString() ?? ''}
            label={translate(lang, 'Amount')}
            placeholder={
              availableWeight <= 0
                ? ''
                : translate(lang, '_max_spat_amount', availableWeight)
            }
            max={availableWeight > 0 ? availableWeight : undefined}
            unit='kg'
            onChange={e => updateForm('spat_amount', e.target.value)}
          />
        )}
      </div>
      <div className='d-flex mb-17 mt-16 justify-content-between'>
        <div className='mr-12 w-100'>
          <Datepicker
            label={translate(lang, 'Line seeding date')}
            defaultValue={formData.planned_date_seed}
            onChange={e =>
              updateForm(
                'planned_date_seed',
                e?.toDate().getTime() ?? moment().toDate().getTime(),
              )
            }
            required
          />
        </div>
        <div className='ml-12 w-100'>
          <Datepicker
            label={translate(lang, 'Planned harvest date')}
            defaultValue={formData.planned_date_harvest}
            onChange={e => {
              if (
                formData.planned_date_seed !== undefined &&
                e &&
                e!.toDate().getTime() < formData.planned_date_seed
              ) {
                updateForm('planned_date_harvest', moment().toDate().getTime());
              } else {
                updateForm(
                  'planned_date_harvest',
                  e?.toDate().getTime() ?? moment().toDate().getTime(),
                );
              }
            }}
            required
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <DateTimePicker
          className='mr-12'
          defaultValue={formData.start_time}
          label={translate(lang, 'Start time')}
          onChange={e => updateForm('start_time', e?.toDate().getTime())}
        />
        <DateTimePicker
          className='ml-12'
          defaultValue={formData.finish_time}
          label={translate(lang, 'Finish time')}
          onChange={e => updateForm('finish_time', e?.toDate().getTime())}
        />
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            value={formData.line_length?.toString() ?? ''}
            label={translate(lang, 'Line Length')}
            unit='m'
            required
            onChange={e => updateForm('line_length', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            value={formData.drop?.toString() ?? ''}
            label={translate(lang, 'Drop')}
            unit='m'
            required
            onChange={e => updateForm('drop', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            value={formData.spat_size?.toString() ?? ''}
            label={translate(lang, 'Spat size min')}
            unit='mm'
            onChange={e => updateForm('spat_size', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            value={formData.spat_size_max?.toString() ?? ''}
            label={translate(lang, 'Spat size max')}
            unit='mm'
            onChange={e => updateForm('spat_size_max', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            value={formData.density?.toString() ?? ''}
            label={translate(lang, 'Density min')}
            unit={formData.density_unit}
            onChange={e => updateForm('density', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            value={formData.density_max?.toString() ?? ''}
            label={translate(lang, 'Density max')}
            unit={formData.density_unit}
            onChange={e => updateForm('density_max', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            value={formData.submersion?.toString() ?? ''}
            label={translate(lang, 'Submersion')}
            unit='m'
            onChange={e => updateForm('submersion', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            value={formData.spacing?.toString() ?? ''}
            label={translate(lang, 'Spacing')}
            unit='m'
            onChange={e => updateForm('spacing', e.target.value)}
          />
        </div>
      </div>
      <div className='mt-17 mb-17'>
        <Input
          label={translate(lang, 'Comment')}
          type='textarea'
          value={formData.comment ?? ''}
          onChange={e => updateForm('comment', e.target.value)}
        />
      </div>
      <div className='mt-17 mb-17'>
        <PicturesWall
          label={translate(lang, 'Images')}
          fileList={formData.images}
          handleChangeImages={f => updateForm('images', f)}
        />
      </div>
      <div className='mt-17 mb-17 d-flex justify-content-between'>
        <div className='w-100 mr-12'>
          <Dropdown
            label={translate(lang, 'Float type')}
            placeholder={translate(lang, 'Select inventory')}
            options={inventories.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            value={formData.float_id?.toString()}
            onChange={v => updateForm('float_id', Number(v))}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            value={formData.float_quantity?.toString() ?? ''}
            label={translate(lang, 'Quantity')}
            placeholder={
              curFloat
                ? translate(
                    lang,
                    'Maximum value is %s',
                    curFloat.available_quantity,
                  )
                : ''
            }
            onChange={e =>
              updateForm(
                'float_quantity',
                e.target.value.length <= 0 ? undefined : Number(e.target.value),
              )
            }
          />
        </div>
      </div>
      <div className='mt-17 d-flex justify-content-end'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </div>
  );
};

export default PaperSeedingForm;
