import { Modal, Radio } from 'antd';
import React, { useState } from 'react';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  PlusIcon,
  RadioButton,
  Subtitle,
  TrashIcon,
} from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { useDispatch, useSelector } from 'react-redux';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { ILineResource } from '../../entities/farms.entities';
import moment from 'moment';
import { selectSeasons } from '../../store/utils/utils.selector';
import { showFeedback } from '../../store/ui/ui.actions';
import { sendSingleRequest } from '../../apis';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { formatNumber, numberToMoneyStr } from '../../entities/util-functions';
import PDFWall from '../shared/pictures-wall/PDFWall';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

interface IForm {
  season_name: string;
  seed_date: number;
  spat_source: string;
  spat_amount: number | null;
  line_length: number | null;
  spat_size: number | null;
  shell_length: number | null;
  harvest_date: number;
  harvest_amount: number | null;
  harvest_income: number | null;
  bags_quantity: number | null;
  rope_bags_quantity: number | null;
  harvest_number: string | null;
  port_of_unload: string | null;
  vessel: string | null;
  company: string | null;
  comment: string | null;
}

interface IExpenseRequest {
  name: string;
  amount: number | null;
  quantity: number | null;
}

interface ViewProps {
  items: IExpenseRequest[];
  setItems: (d: IExpenseRequest[]) => void;
  label: string;
  lang: any;
}

const ExpensesView = ({ items, setItems, label, lang }: ViewProps) => {
  const updateCost = (
    i: number,
    key: 'name' | 'amount' | 'quantity',
    value: any,
  ) => {
    let tmp: any = [...items];
    if (tmp.length <= i) return;
    tmp[i][key] = value;
    setItems(tmp);
  };
  const addCost = () => {
    let tmp = [...items];
    tmp.push({ name: '', amount: null, quantity: null });
    setItems(tmp);
  };
  const deleteCost = (i: number) => {
    const tmp = items.filter((x, j) => i !== j);
    setItems(tmp);
  };

  return (
    <>
      <div className='mb-7'>
        <Subtitle color='black-2' align='left' size={5} fontWeight={500}>
          {translate(lang, label)}
        </Subtitle>
      </div>
      <div className='mb-7'>
        <div className='item-row'>
          <div className='item-cell'>{translate(lang, 'Description')}</div>
          <div className='item-cell'>{translate(lang, 'Quantity')}</div>
          <div className='item-cell'>{translate(lang, 'Unit price')}</div>
          <div className='item-cell'>{translate(lang, 'Amount')}</div>
          <div className='item-cell-half'></div>
        </div>
        {items.map((x, i) => (
          <div className='item-row' key={i}>
            <div className='item-cell'>
              <Input
                type='text'
                onChange={e => updateCost(i, 'name', e.target.value)}
                value={x.name}
                label=''
                placeholder=''
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                onChange={e => updateCost(i, 'quantity', e.target.value)}
                value={x.quantity?.toString() ?? ''}
                label=''
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                disabled={true}
                value={
                  x.quantity ? ((x.amount ?? 0) / x.quantity).toFixed(2) : ''
                }
                label=''
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                onChange={e => updateCost(i, 'amount', e.target.value)}
                value={x.amount?.toString() ?? ''}
                label=''
                unit='$'
              />
            </div>
            <div className='item-cell-half'>
              <Button
                width='full'
                size={0}
                type='bordered'
                color='red'
                className='d-flex justify-content-center'
                onClick={deleteCost.bind(this, i)}
              >
                <TrashIcon color='#cc0101' />
              </Button>
            </div>
          </div>
        ))}
        <div className='item-row'>
          <div className='item-cell'>
            <Button
              width='full'
              size={5}
              type='bordered'
              color='blue'
              className='d-flex justify-content-center'
              onClick={addCost}
            >
              <PlusIcon />
            </Button>
          </div>
          <div className='item-cell'></div>
          <div className='item-cell'></div>
          <div className='item-cell'></div>
          <div className='item-cell-half'></div>
        </div>
      </div>
    </>
  );
};

interface Props {
  visible: boolean;
  onClose: (d: any) => void;
  onConfirm: (d: any) => void;
  className?: string;
}

const GrowerCycleModal = ({
  visible,
  onClose,
  onConfirm,
  className,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const farms = useSelector(selectFarmsData);
  const allLines = farms.reduce(
    (p, c) => [...p, ...c.lines],
    [] as ILineResource[],
  );
  const linesOption =
    allLines.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: `${x.farm_name} - ${x.line_name}`,
    })) ?? [];
  const seasons = useSelector(selectSeasons).map(x => ({
    id: x.id.toString(),
    label: x.name,
    value: x.name,
  }));

  const [disabled, setDisabled] = useState(false);
  const [curLine, setCurLine] = useState<ILineResource>();
  const [formData, setFormData] = useState<IForm>({
    season_name: '',
    seed_date: moment().toDate().getTime(),
    spat_source: '',
    spat_amount: null,
    line_length: null,
    spat_size: null,
    shell_length: null,
    harvest_date: moment().toDate().getTime(),
    harvest_amount: null,
    harvest_income: null,
    bags_quantity: null,
    rope_bags_quantity: null,
    harvest_number: '',
    port_of_unload: '',
    vessel: '',
    company: '',
    comment: '',
  });
  const [seasonType, setSeasonType] = useState<'old' | 'new'>('old');
  const [seedExpenses, setSeedExpenses] = useState<IExpenseRequest[]>([]);
  const [harvestExpenses, setHarvestExpenses] = useState<IExpenseRequest[]>([]);
  const [pdfFiles, setPdfFiles] = useState<any[]>();

  const updateForm = (key: keyof IForm, val: any) =>
    setFormData(prv => ({ ...prv, [key]: val }));
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        message: translate(lang, message),
        type: 'error',
      }),
    );

  const validForm = () => {
    if (!curLine) {
      showError('Please select line');
      return null;
    }
    if (!formData.season_name) {
      showError('Season name field is required');
      return null;
    }
    if (!formData.spat_source) {
      showError('Spat source field is required');
      return null;
    }
    if (!formData.spat_amount) {
      showError('Spat amount field is required');
      return null;
    }
    if (!formData.line_length) {
      showError('Longline length field is required');
      return null;
    }
    if (!formData.spat_size) {
      showError('Seeded spat size field is required');
      return null;
    }
    if (!formData.shell_length) {
      showError('Harvested shell size field is required');
      return null;
    }
    if (!formData.harvest_amount) {
      showError('Harvest amount field is required');
      return null;
    }
    if (formData.seed_date > formData.harvest_date) {
      showError('Harvested date should be later than seeded date');
      return null;
    }
    if (seedExpenses.some(x => !x.name || !x.amount || !x.quantity)) {
      showError('Please enter seed expenses data correctly');
      return null;
    }
    if (harvestExpenses.some(x => !x.name || !x.amount || !x.quantity)) {
      showError('Please enter harvest expenses data correctly');
      return null;
    }
    let result = {
      line_id: curLine.id,
      ...formData,
      seed_date: Math.floor(formData.seed_date / 1000),
      harvest_date: Math.floor(formData.harvest_date / 1000),
      seed_expenses: seedExpenses,
      harvest_expenses: harvestExpenses,
      pdf_files: pdfFiles,
    };
    if (!result.harvest_number) {
      result.harvest_number = null;
    }
    if (!result.port_of_unload) {
      result.port_of_unload = null;
    }
    if (!result.vessel) {
      result.vessel = null;
    }
    if (!result.company) {
      result.company = null;
    }
    if (!result.comment) {
      result.comment = null;
    }
    return result;
  };
  const confirmClick = async () => {
    const data = validForm();
    if (!data) return;

    setDisabled(true);
    const response = await sendSingleRequest(
      data,
      'POST',
      'api/import/grower-cycle',
      true,
    );
    if (response.status) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          message: translate(lang, 'Succeed'),
          type: 'success',
        }),
      );
      await dispatch(loadFarmsData());
      onConfirm(response.data);
    } else {
      showError(response.data?.message ?? 'Unknown error');
      setDisabled(false);
    }
  };

  const harvestCost = harvestExpenses.reduce((p, c) => p + Number(c.amount), 0);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      maskClosable={false}
      closeIcon={<CloseIcon />}
      width={900}
      footer={null}
    >
      <div className='section-modal wrap'>
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {`${translate(lang, 'Enter Seeding')} - ${translate(
              lang,
              'Harvest Information',
            )}`}
          </Subtitle>
        </div>
        <div className='section'>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Select line')}
              placeholder={translate(lang, 'Select line')}
              options={linesOption}
              value={curLine?.id.toString()}
              onChange={v => setCurLine(allLines.find(x => x.id === Number(v)))}
              showSearch={true}
            />
          </div>
          <div className='mb-17 item-row'>
            <div className='item-cell'>
              <Datepicker
                label={translate(lang, 'Seeded date')}
                defaultValue={formData.seed_date}
                onChange={e =>
                  e && updateForm('seed_date', e.toDate().getTime())
                }
              />
            </div>
            <div className='item-cell'>
              <Datepicker
                label={translate(lang, 'Harvested date')}
                defaultValue={formData.harvest_date}
                onChange={e =>
                  e && updateForm('harvest_date', e.toDate().getTime())
                }
              />
            </div>
          </div>
          <div className='mb-17'>
            <div className='mb-7 mt-32'>
              <Radio.Group
                className='d-flex mb-16'
                onChange={e => setSeasonType(e.target.value)}
                value={seasonType}
              >
                <RadioButton
                  label={translate(lang, 'Existing season')}
                  value='old'
                />
                <RadioButton
                  className='ml-34'
                  label={translate(lang, 'New season')}
                  value='new'
                />
              </Radio.Group>
            </div>
            <div>
              {seasonType === 'old' ? (
                <Dropdown
                  label={translate(lang, 'Season name')}
                  placeholder={translate(lang, 'Choose season')}
                  options={seasons}
                  value={formData.season_name}
                  onChange={v => updateForm('season_name', v)}
                />
              ) : (
                <Input
                  label={translate(lang, 'Season name')}
                  type='text'
                  value={formData.season_name}
                  onChange={e => updateForm('season_name', e.target.value)}
                />
              )}
            </div>
          </div>
          <div className='mb-17 item-row'>
            <div className='item-cell'>
              <Input
                type='text'
                label={translate(lang, 'Spat Source')}
                value={formData.spat_source}
                onChange={e => updateForm('spat_source', e.target.value)}
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Spat amount')}
                type='number'
                value={formData.spat_amount?.toString() ?? ''}
                unit='kg'
                onChange={e =>
                  updateForm('spat_amount', Number(e.target.value))
                }
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                label={translate(lang, 'Longline length')}
                value={formData.line_length?.toString() ?? ''}
                onChange={e =>
                  updateForm('line_length', Number(e.target.value))
                }
                unit='m'
              />
            </div>
          </div>
          <div className='mb-17 item-row'>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Seeded spat size')}
                type='number'
                value={formData.spat_size?.toString() ?? ''}
                onChange={e => updateForm('spat_size', Number(e.target.value))}
                unit='mm'
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Harvested shell size')}
                type='number'
                value={formData.shell_length?.toString() ?? ''}
                onChange={e =>
                  updateForm('shell_length', Number(e.target.value))
                }
                unit='mm'
              />
            </div>
          </div>
          <div className='mb-17 item-row'>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Harvest amount')}
                type='number'
                value={formData.harvest_amount?.toString() ?? ''}
                onChange={e =>
                  updateForm('harvest_amount', Number(e.target.value))
                }
                unit='kg'
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Harvest income')}
                type='number'
                value={formData.harvest_income?.toString() ?? ''}
                onChange={e =>
                  updateForm('harvest_income', Number(e.target.value))
                }
                unit='$'
              />
            </div>
          </div>
        </div>
        <div className='section'>
          <div className='item-row mb-17'>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Number of bags')}
                type='number'
                value={formData.bags_quantity?.toString() ?? ''}
                onChange={e =>
                  updateForm('bags_quantity', Number(e.target.value))
                }
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Number of rope bags')}
                type='number'
                value={formData.rope_bags_quantity?.toString() ?? ''}
                onChange={e =>
                  updateForm('rope_bags_quantity', Number(e.target.value))
                }
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Harvest number')}
                type='text'
                value={formData.harvest_number ?? ''}
                onChange={e => updateForm('harvest_number', e.target.value)}
              />
            </div>
          </div>
          <div className='item-row mb-17'>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Vessel')}
                type='text'
                value={formData.vessel ?? ''}
                onChange={e => updateForm('vessel', e.target.value)}
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Company')}
                type='text'
                value={formData.company ?? ''}
                onChange={e => updateForm('company', e.target.value)}
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Port of unload')}
                type='text'
                value={formData.port_of_unload ?? ''}
                onChange={e => updateForm('port_of_unload', e.target.value)}
              />
            </div>
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Comment')}
              placeholder={translate(lang, 'Please write description here')}
              type='textarea'
              value={formData.comment ?? ''}
              onChange={e => updateForm('comment', e.target.value)}
            />
          </div>
          <div className='mb-17'>
            <PDFWall
              label={translate(lang, 'Attach PDF File')}
              fileList={pdfFiles}
              onChange={v => setPdfFiles(v)}
            />
          </div>
        </div>
        <div className='section'>
          <ExpensesView
            label='Seed Items'
            items={seedExpenses}
            setItems={setSeedExpenses}
            lang={lang}
          />
          <div className='item-row mt-7 strong'>
            <div className='item-cell'>{translate(lang, 'Totals')}</div>
            <div className='item-cell'>
              {seedExpenses.reduce((p, c) => p + Number(c.quantity), 0)}
            </div>
            <div className='item-cell'></div>
            <div className='item-cell'>
              {numberToMoneyStr(
                seedExpenses.reduce((p, c) => p + Number(c.amount), 0),
              )}
            </div>
            <div className='item-cell-half'></div>
          </div>
        </div>
        <div className='section'>
          <ExpensesView
            label='Harvest Expenses'
            items={harvestExpenses}
            setItems={setHarvestExpenses}
            lang={lang}
          />
          <div className='item-row mt-7 strong'>
            <div className='item-cell'>{translate(lang, 'Totals')}</div>
            <div className='item-cell'>
              {`${formatNumber(formData.harvest_amount ?? 0)} kg`}
            </div>
            <div className='item-cell'>
              <div>{numberToMoneyStr(Number(formData.harvest_income))} ($)</div>
              <div>-{numberToMoneyStr(harvestCost)} ($)</div>
              <div>
                {`${numberToMoneyStr(
                  Number(formData.harvest_income) - harvestCost,
                )} ($)`}
              </div>
            </div>
            <div className='item-cell-half'></div>
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width='small'
            size={2}
            type='fill'
            color='red'
            className='rsp-btn'
            onClick={onClose}
            disabled={disabled}
          >
            {translate(lang, 'Cancel')}
          </Button>
          <Button
            width='small'
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={confirmClick}
            disabled={disabled}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default GrowerCycleModal;
