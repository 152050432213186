import { TLang } from '../entities/ui.entities';
import en from '../lang/en.json';
import fr from '../lang/fr.json';
import es from '../lang/es.json';
import { ReactElement } from 'react';

export const translate = (
  lang: TLang | undefined,
  content: string,
  ...values: Array<any>
) => {
  if (typeof content !== 'string') {
    try {
      content = (content as any).toString();
    } catch (error) {
      content = 'Unknown error';
    }
  }
  let idx = 0;
  const data: any =
    lang === 'en' ? en : lang === 'fr' ? fr : lang === 'es' ? es : en;
  const template = content in data ? data[content] : content;

  return template.replace(/%s/g, () =>
    idx < values.length ? values[idx++] : '',
  );
};

export const componentTranslate = (
  lang: TLang | undefined,
  content: string,
  ...values: Array<ReactElement>
) => {
  if (typeof content !== 'string') {
    try {
      content = (content as any).toString();
    } catch (error) {
      content = 'Unknown error';
    }
  }
  const data: any =
    lang === 'en' ? en : lang === 'fr' ? fr : lang === 'es' ? es : en;
  const template = content in data ? data[content] : content;
  const elements = template.split('%s');

  let result: any = [];
  for (let i = 0; i < elements.length; i++) {
    result.push(elements[i]);
    if (i < values.length) {
      result.push(values[i]);
    }
  }
  return result;
};
