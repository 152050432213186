import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectCustomFields = createSelector(
  (state: RootState) => state.automation.customFields,
  fields => fields,
);

export const selectTags = createSelector(
  (state: RootState) => state.automation.tags,
  tags => tags,
);

export const selectAssessmentFields = createSelector(
  (state: RootState) => state.automation.customFields,
  fields => fields.filter(x => x.type === 'ASSESSMENT'),
);

export const selectAutomations = createSelector(
  (state: RootState) => state.automation.automationsData,
  automations => automations.filter(x => x.type !== 'REPORT'),
);

export const selectAutoReports = createSelector(
  (state: RootState) => state.automation.automationsData,
  reports => reports.filter(x => x.type === 'REPORT'),
);
