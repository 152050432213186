export const validationForZeroMinus = (value: string | number): string => {
  const newValue = value.toString().split('');
  const validValue = newValue
    .filter((word, i) => {
      if (i === 0) {
        return Number(word) !== 0;
      }

      return word;
    })
    .filter(word => word !== '-')
    .join('');

  return validValue;
};

export const isEmailFormat = (s: string) =>
  !!s.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

export const isColorFormat = (color: string) =>
  color && /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/.test(color);
