import { useState } from 'react';
import { DatePicker, Modal } from 'antd';
import toggleSecondMillisecond from '../../../util/toggleSecondMillisecond';
import { ISeaweedLineResource } from '../../../entities/farms.entities';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Input,
  Subtitle,
} from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { sendSingleRequest } from '../../../apis';
import { showFeedback } from '../../../store/ui/ui.actions';
import { selectAccount } from '../../../store/auth/auth.selector';
import { ICustomValue } from '../../../entities/automation.entities';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
const { RangePicker } = DatePicker;

interface IFormData {
  tank_area: number | null;
  spat_size: number;
  spat_size_max: number | null;
  comment: string | null;
  images: any[];
  time_tracks?: ITimeRange[];
  cost?: number | null;
  custom_values?: ICustomValue[];
}

const defaultForm: IFormData = {
  tank_area: null,
  spat_size: 0,
  spat_size_max: null,
  comment: null,
  images: [],
  time_tracks: [],
};

interface Props {
  visible: boolean;
  title: string;
  lineData: ISeaweedLineResource;
  onCancel: () => void;
  onConfirm: (data: any) => void;
}

const SeaweedCatchSpatModal = ({
  visible,
  title,
  lineData,
  onCancel,
  onConfirm,
}: Props) => {
  const dispatch = useDispatch<any>();

  const showBudget = useSelector(selectAccount)?.show_budget_menu;
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [fieldData, setFieldData] = useState<IFormData>({
    ...defaultForm,
    tank_area: lineData.tank_area,
  });
  const [addSpatSizeRange, setAddSpatSizeRange] = useState(false);

  const fieldValid = () => {
    if (startDate === 0 || endDate === 0) {
      setError(translate(lang, 'Please select range'));
      return null;
    }
    if (
      fieldData.spat_size_max !== null &&
      fieldData.spat_size > fieldData.spat_size_max
    ) {
      setError(translate(lang, 'Invalid spat size range'));
      return null;
    }
    if (!fieldData.tank_area || fieldData.tank_area <= 0) {
      setError(translate(lang, 'Invalid tank area'));
      return null;
    }
    let res = {
      planned_date_seed: toggleSecondMillisecond(startDate),
      planned_date_harvest: toggleSecondMillisecond(endDate),
      line_id: lineData.id,
      ...fieldData,
    };
    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        setError(translate(lang, 'Invalid time tracks information'));
        return null;
      }
      res.time_tracks = tt;
    }
    return res;
  };
  const onRangeChange = (dates: any, dateStrings: any) => {
    setStartDate(dates[0].toDate().getTime());
    setEndDate(dates[1].toDate().getTime());
    setError(null);
  };
  const updateField = (key: keyof IFormData, val: any) => {
    setFieldData({ ...fieldData, [key]: val });
  };
  const toggleAddRangeCheck = (val: boolean) => {
    setAddSpatSizeRange(val);
    if (val) updateField('spat_size_max', fieldData.spat_size);
    else updateField('spat_size_max', null);
  };
  const handleConfirmClick = async () => {
    const form = fieldValid();
    if (!form) return;

    setDisabled(true);
    const res = await sendSingleRequest(
      form,
      'POST',
      'api/farm/line/catch-spat',
      true,
    );
    if (res.status) {
      dispatch(loadFarmsData());
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
    } else {
      setError(translate(lang, res.data?.message ?? 'Request failed'));
      setDisabled(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {title}
          </Subtitle>
        </div>
        <div>
          <label>{translate(lang, '_catch_spat_range')}</label>
          <div className='mt-16 mb-32'>
            <RangePicker onChange={onRangeChange} />
          </div>
          {error && (
            <div
              className='invalid-form'
              style={{ fontSize: '15px', textAlign: 'center' }}
            >
              <div className='invalid-feedback'>{error}</div>
            </div>
          )}
          {fieldData.time_tracks && (
            <TimeTrackInput
              data={fieldData.time_tracks}
              onChange={t => updateField('time_tracks', t)}
            />
          )}
          <div className='mb-7'>
            <CheckboxButton
              label={translate(lang, 'Add spat size range')}
              checked={addSpatSizeRange}
              onChange={e => toggleAddRangeCheck(e.target.checked)}
            />
          </div>
          {fieldData.spat_size_max === null ? (
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Spat Size')}
                type='number'
                value={fieldData.spat_size.toString()}
                dataType='spat_size'
                unit='mm'
                required
                onChange={e => updateField('spat_size', Number(e.target.value))}
              />
            </div>
          ) : (
            <div className='d-flex mb-17 justify-content-between'>
              <div className={`mr-12 w-100`}>
                <Input
                  label={translate(lang, 'Minimum spat size')}
                  type='number'
                  value={fieldData.spat_size.toString()}
                  required
                  onChange={e =>
                    updateField('spat_size', Number(e.target.value))
                  }
                />
              </div>
              <div className='ml-12 w-100'>
                <Input
                  label={translate(lang, 'Maximum spat size')}
                  type='number'
                  value={fieldData.spat_size_max.toString()}
                  required
                  onChange={e =>
                    updateField('spat_size_max', Number(e.target.value))
                  }
                />
              </div>
            </div>
          )}
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Tank Area')}
              type='number'
              value={fieldData.tank_area?.toString() ?? ''}
              required
              onChange={e => updateField('tank_area', Number(e.target.value))}
              unit='m²'
            />
          </div>
          {!!showBudget && (
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Cost')}
                type='number'
                value={fieldData.cost?.toString() ?? ''}
                dataType='cost'
                unit='$'
                onChange={e =>
                  updateField(
                    'cost',
                    e.target.value.length <= 0
                      ? undefined
                      : Number(e.target.value),
                  )
                }
              />
            </div>
          )}
          <div className='mt-17 mb-17'>
            <Input
              label={translate(lang, 'Comment')}
              placeholder={translate(lang, 'Comment')}
              type='textarea'
              value={fieldData.comment ?? ''}
              onChange={e => updateField('comment', e.target.value)}
            />
          </div>
          <div className='mb-17'>
            <PicturesWall
              label={translate(lang, 'Images')}
              fileList={fieldData.images}
              handleChangeImages={v => updateField('images', v)}
            />
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default SeaweedCatchSpatModal;
