import { useEffect, useState } from 'react';
import { ITrackCode } from '../../entities/task.entities';
import { sendSingleRequest } from '../../apis';
import { hashColor } from '../../entities/util-functions';
import moment from 'moment';
import { toMillisecond } from '../../util/toggleSecondMillisecond';
import { Modal, Table } from 'antd';
import { Button, CloseIcon, Spinner, Subtitle } from '../shared';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';

interface ITrackRow {
  id: number | string;
  trackTime: string;
  seedings: string;
  harvests: string;
  trackID: string;
  status: string;
  userName: string;
  color: string;
  children: Array<{
    id: number;
    trackTime: string;
    status: string;
    userName: string;
    color: string;
  }>;
}

const columns = (lang: TLang | undefined) => [
  {
    title: translate(lang, 'Date & Time'),
    key: 'time',
    render: (x: ITrackRow) => <span>{x.trackTime}</span>,
  },
  {
    title: translate(lang, 'Track ID'),
    key: 'trackID',
    render: (x: ITrackRow) => <span>{x.trackID}</span>,
  },
  {
    title: translate(lang, 'Status'),
    key: 'status',
    render: (x: ITrackRow) => <span>{x.status}</span>,
  },
  {
    title: translate(lang, 'Seedings'),
    key: 'seedings',
    render: (x: ITrackRow) => <span>{x.seedings}</span>,
  },
  {
    title: translate(lang, 'Harvests'),
    key: 'harvests',
    render: (x: ITrackRow) => <span>{x.harvests}</span>,
  },
  {
    title: translate(lang, 'User'),
    key: 'user',
    render: (x: ITrackRow) => <span>{x.userName}</span>,
  },
  {
    title: '',
    key: 'right',
    render: (x: any) => null,
  },
];

interface Props {
  visible: boolean;
  seedingIDs: number[];
  onClose: () => void;
}

const SeedingTrackModal = ({ visible, seedingIDs, onClose }: Props) => {
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(false);
  const [tracks, setTracks] = useState<ITrackRow[]>();

  useEffect(() => {
    (async () => {
      let res: ITrackRow[] = [];
      let tmp: ITrackCode[] = [];
      setLoading(true);
      for (let id of seedingIDs) {
        const response = await sendSingleRequest(
          { seeding_id: id },
          'GET',
          'api/task/track-codes',
          true,
        );
        if (response.status) {
          for (let x of response.data) {
            if (!tmp.some(y => y.track_id === x.track_id)) {
              tmp.push(x);
            }
          }
        }
      }
      tmp = tmp.filter(x => x.statuses.length > 0);
      tmp.sort(
        (a, b) => a.statuses[0].tracked_time - b.statuses[0].tracked_time,
      );
      for (let t of tmp) {
        const last = t.statuses[t.statuses.length - 1];
        const color = hashColor(
          `${Math.random() * 1000}-${last.tracked_time}-${t.track_id}`,
        );
        const harvestNumber = t.track_lines
          .filter(x => x.harvest)
          .map(x => x.harvest?.harvest_number)
          .join(', ');
        const row: ITrackRow = {
          id: t.id,
          trackTime: moment(toMillisecond(last.tracked_time)).format('lll'),
          trackID: t.track_id,
          seedings: t.track_lines
            .filter(x => x.seeding_id)
            .map(x => x.seeding_id)
            .join(', '),
          harvests: t.track_lines
            .filter(x => x.harvest?.harvest_number)
            .map(x => x.harvest?.harvest_number)
            .join(', '),
          status: last.status,
          userName: last.user?.name ?? '',
          color,
          children: t.statuses.map((x, j) => ({
            id: j,
            trackTime: moment(toMillisecond(x.tracked_time)).format('lll'),
            status: x.status,
            userName: x.user?.name ?? '',
            color: color,
          })),
        };
        res.push(row);
      }
      setTracks(res);
      setLoading(false);
    })();
  }, [seedingIDs]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={1100}
      footer={null}
    >
      <div className='section-modal wrap'>
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Track Information')}
          </Subtitle>
        </div>
        {loading ? (
          <div className='mt-32 mb-32'>
            <Spinner />
          </div>
        ) : (
          <div className='section'>
            <Table
              rowKey={'id'}
              className='table'
              columns={columns(lang)}
              pagination={false}
              dataSource={tracks}
            />
          </div>
        )}
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='blue'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SeedingTrackModal;
