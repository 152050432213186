import {
  IFarmResource,
  ILineResource,
  IMusselFarm,
  IMusselLineResource,
  IOysterFarm,
  IOysterLineResource,
  ISeaweedFarm,
  ISeaweedLineResource,
} from '../../../../entities/farms.entities';
import MusselLinesTable from './MusselLinesTable';
import OysterLinesTable from './OysterLinesTable';
import SeaweedLinesTable from './SeaweedLinesTable';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../../../store/ui/ui.selector';
import { translate } from '../../../../lib/lang.helper';
import Dropdown from '../../dropdown/Dropdown';
import Input from '../../input/Input';
import { Button } from 'antd';
import {
  selectAllTags,
  SelectIsEditable,
} from '../../../../store/extra/extra.selector';
import LineColumnModifyModal from '../../../user-settings/LineColumnModifyModal';
import { sendSingleRequest } from '../../../../apis';
import { loadFarmsData } from '../../../../store/farms/farms.actions';
import { showFeedback } from '../../../../store/ui/ui.actions';
import LineLocationModal from '../../modal/LineLocationModal';
import LinesFilterView, {
  checkLineFilter,
} from '../../../lines/LinesFilterView';
import '../styles.scss';

const lineFilterOptions = (lang: any) => [
  { id: 'all', value: 'all', label: translate(lang, 'All') },
  {
    id: 'empty_lines',
    value: 'empty_lines',
    label: translate(lang, 'Empty Lines'),
  },
  {
    id: 'ready_harvest',
    value: 'ready_harvest',
    label: translate(lang, 'Ready To Harvest'),
  },
  {
    id: 'recently_seeded',
    value: 'recently_seeded',
    label: translate(lang, 'Recently Seeded'),
  },
];

interface IFilter {
  spatSource?: string;
  tagName?: string;
  sizeIndexes: number[];
  lineName?: string;
  status: string;
}

interface Props {
  farmData: IFarmResource;
  isChild?: boolean;
}

const LinesTable = ({ farmData, isChild }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const isEditable = SelectIsEditable(farmData.id);
  const lineTags = useSelector(selectAllTags);

  const [filter, setFilter] = useState<IFilter>({
    sizeIndexes: [],
    status: 'all',
  });
  const [visModify, setVisModify] = useState(false);
  const [editLineLocation, setEditLineLocation] = useState<ILineResource>();
  const [disabled, setDisabled] = useState(false);

  const updateLineLocation = async (points: any, goNext: boolean) => {
    if (disabled || !editLineLocation) return;

    setDisabled(true);
    const response = await sendSingleRequest(
      { line_id: editLineLocation.id, points },
      'PUT',
      `api/farm/line/lines/${editLineLocation.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      setDisabled(false);
      if (goNext) {
        dispatch(
          showFeedback({
            isMessageModal: true,
            message: translate(lang, 'Line updated successfully'),
            type: 'success',
          }),
        );
        const i = farmData.lines.findIndex(x => x.id === editLineLocation.id);
        const nl = farmData.lines[(i + 1) % farmData.lines.length];
        setEditLineLocation(nl);
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            message: translate(lang, 'Line updated successfully'),
            type: 'success',
          }),
        );
        setEditLineLocation(undefined);
      }
    } else {
      setDisabled(false);
      window.alert(response.data?.message ?? 'Failed to update the line');
    }
  };

  const linesData = useMemo(() => {
    let result: ILineResource[] = [];
    if (filter.status === 'empty_lines') {
      result = farmData.lines.filter(x => !x.growing_cycle);
    } else if (filter.status === 'ready_harvest') {
      let res = farmData.lines.filter(
        x => x.growing_cycle && !!x.growing_cycle.ready_harvest,
      );
      if (res)
        res.sort((a, b) =>
          (a.growing_cycle?.ready_harvest ?? 0) >
          (b.growing_cycle?.ready_harvest ?? 0)
            ? -1
            : 1,
        );
      result = res;
    } else if (filter.status === 'recently_seeded') {
      let res = farmData.lines.filter(x => !!x.growing_cycle);
      if (res)
        res.sort((a, b) =>
          (a.growing_cycle?.main_seed.planned_date_seed ?? 0) >
          (b.growing_cycle?.main_seed.planned_date_seed ?? 0)
            ? -1
            : 1,
        );
      result = res;
    } else {
      result = farmData.lines;
    }
    if (filter.lineName) {
      result = result.filter(x =>
        x.line_name
          .toLowerCase()
          .includes(filter.lineName?.toLowerCase() ?? ''),
      );
    }
    result = result.filter(x => checkLineFilter(x, filter, lineTags));
    return result;
  }, [farmData.lines, filter, lineTags]);

  return (
    <div className='w-100'>
      {!isChild && (
        <div className='lines-filter-bar'>
          <div className='d-flex align-items-end'>
            <div style={{ minWidth: '200px' }}>
              <Dropdown
                label=''
                value={filter.status}
                options={lineFilterOptions(lang)}
                onChange={val => setFilter({ ...filter, status: val })}
                allowClear={true}
              />
            </div>
            <div className='ml-12 mr-12' style={{ width: '150px' }}>
              <Input
                type='text'
                label={translate(lang, 'Search by Line name')}
                value={filter.lineName ?? ''}
                placeholder=''
                onChange={e =>
                  setFilter({
                    ...filter,
                    lineName:
                      e.target.value.length <= 0 ? undefined : e.target.value,
                  })
                }
              />
            </div>
            <LinesFilterView
              filter={filter}
              onChange={v => setFilter({ ...filter, ...v })}
            />
          </div>
          {isEditable && (
            <Button type='primary' onClick={() => setVisModify(true)}>
              {translate(lang, 'Modify columns')}
            </Button>
          )}
        </div>
      )}
      {farmData.type === 'MUSSEL' ? (
        <MusselLinesTable
          farmData={farmData as IMusselFarm}
          linesData={linesData as IMusselLineResource[]}
          isChild={isChild}
          onEditLineLocation={setEditLineLocation}
        />
      ) : farmData.type === 'OYSTER' ? (
        <OysterLinesTable
          farmData={farmData as IOysterFarm}
          linesData={linesData as IOysterLineResource[]}
          isChild={isChild}
          onEditLineLocation={setEditLineLocation}
        />
      ) : (
        <SeaweedLinesTable
          farmData={farmData as ISeaweedFarm}
          linesData={linesData as ISeaweedLineResource[]}
          isChild={isChild}
          onEditLineLocation={setEditLineLocation}
        />
      )}
      {visModify && (
        <LineColumnModifyModal
          type={farmData.type}
          visible={true}
          onClose={() => setVisModify(false)}
        />
      )}
      {!!editLineLocation && (
        <LineLocationModal
          visible={true}
          lineData={editLineLocation}
          farmID={editLineLocation.farm_id}
          points={editLineLocation.points ?? undefined}
          onConfirm={points => updateLineLocation(points, false)}
          onConfirmNext={points => updateLineLocation(points, true)}
          onCancel={() => setEditLineLocation(undefined)}
        />
      )}
    </div>
  );
};

export default LinesTable;
