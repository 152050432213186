import { TLang } from '../entities/ui.entities';
import { ITaskSetting } from '../entities/user.entities';
import { translate } from './lang.helper';

export const getTaskOptions = (
  lang: TLang | undefined,
  customTasks: ITaskSetting[] | null = null,
) => {
  let result = [
    { id: 'GENERAL', value: 'GENERAL', label: 'General' },
    { id: 'SEEDING', value: 'SEEDING', label: 'Seeding' },
    { id: 'ASSESSMENT', value: 'ASSESSMENT', label: 'Assessment' },
    { id: 'MAINTENANCE', value: 'MAINTENANCE', label: 'Maintenance' },
    { id: 'HARVEST', value: 'HARVEST', label: 'Harvest' },
    {
      id: 'INVENTORY_SEED',
      value: 'INVENTORY_SEED',
      label: 'Inventory Management',
    },
  ].map(x => ({ ...x, label: translate(lang, x.label) }));
  if (customTasks) {
    result.push(
      ...customTasks.map(x => ({ id: x.name, value: x.name, label: x.name })),
    );
  }
  return result;
};

export const isNormalTask = (type: string | null | undefined) => {
  return (
    !type ||
    ![
      'SEEDING',
      'ASSESSMENT',
      'MAINTENANCE',
      'HARVEST',
      'INVENTORY_SEED',
    ].includes(type)
  );
};
