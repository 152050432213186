import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title,
  Feedback,
  Input,
  Button,
  CheckboxButton,
  Spinner,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import { showFeedback } from '../../store/ui/ui.actions';
import {
  authLoadMe,
  updateAccountSetting,
} from '../../store/auth/auth.actions';
import FinanceSettings from './FinanceSettings';
import AccountXeroModal from '../../components/users/AccountXeroModal';
import { sendSingleRequest } from '../../apis';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const Xero = () => {
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const callbackURL = `${process.env.REACT_APP_API_URL}xero/callback/${btoa(
    profile?.id.toString() ?? '',
  )}`;

  const [loading, setLoading] = useState(false);
  const [showXero, setShowXero] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const updateSetting = (show_budget_menu: boolean) => {
    setLoading(true);
    dispatch(updateAccountSetting({ show_budget_menu })).then((res: any) => {
      setLoading(false);
      if (res !== true) {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, res.data?.message ?? 'Failed'),
          }),
        );
      }
    });
  };
  const deleteXero = async (account_xero_id: number) => {
    if (!window.confirm(translate(lang, 'Are you sure to delete this data?')))
      return;
    setDisabled(true);
    await sendSingleRequest(
      { account_xero_id },
      'DELETE',
      'api/user/account-xero',
      true,
    );
    await dispatch(authLoadMe(true));
    setDisabled(false);
  };
  const refreshXero = async (account_xero_id: number) => {
    setDisabled(true);
    const response = await sendSingleRequest(
      { account_xero_id },
      'POST',
      'api/user/refresh-xero',
      true,
    );
    setDisabled(false);
    if (response.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Refreshed successfully'),
        }),
      );
    } else if (response.data.reload_token) {
      const url = `${process.env.REACT_APP_API_URL}xero/reconnect?reload_token=${response.data.reload_token}`;

      window.location.replace(url);
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, response.data?.message ?? 'Unknown error'),
        }),
      );
    }
  };

  return (
    <>
      <div className='content pb-32' style={{ maxWidth: '777px' }}>
        {width > 768 && (
          <Title
            className='mb-24'
            size={5}
            color='black-3'
            align='default'
            fontWeight={600}
          >
            {translate(lang, 'Budget Setting')}
          </Title>
        )}
        <div className='d-flex mb-24 justify-content-between'>
          <CheckboxButton
            label={translate(lang, 'Manage budget')}
            checked={!!profile?.account?.show_budget_menu}
            onChange={e => updateSetting(e.target.checked)}
          />
          {loading && <Spinner />}
        </div>
        {!profile?.xero_connects || profile.xero_connects.length <= 0 ? (
          <Feedback
            className='mb-32'
            isWithoutClosable
            theme='light'
            message={translate(lang, '_xero_no_connected')}
            type='warning'
          />
        ) : (
          <Feedback
            className='mb-32'
            isWithoutClosable
            theme='light'
            message={translate(lang, 'Connected Xero Accounts')}
            type='info'
          />
        )}
        <div className='mb-16'>
          {profile?.xero_connects?.map(x => (
            <div key={x.id} className='xero-item'>
              <div className='mb-7'>
                <div className='--label'>{translate(lang, 'Client ID')}</div>
                <div className='--value'>{x.client_id}</div>
              </div>
              <div className='mb-7'>
                <div className='--label'>
                  {translate(lang, 'Client Secret')}
                </div>
                <div className='--value'>
                  {`${'*'.repeat(44)}${x.client_secret}`}
                </div>
              </div>
              <div className='mb-7'>
                <div className='--label'>{translate(lang, 'Tenant ID')}</div>
                <div className='--value'>
                  {`${'*'.repeat(32)}${x.tenant_id}`}
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <Button
                  className='rsp-btn mr-7'
                  width='small'
                  size={0}
                  type='fill'
                  color='green'
                  onClick={() => refreshXero(x.id)}
                  disabled={disabled}
                >
                  {translate(lang, 'Refresh')}
                </Button>
                <Button
                  width='small'
                  size={0}
                  type='fill'
                  color='red'
                  className='rsp-btn'
                  onClick={() => deleteXero(x.id)}
                  disabled={disabled}
                >
                  {translate(lang, 'Delete')}
                </Button>
              </div>
            </div>
          ))}
        </div>
        {showXero && (
          <AccountXeroModal visible={true} onClose={() => setShowXero(false)} />
        )}
        <Input
          type='text'
          className='mb-16'
          value={callbackURL}
          label='OAuth 2.0 redirect URI'
          placeholder='OAuth 2.0 redirect URI'
          max={255}
          required
          disabled
        />
        <Button
          className='mt-16 mb-32'
          color='blue'
          size={1}
          width={width < 769 ? 'wide' : 'small'}
          onClick={() => setShowXero(true)}
          type='fill'
        >
          {translate(lang, 'Add Xero Account')}
        </Button>
        <FinanceSettings />
      </div>
    </>
  );
};

export default Xero;
