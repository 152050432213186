import { Switch, useLocation } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Header from '../components/header/Header';
import Loading from './static/Loading';
import NotFound from './static/NotFound';
import { useDispatch, useSelector } from 'react-redux';
import AdminRoute from './admin/AdminRoute';
import { useEffect, useLayoutEffect } from 'react';
import { clearFeedback } from '../store/ui/ui.actions';
import ProtectedRoute from '../components/ProtectedRoute';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import RecoverPassword from './auth/RecoverPassword';
import SecondRecoverPassword from './auth/SecondRecoverPassword';
import { RegistrationMessage } from '../components/sign-up';
import { PasswordMessage } from '../components/recover-password';
import { updateSystemSettings } from '../store/utils/utils.actions';
import { authLoadMe, updateUserMeta } from '../store/auth/auth.actions';
import { is_role_allowed, is_user_allowed } from '../entities/util-functions';
import { selectProfile, selectToken } from '../store/auth/auth.selector';
import { selectPusher } from '../store/utils/utils.selector';
import Pusher from 'pusher-js/with-encryption';
import { newMessageReceivedAction } from '../store/users/users.actions';
import { normalRoutes } from './RouterHelper';
import OnboardingPage from './static/OnboardingPage';
import Footer from '../components/header/Footer';

const Router = () => {
  const dispatch = useDispatch<any>();
  const location = useLocation();

  const token = useSelector(selectToken);
  const profile = useSelector(selectProfile);
  const { key: pusherKey, cluster: pusherCluster } = useSelector(selectPusher);

  useLayoutEffect(() => {
    dispatch(clearFeedback());
    dispatch(updateSystemSettings());

    return () => {};
  }, [dispatch]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (token) {
      dispatch(authLoadMe());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (profile?.id && pusherKey && pusherCluster) {
      const pusher = new Pusher(pusherKey, { cluster: pusherCluster });
      pusher
        .subscribe('account-user-meta')
        .bind(`account-${profile?.account_id}`, function (data: any) {
          dispatch(updateUserMeta());
        });
      pusher
        .subscribe('account-message')
        .bind(
          `message.created.to.${profile?.account_id}`,
          function (data: any) {
            dispatch(newMessageReceivedAction('ACCOUNT', data?.message));
          },
        );
      pusher
        .subscribe('boat-message')
        .bind(`message.created.to.${profile.account_id}`, function (data: any) {
          dispatch(newMessageReceivedAction('BOAT', data?.message));
        });
      pusher
        .subscribe('user-message')
        .bind(`message.sent.to.${profile?.id}`, function (data: any) {
          dispatch(newMessageReceivedAction('USER', data?.message));
        });
    }
  }, [profile?.id, pusherKey, pusherCluster, dispatch]);

  return !token ? (
    <>
      <ProtectedRoute path='/sign-in/:checked?' component={SignIn} exact />
      <ProtectedRoute path='/sign-up' component={SignUp} exact />
      <ProtectedRoute
        path='/recover-password/email'
        component={RecoverPassword}
        exact
      />
      <ProtectedRoute
        path='/recover-password/password/:token/:email'
        component={SecondRecoverPassword}
        exact
      />
      <ProtectedRoute
        path='/message-registration'
        component={RegistrationMessage}
        exact
      />
      <ProtectedRoute
        path='/message-password'
        component={PasswordMessage}
        exact
      />
      <ProtectedRoute path='/onboarding' component={OnboardingPage} exact />
    </>
  ) : (
    <div>
      <Header />
      {profile?.type === 'admin' ? (
        <AdminRoute />
      ) : (
        <Switch>
          {normalRoutes
            .filter(x => is_user_allowed(profile?.account_type, x.module))
            .filter(x => is_role_allowed(profile?.role, x.route.path))
            .map((r, i) => (
              <PrivateRoute
                key={i}
                path={r.route.path}
                component={r.route.component}
                exact={r.route.exact}
                isAdmin={r.route.isAdmin}
                isFinance={r.route.isFinance}
              />
            ))}
          <PrivateRoute path='/loading/:type' component={Loading} exact />
          <PrivateRoute path='*' component={NotFound} exact={false} />
        </Switch>
      )}
      <Footer />
    </div>
  );
};

export default Router;
