import { useState } from 'react';
import { Alert, Checkbox, Table, Button as OrgButton, message } from 'antd';
import {
  Button,
  Datepicker,
  Dropdown,
  Input,
  Title,
} from '../../components/shared';
import { sendMultipart, sendSingleRequest } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { formatNumber } from '../../entities/util-functions';
import { selectBoats } from '../../store/users/users.selector';
import { IBoatResource } from '../../entities/boat.entities';
import moment from 'moment';
import { toSecTimeTracks } from '../../components/shared/input/TimeTrackInput';
import { toSecond } from '../../util/toggleSecondMillisecond';
import { getTaskData } from '../../store/tasks/tasks.actions';
import { updateUserMeta } from '../../store/auth/auth.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import { selectCustomTasks } from '../../store/tasks/tasks.selector';
import { selectUtils } from '../../store/utils/utils.selector';
import { showFeedback } from '../../store/ui/ui.actions';
import './styles.scss';

interface IRow {
  id: number;
  date: number;
  start_time: string;
  finish_time: string;
  farm_id: number | null;
  line_id: number | null;
  client: string | null;
  inventory: string | null;
  comment: string | null;
  is_billable?: boolean;
  task_type?: string;
}

interface ITaskRow {
  id: number;
  farm_id: number | null;
  farm_name: string;
  line_id: number | null;
  line_name: string;
  title: string;
  content: string | null;
  due_date: number;
  type: string;
  start_time: number;
  finish_time: number;
  is_billable: boolean;
}

const taskColumns = (lang: TLang | undefined, customTask: any): any => [
  {
    title: translate(lang, 'Farm'),
    key: 'farm_name',
    render: (x: ITaskRow) => <span>{x.farm_name}</span>,
  },
  {
    title: translate(lang, 'Line'),
    key: 'line_name',
    render: (x: ITaskRow) => <span>{x.line_name}</span>,
  },
  {
    title: translate(lang, 'Title'),
    key: 'title',
    render: (x: ITaskRow) => <span>{x.title}</span>,
  },
  {
    title: translate(lang, 'Content'),
    key: 'content',
    render: (x: ITaskRow) => <span>{x.content}</span>,
  },
  {
    title: translate(lang, 'Due date'),
    key: 'due_date',
    render: (x: ITaskRow) => (
      <span>{moment(x.due_date).format('DD.MM.YYYY')}</span>
    ),
  },
  {
    title: translate(lang, 'Start time'),
    key: 'start_time',
    render: (x: ITaskRow) => <span>{moment(x.start_time).format('H.mm')}</span>,
  },
  {
    title: translate(lang, 'Finish time'),
    key: 'finish_time',
    render: (x: ITaskRow) => (
      <span>{moment(x.finish_time).format('H.mm')}</span>
    ),
  },
  {
    title: translate(lang, 'Type'),
    key: 'type',
    align: 'left',
    render: (x: ITaskRow) => <span>{x.type}</span>,
  },
  {
    title: translate(lang, 'Billable'),
    key: 'type',
    align: 'left',
    render: (x: ITaskRow) => <span>{x.is_billable ? 'Yes' : 'No'}</span>,
  },
];

function getLastThreeMonthsMondays(): Date[] {
  const result: Date[] = [];
  const today = new Date();
  const threeMonthsAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 3,
    today.getDate(),
  );

  let day = new Date(threeMonthsAgo);
  day.setDate(day.getDate() + ((1 + 7 - day.getDay()) % 7));

  while (day <= today) {
    if (day.getDay() === 1) {
      result.push(new Date(day));
    }
    day.setDate(day.getDate() + 7);
  }
  return result.reverse();
}

const toDate = (d: number, h: string) => {
  let res = moment(d);
  if (h.includes('.')) {
    const t = h.split('.');
    res = res.set('hour', Number(t[0])).set('minute', Number(t[1]));
  } else {
    res = res.set('hour', Number(h)).set('minute', 0);
  }
  return res.toDate().getTime();
};

const hoursDiff = (s1: string | null, s2: string | null) => {
  if (!s1) s1 = '0';
  if (!s2) s2 = '0';

  let t1 = s1.split('.').map(x => Number(x));
  if (t1.length < 2) t1.push(0);

  let t2 = s2.split('.').map(x => Number(x));
  if (t2.length < 2) t2.push(0);

  return formatNumber(t2[0] - t1[0] + (t2[1] - t1[1]) / 60);
};

const captureActivity = (
  act: string | null | undefined,
  customTasks: string[],
) =>
  !act
    ? 'GENERAL'
    : customTasks.find(x => x.toLowerCase() === act.toLowerCase()) ?? 'GENERAL';

const ImportTimeSheetPage = () => {
  const dispatch = useDispatch<any>();
  const farmsData = useSelector(selectFarmsData);
  const boatsData = useSelector(selectBoats);
  const lang = useSelector(selectLang);
  const customTasks = useSelector(selectCustomTasks);
  const customers = useSelector(selectUtils).filter(x => x.type === 'customer');

  const farmsOption = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.farm_number} ${x.name}`,
  }));
  const mondayOptions = getLastThreeMonthsMondays().map(x => ({
    id: moment(x).format('YYYY-MM-DD'),
    value: moment(x).format('YYYY-MM-DD'),
    label: moment(x).format('DD MMMM YYYY'),
  }));

  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState<IRow[]>([]);
  const [boat, setBoat] = useState<IBoatResource>();
  const [tasks, setTasks] = useState<ITaskRow[]>([]);
  const [monday, setMonday] = useState(mondayOptions[0].value);

  const importExcel = async () => {
    const file = document.createElement('input');
    file.type = 'file';
    file.accept =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    file.click();
    file.onchange = async (e: any) => {
      if (!e.target.files || e.target.files.length <= 0) return;

      const form = new FormData();
      form.append('excel', e.target.files[0]);
      form.append('monday', monday);

      setDisabled(true);
      const response = await sendMultipart(
        form,
        'POST',
        'api/import/time-sheet',
        true,
      );
      setTasks([]);
      setDisabled(false);
      if (response.status) {
        const tmp = response.data.map((x: any) => ({
          ...x,
          date: moment(x.date).toDate().getTime(),
          task_type: captureActivity(
            x.activity,
            customTasks?.map(x => x.name) ?? [],
          ),
          is_billable:
            x.client && !customers.some(y => y.flag && y.name === x.client),
        }));
        setData(tmp);
      } else {
        window.alert(
          translate(lang, response.data?.message ?? 'Unknown error'),
        );
      }
    };
    file.remove();
  };
  const updateForm = (i: number, key: keyof IRow, value: any) => {
    let tmp: any = [...data];
    tmp[i][key] = value;
    if (key === 'client') {
      const ba = !customers.some(x => x.flag && x.name === value);
      tmp[i]['is_billable'] = ba;
    }
    setData(tmp);
  };
  const selectBoat = (v: string) => {
    setBoat(boatsData.find(x => x.id === Number(v)));
  };
  const previewClick = () => {
    if (data.filter(x => !!x.task_type).length <= 0) {
      window.alert(
        translate(lang, 'Please select at least one task to convert'),
      );
      return;
    }
    if (!boat) {
      window.alert(translate(lang, 'Please select a boat'));
      return;
    }
    const tasks: ITaskRow[] = data
      .filter(x => !!x.task_type)
      .map(x => {
        const farm = farmsData.find(y => y.id === x.farm_id);
        const line = farm?.lines.find(y => y.id === x.line_id);

        return {
          id: x.id,
          farm_id: farm?.id ?? null,
          line_id: line?.id ?? null,
          farm_name: farm?.name ?? '',
          line_name: line?.line_name ?? '',
          title: x.client ?? x.task_type ?? '',
          content: [x.inventory, x.comment].filter(y => !!y).join('\n'),
          due_date: moment(x.date).toDate().getTime(),
          type: x.task_type ?? 'GENERAL',
          start_time: toDate(x.date, x.start_time),
          finish_time: toDate(x.date, x.finish_time),
          is_billable: x.is_billable ?? false,
        };
      });
    setTasks(tasks);
  };
  const confirmClick = async () => {
    if (tasks.length <= 0) return;

    let tasksCount = 0,
      actionsCount = 0;

    setDisabled(true);
    for (let task of tasks) {
      const tt = toSecTimeTracks([
        { start_time: task.start_time, finish_time: task.finish_time },
      ]);
      const form = {
        farm_id: task.farm_id,
        line_id: task.line_id,
        title: task.title,
        content: task.content,
        due_date: task.due_date,
        is_completed: true,
        boat_id: boat?.id,
        type: task.type,
        time_tracks: tt ? tt : undefined,
        is_billable: task.is_billable,
      };
      const response = await sendSingleRequest(
        form,
        'POST',
        'api/task/tasks',
        true,
      );
      if (!response.status) {
        message.error(
          translate(
            lang,
            'Error when creating task %s: %s',
            form.title,
            response.data?.message,
          ),
        );
        continue;
      } else {
        message.success(
          translate(lang, 'Task %s is created successfully.', form.title),
        );
        tasksCount++;
      }
      const act = customTasks?.find(x => x.name === task.type);
      if (!act || !act.action) continue;

      const farm = farmsData.find(x => x.id === task.farm_id);
      if (!farm) {
        message.error(
          translate(lang, 'Farm is not exist for task %s', form.title),
        );
        continue;
      }
      const line = farm.lines.find(x => x.id === task.line_id);
      if (!line) {
        message.error(
          translate(lang, 'Line is not exist for task %s', form.title),
        );
        continue;
      }
      const task_id = response.data.id;
      if (act?.action === 'SEEDING') {
        const tmp = {
          line_id: line.id,
          planned_date_seed: toSecond(task.due_date),
          planned_date_harvest: toSecond(task.due_date),
          task_id,
          time_tracks: tt ? tt : undefined,
          comment: task.content,
        };
        const res = await sendSingleRequest(
          { type: 'seeding', data: tmp },
          'POST',
          'api/queue/entity',
          true,
        );
        if (!res.status) {
          message.error(
            translate(
              lang,
              'Error when creating seeding validation for task %s: %s',
              form.title,
              res.data?.message,
            ),
          );
        } else {
          message.success(
            translate(
              lang,
              'Seeding validation for task %s is created successfully.',
              form.title,
            ),
          );
          actionsCount++;
        }
      } else if (act?.action === 'ASSESSMENT') {
        if (!line.growing_cycle) {
          message.error(
            translate(
              lang,
              'Error when creating assessment validation for task %s: Line %s is empty now.',
              form.title,
              line.line_name,
            ),
          );
          continue;
        }
        const tmp = {
          line_id: line.id,
          assessment_date: toSecond(task.due_date),
          planned_date_harvest: toSecond(task.due_date),
          seeding_id: line.growing_cycle.main_seed.id,
          task_id,
          time_tracks: tt ? tt : undefined,
          comment: task.content,
        };
        const res = await sendSingleRequest(
          { type: 'assessment', data: tmp },
          'POST',
          'api/queue/entity',
          true,
        );
        if (!res.status) {
          message.error(
            translate(
              lang,
              'Error when creating assessment validation for task %s: %s',
              form.title,
              res.data?.message,
            ),
          );
        } else {
          message.success(
            translate(
              lang,
              'Assessment validation for task %s is created successfully.',
              form.title,
            ),
          );
          actionsCount++;
        }
      } else if (act?.action === 'MAINTENANCE') {
        if (!line.growing_cycle) {
          message.error(
            translate(
              lang,
              'Error when creating maintenance validation for task %s: Line %s is empty now.',
              form.title,
              line.line_name,
            ),
          );
          continue;
        }
        const tmp = {
          line_id: line.id,
          maintain_date: toSecond(task.due_date),
          seeding_id: line.growing_cycle.main_seed.id,
          task_id,
          time_tracks: tt ? tt : undefined,
          comment: task.content,
        };
        const res = await sendSingleRequest(
          { type: 'maintenance', data: tmp },
          'POST',
          'api/queue/entity',
          true,
        );
        if (!res.status) {
          message.error(
            translate(
              lang,
              'Error when creating maintenance validation for task %s: %s',
              form.title,
              res.data?.message,
            ),
          );
        } else {
          message.success(
            translate(
              lang,
              'Maintenance validation for task %s is created successfully.',
              form.title,
            ),
          );
          actionsCount++;
        }
      } else if (act?.action === 'HARVEST') {
        if (!line.growing_cycle) {
          message.error(
            translate(
              lang,
              'Error when creating harvest validation for task %s: Line %s is empty now.',
              form.title,
              line.line_name,
            ),
          );
          continue;
        }
        const tmp = {
          line_id: line.id,
          complete_date: toSecond(task.due_date),
          task_id,
          time_tracks: tt ? tt : undefined,
          comment: task.content,
        };
        const res = await sendSingleRequest(
          { type: 'harvest', data: tmp },
          'POST',
          'api/queue/entity',
          true,
        );
        if (!res.status) {
          message.error(
            translate(
              lang,
              'Error when creating harvest validation for task %s: %s',
              form.title,
              res.data?.message,
            ),
          );
        } else {
          message.success(
            translate(
              lang,
              'Harvest validation for task %s is created successfully.',
              form.title,
            ),
          );
          actionsCount++;
        }
      }
    }
    await dispatch(updateUserMeta());
    setDisabled(false);
    dispatch(
      showFeedback({
        isMessage: true,
        message: translate(
          lang,
          'Imported %s tasks and %s validations',
          tasksCount,
          actionsCount,
        ),
        type: 'success',
      }),
    );
    await dispatch(getTaskData());
  };
  const getAction = (t: string | undefined) =>
    customTasks?.find(x => x.name === t)?.action;

  const columns: any = [
    {
      title: translate(lang, 'Date'),
      key: 'date',
      width: 170,
      render: (a: any, x: IRow, i: number) => (
        <div>
          <div style={{ textAlign: 'center' }}>
            {moment(x.date).format('dddd')}
          </div>
          <Datepicker
            label=''
            defaultValue={x.date}
            onChange={e => e && updateForm(i, 'date', e.toDate().getTime())}
            disabled={disabled}
            format='DD MMMM YYYY'
          />
        </div>
      ),
    },
    {
      title: translate(lang, 'Start time'),
      key: 'start_time',
      width: 80,
      render: (a: any, x: IRow, i: number) => (
        <Input
          label=''
          type='text'
          onChange={e => updateForm(i, 'start_time', e.target.value)}
          value={x.start_time ?? ''}
        />
      ),
    },
    {
      title: translate(lang, 'Finish time'),
      key: 'finish_time',
      width: 80,
      render: (a: any, x: IRow, i: number) => (
        <Input
          label=''
          type='text'
          onChange={e => updateForm(i, 'finish_time', e.target.value)}
          value={x.finish_time ?? ''}
        />
      ),
    },
    {
      title: translate(lang, 'Farm'),
      key: 'farm_id',
      render: (a: any, x: IRow, i: number) => (
        <div style={{ width: 200 }}>
          <Dropdown
            label=''
            options={farmsOption}
            onChange={v => {
              if (v) {
                updateForm(i, 'farm_id', Number(v));
                updateForm(i, 'line_id', null);
              }
            }}
            value={x.farm_id?.toString()}
          />
        </div>
      ),
    },
    {
      title: translate(lang, 'Line'),
      key: 'line_id',
      render: (a: any, x: IRow, i: number) => (
        <div style={{ width: 100 }}>
          <Dropdown
            label=''
            options={
              farmsData
                .find(y => y.id === x.farm_id)
                ?.lines.map(y => ({
                  id: y.id.toString(),
                  value: y.id.toString(),
                  label: y.line_name,
                })) ?? []
            }
            onChange={v => v && updateForm(i, 'line_id', Number(v))}
            value={x.line_id?.toString()}
          />
        </div>
      ),
    },
    {
      title: translate(lang, 'Client'),
      key: 'client',
      render: (a: any, x: IRow, i: number) => (
        <Dropdown
          label=''
          options={customers.map(x => ({
            id: x.name,
            value: x.name,
            label: x.name,
          }))}
          value={x.client ?? undefined}
          onChange={v => updateForm(i, 'client', v)}
        />
      ),
    },
    {
      title: translate(lang, 'Inventory'),
      key: 'inventory',
      render: (a: any, x: IRow, i: number) => (
        <Input
          label=''
          type='text'
          onChange={e => updateForm(i, 'inventory', e.target.value)}
          value={x.inventory ?? ''}
        />
      ),
    },
    {
      title: translate(lang, 'Comment'),
      key: 'comment',
      render: (a: any, x: IRow, i: number) => (
        <Input
          label=''
          type='textarea'
          onChange={e => updateForm(i, 'comment', e.target.value)}
          value={x.comment ?? ''}
        />
      ),
    },
    {
      title: translate(lang, 'Total hours'),
      key: 'total_time',
      align: 'center',
      render: (a: any, x: IRow, i: number) => (
        <div>{hoursDiff(x.start_time, x.finish_time)}</div>
      ),
    },
    {
      title: translate(lang, 'Billable'),
      key: 'billable',
      align: 'center',
      render: (a: any, x: IRow, i: number) => (
        <Checkbox
          checked={x.is_billable}
          onChange={e => updateForm(i, 'is_billable', e.target.checked)}
        />
      ),
    },
    {
      title: translate(lang, 'Convert to task'),
      key: 'action',
      width: 180,
      align: 'left',
      render: (a: any, x: IRow, i: number) => (
        <Dropdown
          label=''
          options={
            customTasks?.map(y => ({
              id: y.name,
              value: y.name,
              label: y.name,
            })) ?? []
          }
          value={x.task_type}
          onChange={v => updateForm(i, 'task_type', v)}
        />
      ),
    },
  ];

  const totalHours = formatNumber(
    data.reduce(
      (p, c) => p + Number(hoursDiff(c.start_time, c.finish_time)),
      0,
    ),
  );

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container timesheet-container'>
        <div className='pt-32 d-flex align-items-center'>
          <Title size={5} color='black-3' align='default' fontWeight={700}>
            {translate(lang, 'Import timesheet')}
          </Title>
        </div>
        <div className='mb-16'>
          <div className='d-flex justify-content-end align-items-end'>
            <div className='mr-16'>
              <Dropdown
                label={translate(lang, 'Monday start date')}
                options={mondayOptions}
                value={monday}
                onChange={v => setMonday(v)}
              />
            </div>
            <div className='mr-16 ml-16'>
              <Button
                width={'small'}
                size={2}
                type='fill'
                color='blue'
                disabled={disabled}
              >
                {translate(lang, 'Download Template')}
              </Button>
            </div>
            <div className='ml-16'>
              <Button
                width={'small'}
                size={2}
                type='fill'
                color='green'
                onClick={importExcel}
                disabled={disabled}
              >
                {translate(lang, 'Import Excel')}
              </Button>
            </div>
          </div>
          <div className='mt-32 pb-32'>
            <Table
              className='table table--units'
              rowKey={'id'}
              pagination={false}
              columns={columns}
              dataSource={data}
            />
            <div
              style={{
                textAlign: 'right',
                fontSize: 20,
                fontWeight: 'bold',
                backgroundColor: 'white',
                paddingRight: 50,
                paddingBottom: 20,
              }}
            >
              {`${translate(lang, 'Total hours')}: ${totalHours}`}
            </div>
          </div>
          <div className='d-flex pb-32 align-items-end'>
            <div className='mr-16' style={{ width: 300 }}>
              <Dropdown
                label={translate(lang, 'Select boat')}
                options={boatsData.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} ${x.reg_number}`,
                }))}
                value={boat?.id.toString()}
                onChange={v => selectBoat(v)}
              />
            </div>
            <div className='ml-16'>
              <Button
                width={'small'}
                size={2}
                type='fill'
                color='green'
                onClick={previewClick}
                disabled={disabled}
              >
                {translate(lang, 'Preview import')}
              </Button>
            </div>
          </div>
          {tasks.length > 0 && (
            <div className='pb-32'>
              <Table
                className='table table--units'
                rowKey={'id'}
                pagination={false}
                columns={taskColumns(lang, customTasks)}
                dataSource={tasks}
              />
              <div style={{ backgroundColor: 'white', padding: 20 }}>
                <Alert
                  className='mt-18 mb-18 suggest-info'
                  message={'This export will generate:'}
                  style={{ maxWidth: '100%', width: '100%' }}
                  description={
                    <div>
                      <div className='mb-7'>
                        <ul>
                          <li>
                            {componentTranslate(
                              lang,
                              '%s generic tasks',
                              <strong key={1}>
                                {tasks.filter(x => !getAction(x.type)).length}
                              </strong>,
                            )}
                          </li>
                          <li>
                            {componentTranslate(
                              lang,
                              '%s seedings',
                              <strong key={1}>
                                {
                                  tasks.filter(
                                    x => getAction(x.type) === 'SEEDING',
                                  ).length
                                }
                              </strong>,
                            )}
                          </li>
                          <li>
                            {componentTranslate(
                              lang,
                              '%s harvests',
                              <strong key={1}>
                                {
                                  tasks.filter(
                                    x => getAction(x.type) === 'HARVEST',
                                  ).length
                                }
                              </strong>,
                            )}
                          </li>
                          <li>
                            {componentTranslate(
                              lang,
                              '%s assessments',
                              <strong key={1}>
                                {
                                  tasks.filter(
                                    x => getAction(x.type) === 'ASSESSMENT',
                                  ).length
                                }
                              </strong>,
                            )}
                          </li>
                          <li>
                            {componentTranslate(
                              lang,
                              '%s maintenances',
                              <strong key={1}>
                                {
                                  tasks.filter(
                                    x => getAction(x.type) === 'MAINTENANCE',
                                  ).length
                                }
                              </strong>,
                            )}
                          </li>
                        </ul>
                        <div>
                          <strong>
                            {translate(lang, '_import_validation_warning')}
                          </strong>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <OrgButton
                          size='small'
                          type='primary'
                          onClick={confirmClick}
                          disabled={disabled}
                        >
                          {translate(lang, 'Confirm')}
                        </OrgButton>
                      </div>
                    </div>
                  }
                  showIcon={true}
                  type='info'
                  closable={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportTimeSheetPage;
