import { useEffect, useMemo, useState } from 'react';
import { IHarvestResource } from '../../entities/growing.entities';
import { Modal, Table } from 'antd';
import { Button, CloseIcon, Spinner, Subtitle } from '../shared';
import { formatNumber, hashColor } from '../../entities/util-functions';
import { sendSingleRequest } from '../../apis';
import { ITrackCode } from '../../entities/task.entities';
import { TrackStatus } from '../../entities/general.entities';
import moment from 'moment';
import { toMillisecond } from '../../util/toggleSecondMillisecond';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';

interface ITrackRow {
  id: number | string;
  trackTime: string;
  harvestNumber: string;
  trackID: string;
  status: string;
  userName: string;
  color: string;
  children: Array<{
    id: number;
    trackTime: string;
    status: string;
    userName: string;
    color: string;
  }>;
}

const trackColumns = (lang: TLang | undefined) => [
  {
    title: translate(lang, 'Date & Time'),
    key: 'time',
    render: (x: ITrackRow) => <span>{x.trackTime}</span>,
    width: '25%',
  },
  {
    title: translate(lang, 'Track ID'),
    key: 'trackID',
    render: (x: ITrackRow) => <span>{x.trackID}</span>,
  },
  {
    title: translate(lang, 'Harvest Number'),
    key: 'harvestNumber',
    render: (x: ITrackRow) => <span>{x.harvestNumber}</span>,
  },
  {
    title: translate(lang, 'Status'),
    key: 'status',
    render: (x: ITrackRow) => (
      <span style={{ textTransform: 'capitalize' }}>
        {x.status === TrackStatus.MIDDLE_HARVESTED
          ? TrackStatus.HARVESTED
          : x.status}
      </span>
    ),
  },
  {
    title: translate(lang, 'User'),
    key: 'user',
    render: (x: ITrackRow) => <span>{x.userName}</span>,
  },
  {
    title: '',
    key: 'right',
    render: (x: ITrackRow) => null,
  },
];

const bagColumns = (lang: TLang | undefined) => [
  {
    title: translate(lang, 'Harvest ID'),
    key: 'harvest_id',
    render: (x: ITrackCode) => (
      <span>
        {x.track_lines
          .filter(y => y.harvest)
          .map(y => y.harvest?.id)
          .join(', ')}
      </span>
    ),
  },
  {
    title: translate(lang, 'Track ID'),
    key: 'track_id',
    render: (x: ITrackCode) => <span>{x.track_id}</span>,
  },
  {
    title: translate(lang, 'Harvested Total'),
    key: 'harvested_total',
    render: (x: ITrackCode) => {
      const amount = x.track_lines
        .filter(y => y.harvest)
        .reduce((p, c) => p + (c.harvest?.amount ?? 0), 0);
      return <span className='strong'>{formatNumber(amount)}</span>;
    },
  },
  {
    title: translate(lang, 'Processed Total'),
    key: 'processed_total',
    render: (x: ITrackCode) => {
      const bag_details = x.qr_data?.bag_details;
      let sum = 0;
      if (bag_details) {
        sum = bag_details.reduce((p: number, c: any) => p + c.weight, 0);
      }
      return <span className='strong'>{formatNumber(sum)}</span>;
    },
  },
  {
    title: '',
    key: 'right',
  },
];

const bagDataColumns = (lang: TLang | undefined) => [
  { title: '', key: 'first' },
  {
    title: translate(lang, 'Name'),
    key: 'name',
    render: (x: any) => <span>{x?.name}</span>,
  },
  {
    title: translate(lang, 'Weight'),
    key: 'weight',
    render: (x: any) => <span>{formatNumber(x?.weight)}</span>,
  },
  {
    title: '',
    key: 'right',
  },
];

interface Props {
  visible: boolean;
  trackIDs?: string[];
  harvests?: IHarvestResource[];
  onClose: () => void;
}

const HarvestTrackModal = ({ visible, harvests, trackIDs, onClose }: Props) => {
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(false);
  const [tracks, setTracks] = useState<ITrackCode[]>([]);

  const trackRows = useMemo(() => {
    let trackRows: ITrackRow[] = [];
    for (const t of tracks) {
      const last = t.statuses[t.statuses.length - 1];
      const color = hashColor(
        `${Math.random() * 1000}-${last.tracked_time}-${t.track_id}`,
      );
      const harvestNumber = t.track_lines
        .filter(x => x.harvest)
        .map(x => x.harvest?.harvest_number)
        .join(', ');
      const row: ITrackRow = {
        id: t.id,
        trackTime: moment(toMillisecond(last.tracked_time)).format('lll'),
        trackID: t.track_id,
        harvestNumber,
        status: last.status,
        userName: last.user?.name ?? '',
        color,
        children: t.statuses.map((x, j) => ({
          id: j,
          trackTime: moment(toMillisecond(x.tracked_time)).format('lll'),
          status: x.status,
          userName: x.user?.name ?? '',
          color: color,
        })),
      };
      trackRows.push(row);
    }
    return trackRows;
  }, [tracks]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let tmpData: ITrackCode[] = [];
      if (harvests) {
        for (const h of harvests) {
          const res = await sendSingleRequest(
            { harvest_id: h.id },
            'GET',
            'api/task/track-codes',
            true,
          );
          if (res.status) {
            for (let x of res.data) {
              if (!tmpData.some(y => y.track_id === x.track_id)) {
                tmpData.push(x);
              }
            }
          }
        }
      } else if (trackIDs) {
        for (const t of trackIDs) {
          const res = await sendSingleRequest(
            { track_id: t },
            'GET',
            'api/task/track-codes',
            true,
          );
          if (res.status) {
            for (let x of res.data) {
              if (!tmpData.some(y => y.track_id === x.track_id)) {
                tmpData.push(x);
              }
            }
          }
        }
      }
      tmpData = tmpData.filter(x => x.statuses.length > 0);
      tmpData.sort(
        (a, b) => a.statuses[0].tracked_time - b.statuses[0].tracked_time,
      );
      setTracks(tmpData);
      setLoading(false);
    })();
  }, [harvests, trackIDs]);

  const { totAmount, totProcessed } = useMemo(() => {
    let totAmount = 0,
      totProcessed = 0;
    let hc: number[] = [];
    if (tracks) {
      for (const t of tracks) {
        if (t.qr_data && t.qr_data.bag_details) {
          totProcessed += t.qr_data.bag_details.reduce(
            (p: number, c: any) => p + c.weight,
            0,
          );
        }
        for (let l of t.track_lines) {
          if (l.harvest && !hc.includes(l.harvest.id)) {
            totAmount += l.harvest.amount;
            hc.push(l.harvest.id);
          }
        }
      }
    }
    return { totAmount, totProcessed };
  }, [tracks]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={1100}
      footer={null}
    >
      <div className='section-modal wrap'>
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Track Information')}
          </Subtitle>
        </div>
        {loading ? (
          <div className='mt-32 mb-32'>
            <Spinner />
          </div>
        ) : (
          <>
            <div className='section'>
              <Table
                rowKey={'id'}
                className='table'
                columns={trackColumns(lang)}
                pagination={false}
                dataSource={trackRows}
                onRow={row => ({
                  style: {
                    backgroundColor: `${row.color}33`,
                  },
                })}
              />
            </div>
            <div className='d-flex align-items-center mb-17'>
              <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                {translate(lang, 'Processed Bag Result')}
              </Subtitle>
            </div>
            <div className='section'>
              <Table
                rowKey={'id'}
                className='table'
                columns={bagColumns(lang)}
                pagination={false}
                dataSource={tracks.filter(x => !!x.qr_data?.bag_details)}
                expandable={{
                  expandedRowRender: d => (
                    <div className='mb-17'>
                      <Table
                        rowKey={'name'}
                        className='table is__child'
                        columns={bagDataColumns(lang)}
                        dataSource={d.qr_data?.bag_details ?? []}
                        pagination={false}
                      />
                    </div>
                  ),
                }}
              />
              <div className='mr-32 ml-32 pr-32 pl-32 pt-17 pb-17 d-flex justify-content-center'>
                <div className='strong mr-17'>
                  {`${translate(lang, 'Harvested Total')}: ${formatNumber(
                    totAmount,
                  )} kg`}
                </div>
                <div className='strong ml-17'>
                  {`${translate(lang, 'Processed Total')}: ${formatNumber(
                    totProcessed,
                  )} kg`}
                </div>
              </div>
            </div>
          </>
        )}
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='blue'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default HarvestTrackModal;
