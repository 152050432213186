import { IAuthAction, IAuthState } from './auth.type';

const initialState: IAuthState = {
  nextView: {
    isSuccess: false,
    email: '',
  },
  token: null,
  profile: null,
  userMeta: null,
  settings: {
    harvestTypes: null,
    customTasks: null,
    friendEmails: null,
    showGpsWarning: null,
  },
};

const authReducer = (state = initialState, action: IAuthAction): IAuthState => {
  switch (action.type) {
    case 'AUTH/SET_TOKEN': {
      return { ...state, token: action.payload };
    }
    case 'AUTH/SET_PROFILE': {
      return { ...state, profile: action.payload };
    }
    case 'AUTH/SET_NEXT_VIEW': {
      return { ...state, nextView: action.payload };
    }
    case 'AUTH/SET_USER_META': {
      return { ...state, userMeta: action.payload };
    }
    case 'AUTH/SET_SETTINGS': {
      return { ...state, settings: action.payload };
    }
    case 'AUTH/QUEUE_COUNT': {
      let newState = { ...state };
      if (newState.userMeta) {
        newState.userMeta.queue_count = action.payload;
      }
      return newState;
    }
    case 'AUTH/SET_DEMO_LOGIN': {
      return { ...state, isDemo: action.payload };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default:
      return state;
  }
};

export default authReducer;
