import { useEffect, useState } from 'react';
import { Subtitle } from '../../components/shared';
import { IBoatMessage } from '../../entities/boat.entities';
import boat_png from '../../images/boat.png';
import { toMillisecond } from '../../util/toggleSecondMillisecond';
import moment from 'moment';
import { sendSingleRequest } from '../../apis';
import { useSelector } from 'react-redux';
import { selectBoats } from '../../store/users/users.selector';
import { hashColor } from '../../entities/util-functions';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const MessagesView = () => {
  const boats = useSelector(selectBoats);
  const lang = useSelector(selectLang);

  const [messages, setMessages] = useState<IBoatMessage[]>([]);

  useEffect(() => {
    (async () => {
      const response = await sendSingleRequest(
        { limit: 5 },
        'GET',
        'api/boat-message/messages',
        true,
      );
      if (response.status) {
        setMessages(response.data);
      } else {
        window.alert(
          translate(lang, response.data?.message ?? 'Something went wrong'),
        );
      }
    })();
  }, []);

  return (
    <div
      className='mb-32 mt-32'
      style={{
        border: '1px solid #e5e5e5',
        borderRadius: '3px',
        padding: '8px 15px',
      }}
    >
      <Subtitle size={2} color='black' align='left' fontWeight={600}>
        {translate(lang, 'Recent Chats')}
      </Subtitle>
      <div className='mt-16 mb-16'>
        {messages.map(msg => (
          <div key={msg.id} className='message-view'>
            <div
              className='boat-image'
              style={{
                backgroundColor: hashColor(
                  boats.find(x => x.id === msg.boat_id)?.reg_number ?? '',
                ),
              }}
            >
              <img src={boat_png} alt='boat' />
            </div>
            <div className='--message-content'>
              <div className='--content'>{msg.content}</div>
              <div className='--created-at'>
                {moment(toMillisecond(msg.created_at)).format('dddd hh:mm a')}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Link className='mt-7 mb-7' to='/boat-messages'>
        <Button type='primary'>{translate(lang, 'View Messages')}</Button>
      </Link>
    </div>
  );
};

export default MessagesView;
