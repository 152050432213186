import { FormEvent, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CheckboxButton,
  Input,
  Title,
  Paragrapgh,
  Feedback,
  Dropdown,
} from '../../components/shared';
import { authLogin } from '../../store/auth/auth.actions';
import { ILoginData } from '../../store/auth/auth.type';
import { useWidth } from '../../util/useWidth';
import { selectToken } from '../../store/auth/auth.selector';
import { selectFeedbacks, selectLang } from '../../store/ui/ui.selector';
import { setLangUI } from '../../store/ui/ui.actions';
import { translate } from '../../lib/lang.helper';

const SignIn = () => {
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const params = useParams<{ checked: string }>();
  const history = useHistory();
  const width = useWidth();

  const lang = useSelector(selectLang);
  const token = useSelector(selectToken);
  const allFeedbacks = useSelector(selectFeedbacks);

  const [disabled, setDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isInviteAccept, setIsInviteAccept] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidFields, setIsValidFields] = useState<boolean>(false);
  const [fieldsValid, setFieldsValid] = useState([
    {
      name: 'password',
      isValidate: false,
    },
    {
      name: 'email',
      isValidate: false,
    },
  ]);
  const [remember, setRemember] = useState<boolean>(false);

  const updateLanguage = (v: any) => dispatch(setLangUI(v));

  useEffect(() => {
    if (!!token) {
      const backUrl = query.get('redirect');
      if (!backUrl) {
        history.push('/');
      } else if (backUrl.startsWith(window.location.origin)) {
        history.push(backUrl.substring(window.location.origin.length));
      } else {
        window.location.href = backUrl;
      }
    }
  }, [token]);

  useEffect(() => {
    if (params.checked === 'checked') {
      setIsChecked(true);
    }

    if (params.checked === 'invite-accept') {
      setIsInviteAccept(true);
    }
  }, []);

  useEffect(() => {
    if (isChecked) {
      setTimeout(() => {
        setIsChecked(false);
      }, 3000);
    }
    if (isInviteAccept) {
      setTimeout(() => {
        setIsInviteAccept(false);
      }, 3000);
    }
  }, [isChecked, isInviteAccept]);

  const handleOnChangeEmail = (value: string) => {
    setEmail(value);
  };
  const handleOnChangePassword = (value: string) => {
    setPassword(value);
  };
  const handleOnValidFields = (value: boolean, data: string | undefined) => {
    let counter = 0;
    const newArr = fieldsValid.map(field => {
      /* eslint-disable*/
      if (field.name === data) {
        if (value) counter++;
        return { ...field, isValidate: value };
      }
      if (field.name !== data && field.isValidate) counter++;
      return field;
    });

    setFieldsValid(newArr);
    setIsValidFields(2 === counter || false);
  };

  const onLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    const data: ILoginData = {
      email,
      password,
      remember,
    };
    if (isValidFields) {
      const res = await dispatch(authLogin(data));
      if (!res) {
        setDisabled(false);
      }
    }
  };

  return (
    <>
      <div
        className={
          width > 768
            ? 'd-flex justify-content-center align-items-center h-80-vh'
            : 'd-block'
        }
      >
        <div className='card'>
          <div className='card-wrapper'>
            <Title
              className={width < 769 ? 'mb-20' : 'mb-4'}
              size={5}
              align='default'
              color='black-3'
              fontWeight={600}
            >
              {translate(lang, 'Sign In')}
            </Title>
            {width > 768 && (
              <div className='d-flex mb-16'>
                <Paragrapgh
                  size={2}
                  fontWeight={500}
                  color='default'
                  align='default'
                >
                  {translate(lang, "Don't have an account?")}
                </Paragrapgh>
                <Link to='/sign-up' className='ml-4'>
                  <Paragrapgh
                    size={2}
                    fontWeight={500}
                    color='blue-1'
                    align='default'
                  >
                    {translate(lang, 'Sign Up')}
                  </Paragrapgh>
                </Link>
              </div>
            )}
            <div className='pt-17 pb-17'>
              <Dropdown
                label='Language'
                options={[
                  { id: 'en', value: 'en', label: 'English' },
                  { id: 'fr', value: 'fr', label: 'Français' },
                  { id: 'es', value: 'es', label: 'Español' },
                ]}
                value={lang ?? 'en'}
                onChange={updateLanguage}
              />
            </div>
            <form onSubmit={onLogin}>
              <Input
                label={translate(lang, 'Email')}
                onChange={e => handleOnChangeEmail(e.target.value)}
                type='email'
                value={email}
                className='mb-16'
                dataType='email'
                onValidate={(e, data) => handleOnValidFields(e, data)}
              />
              <Input
                label={translate(lang, 'Password')}
                onChange={e => handleOnChangePassword(e.target.value)}
                type='password'
                value={password}
                className='mb-16'
                dataType='password'
                onValidate={(e, data) => handleOnValidFields(e, data)}
              />
              <div className='w-100 mb-20 d-flex align-items-center justify-content-between'>
                <CheckboxButton
                  label={translate(lang, 'Remember me')}
                  checked={remember}
                  onChange={e => setRemember(e.target.checked)}
                />
                <Link to='/recover-password/email'>
                  <Paragrapgh
                    size={2}
                    fontWeight={400}
                    color='blue-1'
                    align='default'
                  >
                    {translate(lang, 'Forgot Password')}?
                  </Paragrapgh>
                </Link>
              </div>
              <div className='card-bottom'>
                <Button
                  color='blue'
                  disabled={!isValidFields || disabled}
                  size={1}
                  width='wide'
                  type='fill'
                >
                  {translate(lang, 'Sign In')}
                </Button>
                {width < 769 && (
                  <div className='d-flex mt-28 mb-36 justify-content-center'>
                    <Paragrapgh
                      size={2}
                      fontWeight={500}
                      color='default'
                      align='default'
                    >
                      {translate(lang, "Don't have an account?")}
                    </Paragrapgh>
                    <Link to='/sign-up' className='ml-4'>
                      <Paragrapgh
                        size={2}
                        fontWeight={500}
                        color='blue-1'
                        align='default'
                      >
                        {translate(lang, 'Sign Up')}
                      </Paragrapgh>
                    </Link>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {allFeedbacks &&
        allFeedbacks.map(item => (
          <Feedback
            key={item.id}
            message={item.message}
            type={item.type}
            theme='light'
            isGlobal
          />
        ))}
      {isChecked && (
        <Feedback
          className='mt-4'
          message={translate(lang, 'An email has been confirmed successfully')}
          type='success'
          theme='light'
          isGlobal
        />
      )}
      {isInviteAccept && (
        <Feedback
          className='mt-4'
          message={translate(lang, 'Invitation accepted successfully')}
          type='success'
          theme='light'
          isGlobal
        />
      )}
    </>
  );
};

export default SignIn;
