import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectVersion = createSelector(
  (state: RootState) => state.ui.version,
  version => version,
);

export const selectFeedbacks = createSelector(
  (state: RootState) => state.ui.allFeedbacks,
  feedbacks => feedbacks,
);

export const selectSpin = createSelector(
  (state: RootState) => state.ui.isSpinner,
  spin => spin,
);

export const selectLang = createSelector(
  (state: RootState) => state.ui.lang,
  lang => lang,
);

export const selectVisibleGuide = createSelector(
  (state: RootState) => state.ui.visibleGuide,
  visibleGuide => visibleGuide,
);

export const selectMapType = createSelector(
  (state: RootState) => state.ui.mapType,
  mapType => mapType,
);
