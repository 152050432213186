import { calcInterestRate } from '../../../entities/util-functions';
import InterestGrowth from './InterestGrowth';

interface Props {
  planned: number;
  actual: number;
}

const PlannedActualRate = ({ planned, actual }: Props) => {
  const rate = calcInterestRate(planned, actual);

  return (
    <InterestGrowth
      isGrow={rate >= 0}
      interest={
        rate % 1 === 0 ? Math.abs(rate) : Number(Math.abs(rate).toFixed(2))
      }
    />
  );
};

export default PlannedActualRate;
