import { useEffect, useState } from 'react';
import { useWidth } from '../../util/useWidth';
import {
  Feedback,
  ModalComponent,
  Spinner,
  Title,
} from '../../components/shared';
import { sendSingleRequest } from '../../apis';
import BudgetLogsTable from '../../components/shared/tables/BudgetLogsTable';
import { IBudgetLog } from '../../entities/budget.entities';
import BudgetLogsMobile from '../../components/shared/table-mobile/BudgetLogsMobile';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const BudgetLog = () => {
  const width = useWidth();
  const lang = useSelector(selectLang);

  const [logs, setLogs] = useState<IBudgetLog[]>([]);
  const [deleteId, setDeleteId] = useState(0);
  const [message, setMessage] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 200,
  });
  const [isSpinner, setIsSpinner] = useState(false);

  const handleOnDelete = (log: IBudgetLog) => {
    setDeleteId(log.id);
  };

  const handleConfirmDelete = async () => {
    const response = await sendSingleRequest(
      { budget_log_id: deleteId },
      'POST',
      'api/farm/line/retrieve-log',
      true,
    );
    if (response.status) {
      setLogs(logs.filter(item => item?.id !== deleteId));
      setPagination({ ...pagination, total: pagination.total - 1 });
      setMessage('Success');
    } else {
      setMessage(response.data?.message ?? 'Unknown error');
    }
    setDeleteId(0);
  };

  const paginationOnChange = (changePagination: any) => {
    setPagination(changePagination);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  }, [message]);

  useEffect(() => {
    let isIn = true;

    setIsSpinner(true);
    sendSingleRequest(
      { current_page: pagination.current, page_size: pagination.pageSize },
      'GET',
      `api/farm/line/budget-logs`,
      true,
    ).then(res => {
      if (!isIn) return;

      if (res.status) {
        setLogs(res.data?.data);
        setPagination({ ...pagination, total: res.data?.meta?.total });
      }
      setIsSpinner(false);
    });

    return () => {
      isIn = false;
    };
  }, [pagination.current]);

  return (
    <>
      <div className='bg-secondary min-height budget-log'>
        <div className='container'>
          <div className='users d-flex justify-content-between align-items-center'>
            <Title size={5} color='black' align='default' fontWeight={700}>
              {translate(lang, 'Budget Log')}
            </Title>
          </div>
          {!isSpinner ? (
            <div className='users__content'>
              {width > 768 ? (
                <BudgetLogsTable
                  logsData={logs}
                  onDelete={handleOnDelete}
                  pagination={pagination}
                  onPagination={paginationOnChange}
                />
              ) : (
                <BudgetLogsMobile
                  data={logs}
                  onDeleteLog={handleOnDelete}
                  pagination={pagination}
                  onPagination={paginationOnChange}
                />
              )}
            </div>
          ) : (
            <div className='mt-20'>
              <Spinner />
            </div>
          )}
        </div>
        <ModalComponent
          title={translate(lang, 'Delete')}
          text={translate(lang, 'Are you sure to delete this data?')}
          visible={deleteId !== 0}
          onCancel={() => setDeleteId(0)}
          type='delete'
          onConfirm={() => handleConfirmDelete()}
        />
      </div>
      {message && (
        <Feedback
          message={translate(lang, message)}
          type={message === 'Success' ? 'success' : 'error'}
          theme='light'
          isGlobal
        />
      )}
    </>
  );
};

export default BudgetLog;
