import { RootState, IThunkType } from '../rootReducer';
import { isSpinner, showFeedback, showGuideModal } from '../ui/ui.actions';
import { transformFarmWithKey } from '../../util/farmUtil';
import { sendSingleRequest } from '../../apis';
import { IFarmAction } from './farms.type';

const setFarm = (farm: IFarmAction): IFarmAction => farm;

let loadingFarmsData = false;

export const loadFarmsData = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingFarmsData) return;
    loadingFarmsData = true;

    const response = await sendSingleRequest(
      {},
      'GET',
      `api/farm/farms-all`,
      true,
    );
    if (response.status) {
      const dataWithKey = transformFarmWithKey(response.data);

      if (dataWithKey.length <= 0 && getState().ui.visibleGuide === undefined) {
        dispatch(showGuideModal());
      }

      dispatch(setFarm({ type: 'FARMS/SET_FARMS_DATA', payload: dataWithKey }));
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: `Failed to load information`,
        }),
      );
    }
    loadingFarmsData = false;
  };
};

export const addFarm = (data: any, history?: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(isSpinner(true));

    const response = await sendSingleRequest(
      { ...data, user_id: getState().auth.profile?.id },
      'POST',
      'api/farm/farms',
      true,
    );
    if (response.status) {
      dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Farm added successfully',
        }),
      );
      history.push('/farms');
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Farm not added',
        }),
      );
    }
    dispatch(isSpinner(false));
  };
};

export const editFarm = (
  data: any,
  farmId: string | undefined,
  history: any,
) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(isSpinner(true));
    const response = await sendSingleRequest(
      data,
      'PUT',
      `api/farm/farms/${farmId}`,
      true,
    );
    if (response.status) {
      dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Update farm completed',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Update farm failed',
        }),
      );
    }
    history.push('/farms');
    dispatch(isSpinner(false));
  };
};

let loadingStorages = false;

export const loadSpatStorages = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingStorages) return;
    loadingStorages = true;

    const res = await sendSingleRequest(
      {},
      'GET',
      'api/farm/spat-storage',
      true,
    );
    const spats = res.status ? res.data : [];
    dispatch(setFarm({ type: 'FARMS/SET_SPAT_STORAGES', payload: spats }));

    loadingStorages = false;
  };
};

let loadingInventoryPositions = false;

export const loadInventoryPositions = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingInventoryPositions) return true;
    loadingInventoryPositions = true;

    const response = await sendSingleRequest(
      {},
      'GET',
      'api/farm/line/visual-floats-position',
      true,
    );
    if (!response.status) {
      loadingInventoryPositions = false;
      return false;
    }

    dispatch(
      setFarm({
        type: 'FARMS/SET_INVENTORY_POSITIONS',
        payload: response.data,
      }),
    );
    loadingInventoryPositions = false;

    return true;
  };
};

let loadingQueueData = false;

export const loadQueueData = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingQueueData) return true;
    loadingQueueData = true;

    const result = await sendSingleRequest({}, 'GET', 'api/queue/all', true);
    if (!result.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: result.data?.message ?? 'Something went wrong',
        }),
      );
      loadingQueueData = false;
      return false;
    }

    dispatch(setFarm({ type: 'FARMS/SET_QUEUE_DATA', payload: result.data }));

    const qCnt = result.data?.length ?? 0;
    dispatch({ type: 'AUTH/QUEUE_COUNT', payload: qCnt });

    loadingQueueData = false;
    return true;
  };
};
