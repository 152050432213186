import { IOysterLineResource } from '../../../entities/farms.entities';

interface Props {
  visible: boolean;
  lineData: IOysterLineResource;
}

const OysterGrowerHarvestModal = ({ visible, lineData }: Props) => (
  <div>OysterGrowerHarvestModal</div>
);

export default OysterGrowerHarvestModal;
