import { RootState, IThunkType } from '../rootReducer';
import { sendSingleRequest } from '../../apis';
import { IUtilsAction } from './utils.type';
import { IUtilData } from '../../entities/utils.entities';

const setUtils = (util: IUtilsAction): IUtilsAction => util;

let loadingUtils = false;

export const loadUtilsData = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingUtils) return true;
    loadingUtils = true;

    const res = await sendSingleRequest({}, 'GET', 'api/utils', true);

    loadingUtils = false;

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_FARM_UTILS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load utils';
    }
  };
};

export const deleteUtilData = (utilId: number) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      {},
      'DELETE',
      `api/utils/${utilId}`,
      true,
    );
    if (res.status) {
      await dispatch(loadUtilsData());

      return true;
    } else {
      return res.data?.message ?? 'Failed to delete util';
    }
  };
};

export const addUtilData = (type: IUtilData['type'], name: string) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      { name, type },
      'POST',
      'api/utils',
      true,
    );
    if (res.status) {
      await dispatch(loadUtilsData());
      return true;
    } else {
      return res.data?.message ?? 'Failed to add new util';
    }
  };
};

export const updateSystemSettings = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const result = await sendSingleRequest(
      null,
      'GET',
      'api/system-settings',
      false,
    );
    if (result.status) {
      dispatch(
        setUtils({ type: 'UTILS/SET_SYSTEM_SETTINGS', payload: result.data }),
      );
    }
  };
};
