import { Button, Modal } from 'antd';
import { useState } from 'react';
import { sendSingleRequest } from '../../apis';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import ReactImageGallery from 'react-image-gallery';

interface IImage {
  original: string;
  thumbnail: string;
  originalTitle: string;
  description: string;
  date: number;
}

interface Props {
  lineID: number;
  label: string;
}

const PhotosViewButton = ({ lineID, label }: Props) => {
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [images, setImages] = useState<IImage[]>();

  const onViewClick = async () => {
    setDisabled(true);
    const response = await sendSingleRequest(
      {},
      'GET',
      `api/farm/line/lines/${lineID}`,
      true,
    );
    setDisabled(false);

    if (response.status) {
      const cycles: any[] = response.data?.cycles ?? [];
      let tmp: IImage[] = [];
      for (let cycle of cycles) {
        for (let x of cycle.assessments) {
          if (x.images && x.images.length > 0) {
            for (let img of x.images) {
              const str = `${translate(
                lang,
                'Assessment',
              )} - ${defaultDateFormat(x.assessment_date)}`;
              tmp.push({
                original: img,
                thumbnail: img,
                originalTitle: str,
                description: str,
                date: x.assessment_date,
              });
            }
          }
        }
        for (let x of cycle.harvests) {
          if (x.images && x.images.length > 0) {
            for (let img of x.images) {
              const str = `${translate(lang, 'Harvest')} - ${defaultDateFormat(
                x.complete_date,
              )}`;
              tmp.push({
                original: img,
                thumbnail: img,
                originalTitle: str,
                description: str,
                date: x.complete_date,
              });
            }
          }
        }
        for (let x of cycle.maintenances) {
          if (x.images && x.images.length > 0) {
            for (let img of x.images) {
              const str = `${translate(
                lang,
                'Maintenance',
              )} - ${defaultDateFormat(x.maintain_date)}`;
              tmp.push({
                original: img,
                thumbnail: img,
                originalTitle: str,
                description: str,
                date: x.maintain_date,
              });
            }
          }
        }
        for (let x of cycle.seedings) {
          if (x.images && x.images.length > 0) {
            for (let img of x.images) {
              const str = `${translate(lang, 'Seeding')} - ${defaultDateFormat(
                x.planned_date_seed,
              )}`;
              tmp.push({
                original: img,
                thumbnail: img,
                originalTitle: str,
                description: str,
                date: x.planned_date_seed,
              });
            }
          }
        }
      }
      tmp.sort((a, b) => b.date - a.date);

      if (tmp.length <= 0) {
        alert(translate(lang, 'No photos found'));
      } else {
        setImages(tmp);
      }
    } else {
      alert(translate(lang, response.data?.message ?? 'Unknown error'));
    }
  };

  return (
    <>
      <Button type='primary' disabled={disabled} onClick={onViewClick}>
        {label}
      </Button>
      {images !== undefined && (
        <Modal
          title={translate(lang, 'Photos')}
          centered
          visible={true}
          onOk={() => setImages(undefined)}
          onCancel={() => setImages(undefined)}
          width={1000}
        >
          <ReactImageGallery items={images} />
        </Modal>
      )}
    </>
  );
};

export default PhotosViewButton;
