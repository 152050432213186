import { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import {
  Button,
  Dropdown,
  ModalComponent,
  Title,
} from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import prevIcon from '../../images/prev-image.png';
import nextIcon from '../../images/next-image.png';
import { Link, useHistory } from 'react-router-dom';
import ModalTask from '../../components/todo/ModalTask';
import {
  getTaskData,
  removeTask,
  updateTask,
} from '../../store/tasks/tasks.actions';
import {
  IAssessmentResource,
  IFarmWeatherResource,
  ILineResource,
  IMaintenanceResource,
} from '../../entities/farms.entities';
import CalendarCell from './CalendarCell';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { sendSingleRequest } from '../../apis';
import FarmWeatherModal from '../../components/farm-modals/FarmWeatherModal';
import { ITaskData } from '../../entities/task.entities';
import weather_icon from '../../images/weather-icon.png';
import clock_icon from '../../images/clock-image.png';
import DailyReportModal from '../../components/farm-modals/DailyReportModal';
import SeedingModal from '../../components/farm-modals/SeedingModal';
import MaintenanceModal from '../../components/farm-modals/MaintenanceModal';
import AssessmentModal from '../../components/farm-modals/AssessmentModal';
import FloatManageModal from '../../components/farm-modals/FloatManageModal';
import { checkRolePermission } from '../../entities/util-functions';
import GrowerSeedModal from '../../components/farm-modals/GrowerSeedModal';
import HarvestViewModal from '../../components/view-modals/HarvestViewModal';
import {
  IHarvestResource,
  ISeedingResource,
} from '../../entities/growing.entities';
import { selectTasks } from '../../store/tasks/tasks.selector';
import { selectProfile, selectUserMeta } from '../../store/auth/auth.selector';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { getLineStatus } from '../../lib/farm.helpers';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { IBoatRefuel } from '../../entities/boat.entities';
import { toMillisecond, toSecond } from '../../util/toggleSecondMillisecond';
import './styles.scss';
import { IInventoryManage } from '../../entities/inventory.entities';

interface ICell {
  seedings: ISeedingResource[];
  assessments: IAssessmentResource[];
  maintenances: IMaintenanceResource[];
  inventory_seeds: IInventoryManage[];
  harvests: IHarvestResource[];
  tasks: ITaskData[];
  req_assess_seeds: ILineResource[];
  boat_refuels: IBoatRefuel[];
}

interface IGrid {
  dateStr: string;
  cellData: ICell;
  status: 'prev' | 'current' | 'next';
  dayLabel: string;
  weathers?: Array<IFarmWeatherResource>;
}

const zeroPad = (num: number) => String(num).padStart(2, '0');
const viewModeOptions = [
  { id: 'D', value: 'D', label: 'Day View' },
  { id: 'W', value: 'W', label: 'Week View' },
  { id: 'M', value: 'M', label: 'Month View' },
];
const weeks = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
const TodayStr = moment().format('YYYY-MM-DD');

const CalendarPage = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const tasksData = useSelector(selectTasks);
  const farmsData = useSelector(selectFarmsData);
  const userMeta = useSelector(selectUserMeta);
  const profile = useSelector(selectProfile);

  const [loadedData, setLoadedData] = useState<ICell>();
  const [curDay, setCurDay] = useState(moment().format('YYYY-MM-DD'));
  const [createTask, setCreateTask] = useState(false);
  const [showTaskData, setShowTaskData] = useState<ITaskData>();
  const [editTaskData, setEditTaskData] = useState<ITaskData>();
  const [editTask, setEditTask] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState<string | number>('0');
  const [createTData, setCreateTData] = useState<any>(null);
  const [checkingTask, setCheckingTask] = useState<ITaskData>();
  const [weathers, setWeathers] = useState<IFarmWeatherResource[] | null>();
  const [focWeathers, setFocWeathers] = useState<IFarmWeatherResource[]>();
  const [dailyStr, setDailyStr] = useState<string>();
  const [viewMode, setViewMode] = useState('M');
  const [assessCell, setAssessCell] = useState<{
    line: ILineResource;
    data: IAssessmentResource;
  }>();
  const [mntCell, setMntCell] = useState<{
    line: ILineResource;
    data: IMaintenanceResource;
  }>();
  const [floatCell, setFloatCell] = useState<{
    line: ILineResource;
    data: IInventoryManage;
  }>();
  const [seedCell, setSeedCell] = useState<{
    line: ILineResource;
    data: ISeedingResource;
  }>();
  const [reqCell, setReqCell] = useState<ILineResource>();
  const [harCell, setHarCell] = useState<IHarvestResource>();

  const getBusinessType = (line: any) =>
    farmsData.find(x => x.id === line.farm_id)?.type ?? 'MUSSEL';

  const grid = useMemo(() => {
    const getGridRows = () => {
      let result: Array<IGrid[]> = [];
      if (viewMode === 'D') {
        result = [
          [
            {
              dateStr: curDay,
              cellData: {
                tasks: tasksData?.filter(
                  t => moment(t.due_date).format('YYYY-MM-DD') === curDay,
                ),
                seedings: [],
                assessments: [],
                maintenances: [],
                inventory_seeds: [],
                harvests: [],
                req_assess_seeds: [],
                boat_refuels: [],
              },
              status: 'current',
              dayLabel: moment(curDay).format('DD'),
            },
          ],
        ];
      } else if (viewMode === 'W') {
        const w = (new Date(curDay).getDay() + 6) % 7;
        let rows: IGrid[] = [];
        for (let i = 0; i < 7; i++) {
          const ds = moment(curDay)
            .add(i - w, 'days')
            .format('YYYY-MM-DD');
          const mc = new Date(curDay).getMonth(),
            mm = new Date(ds).getMonth();
          rows.push({
            dateStr: ds,
            cellData: {
              tasks: tasksData?.filter(
                t => moment(t.due_date).format('YYYY-MM-DD') === ds,
              ),
              seedings: [],
              assessments: [],
              maintenances: [],
              inventory_seeds: [],
              harvests: [],
              req_assess_seeds: [],
              boat_refuels: [],
            },
            status: mm < mc ? 'prev' : mm > mc ? 'next' : 'current',
            dayLabel: moment(ds).format(mm === mc ? 'DD' : 'MMM DD'),
          });
        }
        result.push(rows);
      } else {
        const yy = moment(curDay).format('YYYY'),
          mm = moment(curDay).format('MM');
        const day = `${moment(curDay).format('YYYY-MM')}-01`;
        const firstWeek = (new Date(day).getDay() + 6) % 7;
        const lastDay = new Date(Number(yy), Number(mm), 0).getDate();
        let rows: IGrid[] = [];
        for (let i = 0; i < firstWeek; i++) {
          const ds = moment(day)
            .subtract(firstWeek - i, 'days')
            .format('YYYY-MM-DD');
          rows.push({
            dateStr: ds,
            cellData: {
              tasks: tasksData?.filter(
                t => moment(t.due_date).format('YYYY-MM-DD') === ds,
              ),
              seedings: [],
              assessments: [],
              maintenances: [],
              inventory_seeds: [],
              harvests: [],
              req_assess_seeds: [],
              boat_refuels: [],
            },
            status: 'prev',
            dayLabel: moment(ds).format('DD'),
          });
        }
        for (let i = 1; i <= lastDay; i++) {
          const ds = moment(`${yy}-${mm}-${zeroPad(i)}`).format('YYYY-MM-DD');
          rows.push({
            dateStr: ds,
            cellData: {
              tasks: tasksData?.filter(
                t => moment(t.due_date).format('YYYY-MM-DD') === ds,
              ),
              seedings: [],
              assessments: [],
              maintenances: [],
              inventory_seeds: [],
              harvests: [],
              req_assess_seeds: [],
              boat_refuels: [],
            },
            status: 'current',
            dayLabel: moment(ds).format(i === 1 ? 'MMM DD' : 'DD'),
          });
          if (rows.length >= 7) {
            result.push(rows);
            rows = [];
          }
        }
        if (rows.length > 0) {
          for (let i = 0; rows.length < 7; i++) {
            const ds = moment(day)
              .add(1, 'months')
              .add(i, 'days')
              .format('YYYY-MM-DD');
            rows.push({
              dateStr: ds,
              cellData: {
                tasks: tasksData?.filter(
                  t => moment(t.due_date).format('YYYY-MM-DD') === ds,
                ),
                seedings: [],
                assessments: [],
                maintenances: [],
                inventory_seeds: [],
                harvests: [],
                req_assess_seeds: [],
                boat_refuels: [],
              },
              status: 'next',
              dayLabel: moment(ds).format(i === 0 ? 'MMM DD' : 'DD'),
            });
          }
          result.push(rows);
        }
      }
      return result;
    };
    let result = getGridRows();
    const insertData = (ds: string, key: keyof ICell, val: any) => {
      for (let i = 0; i < result.length; i++) {
        let j = 0;
        for (j = 0; j < result[i].length && result[i][j].dateStr !== ds; j++);
        if (j < result[i].length) {
          result[i][j].cellData[key].push(val);
          break;
        }
      }
    };
    const insertWeather = (ds: string, w: IFarmWeatherResource) => {
      for (let i = 0; i < result.length; i++) {
        let j = 0;
        for (j = 0; j < result[i].length && result[i][j].dateStr !== ds; j++);
        if (j < result[i].length) {
          if (result[i][j].weathers === undefined) result[i][j].weathers = [];
          result[i][j].weathers?.push(w);
          break;
        }
      }
    };
    for (let farm of farmsData) {
      for (let line of farm.lines.filter(x => !!x.growing_cycle)) {
        if (
          getLineStatus(line, userMeta?.line_assess_expire_days) ===
          'REQUIRE_ASSESSMENT'
        ) {
          const ds = moment().format('YYYY-MM-DD');
          insertData(ds, 'req_assess_seeds', line);
        }
      }
    }
    for (let x of loadedData?.seedings ?? []) {
      const ds = moment(toMillisecond(x.planned_date_seed)).format(
        'YYYY-MM-DD',
      );
      insertData(ds, 'seedings', x);
    }
    for (let x of loadedData?.assessments ?? []) {
      const ds = moment(toMillisecond(x.assessment_date)).format('YYYY-MM-DD');
      insertData(ds, 'assessments', x);
    }
    for (let x of loadedData?.maintenances ?? []) {
      const ds = moment(toMillisecond(x.maintain_date)).format('YYYY-MM-DD');
      insertData(ds, 'maintenances', x);
    }
    for (let x of loadedData?.inventory_seeds ?? []) {
      const ds = moment(toMillisecond(x.manage_date)).format('YYYY-MM-DD');
      insertData(ds, 'inventory_seeds', x);
    }
    for (let x of loadedData?.harvests ?? []) {
      const ds = moment(toMillisecond(x.complete_date)).format('YYYY-MM-DD');
      insertData(ds, 'harvests', x);
    }
    if (weathers && weathers.length > 0) {
      for (let w of weathers) {
        insertWeather(moment(w.rts).format('YYYY-MM-DD'), w);
      }
    }
    for (let x of loadedData?.boat_refuels ?? []) {
      const ds = moment(toMillisecond(x.start_time)).format('YYYY-MM-DD');
      insertData(ds, 'boat_refuels', x);
    }
    return result;
  }, [curDay, tasksData, farmsData, loadedData, weathers, userMeta, viewMode]);

  const onShowTask = (data: ITaskData) => {
    setShowTaskData(data);
  };
  const onEditTask = (data: any) => {
    setEditTaskData(data);
    setEditTask(true);
  };
  const onDeleteTask = (data: ITaskData) => {
    setDeleteTask(true);
    setDeleteTaskId(data.id!);
  };
  const handleOnAddTask = () => {
    setCreateTask(false);
  };
  const handleOnCreateTask = () => {
    if (!createTask) {
      setCreateTData(null);
    }
    setCreateTask(!createTask);
  };
  const handleUpdateMonth = (d: number) => {
    const day = moment(curDay)
      .add(d, viewMode === 'M' ? 'months' : viewMode === 'W' ? 'weeks' : 'days')
      .format('YYYY-MM-DD');
    setCurDay(day);
  };
  const goToToday = () => {
    setCurDay(TodayStr);
  };
  const handleOnConfirmDelete = async () => {
    await dispatch(removeTask(Number(deleteTaskId), history));
    setDeleteTask(false);
  };
  const handlePlusTaskClick = (date: string) => {
    setCreateTData({ due_date: moment(date).toDate().getTime() });
    setCreateTask(true);
  };
  const onCompleteTask = (task: ITaskData) => {
    setCheckingTask(task);
  };
  const doCompleteTask = () => {
    if (!checkingTask) return;
    dispatch(updateTask({ ...checkingTask, is_completed: true }, history)).then(
      () => setCheckingTask(undefined),
    );
  };
  const loadData = useCallback(async () => {
    const min_date = moment(curDay).add(-2, 'months').format('YYYY-MM-DD');
    let response = await sendSingleRequest(
      { min_date },
      'GET',
      'api/farm/weather',
      true,
    );
    if (response.status) {
      setWeathers(response.data);
    }
    response = await sendSingleRequest(
      {
        min_date: toSecond(moment(curDay).add(-2, 'months').toDate().getTime()),
        max_date: toSecond(moment(curDay).add(2, 'months').toDate().getTime()),
      },
      'GET',
      'api/overview/entries',
      true,
    );
    if (!response.status) return;
    let tmpData: ICell = response.data;

    response = await sendSingleRequest(
      {
        min_time: toSecond(moment(curDay).add(-2, 'months').toDate().getTime()),
        max_time: toSecond(moment(curDay).add(2, 'months').toDate().getTime()),
      },
      'GET',
      'api/boat/refuels',
      true,
    );
    if (response.status) {
      tmpData.boat_refuels = response.data;
    }
    setLoadedData(tmpData);
  }, [curDay]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    dispatch(getTaskData());

    return () => {};
  }, [dispatch]);

  const wi = grid.some(x => x.some(y => y.dateStr === TodayStr))
    ? (new Date(TodayStr).getDay() + 6) % 7
    : -1;

  return (
    <div className='container w-100 pb-32'>
      <div className='d-flex align-items-center mt-28'>
        <div className='notifications w-100'>
          <div className='mt-28 mb-28 d-flex flex-wrap align-items-center justify-content-between'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              {translate(lang, 'Calendar')}
            </Title>
          </div>
        </div>
      </div>
      <div className='calendar-container'>
        <div className='calendar-header'>
          <div className='d-flex'>
            <Button
              size={5}
              type='fill'
              width='small'
              color='blue'
              onClick={goToToday.bind(this)}
            >
              {translate(lang, 'Today')}
            </Button>
            <div className='ml-17 d-flex'>
              <div
                className='prev-link'
                onClick={handleUpdateMonth.bind(this, -1)}
              >
                <img src={prevIcon} />
              </div>
              <div className='current-month'>
                {moment(curDay).format('MMMM YYYY')}
              </div>
              <div
                className='next-link'
                onClick={handleUpdateMonth.bind(this, 1)}
              >
                <img src={nextIcon} />
              </div>
            </div>
          </div>
          <div className='d-flex flex-wrap'>
            <div className='mr-32' style={{ width: '150px', fontWeight: 600 }}>
              <Dropdown
                label=''
                options={viewModeOptions}
                value={viewMode}
                onChange={v => setViewMode(v)}
              />
            </div>
            <div className='d-flex flex-grow justify-content-between align-items-center'>
              <Button
                color='blue'
                onClick={handleOnCreateTask}
                size={3}
                width='small'
                type='fill'
              >
                {translate(lang, 'Add task')}
              </Button>
              <Link className='ml-10' to='/automation'>
                <Button color='green' size={3} width='default' type='fill'>
                  {translate(lang, 'Automations')}
                </Button>
              </Link>
              <Link className='ml-10' to='/import/time-sheet'>
                <Button color='blue' size={3} width='default' type='fill'>
                  {translate(lang, 'Import timesheet')}
                </Button>
              </Link>
            </div>
            {createTask && (
              <ModalTask
                visible={true}
                title={translate(lang, 'Create task')}
                onCancel={handleOnCreateTask}
                data={createTData}
                onConfirm={handleOnAddTask}
              />
            )}
          </div>
        </div>
        <div className='calendar-body'>
          <div className='calendar-note-bar'>
            <div className='--note-item --task'>
              <div className='--note-label'>{translate(lang, 'Task')}</div>
              <div className='--note-color'></div>
            </div>
            <div className='--note-item --seed'>
              <div className='--note-label'>{translate(lang, 'Seed')}</div>
              <div className='--note-color'></div>
            </div>
            <div className='--note-item --assessment'>
              <div className='--note-label'>
                {translate(lang, 'Assessment')}
              </div>
              <div className='--note-color'></div>
            </div>
            <div className='--note-item --maintenance'>
              <div className='--note-label'>
                {translate(lang, 'Maintenance')}
              </div>
              <div className='--note-color'></div>
            </div>
            <div className='--note-item --inventory_seed'>
              <div className='--note-label'>
                {translate(lang, 'Floats manage')}
              </div>
              <div className='--note-color'></div>
            </div>
            <div className='--note-item --harvest'>
              <div className='--note-label'>{translate(lang, 'Harvest')}</div>
              <div className='--note-color'></div>
            </div>
            <div className='--note-item --require-assessment'>
              <div className='--note-label'>
                {translate(lang, 'REQ. ASSESS')}
              </div>
              <div className='--note-color'></div>
            </div>
            <div className='--note-item --refuel'>
              <div className='--note-label'>{translate(lang, 'Refuel')}</div>
              <div className='--note-color'></div>
            </div>
          </div>
          <table className='calendar-table'>
            <thead>
              <tr>
                {viewMode === 'D' ? (
                  <th>{weeks[(new Date(curDay).getDay() + 6) % 7]}</th>
                ) : (
                  weeks.map((w, i) => (
                    <th key={i} className={i === wi ? '--today' : ''}>
                      {w}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {grid.map((rows, i) => (
                <tr key={i}>
                  {rows.map((item, j) => (
                    <td
                      className={`--${item.status}${
                        item.dateStr === TodayStr ? ' --today' : ''
                      }`}
                      key={j}
                    >
                      <div className='--day'>
                        <button
                          className='--add-task'
                          onClick={() => handlePlusTaskClick(item.dateStr)}
                        >
                          {translate(lang, 'Add task')}
                        </button>
                        <span>{item.dayLabel}</span>
                        {checkRolePermission(
                          { allowedRoles: ['owner', 'admin'] },
                          profile?.role,
                        ) && (
                          <img
                            className='ant-image-img clock-image'
                            src={clock_icon}
                            alt='clock'
                            onClick={() => setDailyStr(item.dateStr)}
                          />
                        )}
                        {item.weathers && item.weathers.length > 0 && (
                          <img
                            className='ant-image-img'
                            src={weather_icon}
                            alt='weather'
                            onClick={() => setFocWeathers(item.weathers)}
                          />
                        )}
                      </div>
                      <CalendarCell
                        cellData={item.cellData}
                        onShowTask={onShowTask}
                        onCompleteTask={onCompleteTask}
                        onEditTask={onEditTask}
                        onDeleteTask={onDeleteTask}
                        onShowSeed={d => setSeedCell(d)}
                        onShowAssessment={d => setAssessCell(d)}
                        onShowMaintenance={d => setMntCell(d)}
                        onShowFloats={d => setFloatCell(d)}
                        onShowReqAssess={d => setReqCell(d)}
                        onShowHarvest={d => setHarCell(d)}
                        perPage={
                          viewMode === 'M'
                            ? 5
                            : viewMode === 'W'
                            ? 10
                            : item.cellData.assessments.length +
                              item.cellData.harvests.length +
                              item.cellData.inventory_seeds.length +
                              item.cellData.maintenances.length +
                              item.cellData.req_assess_seeds.length +
                              item.cellData.seedings.length +
                              item.cellData.tasks.length
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {!!showTaskData && (
        <ModalTask
          visible={true}
          title='View task'
          data={showTaskData}
          viewOnly
          onConfirm={() => setShowTaskData(undefined)}
          onCancel={() => setShowTaskData(undefined)}
        />
      )}
      {editTask && (
        <ModalTask
          title='Edit task'
          visible={editTask}
          onCancel={() => setEditTask(false)}
          data={editTaskData}
          updateId={Number(editTaskData?.id)}
          onConfirm={() => setEditTask(false)}
        />
      )}
      <ModalComponent
        title={translate(lang, 'Delete')}
        text={translate(lang, 'Are you sure to delete this data?')}
        visible={deleteTask}
        onCancel={() => setDeleteTask(!deleteTask)}
        type='delete'
        onConfirm={handleOnConfirmDelete}
      />
      {checkingTask && (
        <ModalComponent
          title={translate(lang, 'Complete Task')}
          text={translate(lang, 'Do you want to mark this task as completed?')}
          visible={true}
          onCancel={() => setCheckingTask(undefined)}
          type='confirm'
          onConfirm={doCompleteTask}
        />
      )}
      {focWeathers && focWeathers.length > 0 && (
        <FarmWeatherModal
          visible={true}
          title='Weather'
          onClose={() => setFocWeathers(undefined)}
          data={focWeathers}
        />
      )}
      {!!assessCell && (
        <AssessmentModal
          type={getBusinessType(assessCell.line)}
          visible={true}
          title='View assessment'
          onlyView={true}
          data={assessCell.data}
          lineData={assessCell.line}
          onConfirm={() => {}}
          onCancel={() => setAssessCell(undefined)}
        />
      )}
      {!!mntCell && (
        <MaintenanceModal
          visible={true}
          onlyView={true}
          onCancel={() => setMntCell(undefined)}
          onConfirm={() => {}}
          title='View maintenance'
          lineData={mntCell.line}
          data={mntCell.data}
        />
      )}
      {!!floatCell && (
        <FloatManageModal
          visible={true}
          title='View Floats Management'
          onlyView={true}
          onConfirm={() => {}}
          lineData={floatCell.line}
          data={{
            ...floatCell.data,
            inventory_id: floatCell.data.inventory.id,
          }}
          onCancel={() => setFloatCell(undefined)}
        />
      )}
      {!!reqCell && (
        <AssessmentModal
          type={getBusinessType(reqCell)}
          visible={true}
          title='Add assessment'
          onCancel={() => setReqCell(undefined)}
          onConfirm={() => {
            setReqCell(undefined);
            dispatch(loadFarmsData());
            loadData();
          }}
          lineData={reqCell}
        />
      )}
      {!!seedCell &&
        (profile?.account_type === 'grower' ? (
          <GrowerSeedModal
            type={getBusinessType(seedCell.line)}
            visible={true}
            lineData={seedCell.line}
            onClose={() => setSeedCell(undefined)}
            onConfirm={() => setSeedCell(undefined)}
            onlyView={true}
            seedData={seedCell.data}
          />
        ) : (
          <SeedingModal
            type={getBusinessType(seedCell.line)}
            onlyView={true}
            title='View seed data'
            visible={true}
            onCancel={() => setSeedCell(undefined)}
            onConfirm={() => setSeedCell(undefined)}
            lineData={seedCell.line}
            updateID={seedCell.data.id}
            data={seedCell.data}
          />
        ))}
      {!!harCell && (
        <HarvestViewModal
          type={getBusinessType(harCell.line)}
          visible={true}
          harvest={harCell}
          onClose={() => setHarCell(undefined)}
        />
      )}
      {!!dailyStr && (
        <DailyReportModal
          visible={true}
          dateStr={dailyStr}
          onClose={() => setDailyStr(undefined)}
        />
      )}
    </div>
  );
};

export default CalendarPage;
