import { PlusIcon, TrashIcon } from '..';
import DateTimePicker from '../datepicker/DateTimePicker';
import Button from '../button/Button';
import { ITimeRange } from '../../../entities/general.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';

interface Props {
  data: ITimeRange[];
  onChange: (d: any) => void;
  disabled?: boolean;
}

const TimeTrackInput = ({ data, onChange, disabled }: Props) => {
  const lang = useSelector(selectLang);

  const updateForm = (
    i: number,
    key: 'start_time' | 'finish_time',
    val: number | undefined,
  ) => {
    if (!val) return;
    let tmp = [...data];
    tmp[i][key] = val;
    onChange(tmp);
  };
  const addClick = () => {
    let tmp = [...data];
    tmp.push({ start_time: Date.now(), finish_time: Date.now() });
    onChange(tmp);
  };
  const deleteClick = (i: number) => {
    let tmp = [...data];
    if (i >= tmp.length) return;
    tmp.splice(i, 1);
    onChange(tmp);
  };

  return (
    <div className='mt-17 mb-32'>
      <div className='mb-7 d-flex'>
        <span>Time Tracking</span>
        {!disabled && (
          <div
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={addClick}
          >
            <PlusIcon />
          </div>
        )}
      </div>
      {data.map((x, i) => (
        <div key={i} className='mb-7 d-flex justify-content-between'>
          <DateTimePicker
            className='mr-12'
            defaultValue={x.start_time}
            label={translate(lang, 'Start time')}
            onChange={e => updateForm(i, 'start_time', e?.toDate().getTime())}
            disabled={!!disabled}
          />
          <DateTimePicker
            className='ml-12'
            defaultValue={x.finish_time}
            label={translate(lang, 'Finish time')}
            onChange={e => updateForm(i, 'finish_time', e?.toDate().getTime())}
            disabled={!!disabled}
          />
          {!disabled && (
            <div className='ml-7 d-flex align-items-end mb-7'>
              <Button
                width='full'
                size={0}
                type='bordered'
                onClick={deleteClick.bind(this, i)}
                color='red'
                className='d-flex justify-content-center'
              >
                <TrashIcon color='#cc0101' />
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const toSecTimeTracks = (data: ITimeRange[] | undefined | null) => {
  if (!data) return false;
  let prev = 0;
  for (let x of data) {
    if (!x.start_time || !x.finish_time) return false;
    if (x.start_time >= x.finish_time) return false;
    if (prev && prev >= x.start_time) return false;
    prev = x.finish_time;
  }
  return data.map(x => ({
    start_time: Math.floor(x.start_time / 1000),
    finish_time: Math.floor(x.finish_time / 1000),
  }));
};

export default TimeTrackInput;
