import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title,
  Dropdown,
  Spinner,
  Button,
  Tooltip,
} from '../../components/shared';
import { sendSingleRequest } from '../../apis';
import { useWidth } from '../../util/useWidth';
import BudgetYearArrows from '../../components/budget/BudgetYearArrows';
import NotFound from '../static/NotFound';
import { IBudgetFarm } from '../../entities/budget.entities';
import { showFeedback } from '../../store/ui/ui.actions';
import PlannedActualRate from '../../components/shared/tables-card/PlannedActualRate';
import { Table } from 'antd';
import { formatNumber, numberToMoneyStr } from '../../entities/util-functions';
import BudgetExpenseModal from '../../components/budget/BudgetExpenseModal';
import { randomKey } from '../../lib/common.helpers';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

type TRowName =
  | 'Harvest'
  | 'Length'
  | 'Harvest tonnes'
  | 'Income'
  | 'Harvest income'
  | 'Profit'
  | 'Expenses'
  | 'Seeding cost'
  | 'Maintenance cost'
  | 'Harvest cost'
  | 'Total expenses';

interface IRow {
  name: TRowName;
  planned: number;
  actual: number;
  count?: number;
  unit: '$' | 'm' | 't';
}

const ValueCell = (v: number, unit: '$' | 'm' | 't') =>
  unit === '$'
    ? `$ ${numberToMoneyStr(v)}`
    : unit === 'm'
    ? `${formatNumber(v)} m`
    : `${formatNumber(v / 1000)} t`;

const CountContent = (name: string, value: number) => {
  let title = '';
  if (name === 'Length' || name === 'Seeding cost') title = `${value} seedings`;
  else if (
    name === 'Harvest tonnes' ||
    name === 'Harvest income' ||
    name === 'Harvest cost'
  )
    title = `${value} harvests`;
  else if (name === 'Maintenance cost') title = `${value} maintenances`;
  return [{ id: randomKey(), title }];
};

const columns = [
  { title: '', dataIndex: 'name', key: 'name', width: '16%' },
  {
    title: 'Budgeted',
    key: 'planned',
    render: (x: IRow) =>
      ['Harvest', 'Income', 'Expenses'].includes(x.name) ? (
        <span></span>
      ) : !x.count ? (
        <span>{ValueCell(x.planned, x.unit)}</span>
      ) : (
        <Tooltip content={CountContent(x.name, x.count)} position='bottom'>
          <span style={{ position: 'inherit' }}>
            {ValueCell(x.planned, x.unit)}
          </span>
        </Tooltip>
      ),
  },
  {
    title: 'Actual',
    key: 'actual',
    render: (x: IRow) =>
      ['Harvest', 'Income', 'Expenses'].includes(x.name) ? (
        <span></span>
      ) : !x.count ? (
        <span>{ValueCell(x.actual, x.unit)}</span>
      ) : (
        <Tooltip content={CountContent(x.name, x.count)} position='top'>
          <span style={{ position: 'inherit' }}>
            {ValueCell(x.actual, x.unit)}
          </span>
        </Tooltip>
      ),
  },
  {
    title: 'Var, %',
    key: 'rate',
    render: (x: IRow) =>
      ['Harvest', 'Income', 'Expenses'].includes(x.name) ? (
        <span></span>
      ) : (
        <span>
          <PlannedActualRate planned={x.planned} actual={x.actual} />
        </span>
      ),
  },
];

const BudgetFarmPage = () => {
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const query = new URLSearchParams(useLocation().search);
  const lang = useSelector(selectLang);

  const farmId = Number(useParams<{ farmId: string }>().farmId);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IBudgetFarm>();
  const [lineId, setLineId] = useState<number>(
    query.get('line') ? Number(query.get('line')) : 0,
  );
  const [year, setYear] = useState(
    query.get('year') ? Number(query.get('year')) : new Date().getFullYear(),
  );
  const [showExpense, setShowExpense] = useState(false);

  const linesOption = [
    { id: '0', value: '0', label: translate(lang, 'All') },
    ...(data?.lines.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.line_name,
    })) ?? []),
  ];

  const tableData = useMemo(() => {
    let result: IRow[] = [];
    if (!data) {
      return result;
    }
    if (!lineId) {
      const expense = {
        planned:
          data.total_seeding_cost.planned +
          data.total_maintenance_cost.planned +
          data.total_harvest_cost.planned,
        actual:
          data.total_seeding_cost.actual +
          data.total_maintenance_cost.actual +
          data.total_harvest_cost.actual,
      };
      const profit = {
        planned: data.total_harvest_income.planned - expense.planned,
        actual: data.total_harvest_income.actual - expense.actual,
      };
      result.push({ name: 'Harvest', unit: 't', planned: 0, actual: 0 });
      result.push({ name: 'Length', unit: 'm', ...data.total_length });
      result.push({
        name: 'Harvest tonnes',
        unit: 't',
        ...data.total_harvest_amount,
      });
      result.push({ name: 'Income', unit: '$', planned: 0, actual: 0 });
      result.push({
        name: 'Harvest income',
        unit: '$',
        ...data.total_harvest_income,
      });
      result.push({ name: 'Profit', unit: '$', ...profit });
      result.push({ name: 'Expenses', unit: '$', planned: 0, actual: 0 });
      result.push({
        name: 'Seeding cost',
        unit: '$',
        ...data.total_seeding_cost,
      });
      result.push({
        name: 'Maintenance cost',
        unit: '$',
        ...data.total_maintenance_cost,
      });
      result.push({
        name: 'Harvest cost',
        unit: '$',
        ...data.total_harvest_cost,
      });
      result.push({ name: 'Total expenses', unit: '$', ...expense });
    } else {
      const line = data.lines.find(x => x.id === lineId);
      if (!line) return;
      const expense = {
        planned:
          line.seeding_cost.planned +
          line.maintenance_cost.planned +
          line.harvest_cost.planned,
        actual:
          line.seeding_cost.actual +
          line.maintenance_cost.actual +
          line.harvest_cost.actual,
      };
      const profit = {
        planned: line.harvest_income.planned - expense.planned,
        actual: line.harvest_income.actual - expense.actual,
      };
      result.push({ name: 'Harvest', unit: 't', planned: 0, actual: 0 });
      result.push({ name: 'Length', unit: 'm', ...line.length });
      result.push({
        name: 'Harvest tonnes',
        unit: 't',
        ...line.harvest_amount,
      });
      result.push({ name: 'Income', unit: '$', planned: 0, actual: 0 });
      result.push({
        name: 'Harvest income',
        unit: '$',
        ...line.harvest_income,
      });
      result.push({ name: 'Profit', unit: '$', ...profit });
      result.push({ name: 'Expenses', unit: '$', planned: 0, actual: 0 });
      result.push({ name: 'Seeding cost', unit: '$', ...line.seeding_cost });
      result.push({
        name: 'Maintenance cost',
        unit: '$',
        ...line.maintenance_cost,
      });
      result.push({ name: 'Harvest cost', unit: '$', ...line.harvest_cost });
      result.push({ name: 'Total expenses', unit: '$', ...expense });
    }
    return result;
  }, [lineId, data]);

  const loadData = useCallback(() => {
    if (!farmId) return;
    setLoading(true);
    sendSingleRequest(
      { farm_id: farmId, year },
      'GET',
      'api/farm/line/budgets',
      true,
    ).then(res => {
      setLoading(false);
      if (res.status) {
        setData(res.data);
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            message: translate(lang, res.data?.message ?? 'Unknown error'),
            type: 'error',
          }),
        );
      }
    });
  }, [farmId, year]);

  useEffect(() => loadData(), [loadData]);

  return (
    <div className='budget bg-secondary min-height'>
      <div className='container'>
        <div
          className={
            width > 460
              ? 'd-flex pt-28 pb-24 pr-15 pl-21 align-items-center justify-content-between'
              : 'pt-16'
          }
        >
          <Title size={5} color='black-3' align='default' fontWeight={700}>
            {data?.name}
          </Title>
          <BudgetYearArrows
            disabledPrev={loading}
            disabledNext={year === new Date().getFullYear() || loading}
            year={year}
            onChange={value => setYear(value)}
          />
        </div>
        <div className={width > 460 ? 'pl-15 pr-15 pb-34' : 'pb-70'}>
          {loading ? (
            <div className='mt-20'>
              <Spinner />
            </div>
          ) : !data ? (
            <NotFound />
          ) : (
            <>
              <div className='budget-line d-flex justify-content-between align-items-center'>
                <Dropdown
                  label={translate(lang, 'Select line')}
                  className='dropdown-budget'
                  onChange={v => setLineId(Number(v))}
                  options={linesOption as any}
                  value={lineId.toString()}
                  defaultValue={'0'}
                />
                <div className='d-flex flex-direction-row align-items-center'>
                  <Button
                    size={2}
                    width='wide'
                    type='fill'
                    color='blue'
                    onClick={() => setShowExpense(true)}
                  >
                    {translate(lang, 'Add expense')}
                  </Button>
                </div>
              </div>
              <Table
                className='mr-table mr-table-budget mt-12'
                rowKey={'name'}
                pagination={false}
                bordered
                columns={columns}
                dataSource={tableData}
                rowClassName={(d: IRow) => {
                  if (d.name === 'Profit' || d.name === 'Total expenses') {
                    return 'mr-table__row-footer';
                  }
                  if (
                    d.name === 'Harvest' ||
                    d.name === 'Income' ||
                    d.name === 'Expenses'
                  ) {
                    return 'mr-table__row-title';
                  }
                  if (
                    d.name === 'Seeding cost' ||
                    d.name === 'Maintenance cost' ||
                    d.name === 'Harvest cost'
                  ) {
                    return 'mr-table__row-sub-title';
                  }
                  if (d.actual >= d.planned) {
                    return 'mr-table__row-bg';
                  }
                  return '';
                }}
              />
            </>
          )}
        </div>
      </div>
      {showExpense && !!data && (
        <BudgetExpenseModal
          visible={showExpense}
          farmId={farmId}
          paramLineId={lineId ? lineId : undefined}
          budgetFarm={data}
          onCancel={() => setShowExpense(false)}
          onConfirm={() => {
            loadData();
            setShowExpense(false);
          }}
        />
      )}
    </div>
  );
};

export default BudgetFarmPage;
