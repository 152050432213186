import { Modal, Table } from 'antd';
import {
  Button,
  CloseIcon,
  Input,
  Subtitle,
  Title,
} from '../../components/shared';
import { useCallback, useEffect, useState } from 'react';
import { sendSingleRequest } from '../../apis';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../store/auth/auth.selector';
import { useHistory, useLocation } from 'react-router-dom';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';

interface IRow {
  id: number;
  farm_id: number;
  farm_name: string;
  is_approved: boolean;
}

interface IRequested extends IRow {
  owner_name: string;
}

interface IShared extends IRow {
  user_name: string;
}

interface IModalProps {
  visible: boolean;
  data: IShared;
  onCancel: () => void;
  onConfirm: () => void;
}

const SharedModal = ({ visible, data, onCancel, onConfirm }: IModalProps) => {
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);

  const approveClick = async (status: boolean) => {
    setDisabled(true);
    const response = await sendSingleRequest(
      { access_id: data.id, status },
      'PUT',
      'api/user/farm-access',
      true,
    );
    if (response.status) {
      window.alert(
        translate(lang, response.data?.message ?? 'Approved successfully'),
      );
      onConfirm();
    } else {
      window.alert(translate(lang, response.data?.message ?? 'Unknown error'));
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Farm access request')}
          </Subtitle>
        </div>
        <div className='mt-24 mb-24'>
          <div className='mb-7'>
            <Subtitle color='black-1' align='left' size={5} fontWeight={500}>
              {`${translate(lang, 'Farm')}: ${data.farm_name}`}
            </Subtitle>
          </div>
          <div className='mb-7'>
            <Subtitle color='black-1' align='left' size={5} fontWeight={500}>
              {`${translate(lang, 'Requestor')}: ${data.user_name}`}
            </Subtitle>
          </div>
          <div className='mb-7'>
            <Subtitle color='black-1' align='left' size={5} fontWeight={500}>
              {`${translate(lang, 'Status')}: ${translate(
                lang,
                data.is_approved ? 'Approved' : 'Pending',
              )}`}
            </Subtitle>
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        {!data.is_approved ? (
          <>
            <Button
              width={'small'}
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16 mt-24'
              onClick={() => approveClick(true)}
              disabled={disabled}
            >
              {translate(lang, 'Approve')}
            </Button>
            <Button
              width={'small'}
              size={2}
              type='fill'
              color='red'
              className='rsp-btn ml-16 mt-24'
              onClick={() => approveClick(false)}
              disabled={disabled}
            >
              {translate(lang, 'Decline')}
            </Button>
          </>
        ) : (
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='blue'
            className='rsp-btn ml-16 mt-24'
            onClick={onCancel}
          >
            {translate(lang, 'Close')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

const r_columns = (lang: TLang | undefined): any => [
    {
      title: translate(lang, 'Farm ID'),
      key: 'farm_id',
      render: (x: IRequested) => <div>{x.farm_id}</div>,
    },
    {
      title: translate(lang, 'Farm'),
      key: 'farm_name',
      render: (x: IRequested) => <div>{x.farm_name}</div>,
    },
    {
      title: translate(lang, 'Owner'),
      key: 'owner_name',
      render: (x: IRequested) => <div>{x.owner_name}</div>,
    },
    {
      title: translate(lang, 'Status'),
      key: 'is_approved',
      align: 'center',
      render: (x: IRequested) => (
        <div>{translate(lang, x.is_approved ? 'Approved' : 'Pending')}</div>
      ),
    },
  ],
  s_columns = (lang: TLang | undefined): any => [
    {
      title: translate(lang, 'Farm ID'),
      key: 'farm_id',
      render: (x: IShared) => <div>{x.farm_id}</div>,
    },
    {
      title: translate(lang, 'Farm'),
      key: 'farm_name',
      render: (x: IShared) => <div>{x.farm_name}</div>,
    },
    {
      title: translate(lang, 'Requestor'),
      key: 'user',
      render: (x: IShared) => <div>{x.user_name}</div>,
    },
    {
      title: translate(lang, 'Status'),
      key: 'is_approved',
      align: 'center',
      render: (x: IShared) => (
        <div>{translate(lang, x.is_approved ? 'Approved' : 'Pending')}</div>
      ),
    },
  ];

let isTried = false;

const ExternalFarmAccessPage = () => {
  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const [requested, setRequested] = useState<IRequested[]>([]);
  const [shared, setShared] = useState<IShared[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [farmNo, setFarmNo] = useState('');
  const [focShd, setFocShd] = useState<IShared>();

  const loadData = useCallback(async (type: 'request' | 'shared') => {
    const res = await sendSingleRequest(
      { type },
      'GET',
      'api/user/farm-access',
      true,
    );
    if (res.status) {
      if (type === 'request') {
        setRequested(res.data);
      } else {
        setShared(res.data);
      }
    } else {
      window.alert(translate(lang, res.data?.message ?? 'Unknown error'));
    }
  }, []);

  const confirmClick = async () => {
    if (!farmNo) {
      window.alert('Please enter farm ID');
      return;
    }
    setDisabled(true);
    const res = await sendSingleRequest(
      { farm_id: farmNo },
      'POST',
      'api/user/farm-access',
      true,
    );
    if (res.status) {
      await loadData('request');
      setDisabled(false);
      setFarmNo('');
      setVisible(false);
    } else {
      setDisabled(false);
      window.alert(res.data?.message ?? 'Invalid farm id');
    }
  };

  useEffect(() => {
    loadData('request');
    if (profile?.role === 'owner') {
      loadData('shared');
    }
  }, [loadData, profile]);

  useEffect(() => {
    (async () => {
      const access_id = query.get('access_id'),
        status = query.get('status');

      if (!isTried && profile?.role === 'owner' && access_id && status) {
        isTried = true;
        const response = await sendSingleRequest(
          { access_id, status: status === 'approve' },
          'PUT',
          'api/user/farm-access',
          true,
        );
        history.replace('/external-farm-access');
        if (response.status) {
          window.alert(
            translate(
              lang,
              response.data?.message ??
                (status === 'approve'
                  ? 'Approved successfully'
                  : 'Declined successfully'),
            ),
          );
          await loadData('shared');
        } else {
          window.alert(
            translate(lang, response.data?.message ?? 'Unknown error'),
          );
        }
      }
    })();
  }, [query, profile]);

  return (
    <div className='container w-100'>
      <div
        className='content pb-32 pt-32'
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <div className='mb-32'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'External Farm Access Requests')}
          </Title>
        </div>
        <div className='d-flex justify-content-end'>
          <Button
            color='blue'
            size={3}
            width='small'
            type='fill'
            onClick={() => setVisible(true)}
          >
            {translate(lang, 'Request farm access')}
          </Button>
        </div>
        <div className='mt-32 mb-32'>
          <Table
            className='table table--units'
            rowKey={'id'}
            pagination={false}
            columns={r_columns(lang)}
            dataSource={requested}
          />
        </div>
        {profile?.role === 'owner' && (
          <>
            <div className='mb-32'>
              <Title size={5} color='black' align='default' fontWeight={700}>
                {translate(lang, 'My Shared Farms')}
              </Title>
            </div>
            <div className='mb-32'>
              <Table
                className='table table--units'
                rowKey={'id'}
                pagination={false}
                columns={s_columns(lang)}
                dataSource={shared}
                onRow={record => ({
                  onClick: () => setFocShd(record),
                })}
              />
            </div>
          </>
        )}
      </div>
      {visible && (
        <Modal
          visible={true}
          onCancel={() => setVisible(false)}
          footer={null}
          closable
          closeIcon={<CloseIcon />}
        >
          <div className='wrap'>
            <div className='d-flex align-items-center mb-32'>
              <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                {translate(lang, 'External Farm Access Requests')}
              </Subtitle>
            </div>
            <Input
              label={translate(lang, 'Farm ID')}
              className='mr-16 w-100 mb-17'
              type='number'
              value={farmNo}
              onChange={e => setFarmNo(e.target.value)}
            />
          </div>
          <div className='modal-button d-flex justify-content-end align-items-center'>
            <Button
              width={'small'}
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16 mt-24'
              onClick={confirmClick}
              disabled={disabled}
            >
              {translate(lang, 'Confirm')}
            </Button>
          </div>
        </Modal>
      )}
      {!!focShd && (
        <SharedModal
          visible={true}
          data={focShd}
          onCancel={() => setFocShd(undefined)}
          onConfirm={() => {
            loadData('shared');
            setFocShd(undefined);
          }}
        />
      )}
    </div>
  );
};

export default ExternalFarmAccessPage;
