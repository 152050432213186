import { Modal, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { IBudgetFarm } from '../../entities/budget.entities';
import {
  Button,
  CloseIcon,
  Dropdown,
  Input,
  RadioButton,
  Subtitle,
} from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const typeOptions = [
  { id: 'seeding_cost', value: 'seeding_cost', label: 'Seeding Cost ($)' },
  {
    id: 'maintenance_cost',
    value: 'maintenance_cost',
    label: 'Maintenance Cost ($)',
  },
  { id: 'harvest_cost', value: 'harvest_cost', label: 'Harvest Cost ($)' },
];

const changeOptions = [
  {
    id: 'harvest_income',
    value: 'harvest_income',
    label: 'Harvest Income ($)',
  },
  {
    id: 'harvest_amount',
    value: 'harvest_amount',
    label: 'Harvest Amount (kg)',
  },
  { id: 'line_length', value: 'line_length', label: 'Budgeted Length (m)' },
];

interface Props {
  visible: boolean;
  farmId: number;
  paramLineId?: number;
  budgetFarm: IBudgetFarm;
  onConfirm: (d: any) => void;
  onCancel: () => void;
}

const BudgetExpenseModal = ({
  visible,
  farmId,
  paramLineId,
  budgetFarm,
  onConfirm,
  onCancel,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const linesOption =
    useSelector(selectFarmsData)
      .find(x => x.id === farmId)
      ?.lines.map(l => ({
        id: l.id.toString(),
        value: l.id.toString(),
        label: l.line_name,
      })) ?? [];

  const [mode, setMode] = useState<'add' | 'update'>('add');
  const [disabled, setDisabled] = useState(false);
  const [lineId, setLineId] = useState(paramLineId?.toString() ?? '');
  const [name, setName] = useState('');
  const [expenseType, setExpenseType] = useState<string>();
  const [budgetType, setBudgetType] = useState<string>();
  const [amount, setAmount] = useState('');
  const [unit, setUnit] = useState('$');

  const showError = (message: string) =>
    dispatch(showFeedback({ isMessageModal: true, type: 'error', message }));
  const confirmClick = async () => {
    if (!lineId) {
      showError(translate(lang, 'Please select a line'));
      return;
    }
    if (mode === 'add') {
      if (!expenseType) {
        showError(translate(lang, 'Which cost expense do you want to add ?'));
        return;
      }
      if (!name) {
        showError(translate(lang, 'Please input budgeted expense name'));
        return;
      }
    } else {
      if (!budgetType) {
        showError(translate(lang, 'Which budget do you want to update ?'));
        return;
      }
    }
    if (!amount || !Number(amount)) {
      showError(translate(lang, 'Please input valid expense amount'));
      return;
    }
    setDisabled(true);
    const res =
      mode === 'add'
        ? await sendSingleRequest(
            { line_id: lineId, name, type: expenseType, amount },
            'POST',
            'api/farm/line/budgets/add-expenses',
            true,
          )
        : await sendSingleRequest(
            { line_id: lineId, type: budgetType, amount },
            'PUT',
            'api/farm/line/budget/update',
            true,
          );

    setDisabled(false);
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Succeed'),
        }),
      );
      onConfirm(res.data);
    } else {
      showError(translate(lang, res.data?.message ?? 'Something went wrong'));
    }
  };

  useEffect(() => {
    if (lineId && budgetType) {
      const bud = budgetFarm.lines.find(x => x.id === Number(lineId));
      if (budgetType === 'line_length') {
        setAmount(bud?.length.planned.toString() ?? '');
        setUnit('m');
      } else if (budgetType === 'harvest_income') {
        setAmount(bud?.harvest_income.planned.toString() ?? '');
        setUnit('$');
      } else if (budgetType === 'harvest_amount') {
        setAmount(bud?.harvest_amount.planned.toString() ?? '');
        setUnit('kg');
      } else {
        setUnit('$');
      }
    }
    if (mode === 'add') {
      setUnit('$');
    }

    return () => {};
  }, [lineId, budgetType]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='budget-expense-modal wrap'>
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(
              lang,
              mode === 'add'
                ? 'Add budgeted expense'
                : 'Update budgeted amount',
            )}
          </Subtitle>
        </div>
        <div className='mt-32 mb-17'>
          <Radio.Group
            className='d-flex'
            onChange={e => setMode(e.target.value)}
            value={mode}
          >
            <RadioButton label={translate(lang, 'Add expense')} value='add' />
            <RadioButton
              className='ml-34'
              label={translate(lang, 'Update budgeted')}
              value='update'
            />
          </Radio.Group>
        </div>
        <div className='mb-32'>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Select line')}
              options={linesOption}
              value={linesOption.find(t => t.id === lineId)?.label}
              onChange={v => setLineId(v)}
            />
          </div>
          {mode === 'add' ? (
            <>
              <div className='mb-17'>
                <Input
                  type='text'
                  label={translate(lang, 'Budgeted expense name')}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className='mb-17'>
                <Dropdown
                  label={translate(lang, 'Which cost do you want to add ?')}
                  options={typeOptions}
                  value={expenseType}
                  onChange={v => setExpenseType(v)}
                />
              </div>
            </>
          ) : (
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, 'Which budget do you want to update ?')}
                options={changeOptions}
                value={budgetType}
                onChange={v => setBudgetType(v)}
              />
            </div>
          )}
          <div className='mb-17'>
            <Input
              type='number'
              label={translate(lang, 'Amount')}
              value={amount}
              onChange={e => setAmount(e.target.value)}
              unit={unit}
            />
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            className='rsp-btn'
            width='small'
            size={2}
            type='fill'
            color='red'
            onClick={onCancel}
            disabled={disabled}
          >
            {translate(lang, 'Cancel')}
          </Button>
          <Button
            width='small'
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={confirmClick}
            disabled={disabled}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default BudgetExpenseModal;
