import {
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
  ISeaweedLineResource,
} from '../../../entities/farms.entities';
import MusselAssessModal from './MusselAssessModal';
import OysterAssessModal from './OysterAssessModal';
import { TBusinessType } from '../../../entities/general.entities';
import SeaweedAssessModal from './SeaweedAssessModal';
import '../styles.scss';

interface Props {
  type: TBusinessType;
  visible: boolean;
  title?: string;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  data?: any;
  lineData: ILineResource;
  updateID?: number;
  onlyView?: boolean;
  overConfirm?: (data: any) => void; // only for MUSSEL
  lineChangeable?: boolean; // only for MUSSEL
}

const AssessmentModal = ({
  type,
  visible,
  title,
  onCancel,
  onConfirm,
  data,
  lineData,
  updateID,
  onlyView,
  overConfirm,
  lineChangeable,
}: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselAssessModal
        visible={visible}
        title={title}
        onCancel={onCancel}
        onConfirm={onConfirm}
        data={data}
        lineData={lineData as IMusselLineResource}
        updateID={updateID}
        onlyView={onlyView}
        overConfirm={overConfirm}
        lineChangeable={lineChangeable}
      />
    ) : type === 'OYSTER' ? (
      <OysterAssessModal
        visible={visible}
        title={title}
        onCancel={onCancel}
        onConfirm={onConfirm}
        data={data}
        lineData={lineData as IOysterLineResource}
        updateID={updateID}
        onlyView={onlyView}
        lineChangeable={lineChangeable}
        overConfirm={overConfirm}
      />
    ) : (
      <SeaweedAssessModal
        visible={visible}
        title={title}
        onCancel={onCancel}
        onConfirm={onConfirm}
        data={data}
        lineData={lineData as ISeaweedLineResource}
        updateID={updateID}
        onlyView={onlyView}
      />
    )}
  </>
);

export default AssessmentModal;
