import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { Button, Input, Spinner } from '../../components/shared';
import { hashColor } from '../../entities/util-functions';
import { showFeedback } from '../../store/ui/ui.actions';
import { loadUserBoats } from '../../store/users/users.actions';
import { toMillisecond } from '../../util/toggleSecondMillisecond';
import { IBoatMessage, IBoatResource } from '../../entities/boat.entities';
import boat_png from '../../images/boat.png';
import { updateUserMeta } from '../../store/auth/auth.actions';
import { selectProfile } from '../../store/auth/auth.selector';
import {
  selectBoats,
  selectNewBoatMessage,
} from '../../store/users/users.selector';
import { selectLang } from '../../store/ui/ui.selector';
import './styles.scss';
import { translate } from '../../lib/lang.helper';

const BoatMessagesPage = () => {
  const dispatch = useDispatch<any>();
  const isReading = useRef<boolean>(false);

  const profile = useSelector(selectProfile);
  const boatsData = useSelector(selectBoats);
  const newBoatMessage = useSelector(selectNewBoatMessage);
  const lang = useSelector(selectLang);

  const [lastMessage, setLastMessage] = useState<IBoatMessage>();
  const [boats, setBoats] = useState<IBoatResource[]>([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [sbId, setSbId] = useState<number>();
  const [messages, setMessages] = useState<IBoatMessage[]>([]);
  const [limit, setLimit] = useState(30);
  const [content, setContent] = useState('');

  const onScrollEvent = (event: React.UIEvent<HTMLDivElement>) => {
    const target: any = event.target;
    if (
      sbId &&
      target.scrollHeight + target.scrollTop <= target.clientHeight + 10
    ) {
      if (limit <= messages.length) setLimit(limit + 30);
    }
  };
  const handleClickBoat = (boatId: number) => {
    if (boatId !== sbId) {
      setLimit(30);
      setLoading(true);
      setSbId(boatId);
    }
  };
  const readMessage = (boatId: number, msg: IBoatMessage[]) => {
    if (
      msg.length <= 0 ||
      !boats.find(x => x.id === boatId)?.unread_message_count ||
      isReading.current
    )
      return;
    const lastMsg = msg.reduce((p, c) => (p.id > c.id ? p : c));
    const tmp = boats.map(x =>
      x.id === boatId ? { ...x, unread_message_count: 0 } : x,
    );
    setBoats(tmp);

    isReading.current = true;
    sendSingleRequest(
      { boat_id: boatId, last_message_id: lastMsg.id },
      'POST',
      'api/boat-message/read-message',
      true,
    ).then(res => {
      dispatch(updateUserMeta());
      isReading.current = false;
    });
  };
  const inputContentHandle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (sbId) readMessage(sbId, messages);
    setContent(event.target.value);
  };
  const sendClickHandle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (content.length <= 0 || !sbId) return;
    setDisabled(true);
    sendSingleRequest(
      { boat_id: sbId, content },
      'POST',
      'api/boat-message/message',
      true,
    ).then(res => {
      setDisabled(false);
      if (res.status) {
        setContent('');
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, res.data?.message ?? 'Unknown error'),
          }),
        );
      }
    });
  };

  const loadMessages = useCallback(() => {
    if (!sbId) return;
    sendSingleRequest(
      { boat_id: sbId, limit },
      'GET',
      'api/boat-message/messages',
      true,
    ).then(res => {
      if (loading) setLoading(false);
      if (res.status) {
        setMessages(res.data);
        setLastMessage(undefined);
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, res.data?.message ?? 'Unknown error'),
          }),
        );
      }
    });
  }, [limit, sbId]);

  useEffect(() => setBoats(boatsData), [boatsData]);
  useEffect(() => loadMessages(), [loadMessages]);

  useEffect(() => {
    dispatch(loadUserBoats({ with_message_count: true }));
  }, [dispatch]);

  useEffect(() => {
    if (lastMessage) {
      let tempMsg = { ...lastMessage };
      if (profile?.id === tempMsg.user_id) {
        tempMsg.is_mine = true;
        tempMsg.is_read = true;
      } else {
        tempMsg.is_mine = false;
      }
      if (sbId && tempMsg.boat_id === sbId) {
        tempMsg.is_read = true;
        if (messages.findIndex(x => x.id === tempMsg.id) < 0) {
          setMessages([tempMsg, ...messages]);
        }
      } else {
        tempMsg.is_read = false;
        const tmp = boats.map(x =>
          x.id === tempMsg.boat_id
            ? { ...x, unread_message_count: (x.unread_message_count ?? 0) + 1 }
            : x,
        );
        setBoats(tmp);
        dispatch(updateUserMeta());
      }
      setLastMessage(undefined);
    }
  }, [lastMessage, messages, boats, sbId]);

  useEffect(() => {
    setLastMessage(newBoatMessage);
  }, [newBoatMessage]);

  const lastReadId = messages.reduce(
    (p, c) => ((c.is_read || c.is_mine) && p < c.id ? c.id : p),
    0,
  );

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container'>
        <div className='boat-messages-page'>
          <div className='--boats-list'>
            {boats.map(x => (
              <div
                key={x.id}
                className={`--boat-row ${
                  sbId === x.id ? '--selected-boat' : ''
                }`}
                onClick={handleClickBoat.bind(this, x.id)}
              >
                <div
                  className='--boat-image'
                  style={{
                    backgroundColor: hashColor(x.reg_number),
                  }}
                >
                  <img src={boat_png} alt='boat' />
                </div>
                <div className='--boat-profile'>
                  <div className='--boat-name'>{x.name}</div>
                  <div className='--boat-number'>{x.reg_number}</div>
                </div>
                {!!x.unread_message_count && (
                  <div className='--boat-unread-count'>
                    {x.unread_message_count}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='--inbox'>
            {loading ? (
              <Spinner />
            ) : (
              sbId && (
                <>
                  {messages.length > 0 && (
                    <div
                      className='white-card --messages'
                      onScroll={onScrollEvent}
                      onClick={() => readMessage(sbId, messages)}
                    >
                      {messages.map(msg => (
                        <div
                          key={msg.id}
                          className={`--message ${
                            msg.user_id === profile?.id
                              ? '--sender'
                              : '--receiver'
                          } ${msg.id > lastReadId ? '--unread' : ''}`}
                        >
                          <div className='text-left'>
                            {msg.user_id !== profile?.id && (
                              <div className='--writer'>{msg.user.name}</div>
                            )}
                            <pre className='--content'>{msg.content}</pre>
                            <div className='--created_at'>
                              {moment(toMillisecond(msg.created_at)).format(
                                'dddd hh:mm a',
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div
                    className='white-card'
                    onClick={() => readMessage(sbId, messages)}
                  >
                    <div className='card-body'>
                      <Input
                        label=''
                        placeholder={translate(
                          lang,
                          'Please put your text here',
                        )}
                        type='textarea'
                        value={content}
                        onChange={inputContentHandle}
                        disabled={disabled}
                      />
                    </div>
                    <div className='card-footer d-flex justify-content-end'>
                      <Button
                        className='align-right'
                        type='bordered'
                        color='green'
                        size={2}
                        width='100px'
                        disabled={disabled}
                        onClick={sendClickHandle}
                      >
                        {translate(lang, 'Send')}
                      </Button>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoatMessagesPage;
