import { Table } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import MobileAutomationTable from './MobileAutomationTable';
import DropdownMenu from '../shared/dropdown-menu/DropdownMenu';
import { useWidth } from '../../util/useWidth';
import {
  IAutomation,
  ITag,
  ITagSchedule,
} from '../../entities/automation.entities';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectTags } from '../../store/automation/automation.selector';
import './styles.scss';

const timeLabel = (t: number, unit: string) =>
  t === 0
    ? `At the time`
    : t < 0
    ? `${-t} ${unit}(s) Before`
    : `${t} ${unit}(s) After`;

const columns = (lang: any, tags: ITag[]): any[] => [
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => <span>{id}</span>,
  },
  {
    title: 'Where',
    key: 'line',
    render: (x: IAutomation | ITagSchedule) => {
      if (x.type === 'TAG_SCHEDULE') {
        const t = x as ITagSchedule;
        return (
          <span>
            {t.lines && t.lines.length > 0
              ? `On ${t.lines.length} lines`
              : `On ${t.farms.length} farms`}
          </span>
        );
      }
      const d = x as IAutomation;
      if (!d.farm && !d.line) {
        return <span>{'On every line'}</span>;
      }
      return (
        <span>
          {!!d.farm && <span>{d.farm.name}</span>}
          {!!d.line && <span className='ml-7'>{d.line.line_name}</span>}
        </span>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (d: IAutomation | ITagSchedule) =>
      d.type === 'Assessment' ? (
        <>
          <span className='mr-10'>
            {translate(lang, 'If Assessment Is Created Then')}
          </span>
          <span>{timeLabel(d.time, d.unit)}</span>
        </>
      ) : d.type === 'Seeding' ? (
        <>
          <span className='mr-10'>
            {translate(lang, 'If Seed is planted Then')}
          </span>
          <span>{timeLabel(d.time, d.unit)}</span>
        </>
      ) : d.type === 'Harvesting' ? (
        <>
          <span className='mr-10'>
            {translate(lang, 'If Harvest is completed Then')}
          </span>
          <span>{timeLabel(d.time, d.unit)}</span>
        </>
      ) : d.type === 'TAG_SCHEDULE' ? (
        <span>{timeLabel(d.time, d.unit)}</span>
      ) : (
        <span>{`${translate(lang, 'Every')} ${d.time} ${d.unit}(s)`}</span>
      ),
  },
  {
    title: 'Outcome',
    key: 'outcome',
    render: (data: IAutomation | ITagSchedule) => {
      const d = data as IAutomation;
      const t = data as ITagSchedule;

      return data.type === 'TAG_SCHEDULE' ? (
        <div>
          {`Tag ${tags?.find(x => x.id === t.tag_id)?.name} to ${
            t.lines && t.lines.length > 0
              ? `${t.lines.length} line(s)`
              : `${t.farms.length} farm(s)`
          }`}
        </div>
      ) : (
        <div className='d-flex'>
          <div className='mr-15 tx-left' style={{ width: 80 }}>
            {translate(lang, 'Create task')}
          </div>
          <div
            className='d-flex flex-direction-col tx-left'
            style={{ flex: 1 }}
          >
            <div>
              <span>{translate(lang, 'Title')}:</span>
              <span>{d.title}</span>
            </div>
            <div>
              <span>{translate(lang, 'Description')}:</span>
              <span>
                {d.description.length >= 70
                  ? `${d.description.slice(0, 70)}...`
                  : d.description}
              </span>
            </div>
          </div>
        </div>
      );
    },
  },
];

interface Props {
  data: IAutomation[];
  isTableChild?: boolean;
  onEdit: (d: any) => void;
  onDelete: (d: any) => void;
}

const AutomationTable = ({ data, isTableChild, onEdit, onDelete }: Props) => {
  const width = useWidth();
  const profile = useSelector(selectProfile);
  const lang = useSelector(selectLang);
  const tags = useSelector(selectTags);

  const checkPermission = (d: IAutomation) => {
    if (profile?.role === 'admin' || profile?.role === 'owner') {
      return 1;
    }
    if (profile?.id === d.creator_id || profile?.id === d.assigned_to) {
      return 1;
    }
    return 0;
  };
  const editField = {
    title: '',
    key: 'more',
    align: 'right',
    render: (d: any) =>
      checkPermission(d) ? (
        <>
          <DropdownMenu
            data={d}
            column={'isAutomation'}
            onEdit={onEdit}
            onDeleteRow={onDelete}
            type='automations'
          />
        </>
      ) : (
        <></>
      ),
  };

  return (
    <>
      {width > 820 ? (
        <Table
          className={classNames(`budget table table--isAutomation`, {
            'table--is__child': isTableChild,
          })}
          rowKey='id'
          pagination={false}
          columns={[...columns(lang, tags ?? []), editField]}
          dataSource={data}
        />
      ) : (
        <MobileAutomationTable
          data={data}
          handleOnEdit={onEdit}
          onDeleteRow={onDelete}
        />
      )}
    </>
  );
};

export default AutomationTable;
