import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title,
  TabsComponent,
  Input,
  DropdownMenu,
  Spinner,
} from '../../components/shared';
import ModalTask from '../../components/todo/ModalTask';
import { getTaskData } from '../../store/tasks/tasks.actions';
import { ITaskResource } from '../../entities/task.entities';
import moment from 'moment';
import TaskTypeButton from '../../components/todo/TaskTypeButton';
import { Table, Button } from 'antd';
import { selectTasks } from '../../store/tasks/tasks.selector';
import { sendSingleRequest } from '../../apis';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { isNormalTask } from '../../lib/task.helper';
import './styles.scss';

const TasksTable: FC<{
  isCompleted: boolean;
  onRowClick: (t: ITaskResource) => void;
  onDeleteClick: (t: ITaskResource) => void;
  onEditTask: (t: ITaskResource) => void;
  onCompleteTask: (t: ITaskResource) => void;
  tasksData: ITaskResource[];
}> = ({
  isCompleted,
  onRowClick,
  onDeleteClick,
  onEditTask,
  onCompleteTask,
  tasksData,
}) => {
  const lang = useSelector(selectLang);

  const columns = useMemo(() => {
    const rs = [
      {
        title: 'Title',
        key: 'title',
        render: (x: ITaskResource) => <span>{x.title}</span>,
        sorter: (a: ITaskResource, b: ITaskResource) =>
          a.title.localeCompare(b.title),
      },
      {
        title: 'Creator',
        key: 'creator',
        render: (x: ITaskResource) => (
          <span>
            {x.creator
              ? `created by ${x.creator.name} on ${moment(
                  1000 * x.created_at,
                ).format('DD.MM.YYYY')}`
              : `created on ${moment(1000 * x.created_at).format(
                  'DD.MM.YYYY',
                )}`}
          </span>
        ),
        sorter: (a: ITaskResource, b: ITaskResource) =>
          a.created_at - b.created_at,
      },
      {
        title: 'Type',
        key: 'type',
        render: (x: ITaskResource) => (
          <div onClick={e => e.stopPropagation()}>
            <TaskTypeButton task={x} />
          </div>
        ),
      },
    ];
    if (!isCompleted) {
      rs.push(
        {
          title: 'Due to',
          key: 'due_date',
          render: (x: ITaskResource) => {
            const dov = moment.unix(x.due_date / 1000);
            const d = Math.round(moment().diff(dov, 'days', true));
            return (
              <div>
                <div>{moment(x.due_date).format('DD.MM.YYYY')}</div>
                {d > 0 ? (
                  <div className='ant-tag ant-tag-red'>{`Overdue ${d} day(s)`}</div>
                ) : d === 0 ? (
                  <div className='ant-tag ant-tag-gold'>{'Due today'}</div>
                ) : (
                  <div className='ant-tag ant-tag-green'>{`Due in ${-d} day(s)`}</div>
                )}
              </div>
            );
          },
          sorter: (a: ITaskResource, b: ITaskResource) =>
            a.due_date - b.due_date,
        },
        {
          title: '',
          key: 'action',
          render: (x: ITaskResource) => (
            <div onClick={e => e.stopPropagation()}>
              <DropdownMenu
                data={x}
                type='todo'
                onEdit={onEditTask}
                onDeleteRow={onDeleteClick}
                onArchiveRow={onCompleteTask}
              />
            </div>
          ),
        },
      );
    } else {
      rs.push({
        title: '',
        key: 'action',
        render: (x: ITaskResource) => (
          <Button
            size='small'
            danger={true}
            type='primary'
            onClick={e => {
              e.stopPropagation();
              onDeleteClick(x);
            }}
          >
            Delete
          </Button>
        ),
      });
    }
    return rs.map(x => ({ ...x, title: translate(lang, x.title) }));
  }, [isCompleted, lang]);

  return (
    <div className='w-100' style={{ overflow: 'auto' }}>
      <Table
        rowKey='id'
        className='table table--isFarm'
        pagination={false}
        columns={columns}
        dataSource={tasksData}
        onRow={r => ({
          onClick: () => onRowClick(r as any),
        })}
      />
    </div>
  );
};

const tabs = [
  { id: 'all', title: 'All' },
  { id: 'GENERAL', title: 'General' },
  { id: 'SEEDING', title: 'Seeding' },
  { id: 'ASSESSMENT', title: 'Assessment' },
  { id: 'MAINTENANCE', title: 'Maintenance' },
  { id: 'HARVEST', title: 'Harvest' },
  { id: 'completed', title: 'Completed' },
];

const TaskListPage = () => {
  const dispatch = useDispatch();
  const tasksData = useSelector(selectTasks);
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [createTask, setCreateTask] = useState(false);
  const [showTask, setShowTask] = useState<ITaskResource>();
  const [activeTab, setActiveTab] = useState('all');
  const [filterTitle, setFilterTitle] = useState('');
  const [editTask, setEditTask] = useState<ITaskResource>();

  const completedClearClick = async () => {
    const r = window.confirm('Are you sure to delete all completed tasks?');
    if (r) {
      setDisabled(true);
      const rs = await sendSingleRequest(
        {},
        'POST',
        'api/task/remove-completed-tasks',
        true,
      );
      if (rs.status) {
        await dispatch(getTaskData());
      }
      setDisabled(false);
    }
  };
  const deleteTask = async (x: ITaskResource) => {
    const r = window.confirm('Are you sure to delete this task data?');
    if (r) {
      setDisabled(true);
      const r = await sendSingleRequest(
        {},
        'DELETE',
        `api/task/tasks/${x.id}`,
        true,
      );
      if (r.status) {
        await dispatch(getTaskData());
      } else {
        window.alert(r.data?.message ?? 'Error');
      }
      setDisabled(false);
    }
  };
  const completeTask = async (x: ITaskResource) => {
    setDisabled(true);
    const r = await sendSingleRequest(
      { is_completed: true },
      'PUT',
      `api/task/tasks/${x.id}`,
      true,
    );
    if (r.status) {
      await dispatch(getTaskData());
    }
    setDisabled(false);
  };

  const filteredTasks = useMemo(() => {
    let rs = [...tasksData];
    if (activeTab === 'all') {
      rs = rs.filter(x => !x.is_completed);
    } else if (activeTab === 'GENERAL') {
      rs = rs.filter(x => !x.is_completed && isNormalTask(x.type));
    } else if (activeTab === 'completed') {
      rs = rs.filter(x => x.is_completed);
    } else {
      rs = rs.filter(x => !x.is_completed && x.type === activeTab);
    }
    if (filterTitle) {
      rs = rs.filter(x =>
        x.title.toLowerCase().includes(filterTitle.toLowerCase()),
      );
    }
    return rs;
  }, [tasksData, filterTitle, activeTab]);

  useEffect(() => {
    dispatch(getTaskData());
  }, [dispatch]);

  return (
    <div className='h-calc-80'>
      {disabled && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', zIndex: 1 }}>
          <Spinner />
        </div>
      )}
      <div className='tasks-page'>
        <div className='mt-28 mb-28'>
          <Title size={5} color='black-3' align='default' fontWeight={700}>
            {translate(lang, 'Things to do')}
          </Title>
        </div>
        <div className='d-flex flex-wrap align-items-center justify-content-between mb-32'>
          <div className='d-flex align-items-center'>
            <Input
              className='mr-6'
              label=''
              type='text'
              placeholder='Filter tasks by title'
              value={filterTitle}
              onChange={e => setFilterTitle(e.target.value)}
            />
          </div>
          <div className='d-flex align-items-center'>
            {activeTab === 'completed' && (
              <>
                <Button
                  className='mr-6'
                  danger
                  size='large'
                  disabled={disabled}
                  onClick={completedClearClick}
                >
                  {translate(lang, 'Clear completed')}
                </Button>
              </>
            )}
            <Button
              size='large'
              type='primary'
              disabled={disabled}
              onClick={() => setCreateTask(true)}
            >
              {translate(lang, 'Add task')}
            </Button>
          </div>
        </div>
        <TabsComponent
          onChange={e => setActiveTab(e)}
          defaultActiveKey='all'
          items={tabs.map(x => ({
            id: x.id,
            title: x.title,
            content: (
              <TasksTable
                key={x.id}
                isCompleted={activeTab === 'completed'}
                onRowClick={t => setShowTask(t)}
                onDeleteClick={t => deleteTask(t)}
                onEditTask={t => setEditTask(t)}
                onCompleteTask={completeTask}
                tasksData={filteredTasks as any}
              />
            ),
          }))}
        />
      </div>
      {createTask && (
        <ModalTask
          title={'Create task'}
          visible={createTask}
          onCancel={() => setCreateTask(false)}
          onConfirm={() => setCreateTask(false)}
        />
      )}
      {!!showTask && (
        <ModalTask
          title={'View task'}
          visible={true}
          viewOnly={true}
          onCancel={() => setShowTask(undefined)}
          onConfirm={() => setShowTask(undefined)}
          data={showTask}
        />
      )}
      {!!editTask && (
        <ModalTask
          title='Edit task'
          visible={true}
          onCancel={() => setEditTask(undefined)}
          onConfirm={() => setEditTask(undefined)}
          data={editTask}
          updateId={editTask.id}
        />
      )}
    </div>
  );
};

export default TaskListPage;
