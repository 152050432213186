import { useDispatch, useSelector } from 'react-redux';
import {
  CheckboxButton,
  Dropdown,
  Input,
  Spinner,
  Title,
} from '../../components/shared';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { IName } from '../../entities/general.entities';
import { selectOysterFarms } from '../../store/farms/farms.selector';
import { selectSizedOysters } from '../../store/utils/utils.selector';
import { IOysterFarm } from '../../entities/farms.entities';
import { sendSingleRequest } from '../../apis';
import moment from 'moment';
import {
  defaultDateFormat,
  toMillisecond,
} from '../../util/toggleSecondMillisecond';
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { formatNumber } from '../../entities/util-functions';
import { NavLink, useHistory } from 'react-router-dom';
import { selectAccount, selectProfile } from '../../store/auth/auth.selector';
import { ISizedOyster } from '../../entities/utils.entities';
import PhotosViewButton from '../../components/lines/PhotosViewButton';
import { updateAccountSetting } from '../../store/auth/auth.actions';
import OysterLineProbModal from '../../components/farm-util/OysterLineProbModal';
import { calcSpecieLineDozens } from '../../lib/farm.helpers';
import './styles.scss';

interface ISeed {
  id: number;
  spat_storage: { id: number; source: string } | null;
  spat_amount: number;
  stage: number;
}

interface IStage {
  farm: IName;
  line: { id: number; line_name: string };
  seedings: ISeed[];
}

interface IEstimate {
  name: string;
  amount: number | undefined;
}

interface IRow {
  farm_id: number;
  farm_name: string;
  farm_number: string;
  line_id: number;
  line_name: string;
  farming_method: string | null;
  seeded_date: number;
  spat_name: string;
  rotation: number;
  age: number;
  estimates: IEstimate[];
}

const toRowData = (
  farm: IOysterFarm,
  oysterFields: ISizedOyster[],
  rotations: IStage[],
  onGrowWaste: any,
) => {
  let result: IRow[] = [];
  for (const line of farm.lines) {
    if (!line.growing_cycle) continue;
    const rotation = rotations
      .filter(x => x.line.id === line.id)
      .reduce(
        (p, c) =>
          Math.max(
            p,
            c.seedings.reduce((ps, cs) => Math.max(ps, cs.stage), 1),
          ),
        1,
      );
    const estimates =
      calcSpecieLineDozens(line, onGrowWaste, oysterFields) ?? [];
    result.push({
      farm_id: farm.id,
      farm_name: farm.name,
      farm_number: farm.farm_number,
      line_id: line.id,
      line_name: line.line_name,
      farming_method: line.farming_method,
      seeded_date: line.growing_cycle.main_seed.planned_date_seed,
      spat_name: line.growing_cycle.main_seed.spat_storage?.source ?? '-',
      rotation,
      age: moment().diff(
        toMillisecond(line.growing_cycle.main_seed.planned_date_seed),
        'days',
      ),
      estimates,
    });
  }
  return result;
};

const OysterEstimatesPage = () => {
  const history = useHistory();
  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const farmsData = useSelector(selectOysterFarms);
  const oysterFields = useSelector(selectSizedOysters);
  const onGrowWaste = useSelector(selectAccount)?.oyster_crops;
  const hiddenColumns =
    useSelector(selectAccount)?.oyster_estimate_hidden_columns;

  const [loading, setLoading] = useState(false);
  const [rotations, setRotations] = useState<IStage[]>([]);
  const [filterFarmID, setFilterFarmID] = useState<number | null>(null);
  const [filterName, setFilterName] = useState<string>('');
  const [visModify, setVisModify] = useState(false);
  const [editLineId, setEditLineId] = useState<number>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await sendSingleRequest(
        { with_spats: true },
        'GET',
        'api/overview/stages',
        true,
      );
      setLoading(false);
      if (!response.status) {
        window.alert(translate(lang, response.data?.message ?? 'Server error'));
        return;
      }
      setRotations(response.data);
    })();
  }, []);

  const data = useMemo(() => {
    let result = farmsData
      .filter(x => x.lines.filter(y => !!y.growing_cycle).length > 0)
      .filter(x => (filterFarmID ? x.id === filterFarmID : true))
      .map(farm => toRowData(farm, oysterFields, rotations, onGrowWaste))
      .flat();
    if (filterName) {
      result = result.filter(x =>
        x.line_name.toLowerCase().includes(filterName.toLowerCase()),
      );
    }
    return result;
  }, [
    farmsData,
    rotations,
    oysterFields,
    filterFarmID,
    filterName,
    onGrowWaste,
  ]);

  const cropColumns: any = [
    ...oysterFields.map(x => x.name),
    'Grown ons',
    'Waste',
  ]
    .filter(x => !hiddenColumns?.includes(x))
    .map(field => (
      <Column
        key={field}
        title={field}
        render={(r: IRow) => {
          const v = r.estimates.find(x => x.name === field);
          return v && v.amount !== undefined ? (
            <span>{`${formatNumber(v.amount)} dz`}</span>
          ) : (
            <span>{'-'}</span>
          );
        }}
        sorter={(a: IRow, b: IRow) =>
          (a.estimates.find(x => x.name === field)?.amount ?? 0) -
          (b.estimates.find(x => x.name === field)?.amount ?? 0)
        }
      />
    ));

  return (
    <div className='bg-secondary'>
      <div
        className='container oyster-estimate-page pb-32'
        style={{ maxWidth: 1600 }}
      >
        <div className='sub-header'>
          <NavLink className='header__link' to={'/oyster-summary/estimate'}>
            {translate(lang, 'Harvest Delivery Estimates')}
          </NavLink>
          <NavLink
            className='header__link ml-24 mr-24'
            to={'/oyster-summary/calender'}
          >
            {translate(lang, 'Calendar Estimates')}
          </NavLink>
          <NavLink className='header__link mr-24' to={'/oyster-summary/growth'}>
            {translate(lang, 'Growth Calculator')}
          </NavLink>
          <NavLink className='header__link' to={'/oyster-summary/lines'}>
            {translate(lang, 'Size Group')}
          </NavLink>
        </div>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Harvest Delivery Estimates')}
          </Title>
        </div>
        {loading && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        <div className='content mb-32'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <div style={{ width: 350, margin: `7px 12px` }}>
                <Dropdown
                  label={translate(lang, 'Select farm')}
                  options={[
                    { id: '0', value: '0', label: 'All' },
                    ...farmsData.map(x => ({
                      id: x.id.toString(),
                      label: x.name,
                      value: x.id.toString(),
                    })),
                  ]}
                  value={filterFarmID?.toString() ?? '0'}
                  onChange={v => setFilterFarmID(v === '0' ? null : Number(v))}
                />
              </div>
              <div className='ml-12'>
                <Input
                  type='text'
                  label={translate(lang, 'Search by Line name')}
                  value={filterName}
                  placeholder=''
                  onChange={e => setFilterName(e.target.value)}
                />
              </div>
            </div>
            {profile?.role === 'owner' && (
              <Button
                className='mr-12'
                type='primary'
                onClick={() => setVisModify(true)}
              >
                {translate(lang, 'Modify columns')}
              </Button>
            )}
          </div>
          <Table
            dataSource={data}
            rowKey={'line_id'}
            onRow={row => ({
              onClick: () =>
                history.push(`/farms/${row.farm_id}/${row.line_id}`),
              style: { cursor: 'pointer' },
            })}
          >
            {!hiddenColumns?.includes('Farm') && (
              <Column
                title={translate(lang, 'Farm')}
                key={'farm'}
                render={(x: IRow) => (
                  <span>
                    <div>{x.farm_name}</div>
                    <div>{x.farm_number}</div>
                  </span>
                )}
                sorter={(a: IRow, b: IRow) =>
                  a.farm_name.localeCompare(b.farm_name)
                }
              />
            )}
            {!hiddenColumns?.includes('Line name') && (
              <Column
                title={translate(lang, 'Line name')}
                key={'line_name'}
                render={(x: IRow) => <span>{x.line_name}</span>}
                sorter={(a: IRow, b: IRow) =>
                  a.line_name.localeCompare(b.line_name)
                }
              />
            )}
            {!hiddenColumns?.includes('Seeded date') && (
              <Column
                title={translate(lang, 'Seeded date')}
                key={'seeded_date'}
                render={(x: IRow) => (
                  <span>{defaultDateFormat(x.seeded_date)}</span>
                )}
                sorter={(a: IRow, b: IRow) => a.seeded_date - b.seeded_date}
              />
            )}
            {!hiddenColumns?.includes('Batch no') && (
              <Column
                title={translate(lang, 'Batch no')}
                key={'Batch no'}
                render={(x: IRow) => <span>{x.spat_name}</span>}
                sorter={(a: IRow, b: IRow) =>
                  a.spat_name.localeCompare(b.spat_name)
                }
              />
            )}
            {!hiddenColumns?.includes('Rotation') && (
              <Column
                title={translate(lang, 'Rotation')}
                key={'rotation'}
                render={(x: IRow) => <span>{x.rotation}</span>}
                sorter={(a: IRow, b: IRow) => a.rotation - b.rotation}
              />
            )}
            {!hiddenColumns?.includes('Oyster age') && (
              <Column
                title={translate(lang, 'Oyster age')}
                key={'oyster_age'}
                render={(x: IRow) => (
                  <span>{translate(lang, '%s days grown', x.age)}</span>
                )}
                sorter={(a: IRow, b: IRow) => a.age - b.age}
              />
            )}
            {!hiddenColumns?.includes('Estimated Crops') && (
              <ColumnGroup
                title={translate(lang, 'Estimated Crops')}
                key={'estimated_crops'}
              >
                {cropColumns}
              </ColumnGroup>
            )}
            {!hiddenColumns?.includes('Photos') && (
              <Column
                title={translate(lang, 'Photos')}
                key={'photos'}
                render={(x: IRow) => (
                  <div onClick={e => e.stopPropagation()}>
                    <PhotosViewButton
                      lineID={x.line_id}
                      label={translate(lang, 'View')}
                    />
                  </div>
                )}
              />
            )}
            {!hiddenColumns?.includes('Modify probability') && (
              <Column
                title={translate(lang, 'Modify probability')}
                key={'modify_probability'}
                render={(x: IRow) => (
                  <div onClick={e => e.stopPropagation()}>
                    <Button
                      type='primary'
                      onClick={() => setEditLineId(x.line_id)}
                    >
                      {translate(lang, 'Modify')}
                    </Button>
                  </div>
                )}
              />
            )}
          </Table>
        </div>
      </div>
      {visModify && (
        <ColumnModifyModal
          visible={visModify}
          onClose={() => setVisModify(false)}
        />
      )}
      {!!editLineId && (
        <OysterLineProbModal
          visible={true}
          lineID={editLineId}
          onClose={() => setEditLineId(undefined)}
        />
      )}
    </div>
  );
};

interface ColModalProps {
  visible: boolean;
  onClose: () => void;
}

const ColumnModifyModal = ({ visible, onClose }: ColModalProps) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const hiddenColumns =
    useSelector(selectAccount)?.oyster_estimate_hidden_columns;
  const oysterFields = useSelector(selectSizedOysters);

  const tableColumns = [
    'Farm',
    'Line name',
    'Seeded date',
    'Batch no',
    'Rotation',
    'Oyster age',
    'Estimated Crops',
    'Grown ons',
    'Waste',
    'Photos',
    ...oysterFields.map(x => x.name),
    'Modify probability',
  ];

  const [checked, setChecked] = useState(hiddenColumns ?? []);

  const toggleCheck = (name: string, c: boolean) => {
    if (c) {
      setChecked(checked.filter(x => x !== name));
    } else if (!checked.includes(name)) {
      setChecked([...checked, name]);
    }
  };
  const confirmClick = async () => {
    const oyster_estimate_hidden_columns = tableColumns.filter(x =>
      checked.includes(x),
    );
    const r = await dispatch(
      updateAccountSetting({ oyster_estimate_hidden_columns }),
    );
    if (r !== true) {
      window.alert(r ?? 'Server error');
    } else {
      onClose();
    }
  };

  return (
    <Modal
      title={translate(lang, 'Modify columns')}
      visible={visible}
      onCancel={onClose}
      onOk={confirmClick}
      closable
    >
      <div className='wrap pl-12 pr-12'>
        {tableColumns.map((x, i) => (
          <div key={i} className='mb-17'>
            <CheckboxButton
              label={translate(lang, x)}
              checked={!checked.includes(x)}
              onChange={e => toggleCheck(x, e.target.checked)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default OysterEstimatesPage;
