import { Button, Modal, message } from 'antd';
import { ILineResource } from '../../entities/farms.entities';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
  CheckboxButton,
  CloseIcon,
  Subtitle,
  Button as BigBtn,
  Dropdown,
  Datepicker,
} from '../shared';
import { CheckCircleFilled, MinusCircleOutlined } from '@ant-design/icons';
import { ITag } from '../../entities/automation.entities';
import { sendSingleRequest } from '../../apis';
import { loadTags } from '../../store/automation/automation.actions';
import { periodToStr } from '../../lib/common.helpers';
import moment from 'moment';
import { toMillisecond } from '../../util/toggleSecondMillisecond';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { SelectTagsByFarmID } from '../../store/extra/extra.selector';
import { loadExtraData } from '../../store/extra/extra.actions';

interface IPastForm {
  tag_id: number | null;
  start_time: number;
  end_time?: number | null;
}

interface Props {
  lineData: ILineResource;
  loadLine: (i: boolean) => void;
}

const LineTagButton = ({ lineData, loadLine }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const lineTags = SelectTagsByFarmID(lineData.farm_id);
  const selectedTags = lineTags?.filter(x =>
    x.tag_lines.some(y => y.line_id === lineData.id),
  );

  const [disabled, setDisabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isPast, setIsPast] = useState(false);

  const [pastForm, setPastForm] = useState<IPastForm>({
    tag_id: null,
    start_time: Date.now(),
  });

  const toggleClick = async (tag: ITag) => {
    let lines = tag.tag_lines.map(x => x.line_id);
    if (lines.includes(lineData.id)) {
      lines = lines.filter(x => x !== lineData.id);
    } else {
      lines.push(lineData.id);
    }
    setDisabled(true);
    const res = await sendSingleRequest(
      { name: tag.name, lines },
      'PUT',
      `api/tag/${tag.id}`,
      true,
    );
    if (res.status) {
      await dispatch(loadTags());
      await loadLine(true);
      if (tag.account_id) {
        await dispatch(loadExtraData());
      }
      dispatch(loadFarmsData());
    } else {
      message.error(translate(lang, res.data?.message ?? 'Unknown error'));
    }
    setDisabled(false);
  };
  const toggleUrgent = async (is_urgent: boolean) => {
    if (lineData.is_urgent === is_urgent) return;
    setDisabled(true);
    const response = await sendSingleRequest(
      { line_id: lineData.id, is_urgent },
      'PUT',
      `api/farm/line/lines/${lineData.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      await loadLine(true);
      dispatch(loadExtraData());
      setDisabled(false);
    } else {
      setDisabled(false);
      message.error(translate(lang, response.data?.message ?? 'Unknown error'));
    }
  };
  const pastConfirm = async () => {
    if (!pastForm.tag_id) {
      message.error(translate(lang, 'Please select a tag'));
      return;
    }
    const data = {
      line_id: lineData.id,
      tag_id: pastForm.tag_id,
      start_time: moment(pastForm.start_time).format('YYYY-MM-DD'),
      end_time: pastForm.end_time
        ? moment(pastForm.end_time).format('YYYY-MM-DD')
        : null,
    };
    setDisabled(true);
    const res = await sendSingleRequest(data, 'POST', 'api/tag_line', true);
    setDisabled(false);
    if (res.status) {
      await dispatch(loadTags());
      await loadLine(true);
      dispatch(loadExtraData());
      dispatch(loadFarmsData());
      setIsPast(false);
    } else {
      message.error(translate(lang, res.data?.message ?? 'Unknown error'));
    }
  };

  return !!lineTags && lineTags.length > 0 ? (
    <>
      {!selectedTags || selectedTags.length <= 0 ? (
        <button className='line-tag-btn' onClick={() => setVisible(true)}>
          {translate(lang, 'No tagged')}
        </button>
      ) : (
        selectedTags.map(x => (
          <button
            key={x.id}
            className='line-tag-btn'
            onClick={() => setVisible(true)}
          >
            <div key={x.id} style={{ margin: '2px 10px' }}>
              <div style={{ fontSize: '15px', fontWeight: '600' }}>
                {x.name}
              </div>
              <div style={{ fontSize: '12px', fontWeight: '500' }}>
                {translate(
                  lang,
                  'tagged %s days ago',
                  moment().diff(
                    toMillisecond(
                      x.tag_lines.find(y => y.line_id === lineData.id)
                        ?.created_at,
                    ),
                    'days',
                  ),
                )}
              </div>
            </div>
          </button>
        ))
      )}
      {visible && (
        <Modal
          visible={true}
          onCancel={() => setVisible(false)}
          footer={null}
          closable
          closeIcon={<CloseIcon />}
          width={550}
        >
          <div className='wrap text-center'>
            <div className='mb-32 mt-32 d-flex justify-content-center'>
              <CheckboxButton
                label={translate(lang, 'Requires urgent attention')}
                disabled={disabled}
                checked={lineData.is_urgent}
                onChange={e => toggleUrgent(e.target.checked)}
              />
            </div>
            <div
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: '#78716c',
              }}
            />
            <div className='mb-32 mt-32'>
              {lineTags.map(x => (
                <div className='mt-7 mb-7' key={x.id}>
                  <Button
                    style={{ minWidth: 380 }}
                    icon={
                      x.tag_lines.some(y => y.line_id === lineData.id) ? (
                        <CheckCircleFilled />
                      ) : (
                        <MinusCircleOutlined />
                      )
                    }
                    type={
                      x.tag_lines.some(y => y.line_id === lineData.id)
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => toggleClick(x)}
                    danger={x.is_urgent}
                    disabled={disabled}
                  >
                    {x.name}
                    {x.is_urgent
                      ? ` (${translate(
                          lang,
                          'Required urgent attention after %s',
                          periodToStr(x.period ?? 0),
                        )})`
                      : ''}
                  </Button>
                </div>
              ))}
            </div>
            <div className='mb-32 pt-32'>
              <BigBtn
                width={'small'}
                size={2}
                type='fill'
                color='green'
                onClick={() => {
                  setVisible(false);
                  setIsPast(true);
                }}
              >
                {translate(lang, 'Add tag in the past')}
              </BigBtn>
            </div>
          </div>
        </Modal>
      )}
      {!!isPast && (
        <Modal
          visible={isPast}
          onCancel={() => setIsPast(false)}
          footer={null}
          closable
          closeIcon={<CloseIcon />}
          width={650}
        >
          <div className='wrap'>
            <div className='d-flex align-items-center mb-32'>
              <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                {translate(lang, 'Add tag in the past')}
              </Subtitle>
            </div>
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, 'Select Tag')}
                options={lineTags.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                value={pastForm.tag_id?.toString() ?? undefined}
                onChange={v => setPastForm({ ...pastForm, tag_id: Number(v) })}
              />
            </div>
            <div className='mb-17'>
              <Datepicker
                label={translate(lang, 'Start time')}
                defaultValue={pastForm.start_time}
                onChange={e =>
                  e &&
                  setPastForm({ ...pastForm, start_time: e.toDate().getTime() })
                }
              />
            </div>
            <div className='mb-17'>
              <CheckboxButton
                label={translate(lang, '_tag_still_active')}
                checked={!pastForm.end_time}
                onChange={e => {
                  e.target.checked
                    ? setPastForm({
                        ...pastForm,
                        end_time: null,
                      })
                    : setPastForm({
                        ...pastForm,
                        end_time: Date.now(),
                      });
                }}
              />
            </div>
            {!!pastForm.end_time && (
              <div className='mb-17'>
                <Datepicker
                  label={translate(lang, 'End time')}
                  defaultValue={pastForm.end_time}
                  onChange={e =>
                    e &&
                    setPastForm({ ...pastForm, end_time: e.toDate().getTime() })
                  }
                />
              </div>
            )}
          </div>
          <div className='modal-button d-flex justify-content-end mt-24'>
            <BigBtn
              width={'small'}
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16'
              onClick={pastConfirm}
              disabled={disabled}
            >
              {translate(lang, 'Confirm')}
            </BigBtn>
          </div>
        </Modal>
      )}
    </>
  ) : (
    <></>
  );
};

export default LineTagButton;
