import { useState } from 'react';
import { Modal } from 'antd';
import { Button, CloseIcon, Subtitle } from '../shared';
import { useHistory } from 'react-router-dom';
import GrowerHarvestModal from './GrowerHarvestModal';
import GrowerMaintenanceModal from './GrowerMaintenanceModal';
import GrowerCycleModal from './GrowerCycleModal';
import GrowerSeedModal from './GrowerSeedModal';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  visible: boolean;
  onClose: (d: any) => void;
  onConfirm: (d: any) => void;
  className?: string;
}

const GrowerImportSelectModal = ({
  visible,
  onClose,
  onConfirm,
  className,
}: Props) => {
  const history = useHistory();
  const lang = useSelector(selectLang);

  const [show, setShow] = useState(visible);
  const [showSeed, setShowSeed] = useState(false);
  const [showHarvest, setShowHarvest] = useState(false);
  const [showMnt, setShowMnt] = useState(false);
  const [showCycle, setShowCycle] = useState(false);

  const clickSeed = () => {
    setShow(false);
    setShowMnt(false);
    setShowCycle(false);
    setShowSeed(true);
  };
  const clickHarvest = () => {
    setShow(false);
    setShowSeed(false);
    setShowMnt(false);
    setShowCycle(false);
    setShowHarvest(true);
  };
  const clickAssessment = () => history.push('/import/assessments');
  const clickMntClick = () => {
    setShow(false);
    setShowSeed(false);
    setShowCycle(false);
    setShowHarvest(false);
    setShowMnt(true);
  };
  const cycleClick = () => {
    setShow(false);
    setShowSeed(false);
    setShowMnt(false);
    setShowHarvest(false);
    setShowCycle(true);
  };
  const handleClose = () => {
    setShow(true);
    setShowSeed(false);
    setShowHarvest(false);
    setShowMnt(false);
    setShowCycle(false);
  };

  return (
    <>
      <Modal
        visible={show}
        onCancel={onClose}
        className={className}
        closable
        closeIcon={<CloseIcon />}
        width={400}
        footer={null}
      >
        <div className='wrap'>
          <div className='d-flex align-items-center mb-17'>
            <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
              {translate(lang, 'What would you like to import?')}
            </Subtitle>
          </div>
          <div className='mt-17 mb-17'>
            <div className='mt-17'>
              <Button
                width='wide'
                size={2}
                type='fill'
                color='blue'
                onClick={clickSeed}
              >
                {translate(lang, 'Seed')}
              </Button>
            </div>
            <div className='mt-17'>
              <Button
                width='wide'
                size={2}
                type='fill'
                color='blue'
                onClick={clickAssessment}
              >
                {translate(lang, 'Assessment')}
              </Button>
            </div>
            <div className='mt-17'>
              <Button
                width='wide'
                size={2}
                type='fill'
                color='blue'
                onClick={clickHarvest}
              >
                {translate(lang, 'Harvest')}
              </Button>
            </div>
            <div className='mt-17'>
              <Button
                width='wide'
                size={2}
                type='fill'
                color='blue'
                onClick={clickMntClick}
              >
                {translate(lang, 'Maintenance')}
              </Button>
            </div>
            <div className='mt-17'>
              <Button
                width='wide'
                size={2}
                type='fill'
                color='red'
                onClick={cycleClick}
              >
                {translate(lang, 'Insert past cycle')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {showSeed && (
        <GrowerSeedModal
          type='MUSSEL'
          visible={showSeed}
          onClose={handleClose}
          onConfirm={onConfirm}
        />
      )}
      {showHarvest && (
        <GrowerHarvestModal
          type='MUSSEL'
          visible={showHarvest}
          onClose={handleClose}
          onConfirm={onConfirm}
        />
      )}
      {showMnt && (
        <GrowerMaintenanceModal
          visible={true}
          onClose={handleClose}
          onConfirm={onConfirm}
        />
      )}
      {showCycle && (
        <GrowerCycleModal
          visible={true}
          onClose={handleClose}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default GrowerImportSelectModal;
