import { Modal } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Paragrapgh,
  Subtitle,
} from '../../shared';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import ModalFeedbackView from '../../shared/feedback/ModalFeedbackView';
import { sendSingleRequest } from '../../../apis';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { showFeedback } from '../../../store/ui/ui.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { IOysterLineResource } from '../../../entities/farms.entities';
import { translate } from '../../../lib/lang.helper';
import { selectLang } from '../../../store/ui/ui.selector';
import {
  SelectIsMyFarm,
  SelectOysterFarmsByFarmID,
} from '../../../store/extra/extra.selector';
import OysterLineProbTable, {
  OysterProbRef,
} from '../../farm-util/OysterLineProbTable';
import BatchAssessView from './BatchAssessView';
import DirectionForm from '../../farm-util/DirectionForm';
import { dozensToPieces, piecesToDozens } from '../../../lib/common.helpers';

interface IFormData {
  estimated_amount?: number | null;
  estimated_dozens?: number | null;
  size_min: number | null;
  size_max: number | null;
  size_avg: number | null;
  assessment_date: number;
  planned_date_harvest: number;
  ready_to_harvest: boolean;
  origin_spat_id?: number | null;
  comment: string | null;
  direction: string | null;
  time_tracks?: ITimeRange[];
  images: any[];
}

const defaultForm: IFormData = {
  size_min: null,
  size_max: null,
  size_avg: null,
  images: [],
  planned_date_harvest: moment().toDate().getTime(),
  assessment_date: moment().toDate().getTime(),
  comment: null,
  ready_to_harvest: false,
  direction: null,
  time_tracks: [],
};

const getEstimated = (line: IOysterLineResource) => {
  if (line.growing_cycle?.last_assessment?.estimated_amount) {
    return {
      estimated_amount: line.growing_cycle.last_assessment.estimated_amount,
      estimated_dozens: piecesToDozens(
        line.growing_cycle.last_assessment.estimated_amount,
      ),
      planned_date_harvest: toMillisecond(
        line.growing_cycle.last_assessment.planned_date_harvest,
      ),
    };
  } else if (line.growing_cycle) {
    return {
      estimated_amount: line.growing_cycle.current_amount_pcs,
      estimated_dozens: line.growing_cycle.current_amount_dz,
      planned_date_harvest: toMillisecond(
        line.growing_cycle.main_seed.planned_date_harvest,
      ),
    };
  } else {
    return {
      estimated_amount: undefined,
      estimated_dozens: undefined,
      planned_date_harvest: defaultForm.planned_date_harvest,
    };
  }
};

interface Props {
  visible: boolean;
  title?: string;
  lineData: IOysterLineResource;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  data?: any;
  updateID?: number;
  onlyView?: boolean;
  overConfirm?: (data: any) => void;
  lineChangeable?: boolean;
}

const OysterAssessModal = ({
  lineData,
  visible,
  title,
  onCancel,
  onConfirm,
  data,
  updateID,
  onlyView,
  overConfirm,
  lineChangeable,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const farmsData = SelectOysterFarmsByFarmID(lineData.farm_id);
  const isMyFarm = SelectIsMyFarm(lineData.farm_id);

  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    planned_date_harvest: getEstimated(lineData).planned_date_harvest,
    estimated_amount: getEstimated(lineData).estimated_amount,
    estimated_dozens: getEstimated(lineData).estimated_dozens,
  });
  const [warning, setWaring] = useState(false);
  const [errors, setErrors] = useState<{
    min: string | null;
    max: string | null;
    avg: string | null;
  }>({
    min: null,
    max: null,
    avg: null,
  });
  const [focFarmId, setFocFarmId] = useState(lineData.farm_id);
  const [curLine, setCurLine] = useState(lineData);
  const [trackOrigin, setTrackOrigin] = useState(!!data?.origin_spat_id);
  const [disabled, setDisabled] = useState(false);

  const tableRef = useRef<OysterProbRef>(null);

  const handleLineChange = (v: string) => {
    const farm = farmsData.find(x => x.id === focFarmId);
    if (!farm) return;
    const line = farm.lines.find(x => x.id === Number(v));
    if (!line?.growing_cycle) return;
    setCurLine(line);
    setFormData(prv => ({
      ...prv,
      planned_date_harvest: toMillisecond(
        line.growing_cycle?.main_seed.planned_date_harvest ?? 0,
      ),
    }));
  };
  const updateForm = (key: keyof IFormData, val: any) => {
    if (
      [
        'size_min',
        'size_max',
        'size_avg',
        'estimated_amount',
        'estimated_dozens',
      ].includes(key)
    ) {
      val = val.length <= 0 ? null : Number(val);
    }
    let tmp = { ...formData, [key]: val };
    if (key === 'size_min') {
      setErrors(p => ({ ...p, min: null, avg: null }));
      tmp.size_avg = Math.round(
        (Number(tmp.size_max) + Number(tmp.size_min)) / 2,
      );
    } else if (key === 'size_max') {
      setErrors(p => ({ ...p, max: null, avg: null }));
      tmp.size_avg = Math.round(
        (Number(tmp.size_max) + Number(tmp.size_min)) / 2,
      );
    } else if (key === 'estimated_dozens') {
      tmp.estimated_amount = dozensToPieces(val);
    }
    setFormData(tmp);
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );

  const validForm = () => {
    let res: any = true;
    let tmpErr = { ...errors };
    if (!formData.size_min) {
      tmpErr.min = translate(lang, 'Required field');
      res = false;
    }
    if (!formData.size_max) {
      tmpErr.max = translate(lang, 'Required field');
      res = false;
    }
    if (!formData.size_avg) {
      tmpErr.avg = translate(lang, 'Required field');
      res = false;
    }
    if (Number(formData.size_min) > Number(formData.size_max)) {
      tmpErr.avg = translate(
        lang,
        'Maximum value should be equal or greater than minimum value',
      );
      res = false;
    }
    if (
      formData.time_tracks &&
      toSecTimeTracks(formData.time_tracks) === false
    ) {
      showError('_invalid_time_range');
      res = false;
    }
    setErrors(tmpErr);
    if (!res) return null;

    res = {
      ...formData,
      assessment_date: toSecond(formData.assessment_date),
      planned_date_harvest: toSecond(formData.planned_date_harvest),
      seeding_id: lineData.growing_cycle?.main_seed.id,
      line_id: lineData.id,
    };
    res.btm_size_min = res.btm_size_max = res.btm_size_avg = undefined;

    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        showError('Invalid time tracks information');
        return null;
      }
      res.time_tracks = tt;
    }
    return res;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    const form = validForm();
    if (!form) return;

    if (tableRef.current) {
      const res = tableRef.current.checkIsValid();
      if (res !== true) {
        showError(res);
        return;
      }
    }

    if (
      !warning &&
      lineData.growing_cycle?.last_assessment &&
      (lineData.growing_cycle.last_assessment.shell_size.min >= form.size_min ||
        lineData.growing_cycle.last_assessment.shell_size.max >= form.size_max)
    ) {
      setWaring(true);
      return;
    }
    setDisabled(true);
    if (overConfirm) {
      overConfirm(form);
      return;
    }
    const res = !updateID
      ? await sendSingleRequest(
          form,
          'POST',
          'api/farm/line/assessment/assessments',
          true,
        )
      : await sendSingleRequest(
          form,
          'PUT',
          `api/farm/line/assessment/assessments/${updateID}`,
          true,
        );
    if (res.status) {
      dispatch(loadFarmsData());
      if (tableRef.current) {
        await tableRef.current.runConfirm();
      }
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
      onConfirm(res.data);
    } else {
      showError(res.data?.message ?? 'Request failed');
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (data) {
      let form = { ...data };
      if (form.assessment_date)
        form.assessment_date = toMillisecond(form.assessment_date);
      if (form.planned_date_harvest)
        form.planned_date_harvest = toMillisecond(form.planned_date_harvest);
      form.ready_to_harvest = !!data.ready_harvest || !!data.ready_to_harvest;
      if (form.time_tracks && form.time_tracks.length > 0) {
        form.time_tracks = data.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      if (data.images && data.images.length > 0) {
        let tmp: any = [];
        for (let x of data.images) {
          if (typeof x === 'object' && x !== null) {
            tmp.push(x);
          } else {
            tmp.push(parseImageUrlToFileItem(x));
          }
        }
        if (tmp && tmp.length > 0) {
          form.images = tmp;
        }
      }
      if (data.estimated_amount) {
        form.estimated_dozens = piecesToDozens(data.estimated_amount);
      }
      setFormData(prv => ({ ...prv, ...form }));
    }
  }, [data]);

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = farmsData
    .find(x => x.id === focFarmId)
    ?.lines.filter(x => !!x.growing_cycle)
    .map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.line_name,
    }));

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Oyster assessment')}
          </Subtitle>
        </div>
        <div className='assessment-modal'>
          {lineChangeable && (
            <div className='mb-17 d-flex'>
              <div className='w-100 mr-7'>
                <Dropdown
                  label={translate(lang, 'Select farm')}
                  options={farmOptions}
                  value={focFarmId.toString()}
                  onChange={v => setFocFarmId(Number(v))}
                  disabled={onlyView}
                />
              </div>
              <div className='w-100 ml-7'>
                <Dropdown
                  label={translate(lang, 'Select line')}
                  options={lineOptions ?? []}
                  value={
                    !!lineOptions &&
                    lineOptions.some(x => Number(x.id) === curLine.id)
                      ? curLine.id.toString()
                      : curLine.line_name
                  }
                  onChange={handleLineChange}
                  disabled={onlyView}
                />
              </div>
            </div>
          )}
          {warning ? (
            <div className='mt-32 mb-64 pb-32 pt-17 pl-17 pr-17'>
              <Paragrapgh
                size={1}
                fontWeight={400}
                color='black-2'
                align='default'
              >
                {translate(lang, '_assessment_warning')}
              </Paragrapgh>
            </div>
          ) : (
            <div className='assessment-modal'>
              <div className='pb-17'>
                <Input
                  label={translate(lang, 'Estimated dozens')}
                  type='number'
                  value={formData.estimated_dozens?.toString() ?? ''}
                  onChange={e => updateForm('estimated_dozens', e.target.value)}
                  unit='dozens'
                />
              </div>
              {isMyFarm && (
                <div className='pb-17'>
                  <OysterLineProbTable
                    ref={tableRef}
                    lineID={curLine.id}
                    totalAmount={formData.estimated_dozens ?? 0}
                  />
                </div>
              )}
              <div className='assessment-modal__condition d-flex justify-content-between'>
                <div className={`mx-180 ${errors.min ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size min')} (mm)`}
                    value={formData.size_min?.toString() ?? ''}
                    onChange={e => updateForm('size_min', e.target.value)}
                    required={true}
                  />
                  {errors.min && (
                    <div className='invalid-feedback'>{errors.min}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors.max ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size max')} (mm)`}
                    value={formData.size_max?.toString() ?? ''}
                    onChange={e => updateForm('size_max', e.target.value)}
                    required={true}
                  />
                  {errors.max && (
                    <div className='invalid-feedback'>{errors.max}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors.avg ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size avg')} (mm)`}
                    value={formData.size_avg?.toString() ?? ''}
                    onChange={e => updateForm('size_avg', e.target.value)}
                    required={true}
                  />
                  {errors.avg && (
                    <div className='invalid-feedback'>{errors.avg}</div>
                  )}
                </div>
              </div>
              <Datepicker
                className='mb-17'
                defaultValue={formData.assessment_date}
                label={translate(lang, 'Assessment date')}
                onChange={e =>
                  updateForm(
                    'assessment_date',
                    e!.toDate().getTime() <
                      Number(
                        lineData.growing_cycle?.main_seed.planned_date_seed,
                      )
                      ? moment().toDate().getTime()
                      : e!.toDate().getTime(),
                  )
                }
                required={true}
              />
              <Datepicker
                className='mb-17'
                defaultValue={formData.planned_date_harvest}
                label={translate(lang, 'Planned harvest date')}
                onChange={e =>
                  updateForm(
                    'planned_date_harvest',
                    e!.toDate().getTime() <
                      Number(
                        lineData.growing_cycle?.main_seed.planned_date_seed,
                      )
                      ? moment().toDate().getTime()
                      : e!.toDate().getTime(),
                  )
                }
                required={true}
              />
              {curLine.growing_cycle && (
                <BatchAssessView
                  status={trackOrigin}
                  onChange={setTrackOrigin}
                  lineID={curLine.id}
                  farmID={curLine.farm_id}
                  trackSeedingID={curLine.growing_cycle.main_seed.id}
                  batchID={formData.origin_spat_id ?? undefined}
                  onBatchSelect={v => updateForm('origin_spat_id', v)}
                />
              )}
              <CheckboxButton
                className='mb-17'
                label={translate(lang, 'Ready to harvest')}
                checked={formData.ready_to_harvest}
                onChange={e => updateForm('ready_to_harvest', e.target.checked)}
              />
              <DirectionForm
                value={formData.direction}
                onChange={v => updateForm('direction', v)}
              />
              {formData.time_tracks && (
                <TimeTrackInput
                  data={formData.time_tracks}
                  onChange={t => updateForm('time_tracks', t)}
                />
              )}
              <Input
                label={translate(lang, 'Comment')}
                placeholder={`${translate(lang, 'Type your comment')}...`}
                className='mb-17'
                type='textarea'
                value={formData.comment ?? ''}
                dataType='comment'
                onChange={e => updateForm('comment', e.target.value)}
                isOptional={true}
              />
              {!!formData.images && (
                <PicturesWall
                  label={translate(lang, 'Images')}
                  fileList={formData.images}
                  handleChangeImages={f => updateForm('images', f)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        {!warning ? (
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={handleConfirmClick}
            disabled={disabled || onlyView}
          >
            {translate(lang, 'Confirm')}
          </Button>
        ) : (
          <>
            <Button
              className='rsp-btn'
              width='small'
              size={2}
              type='fill'
              color='blue'
              onClick={() => setWaring(false)}
              disabled={disabled}
            >
              {translate(lang, 'Let me correct')}
            </Button>
            <Button
              width='small'
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16'
              onClick={handleConfirmClick}
              disabled={disabled || onlyView}
            >
              {translate(lang, "Yes, it's valid")}
            </Button>
          </>
        )}
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default OysterAssessModal;
