import { useMemo } from 'react';
import { Subtitle } from '../../components/shared';
import { IFarmProperty, ILineProperty } from '../../entities/farms.entities';
import { formatNumber } from '../../entities/util-functions';
import {
  ISeaweedHarvest,
  ISeaweedSeeding,
} from '../../entities/growing.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { labelNumber } from '../../lib/common.helpers';

interface Props {
  farm: IFarmProperty;
  line: ILineProperty;
  seedings: ISeaweedSeeding[];
  harvests: ISeaweedHarvest[];
}

const SeaweedHarvestHeader = ({ farm, line, seedings, harvests }: Props) => {
  const lang = useSelector(selectLang);

  const { seededArea, amountSum, firstRatio, lastRatio } = useMemo(() => {
    const seededArea = seedings.reduce((p, c) => p + c.tank_area, 0);
    const amountSum = harvests.reduce((p, c) => p + c.amount, 0);
    const ll =
      seededArea -
      harvests.reduce((p, c) => p + (c.tank_area ?? 0), 0) +
      (harvests[harvests.length - 1]?.tank_area ?? 0);
    const fr = !seedings[0]?.tank_area ? 0 : ll / seedings[0]?.tank_area;
    const lr = !harvests[harvests.length - 2]?.tank_area
      ? 0
      : ll / (harvests[harvests.length - 2].tank_area ?? 1);

    return {
      seededArea,
      amountSum,
      firstRatio: !fr ? '- : -' : `1 : ${formatNumber(fr)}`,
      lastRatio: !lr ? '- : -' : `1 : ${formatNumber(lr)}`,
    };
  }, [seedings, harvests]);

  return (
    <div className='d-flex justify-content-between white-card pt-28 pr-16 pb-28 pl-24 mb-16 overflow-auto'>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.name}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Line name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {line.line_name}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm number')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.farm_number}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Total tank area')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${seededArea} m²`}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm area')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {labelNumber(
            farm.area.length <= 0 ? undefined : Number(farm.area),
            'ha',
            '-',
          )}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Line tank area')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {labelNumber(line.tank_area ?? undefined, 'm²', '-')}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Total harvest amount')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {amountSum.toFixed(2)} kg
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Seaweeds per m² of tank')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {(amountSum / seededArea).toFixed(2)} kg/m
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'First seed ratio')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {firstRatio}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Last seed ratio')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {lastRatio}
        </Subtitle>
      </div>
    </div>
  );
};

export default SeaweedHarvestHeader;
