import moment from 'moment';
import { IMusselLineResource } from '../../entities/farms.entities';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectMusselFarms } from '../../store/farms/farms.selector';
import { useEffect, useRef, useState } from 'react';
import { showFeedback } from '../../store/ui/ui.actions';
import {
  Button,
  CheckboxButton,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
} from '../../components/shared';
import DateTimePicker from '../../components/shared/datepicker/DateTimePicker';
import SignatureCanvas from 'react-signature-canvas';
import { toSecTimeTracks } from '../../components/shared/input/TimeTrackInput';
import { sendSingleRequest } from '../../apis';
import { loadFarmsData } from '../../store/farms/farms.actions';
import PicturesWall from '../../components/shared/pictures-wall/PicturesWall';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const getJSTime = (t: number | undefined) =>
  t ? t * 1000 : moment().toDate().getTime();

interface IFloat {
  inventory_id?: number;
  quantity?: number;
}

interface IForm {
  company?: string;
  harvest_number?: string;
  vessel?: string;
  bags_quantity?: number;
  shell_length?: number;
  shell_length_max?: number;
  amount?: number;
  shell_condition?: string;
  tag_color?: string;
  port_of_unload?: string;
  crop_owner?: string;
  delivered_to?: string;
  packhouse?: string;
  is_final: boolean;
  backbone_ok: boolean;
  backbone_replace: boolean;
  flotation_on_farm: boolean;
  lights_ids_in_place: boolean;
  product_left_on_line: boolean;
  blues?: string;
  marine_waste?: string;
  mussel_type?: string;
  mussels?: string;
  meat_yield?: string;
  growing_area?: string;
  rope_bags_quantity?: string;
  complete_date: number;
  season_name?: string;
  start_time: number;
  finish_time: number;
  comment?: string;
  images?: any[];
  inventories?: IFloat[];
}

const extractForm = (data: any, lineData: IMusselLineResource): IForm => ({
  company: data.company,
  season_name:
    data.season_name ?? lineData.growing_cycle?.main_seed.season_name,
  harvest_number: data.harvest_number,
  vessel: data.vessel,
  bags_quantity: data.bags_quantity,
  shell_length: data.shell_length,
  shell_length_max: data.shell_length_max,
  amount: data.amount,
  shell_condition: data.shell_condition,
  tag_color: data.tag_color,
  port_of_unload: data.port_of_unload,
  crop_owner: data.crop_owner,
  delivered_to: data.delivered_to,
  packhouse: data.packhouse,
  is_final: data.is_final ?? true,
  backbone_ok: data.backbone_ok ?? false,
  backbone_replace: data.backbone_replace ?? false,
  flotation_on_farm: data.flotation_on_farm ?? false,
  lights_ids_in_place: data.lights_ids_in_place ?? false,
  product_left_on_line: data.product_left_on_line ?? false,
  blues: data.blues,
  marine_waste: data.marine_waste,
  mussel_type: data.mussel_type,
  mussels: data.mussels,
  meat_yield: data.meat_yield,
  growing_area: data.growing_area,
  rope_bags_quantity: isNaN(Number(data.rope_bags_quantity))
    ? undefined
    : data.rope_bags_quantity,
  complete_date: getJSTime(data.complete_date),
  start_time: getJSTime(data.start_time),
  finish_time: getJSTime(data.finish_time),
  comment: data.comment,
  images: data.images,
  inventories: data.inventories ?? [],
});

interface Props {
  formID: number;
  data: any;
  lineData: IMusselLineResource;
}

const PaperHarvestForm = ({ formID, data, lineData }: Props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const lang = useSelector(selectLang);

  const signatureCanvas = useRef<SignatureCanvas | null>();
  const farmsData = useSelector(selectMusselFarms);

  const [curFarm, setCurFarm] = useState(
    farmsData.find(x => x.id === lineData.farm_id),
  );
  const [curLine, setCurLine] = useState<IMusselLineResource | undefined>(
    lineData,
  );
  const [formData, setFormData] = useState<IForm>(extractForm(data, lineData));
  const [sigCanLoaded, setSigCanLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const selectFarm = (v: string) => {
    const farm = farmsData.find(x => x.id === Number(v));
    setCurFarm(farm);
    setCurLine(undefined);
  };
  const selectLine = (v: string) => {
    const line = curFarm?.lines.find(x => x.id === Number(v));
    setCurLine(line);
  };
  const updateForm = (key: keyof IForm, value: any) => {
    if (
      ['amount', 'shell_length', 'shell_length_max', 'bags_quantity'].includes(
        key,
      )
    ) {
      value = value.length <= 0 ? undefined : Number(value);
    }
    setFormData({ ...formData, [key]: value });
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        isMessage: true,
        message: translate(lang, message),
      }),
    );

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = curFarm?.lines.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: x.line_name,
  }));
  const ivtSum = curLine?.growing_cycle?.inventories_sum ?? [];

  const changeInventory = (id: number, value: string) => {
    const newInventories = [...(formData.inventories ?? [])];
    const idx = newInventories.findIndex(x => x.inventory_id === id);
    if (idx >= 0) {
      newInventories[idx].quantity = Number(value);
    } else {
      newInventories.push({ inventory_id: id, quantity: Number(value) });
    }
    setFormData({ ...formData, inventories: newInventories });
  };
  const confirmClick = async () => {
    if (!curFarm || !curLine) {
      showError('Please select farm and line');
      return;
    }
    if (curLine.id !== lineData.id && !curLine.growing_cycle) {
      showError('_line_empty');
      return;
    }
    if (!formData.amount) {
      showError('Please input harvest amount');
      return;
    }
    if (!formData.shell_length || !formData.shell_length_max) {
      showError('Please input shell length range');
      return;
    }
    if (!formData.bags_quantity) {
      showError('Please input number of bags');
      return;
    }
    const { complete_date, start_time, finish_time, inventories, ...params } =
      formData;
    const time_tracks = toSecTimeTracks([{ start_time, finish_time }]);
    if (time_tracks === false) {
      showError('_invalid_time_range');
      return;
    }
    const ivtItems = inventories?.filter(x =>
      ivtSum.some(t => t.inventory_id === x.inventory_id),
    );
    if (ivtItems?.some(x => x.quantity && x.quantity < 0)) {
      showError('Please enter valid quantity');
      return;
    }
    if (
      ivtItems?.some(
        x =>
          x.quantity &&
          x.quantity &&
          ivtSum.some(
            t =>
              t.inventory_id === x.inventory_id &&
              t.quantity < (x.quantity ?? 0),
          ),
      )
    ) {
      showError('Floats quantity should not exceed the maximum value');
      return;
    }
    if (
      (ivtItems?.length !== ivtSum?.length ||
        ivtItems.some(i =>
          ivtSum.some(
            t => t.inventory_id === i.inventory_id && t.quantity !== i.quantity,
          ),
        )) &&
      !window.confirm('Are you sure to leave some floats?')
    )
      return;
    const submitData = {
      ...params,
      income: 0,
      complete_date: Math.floor(complete_date / 1000),
      time_tracks,
      signature: signatureCanvas.current?.toDataURL(),
      line_id: curLine.id,
      seeding_id: curLine.growing_cycle?.main_seed.id ?? data.seeding_id,
      is_full: true,
      id: formID,
      inventories: ivtItems
        ?.filter(x => x.quantity && x.quantity > 0)
        .map(x => ({
          inventory_id: x.inventory_id,
          quantity: x.quantity,
          seeding_id: curLine.growing_cycle?.main_seed.id ?? data.seeding_id,
        })),
    };
    if (!submitData.seeding_id) {
      showError('_line_empty');
      return;
    }
    setDisabled(true);
    const res = await sendSingleRequest(
      submitData,
      'POST',
      'api/queue/confirm-harvest/mussel',
      true,
    );
    if (res.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Confirmed successfully'),
        }),
      );
      history.goBack();
    } else {
      setDisabled(false);
      showError(res.data?.message ?? 'Unknown error');
    }
  };

  useEffect(() => {
    if (sigCanLoaded && data && data.signature) {
      signatureCanvas.current?.fromDataURL(data.signature);
    }
  }, [data, sigCanLoaded, signatureCanvas.current]);

  return (
    <div>
      <div className='mb-17 d-flex'>
        <div className='w-100 mr-7'>
          <Dropdown
            label={translate(lang, 'Select farm')}
            options={farmOptions}
            value={curFarm?.id.toString()}
            onChange={v => selectFarm(v)}
          />
        </div>
        <div className='w-100 ml-7'>
          <Dropdown
            label={translate(lang, 'Select line')}
            options={lineOptions ?? []}
            value={curLine?.id.toString()}
            onChange={v => selectLine(v)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Datepicker
            label={translate(lang, 'Complete date')}
            defaultValue={formData.complete_date}
            onChange={e =>
              updateForm(
                'complete_date',
                e?.toDate().getTime() ?? moment().toDate().getTime(),
              )
            }
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, 'Season name')}
            value={formData.season_name ?? ''}
            onChange={e => updateForm('season_name', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <DateTimePicker
          className='mr-12'
          defaultValue={formData.start_time}
          label={translate(lang, 'Start time')}
          onChange={e => updateForm('start_time', e?.toDate().getTime())}
        />
        <DateTimePicker
          className='ml-12'
          defaultValue={formData.finish_time}
          label={translate(lang, 'Finish time')}
          onChange={e => updateForm('finish_time', e?.toDate().getTime())}
        />
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            value={formData.amount?.toString() ?? ''}
            label={translate(lang, 'Harvest amount')}
            unit='kg'
            onChange={e => updateForm('amount', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            value={formData.bags_quantity?.toString() ?? ''}
            label={translate(lang, 'Number of bags')}
            onChange={e => updateForm('bags_quantity', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            label={translate(lang, 'Shell length min')}
            value={formData.shell_length?.toString() ?? ''}
            unit='mm'
            onChange={e => updateForm('shell_length', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            value={formData.shell_length_max?.toString() ?? ''}
            label={translate(lang, 'Shell length max')}
            unit='mm'
            onChange={e => updateForm('shell_length_max', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='text'
            label={translate(lang, 'Company')}
            value={formData.company ?? ''}
            onChange={e => updateForm('company', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, 'Harvest number')}
            value={formData.harvest_number ?? ''}
            onChange={e => updateForm('harvest_number', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='text'
            label={translate(lang, 'Vessel')}
            value={formData.vessel ?? ''}
            onChange={e => updateForm('vessel', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, 'Tag color')}
            value={formData.tag_color ?? ''}
            onChange={e => updateForm('tag_color', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='text'
            label={translate(lang, 'Port of unload')}
            value={formData.port_of_unload ?? ''}
            onChange={e => updateForm('port_of_unload', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, 'Crop owner')}
            value={formData.crop_owner ?? ''}
            onChange={e => updateForm('crop_owner', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='text'
            label={translate(lang, 'Delivered to')}
            value={formData.delivered_to ?? ''}
            onChange={e => updateForm('delivered_to', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, 'Packhouse')}
            value={formData.packhouse ?? ''}
            onChange={e => updateForm('packhouse', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 mt-32 justify-content-between'>
        <div className='w-100 mr-12'>
          <CheckboxButton
            label={translate(lang, 'Is Final')}
            checked={formData.is_final}
            onChange={e => updateForm('is_final', e.target.checked)}
          />
        </div>
        <div className='w-100 ml-12'>
          <CheckboxButton
            onChange={e => updateForm('product_left_on_line', e.target.checked)}
            checked={formData.product_left_on_line}
            label={translate(lang, 'Product left on line')}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <CheckboxButton
            onChange={e => updateForm('backbone_ok', e.target.checked)}
            checked={formData.backbone_ok}
            label={translate(lang, 'Backbone OK')}
          />
        </div>
        <div className='w-100 ml-12'>
          <CheckboxButton
            onChange={e => updateForm('backbone_replace', e.target.checked)}
            checked={formData.backbone_replace}
            label={translate(lang, 'Backbone replace')}
          />
        </div>
      </div>
      <div className='d-flex mb-32 justify-content-between'>
        <div className='w-100 mr-12'>
          <CheckboxButton
            onChange={e => updateForm('flotation_on_farm', e.target.checked)}
            checked={formData.flotation_on_farm}
            label={translate(lang, 'Flotation on farm')}
          />
        </div>
        <div className='w-100 ml-12'>
          <CheckboxButton
            onChange={e => updateForm('lights_ids_in_place', e.target.checked)}
            checked={formData.lights_ids_in_place}
            label={translate(lang, 'Lights and IDs in place')}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            label={translate(lang, '_blues_oysters')}
            value={formData.blues ?? ''}
            onChange={e => updateForm('blues', e.target.value)}
            unit='%'
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, '_marine_waste')}
            value={formData.marine_waste ?? ''}
            onChange={e => updateForm('marine_waste', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            label={translate(lang, 'Meat yield')}
            unit='%'
            value={formData.meat_yield ?? ''}
            onChange={e => updateForm('meat_yield', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, 'Shell condition')}
            value={formData.shell_condition ?? ''}
            onChange={e => updateForm('shell_condition', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='text'
            label={translate(lang, 'Mussel type')}
            value={formData.mussel_type ?? ''}
            onChange={e => updateForm('mussel_type', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='text'
            label={translate(lang, 'Growing area')}
            value={formData.growing_area ?? ''}
            onChange={e => updateForm('growing_area', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100 mr-12'>
          <Input
            type='number'
            label={translate(lang, 'Performance')}
            value={formData.mussels ?? ''}
            onChange={e => updateForm('mussels', e.target.value)}
            unit='mussels per kg'
          />
        </div>
        <div className='w-100 ml-12'>
          <Input
            type='number'
            label={translate(lang, 'Number of rope bags')}
            value={formData.rope_bags_quantity ?? ''}
            onChange={e => updateForm('rope_bags_quantity', e.target.value)}
          />
        </div>
      </div>
      <div className='mb-17'>
        <Input
          type='textarea'
          label={translate(lang, 'Comment')}
          value={formData.comment ?? ''}
          onChange={e => updateForm('comment', e.target.value)}
        />
      </div>
      <div className='mb-17'>
        <p className='mb-4 d-block paragrapgh paragrapgh--2 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
          {translate(lang, 'Signature')}
        </p>
        <div
          style={{
            border: '1px solid #EFEFEF',
            borderRadius: '4px',
            height: '200px',
          }}
        >
          <SignatureCanvas
            ref={r => {
              signatureCanvas.current = r;
              setSigCanLoaded(true);
            }}
            penColor='green'
            canvasProps={{ width: 550, height: 200 }}
          />
        </div>
      </div>
      <div className='mb-17'>
        <PicturesWall
          label={translate(lang, 'Images')}
          fileList={formData.images}
          handleChangeImages={f => updateForm('images', f)}
        />
      </div>
      {ivtSum && ivtSum.length > 0 && (
        <div className='mt-17 mb-17'>
          <Subtitle
            size={5}
            color='black-1'
            fontWeight={500}
            align='left'
            className='mb-7'
          >
            {translate(lang, 'Returning floats')}
          </Subtitle>
          {ivtSum.map(d => (
            <div className='mb-7' key={d.inventory_id}>
              <Input
                type='number'
                label={`${d.inventory_name} - ${d.quantity}`}
                placeholder={`Maximum value is ${d.quantity}`}
                value={
                  formData.inventories
                    ?.find(x => x.inventory_id === d.inventory_id)
                    ?.quantity?.toString() ?? ''
                }
                onChange={e => changeInventory(d.inventory_id, e.target.value)}
              />
            </div>
          ))}
        </div>
      )}
      <div className='mt-17 d-flex justify-content-end'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </div>
  );
};

export default PaperHarvestForm;
