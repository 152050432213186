import { useState } from 'react';
import { Modal, Table } from 'antd';
import { IFarmResource } from '../../entities/farms.entities';
import { Button, CloseIcon, Datepicker, Subtitle } from '../shared';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import moment from 'moment';
import { formatNumber } from '../../entities/util-functions';
import { sendSingleRequest } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { loadFarmsData } from '../../store/farms/farms.actions';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

const columns = (lang: any) => [
  {
    key: 'fee',
    dataIndex: 'fee',
    title: translate(lang, 'Fee'),
    render: (x: any) => <span>{formatNumber(x)}</span>,
  },
  {
    key: 'frequency',
    dataIndex: 'frequency',
    title: translate(lang, 'Frequency'),
    render: (x: any) => <span>{x === 'M' ? 'Monthly' : 'Annually'}</span>,
  },
  {
    key: 'type',
    dataIndex: 'type',
    title: translate(lang, 'Type'),
    render: (x: any) => <span>{x}</span>,
  },
  {
    key: 'more',
  },
];

interface Props {
  farm: IFarmResource;
  className?: string;
  onClose: () => void;
}

const FarmLeaseModal = ({ farm, onClose, className }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const feeList = JSON.parse(farm.farm_fee?.fee_json ?? '[]').map(
    (x: any, i: number) => ({ id: i.toString(), ...x }),
  );

  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState(
    farm.farm_fee?.start_date
      ? farm.farm_fee?.start_date * 1000
      : moment().toDate().getTime(),
  );

  const updateDate = (date: number) => {
    const min = farm.farm_fee?.start_date;
    if (min && date < min * 1000) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(
            lang,
            'Minimum allowed date is %s',
            defaultDateFormat(min),
          ),
        }),
      );
      return;
    }
    if (date > moment().toDate().getTime()) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(
            lang,
            'Maximum allowed date is %s',
            defaultDateFormat(moment().toDate().getTime()),
          ),
        }),
      );
      return;
    }
    setDate(date);
  };
  const closeModal = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
  };
  const afterClick = (res: any) => {
    setDisabled(false);
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Success'),
        }),
      );
      dispatch(loadFarmsData());
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, res.data?.message ?? 'Failed'),
        }),
      );
    }
    onClose();
  };
  const startClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setDisabled(true);
    sendSingleRequest(
      {
        status: 1,
        farm_id: farm.id,
        start_date: moment(date).format('YYYY-MM-DD'),
      },
      'POST',
      'api/farm/farm-lease',
      true,
    ).then(res => {
      afterClick(res);
    });
  };
  const stopClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setDisabled(true);
    sendSingleRequest(
      {
        status: 0,
        farm_id: farm.id,
        start_date: moment(date).format('YYYY-MM-DD'),
      },
      'POST',
      'api/farm/farm-lease',
      true,
    ).then(res => {
      afterClick(res);
    });
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      className={className}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <div
        className='wrap'
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className='d-flex align-items-center justify-content-center mb-16'>
          <Subtitle color='black-1' align='center' size={1} fontWeight={600}>
            {translate(lang, 'Farm %s leasing', farm.name)}
          </Subtitle>
        </div>
        <div className='mt-16 mb-16'>
          <Subtitle color='black-1' align='center' size={0} fontWeight={500}>
            {!farm.farm_fee?.status
              ? translate(lang, '%s is not leased currently', farm.name)
              : translate(
                  lang,
                  '%s leasing is started at %s, leased for %s days.',
                  farm.name,
                  defaultDateFormat(farm.farm_fee.start_date),
                  moment().diff(moment(farm.farm_fee.start_date * 1000), 'd') +
                    1,
                )}
          </Subtitle>
        </div>
        {!!farm.farm_fee?.status && (
          <>
            <div className='mt-17' style={{ textAlign: 'center' }}>
              <div className='text-center'>
                <span>{translate(lang, 'Monthly fixed fee')}: $</span>
                {formatNumber(farm.farm_fee.fixed_month_price)}
              </div>
              <div className='text-center'>
                <span>{translate(lang, 'Monthly percent fee')}: </span>
                {formatNumber(farm.farm_fee.percent_month_price)}%
              </div>
              <div className='text-center'>
                {translate(lang, 'Annually fixed fee')}: $
                {formatNumber(farm.farm_fee.fixed_annual_price)}
              </div>
              <div className='text-center'>
                <span>{translate(lang, 'Annually percent fee')}: </span>
                {formatNumber(farm.farm_fee.percent_annual_price)}%
              </div>
            </div>
            <div className='mt-17'>
              <Table
                className='table'
                rowKey={'id'}
                pagination={false}
                columns={columns(lang)}
                dataSource={feeList}
              />
            </div>
          </>
        )}
        <div className='pb-24'>
          <Datepicker
            className='mb-17'
            defaultValue={date}
            label={translate(lang, 'Started from')}
            onChange={e => e && updateDate(e.toDate().getTime())}
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-center align-items-center'>
        <Button
          className='rsp-btn ml-17 mr-17'
          width='small'
          size={2}
          type='fill'
          color='green'
          onClick={startClick}
          disabled={disabled}
        >
          {translate(
            lang,
            !farm.farm_fee?.status ? 'Start lease' : 'Renew lease',
          )}
        </Button>
        {!!farm.farm_fee?.status && (
          <Button
            className='rsp-btn ml-17 mr-17'
            width='small'
            size={2}
            type='fill'
            color='red'
            onClick={stopClick}
            disabled={disabled}
          >
            {translate(lang, 'Stop lease')}
          </Button>
        )}
        <Button
          className='rsp-btn ml-17 mr-17'
          width='small'
          size={2}
          type='fill'
          color='blue'
          onClick={closeModal}
          disabled={disabled}
        >
          {translate(lang, 'Cancel')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default FarmLeaseModal;
