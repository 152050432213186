import { useCallback, useState, useEffect } from 'react';
import {
  Button,
  CheckboxButton,
  Dropdown,
  Input,
  Title,
  TrashIcon,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import LineAssessExpireDaysInput from '../../components/user-settings/LineAssessExpireDaysInput';
import { useDispatch, useSelector } from 'react-redux';
import SeedStagesSize from '../../components/user-settings/SeedStagesSize';
import {
  updateAccountSetting,
  updateShowGpsWarning,
} from '../../store/auth/auth.actions';
import {
  addUtilData,
  deleteUtilData,
  loadUtilsData,
} from '../../store/utils/utils.actions';
import { setLangUI, showFeedback } from '../../store/ui/ui.actions';
import { selectUtils } from '../../store/utils/utils.selector';
import { selectAccount, selectSettings } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import OysterHarvestTypes from '../../components/farm-util/OysterHarvestTypes';
import './styles.scss';

const harvestTypes = [
  'Whole Mussel',
  'Half Shell',
  'Meat',
  'Oil Product',
  'Powder Product',
];

const MusselHarvestTypeList = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const checkedOptions = useSelector(selectUtils).filter(
    x => x.type === 'harvest_type',
  );
  const otherOptions = checkedOptions.filter(
    x => !harvestTypes.includes(x.name),
  );
  const [otherVal, setOtherVal] = useState('');

  const isChecked = useCallback(
    (v: string) => checkedOptions.some(x => x.name === v),
    [checkedOptions],
  );
  const toggleCheck = useCallback(
    (v: string) => {
      let item = checkedOptions.find(x => x.name === v);
      if (item) {
        dispatch(deleteUtilData(item.id));
      } else {
        dispatch(addUtilData('harvest_type', v));
      }
    },
    [checkedOptions, dispatch],
  );
  const onOtherAddClick = async () => {
    if (otherVal.length <= 0 || checkedOptions.some(x => x.name === otherVal))
      return;
    await dispatch(addUtilData('harvest_type', otherVal));
    setOtherVal('');
  };
  const onOtherDeleteClick = async (v: string) => {
    let item = checkedOptions.find(x => x.name === v);
    if (item) {
      await dispatch(deleteUtilData(item.id));
    }
  };

  return (
    <div className='mt-19 harvest-type-list mb-16'>
      <div className='d-flex mb-16 mt-27 align-items-center justify-content-between'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Mussel harvest type List')}
        </Title>
      </div>
      <div className='ml-21'>
        {harvestTypes.map((s, i) => (
          <CheckboxButton
            key={i}
            className='mb-17 ml-0'
            label={s}
            checked={isChecked(s)}
            onChange={e => toggleCheck(s)}
          />
        ))}
        {otherOptions.map(x => (
          <div key={x.id.toString()} className='--other-option'>
            <Input type='string' disabled={true} value={x.name} label='' />
            <button
              className='--other-delete-btn'
              onClick={e => onOtherDeleteClick(x.name)}
            >
              <TrashIcon />
            </button>
          </div>
        ))}
        <div className='d-flex align-items-end mt-17'>
          <Input
            label={translate(lang, 'Other')}
            type='text'
            value={otherVal}
            required={false}
            onChange={e => setOtherVal(e.target.value)}
          />
          <Button
            className='ml-17'
            type='fill'
            width='large'
            size={1}
            color='blue'
            onClick={onOtherAddClick}
          >
            {translate(lang, 'Add')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const GpsWarningSetting = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const checked = useSelector(selectSettings).showGpsWarning;
  const toggleCheck = (c: boolean) => dispatch(updateShowGpsWarning(c));

  return (
    <div className='mb-64'>
      <div className='d-flex mb-16 mt-27 align-items-center justify-content-between'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Show Gps Warning')}
        </Title>
      </div>
      <div className='ml-21'>
        <CheckboxButton
          label={translate(
            lang,
            checked
              ? 'Show Gps Warning message'
              : 'Don not show gps warning message',
          )}
          checked={!!checked}
          onChange={e => toggleCheck(e.target.checked)}
        />
      </div>
    </div>
  );
};

const TrackOriginalSpat = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const checked = useSelector(selectAccount)?.track_original_spat;

  const [disabled, setDisabled] = useState(false);

  const toggleCheck = (c: boolean) => {
    setDisabled(true);
    dispatch(updateAccountSetting({ track_original_spat: c })).then(
      (res: any) => {
        setDisabled(false);
        if (res !== true) {
          dispatch(
            showFeedback({
              isMessage: true,
              type: 'error',
              message: translate(lang, res?.data?.message ?? 'Server error'),
            }),
          );
        }
      },
    );
  };

  return (
    <div className='mb-64'>
      <div className='d-flex mb-16 mt-27 align-items-center justify-content-between'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Track Original Spats')}
        </Title>
      </div>
      <div className='ml-21'>
        <CheckboxButton
          label={translate(lang, 'Track Original Spats')}
          disabled={disabled}
          checked={checked}
          onChange={e => toggleCheck(e.target.checked)}
        />
      </div>
    </div>
  );
};

const HarvestSizeSetting = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const accSetting = useSelector(selectAccount);

  const [minSize, setMinSize] = useState(accSetting?.harvest_min_size);
  const [growSpeed, setGrowSpeed] = useState(accSetting?.grow_speed);
  const [disabled, setDisabled] = useState(false);

  const updateValue = (key: 'size' | 'speed', value: string) => {
    const val = value.length <= 0 ? undefined : Number(value);
    if (key === 'size') {
      setMinSize(val);
    } else {
      setGrowSpeed(val);
    }
  };
  const updateClick = async () => {
    setDisabled(true);
    await dispatch(
      updateAccountSetting({
        harvest_min_size: minSize,
        grow_speed: growSpeed,
      }),
    );
    setDisabled(false);
  };

  return (
    <div className='mb-12'>
      <div className='mb-16'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {`${translate(lang, 'Size')} & ${translate(lang, 'Speed')}`}
        </Title>
      </div>
      <div className='d-flex align-items-center ml-21'>
        <div className='w-100 mr-7'>
          <Input
            label={translate(lang, 'Minimum Harvest Size')}
            value={minSize?.toString() ?? ''}
            onChange={e => updateValue('size', e.target.value)}
            type='number'
            unit='mm'
          />
        </div>
        <div className='w-100 ml-7'>
          <Input
            label={translate(lang, 'Grow Speed')}
            value={growSpeed?.toString() ?? ''}
            onChange={e => updateValue('speed', e.target.value)}
            type='number'
            unit='mm per month'
          />
        </div>
      </div>
      <Button
        className='mt-16 mb-16 ml-21'
        type='fill'
        width='small'
        size={0}
        color='blue'
        onClick={updateClick}
        disabled={disabled}
      >
        {translate(lang, 'Update')}
      </Button>
    </div>
  );
};

const SettingsPage = () => {
  const lang = useSelector(selectLang);
  const dispatch = useDispatch<any>();
  const width = useWidth();

  const updateLanguage = (v: any) => dispatch(setLangUI(v));

  useEffect(() => {
    dispatch(loadUtilsData());
  }, [dispatch]);

  return (
    <div className='content pb-32'>
      {width > 768 && (
        <Title
          className='mb-24'
          size={5}
          color='black-3'
          align='default'
          fontWeight={600}
        >
          {translate(lang, 'Setting')}
        </Title>
      )}
      <div className='pt-24 pb-32 mb-32'>
        <Dropdown
          label={translate(lang, 'Language')}
          options={[
            { id: 'en', value: 'en', label: 'English' },
            { id: 'fr', value: 'fr', label: 'Français' },
            { id: 'es', value: 'es', label: 'Español' },
          ]}
          value={lang ?? 'en'}
          onChange={updateLanguage}
        />
      </div>
      <MusselHarvestTypeList />
      <OysterHarvestTypes />
      <LineAssessExpireDaysInput />
      <SeedStagesSize />
      <GpsWarningSetting />
      <TrackOriginalSpat />
      <HarvestSizeSetting />
    </div>
  );
};

export default SettingsPage;
