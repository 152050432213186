import { Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { IMusselLineResource } from '../../entities/farms.entities';
import { loadQueueData } from '../../store/farms/farms.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { Button, ModalComponent, Subtitle } from '../shared';
import { useHistory } from 'react-router-dom';
import SeedingModal from '../farm-modals/SeedingModal';
import MaintenanceModal from '../farm-modals/MaintenanceModal';
import FloatManageModal from '../farm-modals/FloatManageModal';
import AssessmentModal from '../farm-modals/AssessmentModal';
import HarvestCompleteModal from '../farm-modals/HarvestCompleteModal';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectQueueData } from '../../store/farms/farms.selector';
import { IQueueItem } from '../../entities/automation.entities';

const QLabels = {
  assessment: 'Assessment',
  maintenance: 'Maintenance',
  inventory_seed: 'Inventory management',
  seeding: 'Seeding',
  harvest: 'Harvest',
};

const columns = [
  {
    key: 'type',
    title: 'Type',
    render: (x: IQueueItem) => <span>{(QLabels as any)[x.type]}</span>,
  },
  {
    key: 'title',
    title: 'Title',
    render: (x: IQueueItem) => (
      <span>{`${(QLabels as any)[x.type]} data`}</span>
    ),
  },
  {
    key: 'date',
    title: 'Date',
    render: (x: IQueueItem) => <span>{defaultDateFormat(x.date)}</span>,
  },
  {
    key: 'createdAt',
    title: 'Reported At',
    render: (x: IQueueItem) => <span>{defaultDateFormat(x.created_at)}</span>,
  },
  {
    key: 'userName',
    title: 'Reported by',
    render: (x: IQueueItem) => <span>{x.user.name}</span>,
  },
];

interface Props {
  lineData: IMusselLineResource;
  loadLine: () => void;
}

const PendingDataTable = ({ lineData, loadLine }: Props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const profile = useSelector(selectProfile);
  const queueData = useSelector(selectQueueData);
  const seedingId = lineData.growing_cycle?.main_seed.id;

  const [visibleAssessment, setVisibleAssessment] = useState(false);
  const [visibleMaintenance, setVisibleMaintenance] = useState(false);
  const [visibleFloats, setVisibleFloats] = useState(false);
  const [visibleSeeding, setVisibleSeeding] = useState(false);
  const [visibleHarvest, setVisibleHarvest] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [disable, setDisable] = useState(false);
  const [deletingItem, setDeletingItem] = useState<{
    type: string;
    item_id: number;
  }>();

  const queueItems = useMemo(() => {
    if (!queueData) return [];
    try {
      const queue = queueData.filter(
        x =>
          !x.type.startsWith('paper') &&
          x.line.id === lineData.id &&
          (!x.seeding_id || x.seeding_id === seedingId),
      );
      return queue;
    } catch (error) {
      return [];
    }
  }, [queueData, seedingId, lineData]);

  const afterConfirm = (r: any, visibleUpdate: () => void) => {
    if (r.status) {
      visibleUpdate();
      loadLine();
      dispatch(loadQueueData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: r.data?.message ?? 'Success',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: r.data?.message ?? 'Something went wrong',
        }),
      );
    }
  };
  const confirmMaintenance = (data: any) => {
    if (!selectedData) return;
    setDisable(true);
    sendSingleRequest(
      {
        id: selectedData.id,
        ...data,
        weather_condition: selectedData.weather_condition,
      },
      'POST',
      'api/queue/confirm-maintenance',
      true,
    ).then(r => {
      setDisable(false);
      afterConfirm(r, () => setVisibleMaintenance(false));
    });
  };
  const confirmAssessment = (data: any) => {
    if (!selectedData) return;
    setDisable(true);
    sendSingleRequest(
      {
        id: selectedData.id,
        ...data,
        weather_condition: selectedData.weather_condition,
      },
      'POST',
      'api/queue/confirm-assessment',
      true,
    ).then(r => {
      setDisable(false);
      afterConfirm(r, () => setVisibleAssessment(false));
    });
  };
  const confirmInventorySeed = (data: any) => {
    if (!selectedData) return;
    setDisable(true);
    sendSingleRequest(
      {
        id: selectedData.id,
        ...data,
        weather_condition: selectedData.weather_condition,
      },
      'POST',
      'api/queue/confirm-inventory_seed',
      true,
    ).then(r => {
      setDisable(false);
      afterConfirm(r, () => setVisibleFloats(false));
    });
  };
  const confirmSeeding = async (data: any) => {
    if (!selectedData) return;
    setDisable(true);
    const r = await sendSingleRequest(
      {
        id: selectedData.id,
        ...data,
        weather_condition: selectedData.weather_condition,
      },
      'POST',
      `api/queue/confirm-seeding/${selectedData.crop_type}`,
      true,
    );
    setDisable(false);
    if (!r.status) {
      return r.data;
    } else {
      afterConfirm(r, () => setVisibleSeeding(false));
      return true;
    }
  };
  const confirmHarvest = async (data: any) => {
    if (!selectedData) return;
    setDisable(true);
    const res = await sendSingleRequest(
      {
        id: selectedData.id,
        ...data,
        weather_condition: selectedData.weather_condition,
      },
      'POST',
      `api/queue/confirm-harvest/${selectedData.crop_type}`,
      true,
    );
    if (res.status) {
      setVisibleHarvest(false);
      await loadLine();
      await dispatch(loadQueueData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: res.data?.message ?? 'Success',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: res.data?.message ?? 'Something went wrong',
        }),
      );
    }
    return res;
  };
  const deleteQueueItem = () => {
    if (!deletingItem) return;
    setDisable(true);
    sendSingleRequest(deletingItem, 'DELETE', 'api/queue/entity', true).then(
      res => {
        setDisable(false);
        setDeletingItem(undefined);
        if (res.status) {
          dispatch(
            showFeedback({
              type: 'success',
              isMessage: true,
              message: 'Deleted successfully',
            }),
          );
          dispatch(loadQueueData());
        } else {
          dispatch(
            showFeedback({
              type: 'error',
              isMessage: true,
              message: res.data?.message ?? 'Failed to delete',
            }),
          );
        }
      },
    );
  };
  const clickQueueItem = async (item: IQueueItem) => {
    setDisable(true);
    const res = await sendSingleRequest(
      {},
      'GET',
      `api/queue/detail/${item.id}`,
      true,
    );
    if (res.status) {
      setDisable(false);
      setSelectedData(res.data);
      if (item.type === 'assessment') {
        setVisibleAssessment(true);
      } else if (item.type === 'maintenance') {
        setVisibleMaintenance(true);
      } else if (item.type === 'inventory_seed') {
        setVisibleFloats(true);
      } else if (item.type === 'seeding') {
        setVisibleSeeding(true);
      } else {
        setVisibleHarvest(true);
      }
    } else {
      dispatch(
        showFeedback({
          message: res.data?.message ?? 'Unknown error',
          type: 'error',
          isMessage: true,
        }),
      );
      setDisable(false);
    }
  };

  useEffect(() => dispatch(loadQueueData()), [dispatch]);

  const tableColumns = [
    ...columns,
    {
      key: 'action',
      title: '',
      render: (x: IQueueItem) =>
        profile?.role !== 'owner' && !profile?.is_verified ? (
          <span></span>
        ) : (
          <span>
            <Button
              size={0}
              color='green'
              width='small'
              type='fill'
              disabled={x.id !== queueItems[0].id || disable}
              onClick={() => clickQueueItem(x)}
            >
              Confirm
            </Button>
            <Button
              className='ml-17'
              size={0}
              color='red'
              width='small'
              type='fill'
              disabled={disable}
              onClick={() => {
                setDeletingItem({
                  type: x.type,
                  item_id: x.id,
                });
              }}
            >
              Delete
            </Button>
          </span>
        ),
    },
  ];

  return queueItems.length <= 0 ? (
    <></>
  ) : (
    <div style={{ overflow: 'auto', marginBottom: '20px' }}>
      <div
        className='ant-table-wrapper table mt-17'
        style={{ width: '100%', minWidth: '100%', maxWidth: '1200px' }}
      >
        <Subtitle
          className='ml-7'
          size={6}
          align='default'
          color='red'
          fontWeight={700}
        >
          <span
            style={{ color: '#c8051a', cursor: 'pointer' }}
            onClick={() => history.push('/queue')}
          >
            There's a pending action to be validated for this line
          </span>
        </Subtitle>
        <Table
          rowKey='id'
          className='table table--isFarm pl-0 pr-0'
          pagination={false}
          columns={tableColumns}
          dataSource={queueItems}
        />
      </div>
      {selectedData && (
        <>
          {visibleSeeding && (
            <SeedingModal
              type={selectedData.crop_type}
              visible={visibleSeeding}
              onCancel={() => setVisibleSeeding(false)}
              title='Seed the line'
              onConfirm={() => {}}
              lineData={selectedData.lineData}
              data={selectedData}
              overConfirm={confirmSeeding}
              parentID={
                !selectedData.parent_id ? undefined : selectedData.parent_id
              }
              lineChangeable={true}
            />
          )}
          {visibleHarvest && (
            <HarvestCompleteModal
              type={selectedData.crop_type}
              visible={true}
              title='Harvest Complete'
              onCancel={() => setVisibleHarvest(false)}
              onConfirm={() => {}}
              overConfirm={confirmHarvest}
              data={selectedData}
              lineData={selectedData.lineData}
              lineChangeable={true}
            />
          )}
          {visibleAssessment && (
            <AssessmentModal
              type={selectedData.crop_type}
              title='Add assessment'
              visible={visibleAssessment}
              data={selectedData}
              onCancel={() => setVisibleAssessment(false)}
              onConfirm={() => {}}
              overConfirm={confirmAssessment}
              lineData={lineData}
              lineChangeable={true}
            />
          )}
          {visibleFloats && (
            <FloatManageModal
              visible={visibleFloats}
              onCancel={() => setVisibleFloats(false)}
              title='Inventory management'
              onConfirm={() => {}}
              data={selectedData}
              lineData={lineData}
              overConfirm={confirmInventorySeed}
              lineChangeable
            />
          )}
          {visibleMaintenance && (
            <MaintenanceModal
              visible={visibleMaintenance}
              title='Add maintenance'
              onCancel={() => setVisibleMaintenance(false)}
              onConfirm={() => {}}
              overConfirm={confirmMaintenance}
              data={selectedData}
              lineData={selectedData.lineData}
              lineChangeable={true}
            />
          )}
        </>
      )}
      {deletingItem && (
        <ModalComponent
          visible={true}
          disabled={disable}
          onCancel={() => setDeletingItem(undefined)}
          type='delete'
          title='Error / Delete'
          text='Do you really want to delete this data?'
          onConfirm={deleteQueueItem}
        />
      )}
    </div>
  );
};

export default PendingDataTable;
