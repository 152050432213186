import {
  IName,
  ITimeTrack,
  IWeatherConditionResource,
} from './general.entities';

export interface IBasicInventory {
  id: number;
  type: string;
  name: string;
  level: number;
}

export interface IInventoryResource extends IBasicInventory {
  quantity: number;
  price: number;
  volume: number | null;
  available_quantity: number;
  pending_quantity: number;
  inventory_seeds: IFloatSeed[];

  account_id?: number;
}

export interface IInventoryPosition {
  seeding_id: number;
  inventory_id: number;
  points: number[];
}

interface IFloatSeed {
  id: number;
  line_id: number;
  quantity: number;
  status: 'IN' | 'OUT';
  manage_date: number;
}

export interface IInventorySum {
  inventory_id: number;
  inventory_type: string;
  inventory_name: string;
  seeding_id: number;
  quantity: number;
}

export interface IInventoryManage {
  id: number;
  manage_date: number;
  quantity: number;
  status: 'IN' | 'OUT';
  line_id: number;
  seeding_id: number;
  harvest_id: number | null;
  inventory: IBasicInventory;
  user: IName | null;
  verifier: IName | null;
  weather_condition: IWeatherConditionResource | null;
  time_tracks?: ITimeTrack[];
}

export interface IPendingInventory {
  id: number;
  name: string;
  pending_quantity: number;
}

export const defaultBagMusselTypes = [
  'Harvested Mussels',
  'Brokens',
  'Smalls',
  'Biofouling (Blue mussels/Oysters)',
  'Green Waste',
];
