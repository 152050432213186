import { useSelector } from 'react-redux';
import { ModalComponent } from '../../components/shared';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { Table } from 'antd';
import { selectOysterFarms } from '../../store/farms/farms.selector';
import { useMemo, useState } from 'react';
import { formatNumber } from '../../entities/util-functions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { NavLink, useHistory } from 'react-router-dom';
import PhotosViewButton from '../../components/lines/PhotosViewButton';
import { calcLineLastSize, getLineOysters } from '../../lib/farm.helpers';

const sizeSteps = [2, 3, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80];

interface IRow {
  isTotal?: boolean;
  isFinal?: boolean;
  farm_id: number;
  farm_name: string;
  farm_number: string;
  line_id: number;
  line_name: string;
  date: number;
  batch: string;
  basket_count: number;
  method: string;
  size: number;
  quantity: number;
  comment?: string;
}

const columns = [
  {
    key: 'size',
    title: 'Size / Detail',
    render: (x: IRow) =>
      x.size < 0 ? (
        <></>
      ) : (
        <span>
          {x.size === 0
            ? '1 - 2 mm'
            : x.size >= sizeSteps.length
            ? '80+ mm'
            : `${sizeSteps[x.size - 1]} - ${sizeSteps[x.size]} mm`}
        </span>
      ),
  },
  {
    key: 'Rack',
    title: 'Rack',
    render: (x: IRow) =>
      x.isFinal ? undefined : x.isTotal ? (
        <strong>{'Total'}</strong>
      ) : (
        <div>
          <div>{`${x.farm_name}/${x.farm_number}`}</div>
          <div>{x.line_name}</div>
        </div>
      ),
  },
  {
    key: 'Units',
    title: 'Units',
    render: (x: IRow) =>
      x.isTotal ? (
        <strong>{x.basket_count}</strong>
      ) : (
        <span>{`${x.basket_count} ${x.method}`}</span>
      ),
  },
  {
    key: 'quantity',
    title: 'QTY (Dozens)',
    render: (x: IRow) =>
      x.isTotal ? (
        <strong>{formatNumber(x.quantity)}</strong>
      ) : (
        <span>{formatNumber(x.quantity)}</span>
      ),
  },
  {
    key: 'batch',
    title: 'Batch',
    render: (x: IRow) => <span>{x.batch}</span>,
  },
  {
    key: 'date',
    title: 'Seeding date',
    render: (x: IRow) =>
      x.isTotal ? <></> : <span>{defaultDateFormat(x.date)}</span>,
  },
  {
    key: 'photos',
    title: 'Photos',
    render: (x: IRow) =>
      x.isFinal ? (
        <strong>{'Total'}</strong>
      ) : x.isTotal ? undefined : (
        <div onClick={e => e.stopPropagation()}>
          <PhotosViewButton lineID={x.line_id} label={'View'} />
        </div>
      ),
  },
];
const OysterLinesPage = () => {
  const history = useHistory();
  const lang = useSelector(selectLang);
  const farms = useSelector(selectOysterFarms);

  const [comment, setComment] = useState<string>();

  const dataCols = [
    ...columns,
    {
      key: 'comment',
      title: translate(lang, 'Comment'),
      render: (x: IRow) =>
        !x.comment ? undefined : (
          <div
            className='btn__modal'
            onKeyDown={() => undefined}
            onClick={e => {
              e.stopPropagation();
              setComment(x.comment);
            }}
          >
            {translate(lang, 'View')}
          </div>
        ),
    },
    {
      key: 'more',
      title: '',
      render: () => undefined,
    },
  ];

  const data = useMemo(() => {
    let result: IRow[] = [];
    for (const farm of farms) {
      for (const line of farm.lines) {
        if (!line.growing_cycle) continue;
        const shellSize = calcLineLastSize(line);
        if (!shellSize) continue;

        const cycle = line.growing_cycle;
        const avgSize = shellSize.avg;

        let i = 0;
        for (; i < sizeSteps.length && avgSize >= sizeSteps[i]; i++);
        result.push({
          farm_id: farm.id,
          farm_name: farm.name,
          farm_number: farm.farm_number,
          line_id: line.id,
          line_name: line.line_name,
          date: cycle.main_seed.planned_date_seed,
          batch: cycle.main_seed.spat_storage?.source ?? 'Catch Spat',
          size: i,
          basket_count: cycle.current_basket_count,
          method: line.farming_method ?? '',
          quantity: getLineOysters(line) ?? 0,
          comment: cycle.last_assessment?.comment ?? undefined,
        });
      }
    }
    result.sort((a, b) =>
      a.size === b.size ? a.date - b.date : a.size - b.size,
    );
    let prv = -1;
    let unitSum = 0,
      qtySum = 0;
    let tmp: IRow[] = [];
    for (let i = 0; i < result.length; i++) {
      if (result[i].size === prv) {
        result[i].size = -1;
      } else {
        if (prv !== -1) {
          tmp.push({
            isTotal: true,
            farm_id: 0,
            farm_name: '',
            farm_number: '',
            line_id: -i,
            line_name: '',
            date: 0,
            batch: '',
            size: -1,
            basket_count: unitSum,
            method: '',
            quantity: qtySum,
          });
          unitSum = 0;
          qtySum = 0;
        }
        prv = result[i].size;
      }
      unitSum += result[i].basket_count;
      qtySum += result[i].quantity;
      tmp.push(result[i]);
    }
    if (prv !== -1) {
      tmp.push({
        isTotal: true,
        farm_id: 0,
        farm_name: '',
        farm_number: '',
        line_id: 0,
        line_name: '',
        date: 0,
        batch: '',
        size: -1,
        basket_count: unitSum,
        method: '',
        quantity: qtySum,
      });
    }
    unitSum = tmp
      .filter(x => !x.isTotal)
      .reduce((acc, x) => acc + x.basket_count, 0);
    qtySum = tmp
      .filter(x => !x.isTotal)
      .reduce((acc, x) => acc + x.quantity, 0);
    tmp.push({
      isTotal: true,
      isFinal: true,
      farm_id: 0,
      farm_name: '',
      farm_number: '',
      line_id: -tmp.length,
      line_name: '',
      date: 0,
      batch: '',
      size: -1,
      basket_count: unitSum,
      method: '',
      quantity: qtySum,
    });
    return tmp;
  }, [farms]);

  return (
    <div className='bg-secondary min-h-100'>
      <div className='container'>
        <div className='sub-header'>
          <NavLink className='header__link' to={'/oyster-summary/estimate'}>
            {translate(lang, 'Harvest Delivery Estimates')}
          </NavLink>
          <NavLink
            className='header__link ml-24 mr-24'
            to={'/oyster-summary/calender'}
          >
            {translate(lang, 'Calendar Estimates')}
          </NavLink>
          <NavLink className='header__link mr-24' to={'/oyster-summary/growth'}>
            {translate(lang, 'Growth Calculator')}
          </NavLink>
          <NavLink className='header__link' to={'/oyster-summary/lines'}>
            {translate(lang, 'Size Group')}
          </NavLink>
        </div>
        <div className='farms__line-content'>
          <div className='w-100 mt-17 pb-32'>
            <Table
              rowKey='line_id'
              className='table table--isFarm'
              pagination={false}
              columns={dataCols}
              dataSource={data}
              onRow={row => ({
                onClick: row.isTotal
                  ? undefined
                  : () => history.push(`/farms/${row.farm_id}/${row.line_id}`),
              })}
            />
          </div>
        </div>
      </div>
      <ModalComponent
        visible={!!comment}
        onCancel={() => setComment(undefined)}
        type=''
        title={translate(lang, 'Comment')}
        text={comment ?? ''}
      />
    </div>
  );
};

export default OysterLinesPage;
