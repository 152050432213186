import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { getTaskData } from '../../store/tasks/tasks.actions';
import { Button } from '../shared';
import { showFeedback } from '../../store/ui/ui.actions';
import { ITaskData, ITaskResource } from '../../entities/task.entities';
import SeedingModal from '../farm-modals/SeedingModal';
import MaintenanceModal from '../farm-modals/MaintenanceModal';
import AssessmentModal from '../farm-modals/AssessmentModal';
import HarvestCompleteModal from '../farm-modals/HarvestCompleteModal';
import { sendSingleRequest } from '../../apis';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectProfile } from '../../store/auth/auth.selector';

interface Props {
  task: ITaskResource | ITaskData;
}

const TaskTypeButton = ({ task }: Props) => {
  const dispatch = useDispatch<any>();
  const farmsData = useSelector(selectFarmsData);
  const profile = useSelector(selectProfile);
  const isEditable = profile?.edit_permission;

  const farm = farmsData.find(f => f.id === task.farm_id);
  const line = farm?.lines.find(l => l.id === task.line_id);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(task.is_completed);
  const [label, setLabel] = useState('General');

  const clickButton = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    evt?.preventDefault();
    evt.stopPropagation();
    setVisible(true);
  };
  const archiveTask = async () => {
    const r = await sendSingleRequest(
      { is_completed: true },
      'PUT',
      `api/task/tasks/${task.id}`,
      true,
    );
    if (r.status) {
      await dispatch(getTaskData());
    }
  };
  const afterConfirm = async (data: any) => {
    const cpt = async () => {
      setVisible(false);
      await archiveTask();
      await dispatch(loadFarmsData());
    };
    if (task.type === 'ASSESSMENT') {
      if (line?.growing_cycle) {
        await cpt();
      }
    } else if (task.type === 'SEEDING') {
      if (line?.id) {
        await cpt();
      }
    } else if (task.type === 'HARVEST') {
      await cpt();
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: `${label} successfully done!`,
        }),
      );
      await cpt();
    }
  };

  useEffect(() => {
    if (task.type === 'ASSESSMENT') {
      if (isEditable && line && line.growing_cycle) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setLabel('Add assessment');
    } else if (task.type === 'HARVEST') {
      if (isEditable && line && line.growing_cycle) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setLabel('Harvest complete');
    } else if (task.type === 'MAINTENANCE') {
      if (isEditable && line && line.growing_cycle) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setLabel('Add maintenance');
    } else if (task.type === 'SEEDING') {
      if (isEditable && line && !line.growing_cycle) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setLabel('Seed the line');
    } else if (task.type === 'INVENTORY_SEED') {
      setDisabled(true);
      setLabel('Floating');
    } else {
      setDisabled(true);
      setLabel(task.type ?? 'General');
    }
  }, [line, task]);

  return (
    <>
      <Button
        color='green'
        size={5}
        width='small'
        type='fill'
        isNoneBorder
        disabled={disabled || task.is_completed}
        onClick={clickButton}
      >
        {label}
      </Button>
      {!!farm && (
        <>
          {visible && task.type === 'ASSESSMENT' && line?.growing_cycle && (
            <AssessmentModal
              type={farm.type}
              visible={true}
              title={label}
              onCancel={() => setVisible(false)}
              onConfirm={afterConfirm}
              lineData={line}
            />
          )}
          {visible && task.type === 'HARVEST' && !!line && (
            <HarvestCompleteModal
              type={farm.type}
              visible={true}
              title={label}
              onCancel={() => setVisible(false)}
              onConfirm={afterConfirm}
              lineData={line}
            />
          )}
          {!!visible && task.type === 'SEEDING' && !!line && (
            <SeedingModal
              type={farm.type}
              visible={true}
              title='Seed the line'
              lineData={line}
              onConfirm={afterConfirm}
              confirmBtnLabel='Confirm & Complete'
              onCancel={() => setVisible(false)}
            />
          )}
        </>
      )}
      {!!visible && task.type === 'MAINTENANCE' && line?.growing_cycle && (
        <MaintenanceModal
          visible={visible}
          lineData={line}
          title={label}
          onCancel={() => setVisible(false)}
          onConfirm={afterConfirm}
          confirmBtnLabel='Confirm & Complete'
        />
      )}
    </>
  );
};

export default TaskTypeButton;
