import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import {
  formatNumber,
  numberToMoneyStr,
} from '../../../entities/util-functions';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import { Button, CloseIcon, Input, Subtitle } from '../../shared';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { IMusselHarvest } from '../../../entities/growing.entities';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import {
  SelectIsBudgetVisible,
  SelectMusselFarmsByFarmID,
} from '../../../store/extra/extra.selector';
import { labelNumber, labelRange } from '../../../lib/common.helpers';

interface Props {
  visible: boolean;
  harvest: IMusselHarvest;
  onClose: () => void;
}

const MusselHarvestViewModal = ({ visible, harvest, onClose }: Props) => {
  const lang = useSelector(selectLang);
  const showBudget = SelectIsBudgetVisible(harvest.line.farm_id);
  const farmsData = SelectMusselFarmsByFarmID(harvest.line.farm_id);
  const farm = farmsData.find(x => x.id === harvest.line.farm_id);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap view-modal'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Harvest Details')}:
          </Subtitle>
          <Subtitle
            className='ml-7'
            color='black-3'
            align='left'
            size={1}
            fontWeight={500}
          >
            {`${farm?.name} - ${harvest.line.line_name}`}
          </Subtitle>
        </div>
        <div className='pt-16 pb-24'>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Season')}</div>
              <div className='info-value'>{harvest.season_name}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Complete date')}
              </div>
              <div className='info-value'>
                {defaultDateFormat(harvest.complete_date)}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>Final</div>
              <div className='info-value'>
                {translate(lang, !harvest.is_final ? 'Middle' : 'Final')}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>{translate(lang, 'Amount')}</div>
              <div className='info-value'>
                {`${formatNumber(harvest.amount)} kg`}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>F/P</div>
              <div className='info-value'>
                {!!harvest.is_full ? 'Full' : 'Partial'}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Harvested length')}
              </div>
              <div className='info-value'>
                {labelNumber(harvest.line_length ?? undefined, 'm')}
              </div>
            </div>
          </div>
          {!!showBudget && (
            <div className='d-flex'>
              <div className='info-card mr-17'>
                <div className='info-label'>{translate(lang, 'Income')}</div>
                <div className='info-value'>
                  {`$ ${numberToMoneyStr(harvest.income ?? 0)}`}
                </div>
              </div>
              <div className='info-card ml-17'>
                <div className='info-label'>{translate(lang, 'Expense')}</div>
                <div className='info-value'>
                  {`$ ${numberToMoneyStr(
                    harvest.expense_items?.reduce((p, c) => p + c.amount, 0) ??
                      0,
                  )}`}
                </div>
              </div>
            </div>
          )}
          {!!harvest.is_final && (
            <div className='d-flex'>
              <div className='info-card mr-17'>
                <div className='info-label'>{translate(lang, 'Company')}</div>
                <div className='info-value'>{harvest.company}</div>
              </div>
              <div className='info-card ml-17'>
                <div className='info-label'>{translate(lang, 'Vessel')}</div>
                <div className='info-value'>{harvest.vessel}</div>
              </div>
            </div>
          )}
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>
                {translate(lang, 'Shell length')}
              </div>
              <div className='info-value'>
                {labelRange(
                  harvest.shell_length,
                  harvest.shell_length_max ?? undefined,
                )}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Shell condition')}
              </div>
              <div className='info-value'>{harvest.shell_condition}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>
                {translate(lang, 'Harvest Number')}
              </div>
              <div className='info-value'>{harvest.harvest_number}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Number of bags')}
              </div>
              <div className='info-value'>{harvest.bags_quantity}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Tag color')}</div>
              <div className='info-value'>{harvest.tag_color}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Port of unload')}
              </div>
              <div className='info-value'>{harvest.port_of_unload}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Crop owner')}</div>
              <div className='info-value'>{harvest.crop_owner}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Growing area')}
              </div>
              <div className='info-value'>{harvest.growing_area}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>
                {translate(lang, 'Delivered to')}
              </div>
              <div className='info-value'>{harvest.delivered_to}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>{translate(lang, 'Packhouse')}</div>
              <div className='info-value'>{harvest.packhouse}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Mussel type')}</div>
              <div className='info-value'>{harvest.mussel_type}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>{translate(lang, 'Performance')}</div>
              <div className='info-value'>{harvest.mussels}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Meat yield')}</div>
              <div className='info-value'>
                {labelNumber(harvest.meat_yield ?? undefined, '%')}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>{translate(lang, "User's name")}</div>
              <div className='info-value'>{harvest.harvester_name}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>
                {translate(lang, '_blues_oysters')}
              </div>
              <div className='info-value'>
                {labelNumber(harvest.blues ?? undefined, '%')}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, '_marine_waste')}
              </div>
              <div className='info-value'>
                {`${harvest.marine_waste ?? 0} kg`}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>Backbone OK</div>
              <div className='info-value'>
                {!harvest.backbone_ok ? 'No' : 'Yes'}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>Backbone Replace</div>
              <div className='info-value'>
                {!harvest.backbone_replace ? 'No' : 'Yes'}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>Lights and IDs in place</div>
              <div className='info-value'>
                {!harvest.lights_ids_in_place ? 'No' : 'Yes'}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>Flotation on farm</div>
              <div className='info-value'>
                {!harvest.flotation_on_farm ? 'No' : 'Yes'}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>
                {translate(lang, 'Number of rope bags')}
              </div>
              <div className='info-value'>{harvest.rope_bags_quantity}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Product left on line')}
              </div>
              <div className='info-value'>{harvest.product_left_on_line}</div>
            </div>
          </div>

          <div className='mt-7 mb-7'>
            <Input
              label={translate(lang, 'Comment')}
              type='textarea'
              value={harvest.comment ?? ''}
              disabled={true}
            />
          </div>
          {!!harvest.images && harvest.images.length > 0 && (
            <div className='mt-7 mb-7'>
              <PicturesWall
                label={translate(lang, 'Images')}
                fileList={
                  harvest.images.map(x => parseImageUrlToFileItem(x)) as any
                }
                handleChangeImages={() => {}}
                disabled={true}
              />
            </div>
          )}
        </div>
        {!!harvest.signature && (
          <div className='pb-20'>
            <p className='mb-4 d-block paragrapgh paragrapgh--2 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
              {translate(lang, 'Signature')}
            </p>
            <div
              style={{
                border: '1px solid #EFEFEF',
                borderRadius: '4px',
              }}
            >
              <img
                style={{ width: '100%' }}
                src={harvest.signature}
                alt='signature'
              />
            </div>
          </div>
        )}
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MusselHarvestViewModal;
