import { useDispatch, useSelector } from 'react-redux';
import { IHarvestResource } from '../../entities/growing.entities';
import { useState } from 'react';
import { TrackStatus } from '../../entities/general.entities';
import { formatNumber } from '../../entities/util-functions';
import { showFeedback } from '../../store/ui/ui.actions';
import { sendSingleRequest } from '../../apis';
import { Modal } from 'antd';
import { Button, CloseIcon, Dropdown, Input, Subtitle } from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { toSecond } from '../../util/toggleSecondMillisecond';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  visible: boolean;
  lineID: number;
  seedingID: number;
  harvests: IHarvestResource[];
  onClose: () => void;
}

const AddTrackStatusModal = ({
  visible,
  lineID,
  seedingID,
  harvests,
  onClose,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [harvestID, setHarvestID] = useState<number>();
  const [code, setCode] = useState('');
  const [status, setStatus] = useState(TrackStatus.HARVESTED);
  const [loading, setLoading] = useState(false);

  const harvestOptions = [
    { id: 'undefined', value: '', label: translate(lang, 'Unconfirmed') },
    ...harvests.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: `${x.id} - ${formatNumber(x.amount)} kg`,
    })),
  ];
  const statusOptions = [
    TrackStatus.HARVESTED,
    TrackStatus.OFFLOADED_FROM_BOAT,
    TrackStatus.LOADED_TO_COLD_STORAGE,
  ].map(x => ({ id: x, value: x, label: x }));

  const setError = (message: string) =>
    dispatch(showFeedback({ isMessageModal: true, message, type: 'error' }));

  const confirmClick = async () => {
    if (harvests?.some(x => x.is_full) && !harvestID) {
      setError('Please select Harvest ID');
      return;
    }
    if (!/^[a-f0-9]{32}$/.test(code)) {
      setError('Invalid QR code value');
      return;
    }
    setLoading(true);
    let response = await sendSingleRequest(
      {
        track_id: code,
        data: [
          { line_id: lineID, seeding_id: seedingID, harvest_id: harvestID },
        ],
      },
      'POST',
      'api/task/track-lines',
      true,
    );
    if (!response.status) {
      setLoading(false);
      setError(response.data?.message ?? 'Error adding track line');
      return;
    }
    response = await sendSingleRequest(
      { track_id: code, status, tracked_time: toSecond(Date.now()) },
      'POST',
      'api/task/track-status',
      true,
    );
    setLoading(false);
    if (!response.status) {
      setError(response.data?.message ?? 'Error adding track status');
      return;
    }
    dispatch(
      showFeedback({
        isMessage: true,
        message: translate(lang, 'QR code added successfully'),
        type: 'success',
      }),
    );
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      footer={null}
    >
      <div className='section-modal wrap'>
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Add Scanned QR Track')}
          </Subtitle>
        </div>
        <div className='section'>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Harvest ID')}
              options={harvestOptions}
              value={harvestID?.toString()}
              onChange={v =>
                setHarvestID(v.length <= 0 ? undefined : Number(v))
              }
            />
          </div>
          <div className='mb-17'>
            <Input
              type='text'
              label={translate(lang, 'Track Code Value')}
              value={code}
              onChange={e => setCode(e.target.value)}
            />
          </div>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Status')}
              options={statusOptions}
              value={status}
              onChange={v => setStatus(v)}
            />
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            className='rsp-btn'
            size={2}
            type='fill'
            color='red'
            onClick={onClose}
            disabled={loading}
          >
            {translate(lang, 'Close')}
          </Button>
          <Button
            width={'small'}
            className='rsp-btn ml-16'
            size={2}
            type='fill'
            color='green'
            onClick={confirmClick}
            disabled={loading}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default AddTrackStatusModal;
