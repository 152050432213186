import { FormEvent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import {
  Input,
  Title,
  Button,
  AvatarComponent,
  CameraIcon,
  Spinner,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import {
  demoAccountLogin,
  exitDemoAccount,
  updateAvatar,
  updateProfile,
} from '../../store/auth/auth.actions';
import { selectIsDemo, selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const FieldValidators = [
  {
    name: 'fullName',
    isValidate: true,
  },
  {
    name: 'phone',
    isValidate: true,
  },
  {
    name: 'companyName',
    isValidate: true,
  },
  {
    name: 'companyAddress',
    isValidate: true,
  },
];

interface IForm {
  name: string;
  phone_number: string;
  company_name: string;
  company_address: string;
  avatar: string;
}

const ProfilePage = () => {
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const profile = useSelector(selectProfile);
  const isDemo = useSelector(selectIsDemo);
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isValidFields, setIsValidFields] = useState<boolean>();
  const [fieldsValid, setFieldsValid] = useState(FieldValidators);

  const handleOnValidFields = (value: boolean, data: string | undefined) => {
    let counter = 0;
    const newArr = fieldsValid.map(field => {
      /* eslint-disable*/
      if (field.name === data) {
        if (value) counter++;
        return { ...field, isValidate: value };
      }
      if (field.name !== data && field.isValidate) counter++;
      return field;
    });

    setFieldsValid(newArr);
    setIsValidFields(fieldsValid.length === counter || false);
  };

  const [user, setUser] = useState<IForm>({
    name: profile?.name ?? '',
    phone_number: profile?.phone_number ?? '',
    company_name: profile?.company_name ?? '',
    company_address: profile?.company_address ?? '',
    avatar: profile?.avatar ?? '',
  });

  const handleOnAvatar = async (e: any) => {
    setIsSpinner(true);
    const data = new FormData();
    data.append('image', e.file);
    await dispatch(updateAvatar(data));
    setIsSpinner(false);
  };

  const handleOnSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    await dispatch(updateProfile(user));
    setDisabled(false);
  };

  const checkPhoneNumber = (e: any) => {
    let phoneNumber = e.target.value
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (e.target.value !== '') {
      phoneNumber = !phoneNumber[3]
        ? `+${phoneNumber[1]}${phoneNumber[2] ? ` ${phoneNumber[2]}` : ''}`
        : `+${phoneNumber[1]} (${phoneNumber[2]}) ${phoneNumber[3]}${
            phoneNumber[4] ? `-${phoneNumber[4]}` : ''
          }`;
    } else {
      phoneNumber = '';
    }

    setUser({ ...user, phone_number: phoneNumber } as any);
  };
  const viewDemoClick = async () => {
    setDisabled(true);
    if (isDemo) {
      await dispatch(exitDemoAccount());
    } else {
      await dispatch(demoAccountLogin());
    }
    setDisabled(false);
  };

  useEffect(() => {
    setUser({
      name: profile?.name ?? '',
      phone_number: profile?.phone_number ?? '',
      company_name: profile?.company_name ?? '',
      company_address: profile?.company_address ?? '',
      avatar: profile?.avatar ?? '',
    });
  }, [profile]);

  return (
    <>
      <div className='content pb-32'>
        <div className='d-flex mb-24 align-items-center justify-content-between'>
          {width > 768 && (
            <Title size={5} color='black-3' align='default' fontWeight={600}>
              {translate(lang, 'Profile')}
            </Title>
          )}
          <Button
            size={0}
            type='fill'
            width='small'
            color='green'
            disabled={disabled}
            onClick={viewDemoClick}
          >
            {translate(lang, isDemo ? 'Exit Demo' : 'View Demo')}
          </Button>
        </div>
        <div className='d-flex align-items-center mb-32'>
          {isSpinner ? (
            <div className='m-auto mt-12'>
              <Spinner />
            </div>
          ) : (
            <>
              <AvatarComponent
                size={width < 769 ? 64 : 72}
                image={user?.avatar ?? undefined}
              />
              <ImgCrop>
                <Upload
                  customRequest={(e: any) => handleOnAvatar(e)}
                  fileList={[]}
                  listType='picture-card'
                  accept='image/x-png,image/gif,image/jpeg'
                >
                  <label htmlFor='upload-avatar' className='upload-button'>
                    <CameraIcon />
                    <span className='upload-button__text'>
                      {translate(lang, 'Upload photo')}
                    </span>
                  </label>
                </Upload>
              </ImgCrop>
            </>
          )}
        </div>
        <div className='d-flex mb-16 align-items-center justify-content-between'>
          <Title size={6} color='black' align='default' fontWeight={600}>
            {translate(lang, 'General info')}
          </Title>
        </div>
        <form onSubmit={handleOnSave}>
          <Input
            label={translate(lang, 'Full Name')}
            type='text'
            className='mb-16'
            onChange={e => setUser({ ...user, name: e.target.value } as any)}
            value={user?.name ? user.name : ''}
            placeholder=''
            dataType='fullName'
            max={255}
            required
            onValidate={(e, data) => handleOnValidFields(e, data)}
          />
          <Input
            label={translate(lang, 'Phone')}
            type='text'
            className='mb-16'
            onChange={e => checkPhoneNumber(e)}
            value={user?.phone_number ? user.phone_number : ''}
            dataType='phone'
            min={17}
            placeholder=''
            onValidate={(e, data) => handleOnValidFields(e, data)}
          />
          <Input
            label={translate(lang, 'Company Name')}
            type='text'
            className='mb-16'
            onChange={e =>
              setUser({ ...user, company_name: e.target.value } as any)
            }
            value={user?.company_name ? user.company_name : ''}
            dataType='companyName'
            max={255}
            placeholder=''
            onValidate={(e, data) => handleOnValidFields(e, data)}
          />
          <Input
            label={translate(lang, 'Company Address')}
            type='text'
            onChange={e =>
              setUser({ ...user, company_address: e.target.value } as any)
            }
            value={user?.company_address ? user.company_address : ''}
            dataType='companyAddress'
            max={255}
            placeholder=''
            onValidate={(e, data) => handleOnValidFields(e, data)}
          />
          <Button
            className='mt-16'
            color='blue'
            size={1}
            width={width < 769 ? 'wide' : 'small'}
            type='fill'
            disabled={disabled || !isValidFields}
          >
            <span>{translate(lang, 'Save')}</span>
          </Button>
        </form>
      </div>
    </>
  );
};

export default ProfilePage;
