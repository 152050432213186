import { useState } from 'react';
import { DatePicker, Modal, Radio } from 'antd';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import { IMusselLineResource } from '../../../entities/farms.entities';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Dropdown,
  Input,
  RadioButton,
  Subtitle,
} from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { sendSingleRequest } from '../../../apis';
import { showFeedback } from '../../../store/ui/ui.actions';
import { ICustomValue } from '../../../entities/automation.entities';
import { Link } from 'react-router-dom';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import {
  SelectInventoriesByFarmID,
  SelectIsBudgetVisible,
  SelectSeedingFields,
} from '../../../store/extra/extra.selector';
import moment from 'moment';
import { inventoryLabel } from '../../../lib/farm.helpers';
const { RangePicker } = DatePicker;

interface IFormData {
  line_length: number | null;
  spat_size: number | null;
  spat_size_max: number | null;
  density?: number;
  density_max?: number | null;
  density_unit: 'spat per m' | 'kgs per m';
  drop?: number;
  submersion?: number;
  spacing?: number;
  comment: string | null;
  images: any[];
  time_tracks?: ITimeRange[];
  cost?: number | null;
  custom_values?: ICustomValue[];
}

const defaultForm: IFormData = {
  line_length: null,
  drop: 0,
  submersion: 0,
  spacing: 0,
  spat_size: 0,
  spat_size_max: null,
  density: 0,
  density_max: null,
  density_unit: 'spat per m',
  comment: null,
  images: [],
  time_tracks: [],
};

interface Props {
  visible: boolean;
  title: string;
  lineData: IMusselLineResource;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  data?: any;
  overConfirm?: (data: any) => Promise<any>;
}

const MusselCatchSpatModal = ({
  visible,
  title,
  lineData,
  onCancel,
  onConfirm,
  data: paramData,
  overConfirm,
}: Props) => {
  const dispatch = useDispatch<any>();

  const showBudget = SelectIsBudgetVisible(lineData.farm_id);
  const customFields = SelectSeedingFields(lineData.farm_id);
  const inventories = SelectInventoriesByFarmID(lineData.farm_id);
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState(
    paramData && paramData.planned_date_seed
      ? toMillisecond(paramData.planned_date_seed)
      : 0,
  );
  const [endDate, setEndDate] = useState(
    paramData && paramData.planned_date_harvest
      ? toMillisecond(paramData.planned_date_harvest)
      : 0,
  );
  const [error, setError] = useState<string | null>(null);
  const [fieldData, setFieldData] = useState<IFormData>(
    paramData
      ? {
          ...defaultForm,
          ...paramData,
          time_tracks: paramData.time_tracks
            ? paramData.time_tracks.map((x: any) => ({
                start_time: toMillisecond(x.start_time),
                finish_time: toMillisecond(x.finish_time),
              }))
            : [],
        }
      : {
          ...defaultForm,
          line_length: lineData.length,
        },
  );
  const [addDensityRange, setAddDensityRange] = useState(
    paramData?.density_max ? true : false,
  );
  const [addSpatSizeRange, setAddSpatSizeRange] = useState(
    paramData?.spat_size_max ? true : false,
  );
  const [customValues, setCustomValues] = useState(
    customFields.map(x => {
      const d =
        paramData && paramData.custom_values
          ? paramData.custom_values.find(
              (y: ICustomValue) => y.field_id === x.id,
            )
          : null;
      return {
        input_type: x.input_type,
        field_label: x.name,
        options: x.options
          ?.split(',')
          .map(t => ({ id: t, label: t, value: t })),
        field_id: x.id,
        field_val: d?.field_val ?? '',
        data_type: x.data_type,
        unit: x.unit,
      };
    }),
  );
  const [pickedInventories, setPickedInventories] = useState<any[]>(
    paramData?.picked_inventories ?? [],
  );

  const fieldValid = () => {
    if (startDate === 0 || endDate === 0) {
      setError(translate(lang, 'Please select range'));
      return null;
    }
    if (
      fieldData.spat_size &&
      fieldData.spat_size_max !== null &&
      fieldData.spat_size > fieldData.spat_size_max
    ) {
      setError(translate(lang, 'Invalid spat size range'));
      return null;
    }
    if (
      fieldData.density_max &&
      fieldData.density &&
      fieldData.density > fieldData.density_max
    ) {
      setError(translate(lang, 'Invalid density range'));
      return null;
    }
    if (!fieldData.line_length) {
      setError(translate(lang, 'Catch spat length should not be empty'));
      return null;
    }
    for (let px of pickedInventories) {
      const ivt = inventories.find(x => x.id === px.id);
      if (!ivt) {
        setError(translate(lang, 'Inventory is not found'));
        return null;
      }
      if (isNaN(Number(px.quantity)) || Number(px.quantity) <= 0) {
        setError(translate(lang, '_invalid_ivt_qty', ivt.name ?? ''));
        return null;
      }
      if (Number(px.quantity) > ivt.available_quantity) {
        setError(
          translate(
            lang,
            '_seed_floats_over',
            px.quantity,
            ivt.name ?? '',
            ivt.available_quantity,
          ),
        );
        return null;
      }
    }
    let res = {
      planned_date_seed: toSecond(startDate),
      planned_date_harvest: toSecond(endDate),
      line_id: lineData.id,
      ...fieldData,
      picked_inventories:
        pickedInventories.length > 0 ? pickedInventories : undefined,
      is_catch_spat: true,
    };
    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        setError(translate(lang, 'Invalid time tracks information'));
        return null;
      }
      res.time_tracks = tt;
    }
    const cvs = customValues
      .filter(x => !!x.field_val)
      .map(({ field_id, field_val }) => ({ field_id, field_val }));
    if (cvs.length > 0) {
      res.custom_values = cvs;
    }
    return res;
  };
  const onRangeChange = (dates: any, dateStrings: any) => {
    setStartDate(dates[0].toDate().getTime());
    setEndDate(dates[1].toDate().getTime());
    setError(null);
  };
  const updateField = (key: keyof IFormData, val: any) => {
    if (['spacing', 'density'].includes(key)) {
      val = val.length <= 0 ? null : Number(val);
    }
    setFieldData({ ...fieldData, [key]: val });
  };
  const toggleAddRangeCheck = (type: 'DENSITY' | 'SPAT', val: boolean) => {
    if (type === 'DENSITY') {
      setAddDensityRange(val);
      if (val) updateField('density_max', fieldData.density);
      else updateField('density_max', null);
    } else {
      setAddSpatSizeRange(val);
      if (val) updateField('spat_size_max', fieldData.spat_size);
      else updateField('spat_size_max', null);
    }
  };
  const selectInventoryIds = (val: string[]) => {
    let pArr = val.map(x => ({ id: parseInt(x), quantity: '' }));
    for (let p of pickedInventories) {
      let i = pArr.findIndex(x => x.id === p.id);
      if (i > -1) pArr[i].quantity = p.quantity;
    }
    setPickedInventories(pArr);
  };
  const updateInventoryQuantity = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      value,
      dataset: { type },
    } = event.target;
    let pickedArr = [...pickedInventories];
    pickedArr[parseInt(type ?? '0')].quantity = value;
    setPickedInventories(pickedArr);
  };
  const handleConfirmClick = async () => {
    const form = fieldValid();
    if (!form) return;

    setDisabled(true);
    if (!!overConfirm) {
      const ovc = await overConfirm(form);
      if (ovc !== true) {
        setDisabled(false);
        if (ovc.message) {
          dispatch(
            showFeedback({
              isMessageModal: true,
              type: 'error',
              message: translate(lang, ovc.message),
            }),
          );
        }
      }
      return;
    }
    const res = await sendSingleRequest(
      form,
      'POST',
      'api/farm/line/catch-spat',
      true,
    );
    if (res.status) {
      dispatch(loadFarmsData());
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
    } else {
      setError(translate(lang, res.data?.message ?? 'Request failed'));
      setDisabled(false);
    }
  };
  const updateCustomValue = (fieldID: number, val: string) => {
    let tmp = [...customValues];
    const i = tmp.findIndex(x => x.field_id === fieldID);
    if (i >= 0) {
      tmp[i].field_val = val;
    }
    setCustomValues(tmp);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {title}
          </Subtitle>
        </div>
        <div>
          <label>{translate(lang, '_catch_spat_range')}</label>
          <div className='mt-16 mb-32'>
            <RangePicker
              defaultValue={
                startDate && endDate
                  ? [moment(startDate), moment(endDate)]
                  : undefined
              }
              onChange={onRangeChange}
              format={'DD/MM/yyyy'}
            />
          </div>
          {error && (
            <div
              className='invalid-form'
              style={{ fontSize: '15px', textAlign: 'center' }}
            >
              <div className='invalid-feedback'>{error}</div>
            </div>
          )}
          {fieldData.time_tracks && (
            <TimeTrackInput
              data={fieldData.time_tracks}
              onChange={t => updateField('time_tracks', t)}
            />
          )}
          <div className='mb-16'>
            <Input
              label={translate(lang, 'Catch spat length')}
              type='number'
              value={fieldData.line_length?.toString() ?? ''}
              dataType='line_length'
              onChange={e => updateField('line_length', Number(e.target.value))}
              required
              unit='m'
            />
          </div>
          <div className='mb-16'>
            <Input
              label={translate(lang, 'Drop')}
              type='number'
              dataType='drop'
              value={fieldData.drop?.toString() ?? ''}
              unit='m'
              required
              onChange={e => updateField('drop', Number(e.target.value))}
            />
          </div>
          <div className='mb-7'>
            <CheckboxButton
              label={translate(lang, 'Add spat size range')}
              checked={addSpatSizeRange}
              onChange={e => toggleAddRangeCheck('SPAT', e.target.checked)}
            />
          </div>
          {fieldData.spat_size_max === null ? (
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Spat Size')}
                type='number'
                value={fieldData.spat_size?.toString() ?? ''}
                dataType='spat_size'
                onChange={e => updateField('spat_size', Number(e.target.value))}
                unit='mm'
                required
              />
            </div>
          ) : (
            <div className='d-flex mb-17 justify-content-between'>
              <div className={`mr-12 w-100`}>
                <Input
                  label={translate(lang, 'Minimum spat size')}
                  type='number'
                  value={fieldData.spat_size?.toString() ?? ''}
                  required
                  onChange={e =>
                    updateField('spat_size', Number(e.target.value))
                  }
                />
              </div>
              <div className='ml-12 w-100'>
                <Input
                  label={translate(lang, 'Maximum spat size')}
                  type='number'
                  value={fieldData.spat_size_max.toString()}
                  required
                  onChange={e =>
                    updateField('spat_size_max', Number(e.target.value))
                  }
                />
              </div>
            </div>
          )}
          <div className='d-flex pb-17'>
            <div className='mr-12 w-100'>
              <Input
                label={translate(lang, 'Submersion')}
                value={fieldData.submersion?.toString() ?? ''}
                dataType='submersion'
                type='number'
                unit='m'
                required
                onChange={e =>
                  updateField('submersion', Number(e.target.value))
                }
              />
            </div>
            <div className='ml-12 w-100'>
              <Input
                label={translate(lang, 'Spacing')}
                type='number'
                value={fieldData.spacing?.toString() ?? ''}
                dataType='spacing'
                unit='mm'
                required
                onChange={e => updateField('spacing', Number(e.target.value))}
              />
            </div>
          </div>
          <div className='d-flex mt-17 pb-7 justify-content-between'>
            <div className='mr-17'>
              <CheckboxButton
                label={translate(lang, 'Add density range')}
                checked={addDensityRange}
                onChange={e => toggleAddRangeCheck('DENSITY', e.target.checked)}
              />
            </div>
            <Radio.Group
              className='d-flex align-items-center'
              onChange={e => updateField('density_unit', e.target.value)}
              value={fieldData.density_unit}
            >
              <RadioButton label='spat per m' value='spat per m' />
              <RadioButton
                className='ml-17'
                label='kgs per m'
                value='kgs per m'
              />
            </Radio.Group>
          </div>
          <div className='d-flex pb-17'>
            <div className='w-100'>
              {fieldData.density_max === null ? (
                <Input
                  label={translate(lang, 'Density')}
                  type='number'
                  value={fieldData.density?.toString() ?? ''}
                  dataType='density'
                  required
                  onChange={e => updateField('density', Number(e.target.value))}
                  unit={fieldData.density_unit}
                />
              ) : (
                <div className='d-flex justify-content-between'>
                  <div className={`mr-12 w-100`}>
                    <Input
                      label={translate(lang, 'Density Min')}
                      type='number'
                      value={fieldData.density?.toString() ?? ''}
                      required
                      onChange={e =>
                        updateField('density', Number(e.target.value))
                      }
                      unit={fieldData.density_unit}
                    />
                  </div>
                  <div className='ml-12 w-100'>
                    <Input
                      label={translate(lang, 'Density Max')}
                      type='number'
                      value={fieldData.density_max?.toString() ?? ''}
                      required
                      onChange={e =>
                        updateField('density_max', Number(e.target.value))
                      }
                      unit={fieldData.density_unit}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {customValues.map(x => (
            <div key={x.field_id} className='mb-17'>
              {x.input_type === 'select' ? (
                <>
                  <Dropdown
                    label={x.field_label}
                    options={x.options ?? []}
                    value={x.field_val}
                    onChange={v => updateCustomValue(x.field_id, v)}
                  />
                  {(!x.options || x.options.length <= 0) && (
                    <Link to={'/custom-fields'}>
                      <span style={{ color: '#008ed1' }}>
                        {translate(lang, 'Set up field')}
                      </span>
                    </Link>
                  )}
                </>
              ) : (
                <Input
                  type={x.data_type === 'number' ? 'number' : 'text'}
                  label={x.field_label}
                  value={x.field_val}
                  onChange={e => updateCustomValue(x.field_id, e.target.value)}
                  unit={x.unit}
                />
              )}
            </div>
          ))}
          {!!showBudget && (
            <div className='mb-17'>
              <Input
                type='number'
                value={fieldData.cost?.toString() ?? ''}
                label={translate(lang, 'Cost')}
                dataType='cost'
                unit='$'
                onChange={e =>
                  updateField(
                    'cost',
                    e.target.value.length <= 0
                      ? undefined
                      : Number(e.target.value),
                  )
                }
              />
            </div>
          )}
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Select inventory')}
              placeholder={translate(lang, 'Select inventory')}
              mode='multiple'
              options={inventories.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: inventoryLabel(x),
              }))}
              value={pickedInventories.map(x => x.id.toString()) as any}
              onChange={(val: any) => selectInventoryIds(val)}
            />
          </div>
          {pickedInventories.length > 0 && (
            <div className='mb-17'>
              {pickedInventories.map((item, idx) => (
                <Input
                  className='mb-15'
                  key={item.id}
                  type='number'
                  label={`${inventories.find(t => t.id === item.id)?.name} - $${
                    inventories.find(t => t.id === item.id)?.price
                  }`}
                  placeholder={translate(
                    lang,
                    'Maximum value is %s',
                    inventories.find(t => t.id === item.id)
                      ?.available_quantity ?? '',
                  )}
                  value={item.quantity?.toString() ?? ''}
                  dataType={idx.toString()}
                  onChange={updateInventoryQuantity}
                  max={
                    inventories.find(t => t.id === item.id)?.available_quantity
                  }
                />
              ))}
            </div>
          )}
          <div className='mt-17 mb-17'>
            <Input
              label={translate(lang, 'Comment')}
              placeholder={translate(lang, 'Comment')}
              type='textarea'
              value={fieldData.comment ?? ''}
              onChange={e => updateField('comment', e.target.value)}
            />
          </div>
          <div className='mb-17'>
            <PicturesWall
              label={translate(lang, 'Images')}
              fileList={fieldData.images}
              handleChangeImages={v => updateField('images', v)}
            />
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default MusselCatchSpatModal;
