import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { Button, CloseIcon, Input, Subtitle } from '../../components/shared';
import { showFeedback } from '../../store/ui/ui.actions';
import { useWidth } from '../../util/useWidth';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IForm {
  name: string;
  reg_number: string;
}

interface Props {
  visible: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (d: any) => void;
  isAdding: boolean;
  data?: any;
  className?: string;
}

const BoatModal = ({
  visible,
  title,
  onClose,
  onConfirm,
  isAdding,
  data,
  className,
}: Props) => {
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IForm>({ name: '', reg_number: '' });

  const updateForm = (key: keyof IForm, val: string) =>
    setForm(p => ({ ...p, [key]: val }));

  const handleConfirm = () => {
    if (form.name.length <= 0) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: 'Name is required field',
        }),
      );
      return;
    }
    if (form.reg_number.length <= 0) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: 'Registration is required',
        }),
      );
      return;
    }
    setDisabled(true);
    if (isAdding) {
      sendSingleRequest(form, 'POST', 'api/boat/boat', true).then(r => {
        setDisabled(false);
        if (r.status) {
          onConfirm(r.data);
        } else {
          dispatch(
            showFeedback({
              isMessageModal: true,
              type: 'error',
              message: r.data?.message ?? 'Something went wrong',
            }),
          );
        }
      });
    } else {
      sendSingleRequest(form, 'PUT', `api/boat/boat/${data?.id}`, true).then(
        r => {
          setDisabled(false);
          if (r.status) {
            onConfirm(r.data);
          } else {
            dispatch(
              showFeedback({
                isMessageModal: true,
                type: 'error',
                message: r.data?.message ?? 'Something went wrong',
              }),
            );
          }
        },
      );
    }
  };

  useEffect(() => data && setForm(p => ({ ...p, ...data })), [data]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        <div className='pt-16'>
          <div className='pb-24'>
            <Input
              label={translate(lang, 'Boat Name')}
              type='text'
              placeholder=''
              value={form.name}
              onChange={e => updateForm('name', e.target.value)}
            />
          </div>
          <div className='pb-24'>
            <Input
              label={translate(lang, 'Registration')}
              type='text'
              placeholder=''
              value={form.reg_number}
              onChange={e => updateForm('reg_number', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='red'
          onClick={onClose}
          disabled={disabled}
        >
          {translate(lang, 'Cancel')}
        </Button>
        <Button
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='green'
          className='ml-16'
          onClick={handleConfirm}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default BoatModal;
