import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FarmLineInfoModal from '../../components/farm-modals/FarmLineInfoModal';
import {
  BreadcrumbComponent,
  Button,
  InputModal,
  Spinner,
  Subtitle,
  Title,
} from '../../components/shared';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { IMusselLineResource } from '../../entities/farms.entities';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import NotFound from '../static/NotFound';
import { selectMusselFarms } from '../../store/farms/farms.selector';
import { selectUserMeta } from '../../store/auth/auth.selector';
import {
  getLineStatus,
  updateLineColumn,
  updateLinePriority,
} from '../../lib/farm.helpers';
import { selectLang } from '../../store/ui/ui.selector';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

interface LTProps {
  line: IMusselLineResource;
  groupId: number;
  moveGroup: (i: number, g: number) => void;
  moveOrder: (i: number, g: number) => void;
  showModal: (x: any) => void;
  width?: number;
}

const LineTr = ({
  line,
  moveGroup,
  moveOrder,
  groupId,
  showModal,
  width = 100,
}: LTProps) => {
  const userMeta = useSelector(selectUserMeta);
  const [over, setOver] = useState(false);

  const lineStatus = getLineStatus(line, userMeta?.line_assess_expire_days);
  const color = `--${lineStatus.toLowerCase()}`;

  const handleClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();
    showModal(line);
  };
  const onDragStartEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('line_id', line.id.toString());
    event.dataTransfer.setData('group_id', groupId.toString());
  };
  const onDropEvent = (event: React.DragEvent<HTMLDivElement>) => {
    const line_id = parseInt(event.dataTransfer.getData('line_id'));
    const group_id = parseInt(event.dataTransfer.getData('group_id'));
    if (line_id !== line.id) {
      moveOrder(line_id, parseInt(line.id.toString()));
    }
    if (group_id !== groupId) {
      moveGroup(line_id, groupId);
    }
    setOver(false);
  };
  const onDragOverEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const line_id = parseInt(event.dataTransfer.getData('line_id'));
    if (line_id !== line.id) {
      setOver(true);
    }
  };
  const onDragLeaveEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOver(false);
  };

  return (
    <div
      className={`visual-line-item ${over ? 'over' : ''}`}
      draggable={true}
      onDragStart={onDragStartEvent}
      onDragOver={onDragOverEvent}
      onDragLeave={onDragLeaveEvent}
      onDrop={onDropEvent}
    >
      <div className='visual-line-label'>{line.line_name}</div>
      <div className='visual-line-line'>
        <div
          className='visual-line-outer'
          onClick={handleClick}
          style={{ width: `${width ? Math.max(width, 5) : width}%` }}
        >
          <div className={`visual-line-inner ${color}`}></div>
        </div>
      </div>
    </div>
  );
};

interface VLProps {
  groupId: number;
  lines: IMusselLineResource[];
  width?: number;
  moveGroup: (i: number, g: number) => void;
  moveOrder: (i: number, p: number) => void;
  showModal: (x: any) => void;
  maxW?: number;
}

const VisualLinesGroup = ({
  groupId,
  lines,
  width = 500,
  moveGroup,
  moveOrder,
  showModal,
  maxW = 100,
}: VLProps) => {
  const onDragOverEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const onDropEvent = (event: React.DragEvent<HTMLDivElement>) => {
    const line_id = parseInt(event.dataTransfer.getData('line_id'));
    const group_id = parseInt(event.dataTransfer.getData('group_id'));
    if (group_id !== groupId) {
      moveGroup(line_id, groupId);
    }
  };

  return (
    <div
      className='visual-lines-group'
      style={{ width: `${width}px`, minWidth: `${width}px` }}
      onDragOver={onDragOverEvent}
      onDrop={onDropEvent}
    >
      <Title
        size={6}
        className='text-center'
        color='black-3'
        align='default'
        fontWeight={700}
      >
        {String.fromCharCode(65 + groupId)}
      </Title>
      {lines.map(line => (
        <LineTr
          groupId={groupId}
          moveGroup={moveGroup}
          moveOrder={moveOrder}
          key={line.id}
          line={line}
          showModal={showModal}
          width={(Number(line.backbone ?? 0) / maxW) * 100}
        />
      ))}
    </div>
  );
};

const NewGroup = ({
  moveGroup,
  lang,
}: {
  moveGroup: (i: number, g: number) => void;
  lang: TLang | undefined;
}) => {
  const onDragOverEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const onDropEvent = (event: React.DragEvent<HTMLDivElement>) => {
    const line_id = parseInt(event.dataTransfer.getData('line_id'));
    moveGroup(line_id, -1);
  };

  return (
    <div
      className='new-group'
      onDragOver={onDragOverEvent}
      onDrop={onDropEvent}
    >
      <span>{translate(lang, 'Drop Here')}</span>
    </div>
  );
};

const FarmVisualLinesPage = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const params = useParams<{ idFarm: string }>();
  const farmsData = useSelector(selectMusselFarms);
  const lang = useSelector(selectLang);
  const farmData = farmsData.find(
    t => parseInt(t.id.toString()) === parseInt(params.idFarm),
  );
  const breadcrumbItems = getBreadcrumbMenu('FARM_VISUAL', {
    FARM_ID: params.idFarm,
    FARM_NAME: farmData?.name,
  });
  const [groups, setGroups] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedLine, setSelectedLine] = useState<IMusselLineResource>();
  const { redirectToLine } = useMenuHandler();

  const buildGroups = useCallback(() => {
    if (!farmData?.lines) return;
    let temp: any = [];
    for (let l of farmData.lines) {
      if (!temp[l.col_no]) temp[l.col_no] = [];
      temp[l.col_no].push(l);
    }
    let result: any = [];
    for (let g of temp) {
      if (g && g.length > 0) {
        g.sort((a: any, b: any) =>
          a.priority === b.priority
            ? a.id < b.id
              ? -1
              : 1
            : a.priority > b.priority
            ? -1
            : 1,
        );
        result.push(g);
      }
    }
    setGroups(result);
  }, [farmData?.lines]);

  const moveOrder = (lineId: number, pos: number) => {
    setLoading(true);
    const finish = () => {
      setLoading(false);
      dispatch(loadFarmsData());
    };
    updateLinePriority(lineId, pos).then(finish).catch(finish);
  };
  const moveGroup = (lineId: number, groupId: number) => {
    setLoading(true);
    const finish = () => {
      setLoading(false);
      dispatch(loadFarmsData());
    };
    updateLineColumn(lineId, groupId).then(finish).catch(finish);
  };
  const showModal = (data: any) => {
    setVisible(true);
    setSelectedLine(data);
  };
  const goToDetail = () => {
    if (!selectedLine) return;
    redirectToLine(selectedLine.farm_id.toString(), selectedLine.id.toString());
  };
  const getMaxBackbone = () => {
    let v: number = 0;
    for (let g of groups)
      for (let t of g) v = Math.max(v, Number(t.backbone ?? 0));
    return v;
  };

  useEffect(() => buildGroups(), [buildGroups]);

  if (!farmData || farmData === undefined) {
    return <NotFound />;
  }
  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        {loading && (
          <div className='d-flex justify-content-center align-items-center min-height pos-absolute w-100'>
            <Spinner />
          </div>
        )}
        <div className='farm-visual-lines-page'>
          <div className='farms__header d-flex justify-content-between align-items-center'>
            <BreadcrumbComponent items={breadcrumbItems} />
          </div>
          <div className='farms__header pl-17'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <Title
                  size={5}
                  color='black-3'
                  align='default'
                  fontWeight={700}
                >
                  {farmData.name}
                </Title>
                <Subtitle
                  size={4}
                  color='black'
                  align='default'
                  fontWeight={400}
                >
                  {farmData.farm_number}
                </Subtitle>
              </div>
              <Button
                size={3}
                type='fill'
                width='small'
                color='blue'
                onClick={() => history.push('/farms/visual-mode')}
              >
                {translate(lang, 'View float placement')}
              </Button>
            </div>
          </div>
          <div className='farms__content'>
            <div className='groups-bar'>
              {groups.map((group: any, groupId: number) => (
                <VisualLinesGroup
                  key={groupId}
                  groupId={groupId}
                  lines={group}
                  moveGroup={moveGroup}
                  moveOrder={moveOrder}
                  showModal={showModal}
                  maxW={getMaxBackbone()}
                />
              ))}
              <NewGroup moveGroup={moveGroup} lang={lang} />
            </div>
          </div>
          <div className='farms__footer'>
            <div className='float-right pt-32 pr-32'>
              <div className='legend'>
                <Title
                  size={6}
                  color='black-3'
                  align='default'
                  fontWeight={500}
                >
                  {translate(lang, 'Legend')}
                </Title>
                <div className='legend-item mt-17'>
                  <div className='legend-inner --empty'></div>
                  <div className='legend-label'>{translate(lang, 'Empty')}</div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --ready_harvest'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Ready to harvest')}
                  </div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --require_assessment'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Requires assessment')}
                  </div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --catch_spat'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Catch spat')}
                  </div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --normal'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Growing mussel')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {visible && selectedLine && (
        <InputModal
          title={`Line Detail - ${selectedLine.line_name}`}
          type='confirm'
          visible={visible}
          onCancel={() => setVisible(false)}
          onConfirm={goToDetail}
          confirmNameBtn={translate(lang, 'View Detail')}
        >
          <FarmLineInfoModal lineData={selectedLine} />
        </InputModal>
      )}
    </div>
  );
};

export default FarmVisualLinesPage;
