import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ISeedingResource } from '../../entities/growing.entities';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const seedPropLabel = (d: any) => {
  if (d.line_length !== undefined) {
    return `${d.season_name} ${d.line_length} m`;
  } else if (d.basket_count !== undefined) {
    return `${d.season_name} ${d.basket_count} baskets`;
  } else if (d.tank_area !== undefined) {
    return `${d.season_name} ${d.tank_area} m²`;
  } else {
    return '';
  }
};

interface Props {
  seedData: ISeedingResource;
  onShowSeed: (d: any) => void;
}

const SeedingCell = ({ seedData, onShowSeed }: Props) => {
  const lang = useSelector(selectLang);
  const curFarm = useSelector(selectFarmsData).find(
    x => x.id === seedData.line.farm_id,
  );
  const line = curFarm?.lines.find(x => x.id === seedData.line.id);

  const handleShowClick = (event: any) => {
    event.preventDefault();
    onShowSeed({ line, data: seedData });
  };

  return (
    <div className='--entity --seed' onClick={handleShowClick.bind(this)}>
      <div className='--header'>
        <span>{translate(lang, 'seed')}</span>
      </div>
      <div className='--title'>
        <div>
          {curFarm?.name} - {line?.line_name}
        </div>
        <div className='mt-7'>{seedPropLabel(seedData)}</div>
      </div>
      <div className='--btn-bar'>
        <Link to={`/farms/${curFarm?.id}/${line?.id}`} className='--view'>
          {translate(lang, 'View')}
        </Link>
      </div>
    </div>
  );
};

export default SeedingCell;
