import { useState } from 'react';
import { Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/lib/upload/interface';
import Paragrapgh from '../paragrapgh/Paragrapgh';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../store/ui/ui.selector';
import './styles.scss';
import { translate } from '../../../lib/lang.helper';

interface IFile {
  name: string;
  size: number;
  thumbUrl: string;
  uid: string;
  type: string;
}

interface Props {
  fileList?: UploadFile<any>[];
  label: string;
  onChange: (files: UploadFile<any>[]) => void;
  disabled?: boolean;
}

const PDFWall = ({ fileList, label, onChange, disabled }: Props) => {
  const lang = useSelector(selectLang);
  const [previewFile, setPreviewFile] = useState<IFile>();

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;
    if (file.status === 'removed') {
      onChange([...new Set(fileList?.filter(image => image.uid !== file.uid))]);
    }
  };
  const handleBeforeUpload = (file: RcFile, imgList: RcFile[]) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      const { uid, name, type, size } = file;

      const image = {
        uid,
        name,
        type,
        size,
        thumbUrl: e.target?.result,
      };

      onChange([...new Set(fileList), image as UploadFile]);
    };
    return false;
  };

  return (
    <>
      <Paragrapgh
        className='mb-4 d-block'
        size={2}
        color='black-2'
        align='default'
        fontWeight={400}
      >
        {label}
      </Paragrapgh>
      <Upload
        className='pictures-wall mb-24'
        listType='picture-card'
        fileList={fileList}
        accept='application/pdf'
        onPreview={v => setPreviewFile(v as any)}
        onChange={handleChange}
        beforeUpload={handleBeforeUpload}
        disabled={disabled}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{translate(lang, 'Upload PDF')}</div>
        </div>
      </Upload>
      {previewFile && (
        <Modal
          visible={true}
          title={previewFile.name}
          footer={null}
          onCancel={() => setPreviewFile(undefined)}
          width={700}
        >
          <iframe
            width='100%'
            height='100%'
            style={{ minHeight: '500px' }}
            src={previewFile.thumbUrl}
          />
        </Modal>
      )}
    </>
  );
};

export default PDFWall;
