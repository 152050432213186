import { useState } from 'react';
import { Modal, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  CloseIcon,
  Dropdown,
  Input,
  RadioButton,
  Subtitle,
} from '../../components/shared';
import { loadInventories } from '../../store/inventories/inventories.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { selectFarmingMethods } from '../../store/utils/utils.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { IInventoryResource } from '../../entities/inventory.entities';

interface IForm {
  type: string;
  name: string;
  quantity?: number;
  price?: number;
  level?: number;
  volume?: number | null;
}

interface Props {
  visible: boolean;
  title: string;
  data?: IInventoryResource;
  onConfirm: (data: any) => void;
  onCancel: () => void;
  updateId?: number;
}

const InventoryModal = ({
  visible,
  title,
  data,
  onConfirm,
  onCancel,
  updateId,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const farmingMethods = useSelector(selectFarmingMethods);

  const typeOptions = [
    { id: 'FLOAT', label: 'Float', value: 'FLOAT' },
    { id: 'LONGLINE', label: 'Longline', value: 'LONGLINE' },
    { id: 'TIES', label: 'Ties', value: 'TIES' },
    ...farmingMethods.map(x => ({
      id: x.name,
      label: x.name,
      value: x.name,
    })),
  ];
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IForm>(
    data
      ? {
          type: data.type,
          name: data.name,
          quantity: data.quantity,
          price: data.price,
          level: data.level,
          volume: data.volume,
        }
      : { type: 'FLOAT', name: '' },
  );
  const [errors, setErrors] = useState<{
    type: string | null;
    quantity: string | null;
    name: string | null;
  }>({ type: null, quantity: null, name: null });

  const updateForm = (key: keyof IForm, val: any) => {
    if (['quantity', 'price', 'level', 'volume'].includes(key)) {
      val = val.length <= 0 ? undefined : Number(val);
    }
    setForm({ ...form, [key]: val });
    setErrors({ ...errors, [key]: null });
  };
  const validForm = () => {
    let tmpErr = { ...errors };
    let res = true;
    if (form.type.length <= 0) {
      tmpErr.type = 'Please put type';
      res = false;
    }
    if (
      form.name.length <= 0 &&
      ['FLOAT', 'LONGLINE', 'TIES'].includes(form.type)
    ) {
      tmpErr.name = 'Please put name';
      res = false;
    }
    if (!form.quantity) {
      tmpErr.quantity = 'Please put valid quantity';
      res = false;
    }
    setErrors(tmpErr);
    if (!res) return null;

    return {
      ...form,
      name: form.name ? form.name : form.type,
    };
  };
  const handleConfirmClick = async () => {
    const valid = validForm();
    if (!valid) return;

    setDisabled(true);
    const res = !updateId
      ? await sendSingleRequest(
          valid,
          'POST',
          'api/inventory/inventories',
          true,
        )
      : await sendSingleRequest(
          valid,
          'PUT',
          `api/inventory/inventories/${updateId}`,
          true,
        );
    if (res.status) {
      dispatch(loadInventories());
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
    } else {
      setDisabled(false);
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, res.data?.message ?? 'Unknown error'),
        }),
      );
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <ModalFeedbackView />
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        <div className='spat-storage-modal'>
          <div className={errors.type ? 'invalid-form mb-17' : 'mb-17'}>
            <Dropdown
              label={translate(lang, 'Type')}
              className='mb-17'
              value={form.type}
              options={typeOptions}
              onChange={v => updateForm('type', v)}
            />
            {errors.type && (
              <div className='invalid-feedback'>
                {translate(lang, errors.type)}
              </div>
            )}
          </div>
          <div className={errors.name ? 'invalid-form mb-17' : 'mb-17'}>
            <Input
              label={translate(lang, 'Name')}
              placeholder={
                ['FLOAT', 'LONGLINE', 'TIES'].includes(form.type)
                  ? '150L'
                  : '120mm Tubes'
              }
              type='text'
              value={form.name}
              onChange={e => updateForm('name', e.target.value)}
            />
          </div>
          <div className={`mb-17 ${errors.quantity ? 'invalid-form' : ''}`}>
            <Input
              label={translate(lang, 'Quantity')}
              type='number'
              value={form.quantity?.toString() ?? ''}
              onChange={e => updateForm('quantity', e.target.value)}
            />
            {errors.quantity && (
              <div className='invalid-feedback'>
                {translate(lang, errors.quantity)}
              </div>
            )}
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Price')}
              type='number'
              value={form.price?.toString() ?? ''}
              onChange={e => updateForm('price', e.target.value)}
              unit='$'
            />
          </div>
          <div className='mt-32 mb-17'>
            <Radio.Group
              className='d-flex'
              onChange={e => updateForm('level', Number(e.target.value))}
              value={form.level}
            >
              <RadioButton label={translate(lang, 'Submerged')} value={0} />
              <RadioButton
                className='ml-34'
                label={translate(lang, 'Surface')}
                value={1}
              />
            </Radio.Group>
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Volume')}
              type='number'
              value={form.volume?.toString() ?? ''}
              onChange={e => updateForm('volume', e.target.value)}
              unit='m3'
            />
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default InventoryModal;
