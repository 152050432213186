import React from 'react';

interface Props {
  color?: string;
}

const TrashIcon = ({ color }: Props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.6667 8.66671H21.2334L19.8167 6.30421C19.4549 5.70135 18.8031 5.33275 18.1 5.33337H13.9C13.1969 5.33275 12.5452 5.70135 12.1834 6.30421L10.7667 8.66671H7.33335C6.96516 8.66671 6.66669 8.96519 6.66669 9.33337V10C6.66669 10.3682 6.96516 10.6667 7.33335 10.6667H8.00002L8.88335 24.7917C8.94935 25.8456 9.82325 26.6666 10.8792 26.6667H21.1209C22.1768 26.6666 23.0507 25.8456 23.1167 24.7917L24 10.6667H24.6667C25.0349 10.6667 25.3334 10.3682 25.3334 10V9.33337C25.3334 8.96519 25.0349 8.66671 24.6667 8.66671ZM13.9 7.33337H18.1L18.9 8.66671H13.1L13.9 7.33337ZM21.1209 24.6667H10.8796L10.0046 10.6667H21.9963L21.1209 24.6667Z'
      fill={color ?? '#131523'}
    />
  </svg>
);

export default TrashIcon;
