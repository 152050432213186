import React from 'react';

export default () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.62 6.60788L9.39989 14.828L6.37952 11.8076C6.23308 11.6612 5.99564 11.6612 5.84917 11.8076L4.9653 12.6915C4.81886 12.8379 4.81886 13.0754 4.9653 13.2218L9.1347 17.3912C9.28114 17.5377 9.51858 17.5377 9.66505 17.3912L19.0342 8.02209C19.1806 7.87566 19.1806 7.63822 19.0342 7.49175L18.1503 6.60788C18.0039 6.46144 17.7665 6.46144 17.62 6.60788Z'
      fill='white'
    />
  </svg>
);
