import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BreadcrumbComponent,
  Button,
  Caret,
  DropdownMenu,
  Pen,
  PositiveNegativeTitle,
  Subtitle,
  Title,
} from '../../components/shared';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { IMusselLineDetail } from '../../entities/farms.entities';
import prevIcon from '../../images/prev-image.png';
import nextIcon from '../../images/next-image.png';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import LineSpatModal from '../../components/lines/LineSpatModal';
import { Spin } from 'antd';
import {
  customSpecialValue,
  formatNumber,
} from '../../entities/util-functions';
import LineTagButton from '../../components/lines/LineTagButton';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import { IMusselCycle, IMusselHarvest } from '../../entities/growing.entities';
import { amountDays } from '../../lib/farm.helpers';
import {
  LineInventoriesLabel,
  SeedInventoriesLabel,
} from '../../components/CommonViews';
import HarvestPredictButton from '../../components/lines/HarvestPredictButton';
import SeedingTrackModal from '../../components/track-modals/SeedingTrackModal';
import AddTrackStatusModal from '../../components/track-modals/AddTrackStatusModal';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  selectAllFarms,
  SelectAssessFields,
  SelectIsEditable,
  SelectIsFarmable,
  SelectIsGrower,
} from '../../store/extra/extra.selector';
import { labelNumber, labelRange } from '../../lib/common.helpers';

interface Props {
  lineData: IMusselLineDetail;
  loadLine: () => void;
  cyclePart: IMusselCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup: () => void;
  onClickNextGroup: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onAssessmentClick: () => void;
  onCatchSpatClick: () => void;
  onHarvestClick: () => void;
  onMaintenanceClick: () => void;
  onFloatingManageClick: () => void;
  onExtraSeedClick: () => void;
  checkedHistoryView: number;
  onHistoryViewClick: () => void;
}

const MusselLineDesktop = ({
  lineData,
  loadLine,
  cyclePart,
  onClickPrevGroup,
  onClickNextGroup,
  isPrevEnabled,
  isNextEnabled,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onAssessmentClick,
  onCatchSpatClick,
  onHarvestClick,
  onMaintenanceClick,
  onFloatingManageClick,
  onExtraSeedClick,
  checkedHistoryView,
  onHistoryViewClick,
}: Props) => {
  const lang = useSelector(selectLang);
  const isEditable = SelectIsEditable(lineData.farm_id);
  const isGrower = SelectIsGrower(lineData.farm_id);
  const isFarmable = SelectIsFarmable(lineData.farm_id);
  const { redirectToLine } = useMenuHandler();
  const curFarm = useSelector(selectAllFarms).find(
    x => x.id === lineData.farm_id,
  );
  const lines = curFarm?.lines ?? [];
  const mainSeed = cyclePart?.main_seed;
  const lastAssessment =
    cyclePart && cyclePart.assessments.length > 0
      ? cyclePart?.assessments.reduce((p, c) =>
          !p || p.assessment_date < c.assessment_date ? c : p,
        )
      : null;
  const lastHarvest =
    cyclePart && cyclePart.harvests.length > 0
      ? cyclePart?.harvests.reduce((p, c) =>
          p.complete_date > c.complete_date ? p : c,
        )
      : null;
  const longlineLength =
    cyclePart?.seedings.reduce((p, c) => p + c.line_length, 0) ?? 0;
  const cur = lines.findIndex(t => t.id === lineData.id) ?? -1;
  const prev = (cur - 1 + (lines.length ?? 0)) % (lines.length ?? 0);
  const next = (cur + 1) % (lines.length ?? 0);

  const breadcrumbItems = getBreadcrumbMenu('FARM_LINE', {
    FARM_ID: lineData.farm_id,
    LINE_ID: lineData.id,
    FARM_NAME: lineData?.farm_name,
    LINE_NAME: lineData?.line_name,
  }).slice(0, -1);

  const customFields = SelectAssessFields(lineData.farm_id);
  const assessmentColor = customSpecialValue(
    customFields,
    lastAssessment?.custom_values,
    'Color',
  );

  const [showLineSpat, setShowLineSpat] = useState(false);
  const [trackSeedings, setTrackSeedings] = useState<number[]>();
  const [qrSeed, setQrSeed] = useState<{
    farmID: number;
    lineID: number;
    seedingID: number;
    harvests: IMusselHarvest[];
  }>();

  const { bagsQuantity, harvestedLength } = useMemo(
    () => ({
      bagsQuantity: lineData.growing_cycle?.total_harvested_bags ?? 0,
      harvestedLength: lineData.growing_cycle?.total_harvested_length ?? 0,
    }),
    [lineData],
  );

  return (
    <div className='farm-line-desktop-template'>
      <div className='pt-28 pb-28 d-flex justify-content-between align-items-center'>
        <div className='d-flex' style={{ alignItems: 'baseline' }}>
          <BreadcrumbComponent items={breadcrumbItems} />
          {lines && (
            <div className='ml-17'>
              <span
                className='prev-link'
                onClick={() => redirectToLine(lineData.farm_id, lines[prev].id)}
              >
                <img src={prevIcon} alt='prev' />
              </span>
              <span className='line-name'>
                {translate(lang, 'Line %s', lineData.line_name)}
              </span>
              <span
                className='next-link'
                onClick={() => redirectToLine(lineData.farm_id, lines[next].id)}
              >
                <img src={nextIcon} alt='next' />
              </span>
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center white-card-small pt-3 pb-3'>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='mr-26'
              name='prev'
              onClick={onClickPrevGroup}
              disabled={!isPrevEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='left' />
            </Button>
            <Subtitle
              size={4}
              color='black'
              align='left'
              fontWeight={500}
              disabled={!mainSeed}
            >
              {mainSeed?.season_name ?? translate(lang, 'Empty')}
            </Subtitle>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='ml-26'
              name='next'
              onClick={onClickNextGroup}
              disabled={!isNextEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='right' />
            </Button>
          </div>
          <Button
            color='blue'
            size={0}
            width='default'
            type='bordered'
            className='ml-8'
            iconOnly
            onClick={showEditSeedModal}
            disabled={!mainSeed?.is_growing || !isEditable}
          >
            <Pen />
          </Button>
          <Button
            color='blue'
            size={1}
            width='middle'
            type='bordered'
            className='ml-16'
            onClick={onHarvestClick}
            disabled={!mainSeed?.is_growing || !isFarmable}
          >
            {translate(lang, 'Harvest Complete')}
          </Button>
        </div>
      </div>
      <div className='white-card pt-28 pr-16 pb-28 pl-24 mb-16'>
        <div className='d-flex justify-content-between'>
          <div>
            <Title size={5} color='black-3' align='default' fontWeight={500}>
              {translate(lang, 'Line %s', lineData.line_name)}
            </Title>
            <div style={{ marginTop: '7px' }}>
              <div
                className='--spat-count'
                onClick={e => setShowLineSpat(true)}
              >
                {translate(
                  lang,
                  'Last Spat Count: %s',
                  lineData.last_spat_count ?? 0,
                )}
              </div>
            </div>
            {!!lineData.growing_cycle?.last_harvest ? (
              <div style={{ marginTop: '20px' }}>
                <Link
                  to={`/harvest/${lineData.growing_cycle.last_harvest.id}`}
                  style={{
                    fontStyle: 'italic',
                    textDecorationLine: 'underline',
                  }}
                >
                  <Subtitle
                    size={5}
                    color='gray'
                    align='default'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line is partially harvested')}
                  </Subtitle>
                </Link>
                <Subtitle
                  size={5}
                  color='#000'
                  align='default'
                  fontWeight={500}
                >
                  {translate(
                    lang,
                    'Harvested %s m and %s bags so far',
                    harvestedLength,
                    bagsQuantity,
                  )}
                </Subtitle>
              </div>
            ) : (
              !!lineData.last_harvest && (
                <div style={{ marginTop: '10px' }}>
                  <Link
                    to={`/harvest/${lineData.last_harvest.id}`}
                    style={{
                      fontStyle: 'italic',
                      textDecorationLine: 'underline',
                    }}
                  >
                    <Subtitle
                      size={5}
                      color='gray'
                      align='default'
                      fontWeight={400}
                    >
                      {translate(lang, 'View last harvest')}
                    </Subtitle>
                  </Link>
                </div>
              )
            )}
            <div className='mt-17'>
              <LineTagButton lineData={lineData} loadLine={loadLine} />
            </div>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(lang, 'Longline Length')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {`${longlineLength} `}m
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Backbone')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {lineData?.backbone ? `${lineData?.backbone} m` : '-'}
            </Subtitle>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(lang, 'Date seeded')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {defaultDateFormat(mainSeed?.planned_date_seed, '-')}
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Spat Size')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {lastAssessment
                ? labelNumber(lastAssessment.shell_size.avg, 'mm', '-')
                : labelRange(
                    mainSeed?.spat_size,
                    mainSeed?.spat_size_max ?? undefined,
                    'mm',
                    '-',
                  )}
            </Subtitle>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(
                lang,
                mainSeed?.is_growing
                  ? 'Planned harvest date'
                  : 'Harvested date',
              )}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {defaultDateFormat(
                lastAssessment?.planned_date_harvest ??
                  mainSeed?.planned_date_harvest,
                '-',
              )}
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Seed type')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {mainSeed?.spat_storage?.seed_type ?? '-'}
            </Subtitle>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(lang, 'Stage')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {longlineLength > 0 &&
                cyclePart?.seedings.map((x, i) => (
                  <div key={i}>
                    {`${formatNumber(
                      (x.line_length / longlineLength) * 100,
                    )}% ${translate(lang, 'stage')} ${
                      x.spat_storage?.stage ?? 1
                    }`}
                  </div>
                ))}
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Submersion')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {mainSeed?.submersion ?? '-'}
            </Subtitle>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(lang, 'Income per meter')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {lastHarvest?.profit_per_meter ?? '-'}
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Density')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {labelRange(
                mainSeed?.density,
                mainSeed?.density_max ?? undefined,
              )}
            </Subtitle>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(lang, 'Avg. Size')}
            </Subtitle>
            <PositiveNegativeTitle isColor={assessmentColor ?? ''}>
              {labelRange(
                mainSeed?.spat_size,
                mainSeed?.spat_size_max ?? undefined,
                'mm',
              )}
            </PositiveNegativeTitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Inventories')}
            </Subtitle>
            <Subtitle size={5} color='black' align='left' fontWeight={500}>
              {cyclePart
                ? SeedInventoriesLabel(cyclePart.inventories_sum)
                : LineInventoriesLabel(lineData.pending_inventories ?? [])}
            </Subtitle>
          </div>
          {isEditable && (
            <div>
              <DropdownMenu
                data={{ ...lineData, isRedirectLine: true }}
                onEdit={showEditLineModal}
                column='isFarm'
                moreMenus={
                  mainSeed
                    ? [
                        {
                          label: translate(lang, 'View Scanned QR'),
                          onClick: () => setTrackSeedings([mainSeed.id]),
                        },
                        {
                          label: translate(lang, 'Add Scanned QR'),
                          onClick: () =>
                            setQrSeed({
                              farmID: lineData.farm_id,
                              lineID: lineData.id,
                              seedingID: mainSeed.id,
                              harvests: cyclePart.harvests,
                            }),
                        },
                      ]
                    : undefined
                }
              />
            </div>
          )}
        </div>
        {!!lineData.growing_cycle?.predicted_date_harvest && (
          <div style={{ textAlign: 'right' }}>
            <HarvestPredictButton lineData={lineData} />
          </div>
        )}
      </div>
      <div className='d-flex justify-content-between align-items-center white-card pt-12 pr-16 pb-12 mb-8'>
        {!!mainSeed ? (
          <button
            className={`timeline-button${
              checkedHistoryView === 1 ? ' --checked' : ''
            }`}
            onClick={onHistoryViewClick}
          >
            {checkedHistoryView === -1 ? (
              <div className='ml-17 mr-17'>
                <Spin />
              </div>
            ) : (
              translate(
                lang,
                checkedHistoryView === 1 ? 'Hide history' : 'Show history',
              )
            )}
          </button>
        ) : (
          <span></span>
        )}
        {isFarmable && (
          <div className='d-flex justify-content-end'>
            {mainSeed ? (
              <>
                {mainSeed.is_growing ? (
                  <>
                    <Button
                      className='mr-10'
                      color='blue'
                      size={1}
                      width='middle'
                      type='fill'
                      onClick={onFloatingManageClick}
                    >
                      {translate(lang, 'Inventory Management')}
                    </Button>
                    <Button
                      className='mr-10'
                      color='green'
                      size={1}
                      width='middle'
                      type='fill'
                      onClick={onMaintenanceClick}
                    >
                      {translate(lang, 'Add maintenance')}
                    </Button>
                    {!mainSeed.is_catch_spat && (
                      <Button
                        color='blue'
                        size={1}
                        width='middle'
                        type='fill'
                        onClick={onExtraSeedClick}
                      >
                        {translate(lang, 'Add extra seed')}
                      </Button>
                    )}
                    <Button
                      className='ml-10'
                      color='green'
                      size={1}
                      width='middle'
                      type='fill'
                      onClick={onAssessmentClick}
                    >
                      {translate(lang, 'Add assessment')}
                    </Button>
                  </>
                ) : (
                  <span></span>
                )}
              </>
            ) : (
              <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                  <Subtitle
                    size={3}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line empty for')}
                  </Subtitle>
                  <Subtitle
                    size={4}
                    color='black'
                    align='left'
                    fontWeight={600}
                    className='ml-4 mr-27'
                  >
                    {amountDays(lineData?.line_idle)}
                  </Subtitle>
                </div>
                {!isGrower && (
                  <Button
                    className='mr-8'
                    color='blue'
                    size={1}
                    width='small'
                    type='fill'
                    onClick={onCatchSpatClick}
                  >
                    {translate(lang, 'Catch Spat')}
                  </Button>
                )}
                <Button
                  className='mr-8'
                  color='blue'
                  size={1}
                  width='small'
                  type='fill'
                  onClick={onSeedingClick}
                >
                  {translate(lang, 'Seed the line')}
                </Button>
                <Button
                  color='green'
                  size={1}
                  width='middle'
                  type='fill'
                  onClick={onMaintenanceClick}
                >
                  {translate(lang, 'Add maintenance')}
                </Button>
              </div>
            )}
            {showLineSpat && (
              <LineSpatModal
                visible={showLineSpat}
                title={translate(lang, 'Line spat count history')}
                lineId={lineData.id}
                onLoadLine={loadLine}
                onClose={() => setShowLineSpat(false)}
              />
            )}
          </div>
        )}
      </div>
      {!!trackSeedings && (
        <SeedingTrackModal
          visible={true}
          seedingIDs={trackSeedings}
          onClose={() => setTrackSeedings(undefined)}
        />
      )}
      {!!qrSeed && (
        <AddTrackStatusModal
          visible={true}
          seedingID={qrSeed.seedingID}
          lineID={qrSeed.lineID}
          harvests={qrSeed.harvests}
          onClose={() => setQrSeed(undefined)}
        />
      )}
    </div>
  );
};

export default MusselLineDesktop;
