import { useDispatch, useSelector } from 'react-redux';
import footerLogo from '../../images/mussel-app.png';
import { selectLang, selectVisibleGuide } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { demoAccountLogin } from '../../store/auth/auth.actions';
import { hideGuideModal } from '../../store/ui/ui.actions';

const Footer = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const visible = useSelector(selectVisibleGuide);

  const [disabled, setDisabled] = useState(false);

  const hideGuide = () => dispatch(hideGuideModal());
  const viewDemoClick = async () => {
    setDisabled(true);
    await dispatch(demoAccountLogin());
    setDisabled(false);
    hideGuide();
  };

  return (
    <footer>
      <div className='container'>
        <img className='footer-logo' src={footerLogo} alt='footer-logo' />
        <div className='copyright'>{translate(lang, '_copyright')}</div>
      </div>
      {visible && (
        <Modal
          title={translate(lang, '_welcome_title')}
          onCancel={hideGuide}
          footer={null}
          visible={true}
          closable={false}
        >
          <div className='mb-24'>
            <p style={{ color: 'black' }}>{translate(lang, '_welcome_body')}</p>
          </div>
          <div className='d-flex justify-content-between'>
            <div className='d-flex'>
              <Button
                type='primary'
                onClick={viewDemoClick}
                disabled={disabled}
              >
                {translate(lang, 'View Demo')}
              </Button>
              <Link className='ml-24' to={'/farms/farm-add'}>
                <Button type='primary' onClick={hideGuide} disabled={disabled}>
                  {translate(lang, 'Setup your farm')}
                </Button>
              </Link>
            </div>
            <Button danger={true} onClick={hideGuide} disabled={disabled}>
              {translate(lang, 'Close')}
            </Button>
          </div>
        </Modal>
      )}
    </footer>
  );
};

export default Footer;
