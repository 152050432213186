import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { IMusselFarm, IOysterFarm } from '../../entities/farms.entities';

export const selectFarmsData = createSelector(
  (state: RootState) => state.farms.farmsData,
  farms => farms,
);

export const selectSpatStorages = createSelector(
  (state: RootState) => state.farms.spatStorages,
  spats => spats,
);

export const selectQueueData = createSelector(
  (state: RootState) => state.farms.queueData,
  queue => queue,
);

export const selectMusselFarms = createSelector(
  (state: RootState) => state.farms.farmsData.filter(x => x.type === 'MUSSEL'),
  farms => farms as IMusselFarm[],
);

export const selectOysterFarms = createSelector(
  (state: RootState) => state.farms.farmsData.filter(x => x.type === 'OYSTER'),
  farms => farms as IOysterFarm[],
);

export const selectSeaweedFarms = createSelector(
  (state: RootState) => state.farms.farmsData.filter(x => x.type === 'SEAWEED'),
  farms => farms,
);

export const selectMusselSpats = createSelector(
  (state: RootState) => state.farms.spatStorages,
  spats => spats.filter(x => x.type === 'MUSSEL'),
);
