import { useDispatch, useSelector } from 'react-redux';
import { Input, Subtitle } from '../../components/shared';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import { selectSizedOysters } from '../../store/utils/utils.selector';
import { useState } from 'react';
import { Button, Checkbox, Table } from 'antd';
import { selectOysterFarms } from '../../store/farms/farms.selector';
import { calcSpecieLineDozens, getLineOysters } from '../../lib/farm.helpers';
import { selectAccount } from '../../store/auth/auth.selector';
import { calcDiffDays } from '../../lib/common.helpers';
import { ITaskRequest } from '../../entities/task.entities';
import ModalTask from '../../components/todo/ModalTask';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectTasks } from '../../store/tasks/tasks.selector';
import { useHistory } from 'react-router-dom';
import { formatNumber } from '../../entities/util-functions';

const EPS = 0.01;

interface IOrder {
  name: string;
  amount?: number;
}

interface ISpecie {
  name: string;
  growing: number;
  harvest: number;
  ordered: number;
}

interface ILineItem {
  farmId: number;
  lineId: number;
  farmName: string;
  farmNumber: string;
  lineName: string;
  farmingMethod: string | null;
  totalBaskets: number;
  needBaskets: number;
  insufficiency: number;
  totalDz: number;
  usedDz: number;
  wasteDz: number;
  daysGrown: number;
  species: ISpecie[];
  checked?: boolean;
}

interface RowProps {
  data: ILineItem;
  index: number;
  sizeNames: string[];
  orders: IOrder[];
  onToggleCheck: (lineId: number, checked: boolean) => void;
}

const LineRow = ({
  data,
  index,
  sizeNames,
  orders,
  onToggleCheck,
}: RowProps) => {
  const history = useHistory();
  const totSum = data.species.reduce((p, c) => p + c.harvest, 0);
  const otherSum = data.species
    .filter(x => !orders.some(y => y.name === x.name))
    .reduce((p, c) => p + c.harvest, 0);

  const ordersView = orders.map(x => (
    <span className='ml-4' key={x.name}>
      <strong>{`${x.name} ${formatNumber(
        data.species.find(y => y.name === x.name)?.harvest ?? 0,
        0,
      )} dz`}</strong>
      <span>{` (${formatNumber(
        ((data.species.find(y => y.name === x.name)?.harvest ?? 0) / totSum) *
          100,
        0,
      )}%) , `}</span>
      <strong>{`${formatNumber(otherSum, 0)} dz other sizes`}</strong>
    </span>
  ));

  return (
    <>
      <tr
        className={'ant-table-row'}
        style={index % 2 === 1 ? { backgroundColor: '#fafafa' } : undefined}
      >
        <td className='ant-table-cell' rowSpan={2}>
          <div>
            <div
              className='mb-8'
              onClick={() => history.push(`/farms/${data.farmId}`)}
            >
              <strong>{'Farm:'}</strong>
              <span className='ml-4'>{`${data.farmName}/${data.farmNumber}`}</span>
            </div>
            <div
              onClick={() =>
                history.push(`/farms/${data.farmId}/${data.lineId}`)
              }
            >
              <strong>{'Line:'}</strong>
              <span className='ml-4'>{data.lineName}</span>
            </div>
          </div>
        </td>
        <td className='ant-table-cell' rowSpan={2}>
          <div>{`${data.daysGrown} days`}</div>
        </td>
        <td className='ant-table-cell' rowSpan={2}>
          {`${formatNumber(data.totalDz, 0)} dz`}
        </td>
        <td className='ant-table-cell' rowSpan={2}>
          {`${data.totalBaskets} ${data.farmingMethod}`}
        </td>
        <>
          {sizeNames.map(x => {
            const spc = data.species.find(y => y.name === x);
            return (
              <td key={x} className='ant-table-cell'>
                <div>{`${formatNumber(spc?.growing ?? 0, 0)} dz`}</div>
              </td>
            );
          })}
        </>
        <td className='ant-table-cell' rowSpan={2}>
          <Checkbox
            checked={data.checked}
            onChange={e => onToggleCheck(data.lineId, e.target.checked)}
          />
        </td>
      </tr>
      <tr
        className={'ant-table-row'}
        style={index % 2 === 1 ? { backgroundColor: '#fafafa' } : undefined}
      >
        <td
          className='ant-table-cell'
          style={{ padding: 0, paddingBottom: 10 }}
          colSpan={sizeNames.length}
        >
          <div className='text-center'>
            <strong>{`${data.needBaskets} ${data.farmingMethod} needed`}</strong>
            <span className='ml-4 mr-4'>{'and will contain'}</span>
            {ordersView}
          </div>
        </td>
      </tr>
    </>
  );
};

const OrderSuggest = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const oysterSpecies = useSelector(selectSizedOysters);
  const farmsData = useSelector(selectOysterFarms);
  const onGrowWaste = useSelector(selectAccount)?.oyster_crops;
  const tasksData = useSelector(selectTasks);

  const [orders, setOrders] = useState<IOrder[]>(
    oysterSpecies.map(x => ({ name: x.name })),
  );
  const [suitLines, setSuitLines] = useState<ILineItem[]>([]);
  const [taskData, setTaskData] = useState<ITaskRequest>();

  const showError = (message: string) =>
    dispatch(showFeedback({ type: 'error', message, isMessage: true }));

  const updateOrder = (name: string, v: string) => {
    const val = v.length <= 0 ? undefined : Number(v);
    let tmp = [...orders];
    const i = tmp.findIndex(x => x.name === name);
    tmp[i].amount = val;
    setOrders(tmp);
  };
  const searchClick = () => {
    if (orders.every(x => !x.amount)) {
      showError(translate(lang, 'Please input at least one order'));
      return;
    }
    let tmp: ILineItem[] = [];
    for (const farm of farmsData) {
      for (const line of farm.lines) {
        if (!line.growing_cycle) continue;

        const oysters =
          calcSpecieLineDozens(line, onGrowWaste, oysterSpecies) ?? [];
        const totalBaskets = line.growing_cycle.current_basket_count;

        let maxCnt = 0;
        for (let i = 0; i < orders.length; i++) {
          const name = orders[i].name;
          const oam = orders[i].amount;
          const o = oysters.find(y => y.name === name);
          if (!o || !o.amount || !oam) continue;

          const final = Math.min(oam, o.amount);

          const density = o.amount / totalBaskets;
          const cnt = Math.ceil(final / density);
          if (cnt > maxCnt) maxCnt = cnt;
        }
        maxCnt = Math.min(maxCnt, totalBaskets);

        if (maxCnt <= 0) continue;

        const amount = getLineOysters(line, 'dozens') ?? 0;
        const totDensity = amount / totalBaskets;
        const total = maxCnt * totDensity;
        let insufficiency = 0,
          finals = 0;
        let species: ISpecie[] = [];
        for (let x of orders) {
          const os = oysters.find(y => y.name === x.name);
          const osm = os?.amount ?? 0;

          const density = osm / totalBaskets;
          const usedDz = density * maxCnt;
          finals += Math.min(usedDz, x.amount ?? 0);
          insufficiency += Math.max(0, (x.amount ?? 0) - usedDz);

          species.push({
            name: x.name,
            growing: osm,
            ordered: x.amount ?? 0,
            harvest: usedDz,
          });
        }
        tmp.push({
          farmId: farm.id,
          lineId: line.id,
          farmName: farm.name,
          farmNumber: farm.farm_number,
          lineName: line.line_name,
          farmingMethod: line.farming_method,
          daysGrown: calcDiffDays(
            line.growing_cycle.main_seed.planned_date_seed,
          ),
          totalBaskets,
          needBaskets: maxCnt,
          insufficiency,
          totalDz: amount,
          usedDz: total,
          wasteDz: total - finals,
          species,
        });
      }
    }
    const isEnough = tmp.some(x => x.insufficiency < EPS);
    if (isEnough) {
      tmp = tmp.filter(x => x.insufficiency < EPS);
    }
    tmp.sort((a, b) => {
      if (Math.abs(a.insufficiency - b.insufficiency) > EPS) {
        return a.insufficiency < b.insufficiency ? -1 : 1;
      }
      if (Math.abs(a.wasteDz - b.wasteDz) > EPS) {
        return a.wasteDz < b.wasteDz ? -1 : 1;
      }
      return b.daysGrown - a.daysGrown;
    });
    setSuitLines(tmp);
    tmp = tmp.slice(0, 10);
  };
  const toggleCheck = (lineId: number, checked: boolean) => {
    let tmp = [...suitLines];
    const i = tmp.findIndex(x => x.lineId === lineId);
    if (i < 0) return;
    tmp[i].checked = checked;
    setSuitLines(tmp);
  };
  const bulkConvertClick = () => {
    const checkedLines = suitLines.filter(x => x.checked);
    if (checkedLines.length === 0) {
      showError(translate(lang, 'Please select at least one line'));
      return;
    }

    const farmID = checkedLines.every(x => x.farmId === checkedLines[0].farmId)
      ? checkedLines[0].farmId
      : undefined;
    const farmStr = farmID
      ? `${checkedLines[0].farmName}/${checkedLines[0].farmNumber}`
      : undefined;
    const title = `Harvest line${
      checkedLines.length > 1 ? 's' : ''
    } ${checkedLines.map(x => x.lineName).join(', ')}${
      farmStr ? ` on farm ${farmStr}` : ''
    }`;
    const content = `Ordered oysters are ${orders
      .filter(x => x.amount)
      .map(x => `${x.name} ${x.amount ?? 0} dozens`)
      .join(', ')}`;

    const exist = tasksData.some(
      x =>
        x.farm_id === (farmID ?? null) &&
        x.type === 'HARVEST' &&
        !x.is_completed &&
        title === x.title,
    );
    if (exist) {
      showError(translate(lang, 'Task already exists for this harvest'));
      return;
    }
    setTaskData({
      title,
      farm_id: farmID,
      line_id: checkedLines.length > 1 ? undefined : checkedLines[0].lineId,
      content,
      due_date: Date.now(),
      type: 'HARVEST',
    });
  };
  const showSuccess = () =>
    dispatch(
      showFeedback({
        type: 'success',
        message: translate(lang, 'Task created successfully'),
        isMessage: true,
      }),
    );

  const totalRequired = orders.reduce((p, c) => p + (c.amount ?? 0), 0);
  const columns: any = [
    {
      title: translate(lang, 'Size'),
      key: 'name',
      render: (x: IOrder) => x.name,
      width: 220,
    },
    {
      title:
        translate(lang, 'Amount') +
        (totalRequired > 0 ? ` (${totalRequired} dozens)` : ''),
      key: 'amount',
      render: (x: IOrder) => (
        <Input
          label=''
          value={x.amount?.toString() ?? ''}
          onChange={e => updateOrder(x.name, e.target.value)}
          type='number'
          unit='dozens'
        />
      ),
      align: 'left',
      width: 180,
    },
  ];
  const suitHeaders = [
    'Farm & Line',
    'Days grown',
    'Dozens',
    'Containers',
    ...oysterSpecies.map(x => x.name),
    'Convert',
  ];

  return (
    <div className='pt-28 pb-28'>
      <div className='mb-18'>
        <Subtitle size={1} color='black' align='left' fontWeight={600}>
          {translate(lang, 'What is your order for today?')}
        </Subtitle>
      </div>
      <div className='ml-17'>
        <div className='mb-18 mxw-500'>
          <Table
            rowKey={'name'}
            className='table table--units table--small'
            dataSource={orders}
            columns={columns}
            pagination={false}
          />
        </div>
        <div className='mb-18'>
          <Button size='large' type='primary' onClick={searchClick}>
            {translate(lang, 'Search')}
          </Button>
        </div>
        {suitLines.length > 0 && (
          <div className='mb-18'>
            <div className='ant-table-wrapper table table--isFarm'>
              <div className='ant-table'>
                <div className='ant-table-container'>
                  <div className='ant-table-content'>
                    <table style={{ tableLayout: 'auto' }}>
                      <thead className='ant-table-thead'>
                        <tr>
                          {suitHeaders.map(x => (
                            <th key={x} className='ant-table-cell'>
                              {x}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='ant-table-tbody'>
                        {suitLines.map((x, i) => (
                          <LineRow
                            key={x.lineId}
                            data={x}
                            index={i}
                            sizeNames={oysterSpecies.map(y => y.name)}
                            orders={orders.filter(y => y.amount)}
                            onToggleCheck={toggleCheck}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-18'>
              <Button type='primary' onClick={bulkConvertClick}>
                {translate(lang, 'Convert into a task')}
              </Button>
            </div>
          </div>
        )}
      </div>
      {!!taskData && (
        <ModalTask
          title='Create a task'
          visible={true}
          data={taskData}
          onConfirm={() => {
            setTaskData(undefined);
            showSuccess();
          }}
          onCancel={() => setTaskData(undefined)}
        />
      )}
    </div>
  );
};

export default OrderSuggest;
