import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Map, Spinner } from '../../components/shared';
import FarmForm from '../../components/farmform/FarmForm';
import { useWidth } from '../../util/useWidth';
import { IFarmResource } from '../../entities/farms.entities';
import { selectSpin } from '../../store/ui/ui.selector';
import { ILocation } from '../../entities/general.entities';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { LINE_COLORS } from '../../lib/constant';
import { getLineStatus } from '../../lib/farm.helpers';
import { selectUserMeta } from '../../store/auth/auth.selector';

const defaultLocation = {
  lat: -41.0917585,
  lng: 173.828391,
};

const FarmsForm = () => {
  const width = useWidth();
  const isSpinner = useSelector(selectSpin);
  const farmsData = useSelector(selectFarmsData);
  const params = useParams<{ id: string; idFarm: string }>();
  const userMeta = useSelector(selectUserMeta);

  const farmData = farmsData.find(x => x.id === Number(params.idFarm));

  const { polygons, lines } = useMemo(() => {
    const polygons = farmsData
      .filter(x => x.bounds && x.bounds.length > 2)
      .map(x => x.bounds as ILocation[]);

    let lines = [];
    for (let farm of farmsData) {
      for (let line of farm.lines) {
        if (!line.points || line.points.length < 2) continue;
        const color = getLineStatus(line, userMeta?.line_assess_expire_days);
        lines.push({
          points: line.points,
          color: LINE_COLORS[color],
        });
      }
    }
    return { polygons, lines };
  }, [farmsData]);

  const [position, setPosition] = useState<ILocation>();
  const [positionInMap, setPositionInMap] = useState<ILocation>();
  const [bounds, setBounds] = useState<ILocation[]>();
  const [locMethod, setLocMethod] = useState<'location' | 'bounds'>('bounds');

  const handleOnPosition = (mapPosition: any) => {
    if (mapPosition.lat && mapPosition.lng) {
      setPosition({
        lat: Number(mapPosition.lat),
        lng: Number(mapPosition.lng),
      });
    }
  };
  const handleOnCallback = (newPosition: ILocation) => {
    setPositionInMap(newPosition);
  };

  useEffect(() => {
    if (farmData && farmData.bounds) {
      setBounds(farmData.bounds);
    }
  }, [farmData]);

  return (
    <div className='h-calc-80 bg-main'>
      <div className='container container--not-pr pos-relative'>
        <div className='farms__form h-calc-80'>
          <div className='farms__form-form'>
            <FarmForm
              typeOf={params.id}
              idFarm={params.idFarm}
              dataFarm={farmData}
              onPosition={handleOnPosition}
              positionInMap={positionInMap}
              bounds={bounds}
              setBounds={setBounds}
              locMethod={locMethod}
              setLocMethod={setLocMethod}
            />
          </div>
          <div
            className='farms__form-map pt-17 pb-17'
            style={{ height: width < 768 ? `${width}px` : '' }}
          >
            <Map
              zoom={10}
              position={!!bounds && bounds.length > 0 ? bounds[0] : position}
              defaultPosition={
                (!!bounds && bounds.length > 0 ? bounds[0] : position) ??
                defaultLocation
              }
              polygons={
                bounds && bounds.length > 1 ? [...polygons, bounds] : polygons
              }
              lines={lines}
              onCallback={handleOnCallback}
              isStayLocation={true}
            />
          </div>
        </div>
        {isSpinner && <Spinner position='global' />}
      </div>
    </div>
  );
};

export default FarmsForm;
