import { useSelector } from 'react-redux';
import { IExpenseRequest } from '../../entities/budget.entities';
import { numberToMoneyStr } from '../../entities/util-functions';
import {
  Button,
  Dropdown,
  Input,
  PlusIcon,
  Subtitle,
  TrashIcon,
} from '../shared';
import { selectUtils } from '../../store/utils/utils.selector';
import { useState } from 'react';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  className?: string;
  title: string;
  data: IExpenseRequest[];
  setData: (data: IExpenseRequest[]) => void;
  additionalQuantity?: number;
  additionalAmount?: number;
  hideTotal?: boolean;
}

const ExpensesForm = ({
  className,
  title,
  data,
  setData,
  additionalQuantity,
  additionalAmount,
  hideTotal,
}: Props) => {
  const lang = useSelector(selectLang);
  const options = useSelector(selectUtils)
    .filter(x => x.type === 'expense_item')
    .map(x => x.name);

  const [isOther, setIsOther] = useState(
    data.map(x => x.name && !options.includes(x.name)),
  );
  const selectItem = (i: number, v: string) => {
    if (v.length <= 0) {
      const tmp = [...isOther];
      tmp[i] = true;
      setIsOther(tmp);

      const tmp2 = [...data];
      tmp2[i].name = '';
      setData(tmp2);
    } else {
      const tmp = [...data];
      tmp[i].name = v;
      setData(tmp);
    }
  };
  const updateForm = (i: number, key: keyof IExpenseRequest, value: string) => {
    const tmp = [...data];
    if (key === 'name') {
      tmp[i].name = value;
    } else if (key === 'quantity') {
      const v = value.length <= 0 ? null : Number(value);
      tmp[i].quantity = v;
      if (
        tmp[i].unit_price !== null &&
        tmp[i].unit_price !== undefined &&
        v !== null
      ) {
        tmp[i].amount = Number(tmp[i].unit_price) * v;
      }
    } else if (key === 'unit_price') {
      const v = value.length <= 0 ? null : Number(value);
      tmp[i].unit_price = v;
      if (
        tmp[i].quantity !== null &&
        tmp[i].quantity !== undefined &&
        v !== null
      ) {
        tmp[i].amount = Number(tmp[i].quantity) * v;
      }
    } else if (key === 'amount') {
      const v = value.length <= 0 ? null : Number(value);
      tmp[i].amount = v;
      if (
        tmp[i].quantity !== null &&
        tmp[i].quantity !== undefined &&
        v !== null
      ) {
        tmp[i].unit_price =
          Math.round((v / Number(tmp[i].quantity)) * 100) / 100;
      }
    }
    setData(tmp);
  };
  const deleteItem = (i: number) => {
    const tmp = [...data];
    tmp.splice(i, 1);
    setData(tmp);

    const tmp2 = [...isOther];
    tmp2.splice(i, 1);
    setIsOther(tmp2);
  };
  const addItem = () => {
    setData([
      ...data,
      { name: '', quantity: null, unit_price: null, amount: null },
    ]);
    setIsOther([...isOther, false]);
  };
  const totalQuantity =
      (additionalQuantity ?? 0) +
      data.reduce((p, c) => p + (c.quantity ?? 0), 0),
    totalAmount =
      (additionalAmount ?? 0) + data.reduce((p, c) => p + (c.amount ?? 0), 0);

  return (
    <div className={className}>
      <div className='mb-7'>
        <Subtitle color='black-2' align='left' size={5} fontWeight={500}>
          {translate(lang, title)}
        </Subtitle>
      </div>
      <div className='mb-7'>
        <div className='item-row'>
          <div className='item-cell'>{translate(lang, 'Description')}</div>
          <div className='item-cell'>{translate(lang, 'Quantity')}</div>
          <div className='item-cell'>{translate(lang, 'Unit price')}</div>
          <div className='item-cell'>{translate(lang, 'Amount')}</div>
          <div className='item-cell-half'></div>
        </div>
        {data.map((x, i) => (
          <div className='item-row' key={i}>
            <div className='item-cell'>
              {options.length > 0 && !isOther[i] ? (
                <Dropdown
                  label=''
                  placeholder='Select description'
                  options={[
                    ...options.map(y => ({ id: y, value: y, label: y })),
                    {
                      id: 'other',
                      value: '',
                      label: translate(lang, 'Other'),
                    },
                  ]}
                  value={x.name.length <= 0 ? undefined : x.name}
                  onChange={v => selectItem(i, v)}
                />
              ) : (
                <Input
                  type='text'
                  label=''
                  onChange={e => updateForm(i, 'name', e.target.value)}
                  value={x.name}
                  placeholder=''
                />
              )}
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                onChange={e => updateForm(i, 'quantity', e.target.value)}
                value={x.quantity?.toString() ?? ''}
                label=''
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                value={x.unit_price?.toString() ?? ''}
                onChange={e => updateForm(i, 'unit_price', e.target.value)}
                label=''
                unit='$'
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                onChange={e => updateForm(i, 'amount', e.target.value)}
                value={x.amount?.toString() ?? ''}
                label=''
                unit='$'
              />
            </div>
            <div className='item-cell-half'>
              <Button
                width='full'
                size={0}
                type='bordered'
                color='red'
                className='d-flex justify-content-center'
                onClick={() => deleteItem(i)}
              >
                <TrashIcon color='#cc0101' />
              </Button>
            </div>
          </div>
        ))}
        <div className='item-row'>
          <div className='item-cell'>
            <Button
              width='full'
              size={5}
              type='bordered'
              color='blue'
              className='d-flex justify-content-center'
              onClick={addItem}
            >
              <PlusIcon />
            </Button>
          </div>
          <div className='item-cell'></div>
          <div className='item-cell'></div>
          <div className='item-cell'></div>
          <div className='item-cell-half'></div>
        </div>
        {!hideTotal && (
          <div className='item-row mt-7 strong'>
            <div className='item-cell'>{translate(lang, 'Totals')}</div>
            <div className='item-cell'>{totalQuantity}</div>
            <div className='item-cell'></div>
            <div className='item-cell'>{numberToMoneyStr(totalAmount)}</div>
            <div className='item-cell-half'></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpensesForm;
