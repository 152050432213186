import { useDispatch, useSelector } from 'react-redux';
import { selectTags } from '../../store/automation/automation.selector';
import { Modal } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Dropdown,
  Input,
  Subtitle,
} from '../shared';
import { useState } from 'react';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { loadAutomationData } from '../../store/automation/automation.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const units = ['hour', 'day', 'week', 'month'];

interface IForm {
  id?: number;
  time: number;
  unit: 'hour' | 'day' | 'week' | 'month';
  tag_id: number | undefined;
  farms: number[];
  lines?: number[];
  is_from_last?: boolean;
}

const defaultForm: IForm = {
  time: 0,
  unit: 'day',
  tag_id: undefined,
  farms: [],
};

interface Props {
  visible: boolean;
  data?: any;
  updateID?: number;
  onClose: (d: any) => void;
}

const TagScheduleModal = ({ visible, data, updateID, onClose }: Props) => {
  const dispatch = useDispatch<any>();
  const tags = useSelector(selectTags)?.filter(x => x.is_urgent) ?? [];
  const farmsData = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [form, setForm] = useState<IForm>(
    data ? { ...data, id: undefined } : defaultForm,
  );
  const [disabled, setDisabled] = useState(false);

  const updateForm = (key: keyof IForm, value: any) => {
    setForm(prev => ({ ...prev, [key]: value }));
  };
  const showError = (msg: string) =>
    dispatch(
      showFeedback({
        message: msg,
        type: 'error',
        isMessageModal: true,
      }),
    );
  const confirmClick = async () => {
    let submitData = { ...form };
    if (!submitData.tag_id) {
      showError(translate(lang, 'Please select a tag'));
      return;
    }
    if (!submitData.farms || submitData.farms.length <= 0) {
      showError(translate(lang, 'Please select at least one farm'));
      return;
    }
    if (updateID) {
      submitData.id = updateID;
    }
    setDisabled(true);
    const res = await sendSingleRequest(
      submitData,
      'POST',
      'api/automation/tag_schedule',
      true,
    );
    if (res.status) {
      await dispatch(loadAutomationData());
      setDisabled(false);
      onClose(res.data);
    } else {
      setDisabled(false);
      showError(translate(lang, res.data?.message ?? 'Unknown error'));
    }
  };

  const linesOption = () => {
    const farms = farmsData.filter(x => form.farms.includes(x.id));
    let result: Array<{ id: string; value: string; label: string }> = [];
    for (const f of farms) {
      for (const l of f.lines) {
        result.push({
          id: l.id.toString(),
          value: l.id.toString(),
          label: `${f.name} - ${l.line_name}`,
        });
      }
    }
    return result;
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(
              lang,
              !updateID ? 'Add Tag Schedule' : 'Edit Tag Schedule',
            )}
          </Subtitle>
        </div>
        <div className='mt-17 mb-17'>
          <Dropdown
            label={translate(lang, 'Select Tag')}
            options={tags?.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            value={form.tag_id?.toString()}
            onChange={v => updateForm('tag_id', Number(v))}
          />
        </div>
        <div className='mb-17 d-flex time-input'>
          <div className='mr-16 w-50 mb-17'>
            <Input
              label={translate(lang, 'Time')}
              type='number'
              onChange={e => updateForm('time', Number(e.target.value))}
              className='w-100'
              value={form.time.toString()}
              min={0}
            />
          </div>
          <div className='w-50 mb-17'>
            <Dropdown
              label={translate(lang, 'Unit')}
              placeholder={translate(lang, 'Choose Unit')}
              className='w-100'
              onChange={v => updateForm('unit', v)}
              options={units.map(x => ({ id: x, label: x, value: x }))}
              value={form.unit}
            />
          </div>
        </div>
        <div className='mb-17'>
          <Dropdown
            label={translate(lang, 'Select applied farms')}
            placeholder={translate(lang, 'Apply to these farms')}
            mode='multiple'
            options={farmsData.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            value={form.farms.map(x => x.toString()) as any}
            onChange={(v: any) =>
              updateForm(
                'farms',
                v.map((x: string) => Number(x)),
              )
            }
          />
        </div>
        <div className='mb-32'>
          <Dropdown
            label={translate(lang, 'Select applied lines')}
            placeholder={translate(lang, 'Apply to these lines')}
            mode='multiple'
            options={linesOption()}
            value={(form.lines?.map(x => x.toString()) ?? undefined) as any}
            onChange={(v: any) =>
              updateForm(
                'lines',
                v.map((x: string) => Number(x)),
              )
            }
          />
        </div>
        <div className='pb-32'>
          <CheckboxButton
            label={translate(lang, 'Use previous tag end as start date')}
            checked={form.is_from_last}
            onChange={e => updateForm('is_from_last', e.target.checked)}
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end pt-32'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default TagScheduleModal;
