import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { loadFarmsData } from '../../store/farms/farms.actions';
import Farms from './Farms';
import Farm from './Farm';
import FarmLine from '../FarmLine/FarmLine';
import AddLines from '../Lines/AddLines';
import FarmsForm from './FarmsForm';
import FarmVisualLinesPage from './FarmVisualLinesPage';
import VisualFarmsPage from './VisualFarmsPage';

const FarmsRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFarmsData());
  }, [dispatch]);

  return (
    <div>
      <Switch>
        <Route exact path='/farms/farm-:id/:idFarm?'>
          <FarmsForm />
        </Route>
        <Route exact path='/farms/visual-lines/:idFarm'>
          <FarmVisualLinesPage />
        </Route>
        <Route exact path='/farms/:idFarm/add-line'>
          <AddLines />
        </Route>
        <Route exact path='/farms/visual-mode'>
          <VisualFarmsPage />
        </Route>
        <Route exact path='/farms/:idFarm'>
          <Farm />
        </Route>
        <Route exact path='/farms/:idFarm/:idLine'>
          <FarmLine />
        </Route>
        <Route exact path='/farms'>
          <Farms />
        </Route>
      </Switch>
    </div>
  );
};

export default FarmsRouter;
