import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  Dropdown,
  ModalComponent,
  Pen,
  Spinner,
  Title,
  TrashIcon,
} from '../../components/shared';
import { IBoatResource } from '../../entities/boat.entities';
import { hashColor } from '../../entities/util-functions';
import { showFeedback } from '../../store/ui/ui.actions';
import { useWidth } from '../../util/useWidth';
import BoatModal from './BoatModal';
import { deleteUserBoat, loadUserBoats } from '../../store/users/users.actions';
import { selectBoats, selectUsers } from '../../store/users/users.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const BoatsPage = () => {
  const dispatch = useDispatch<any>();
  const width = useWidth();
  const lang = useSelector(selectLang);
  const userBoats = useSelector(selectBoats);
  const usersData = useSelector(selectUsers);
  const users =
    usersData.map((x: any) => ({
      id: x.id,
      label: x.name,
      value: x.id,
    })) ?? [];

  const [boats, setBoats] = useState<IBoatResource[]>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedBoat, setSelectedBoat] = useState<IBoatResource>();
  const [showDelete, setShowDelete] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleClickAdd = () => {
    setSelectedBoat(undefined);
    setVisible(true);
  };
  const handleEditClick = (boat: IBoatResource) => {
    setSelectedBoat(boat);
    setVisible(true);
  };
  const handleDeleteClick = (boat: IBoatResource) => {
    setSelectedBoat(boat);
    setShowDelete(true);
  };
  const confirmDelete = () => {
    if (!selectedBoat) return;
    setShowDelete(false);
    dispatch(deleteUserBoat(selectedBoat.id)).then((r: any) => {
      if (r === true) {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, 'Deleted successfully'),
          }),
        );
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, r ?? 'Something went wrong'),
          }),
        );
      }
    });
  };
  const afterConfirm = () => {
    setVisible(false);
    loadBoats();
  };
  const onSelectCrew = (boat: IBoatResource, v: any) => {
    const us = users.filter((x: any) => v.includes(x.id));
    let tmp = [...(boats ?? [])];
    const i = tmp.findIndex(x => x.id === boat.id);
    tmp[i].users = us as any;
    setBoats(tmp);
  };
  const assignUser = (boat: IBoatResource) => {
    setDisabled(true);
    const data = { boat_id: boat.id, users: boat.users.map(x => x.id) };
    sendSingleRequest(data, 'POST', 'api/boat/assign-users', true).then(r => {
      setDisabled(false);
      if (r.status) {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, 'Assigned successfully'),
          }),
        );
        loadBoats();
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, r.data?.message ?? 'Something went wrong'),
          }),
        );
        loadBoats();
      }
    });
  };

  const loadBoats = useCallback(() => {
    setLoading(true);
    dispatch(loadUserBoats()).then((r: any) => {
      setLoading(false);
      if (r !== true) {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, r ?? 'Something went wrong'),
          }),
        );
      }
    });
  }, [dispatch]);

  useEffect(() => setBoats(userBoats), [userBoats]);
  useEffect(() => loadBoats(), [loadBoats]);

  return (
    <div className='container w-100'>
      <div className='boats-page pb-32'>
        {width > 768 && (
          <Title size={5} color='black-3' align='default' fontWeight={700}>
            {translate(lang, 'Boats')}
          </Title>
        )}
        <div className='add-button'>
          <Button
            color='blue'
            size={3}
            width='small'
            type='fill'
            onClick={handleClickAdd}
          >
            {translate(lang, 'Add New Boat')}
          </Button>
        </div>
        <div className='boats-content'>
          {loading || !boats ? (
            <div className='mt-32'>
              <Spinner />
            </div>
          ) : (
            boats.map(boat => (
              <div key={boat.id} className='boat-wrapper'>
                <div className='boat-info'>
                  <div className='mb-17'>
                    <div className='--label'>
                      {translate(lang, 'Boat Name')}
                    </div>
                    <div className='--value'>{boat.name}</div>
                  </div>
                  <div className='mb-17'>
                    <div className='--label'>
                      {translate(lang, 'Registration')}
                    </div>
                    <div
                      className='--value'
                      style={{
                        textDecoration: 'underline',
                        textDecorationColor: hashColor(boat.reg_number),
                      }}
                    >
                      {boat.reg_number}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <button
                      className='edit-btn'
                      onClick={e => handleEditClick(boat)}
                    >
                      <Pen />
                    </button>
                    <button
                      className='delete-btn'
                      onClick={e => handleDeleteClick(boat)}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </div>
                <div className='crew-info'>
                  <Dropdown
                    label={translate(lang, 'Crew')}
                    mode='multiple'
                    placeholder=''
                    options={users}
                    defaultValue={boat.users.map(x => x.id) as any}
                    onChange={v => onSelectCrew(boat, v)}
                  />
                  <button
                    className={`update-btn ${disabled ? 'disabled' : ''}`}
                    disabled={disabled}
                    onClick={e => assignUser(boat)}
                  >
                    {translate(lang, 'Update')}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
        {visible && (
          <BoatModal
            title={!selectedBoat ? 'Add new boat' : 'Update boat'}
            visible={visible}
            isAdding={!selectedBoat}
            data={selectedBoat}
            onConfirm={afterConfirm}
            onClose={() => setVisible(false)}
          />
        )}
        {showDelete && (
          <ModalComponent
            title={translate(lang, 'Delete')}
            text={translate(lang, 'Are you sure to delete this data?')}
            type='delete'
            visible={showDelete}
            disabled={loading}
            onCancel={() => setShowDelete(false)}
            onConfirm={confirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default BoatsPage;
