import { Collapse } from 'antd';
import MobileAutomation from './MobileAutomation';
import { Caret, Paragrapgh } from '../shared';
import { IAutomation, ITagSchedule } from '../../entities/automation.entities';
import { randomKey } from '../../lib/common.helpers';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  data: Array<IAutomation | ITagSchedule>;
  handleOnEdit: (data: IAutomation, col?: string | undefined) => void;
  onDeleteRow: (data: IAutomation) => void;
}

const MobileAutomationTable = ({ data, handleOnEdit, onDeleteRow }: Props) => {
  const lang = useSelector(selectLang);

  return (
    <div className='table-mobile'>
      {data.length > 0 ? (
        <>
          <Collapse
            expandIcon={(props: any) => (
              <div>
                <Caret
                  color='#131523'
                  direction={props.isActive ? 'top' : 'bottom'}
                  fontWeight='big'
                />
              </div>
            )}
          >
            {data.map(automation => (
              <div key={randomKey()}>
                {automation ? (
                  <MobileAutomation
                    data={automation}
                    key={randomKey()}
                    handleOnEdit={handleOnEdit}
                    onDeleteRow={onDeleteRow}
                  />
                ) : (
                  <div className='table-mobile__not-data'>
                    <Paragrapgh
                      size={1}
                      color='black-5'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'No data available')}
                    </Paragrapgh>
                  </div>
                )}
              </div>
            ))}
          </Collapse>
        </>
      ) : (
        <div className='table-mobile__not-data'>
          <Paragrapgh size={1} color='black-5' align='left' fontWeight={400}>
            {translate(lang, 'No data available')}
          </Paragrapgh>
        </div>
      )}
    </div>
  );
};

export default MobileAutomationTable;
