import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  CheckboxButton,
  Input,
  Subtitle,
  Title,
} from '../../components/shared';
import {
  updateFriendEmailSetting,
  updateUserMeta,
} from '../../store/auth/auth.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { useWidth } from '../../util/useWidth';
import { isEmailFormat } from '../../util/validation';
import {
  selectProfile,
  selectSettings,
  selectUserMeta,
} from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const EmailPreference = () => {
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const userMeta = useSelector(selectUserMeta);
  const profile = useSelector(selectProfile);
  const settings = useSelector(selectSettings);
  const val = !!userMeta?.email_preference;
  const lang = useSelector(selectLang);

  const [emails, setEmails] = useState(settings.friendEmails?.join(',') ?? '');
  const [disabled, setDisabled] = useState(false);

  const updateValue = (val: boolean) => {
    sendSingleRequest(
      { email_preference: val ? 1 : 0 },
      'POST',
      `api/user-meta`,
      true,
    ).then(() => dispatch(updateUserMeta()));
  };

  const saveEmailsList = async () => {
    if (emails && emails.split(',').some(x => !isEmailFormat(x))) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, '_invalid_emails'),
        }),
      );
      return;
    }
    setDisabled(true);
    const data = emails ? emails.split(',') : [];
    const res = await dispatch(updateFriendEmailSetting(data));
    setDisabled(false);
    if (res === true) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Email list is updated successfully'),
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, res),
        }),
      );
    }
  };

  return (
    <div className='content pb-32'>
      <div className='mb-32'>
        {width > 768 && (
          <Title
            className='mb-24'
            size={5}
            color='black-3'
            align='default'
            fontWeight={700}
          >
            {translate(lang, 'Email Preference')}
          </Title>
        )}
        <div className='mt-32'>
          <Subtitle size={1} color='black-3' align='left' fontWeight={500}>
            {profile?.email}
          </Subtitle>
        </div>
        <div className='mt-17 mb-32'>
          <CheckboxButton
            label={translate(lang, val ? 'Subscribed' : 'Unsubscribed')}
            checked={val}
            onChange={e => updateValue(e.target.checked)}
          />
        </div>
      </div>
      <div className='mt-32'>
        <div className='mt-17 mb-17'>
          <Input
            label={translate(lang, 'Email list')}
            type='textarea'
            onChange={e => setEmails(e.target.value)}
            value={emails}
            placeholder='Please enter emails separate by comma'
          />
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={saveEmailsList}
            disabled={disabled}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailPreference;
