import {
  IAutomation,
  ICustomField,
  ITag,
  ITagSchedule,
} from '../../entities/automation.entities';
import { IAutomationAction } from './automation.type';

interface IAutomationState {
  automationsData: IAutomation[];
  customFields: ICustomField[];
  tags?: ITag[];
  tagSchedules?: ITagSchedule[];
}

const initialState: IAutomationState = {
  automationsData: [],
  customFields: [],
};

const automationReducer = (
  state = initialState,
  action: IAutomationAction,
): IAutomationState => {
  switch (action.type) {
    case 'AUTOMATIONS/SET_AUTOMATIONS_DATA': {
      return { ...state, automationsData: action.payload };
    }

    case 'AUTOMATIONS/SET_CUSTOM_FIELDS': {
      return { ...state, customFields: action.payload };
    }

    case 'AUTOMATIONS/SET_TAGS': {
      return { ...state, tags: action.payload };
    }

    case 'AUTH/LOGOUT': {
      return initialState;
    }

    default:
      return state;
  }
};

export default automationReducer;
