import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ILineGroup } from '../../entities/farms.entities';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { useHistory } from 'react-router-dom';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { Button, Dropdown, Input, Title } from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import { selectFarmingMethods } from '../../store/utils/utils.selector';
import LineLocationModal from '../../components/shared/modal/LineLocationModal';
import { ILocation } from '../../entities/general.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IFormData {
  line_name: string;
  length: number | null;
  backbone?: number | null;
  points: Array<{ lat: number | null; lng: number | null }> | null;
  line_group_id: number | null;
  basket_count?: number | null;
  tank_area?: number | null;
  farming_method?: string;
}

const AddLines = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const params = useParams<{ idFarm: string }>();
  const width = useWidth();

  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);
  const currentFarm = farmsData.find(x => x.id === Number(params.idFarm));
  const farmingMethods = useSelector(selectFarmingMethods);

  const [formData, setFormData] = useState<IFormData>({
    line_name: `${(currentFarm?.lines.length ?? 0) + 1}`,
    length: null,
    points: null,
    line_group_id: null,
  });
  const [disabled, setDisabled] = useState(false);
  const [lineGroups, setLineGroups] = useState<ILineGroup[]>([]);
  const [visibleMap, setVisibleMap] = useState(false);

  const handleChangePoints = (i: number, x: 'lat' | 'lng', v: string) => {
    let points = formData.points ? [...formData.points] : [];
    while (points.length <= i) points.push({ lat: null, lng: null });
    points[i][x] = v.length <= 0 ? null : Number(v);
    setFormData(prv => ({ ...prv, points }));
  };
  const updateForm = (key: keyof IFormData, val: any) => {
    if (
      key === 'backbone' ||
      key === 'length' ||
      key === 'basket_count' ||
      key === 'tank_area'
    ) {
      val = val.length <= 0 ? null : Number(val);
    }
    setFormData(prv => ({ ...prv, [key]: val }));
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );

  const handleConfirmData = async () => {
    let data = { farm_id: currentFarm?.id, ...formData };
    if (!data.line_name) {
      showError('Fill in the field name');
      return;
    }
    if (!data.length) {
      data.length = 0;
    }
    if (currentFarm?.type === 'MUSSEL') {
      if (!data.backbone) {
        showError('Please input valid backbone');
        return;
      }
    } else if (currentFarm?.type === 'OYSTER') {
      if (!data.farming_method) {
        showError('Please select farming method');
        return;
      }
      if (!data.basket_count) {
        showError('Please input valid basket count');
        return;
      }
    } else {
      if (!data.tank_area) {
        showError('Please input valid tank area');
        return;
      }
    }
    if (
      data.points &&
      (data.points.length !== 2 ||
        data.points.some(x => x.lat === null || x.lng === null))
    ) {
      showError('Please input valid location points');
      return;
    }

    setDisabled(true);
    const res = await sendSingleRequest(
      data,
      'POST',
      'api/farm/line/lines',
      true,
    );
    if (!res.status) {
      showError(res.data?.message ?? 'Unknown error');
      setDisabled(false);
    } else {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(
            lang,
            res.data?.message ?? 'Line added successfully',
          ),
        }),
      );
      history.replace(`/farms/${currentFarm?.id}`);
    }
  };

  useEffect(() => {
    if (currentFarm) {
      sendSingleRequest(
        { farm_id: currentFarm.id },
        'GET',
        'api/line-group',
        true,
      ).then(res => {
        if (res.status) {
          setLineGroups(res.data);
        }
      });
    }
  }, [currentFarm]);

  const groupOptions = [
    { id: '0', value: '0', label: `( ${translate(lang, 'None')} )` },
    ...lineGroups.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.name,
    })),
  ];

  return (
    <div className='h-calc-80 bg-main'>
      <div className='container w-100'>
        <div className='add-line d-flex justify-content-center'>
          <div className='content'>
            <Title
              className='mb-16'
              size={5}
              color='black'
              align='default'
              fontWeight={700}
            >
              {translate(lang, 'Line details')}
            </Title>
            <Input
              label={translate(lang, 'Line name')}
              type='text'
              className='mb-16'
              value={formData.line_name}
              onChange={e => updateForm('line_name', e.target.value)}
            />
            {currentFarm?.type !== 'SEAWEED' && (
              <div className='mb-16'>
                <Input
                  label={translate(lang, 'Length')}
                  unit='m'
                  type='number'
                  value={formData.length?.toString() ?? ''}
                  onChange={e => updateForm('length', e.target.value)}
                />
              </div>
            )}
            {currentFarm?.type === 'MUSSEL' ? (
              <div className='mb-17'>
                <Input
                  label={translate(lang, 'Backbone')}
                  unit='m'
                  type='number'
                  value={formData.backbone?.toString() ?? ''}
                  onChange={e => updateForm('backbone', e.target.value)}
                />
              </div>
            ) : currentFarm?.type === 'OYSTER' ? (
              <>
                <div className='mb-17'>
                  <Dropdown
                    label={translate(lang, 'Farming method')}
                    options={farmingMethods.map(x => ({
                      id: x.name,
                      value: x.name,
                      label: x.name,
                    }))}
                    value={formData.farming_method}
                    onChange={v => updateForm('farming_method', v)}
                  />
                </div>
                <div className='mb-17'>
                  <Input
                    label={translate(lang, 'Baskets capacity')}
                    type='number'
                    value={formData.basket_count?.toString() ?? ''}
                    onChange={e => updateForm('basket_count', e.target.value)}
                  />
                </div>
              </>
            ) : (
              <div className='mb-17'>
                <Input
                  label={translate(lang, 'Tank area')}
                  type='number'
                  unit='m²'
                  value={formData.tank_area?.toString() ?? ''}
                  onChange={e => updateForm('tank_area', e.target.value)}
                />
              </div>
            )}
            <div className='pb-24'>
              <Dropdown
                label={translate(lang, 'Line group')}
                options={groupOptions}
                value={formData.line_group_id?.toString() ?? '0'}
                onChange={v =>
                  updateForm('line_group_id', v === '0' ? null : Number(v))
                }
              />
            </div>
            <div className='pb-10'>
              {[0, 1].map(x => (
                <div
                  key={x}
                  className='pb-17 w-100 d-flex justify-content-between'
                >
                  <div className='mr-17'>
                    <Input
                      label={translate(
                        lang,
                        x === 0
                          ? 'Start location Latitude'
                          : 'End location Latitude',
                      )}
                      placeholder={translate(lang, 'Latitude')}
                      type='number'
                      value={
                        formData.points && formData.points.length > x
                          ? formData.points[x].lat?.toString() ?? ''
                          : ''
                      }
                      onChange={e =>
                        handleChangePoints(x, 'lat', e.target.value)
                      }
                    />
                  </div>
                  <div className='ml-17 mr-17'>
                    <Input
                      label={translate(lang, 'Longitude')}
                      className='mr-17'
                      type='number'
                      value={
                        formData.points && formData.points.length > x
                          ? formData.points[x].lng?.toString() ?? ''
                          : ''
                      }
                      onChange={e =>
                        handleChangePoints(x, 'lng', e.target.value)
                      }
                    />
                  </div>
                  <div className='ml-17 d-flex align-items-end'>
                    <Button
                      width='wide'
                      size={1}
                      type='fill'
                      color='blue'
                      onClick={() => setVisibleMap(true)}
                    >
                      {translate(lang, 'Pickup')}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className='mt-24 mb-32 line-button d-flex justify-content-end align-items-center'>
              <Link to={`/farms/${currentFarm?.id}`}>
                <Button
                  width={width < 769 ? 'wide' : 'small'}
                  size={1}
                  type='transparent'
                  color='blue'
                >
                  {translate(lang, 'Cancel')}
                </Button>
              </Link>
              <Button
                width={width < 769 ? 'wide' : 'small'}
                size={1}
                type='fill'
                color='blue'
                className='ml-16'
                onClick={handleConfirmData}
                disabled={disabled}
              >
                {translate(lang, 'Confirm')}
              </Button>
            </div>
            {visibleMap && currentFarm && (
              <LineLocationModal
                visible={true}
                farmID={currentFarm?.id}
                points={
                  formData.points
                    ? (formData.points.filter(
                        x => !!x.lat && !!x.lat,
                      ) as ILocation[])
                    : undefined
                }
                onConfirm={points => {
                  updateForm('points', points);
                  setVisibleMap(false);
                }}
                onCancel={() => setVisibleMap(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLines;
