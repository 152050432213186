import { useState, useCallback, useEffect } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { CloseIcon, Spinner, Subtitle } from '../shared';
import { Link } from 'react-router-dom';
import { sendSingleRequest } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { IBoatResource } from '../../entities/boat.entities';
import { ITimeTrack } from '../../entities/general.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const TimeRange = (start: number, finish: number) => {
  const start_date = moment(start * 1000).format('MMM Do');
  const finish_date = moment(finish * 1000).format('MMM Do');

  return start_date === finish_date
    ? `${moment(start * 1000).format('LT')} - ${moment(finish * 1000).format(
        'LT',
      )}`
    : `${start_date} ${moment(start * 1000).format(
        'LT',
      )} - ${finish_date} ${moment(finish * 1000).format('LT')}`;
};

const TimeTracks = (tracks: ITimeTrack[]) =>
  tracks.map(x => TimeRange(x.start_time, x.finish_time)).join(', ');

interface IReportData {
  data: Array<{
    type: string;
    data: any;
    farm: { id: number; name: string; farm_number: string } | null;
    line: { id: number; line_name: string } | null;
  }>;
  boat: IBoatResource | null;
}

const ReportItem = ({ data }: { data: IReportData }) => {
  const lang = useSelector(selectLang);

  return (
    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
      <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
        {data.boat
          ? translate(
              lang,
              'Boat %s',
              `${data.boat.name}(${data.boat.reg_number}):`,
            )
          : translate(lang, 'No Boat') + ':'}
      </div>
      <ul>
        {data.data.map((item, i) => (
          <li key={i} style={{ marginLeft: '20px' }}>
            {item.type === 'SEEDING' ? (
              <>
                {`${TimeTracks(item.data.time_tracks)} SEED `}
                <Link
                  to={`/farms/${item.farm?.id}/${item.line?.id}`}
                  style={{ fontStyle: 'italic', textDecoration: 'underline' }}
                >
                  {item.farm?.farm_number} line {item.line?.line_name}
                </Link>
              </>
            ) : item.type === 'ASSESSMENT' ? (
              <>
                {`${TimeTracks(item.data.time_tracks)} Assessment `}
                <Link
                  to={`/farms/${item.farm?.id}/${item.line?.id}`}
                  style={{ fontStyle: 'italic', textDecoration: 'underline' }}
                >
                  {item.farm?.farm_number} line {item.line?.line_name}
                </Link>
              </>
            ) : item.type === 'MAINTENANCE' ? (
              <>
                {`${TimeTracks(item.data.time_tracks)} Maintenance `}
                <Link
                  to={`/farms/${item.farm?.id}/${item.line?.id}`}
                  style={{ fontStyle: 'italic', textDecoration: 'underline' }}
                >
                  {item.farm?.farm_number} line {item.line?.line_name}
                </Link>
              </>
            ) : item.type === 'HARVEST' ? (
              <>
                {`${TimeTracks(item.data.time_tracks)} Harvest `}
                <Link
                  to={`/farms/${item.farm?.id}/${item.line?.id}`}
                  style={{ fontStyle: 'italic', textDecoration: 'underline' }}
                >
                  {item.farm?.farm_number} line {item.line?.line_name}
                </Link>
              </>
            ) : (
              <>{`${TimeTracks(item.data.time_tracks)} ${item.data.title}`}</>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

interface Props {
  visible: boolean;
  dateStr: string;
  className?: string;
  onClose: () => void;
}

const DailyReportModal = ({ visible, dateStr, className, onClose }: Props) => {
  const lang = useSelector(selectLang);
  const dispatch = useDispatch<any>();
  const day = moment(dateStr).toDate().getTime();
  const nextDay = moment(day).add(1, 'd').toDate().getTime();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IReportData[]>();

  const loadData = useCallback(() => {
    setLoading(true);
    sendSingleRequest(
      {
        min_date: Math.floor(day / 1000),
        max_date: Math.floor(nextDay / 1000),
      },
      'GET',
      'api/report/daily-data',
      true,
    ).then(res => {
      setLoading(false);
      if (res.status) {
        let result: IReportData[] = [];
        const data = res.data;
        data.sort(
          (a: any, b: any) =>
            a.data.time_tracks[0].start_time - b.data.time_tracks[0].start_time,
        );
        for (let item of data) {
          const i = result.findIndex(x =>
            !item.boat ? !x.boat : x.boat?.id === item.boat.id,
          );
          if (i < 0) {
            result.push({
              boat: item.boat,
              data: [item],
            });
          } else {
            result[i].data.push(item);
          }
        }
        setData(result);
      } else {
        dispatch(
          showFeedback({
            isMessageModal: true,
            type: 'error',
            message: translate(lang, res.data?.message ?? 'Failed to load'),
          }),
        );
      }
    });
  }, []);

  useEffect(() => loadData(), [loadData]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {`${moment(day).format('DD.MM.YYYY')} day report`}
          </Subtitle>
        </div>
        <div className='pt-3'>
          {loading ? (
            <div>
              <Spinner />
            </div>
          ) : !data || data.length <= 0 ? (
            <div
              style={{
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              {translate(lang, 'No Data')}
            </div>
          ) : (
            data.map((item, i) => <ReportItem key={i} data={item} />)
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DailyReportModal;
