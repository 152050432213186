import { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { Button, Spinner, Title } from '../../components/shared';
import { adminLoginAsUser } from '../../store/auth/auth.actions';
import { useHistory } from 'react-router-dom';
import { showFeedback } from '../../store/ui/ui.actions';
import moment from 'moment';
import { toMillisecond } from '../../util/toggleSecondMillisecond';
import './styles.scss';

interface ILastLocation {
  created_at: number;
  content: any | null;
}

interface IUserResource {
  id: number;
  name: string;
  email: string;
  company_name: string | null;
  company_address: string | null;
  phone_number: string | null;
  account_id: number;
  status: number;
  is_verified: boolean;
  last_location?: ILastLocation;
}

const AdminUsersPage = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const [users, setUsers] = useState<IUserResource[]>();
  const [loading, setLoading] = useState(false);

  const loadUsers = useCallback(() => {
    setLoading(true);
    sendSingleRequest({}, 'GET', 'api/admin/users', true).then(res => {
      setLoading(false);
      if (res.status) {
        setUsers(res.data);
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: res.data?.message ?? 'Something went wrong',
          }),
        );
      }
    });
  }, []);

  const loginAsUser = (user: IUserResource) => {
    setLoading(true);
    dispatch(adminLoginAsUser({ user_id: user.id })).then((r: any) => {
      if (r === true) {
        history.replace('/');
      } else {
        setLoading(false);
        dispatch(showFeedback({ isMessage: true, type: 'error', message: r }));
      }
    });
  };

  useEffect(() => loadUsers(), [loadUsers]);

  const columns = [
    {
      title: 'ID',
      key: 'id',
      render: (x: IUserResource) => <span>{x.id}</span>,
    },
    {
      title: 'Name',
      key: 'name',
      render: (x: IUserResource) => <span>{x.name}</span>,
    },
    {
      title: 'Email',
      key: 'email',
      render: (x: IUserResource) =>
        x.last_location ? (
          <span>
            <div>{x.email}</div>
            <div style={{ color: 'gray' }}>
              {`Last activated on mobile device at ${moment(
                toMillisecond(
                  x.last_location.content?.rec_time ??
                    x.last_location.created_at,
                ),
              ).format('lll')}`}
            </div>
          </span>
        ) : (
          <span>{x.email}</span>
        ),
    },
    {
      title: 'Company name',
      key: 'company_name',
      render: (x: IUserResource) => <span>{x.company_name ?? ''}</span>,
    },
    {
      title: 'Company address',
      key: 'company_address',
      render: (x: IUserResource) => <span>{x.company_address ?? ''}</span>,
    },
    {
      title: 'Phone number',
      key: 'phone_number',
      render: (x: IUserResource) => <span>{x.phone_number ?? ''}</span>,
    },
    {
      title: 'Account ID',
      key: 'account_id',
      render: (x: IUserResource) => <span>{x.account_id}</span>,
    },
    {
      title: 'Activated',
      key: 'status',
      render: (x: IUserResource) => (
        <span>
          {x.status === 1 ? 'Activated' : x.status === 0 ? 'Pending' : 'Closed'}
        </span>
      ),
    },
    {
      title: 'Verified',
      key: 'is_verified',
      render: (x: IUserResource) => <span>{x.is_verified ? 'Yes' : 'No'}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (x: IUserResource) => (
        <Button
          color='blue'
          size={0}
          width='small'
          type='fill'
          onClick={loginAsUser.bind(this, x)}
        >
          Login
        </Button>
      ),
    },
    {
      title: '',
      key: 'right',
      render: (x: IUserResource) => <span></span>,
    },
  ];

  return (
    <div className='bg-secondary min-height'>
      <div className='container'>
        <div className='queued-page-header justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            All Users
          </Title>
        </div>
        <div className='w-100 mt-17 pb-32'>
          {loading ? (
            <div className='d-flex justify-content-center align-items-center min-height'>
              <Spinner />
            </div>
          ) : (
            <Table
              rowKey='id'
              className='table table--isFarm'
              pagination={false}
              columns={columns}
              dataSource={users}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminUsersPage;
