import { useSelector } from 'react-redux';
import { Paragrapgh } from '../shared';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import northIcon from '../../images/north-dir-btn.png';
import eastIcon from '../../images/east-dir-btn.png';
import southIcon from '../../images/south-dir-btn.png';
import westIcon from '../../images/west-dir-btn.png';

interface Props {
  value?: string | null;
  onChange: (value: string | null) => void;
}

const DirectionForm = ({ value, onChange }: Props) => {
  const lang = useSelector(selectLang);

  return (
    <div className='direction-form'>
      <Paragrapgh
        className='mb-4 d-block'
        size={2}
        color='black-2'
        align='default'
        fontWeight={400}
      >
        {translate(lang, 'Direction')}
      </Paragrapgh>
      <div className='btn-bar'>
        <div className={`dir-btn ${value === 'N' ? 'selected' : ''}`}>
          <img
            src={northIcon}
            alt='North'
            onClick={() => onChange(value === 'N' ? null : 'N')}
          />
        </div>
        <div className={`dir-btn ${value === 'E' ? 'selected' : ''}`}>
          <img
            src={eastIcon}
            alt='East'
            onClick={() => onChange(value === 'E' ? null : 'E')}
          />
        </div>
        <div className={`dir-btn ${value === 'S' ? 'selected' : ''}`}>
          <img
            src={southIcon}
            alt='South'
            onClick={() => onChange(value === 'S' ? null : 'S')}
          />
        </div>
        <div className={`dir-btn ${value === 'W' ? 'selected' : ''}`}>
          <img
            src={westIcon}
            alt='West'
            onClick={() => onChange(value === 'W' ? null : 'W')}
          />
        </div>
      </div>
    </div>
  );
};

export default DirectionForm;
