import { Modal } from 'antd';
import { formatNumber } from '../../entities/util-functions';
import { CloseIcon, Subtitle } from '../shared';
import { IHarvestSpat } from '../../entities/growing.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  visible: boolean;
  onClose: () => void;
  className?: string;
  data?: IHarvestSpat[];
}

const HarvestSpatsModal = ({ visible, onClose, className, data }: Props) => {
  const lang = useSelector(selectLang);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      closeIcon={<CloseIcon />}
      width={700}
      footer={null}
    >
      <div className='section-modal wrap'>
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Original harvested spats')}
          </Subtitle>
        </div>
        <div className='mb-17'>
          <div className='item-row'>
            <div className='item-cell strong'>
              {translate(lang, 'Source name')}
            </div>
            <div className='item-cell strong'>
              {translate(lang, 'Harvest amount')} (kg)
            </div>
            <div className='item-cell strong'>
              {translate(lang, 'Harvested length')} (m)
            </div>
          </div>
          <div className='section mb-0'>
            {data?.map((x, i) => (
              <div className='item-row' key={i}>
                <div className='item-cell'>{x.spat_storage.source}</div>
                <div className='item-cell'>{formatNumber(x.amount)}</div>
                <div className='item-cell'>{formatNumber(x.line_length)}</div>
              </div>
            ))}
          </div>
          <div className='item-row'>
            <div className='item-cell strong'>{translate(lang, 'Total')}</div>
            <div className='item-cell strong'>
              {formatNumber(data?.reduce((p, c) => p + c.amount, 0) ?? 0)}
            </div>
            <div className='item-cell strong'>
              {formatNumber(data?.reduce((p, c) => p + c.line_length, 0) ?? 0)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HarvestSpatsModal;
