import { useSelector } from 'react-redux';
import {
  CloseIcon,
  Datepicker,
  Dropdown,
  Subtitle,
} from '../../components/shared';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { useMemo, useState } from 'react';
import { Alert, Button, List, Modal, Typography, message } from 'antd';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { Link } from 'react-router-dom';
import { ILineResource } from '../../entities/farms.entities';
import { selectBoats } from '../../store/users/users.selector';
import { selectProfile } from '../../store/auth/auth.selector';
import moment from 'moment';
import { ITaskRequest } from '../../entities/task.entities';
import { sendSingleRequest } from '../../apis';
import { selectLang } from '../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../lib/lang.helper';

interface IFarmLine {
  farm_id: number;
  farm_name: string;
  line_id: number;
  line_name: string;
}

interface ILastHarvest extends IFarmLine {
  complete_date: number;
}

interface IForecastHarvest extends IFarmLine {
  forecast_date: number;
}

interface IBaseData {
  last_harvest: ILastHarvest | null;
  tasks: Array<{ id: number; farm_id: number; line_id: number }>;
  forecast_harvests: IForecastHarvest[];
}

interface Props {
  baseData: IBaseData;
  loadData: () => void;
}

const HarvestSuggest = ({ baseData, loadData }: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);
  const lastHarvest = baseData.last_harvest,
    tasks = baseData.tasks;
  const boats = useSelector(selectBoats);
  const profile = useSelector(selectProfile);
  const harvestPredictions = baseData.forecast_harvests.slice(0, 10);

  const [showAccept, setShowAccept] = useState(false);
  const [boatId, setBoatId] = useState(profile?.boat_id);
  const [dueDate, setDueDate] = useState(moment().toDate().getTime());
  const [disabled, setDisabled] = useState(false);

  const { farmsInfo, totReadyCnt, totGrowingCnt, suggested } = useMemo(() => {
    const farmsInfo = farmsData.map(x => {
      const readyLines = x.lines.filter(
        x => x.growing_cycle && !!x.growing_cycle.ready_harvest,
      );
      const growingLines = x.lines.filter(x => !!x.growing_cycle);
      return {
        id: x.id,
        name: x.name,
        growingLines: growingLines,
        readyLines: readyLines,
      };
    });
    farmsInfo.sort((a, b) => b.readyLines.length - a.readyLines.length);
    const totReadyCnt = farmsInfo.reduce((p, c) => p + c.readyLines.length, 0),
      totGrowingCnt = farmsInfo.reduce((p, c) => p + c.growingLines.length, 0);
    const growingLines = farmsData.reduce(
      (p, c) => [
        ...p,
        ...c.lines.filter(
          x => !!x.growing_cycle && !!x.growing_cycle.last_assessment,
        ),
      ],
      [] as ILineResource[],
    );
    const readyLines = growingLines.filter(
      x =>
        !!x.growing_cycle?.ready_harvest &&
        tasks.every(t => t.line_id !== x.id),
    );

    let suggestLine: ILineResource | null = null;
    if (readyLines.length > 0) {
      readyLines.sort(
        (a, b) =>
          (a.growing_cycle?.ready_harvest ?? 0) -
          (b.growing_cycle?.ready_harvest ?? 0),
      );
      suggestLine = readyLines[0];
    } else if (growingLines.length > 0) {
      const avg =
        growingLines.reduce(
          (p, c) => p + (c.growing_cycle?.last_assessment?.shell_size.avg ?? 0),
          0,
        ) / growingLines.length;
      let minD = -1;
      for (let x of growingLines) {
        if (!x.growing_cycle?.last_assessment) continue;
        const d = Math.abs(
          x.growing_cycle.last_assessment.shell_size.avg - avg,
        );
        if (minD === -1 || minD > d) {
          minD = d;
          suggestLine = x;
        }
      }
    }
    const suggested = suggestLine
      ? {
          farm_id: suggestLine.farm_id,
          farm_name: suggestLine.farm_name,
          line_id: suggestLine.id,
          line_name: suggestLine.line_name,
          is_ready: !!suggestLine.growing_cycle?.ready_harvest,
          shell_size:
            suggestLine.growing_cycle?.last_assessment?.shell_size.avg,
          date: suggestLine.growing_cycle?.last_assessment?.assessment_date,
        }
      : null;
    return { farmsInfo, totReadyCnt, totGrowingCnt, suggested };
  }, [farmsData, tasks]);

  const acceptClick = async () => {
    if (!suggested || !suggested.is_ready) {
      message.error(translate(lang, 'There are no lines ready to harvest'));
      return;
    }
    if (!boatId) {
      message.error(translate(lang, 'Please select a boat'));
      return;
    }
    const form: ITaskRequest = {
      farm_id: suggested.farm_id,
      line_id: suggested.line_id,
      title: `Harvest farm ${suggested.farm_name} line ${suggested.line_name}`,
      content: 'Please harvest this line',
      due_date: dueDate,
      assigned_to: 0,
      boat_id: boatId,
      type: 'HARVEST',
    };
    setDisabled(true);
    const res = await sendSingleRequest(form, 'POST', 'api/task/tasks', true);
    if (res.status) {
      message.success(
        translate(lang, 'Task %s has been created successfully', form.title),
      );
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'));
    }
    setShowAccept(false);
    setDisabled(false);
    await loadData();
  };

  return (
    <div id='harvest-suggest' className='pt-28 pb-28'>
      <div className='mb-18'>
        <Subtitle size={1} color='black' align='left' fontWeight={600}>
          {translate(lang, 'Harvest suggestions')}
        </Subtitle>
      </div>
      <div className='ml-17'>
        <div className='suggest-sentence'>
          <span>{'You have '}</span>
          <Typography.Text mark>{totGrowingCnt}</Typography.Text>
          <span>{' seeded line(s) in final stage, '}</span>
          <Typography.Text mark>{totReadyCnt}</Typography.Text>
          <span>{' line(s) marked as ready to harvest.'}</span>
        </div>
        <div className='suggest-sentence'>
          {lastHarvest && (
            <span>
              {componentTranslate(
                lang,
                'Your last final harvest was on farm %s line %s on day %s',
                <Typography.Text mark key={1}>
                  {lastHarvest.farm_name}
                </Typography.Text>,
                <Typography.Text mark key={2}>
                  {lastHarvest.line_name}
                </Typography.Text>,
                <Typography.Text mark key={3}>
                  {defaultDateFormat(lastHarvest.complete_date)}
                </Typography.Text>,
              )}
            </span>
          )}
        </div>
      </div>
      {farmsInfo && (
        <div className='mt-18 mb-18'>
          <List
            bordered
            dataSource={farmsInfo}
            renderItem={item => (
              <List.Item>
                <Link to={`/farms/${item.id}`}>
                  <span>
                    {componentTranslate(
                      lang,
                      'Farm %s has %s line(s) seeded growing, and %s line(s) are ready to harvest',
                      <strong key={1}>{item.name}</strong>,
                      <strong key={2}>{item.growingLines.length}</strong>,
                      <strong key={3}>{item.readyLines.length}</strong>,
                    )}
                  </span>
                </Link>
              </List.Item>
            )}
          />
          <div className='mt-18'>
            <div className='suggest-sentence'>
              <Alert
                className='suggest-warning'
                message={translate(lang, '_suggest_warning')}
                type='warning'
                showIcon
              />
            </div>
            {suggested && (
              <Alert
                className='mt-18 mb-18 suggest-info'
                message={'Our suggestion'}
                description={
                  <div>
                    {suggested.is_ready ? (
                      <div className='mb-7'>
                        {componentTranslate(
                          lang,
                          '_harvest_suggest_line',
                          <strong key={1}>
                            <Link
                              to={`/farms/${suggested.farm_id}/${suggested.line_id}`}
                            >
                              {suggested.line_name}
                            </Link>
                          </strong>,
                          <strong key={2}>
                            <Link to={`/farms/${suggested.farm_id}`}>
                              {suggested.farm_name}
                            </Link>
                          </strong>,
                        )}
                      </div>
                    ) : (
                      <div className='mb-7'>
                        {componentTranslate(
                          lang,
                          '_harvest_suggest_check',
                          <strong key={1}>
                            <Link
                              to={`/farms/${suggested.farm_id}/${suggested.line_id}`}
                            >
                              {suggested.line_name}
                            </Link>
                          </strong>,
                          <strong key={2}>
                            <Link to={`/farms/${suggested.farm_id}`}>
                              {suggested.farm_name}
                            </Link>
                          </strong>,
                          <strong key={3}>
                            {`${suggested.shell_size} mm`}
                          </strong>,
                          <strong key={4}>
                            {defaultDateFormat(suggested.date)}
                          </strong>,
                        )}
                      </div>
                    )}
                    {suggested.is_ready && (
                      <div className='d-flex justify-content-end'>
                        <Button
                          size='small'
                          type='primary'
                          onClick={() => setShowAccept(true)}
                        >
                          {translate(lang, 'Accept')}
                        </Button>
                      </div>
                    )}
                  </div>
                }
                showIcon={true}
                type='info'
                closable={false}
              />
            )}
            {harvestPredictions.length > 0 && (
              <Alert
                className='mt-18 mb-18 suggest-info'
                message={'Our predictions'}
                description={harvestPredictions.map((x, i) => (
                  <Link
                    key={i}
                    className='mt-8'
                    to={`/farms/${x.farm_id}/${x.line_id}`}
                  >
                    {componentTranslate(
                      lang,
                      'Line %s on farm %s need to be harvested on %s',
                      <strong key={1}>{x.line_name}</strong>,
                      <strong key={2}>{x.farm_name}</strong>,
                      <strong key={3}>
                        {defaultDateFormat(x.forecast_date)}
                      </strong>,
                    )}
                  </Link>
                ))}
                showIcon={true}
                type='info'
                closable={false}
              />
            )}
          </div>
        </div>
      )}
      {suggested && showAccept && (
        <Modal
          visible={true}
          onCancel={() => setShowAccept(false)}
          closable
          closeIcon={<CloseIcon />}
          footer={null}
        >
          <div className='wrap'>
            <div className='mt-28 mb-28'>
              <Dropdown
                label={translate(lang, '_suggest_boat_select_q')}
                options={boats.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} (${x.reg_number})`,
                }))}
                value={boatId?.toString()}
                onChange={v => setBoatId(Number(v))}
              />
            </div>
            <div className='mt-28 mb-28'>
              <Datepicker
                label={translate(lang, '_suggest_date_q')}
                defaultValue={dueDate}
                onChange={e => e && setDueDate(e.toDate().getTime())}
                required
              />
            </div>
            <div className='mt-32 d-flex justify-content-end'>
              <Button
                size='large'
                disabled={disabled}
                onClick={acceptClick}
                type='primary'
              >
                {translate(lang, 'Create task')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HarvestSuggest;
