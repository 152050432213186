import { ReactNode } from 'react';
import { Modal } from 'antd';
import Button from '../button/Button';
import CloseIcon from '../CloseIcon';
import Subtitle from '../subtitle/Subtitle';
import { useWidth } from '../../../util/useWidth';
import ModalFeedbackView from '../feedback/ModalFeedbackView';
import { translate } from '../../../lib/lang.helper';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../store/ui/ui.selector';
import './styles.scss';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  onConfirmTwo: () => void;
  className?: string;
  children: ReactNode;
  title: string;
  visible: boolean;
  disabled?: boolean;
  confirmNameBtn?: string | undefined;
  confirmNameBtnTwo?: string | undefined;
  modalWidth?: number | undefined;
  hideCancel?: boolean;
}

const InputModalTwo = ({
  visible,
  onCancel,
  onConfirm,
  onConfirmTwo,
  title,
  children,
  disabled,
  className,
  confirmNameBtn,
  confirmNameBtnTwo,
  modalWidth,
  hideCancel = false,
}: Props) => {
  const width = useWidth();
  const lang = useSelector(selectLang);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className={className}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={modalWidth !== undefined ? modalWidth : 600}
    >
      <ModalFeedbackView />
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        {children}
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        {!hideCancel && (
          <Button
            width={width < 769 ? 'wide' : 'small'}
            size={2}
            type='transparent'
            color='red'
            onClick={onCancel}
          >
            {translate(lang, 'Cancel')}
          </Button>
        )}
        <Button
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='green'
          className='ml-16'
          onClick={onConfirm}
          disabled={disabled}
        >
          {confirmNameBtn || <>{translate(lang, 'Confirm')}</>}
        </Button>
        <Button
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='blue'
          className='ml-16'
          onClick={onConfirmTwo}
          disabled={disabled}
        >
          {confirmNameBtnTwo || <>{translate(lang, 'Confirm')}</>}
        </Button>
      </div>
    </Modal>
  );
};

export default InputModalTwo;
