import { useSelector } from 'react-redux';
import { IAssessmentResource } from '../../entities/farms.entities';
import { Link } from 'react-router-dom';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  assessData: IAssessmentResource;
  onShowAssessment: (d: any) => void;
}

const AssessmentCell = ({ assessData, onShowAssessment }: Props) => {
  const lang = useSelector(selectLang);
  const farm = useSelector(selectFarmsData).find(f =>
    f.lines.some(x => x.id === assessData.line_id),
  );
  const line = farm?.lines.find(x => x.id === assessData.line_id);
  const { shell_size } = assessData;
  const handleClick = (event: any) => {
    event.preventDefault();
    onShowAssessment({ line: line, data: assessData });
  };
  return (
    <div className='--entity --assessment' onClick={handleClick.bind(this)}>
      <div className='--header'>
        <span>{translate(lang, 'assessment')}</span>
      </div>
      <div className='--title'>
        <div>
          {farm?.name} - {line?.line_name}
        </div>
        <div className='mt-7'>
          {`${shell_size.min} ~ ${shell_size.max}, ${shell_size.avg} (mm)`}
        </div>
      </div>
      <div className='--btn-bar'>
        <Link to={`/farms/${farm?.id}/${line?.id}`} className='--view'>
          {translate(lang, 'View')}
        </Link>
      </div>
    </div>
  );
};

export default AssessmentCell;
