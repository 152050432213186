import { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  CreditCardIcon,
  Dropdown,
  KeyIcon,
  Navbar,
  UserIcon,
  SettingIcon,
} from '../../components/shared';
import ProfilePage from './ProfilePage';
import Security from './Security';
import SettingsPage from './SettingsPage';
import Xero from './Xero';
import Subscription from './Subscription';
import { useWidth } from '../../util/useWidth';
import EmailPreference from './EmailPreference';
import TaskSettings from './TaskSettings';
import ProcessBagSettings from './ProcessBagSettings';
import StaticFieldsSetting from './StaticFieldsSetting';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { IMainList, INavbar } from '../../entities/general.entities';

const ProfileRouter = () => {
  const history = useHistory();
  const width = useWidth();
  const lang = useSelector(selectLang);

  const [current, setCurrent] = useState(history.location.pathname);

  useEffect(() => {
    return history.listen(location => {
      setCurrent(location.pathname);
    });
  }, [history]);

  const items: IMainList[] = [
    {
      value: '/profile',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <UserIcon />
          <span className='ml-16'>{translate(lang, 'Profile')}</span>
        </p>
      ),
      id: '/profile',
    },
    {
      value: '/profile/security',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <KeyIcon />
          <span className='ml-16'>{translate(lang, 'Security')}</span>
        </p>
      ),
      id: '/profile/security',
    },
    {
      value: '/profile/subscription',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <CreditCardIcon />
          <span className='ml-16'>{translate(lang, 'Plan & billing')}</span>
        </p>
      ),
      id: '/profile/subscription',
    },
    {
      value: '/profile/setting',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <SettingIcon />
          <span className='ml-16'>{translate(lang, 'User Setting')}</span>
        </p>
      ),
      id: '/profile/subscription',
    },
    {
      id: '/profile/email-preference',
      value: '/profile/email-preference',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <SettingIcon />
          <span className='ml-16'>{translate(lang, 'Email Preference')}</span>
        </p>
      ),
    },
    {
      value: '/profile/xero',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <SettingIcon />
          <span className='ml-16'> {translate(lang, 'Xero Integration')} </span>
        </p>
      ),
      id: '/profile/xero',
    },
    {
      value: '/profile/processing',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <SettingIcon />
          <span className='ml-16'>{translate(lang, 'Manage Processing')}</span>
        </p>
      ),
      id: '/profile/processing',
    },
    {
      value: '/profile/static-fields',
      label: (
        <p className='d-flex align-items-center mb-0'>
          <SettingIcon />
          <span className='ml-16'>{translate(lang, 'Static Fields')}</span>
        </p>
      ),
      id: '/profile/static-fields',
    },
  ];

  const navbarItems: INavbar[] = [
    {
      title: translate(lang, 'Profile'),
      icon: <UserIcon />,
      link: '/profile',
      id: '/profile',
    },
    {
      title: translate(lang, 'Security'),
      icon: <KeyIcon />,
      link: '/profile/security',
      id: '/profile/security',
    },
    {
      title: translate(lang, 'Plan & billing'),
      icon: <CreditCardIcon />,
      link: '/profile/subscription',
      id: '/profile/subscription',
    },
    {
      title: translate(lang, 'Setting'),
      icon: <SettingIcon />,
      link: '/profile/setting',
      id: '/profile/setting',
    },
    {
      title: translate(lang, 'Email Preference'),
      id: '/profile/email-preference',
      icon: <SettingIcon />,
      link: '/profile/email-preference',
    },
    {
      title: translate(lang, 'Budget Setting'),
      icon: <SettingIcon />,
      link: '/profile/xero',
      id: '/profile/xero',
    },
    {
      title: translate(lang, 'Task Setting'),
      icon: <SettingIcon />,
      link: '/profile/task-setting',
      id: '/profile/task-setting',
    },
    {
      title: translate(lang, 'Manage Processing'),
      icon: <SettingIcon />,
      link: '/profile/processing',
      id: '/profile/processing',
    },
    {
      title: translate(lang, 'Static Fields'),
      icon: <SettingIcon />,
      link: '/profile/static-fields',
      id: '/profile/static-fields',
    },
  ];

  const handleOnSelectPage = (value: string) => {
    history.push(value);
  };

  return (
    <div className='container w-100'>
      {width < 769 && (
        <Dropdown
          className='mt-16 dropdown-profile'
          placeholder='placeholder'
          defaultValue={current}
          label=''
          onChange={value => handleOnSelectPage(value)}
          options={items}
        />
      )}
      <div className='user-layout'>
        <div className='navbar'>
          {width > 768 && (
            <Navbar
              current={current}
              direction='vertical'
              items={navbarItems}
            />
          )}
        </div>
        <Switch>
          <Route exact path='/profile'>
            <ProfilePage />
          </Route>
          <Route path='/profile/security'>
            <Security />
          </Route>
          <Route path='/profile/subscription'>
            <Subscription />
          </Route>
          <Route path='/profile/setting'>
            <SettingsPage />
          </Route>
          <Route path='/profile/email-preference'>
            <EmailPreference />
          </Route>
          <Route path='/profile/xero'>
            <Xero />
          </Route>
          <Route path='/profile/task-setting'>
            <TaskSettings />
          </Route>
          <Route path='/profile/processing'>
            <ProcessBagSettings />
          </Route>
          <Route path='/profile/static-fields'>
            <StaticFieldsSetting />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default ProfileRouter;
