import { useState } from 'react';
import { InputModal, Input, Paragrapgh, CheckboxButton } from '../shared';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

interface IForm {
  name: string;
  flag?: boolean;
  num_val?: number | null;
}

interface Props {
  type: 'delete' | 'create' | 'update';
  data: IForm;
  onCancel: () => void;
  onConfirm: (d: IForm) => void;
  title: string;
  visible: boolean;
  disabled?: boolean;
  flagLabel?: string;
  flagPlaceholder?: string;
  numLabel?: string;
  numUnit?: string;
}

const ModalUtil = ({
  type,
  data,
  onCancel,
  onConfirm,
  title,
  visible,
  disabled,
  flagLabel,
  flagPlaceholder,
  numLabel,
  numUnit,
}: Props) => {
  const lang = useSelector(selectLang);

  const [form, setForm] = useState(data);

  return (
    <InputModal
      visible={visible}
      onCancel={() => {
        setForm({ name: '' });
        onCancel();
      }}
      title={title}
      type={type === 'create' ? 'create' : 'confirm'}
      onConfirm={() => onConfirm(form)}
      disabled={disabled}
    >
      {type !== 'delete' && (
        <>
          <div className='mb-17'>
            <Input
              type='text'
              onChange={e => setForm({ ...form, name: e.target.value })}
              placeholder={data.name}
              value={form.name}
              label={translate(lang, 'Name')}
              max={255}
              required
            />
          </div>
          {!!flagLabel && (
            <div className='mb-17'>
              <CheckboxButton
                label={flagLabel}
                placeholder={flagPlaceholder}
                checked={form.flag}
                onChange={() => setForm({ ...form, flag: !form.flag })}
              />
            </div>
          )}
          {!!numLabel && (
            <div className='mb-32'>
              <Input
                label={numLabel}
                type='number'
                onChange={e =>
                  setForm({
                    ...form,
                    num_val:
                      e.target.value.length > 0 ? Number(e.target.value) : null,
                  })
                }
                placeholder={data.num_val?.toString() ?? ''}
                value={form.num_val?.toString() ?? ''}
                unit={numUnit}
                required
              />
            </div>
          )}
        </>
      )}
      {type === 'delete' && (
        <Paragrapgh
          color='black'
          align='left'
          className='mb-16'
          size={2}
          fontWeight={400}
        >
          {translate(lang, 'Are you sure to delete the record?')}
        </Paragrapgh>
      )}
    </InputModal>
  );
};

export default ModalUtil;
