import { Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { sendSingleRequest } from '../../apis';
import WeatherInfoModal from '../../components/farm-modals/WeatherInfoModal';
import { ModalComponent, Spinner, Title } from '../../components/shared';
import weather_icon from '../../images/weather-icon.png';
import { IWeatherConditionResource } from '../../entities/general.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';

interface ISecurityRow {
  id: number;
  line_id: number;
  line: {
    id: number;
    line_name: string;
  };
  farm: {
    id: number;
    name: string;
    farm_number: string;
  };
  user_id: number;
  user: {
    id: number;
    name: string;
  };
  content_type: string;
  comment: string | null;
  images: string[];
  weather_condition: any | null;
  created_at: number;
}

const columns = (lang: TLang | undefined) => [
  {
    key: 'farm',
    title: translate(lang, 'Farm'),
    render: (x: ISecurityRow) => <span>{x.farm.name}</span>,
  },
  {
    key: 'line',
    title: translate(lang, 'Line'),
    render: (x: ISecurityRow) => <span>{x.line.line_name}</span>,
  },
  {
    key: 'user',
    title: translate(lang, 'Reporter'),
    render: (x: ISecurityRow) => <span>{x.user.name}</span>,
  },
  {
    key: 'type',
    title: translate(lang, 'Type'),
    render: (x: ISecurityRow) => <span>{x.content_type}</span>,
  },
];

const BiosecurityPage = () => {
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISecurityRow[]>();
  const [comment, setComment] = useState<string>();
  const [images, setImages] = useState<any[]>();
  const [weather, setWeather] = useState<IWeatherConditionResource>();

  useEffect(() => {
    let isIn = true;

    setLoading(true);
    sendSingleRequest({}, 'GET', 'api/report/biosecurity', true).then(res => {
      if (isIn) {
        if (res.status) {
          setLoading(false);
          setData(res.data);
        } else {
          alert(res.data?.message ?? 'Failed to load');
        }
      }
    });

    return () => {
      isIn = false;
    };
  }, []);

  const tableColumns = [
    ...columns(lang),
    {
      key: 'comment',
      title: translate(lang, 'Comment'),
      render: (x: ISecurityRow) => (
        <div
          className='btn__modal'
          onKeyDown={() => undefined}
          onClick={() => setComment(x.comment ?? 'No comments yet')}
        >
          {translate(lang, 'View')}
        </div>
      ),
    },
    {
      key: 'images',
      title: translate(lang, 'Images'),
      render: (x: ISecurityRow) =>
        x.images.length > 0 ? (
          <div
            className='btn__modal'
            onKeyDown={() => undefined}
            onClick={() =>
              setImages(x.images.map(t => ({ original: t, thumbnail: t })))
            }
          >
            View
          </div>
        ) : (
          <></>
        ),
    },
    {
      key: 'weather',
      title: translate(lang, 'Weather'),
      render: (x: ISecurityRow) =>
        !x.weather_condition ? (
          <></>
        ) : (
          <div
            className='btn__modal'
            style={{ display: 'flex' }}
            onClick={() => setWeather(x.weather_condition)}
          >
            <img
              className='ant-image-img'
              src={weather_icon}
              alt='weather'
              style={{ maxWidth: '20px' }}
            />
            <span style={{ marginLeft: '5px' }}>{translate(lang, 'View')}</span>
          </div>
        ),
    },
  ];

  return (
    <div className='bg-secondary'>
      <div className='container performance-page pb-32'>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Biosecurity Reports')}
          </Title>
        </div>
        {loading && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        <div className='content pb-32'>
          <Table
            rowKey={'id'}
            columns={tableColumns}
            dataSource={data}
            pagination={false}
          />
        </div>
      </div>
      <ModalComponent
        visible={comment !== undefined}
        onCancel={() => setComment(undefined)}
        type=''
        title={translate(lang, 'Comment')}
        text={translate(lang, comment ?? '')}
      />
      {images !== undefined && (
        <Modal
          title='Photos'
          centered
          visible={true}
          onOk={() => setImages(undefined)}
          onCancel={() => setImages(undefined)}
          width={1000}
        >
          <ReactImageGallery items={images} />
        </Modal>
      )}
      {weather !== undefined && (
        <WeatherInfoModal
          data={weather}
          visible={true}
          title='Weather Condition'
          onClose={() => setWeather(undefined)}
        />
      )}
    </div>
  );
};

export default BiosecurityPage;
