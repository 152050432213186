import { useSelector } from 'react-redux';
import { Paragrapgh } from '../shared';
import DropdownMenu from '../shared/dropdown-menu/DropdownMenu';
import { IAutomation, ITagSchedule } from '../../entities/automation.entities';
import { checkRolePermission } from '../../entities/util-functions';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectTags } from '../../store/automation/automation.selector';

const timeLabel = (t: number, unit: string) =>
  t === 0
    ? `At the time`
    : t < 0
    ? `${-t} ${unit}(s) Before`
    : `${t} ${unit}(s) After`;

interface Props {
  data: IAutomation | ITagSchedule;
  handleOnEdit: (data: any, col?: string | undefined) => void;
  onDeleteRow: (data: any) => void;
}

const MobileAutomation = ({ data, handleOnEdit, onDeleteRow }: Props) => {
  const profile = useSelector(selectProfile);
  const lang = useSelector(selectLang);
  const tags = useSelector(selectTags);
  const tagSchedule = data as ITagSchedule;
  const automation = data as IAutomation;

  const checkPermission = () => {
    if (
      checkRolePermission({ allowedRoles: ['owner', 'admin'] }, profile?.role)
    ) {
      return 1;
    }
    if (profile?.id === data.creator_id || profile?.id === data.assigned_to) {
      return 1;
    }
    return 0;
  };

  return (
    <div className='table-mobile__card mb-12'>
      {checkPermission() && (
        <div className='table-mobile__card-dots'>
          <DropdownMenu
            data={data}
            column='isAutomation'
            onEdit={handleOnEdit}
            onDeleteRow={onDeleteRow}
            type='automations'
          />
        </div>
      )}
      {data.type === 'TAG_SCHEDULE' ? (
        <div className='pb-23 mt-24'>
          <Paragrapgh size={3} color='black-2' align='left' fontWeight={400}>
            {`Tag ${tags?.find(x => x.id === tagSchedule.tag_id)?.name} to ${
              tagSchedule.lines && tagSchedule.lines.length > 0
                ? `${tagSchedule.lines.length} line(s)`
                : `${tagSchedule.farms.length} farm(s)`
            } after ${tagSchedule.time} ${tagSchedule.unit}`}
          </Paragrapgh>
        </div>
      ) : (
        <>
          <div className='d-flex pb-23 mt-24'>
            <div className='flex-basis-100'>
              <Paragrapgh
                size={3}
                color='black-2'
                align='left'
                fontWeight={400}
              >
                {translate(lang, 'Action')}
              </Paragrapgh>
              <div className='d-flex align-items-center'>
                <div className='pr-6 tx-color-3'>
                  {automation.type === 'Assessment' ? (
                    <>
                      <span className='mr-10'>
                        {translate(lang, 'If Assessment Is Created Then')}
                      </span>
                      <span>{timeLabel(automation.time, automation.unit)}</span>
                    </>
                  ) : automation.type === 'Seeding' ? (
                    <>
                      <span className='mr-10'>
                        {translate(lang, 'If Seed is planted on a line Then')}
                      </span>
                      <span>{timeLabel(automation.time, automation.unit)}</span>
                    </>
                  ) : automation.type === 'Harvesting' ? (
                    <>
                      <span className='mr-10'>
                        {translate(lang, 'If Harvest is completed Then')}
                      </span>
                      <span>{timeLabel(automation.time, automation.unit)}</span>
                    </>
                  ) : (
                    <span>{`Every ${automation.time} ${automation.unit}(s)`}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex pb-23'>
            <div className='flex-basis-100'>
              <Paragrapgh
                size={3}
                color='black-2'
                align='left'
                fontWeight={400}
              >
                {translate(lang, 'Outcome')}
              </Paragrapgh>
              <div className='d-flex flex-direction-col'>
                <div className='mr-15 tx-left mb-15' style={{ width: 80 }}>
                  {translate(lang, 'Create task')}
                </div>
                <div
                  className='d-flex flex-direction-col tx-left'
                  style={{ flex: 1 }}
                >
                  <div>
                    <span>{translate(lang, 'Title')}:</span>
                    <span>{automation.title}</span>
                  </div>
                  <div>
                    <span>{translate(lang, 'Description')}:</span>
                    <span>
                      {automation.description.length >= 70
                        ? `${automation.description.slice(0, 70)}...`
                        : automation.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileAutomation;
