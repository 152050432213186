import { IQueueItem } from '../../entities/automation.entities';
import {
  IFarmResource,
  ISpatStorageResource,
} from '../../entities/farms.entities';
import { IInventoryPosition } from '../../entities/inventory.entities';
import { IFarmAction } from './farms.type';

interface IFarmState {
  farmsData: Array<IFarmResource>;
  spatStorages: ISpatStorageResource[];
  inventoryPositions?: IInventoryPosition[];
  queueData?: IQueueItem[];
}

const initialState: IFarmState = {
  farmsData: [],
  spatStorages: [],
  inventoryPositions: [],
  queueData: [],
};

const farmsReducer = (
  state = initialState,
  action: IFarmAction,
): IFarmState => {
  switch (action.type) {
    case 'FARMS/SET_FARMS_DATA': {
      return { ...state, farmsData: action.payload };
    }
    case 'FARMS/SET_SPAT_STORAGES': {
      return { ...state, spatStorages: action.payload };
    }
    case 'FARMS/SET_INVENTORY_POSITIONS': {
      return { ...state, inventoryPositions: action.payload };
    }
    case 'FARMS/SET_QUEUE_DATA': {
      return { ...state, queueData: action.payload };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default:
      return state;
  }
};

export default farmsReducer;
