import { IFarmResource, IOysterFarm } from '../../../entities/farms.entities';
import { TBusinessType } from '../../../entities/general.entities';
import MusselBulkHarvestModal from './MusselBulkHarvestModal';
import OysterBulkHarvestModal from './OysterBulkHarvestModal';

interface Props {
  type: TBusinessType;
  visible: boolean;
  title?: string;
  farm?: IFarmResource;
  data?: any;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  overConfirm?: (data: any) => void;
  onlyView?: boolean;
  warningMessage?: string;
  confirmID?: number;
}

const BulkHarvestModal = ({
  type,
  visible,
  title,
  farm,
  data,
  onCancel,
  onConfirm,
  overConfirm,
  onlyView,
  warningMessage,
  confirmID,
}: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselBulkHarvestModal
        visible={visible}
        title={title}
        data={data}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onlyView={onlyView}
        warningMessage={warningMessage}
      />
    ) : type === 'OYSTER' ? (
      <OysterBulkHarvestModal
        visible={visible}
        title={title}
        farm={farm ? (farm as IOysterFarm) : undefined}
        data={data}
        onCancel={onCancel}
        onConfirm={onConfirm}
        overConfirm={overConfirm}
        onlyView={onlyView}
        confirmID={confirmID}
        warningMessage={warningMessage}
      />
    ) : (
      <></>
    )}
  </>
);

export default BulkHarvestModal;
