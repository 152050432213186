import React from "react";
import { TModuleName } from "./util-functions";

export type TActionType =
  | 'SEEDING'
  | 'MAINTENANCE'
  | 'ASSESSMENT'
  | 'INVENTORY_SEED'
  | 'HARVEST';

export type TTimeTrackType =
  | 'SEEDING'
  | 'ASSESSMENT'
  | 'MAINTENANCE'
  | 'HARVEST'
  | 'TASK';

export const TrackStatus = {
  HARVESTED: 'harvested',
  MIDDLE_HARVESTED: 'middle_harvested',
  OFFLOADED_FROM_BOAT: 'offloaded from boat',
  LOADED_TO_COLD_STORAGE: 'loaded to cold storage',
  STARTED_PROCESSING: 'started processing',
  FINISHED_PROCESSING: 'finished processing',
  SEEDED: 'seeded',
};

export const BagStatus = {
  HARVESTED_MUSSELS: 'Harvested Mussels',
};

export type TBusinessType = 'MUSSEL' | 'OYSTER' | 'SEAWEED';

export interface ITimeTrack {
  id: number;
  rel_type: TTimeTrackType;
  rel_id: number;
  start_time: number;
  finish_time: number;
  status: 'TRACKED' | 'EDITED';
  is_final: boolean;
}

export interface IWeatherConditionResource {
  id: number;
  type: TActionType;
  form_id: number;
  latitude: string | null;
  longitude: string | null;
  sunset_time: string | null;
  temperature: string | null;
  wind_u: string | null;
  wind_v: string | null;
  precip: string | null;
  pressure: number | null;
  created_at: number;
}

export interface ITimeRange {
  start_time: number;
  finish_time: number;
}

export interface ILocation {
  lat: number;
  lng: number;
}

export interface IWeatherLocation {
  latitude: number;
  longitude: number;
}

export interface IGalleryImage {
  uid: string;
  name: string;
  size: number;
  thumbUrl: string;
  type: 'image/jpeg' | 'image/jpg' | 'image/png';
}

export interface IName {
  id: number;
  name: string;
}

export interface ISize {
  min: number;
  max: number;
  avg: number;
}

export interface IDescription {
  id: number;
  name: string;
  description?: string | null;
}

export interface ISeasonName {
  id: number;
  season_name: string;
}

export interface INavbar {
  link: string;
  title: string;
  id: string;
  icon: React.ReactNode;
}

export interface IMainList {
  label: React.ReactNode;
  value: string;
  id: string;
  apiValue?: string;
}

export interface ILink {
  module?: TModuleName;
  link: string;
  name: string;
  isExact?: boolean;
  children?: ILink[];
}
