import { Modal } from 'antd';
import { IOysterSeeding } from '../../../entities/growing.entities';
import { IOysterLineResource } from '../../../entities/farms.entities';

interface Props {
  visible: boolean;
  lineData?: IOysterLineResource;
  seedData?: IOysterSeeding;
  onClose: (d: any) => void;
  onConfirm: (d: any) => void;
  className?: string;
  onlyView?: boolean;
}

const OysterGrowerSeedModal = ({
  visible,
  lineData,
  seedData,
  onClose,
  onConfirm,
  className,
  onlyView,
}: Props) => {
  return <Modal>OysterGrowerSeedModal</Modal>;
};

export default OysterGrowerSeedModal;
