import { Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import ReactExport from 'react-data-export';
import { useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  Caret,
  Spinner,
  Subtitle,
  Title,
} from '../../components/shared';
import { formatNumber, numberToMoneyStr } from '../../entities/util-functions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectFarmsData } from '../../store/farms/farms.selector';
import './styles.scss';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = (ExcelFile as any).ExcelSheet;

const loadData = async (params: any) =>
  await sendSingleRequest(
    params,
    'POST',
    'api/overview/lines-performance',
    true,
  );

interface IBasicPerDetail {
  seed_date: number;
  seed_cost: number;
  maintenance_cost: number;
  harvest_date: number;
  harvest_amount: number;
  process_details: Array<{ name: string; weight: number }>;
  harvest_income: number;
  harvest_cost: number;
  harvest_levy: number;
  total_fee: number;
  density: number;
}

interface IMusselPerDetail extends IBasicPerDetail {
  seed_length: number;
  harvest_bags: number;
  submersion: number;
  budget_amount: number;
  budget_price: number;
  density_max: number | null;
  spacing: number;
}

interface IOysterPerDetail extends IBasicPerDetail {
  basket_count: number;
  spacing: number | null;
}

interface IBasicData {
  farm: {
    id: number;
    name: string;
    farm_number: string;
  };
  line: {
    id: number;
    line_name: string;
    length: number;
  };
}

interface IMusselData extends IBasicData {
  current: IMusselPerDetail;
  previous?: IMusselPerDetail;
}

interface IOysterData extends IBasicData {
  current: IOysterPerDetail;
  previous?: IOysterPerDetail;
}

const calcRevenue = (
  o: IMusselPerDetail | IOysterPerDetail,
  type: 'c' | 'r' | 'm' | 'h',
) => {
  const length = (o as any).seed_length ?? (o as any).basket_count;
  if (type === 'c') {
    return (
      o.seed_cost +
      o.harvest_cost +
      o.maintenance_cost +
      o.harvest_levy +
      o.total_fee
    );
  } else if (type === 'r') {
    return (
      o.harvest_income -
      o.seed_cost -
      o.harvest_cost -
      o.maintenance_cost -
      o.harvest_levy -
      o.total_fee
    );
  } else if (type === 'm') {
    return (
      (o.harvest_income -
        o.seed_cost -
        o.harvest_cost -
        o.maintenance_cost -
        o.harvest_levy -
        o.total_fee) /
      length
    );
  } else {
    return o.process_details.length > 0
      ? o.process_details.reduce((p, c) => p + c.weight, 0) / length
      : o.harvest_amount / length;
  }
};

const cellDiv = (x: string) => {
  if (!x.startsWith('$ ')) return x;
  const v = Number(x.substring(2).replace(',', ''));
  return v < 0 ? <div style={{ color: 'red' }}>{x}</div> : x;
};

const BudgetCell = (o: IMusselPerDetail, unit: 'kg' | '$') => {
  const actual =
    (unit === 'kg' ? o.harvest_amount : o.harvest_income) / o.seed_length;
  const budget =
    (unit === 'kg' ? o.budget_amount : o.budget_price) / o.seed_length;
  return (
    <div style={{ color: budget > actual ? 'red' : 'green' }}>
      {unit === '$' ? `$ ${numberToMoneyStr(budget)}` : formatNumber(budget)}
    </div>
  );
};

const SeasonArrow: React.FC<{
  line: IMusselData | IOysterData;
  lineSeason: number[];
  setLineSeason: (d: any) => void;
  setLoading: (d: number) => void;
  onChangeLineData: (d: IMusselData | IOysterData) => void;
}> = ({ line, lineSeason, setLineSeason, setLoading, onChangeLineData }) => {
  const onPrevClick = async () => {
    if (!line.previous) return;
    setLoading(2);
    const res = await loadData({
      line_id: line.line.id,
      max_date: line.previous.seed_date,
    });
    setLoading(0);
    if (res.status) {
      setLineSeason([...lineSeason, line.current.seed_date]);
      onChangeLineData(res.data);
    }
  };
  const onNextClick = async () => {
    if (lineSeason.length <= 0) return;
    setLoading(2);
    const res = await loadData({
      line_id: line.line.id,
      max_date: lineSeason[lineSeason.length - 1],
    });
    setLoading(0);
    if (res.status) {
      setLineSeason(lineSeason.slice(0, -1));
      onChangeLineData(res.data);
    }
  };

  return (
    <div className='season-arrow-btn'>
      <div
        className={`--arrow-btn ${!line.previous ? '--disabled-btn' : ''}`}
        onClick={onPrevClick}
      >
        <Caret color='#5A607F' direction='left' />
      </div>
      <div>
        {lineSeason.length <= 0
          ? 'Current'
          : lineSeason.length === 1
          ? 'Previous'
          : `${lineSeason.length} seasons ago`}
      </div>
      <div
        className={`--arrow-btn ${
          lineSeason.length <= 0 ? '--disabled-btn' : ''
        }`}
        onClick={onNextClick}
      >
        <Caret color='#5A607F' direction='right' />
      </div>
    </div>
  );
};

const SeasonsNavigator: React.FC<{
  data: Array<IMusselData | IOysterData>;
  setData: (d: any) => void;
  setLoading: (d: number) => void;
  lineSeasons: any;
  setLineSeasons: (d: any) => void;
}> = ({ data, setData, setLoading, lineSeasons, setLineSeasons }) => {
  const prevAble = data.some(x => !!x.previous);
  const nextAble =
    lineSeasons &&
    Object.values(lineSeasons).some((x: any) => x && x.length > 0);
  const label = useMemo(() => {
    if (!lineSeasons) return 'Current';
    const len = Object.values(lineSeasons).reduce(
      (p: number, c: any) => (c.length > p ? c.length : p),
      0,
    );
    return len <= 0 ? 'Current' : len === 1 ? 'Previous' : `${len} seasons ago`;
  }, [lineSeasons]);

  const prevClick = async () => {
    setLoading(2);
    const params = data
      .filter(x => !!x.previous)
      .map(x => ({ line_id: x.line.id, max_date: x.previous?.seed_date }));
    const res = await loadData({ lines: params });
    setLoading(0);
    if (res.status) {
      let newSeasons = lineSeasons ? { ...lineSeasons } : [];
      let newData = [...data];
      for (let d of res.data) {
        const i = data.findIndex(t => t.line.id === d.line.id);
        if (i < 0) continue;
        newData[i] = d;
        if (!newSeasons[d.line.id]) newSeasons[d.line.id] = [];
        newSeasons[d.line.id].push(data[i].current.seed_date);
      }
      setLineSeasons(newSeasons);
      setData(newData);
    }
  };
  const nextClick = async () => {
    let params = [];
    for (let l in lineSeasons) {
      if (lineSeasons[l].length > 0)
        params.push({
          line_id: l,
          max_date: lineSeasons[l][lineSeasons[l].length - 1],
        });
    }
    setLoading(2);
    const res = await loadData({ lines: params });
    if (res.status) {
      let newSeasons = { ...lineSeasons };
      let newData = [...data];
      for (let d of res.data) {
        const i = data.findIndex(t => t.line.id === d.line.id);
        if (i < 0) continue;
        newData[i] = d;
        newSeasons[d.line.id] = newSeasons[d.line.id].slice(0, -1);
      }
      setLineSeasons(newSeasons);
      setData(newData);
    }
    setLoading(0);
  };

  return (
    <div className='budget-year-button d-flex justify-content-between align-items-center white-card-small pt-3 pb-3'>
      <Button
        color='blue'
        size={3}
        width='default'
        type='transparent'
        className='mr-26'
        name='prev'
        onlyIconDisabled
        onClick={prevClick}
        disabled={!prevAble}
      >
        <Caret color='#5A607F' direction='left' />
      </Button>
      <div className='d-flex align-items-center'>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {label}
        </Subtitle>
      </div>
      <Button
        color='blue'
        size={3}
        width='default'
        type='transparent'
        className='ml-26'
        name='next'
        onlyIconDisabled
        onClick={nextClick}
        disabled={!nextAble}
      >
        <Caret color='#5A607F' direction='right' />
      </Button>
    </div>
  );
};

const SummaryPerformancePage = () => {
  const profile = useSelector(selectProfile);
  const showBudget = !!profile?.account?.show_budget_menu;
  const isGrower = profile?.account_type === 'grower';
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);

  const [loading, setLoading] = useState(0);
  const [musselData, setMusselData] = useState<IMusselData[]>([]);
  const [oysterData, setOysterData] = useState<IOysterData[]>([]);
  const [lineSeasons, setLineSeasons] = useState<any>();
  const [busType, setBusType] = useState(
    farmsData.every(x => x.type === 'OYSTER') ? 'OYSTER' : 'MUSSEL',
  );

  const { excel, table } = useMemo(() => {
    let bags: string[] = [];
    const data = busType === 'MUSSEL' ? musselData : oysterData;
    for (let d of data) {
      for (let c of d.current.process_details)
        if (!bags.includes(c.name)) bags.push(c.name);
      if (d.previous)
        for (let p of d.previous.process_details)
          if (!bags.includes(p.name)) bags.push(p.name);
    }
    const columns = [
      { title: 'Farm', width: { wch: 39 }, style: { font: { bold: false } } },
      ...data.map(x => ({
        title: x.farm.farm_number,
        style: { font: { bold: false } },
      })),
    ];
    let grid: any[] = [
      [{ value: 'Line' }, ...data.map(x => ({ value: x.line.line_name }))],
      [
        { value: '' },
        ...data.map(x => ({
          button: (
            <SeasonArrow
              line={x}
              lineSeason={lineSeasons ? lineSeasons[x.line.id] ?? [] : []}
              setLineSeason={d =>
                setLineSeasons(
                  lineSeasons
                    ? { ...lineSeasons, [x.line.id]: d }
                    : { [x.line.id]: d },
                )
              }
              setLoading={setLoading}
              onChangeLineData={d => {
                const dd: any = data.map(x =>
                  x.line.id === d.line.id ? d : x,
                );
                if (busType === 'MUSSEL') {
                  setMusselData(dd);
                } else {
                  setOysterData(dd);
                }
              }}
            />
          ),
        })),
      ],
      [{ value: 'Seeding', style: { font: { bold: true } } }],
      [
        { value: 'Date Seeded' },
        ...data.map(x => ({ value: defaultDateFormat(x.current.seed_date) })),
      ],
    ];
    if (busType === 'MUSSEL') {
      grid.push([
        { value: 'Metres Seeded', is_length: true },
        ...musselData.map(x => ({
          value: formatNumber(x.current.seed_length),
        })),
      ]);
    } else {
      grid.push([
        { value: 'Seeded baskets', is_length: true },
        ...oysterData.map(x => ({
          value: formatNumber(x.current.basket_count),
        })),
      ]);
    }
    if (showBudget) {
      grid.push(
        [
          { value: 'Seeding Costs', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(x.current.seed_cost)}`,
          })),
        ],
        [
          { value: 'Maintenance Costs', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(x.current.maintenance_cost)}`,
          })),
        ],
        [
          { value: 'Total costs of seeding', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(
              x.current.seed_cost + x.current.maintenance_cost,
            )}`,
          })),
        ],
      );
    }
    grid.push(
      [{ value: '' }],
      [{ value: 'Harvest', style: { font: { bold: true } } }],
      [
        { value: 'Harvest date' },
        ...data.map(x => ({
          value: defaultDateFormat(x.current.harvest_date) ?? '',
        })),
      ],
    );
    if (busType === 'MUSSEL') {
      grid.push(
        [
          { value: 'Bags harvested', is_length: true },
          ...musselData.map(x => ({
            value: formatNumber(x.current.harvest_bags),
          })),
        ],
        [
          { value: 'Harvest kgs', is_length: true },
          ...musselData.map(x => ({
            value: formatNumber(x.current.harvest_amount),
          })),
        ],
      );
    } else {
      grid.push([
        { value: 'Harvest dozens', is_length: true },
        ...oysterData.map(x => ({
          value: formatNumber(x.current.harvest_amount),
        })),
      ]);
    }
    if (!isGrower) {
      grid.push(
        [{ value: '' }],
        [
          {
            value: 'Processing weight',
            style: { font: { bold: true } },
          },
        ],
        ...bags.map(b => [
          { value: b },
          ...data.map(x => ({
            value:
              x.current.process_details.find(p => p.name === b)?.weight ?? '',
          })),
        ]),
        [
          { value: 'Total weight' },
          ...data.map(x => ({
            value: formatNumber(
              x.current.process_details.reduce((p, c) => p + c.weight, 0),
            ),
          })),
        ],
      );
    }
    if (showBudget) {
      grid.push(
        [{ value: '' }],
        [{ value: 'Harvest cost', style: { font: { bold: true } } }],
        [
          { value: 'Levy', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(
              x.current.total_fee + x.current.harvest_levy,
            )}`,
          })),
        ],
        [
          { value: 'Harvest cost', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(x.current.harvest_cost)}`,
          })),
        ],
        [
          { value: 'Total cost', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(
              x.current.harvest_cost +
                x.current.total_fee +
                x.current.harvest_levy,
            )}`,
          })),
        ],
        [{ value: '' }],
        [{ value: 'Revenue', style: { font: { bold: true } } }],
        [
          { value: 'Cost (seed, maintenance, harvest)', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(calcRevenue(x.current, 'c'))}`,
          })),
        ],
        [
          { value: 'Revenue', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(x.current.harvest_income)}`,
          })),
        ],
        [
          { value: 'Income (revenue - cost)', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(calcRevenue(x.current, 'r'))}`,
          })),
        ],
      );
      if (busType === 'MUSSEL') {
        grid.push([
          { value: 'Income per meter', is_cost: true },
          ...data.map(x => ({
            value: `$ ${numberToMoneyStr(calcRevenue(x.current, 'm'))}`,
          })),
        ]);
      }
    }
    grid.push(
      [{ value: '' }],
      [{ value: 'Performance', style: { font: { bold: true } } }],
    );
    if (busType === 'MUSSEL') {
      grid.push(
        [
          { value: 'Budgeted kg/m' },
          ...musselData.map(x => ({
            value: x.current.budget_amount / x.current.seed_length,
            overCell: BudgetCell(x.current, 'kg'),
          })),
        ],
        [
          { value: 'kgs/m', is_length: true },
          ...data.map(x => ({
            value: formatNumber(calcRevenue(x.current, 'h')),
          })),
        ],
        [
          { value: 'previous kgs/m', is_length: true },
          ...data.map(x => ({
            value: x.previous ? formatNumber(calcRevenue(x.previous, 'h')) : '',
          })),
        ],
        [
          { value: 'density' },
          ...musselData.map(x => ({
            value: !x.current.density_max
              ? x.current.density.toString()
              : `${x.current.density} - ${x.current.density_max}`,
          })),
        ],
        [
          { value: 'previous density' },
          ...musselData.map(x => ({
            value: !x.previous
              ? ''
              : !x.previous.density_max
              ? x.previous.density.toString() ?? ''
              : `${x.previous.density} - ${x.previous.density_max}`,
          })),
        ],
        [
          { value: 'submersion (m)' },
          ...musselData.map(x => ({ value: x.current.submersion })),
        ],
        [
          { value: 'previous submersion' },
          ...musselData.map(x => ({
            value: !x.previous ? '' : x.previous.submersion.toString(),
          })),
        ],
      );
    } else {
      grid.push(
        [
          { value: 'density' },
          ...oysterData.map(x => ({
            value: x.current.density.toString(),
          })),
        ],
        [
          { value: 'previous density' },
          ...oysterData.map(x => ({
            value: !x.previous ? '' : x.previous.density.toString(),
          })),
        ],
      );
    }
    grid.push(
      [
        { value: 'spacing' },
        ...data.map(x => ({ value: x.current.spacing?.toString() })),
      ],
      [
        { value: 'previous spacing' },
        ...data.map(x => ({
          value: !x.previous ? '' : x.previous.spacing?.toString(),
        })),
      ],
    );
    if (showBudget && busType === 'MUSSEL') {
      grid.push(
        [
          { value: 'Budgeted $/m' },
          ...musselData.map(x => ({
            value: x.current.budget_price / x.current.seed_length,
            overCell: BudgetCell(x.current, '$'),
          })),
        ],
        [
          { value: 'Income per meter', is_cost: true },
          ...musselData.map(x => ({
            value: `$ ${numberToMoneyStr(
              x.current.harvest_income / x.current.seed_length,
            )}`,
          })),
        ],
        [
          { value: 'Previous income per meter', is_cost: true },
          ...musselData.map(x => ({
            value: !x.previous
              ? ''
              : `$ ${numberToMoneyStr(
                  x.previous.harvest_income / x.previous.seed_length,
                )}`,
          })),
        ],
      );
    }
    const tableColumns: any = [
      {
        title: (
          <>
            <div>Farm</div>
            <div>Line</div>
          </>
        ),
        render: (x: any) =>
          x.is_bold ? (
            <div style={{ fontWeight: 'bold' }}>{x.label}</div>
          ) : (
            x.label
          ),
        width: 180,
        key: 'label',
        fixed: 'left',
      },
      ...data.map(x => ({
        title: (
          <>
            <div>{x.farm.farm_number}</div>
            <div>{x.line.line_name}</div>
          </>
        ),
        dataIndex: `${x.farm.id}-${x.line.id}`,
        key: `${x.farm.id}-${x.line.id}`,
        width: 170,
        render: (y: any) => (typeof y === 'string' ? cellDiv(y) : y),
      })),
    ];
    if (showBudget) {
      tableColumns.push({
        title: 'Totals',
        width: 170,
        render: (x: any) =>
          x.is_cost ? (
            <div style={x.sum < 0 ? { color: 'red' } : {}}>
              {`$ ${numberToMoneyStr(x.sum)}`}
            </div>
          ) : x.is_length ? (
            <div style={x.sum < 0 ? { color: 'red' } : {}}>
              {formatNumber(x.sum)}
            </div>
          ) : (
            <></>
          ),
      });
    }
    let tableData: any[] = [];
    const keys = data.map(x => `${x.farm.id}-${x.line.id}`);
    for (let i = 1; i < grid.length; i++) {
      let row: any = { key: i, label: grid[i][0].value };
      for (let j = 0; j < keys.length; j++) {
        row[keys[j]] =
          j + 1 < grid[i].length
            ? grid[i][j + 1].overCell ??
              grid[i][j + 1].value ??
              grid[i][j + 1].button
            : '';
      }
      if (grid[i].length === 1 && grid[i][0].value !== '') {
        row.is_bold = true;
      }
      if (grid[i][0]?.is_cost || grid[i][0]?.is_length) {
        let s = 0;
        for (let j = 0; j < keys.length; j++) {
          s += Number(row[keys[j]].replace('$ ', '').replace(',', ''));
        }
        row.sum = s;
        if (grid[i][0]?.is_cost) row.is_cost = true;
        if (grid[i][0]?.is_length) row.is_length = true;
      }
      tableData.push(row);
    }
    return {
      excel: { columns, data: grid },
      table: { columns: tableColumns, data: tableData },
    };
  }, [busType, musselData, oysterData, showBudget]);

  useEffect(() => {
    setLoading(1);
    loadData({}).then(res => {
      const mussels = res.data.filter((x: any) => x.farm.type === 'MUSSEL');
      const oysters = res.data.filter((x: any) => x.farm.type === 'OYSTER');
      setMusselData(mussels);
      setOysterData(oysters);
      setLoading(0);
    });
  }, []);

  return (
    <div className='bg-secondary'>
      <div className='container performance-page pb-32'>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Lines performance')}
          </Title>
          {((busType === 'MUSSEL' && musselData.length > 0) ||
            (busType === 'OYSTER' && oysterData.length > 0)) && (
            <>
              <SeasonsNavigator
                data={busType === 'MUSSEL' ? musselData : oysterData}
                setData={(d: any) => {
                  const mussels = d.filter(
                    (x: any) => x.farm.type === 'MUSSEL',
                  );
                  const oysters = d.filter(
                    (x: any) => x.farm.type === 'OYSTER',
                  );
                  setMusselData(mussels);
                  setOysterData(oysters);
                }}
                setLoading={setLoading}
                lineSeasons={lineSeasons}
                setLineSeasons={setLineSeasons}
              />
              <ExcelFile
                element={
                  <Button color='blue' size={3} width='middle' type='fill'>
                    {translate(lang, 'Export to excel')}
                  </Button>
                }
              >
                <ExcelSheet dataSet={[excel]} name='Performance' />
              </ExcelFile>
            </>
          )}
        </div>
        {loading > 0 && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        {loading !== 1 && (
          <div className='content pb-32'>
            <Table
              columns={table.columns}
              dataSource={table.data}
              pagination={false}
              scroll={{ y: 600 }}
              rowClassName={r =>
                r.label.toLowerCase().startsWith('previous') ? '--bg-gray' : ''
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryPerformancePage;
