import { TBusinessType } from '../../../entities/general.entities';
import {
  IHarvestResource,
  IMusselHarvest,
  IOysterHarvest,
  ISeaweedHarvest,
} from '../../../entities/growing.entities';
import MusselHarvestViewModal from './MusselHarvestViewModal';
import OysterHarvestViewModal from './OysterHarvestViewModal';
import SeaweedHarvestViewModal from './SeaweedHarvestViewModal';
import '../styles.scss';

interface Props {
  type: TBusinessType;
  visible: boolean;
  harvest: IHarvestResource;
  onClose: () => void;
}

const HarvestViewModal = ({ type, visible, harvest, onClose }: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselHarvestViewModal
        visible={visible}
        harvest={harvest as IMusselHarvest}
        onClose={onClose}
      />
    ) : type === 'OYSTER' ? (
      <OysterHarvestViewModal
        visible={visible}
        harvest={harvest as IOysterHarvest}
        onClose={onClose}
      />
    ) : type === 'SEAWEED' ? (
      <SeaweedHarvestViewModal
        visible={visible}
        harvest={harvest as ISeaweedHarvest}
        onClose={onClose}
      />
    ) : (
      <></>
    )}
  </>
);

export default HarvestViewModal;
