import { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { DropdownMenu, ModalComponent, Spinner, Title } from '../shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  ILineResource,
  IOysterLineResource,
} from '../../entities/farms.entities';
import { loadFarmsData } from '../../store/farms/farms.actions';
import ImageGallery from 'react-image-gallery';
import { EditFilled } from '@ant-design/icons';
import WeatherInfoModal from '../farm-modals/WeatherInfoModal';
import weather_icon from '../../images/weather-icon.png';
import GrowerSeedModal from '../farm-modals/GrowerSeedModal';
import SeedingModal from '../farm-modals/SeedingModal';
import { sendSingleRequest } from '../../apis';
import {
  IWeatherConditionResource,
  TBusinessType,
} from '../../entities/general.entities';
import {
  IMusselCycle,
  IMusselSeeding,
  IOysterCycle,
  IOysterSeeding,
  ISeedingResource,
} from '../../entities/growing.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { SelectIsGrower } from '../../store/extra/extra.selector';
import { labelRange } from '../../lib/common.helpers';
import SpatJourneyModal from '../view-modals/SpatJourneyModal';
import { directionChar } from '../../lib/form.helpers';

interface IColumn {
  key: string;
  title: string;
  render: (x: any) => JSX.Element;
}

interface IGalleryImage {
  original: string;
  thumbnail: string;
}

const SpatColumn = (x: any, businessType: TBusinessType) =>
  x.is_catch_spat
    ? 'Catch Spat'
    : x.spat_storage
    ? businessType === 'MUSSEL'
      ? `${x.spat_storage.source} - ${x.spat_amount} kg`
      : `${x.spat_storage.source} - ${x.spat_amount_dz} dz`
    : '-';

interface Props {
  lineData: ILineResource;
  loadLine: () => void;
  cyclePart: IMusselCycle | IOysterCycle;
  type: TBusinessType;
}

const ExtraSeedingsTable = ({
  lineData,
  loadLine,
  cyclePart,
  type: businessType,
}: Props) => {
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);
  const isGrower = SelectIsGrower(lineData.farm_id);

  const musselCycle = cyclePart as IMusselCycle,
    oysterCycle = cyclePart as IOysterCycle;

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [disabled, setDisabled] = useState(false);
  const [infoShow, setInfoShow] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [visibleGallery, setVisibleGallery] = useState(false);
  const [gImages, setGImages] = useState<IGalleryImage[]>([]);
  const [selectedWeather, setSelectedWeather] =
    useState<IWeatherConditionResource>();
  const [journeyData, setJourneyData] = useState<any>();

  const totalLength =
      businessType === 'MUSSEL'
        ? musselCycle.seedings.reduce((p, c) => p + c.line_length, 0)
        : 0,
    totalBasketCount =
      businessType === 'OYSTER'
        ? oysterCycle.seedings.reduce((p, c) => p + c.basket_count, 0)
        : 0;

  const showEditModal = (item: any) => {
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const showDeleteModal = (item: any) => {
    setSelectedItem(item);
    setVisibleDelete(true);
  };
  const handleConfirmDelete = async () => {
    if (!selectedItem) return;
    setDisabled(true);
    const res = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/line/seeding/${selectedItem.id}`,
      true,
    );
    if (res.status) {
      loadLine();
      dispatch(loadFarmsData());
      setVisibleDelete(false);
      setDisabled(false);
    } else {
      window.alert(translate(lang, res.data?.message ?? 'Unknown error'));
    }
  };
  const openInfo = (comment: any) => {
    setInfoShow(true);
    setInfoText(comment && comment.length > 0 ? comment : 'No comments yet');
  };
  const openImages = (images: string[]) => {
    setVisibleGallery(true);
    setGImages(
      images.map(image => ({
        original: image,
        thumbnail: image,
      })),
    );
  };
  const openJourney = async (spatID: number) => {
    setDisabled(true);
    const res = await sendSingleRequest(
      { from_root: true },
      'GET',
      `api/farm/spats-journey/${spatID}`,
      true,
    );
    setDisabled(false);
    if (res.status) {
      setJourneyData(res.data);
    } else {
      alert(res.data?.message ?? 'Unknown error');
    }
  };

  const tableColumns = () => {
    let columns: IColumn[] = [
      {
        key: 'id',
        title: 'ID',
        render: (x: ISeedingResource) => <span>{x.id}</span>,
      },
      {
        key: 'date_seed',
        title: translate(lang, 'Date seeded'),
        render: (x: ISeedingResource) => (
          <>
            <span>{defaultDateFormat(x.planned_date_seed)}</span>
            {x.verifier && (
              <>
                <br />
                <span className='verifier-name'>
                  {translate(lang, 'Verified by %s', x.verifier.name)}
                </span>
              </>
            )}
          </>
        ),
      },
    ];
    if (businessType === 'MUSSEL') {
      columns.push({
        key: 'line_length',
        title: translate(lang, 'Longline length'),
        render: (x: IMusselSeeding) => (
          <span>
            {`${x.line_length}m - ${(
              (x.line_length / totalLength) *
              100
            ).toFixed(2)}%`}
          </span>
        ),
      });
    } else if (businessType === 'OYSTER') {
      columns.push({
        key: 'basket_count',
        title: translate(
          lang,
          'Number of %s',
          (lineData as IOysterLineResource).farming_method,
        ),
        render: (x: IOysterSeeding) => (
          <>
            <span>
              {`${x.basket_count} - ${(
                (x.basket_count / totalBasketCount) *
                100
              ).toFixed(2)}%`}
            </span>
            <span>
              {x.harvested && (
                <span>
                  <br />
                  {translate(
                    lang,
                    'Harvested: %s; Waiting: %s',
                    x.harvested,
                    x.basket_count - x.harvested,
                  )}
                </span>
              )}
            </span>
          </>
        ),
      });
    }
    columns.push(
      {
        key: 'season_name',
        title: translate(lang, 'Season'),
        render: (x: ISeedingResource) => <span>{x.season_name}</span>,
      },
      {
        key: 'spat_storage',
        title: translate(lang, 'Spat storage'),
        render: (x: ISeedingResource) => (
          <span
            onClick={() => x.spat_storage && openJourney(x.spat_storage.id)}
          >
            {SpatColumn(x, businessType)}
          </span>
        ),
      },
    );
    if (businessType === 'MUSSEL') {
      columns.push(
        {
          key: 'spacing',
          title: translate(lang, 'Spacing'),
          render: (x: IMusselSeeding) => <span>{x.spacing}</span>,
        },
        {
          key: 'density',
          title: translate(lang, 'Density'),
          render: (x: IMusselSeeding) => (
            <span>{labelRange(x.density, x.density_max ?? undefined)}</span>
          ),
        },
      );
    }
    columns.push(
      {
        key: 'spat_size',
        title: translate(lang, 'Spat size'),
        render: (x: ISeedingResource) => (
          <span>{labelRange(x.spat_size, x.spat_size_max ?? undefined)}</span>
        ),
      },
      {
        key: 'comment',
        title: translate(lang, 'Note'),
        render: (x: ISeedingResource) => (
          <div
            className='btn__modal'
            onKeyDown={() => undefined}
            onClick={openInfo.bind(this, x.comment)}
          >
            {translate(lang, 'View')}
          </div>
        ),
      },
    );
    if (!isGrower) {
      columns.push(
        {
          key: 'images',
          title: translate(lang, 'Photo'),
          render: (x: ISeedingResource) =>
            x.images && x.images.length > 0 ? (
              <div
                className='btn__modal'
                onKeyDown={() => undefined}
                onClick={openImages.bind(this, x.images)}
              >
                {translate(lang, 'View')}
              </div>
            ) : (
              <></>
            ),
        },
        {
          key: 'weather',
          title: translate(lang, 'Weather'),
          render: (x: ISeedingResource) =>
            !x.weather_condition ? (
              <span></span>
            ) : (
              <div
                className='btn__modal'
                onClick={(e: any) =>
                  setSelectedWeather(x.weather_condition ?? undefined)
                }
              >
                <img
                  className='ant-image-img'
                  src={weather_icon}
                  alt='weather'
                  style={{ maxWidth: '20px' }}
                />
                <span style={{ marginLeft: '5px' }}>
                  {translate(lang, 'View')}
                </span>
              </div>
            ),
        },
        {
          key: 'direction',
          title: translate(lang, 'Direction'),
          render: (x: ISeedingResource) => (
            <div className='text-center'>{directionChar(x.direction)}</div>
          ),
        },
      );
    }
    columns.push(
      {
        key: 'stage',
        title: translate(lang, 'Stage'),
        render: (x: ISeedingResource) => (
          <div className='text-center'>{x.spat_storage?.stage}</div>
        ),
      },
      {
        key: 'more',
        title: translate(lang, 'More'),
        render: (x: ISeedingResource) =>
          x.id !== cyclePart.main_seed.id ? (
            <DropdownMenu
              data={x}
              column={'isUtil'}
              onEdit={() => showEditModal(x)}
              onDeleteRow={() => showDeleteModal(x)}
              type='isModal'
            />
          ) : (
            <div onClick={() => showEditModal(x)}>
              <EditFilled />
            </div>
          ),
      },
    );
    return columns;
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <div
        className='ant-table-wrapper table mt-17'
        style={{ width: '1200px', minWidth: '100%', maxWidth: '1200px' }}
      >
        {disabled && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        <Title
          className='ml-7'
          size={6}
          color='black-3'
          align='default'
          fontWeight={700}
        >
          {translate(lang, 'Seedings')}
        </Title>
        <Table
          rowKey='id'
          className='table table--isFarm pl-0 pr-0'
          pagination={false}
          columns={tableColumns()}
          dataSource={
            oysterCycle.seedings
              .filter(
              (seeding) => !seeding.harvested || seeding.harvested < seeding.basket_count)
              .sort((a, b) => a.planned_date_seed - b.planned_date_seed) as ISeedingResource[]
          }
        />
        {visibleEdit && (
          <>
            {isGrower ? (
              <GrowerSeedModal
                type={businessType}
                visible={visibleEdit}
                onClose={() => setVisibleEdit(false)}
                onConfirm={() => {
                  setVisibleEdit(false);
                  loadLine();
                }}
                lineData={lineData}
                seedData={selectedItem}
              />
            ) : (
              <SeedingModal
                type={businessType}
                visible={visibleEdit}
                title={
                  !selectedItem.id
                    ? 'Update seeding data'
                    : 'Update partial line seed'
                }
                lineData={lineData}
                onCancel={() => setVisibleEdit(false)}
                data={selectedItem}
                updateID={selectedItem.id}
                onConfirm={() => {
                  setVisibleEdit(false);
                  loadLine();
                }}
                parentID={!selectedItem.id ? undefined : selectedItem.id}
              />
            )}
          </>
        )}
        <ModalComponent
          visible={infoShow}
          onCancel={() => setInfoShow(false)}
          type=''
          title={translate(lang, 'Comment')}
          text={infoText}
        />
        {visibleGallery && (
          <Modal
            title={translate(lang, 'Photos')}
            centered
            visible={visibleGallery}
            onOk={() => setVisibleGallery(false)}
            onCancel={() => setVisibleGallery(false)}
            width={1000}
          >
            <ImageGallery items={gImages} />
          </Modal>
        )}
        <ModalComponent
          title={translate(lang, 'Delete')}
          text={translate(lang, 'Are you sure to delete this data?')}
          type='delete'
          visible={visibleDelete}
          disabled={disabled}
          onCancel={() => setVisibleDelete(false)}
          onConfirm={handleConfirmDelete}
        />
        {!!selectedWeather && (
          <WeatherInfoModal
            title='Weather Condition'
            data={selectedWeather}
            visible={true}
            onClose={() => setSelectedWeather(undefined)}
          />
        )}
        {!!journeyData && (
          <SpatJourneyModal
            data={journeyData}
            visible={true}
            onClose={() => setJourneyData(undefined)}
            focusLineID={lineData.id}
          />
        )}
      </div>
    </div>
  );
};

export default ExtraSeedingsTable;
