import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectProfile, selectUserMeta } from '../../store/auth/auth.selector';
import { selectBoats } from '../../store/users/users.selector';
import {
  CloseIcon,
  Datepicker,
  Dropdown,
  Subtitle,
} from '../../components/shared';
import { Alert, Button, List, Modal, Typography, message } from 'antd';
import moment from 'moment';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { ITaskRequest } from '../../entities/task.entities';
import { sendSingleRequest } from '../../apis';
import { Link } from 'react-router-dom';
import { getLineStatus } from '../../lib/farm.helpers';
import { selectLang } from '../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../lib/lang.helper';

interface IBaseData {
  last_assessment: {
    farm_id: number;
    farm_name: string;
    line_id: number;
    line_name: string;
    assessment_date: number;
  } | null;
  tasks: Array<{
    id: number;
    farm_id: number;
    line_id: number;
  }>;
}

interface Props {
  baseData: IBaseData;
  loadData: () => void;
}

const AssessmentSuggest = ({ baseData, loadData }: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);
  const userMeta = useSelector(selectUserMeta);
  const boats = useSelector(selectBoats);
  const profile = useSelector(selectProfile);
  const { tasks, last_assessment } = baseData;

  const [showAccept, setShowAccept] = useState(false);
  const [boatId, setBoatId] = useState(profile?.boat_id ?? undefined);
  const [dueDate, setDueDate] = useState(moment().toDate().getTime());
  const [disabled, setDisabled] = useState(false);
  const [slCnt, setSlCnt] = useState(3);

  const { farmsInfo, suggested, totalLines } = useMemo(() => {
    const farmsInfo = farmsData
      .map(farm => {
        const lines = farm.lines
          .filter(
            x =>
              getLineStatus(x, userMeta?.line_assess_expire_days) ===
              'REQUIRE_ASSESSMENT',
          )
          .map(line => ({
            id: line.id,
            line_name: line.line_name,
            last_date:
              line.growing_cycle?.last_assessment?.assessment_date ??
              line.growing_cycle?.main_seed.planned_date_seed ??
              0,
          }));
        const min_date = lines.reduce(
          (p, c) => (p > c.last_date ? c.last_date : p),
          new Date().getTime(),
        );
        return {
          farm_id: farm.id,
          farm_name: farm.name,
          lines,
          min_date,
        };
      })
      .filter(x => x.lines.length > 0);

    if (farmsInfo.length <= 0) {
      return { farmsInfo: null, suggested: null, totalLines: 0 };
    }
    const totalLines = farmsInfo.reduce((p, c) => p + c.lines.length, 0);

    farmsInfo.sort((a, b) => a.min_date - b.min_date);

    let id = 0,
      lines = farmsInfo[0].lines;
    for (id = 0; id < farmsInfo.length; id++) {
      lines = farmsInfo[id].lines.filter(y =>
        baseData.tasks.every(t => t.line_id !== y.id),
      );
      if (lines.length > 0) break;
    }
    farmsInfo.sort((a, b) => b.lines.length - a.lines.length);

    if (id >= farmsInfo.length) {
      return { farmsInfo, suggested: null, totalLines };
    }
    const suggested = {
      farm_id: farmsInfo[id].farm_id ?? 0,
      farm_name: farmsInfo[id].farm_name ?? '',
      lines: lines.slice(0, slCnt),
      totalCnt: lines.length,
    };
    return { farmsInfo, suggested, totalLines };
  }, [farmsData, tasks, userMeta, slCnt]);

  const acceptClick = async () => {
    if (!suggested) {
      message.error(translate(lang, '_no_suggested'));
      return;
    }
    if (!boatId) {
      message.error(translate(lang, 'Please select a boat'));
      return;
    }
    setDisabled(true);
    for (let line of suggested.lines) {
      const form: ITaskRequest = {
        farm_id: suggested.farm_id,
        line_id: line.id,
        title: `Assess farm ${suggested.farm_name} line ${line.line_name}`,
        content: 'Please assessment this line',
        due_date: dueDate,
        assigned_to: 0,
        boat_id: boatId,
        type: 'ASSESSMENT',
      };
      const res = await sendSingleRequest(form, 'POST', 'api/task/tasks', true);
      if (res.status) {
        message.success(
          translate(lang, 'Task %s has been created successfully', form.title),
        );
      } else {
        message.error(translate(lang, res.data?.message ?? 'Server error'));
      }
    }
    setShowAccept(false);
    setDisabled(false);
    await loadData();
  };

  return (
    <div id='assessment-suggest' className='pt-28 pb-28'>
      <div className='mb-18'>
        <Subtitle size={1} color='black' align='left' fontWeight={600}>
          {translate(lang, 'Assessment suggestions')}
        </Subtitle>
      </div>
      <div className='ml-17'>
        <div className='suggest-sentence'>
          <span>
            {componentTranslate(
              lang,
              'You have %s line requiring assessments',
              <Typography.Text mark key={1}>
                {totalLines}
              </Typography.Text>,
            )}
          </span>
        </div>
        <div className='suggest-sentence'>
          {!!last_assessment ? (
            <span>
              {componentTranslate(
                lang,
                'Last assessment was on farm %s line %s on day %s',
                <Typography.Text mark key={1}>
                  {last_assessment.farm_name}
                </Typography.Text>,
                <Typography.Text mark key={2}>
                  {last_assessment.line_name}
                </Typography.Text>,
                <Typography.Text mark key={3}>
                  {defaultDateFormat(last_assessment.assessment_date)}
                </Typography.Text>,
              )}
            </span>
          ) : (
            <span>{translate(lang, 'No assessment data')}</span>
          )}
        </div>
      </div>
      {farmsInfo && (
        <div className='mt-18 mb-18'>
          <List
            bordered
            dataSource={farmsInfo}
            renderItem={item => (
              <List.Item>
                <Link to={`/farms/${item.farm_id}`}>
                  <span>
                    {componentTranslate(
                      lang,
                      'Farm %s has %s line(s) requiring assessments',
                      <strong key={1}>{item.farm_name}</strong>,
                      <strong key={2}>{item.lines.length}</strong>,
                    )}
                  </span>
                </Link>
              </List.Item>
            )}
          />
          {suggested && (
            <div className='mt-18'>
              <div className='suggest-sentence'>
                <Alert
                  className='suggest-warning'
                  message={translate(lang, '_suggest_warning')}
                  type='warning'
                  showIcon
                />
              </div>
              <Alert
                className='mt-18 mb-18 suggest-info'
                message={translate(
                  lang,
                  'Suggest %s line(s)',
                  suggested.lines.length,
                )}
                description={
                  <div>
                    <div className='mb-7'>
                      <span>
                        {componentTranslate(
                          lang,
                          '_assessment_suggest_farm_line',
                          <strong key={1}>{suggested.farm_name}</strong>,
                          <strong key={2}>
                            {suggested.lines.map(x => x.line_name).join(', ')}
                          </strong>,
                        )}
                      </span>
                      <span>
                        {slCnt > 1 && (
                          <Button
                            type='link'
                            size='small'
                            className='italic'
                            onClick={() => setSlCnt(Math.max(1, slCnt - 2))}
                          >
                            {translate(lang, 'Less')}
                          </Button>
                        )}
                        {slCnt > 1 && slCnt < suggested.totalCnt && (
                          <span>{'|'}</span>
                        )}
                        {slCnt < suggested.totalCnt && (
                          <Button
                            type='link'
                            size='small'
                            className='italic'
                            onClick={() =>
                              setSlCnt(Math.min(suggested.totalCnt, slCnt + 2))
                            }
                          >
                            {translate(lang, 'More')}
                          </Button>
                        )}
                      </span>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => setShowAccept(true)}
                      >
                        {translate(lang, 'Accept')}
                      </Button>
                    </div>
                  </div>
                }
                showIcon={true}
                type='info'
                closable={false}
              />
            </div>
          )}
        </div>
      )}
      {suggested && showAccept && (
        <Modal
          visible={true}
          onCancel={() => setShowAccept(false)}
          closable
          closeIcon={<CloseIcon />}
          footer={null}
        >
          <div className='wrap'>
            <div className='mt-28 mb-28'>
              <Dropdown
                label={translate(lang, '_suggest_boat_select_q')}
                options={boats.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} (${x.reg_number})`,
                }))}
                value={boatId?.toString()}
                onChange={v => setBoatId(Number(v))}
              />
            </div>
            <div className='mt-28 mb-28'>
              <Datepicker
                label={translate(lang, '_suggest_date_q')}
                defaultValue={dueDate}
                onChange={e => e && setDueDate(e.toDate().getTime())}
                required
              />
            </div>
            <div className='mt-32 d-flex justify-content-end'>
              <Button
                size='large'
                disabled={disabled}
                onClick={acceptClick}
                type='primary'
              >
                {translate(lang, 'Convert to task')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AssessmentSuggest;
