import { Link } from 'react-router-dom';
import {
  Button,
  ErrorPageIcon,
  ErrorSmallIcon,
  Paragrapgh,
  Title,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const NotFound = () => {
  const width = useWidth();
  const lang = useSelector(selectLang);

  return (
    <div className='d-flex justify-content-center align-items-center bg-second h-80-vh mr-12 ml-12'>
      <div className='error-wrapper tx-center'>
        {width > 660 ? <ErrorPageIcon /> : <ErrorSmallIcon />}
        <Title
          className={width > 660 ? 'pt-48' : 'pt-32'}
          size={5}
          fontWeight={500}
          align='default'
          color='black-3'
        >
          {translate(lang, '_page_not_found_title')}
        </Title>
        <Paragrapgh
          className='pt-12'
          size={1}
          color='default'
          align='default'
          fontWeight={400}
        >
          {translate(lang, '_page_not_found_body')}
        </Paragrapgh>
        <Link to='/' className={width > 660 ? 'mt-24' : 'mt-32'}>
          <Button
            className='bg-transparent'
            width='normal'
            size={1}
            type='bordered'
            color='blue'
            isNoneBorder
          >
            {translate(lang, '_page_not_found_btn')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
