import { useState } from 'react';
import { IBudgetLog } from '../../../entities/budget.entities';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import { Button, Table } from 'antd';
import ModalComponent from '../modal/Modal';
import './styles.scss';

interface Props {
  logsData: IBudgetLog[];
  onDelete: (item: IBudgetLog) => void;
  pagination: any;
  onPagination: (d: any) => void;
}

const BudgetLogsTable = ({
  logsData,
  pagination,
  onDelete,
  onPagination,
}: Props) => {
  const [comment, setComment] = useState<string>();

  const columns = [
    {
      title: 'Farm',
      dataIndex: 'farm_name',
      key: 'farm_name',
    },
    {
      title: 'Line',
      dataIndex: 'line_name',
      key: 'line_name',
    },
    {
      title: 'Type',
      dataIndex: 'human_name',
      key: 'human_name',
    },
    {
      title: 'Change',
      key: 'change',
      render: (x: IBudgetLog) => (
        <span>
          {x.change.old} &#x2192;
          <span className='font-weight-600'>{x.change.new}</span>
        </span>
      ),
    },
    {
      title: 'User',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Date',
      key: 'date',
      render: (x: IBudgetLog) => (
        <span>{defaultDateFormat(Number(x.date))}</span>
      ),
    },
    {
      title: 'Comment',
      key: 'comment',
      render: (x: IBudgetLog) =>
        !!x.comment && (
          <div
            className='btn__modal'
            onKeyDown={() => undefined}
            onClick={() => setComment(x.comment ?? '')}
          >
            View
          </div>
        ),
    },
    {
      title: '',
      key: 'action',
      render: (x: IBudgetLog) => (
        <Button
          size='small'
          danger={true}
          type='link'
          onClick={e => onDelete(x)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table
        className='table table--isBudgetLog'
        dataSource={logsData}
        pagination={pagination}
        onChange={onPagination}
        columns={columns}
      />
      {comment !== undefined && (
        <ModalComponent
          visible={true}
          onCancel={() => setComment(undefined)}
          type=''
          title={'Comment'}
          text={comment}
        />
      )}
    </>
  );
};

export default BudgetLogsTable;
