import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, TrashIcon } from '../../components/shared';
import {
  CreateAccountMessage,
  DeleteAccountMessage,
  MessageActionReadAll,
  ReadAccountMessages,
} from './AccountMessageFunctions';
import { showFeedback } from '../../store/ui/ui.actions';
import { updateUserMeta } from '../../store/auth/auth.actions';
import { selectNewAccountMessage } from '../../store/users/users.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

interface IMessage {
  id: number;
  user_id: number;
  user: any;
  content: string;
  created_at: string;
  updated_at: string;
  is_read: boolean;
  is_mine: boolean;
}

const limit = 30;

const AccountMessagesPage = () => {
  const dispatch = useDispatch();
  const newAccountMessage = useSelector(selectNewAccountMessage);
  const lang = useSelector(selectLang);

  const [content, setContent] = useState('');
  const [disable, setDisable] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const offset = 0;

  const inputContentHandle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setContent(event.target.value);
  };
  const deleteClick = async (
    itemId: any,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    DeleteAccountMessage(
      itemId,
      () => {
        loadData();
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, 'Removed successfully'),
          }),
        );
      },
      data => {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, data?.message ?? 'Something went wrong'),
          }),
        );
      },
    );
  };
  const sendMessage = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (content.length < 2) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, 'You should input %s letters at least', 2),
        }),
      );
      return;
    }
    setDisable(true);
    CreateAccountMessage(
      content,
      () => {
        setDisable(false);
        setContent('');
        loadData();
      },
      data => {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, data?.message ?? 'Something went wrong'),
          }),
        );
        setDisable(false);
      },
    );
  };
  const sendReadAction = async (
    item: IMessage,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    await setReadAll();
  };
  const loadData = useCallback(async () => {
    ReadAccountMessages(
      offset,
      limit,
      data => setMessages(data),
      data =>
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, data?.message ?? 'Something went wrong'),
          }),
        ),
    );
  }, [offset, limit]);

  const setReadAll = async () => {
    if (messages.length <= 0) return;
    const message_ids = messages
      .filter(x => !x.is_read && !x.is_mine)
      .map(t => t.id);
    if (message_ids.length <= 0) return;
    MessageActionReadAll(
      message_ids,
      () => {
        loadData().then(() => updateUserMeta());
      },
      () => {
        loadData().then(() => updateUserMeta());
      },
    );
  };

  useEffect(() => {
    loadData();

    return () => {};
  }, [loadData]);

  useEffect(() => {
    if (newAccountMessage) {
      loadData();
    }
  }, [newAccountMessage]);

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container'>
        <div className='overview__content'>
          <div
            className='overview__left-content'
            style={{ width: '100%', paddingRight: '20px' }}
          >
            {messages.map(item => (
              <div
                className={`white-card ${
                  !!item.is_read || !!item.is_mine ? '' : 'unread'
                }`}
                key={item.id}
                onClick={sendReadAction.bind(this, item)}
              >
                <div className='card-body'>
                  <pre className='cursor-text message-content'>
                    {item.content}
                  </pre>
                </div>
                <div className='card-footer d-flex justify-content-end align-items-center'>
                  <div>
                    <span className='comment'> Written by </span>
                    <span className='comment comment-username'>
                      {item.user.name}
                    </span>
                    <span className='comment'> on </span>
                    <span className='comment comment-date'>
                      {item.created_at}
                    </span>
                  </div>
                  {item.is_mine && (
                    <div>
                      <button
                        className='delete-item'
                        line-id={item.id}
                        onClick={deleteClick.bind(this, item.id)}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className='overview__right-content'>
            <div className='white-card'>
              <div className='card-body' onClick={e => setReadAll()}>
                <Input
                  type='textarea'
                  label=''
                  placeholder='Please put your text here.'
                  value={content}
                  onChange={inputContentHandle}
                />
              </div>
              <div className='card-footer d-flex justify-content-end'>
                <Button
                  className='align-right'
                  type='bordered'
                  color='green'
                  size={2}
                  width='100px'
                  disabled={disable}
                  onClick={sendMessage}
                >
                  {translate(lang, 'Send')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountMessagesPage;
