import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import message_icon from '../../images/message-icon.png';
import './styles.scss';

const BoatMessageMenuItem = () => {
  const userMeta = useSelector((state: RootState) => state.auth.userMeta);

  return (
    <>
      <NavLink className='ml-24' exact={true} to={`/boat-messages`}>
        <img className='message-icon' src={message_icon} alt='message' />
      </NavLink>
      {(userMeta?.unread_messages_count ?? 0) > 0 && (
        <NavLink
          to={`/boat-messages`}
          exact={true}
          className='notification-count-icon'
        >
          {userMeta?.unread_messages_count}
        </NavLink>
      )}
    </>
  );
};

export default BoatMessageMenuItem;
