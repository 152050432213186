import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectXeroContacts = createSelector(
  (state: RootState) => state.subscription.xero_contacts,
  contacts => contacts,
);

export const selectXeroAccounts = createSelector(
  (state: RootState) => state.subscription.xero_accounts,
  accounts => accounts,
);
