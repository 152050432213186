import { FC, useMemo, useState } from 'react';
import { Modal, Table } from 'antd';
import moment from 'moment';
import { IFarmWeatherResource } from '../../entities/farms.entities';
import { CloseIcon, Dropdown, Map, Subtitle } from '../shared';
import arrow_png from '../../images/right-arrow.png';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../entities/util-functions';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const oneKnot = 1.94384;

const WindyIcon: FC<{ wu: number; wv: number }> = ({ wu, wv }) => {
  const x = Number(wu),
    y = Number(wv);
  const s = Math.sqrt(x * x + y * y),
    a = (-Math.atan2(y, x) * 180) / Math.PI;

  return s < 1e-9 ? (
    <div>{`${(s * oneKnot).toFixed(2)} knots`}</div>
  ) : (
    <div className='d-flex align-items-center'>
      <div>{`${(s * oneKnot).toFixed(2)} knots`}</div>
      <div className='ml-7 wind-icon'>
        <img src={arrow_png} style={{ transform: `rotate(${a}deg)` }} />
      </div>
    </div>
  );
};

const columns = (lang: any) => [
  {
    title: translate(lang, 'Time'),
    dataIndex: 'rts',
    key: 'rts',
    render: (x: any) => <span>{moment(x).format('HH:mm')}</span>,
  },
  {
    title: translate(lang, 'Temperature'),
    dataIndex: 'temperature',
    key: 'temperature',
    render: (x: any) => <span>{Number(x).toFixed(1)} °C</span>,
  },
  {
    title: translate(lang, 'Wind Speed'),
    dataIndex: '',
    key: 'wind',
    render: (x: any) => <WindyIcon wu={x.wind_u} wv={x.wind_v} />,
  },
  {
    title: translate(lang, 'Precip'),
    dataIndex: 'precip',
    key: 'precip',
    render: (x: any) => <span>{Number(x).toFixed(1)} mm</span>,
  },
  {
    title: translate(lang, 'Pressure'),
    dataIndex: 'pressure',
    key: 'pressure',
    render: (x: number | null) => (
      <span>{x ? `${formatNumber(x / 1000)} KPa` : ''}</span>
    ),
  },
  {
    title: '',
    dataIndex: '',
    key: 'right',
    render: (x: any) => <span></span>,
  },
];

interface Props {
  visible: boolean;
  onClose: (x: any) => void;
  className?: string;
  title: string;
  data: IFarmWeatherResource[];
}

const FarmWeatherModal = ({
  visible,
  onClose,
  className,
  title,
  data,
}: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);

  const [farmID, setFarmID] = useState(farmsData[0].id.toString());

  const { latitude, longitude, weathers } = useMemo(() => {
    const weathers = data.filter(x => x.farm_id === Number(farmID));
    return {
      weathers,
      latitude: weathers.length > 0 ? weathers[0].latitude : null,
      longitude: weathers.length > 0 ? weathers[0].longitude : null,
    };
  }, [farmID]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      closeIcon={<CloseIcon />}
      width={700}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        <div className='pt-3'>
          <Dropdown
            label={translate(lang, 'Farm')}
            value={farmID}
            options={farmsData.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            onChange={v => setFarmID(v)}
          />
        </div>
        <div className='pt-3'>
          <Table
            rowKey='id'
            className='table'
            columns={columns(lang)}
            pagination={false}
            dataSource={weathers}
          />
        </div>
        <div className='pt-16'>
          {latitude && longitude && (
            <div>
              <Subtitle color='black-1' align='left' size={3} fontWeight={600}>
                {translate(lang, 'The data entry location')}
              </Subtitle>
              <div style={{ height: '300px' }}>
                <Map
                  zoom={10}
                  position={{
                    lat: Number(latitude),
                    lng: Number(longitude),
                  }}
                  defaultPosition={{
                    lat: Number(latitude),
                    lng: Number(longitude),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FarmWeatherModal;
