import { useEffect, useState } from 'react';
import {
  AbacusIcon,
  BgIcon,
  Button,
  Spinner,
  Title,
} from '../../components/shared';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { sendSingleRequest } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import {
  checkRolePermission,
  formatNumber,
} from '../../entities/util-functions';
import { BagStatus, TrackStatus } from '../../entities/general.entities';
import { ITrackCode } from '../../entities/task.entities';
import HarvestTrackModal from '../../components/track-modals/HarvestTrackModal';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import { selectFarmsData } from '../../store/farms/farms.selector';
import './styles.scss';

interface ISpecie {
  name: string;
  weight: number;
}

interface IData {
  day: string;
  processed: number;
  mussels: number;
  wasted: number;
  unprocessed: number;
  trackIDs: string[];
}

const columns = (lang: TLang | undefined, type: string) => [
  {
    key: 'date',
    title: translate(lang, 'Date'),
    render: (x: IData) => <span>{x.day}</span>,
  },
  {
    key: 'processed',
    title: translate(lang, 'Processed bags'),
    render: (x: IData) => <span>{x.processed}</span>,
  },
  {
    key: 'mussels',
    title: translate(lang, 'Mussels produced'),
    render: (x: IData) => (
      <span>{`${formatNumber(x.mussels)} ${
        type === 'MUSSEL' ? 'kg' : type === 'OYSTER' ? 'dz' : 'kg/dz'
      }`}</span>
    ),
  },
  {
    key: 'wasted',
    title: translate(lang, 'Waste produced'),
    render: (x: IData) => (
      <span>{`${formatNumber(x.wasted)} ${
        type === 'MUSSEL' ? 'kg' : type === 'OYSTER' ? 'dz' : 'kg/dz'
      }`}</span>
    ),
  },
  {
    key: 'remaining',
    title: translate(lang, 'Remaining bags'),
    render: (x: IData) => <span>{x.unprocessed}</span>,
  },
  { key: 'right', title: '', render: () => null },
];

const SummaryProcessPage = () => {
  const dispatch = useDispatch<any>();
  const profile = useSelector(selectProfile);
  const lang = useSelector(selectLang);
  const farms = useSelector(selectFarmsData);
  const type = farms.every(x => x.type === 'MUSSEL')
    ? 'MUSSEL'
    : farms.every(x => x.type === 'OYSTER')
    ? 'OYSTER'
    : 'MIXED';

  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageOptions, setPageOptions] = useState({
    current: 1,
    pageSize: 10,
    total: data.length ?? 0,
  });
  const [curBag, setCurBag] = useState({
    storage: 0,
    processing: 0,
    processed: 0,
  });
  const [speed, setSpeed] = useState({ bags: '', mussels: '', wasted: '' });
  const [trackIDs, setTrackIDs] = useState<string[]>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await sendSingleRequest(
        {},
        'GET',
        'api/task/track-codes',
        true,
      );
      if (!response.status) {
        dispatch(
          showFeedback({
            type: 'error',
            message: translate(lang, response.data?.message ?? 'Server error'),
            isMessage: true,
          }),
        );
        setLoading(false);
        return;
      }
      let codes: ITrackCode[] = response.data;
      codes = codes.filter(x => x.statuses.length > 0);
      codes.sort((a, b) => {
        const aTime = a.statuses[0].tracked_time;
        const bTime = b.statuses[0].tracked_time;
        return aTime > bTime ? -1 : aTime < bTime ? 1 : 0;
      });
      let inStoreCnt = 0,
        processingCnt = 0,
        todayProcessedCnt = 0;
      let dates: string[] = [];
      for (let code of codes) {
        if (
          code.statuses.some(
            x => x.status === TrackStatus.LOADED_TO_COLD_STORAGE,
          ) &&
          code.statuses.every(
            x =>
              x.status !== TrackStatus.STARTED_PROCESSING &&
              x.status !== TrackStatus.FINISHED_PROCESSING &&
              x.status !== TrackStatus.SEEDED,
          )
        ) {
          inStoreCnt++;
        }
        if (
          code.statuses.some(x => x.status === TrackStatus.STARTED_PROCESSING)
        ) {
          if (
            code.statuses.every(
              x => x.status !== TrackStatus.FINISHED_PROCESSING,
            )
          ) {
            processingCnt++;
          }
        }
        for (let x of code.statuses) {
          const d = defaultDateFormat(x.tracked_time);
          if (d && !dates.includes(d)) {
            dates.push(d);
          }
        }
      }
      dates.sort((a, b) =>
        `${b.substring(6)}${b.substring(3, 5)}${b.substring(
          0,
          2,
        )}`.localeCompare(
          `${a.substring(6)}${a.substring(3, 5)}${a.substring(0, 2)}`,
        ),
      );
      let totBags = 0,
        totMussels = 0,
        totWasted = 0;
      let todayStr = defaultDateFormat(Date.now());
      let result: IData[] = [];
      for (let d of dates) {
        let processed = 0,
          mussels = 0,
          wasted = 0,
          unprocessed = 0;
        let tracks: string[] = [];
        for (let code of codes) {
          if (
            code.statuses.every(x => defaultDateFormat(x.tracked_time) !== d)
          ) {
            continue;
          }
          if (
            code.statuses.some(
              x => x.status === TrackStatus.FINISHED_PROCESSING,
            )
          ) {
            processed++;
            if (d === todayStr) {
              todayProcessedCnt++;
            }
          } else {
            unprocessed++;
          }
          if (code.qr_data && code.qr_data.bag_details) {
            const tmp: ISpecie[] = code.qr_data.bag_details;
            mussels += tmp
              .filter(x => x.name === BagStatus.HARVESTED_MUSSELS)
              .reduce((p, c) => p + c.weight, 0);
            wasted += tmp
              .filter(x => x.name !== BagStatus.HARVESTED_MUSSELS)
              .reduce((p, c) => p + c.weight, 0);
          }
          tracks.push(code.track_id);
        }
        result.push({
          day: d,
          processed,
          mussels,
          wasted,
          unprocessed,
          trackIDs: tracks,
        });
        totBags += processed;
        totMussels += mussels;
        totWasted += wasted;
      }
      if (result.length <= 0) {
        setSpeed({
          bags: '-',
          mussels: '-',
          wasted: '-',
        });
      } else {
        setSpeed({
          bags: formatNumber(totBags / result.length),
          mussels: formatNumber(totMussels / result.length),
          wasted: formatNumber(totWasted / result.length),
        });
      }
      setCurBag({
        storage: inStoreCnt,
        processing: processingCnt,
        processed: todayProcessedCnt,
      });
      setData(result);
      setLoading(false);
    })();
  }, []);

  return (
    <div className='bg-secondary min-h-100'>
      <div className='container'>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Processing Stats')}
          </Title>
          {checkRolePermission(
            { deniedRoles: ['processing_plant'] },
            profile?.role,
          ) && (
            <Link to='/summary/harvest'>
              <Button color='blue' size={3} width='middle' type='fill'>
                {translate(lang, 'View Harvest Stats')}
              </Button>
            </Link>
          )}
        </div>
        {!loading ? (
          <div className='d-flex pl-15 pr-15'>
            <div className='dynamicCard'>
              <div className='d-flex align-items-center mb-18'>
                <BgIcon color='orange' icon={<AbacusIcon />} />
                <Title
                  size={6}
                  color='black-3 ml-18'
                  align='left'
                  fontWeight={600}
                >
                  {translate(lang, 'Currently in processing plant')}
                </Title>
              </div>
              <div className='info-content'>
                <div>
                  {`${translate(lang, 'Bags in cold storage')}: ${
                    curBag.storage
                  }`}
                </div>
                <div>
                  {`${translate(lang, 'Bags currently processing')}: ${
                    curBag.processing
                  }`}
                </div>
                <div>
                  {`${translate(lang, 'Bags processed today')}: ${
                    curBag.processed
                  }`}
                </div>
              </div>
            </div>
            <div className='dynamicCard'>
              <div className='d-flex align-items-center mb-18'>
                <BgIcon color='orange' icon={<AbacusIcon />} />
                <Title
                  size={6}
                  color='black-3 ml-18'
                  align='left'
                  fontWeight={600}
                >
                  {translate(lang, 'Processing speed')}
                </Title>
              </div>
              <div className='info-content'>
                <div>
                  {`${translate(lang, 'Avg. Bags per day')}: ${speed.bags}`}
                </div>
                <div>
                  {`${translate(
                    lang,
                    `Avg. ${
                      type === 'MUSSEL'
                        ? 'Mussels'
                        : type === 'OYSTER'
                        ? 'Oysters'
                        : 'Crops'
                    } per day`,
                  )}: ${speed.mussels}`}
                </div>
                <div>
                  {`${translate(lang, 'Avg. Waste per day')}: ${speed.wasted}`}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='mt-32 mb-32'>
            <Spinner />
          </div>
        )}
        <div className='farms__line-content'>
          <div className='d-flex justify-content-end align-items-end mt-17 mb-8'></div>
          <div className='d-flex farms__main'>
            <div className='w-100 pos-relative'>
              <Table
                rowKey='day'
                className='table table--isFarm'
                pagination={pageOptions}
                onChange={(o: any) => setPageOptions(o)}
                columns={columns(lang, type)}
                dataSource={data}
                onRow={d => ({ onClick: () => setTrackIDs(d.trackIDs) })}
              />
            </div>
          </div>
        </div>
      </div>
      {!!trackIDs && (
        <HarvestTrackModal
          visible={true}
          trackIDs={trackIDs}
          onClose={() => setTrackIDs(undefined)}
        />
      )}
    </div>
  );
};

export default SummaryProcessPage;
