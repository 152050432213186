import { useMemo } from 'react';
import { Subtitle } from '../../components/shared';
import { IFarmProperty, ILineProperty } from '../../entities/farms.entities';
import {
  IOysterHarvest,
  IOysterSeeding,
} from '../../entities/growing.entities';
import { formatNumber } from '../../entities/util-functions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { labelNumber } from '../../lib/common.helpers';

interface Props {
  farm: IFarmProperty;
  line: ILineProperty;
  seedings: IOysterSeeding[];
  harvests: IOysterHarvest[];
  harvestID: number;
  stageBasketsSum: number;
}

const OysterHarvestHeader = ({
  farm,
  line,
  seedings,
  harvests,
  harvestID,
  stageBasketsSum,
}: Props) => {
  const lang = useSelector(selectLang);

  const { seededBaskets, harvest } = useMemo(() => {
    const seededBaskets = seedings.reduce((p, c) => p + c.basket_count, 0);
    const harvest = harvests.find(x => x.id === harvestID);

    return { seededBaskets, harvest };
  }, [seedings, harvests, harvestID]);

  return (
    <div className='d-flex justify-content-between white-card pt-28 pr-16 pb-28 pl-24 mb-16 overflow-auto'>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.name}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Line name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {line.line_name}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm number')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.farm_number}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Number of %s seeded', line.farming_method)}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {seededBaskets}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm area')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {labelNumber(
            farm.area.length <= 0 ? undefined : Number(farm.area),
            'ha',
            '-',
          )}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Line %s', line.farming_method)}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${line.basket_count} ${line.farming_method}`}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'This harvest amount')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${formatNumber(harvest?.amount ?? 0)} dozens`}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {`${translate(lang, 'Harvested')} ${
            line.farming_method
          } in this stage`}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {stageBasketsSum}
        </Subtitle>
      </div>
    </div>
  );
};

export default OysterHarvestHeader;
