import { useSelector } from 'react-redux';
import {
  IMusselLineDetail,
  IOysterLineDetail,
} from '../../../entities/farms.entities';
import { translate } from '../../../lib/lang.helper';
import { CheckboxButton, Dropdown, Spinner } from '../../shared';
import { selectLang } from '../../../store/ui/ui.selector';
import { useEffect, useState } from 'react';
import { sendSingleRequest } from '../../../apis';
import { SelectSpatStoragesByFarmID } from '../../../store/extra/extra.selector';
import { getSpatSourceLabel } from '../../../lib/farm.helpers';

interface Props {
  status: boolean;
  onChange: (status: boolean) => void;
  lineID: number;
  farmID: number;
  trackSeedingID: number;
  batchID?: number;
  onBatchSelect: (id: number | undefined) => void;
}

const BatchAssessView = ({
  status,
  onChange,
  lineID,
  farmID,
  trackSeedingID,
  batchID,
  onBatchSelect,
}: Props) => {
  const lang = useSelector(selectLang);
  const spatsData = SelectSpatStoragesByFarmID(farmID);

  const [loading, setLoading] = useState(false);
  const [lineData, setLineData] = useState<
    IMusselLineDetail | IOysterLineDetail
  >();

  const toggleCheck = (c: boolean) => {
    onChange(c);
    if (!c) onBatchSelect(undefined);
  };

  useEffect(() => {
    (async () => {
      if (status && (!lineData || lineData.id !== lineID)) {
        setLoading(true);
        const response = await sendSingleRequest(
          { track_seeding_id: trackSeedingID },
          'GET',
          `api/farm/line/lines/${lineID}`,
          true,
        );
        setLoading(false);
        if (response.status) {
          setLineData(response.data);
        }
      }
    })();
  }, [lineID, trackSeedingID, status, lineData]);

  return (
    <div className='mb-32 mt-24'>
      {loading && (
        <div className='loader'>
          <Spinner />
        </div>
      )}
      <CheckboxButton
        label={translate(lang, 'SPECIE batch assessed')}
        checked={status}
        onChange={e => toggleCheck(e.target.checked)}
      />
      {status && !!lineData?.growing_spats && (
        <Dropdown
          label={translate(lang, 'Assessed original spat')}
          options={lineData.growing_spats.map(x => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: getSpatSourceLabel(x.id, spatsData),
          }))}
          value={batchID?.toString() ?? undefined}
          onChange={v => onBatchSelect(Number(v))}
        />
      )}
    </div>
  );
};

export default BatchAssessView;
