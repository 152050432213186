import { IInventoryAction, IInventoryState } from './inventories.type';

const initialState: IInventoryState = {
  inventories: [],
};

const inventoryReducer = (
  state = initialState,
  action: IInventoryAction,
): IInventoryState => {
  switch (action.type) {
    case 'INVENTORIES/SET_INVENTORIES': {
      return { ...state, inventories: action.payload };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default:
      return state;
  }
};

export default inventoryReducer;
