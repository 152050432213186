import { FC, useLayoutEffect, useState } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { Spinner } from './shared';

const unAuthRoutes = [
  '/sign-in',
  '/sign-up',
  '/recover-password/',
  '/message-registration',
  '/message-password',
];

const PrivateRoute: FC<{
  component: FC;
  path: string;
  exact: boolean;
  isAdmin?: boolean;
  isFinance?: boolean;
}> = ({ component, path, exact, isAdmin = false, isFinance = false }) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [condition, setCondition] = useState<boolean | null>();

  useLayoutEffect(() => {
    setCondition(null);
    if (unAuthRoutes.some(x => history.location.pathname.includes(x))) {
      const backUrl = query.get('redirect');
      if (!backUrl) {
        history.push('/');
      } else if (backUrl.startsWith(window.location.origin)) {
        history.push(backUrl.substring(window.location.origin.length));
      } else {
        window.location.href = backUrl;
      }
    }
    if (profile?.role) {
      if (
        (isAdmin && profile?.role !== 'owner' && profile.role !== 'admin') ||
        (isFinance &&
          profile.role !== 'owner' &&
          profile.role !== 'admin' &&
          !profile.finance_permission)
      ) {
        history.push('/');
      } else if (
        profile.role === 'processing_plant' &&
        history.location.pathname === '/'
      ) {
        history.replace('/farms');
      } else {
        setCondition(true);
      }
    }
  }, [history.location.pathname, profile]);

  return (
    <>
      {condition === false && <Redirect to='/sign-in' />}
      {condition === true && (
        <Route path={path} exact={exact} component={component} />
      )}
      {!(condition === false || condition === true) && (
        <div className='d-flex justify-content-center align-items-center min-height'>
          <Spinner />
        </div>
      )}
    </>
  );
};
export default PrivateRoute;
