import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  CloseIcon,
  Dropdown,
  Input,
  Spinner,
  Subtitle,
  Title,
} from '../../components/shared';
import { defaultBagMusselTypes } from '../../entities/inventory.entities';
import { RootState } from '../../store/rootReducer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSquareCheck, faSquare } from '@fortawesome/free-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { showFeedback } from '../../store/ui/ui.actions';
import { Modal } from 'antd';
import { TrackStatus } from '../../entities/general.entities';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { ITrackCode } from '../../entities/task.entities';
import { selectUtils } from '../../store/utils/utils.selector';
import { toMillisecond, toSecond } from '../../util/toggleSecondMillisecond';
import './styles.scss';

library.add(faSquareCheck, faSquare);

interface BagProcessProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  trackCode: ITrackCode;
}

const BagProcessModal = ({
  visible,
  onCancel,
  onConfirm,
  trackCode,
}: BagProcessProps) => {
  const bagNames = useSelector(selectUtils)
    .filter(x => x.type === 'process_bag')
    .map(x => x.name);
  const bagMussels = [...defaultBagMusselTypes, ...bagNames];

  const [mussels, setMussels] = useState(
    bagMussels.map(x => ({ name: x, weight: '' })),
  );
  const [disabled, setDisabled] = useState(false);

  const handleChangeInput = (i: number, v: string) => {
    let tmp = [...mussels];
    if (tmp.length <= i) return;
    tmp[i].weight = v;
    setMussels(tmp);
  };
  const confirmClick = async () => {
    const data = {
      track_id: trackCode.track_id,
      parent_code: trackCode.parent_code,
      data: mussels.map(x => ({ name: x.name, weight: Number(x.weight) })),
    };
    setDisabled(true);
    const response = await sendSingleRequest(
      data,
      'POST',
      'api/task/track-harvest',
      true,
    );
    setDisabled(false);
    if (response.status) {
      onConfirm();
    } else {
      window.alert(response.data?.message ?? 'Failed');
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            Bag Processed
          </Subtitle>
        </div>
        <div className='pt-3 mb-17'>
          {mussels.map((x, i) => (
            <div key={i} className='mb-17'>
              <Input
                label={x.name}
                type='number'
                value={x.weight}
                onChange={e => handleChangeInput(i, e.target.value)}
                unit='kg'
              />
            </div>
          ))}
        </div>
        <div className='modal-button d-flex justify-content-end mt-32'>
          <Button
            width={'wide'}
            size={2}
            type='fill'
            color='red'
            onClick={onCancel}
            disabled={disabled}
          >
            Close
          </Button>
          <Button
            width={'wide'}
            size={2}
            type='fill'
            color='green'
            className='ml-16'
            onClick={confirmClick}
            disabled={disabled}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

interface IWeight {
  name: string;
  weight: number;
}

const statusLabel = (x: string) =>
  x === TrackStatus.MIDDLE_HARVESTED ? TrackStatus.HARVESTED : x;

interface BagSumProps {
  data: IWeight[];
}

const BagSumView = ({ data }: BagSumProps) => (
  <div className='bag-box'>
    <div className='--label'>Processed Details</div>
    {data?.map((x, j) => (
      <div key={j} className='--data-row'>
        <span className='--data-txt'>{x.name}</span>
        <span className='--data-txt'>{`${x.weight} kg`}</span>
      </div>
    ))}
  </div>
);

interface StatusBtnProps {
  status: string;
  trackCode: ITrackCode;
  updateClick: (s: string) => void;
  removeClick: (s: string) => void;
  disabled: boolean;
  upstate?: string;
}

const StatusButton = ({
  status,
  trackCode,
  updateClick,
  removeClick,
  disabled,
  upstate,
}: StatusBtnProps) => {
  const profile = useSelector(selectProfile);

  return (
    <div className='status-box'>
      {trackCode.statuses.length > 0 ? (
        <div className='d-flex align-items-center'>
          <div className='--done-txt'>{statusLabel(status)}</div>
          <FontAwesomeIcon icon={faSquareCheck} color='green' size='lg' />
        </div>
      ) : (
        <div
          className={`--blank-btn ${
            upstate === status ? '--disabled-btn' : ''
          }`}
          onClick={() => updateClick(status)}
        >
          <div className='--blank-txt'>{statusLabel(status)}</div>
          <FontAwesomeIcon icon={faSquare} color='#658ca4' size='lg' />
        </div>
      )}
      {trackCode.statuses.map((t, j) => (
        <div key={j} className='--record-txt'>
          {`${moment(toMillisecond(t.tracked_time)).format(
            'lll',
          )} performed by ${t.user?.name}`}
        </div>
      ))}
      {trackCode.statuses.some(t => t.user?.id === profile?.id) && (
        <div
          className={`--remove-btn ${disabled ? '--disabled-btn' : ''}`}
          onClick={() => removeClick(status)}
        >
          <span
            style={{
              color: '#dc2626',
              fontSize: 16,
              fontWeight: 'bold',
            }}
          >
            Cancel
          </span>
        </div>
      )}
    </div>
  );
};

interface ResViewProps {
  trackCode: ITrackCode;
  onRefresh: () => void;
}

const ResultView = ({ trackCode, onRefresh }: ResViewProps) => {
  const dispatch = useDispatch<any>();
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [upstate, setUpstate] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const [visModal, setVisModal] = useState<any>();

  const updateClick = async (status: string) => {
    if (!profile || disabled) return;
    setUpstate(status);
    setDisabled(true);
    const data = {
      track_id: trackCode.track_id,
      status,
      tracked_time: toSecond(Date.now()),
      parent_code: trackCode.parent_code,
    };
    const response = await sendSingleRequest(
      data,
      'POST',
      'api/task/track-status',
      true,
    );
    setDisabled(false);
    setUpstate(undefined);
    if (response.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Updated successfully',
        }),
      );
      onRefresh();
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Unknown error',
        }),
      );
    }
  };
  const removeClick = async (status: string) => {
    if (!profile || disabled) return;
    const trk = trackCode.statuses.find(
      x => x.status === status && x.user?.id === profile.id,
    );
    if (!trk) return;

    setDisabled(true);
    const response = await sendSingleRequest(
      { track_id: trackCode.track_id, status },
      'DELETE',
      'api/task/track-status',
      true,
    );
    if (response.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Removed successfully',
        }),
      );
      onRefresh();
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message,
        }),
      );
    }
    setDisabled(false);
  };
  const openModal = () => {
    if (!profile || disabled) return;

    setVisModal(true);
  };

  const { splitCount, processedSum } = useMemo(() => {
    if (!trackCode) {
      return { splitCount: 0, processedSum: null };
    } else {
      const processedSum: IWeight[] = trackCode.qr_data?.bag_details ?? [];
      return { splitCount: trackCode.children_count, processedSum };
    }
  }, [trackCode]);

  const canProcess =
    (!trackCode.qr_data ||
      !trackCode.qr_data.bag_details ||
      trackCode.qr_data.bag_details.length <= 0) &&
    trackCode.statuses.some(x => x.status === TrackStatus.STARTED_PROCESSING);

  return (
    <div className='mb-17 mt-17 pr-17 pl-17'>
      <div className='text-left'>
        <div className='mb-17'>
          {trackCode.statuses.map(x =>
            x.status === TrackStatus.OFFLOADED_FROM_BOAT ? (
              <div key={x.status}>
                <StatusButton
                  key={x.status}
                  status={x.status}
                  upstate={upstate}
                  trackCode={trackCode}
                  updateClick={updateClick}
                  removeClick={removeClick}
                  disabled={disabled}
                />
                {splitCount > 0 && (
                  <div className='--notify-txt'>{`You have ${splitCount} split smaller bags`}</div>
                )}
              </div>
            ) : (
              <StatusButton
                key={x.status}
                status={x.status}
                trackCode={trackCode}
                updateClick={updateClick}
                removeClick={removeClick}
                disabled={disabled}
                upstate={upstate}
              />
            ),
          )}
        </div>
        <div className='mb-17'>
          {trackCode.qr_data &&
          trackCode.qr_data.bag_details &&
          trackCode.qr_data.bag_details.length > 0 ? (
            <BagSumView data={trackCode.qr_data.bag_details} />
          ) : !!processedSum && processedSum.length > 0 ? (
            <BagSumView data={processedSum} />
          ) : (
            <></>
          )}
        </div>
        <div className='mt-32 mb-17 text-center'>
          {canProcess && (
            <div className='--button' onClick={openModal}>
              <span className='--btn-txt'>Enter Bag Processed</span>
            </div>
          )}
        </div>
      </div>
      {!!visModal && (
        <BagProcessModal
          visible={true}
          onCancel={() => setVisModal(undefined)}
          onConfirm={() => {
            setVisModal(undefined);
            onRefresh();
          }}
          trackCode={trackCode}
        />
      )}
    </div>
  );
};

const CvtNum = (x: string | null) => (x ? Number(x) : undefined);

const QRScanPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const farmsData = useSelector(selectFarmsData);

  const trackID = query.get('track_id');
  const [farmId, setFarmId] = useState(CvtNum(query.get('farm_id')));
  const [lineId, setLineId] = useState(CvtNum(query.get('line_id')));
  const [seedingId, setSeedingId] = useState<number | undefined>(
    farmsData.find(x => x.id === farmId)?.lines.find(y => y.id === lineId)
      ?.growing_cycle?.main_seed.id,
  );
  const [loading, setLoading] = useState(false);
  const [trackCode, setTrackCode] = useState<ITrackCode>();

  const currentFarm = farmsData.find(x => x.id === farmId);

  const updateFarm = (val: string) => {
    setFarmId(Number(val));
    setLineId(undefined);
    setSeedingId(undefined);
  };
  const updateLine = (val: string) => {
    if (!currentFarm) return;
    const l = currentFarm.lines.find(x => x.id === Number(val));
    if (!l?.growing_cycle) return;
    setLineId(l.id);
    setSeedingId(l.growing_cycle.main_seed.id);
  };

  const loadData = useCallback(async () => {
    if (!trackID) return;
    setLoading(true);
    const response = await sendSingleRequest(
      { track_id: trackID },
      'GET',
      'api/task/track-codes',
      true,
    );
    setLoading(false);
    if (response.status && response.data.length > 0) {
      setTrackCode(response.data[0]);
    } else {
      showFeedback({
        isMessage: true,
        type: 'error',
        message: response.data?.message ?? 'Track information not found',
      });
    }
  }, [trackID]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className='container w-100 pb-32 qr-scan-page'>
      <div className='d-flex align-items-center mt-28'>
        <div className='notifications w-100'>
          <div className='mt-28 mb-28 d-flex flex-wrap align-items-center justify-content-between'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              QR Code Scan
            </Title>
          </div>
        </div>
      </div>
      <div className='pb-10'>
        {!trackID ? (
          <div className='header-alert'>Empty track code is not allowed</div>
        ) : loading ? (
          <div className='mt-17 mb-17 text-center'>
            <Spinner />
          </div>
        ) : !seedingId ? (
          <div className='mt-17 mb-17'>
            <div className='mb-17'>
              <Subtitle color='red' align='left' size={4} fontWeight={500}>
                This code has not been attached to harvest yet. Under harvest
                option, scan bag there to connect code to the line.
              </Subtitle>
            </div>
            <div className='mb-17'>
              <Subtitle color='black-2' align='left' size={4} fontWeight={500}>
                Where are you going to attach?
              </Subtitle>
            </div>
            <div className='mb-17'>
              <Dropdown
                label='Select Farm'
                options={farmsData.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} - ${x.farm_number}`,
                }))}
                onChange={updateFarm}
              />
            </div>
            {!!farmId && (
              <div className='mb-17'>
                <Dropdown
                  label='Select Line'
                  options={
                    farmsData
                      .find(x => x.id === farmId)
                      ?.lines.filter(y => !!y.growing_cycle)
                      .map(x => ({
                        id: x.id.toString(),
                        value: x.id.toString(),
                        label: x.line_name,
                      })) ?? []
                  }
                  onChange={updateLine}
                />
              </div>
            )}
          </div>
        ) : !!trackCode && !!lineId ? (
          <ResultView trackCode={trackCode} onRefresh={loadData} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default QRScanPage;
