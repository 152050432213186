import { useHistory } from 'react-router-dom';

const useMenuHandler = () => {
  const history = useHistory();

  const redirectToFarm = (idFarm: string | number) => {
    history.push(`/farms/${idFarm}`);
  };

  const redirectToLine = (idFarm: string | number, idLine: string | number) => {
    history.push(`/farms/${idFarm}/${idLine}`);
  };

  const redirectToEditFarm = (idFarm: string) => {
    history.push(`/farms/farm-edit/${idFarm}`);
  };

  const redirectToFarmVisualLine = (idFarm: string | number) => {
    history.push(`/farms/visual-lines/${idFarm}`);
  };

  const redirectToHarvestDetail = (harvestId: string | number) => {
    history.push(`/harvest/${harvestId}`);
  };

  return {
    redirectToFarm,
    redirectToLine,
    redirectToEditFarm,
    redirectToFarmVisualLine,
    redirectToHarvestDetail,
  };
};

export default useMenuHandler;
