export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const parseImageUrlToFileItem = (url: string) => {
  try {
    const s = url.split('/');
    const name = s[s.length - 1];
    const type = name.split('.')[1] ?? 'Unknown';
    const uid = 'parseImageUrlToFileItem-' + Date.now() + Math.random();
    return { uid, type, name, size: 1, thumbUrl: url };
  } catch (error) {
    console.log('url = ', url);
    return null;
  }
};
