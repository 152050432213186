import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IAutomation } from '../../entities/automation.entities';
import { Button, CloseIcon, Dropdown, Input, Subtitle } from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { showFeedback } from '../../store/ui/ui.actions';
import { sendSingleRequest } from '../../apis';
import { loadAutomationData } from '../../store/automation/automation.actions';
import { selectBoats } from '../../store/users/users.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectFarmsData } from '../../store/farms/farms.selector';

interface IFormData {
  type: 'Seeding' | 'Harvesting' | 'Assessment' | 'Regular';
  time: number;
  unit: 'hour' | 'day' | 'week' | 'month';
  assigned_boat_id: number | null;
  title: string;
  description: string;
  condition?: 'FULL' | 'PARTIAL' | null;
  farm_id?: number;
  line_id?: number;
}

const defaultForm: IFormData = {
  type: 'Seeding',
  time: 0,
  unit: 'day',
  assigned_boat_id: null,
  title: '',
  description: '',
  condition: undefined,
};

const typeList = ['Seeding', 'Harvesting', 'Assessment', 'Regular'];
const units = ['hour', 'day', 'week', 'month'];

interface Props {
  visible: boolean;
  data?: IAutomation;
  editId?: number;
  onCancel: () => void;
  onConfirm: (d: any) => void;
  onSelectTag: () => void;
}

const AutomationModal = ({
  visible,
  editId,
  data,
  onCancel,
  onConfirm,
  onSelectTag,
}: Props) => {
  const dispatch = useDispatch<any>();
  const boats = useSelector(selectBoats);
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);

  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState<IFormData>(defaultForm);

  const updateForm = (key: keyof IFormData, val: any) => {
    if (key === 'type' && val === 'Tag Schedule') {
      onSelectTag();
    } else {
      if (key === 'assigned_boat_id' && val === 0) {
        val = null;
      }
      setFormData(prv => ({ ...prv, [key]: val }));
    }
  };
  const updateCondition = (val: 'FULL' | 'PARTIAL' | 'A') => {
    setFormData(prv => ({ ...prv, condition: val === 'A' ? undefined : val }));
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message,
      }),
    );
  const validData = () => {
    let result = { ...formData };
    if (result.type === 'Regular' && result.time <= 0) {
      showError('If type is Regular, the time should be greater than zero');
      return null;
    }
    if (result.title.length <= 0) {
      showError('Title should not be empty');
      return null;
    }
    if (result.description.length <= 0) {
      showError('Description should not be empty');
      return null;
    }
    if (result.type !== 'Harvesting') {
      result.condition = undefined;
    }
    return result;
  };
  const confirmClick = async () => {
    const form = validData();
    if (!form) return;
    setDisabled(true);
    const resp = !editId
      ? await sendSingleRequest(
          form,
          'POST',
          'api/automation/automations',
          true,
        )
      : await sendSingleRequest(
          form,
          'PUT',
          `api/automation/automations/${editId}`,
          true,
        );
    setDisabled(false);
    if (resp.status) {
      dispatch(
        showFeedback({ type: 'success', message: 'Success', isMessage: true }),
      );
      dispatch(loadAutomationData());
      onConfirm(resp.data);
    } else {
      showError(resp.data?.message ?? 'Failed to submit');
    }
  };
  const types = editId ? typeList : [...typeList, 'Tag Schedule'];

  useEffect(() => {
    if (data) {
      let tmp: any = { ...defaultForm };
      for (let k in tmp) {
        if ((data as any)[k]) tmp[k] = (data as any)[k];
      }
      if (data.farm) {
        tmp.farm_id = data.farm.id;
      }
      if (data.line) {
        tmp.line_id = data.line.id;
      }
      setFormData(tmp);
    }
  }, [data]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, !editId ? 'Add Automation' : 'Edit Automation')}
          </Subtitle>
        </div>
        <div className='mt-17 mb-7'>
          <Dropdown
            className='mr-16 w-100 mb-24'
            placeholder={translate(lang, 'Choose Type')}
            onChange={v => updateForm('type', v)}
            label={translate(lang, 'Type')}
            options={types.map(x => ({
              id: x,
              label: translate(lang, x),
              value: x,
            }))}
            value={formData.type}
          />
          {formData.type === 'Harvesting' ? (
            <>
              <div className='d-flex time-input'>
                <div className='mr-16 w-50 mb-17'>
                  <Input
                    label={translate(lang, 'Time')}
                    type='number'
                    onChange={e => updateForm('time', Number(e.target.value))}
                    className='w-100'
                    value={formData.time.toString()}
                    min={0}
                  />
                </div>
                <div className='w-50 mb-17'>
                  <Dropdown
                    label={translate(lang, 'Unit')}
                    placeholder={translate(lang, 'Choose Unit')}
                    className='w-100'
                    onChange={v => updateForm('unit', v)}
                    options={units.map(x => ({
                      id: x,
                      label: translate(lang, x),
                      value: x,
                    }))}
                    value={formData.unit}
                  />
                </div>
              </div>
              <div className='pb-17'>
                <Dropdown
                  label={translate(lang, 'Condition')}
                  className='w-100'
                  options={[
                    {
                      id: 'A',
                      value: 'A',
                      label: translate(lang, 'For all types of harvests'),
                    },
                    {
                      id: 'F',
                      value: 'FULL',
                      label: translate(lang, 'For only full harvests'),
                    },
                    {
                      id: 'P',
                      value: 'PARTIAL',
                      label: translate(lang, 'For only partial harvests'),
                    },
                  ]}
                  value={formData.condition ?? 'A'}
                  onChange={v => updateCondition(v as any)}
                />
              </div>
            </>
          ) : (
            <>
              <div className='d-flex pb-17 time-input'>
                <div className='mr-16 w-50 mb-24'>
                  <Input
                    label={translate(lang, 'Time')}
                    type='number'
                    onChange={e => updateForm('time', Number(e.target.value))}
                    className='w-100'
                    value={formData.time.toString()}
                    min={0}
                  />
                </div>
                <div className='w-50 mb-24'>
                  <Dropdown
                    label={translate(lang, 'Unit')}
                    placeholder={translate(lang, 'Choose Unit')}
                    className='w-100'
                    onChange={v => updateForm('unit', v)}
                    options={units.map(x => ({
                      id: x,
                      label: translate(lang, x),
                      value: x,
                    }))}
                    value={translate(lang, formData.unit)}
                  />
                </div>
              </div>
            </>
          )}
          <Dropdown
            label={translate(lang, 'Select responsible boat')}
            placeholder={translate(lang, 'Select responsible boat')}
            className='mb-16'
            value={formData.assigned_boat_id?.toString() ?? '0'}
            onChange={v => updateForm('assigned_boat_id', Number(v))}
            options={[
              {
                value: '0',
                id: '0',
                label: ` -- ${translate(lang, 'No Boat')} -- `,
              },
              ...boats.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: `${x.name} - ${x.reg_number}`,
              })),
            ]}
          />
          <Dropdown
            label={translate(lang, 'Select farm')}
            className='mb-16'
            value={formData.farm_id?.toString() ?? undefined}
            options={farmsData.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            onChange={v => {
              updateForm('farm_id', Number(v));
              updateForm('line_id', undefined);
            }}
          />
          {!!formData.farm_id && (
            <Dropdown
              label={translate(lang, 'Select line')}
              className='mb-16'
              value={formData.line_id?.toString() ?? undefined}
              options={
                farmsData
                  .find(x => x.id === formData.farm_id)
                  ?.lines.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.line_name,
                  })) ?? []
              }
              onChange={v => updateForm('line_id', Number(v))}
            />
          )}
          <Input
            label={translate(lang, 'Title')}
            placeholder={translate(lang, 'title')}
            type='text'
            onChange={e => updateForm('title', e.target.value)}
            className='mr-16 w-100 mb-24'
            value={formData.title}
          />
          <Input
            label={translate(lang, 'Description')}
            placeholder={translate(lang, 'description')}
            type='textarea'
            onChange={e => updateForm('description', e.target.value)}
            className='mr-16 w-100 mb-24'
            value={formData.description}
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default AutomationModal;
