import { useState } from 'react';
import { Collapse, Modal } from 'antd';
import { useWidth } from '../../util/useWidth';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Dropdown,
  Subtitle,
  ToggleButton,
} from '../shared';
import { CaretLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { IUserResource } from '../../entities/user.entities';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const permissionsLabel = [
  { label: 'View', key: 'view_permission' },
  { label: 'Edit', key: 'edit_permission' },
  { label: 'Finance', key: 'finance_permission' },
];

interface Props {
  visible: boolean;
  title: string;
  className?: string;
  onClose: () => void;
  onConfirm: (d: any) => void;
  data: IUserResource;
}

const UserPermissionsModal = ({
  visible,
  title,
  onClose,
  onConfirm,
  data,
  className,
}: Props) => {
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const farms = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [checkedFarms, setCheckedFarms] = useState<number[]>(data.farms ?? []);
  const [checkedLines, setCheckedLines] = useState<number[]>(data.lines ?? []);
  const [checkedPermissions, setCheckedPermissions] = useState({
    view_permission: !!data.view_permission,
    edit_permission: !!data.edit_permission,
    finance_permission: !!data.finance_permission,
  });
  const [role, setRole] = useState(data.role ?? 'user');

  const isSelectedFarm = (id: number) => checkedFarms.includes(id);
  const isSelectedLine = (id: number) => checkedLines.includes(id);
  const isFullSelected = (id: number) => {
    const f = farms.find(x => x.id === id);
    return f?.lines.every(l => isSelectedLine(l.id));
  };

  const toggleFarm = (id: number, val: boolean) => {
    const f = farms.find(x => x.id === id);
    const lines = f?.lines ?? [];
    if (isFullSelected(id)) {
      const ls = checkedLines.filter(c => lines.findIndex(l => l.id === c) < 0);
      const fs = checkedFarms.filter(x => x !== f?.id);
      setCheckedFarms(fs);
      setCheckedLines(ls);
    } else {
      let ls = [...checkedLines];
      for (let l of lines) {
        if (!checkedLines.includes(l.id)) ls.push(l.id);
      }
      let fs = [...checkedFarms];
      if (!fs.includes(id)) fs.push(id);
      setCheckedFarms(fs);
      setCheckedLines(ls);
    }
  };
  const toggleLine = (id: number, val: boolean) => {
    const f = farms.find(x => x.lines.findIndex(l => l.id === id) > -1);
    if (!f) return;
    let ls: number[] = [];
    if (checkedLines.includes(id)) {
      ls = checkedLines.filter(x => x !== id);
    } else {
      ls = [...checkedLines, id];
    }
    setCheckedLines(ls);
    const lines = f.lines ?? [];
    if (lines.every(x => !ls.includes(x.id)) && checkedFarms.includes(f.id)) {
      setCheckedFarms(checkedFarms.filter(x => x !== f.id));
    } else if (
      lines.some(x => ls.includes(x.id)) &&
      !checkedFarms.includes(f.id)
    ) {
      setCheckedFarms([...checkedFarms, f.id]);
    }
  };
  const togglePermission = (id: string) => {
    let tmp: any = { ...checkedPermissions };
    tmp[id] = !tmp[id];
    setCheckedPermissions(tmp);
  };

  const handleConfirm = () => {
    setDisabled(true);
    sendSingleRequest(
      {
        user_id: data.id,
        farms: checkedFarms,
        lines: checkedLines,
        ...checkedPermissions,
        role,
      },
      'POST',
      'api/user/user-access',
      true,
    ).then(res => {
      setDisabled(false);
      if (res.status) {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, 'Updated successfully'),
          }),
        );
        onConfirm(data);
      } else {
        dispatch(
          showFeedback({
            isMessageModal: true,
            type: 'error',
            message: translate(lang, res.data?.message ?? 'Unknown error'),
          }),
        );
      }
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        <div className='pt-17 pb-32 ml-24 mr-24'>
          <Dropdown
            label={translate(lang, 'Role')}
            value={role}
            options={[
              { id: 'admin', value: 'admin', label: translate(lang, 'Admin') },
              { id: 'user', value: 'user', label: translate(lang, 'User') },
            ]}
            onChange={(v: any) => setRole(v)}
          />
        </div>
        {role === 'user' && (
          <>
            {permissionsLabel.map((x, i) => (
              <div className='pt-16 ml-32 mr-32' key={i}>
                <ToggleButton
                  className='mb-16'
                  label={x.label}
                  isfullWidth
                  checked={
                    checkedPermissions[x.key as keyof typeof checkedPermissions]
                  }
                  isLeftText
                  onChange={e => togglePermission(x.key)}
                />
              </div>
            ))}
            <div className='pt-16'>
              <div className='user-collapse'>
                <Collapse
                  expandIcon={({ isActive }) => (
                    <CaretLeftOutlined rotate={isActive ? 180 : 0} />
                  )}
                >
                  {farms.map(farm => (
                    <Collapse.Panel
                      className={'ant-collapse-item'}
                      header={farm.name}
                      key={farm.id}
                      extra={
                        <CheckboxButton
                          label=''
                          checked={isSelectedFarm(farm.id)}
                          isNegative={!isFullSelected(farm.id)}
                          onChange={e => toggleFarm(farm.id, e.target.checked)}
                        />
                      }
                    >
                      {farm.lines.map(line => (
                        <CheckboxButton
                          key={line.id}
                          label={line.line_name}
                          checked={isSelectedLine(line.id)}
                          isfullWidth
                          isLeftText
                          onChange={e => toggleLine(line.id, e.target.checked)}
                        />
                      ))}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center mt-27'>
        <Button
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='red'
          onClick={onClose}
          disabled={disabled}
        >
          {translate(lang, 'Cancel')}
        </Button>
        <Button
          width={width < 769 ? 'wide' : 'small'}
          size={2}
          type='fill'
          color='green'
          className='ml-16'
          onClick={handleConfirm}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default UserPermissionsModal;
