import { ISetUIAction, IUIState } from './ui.type';
import packageJson from '../../../package.json';

const initialState: IUIState = {
  isSpinner: false,
  allFeedbacks: [],
  version: packageJson.version,
};

const uiReducer = (state = initialState, action: ISetUIAction): IUIState => {
  switch (action.type) {
    case 'UI/IS_SPINNER': {
      return { ...state, isSpinner: action.payload };
    }
    case 'UI/ADD_FEEDBACK': {
      return {
        ...state,
        allFeedbacks: [...(state.allFeedbacks ?? []), action.payload],
      };
    }
    case 'UI/REMOVE_FEEDBACK': {
      return {
        ...state,
        allFeedbacks:
          state.allFeedbacks?.filter(x => x.id !== action.payload) ?? [],
      };
    }
    case 'UI/CLEAR_FEEDBACK': {
      return { ...state, allFeedbacks: [] };
    }
    case 'UI/SET_LANG': {
      return { ...state, lang: action.payload };
    }
    case 'UI/SET_VISIBLE_GUIDE': {
      return { ...state, visibleGuide: action.payload };
    }
    case 'UI/SET_MAP_TYPE': {
      return { ...state, mapType: action.payload };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default uiReducer;
