import { useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Title, Button, DropdownMenu } from '../shared';
import ModalUtil from './ModalUtil';
import { loadUtilsData } from '../../store/utils/utils.actions';
import { IUtilData } from '../../entities/utils.entities';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { sendSingleRequest } from '../../apis';
import './styles.scss';

interface IColumn {
  title: string;
  dataIndex?: string;
  key: string;
  align?: 'left' | 'right' | 'center' | undefined;
  render: any;
}

const columns: Array<IColumn> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => <div data-name='name'>{name}</div>,
  },
];

interface Props {
  data: IUtilData[];
  type: IUtilData['type'];
  label: string;
  btnLabel?: string;
  flagLabel?: string;
  flagPlaceholder?: string;
  numLabel?: string;
  numUnit?: string;
  extraColumns?: IColumn[];
  onUpdate?: () => void;
}

const UtilsTable = ({
  data,
  type,
  label,
  btnLabel,
  flagLabel,
  flagPlaceholder,
  numLabel,
  numUnit,
  extraColumns,
  onUpdate,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [delUtil, setDelUtil] = useState<IUtilData>();
  const [editUtil, setEditUtil] = useState<IUtilData>();
  const [addUtil, setAddUtil] = useState<IUtilData>();

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        message: translate(lang, message),
        isMessage: true,
      }),
    );

  const onModalConfirm = async (newData: {
    name: string;
    flag?: boolean;
    num_val?: number | null;
  }) => {
    if (!delUtil && !editUtil && !addUtil) return;
    setDisabled(true);
    if (delUtil) {
      const res = await sendSingleRequest(
        {},
        'DELETE',
        `api/utils/${delUtil.id}`,
        true,
      );
      if (res.status) {
        await dispatch(loadUtilsData());
        if (onUpdate) {
          onUpdate();
        }
        setDisabled(false);
        setDelUtil(undefined);
      } else {
        showError(res.data?.message ?? translate(lang, 'Server error'));
      }
    } else if (editUtil) {
      const res = await sendSingleRequest(
        { ...editUtil, ...newData },
        'PUT',
        `api/utils/${editUtil.id}`,
        true,
      );
      if (res.status) {
        await dispatch(loadUtilsData());
        if (onUpdate) {
          onUpdate();
        }
        setDisabled(false);
        setEditUtil(undefined);
      } else {
        showError(res.data?.message ?? translate(lang, 'Server error'));
      }
    } else if (addUtil) {
      const res = await sendSingleRequest(
        { type: addUtil.type, ...newData },
        'POST',
        'api/utils',
        true,
      );
      if (res.status) {
        await dispatch(loadUtilsData());
        if (onUpdate) {
          onUpdate();
        }
        setDisabled(false);
        setAddUtil(undefined);
      } else {
        showError(res.data?.message ?? translate(lang, 'Server error'));
      }
    }
  };
  const onCancel = () => {
    setAddUtil(undefined);
    setEditUtil(undefined);
    setDelUtil(undefined);
  };

  const cols = () => {
    let result = [...columns];
    if (flagLabel) {
      result.push({
        title: flagLabel,
        dataIndex: 'flag',
        key: 'flag',
        align: 'center',
        render: (flag: boolean) => (
          <div data-name='flag'>{flag ? 'Yes' : 'No'}</div>
        ),
      });
    }
    if (numLabel) {
      result.push({
        title: numLabel,
        dataIndex: 'num_val',
        key: 'num_val',
        render: (x: number | null) => (
          <div data-name='num_val'>
            {x !== null && x !== undefined ? `${x} ${numUnit}` : '-'}
          </div>
        ),
      });
    }
    if (extraColumns && extraColumns.length > 0) {
      result.push(...extraColumns);
    }
    result.push({
      title: '',
      key: 'more',
      align: 'right',
      render: (d: any, allData: any, element: any, isRedirect: any) => (
        <div className={'wwrap'}>
          {!d?.disabled && (
            <div>
              <DropdownMenu
                data={d}
                column='isUtil'
                onEdit={d => setEditUtil(d)}
                onDeleteRow={d => setDelUtil(d)}
                type='utils'
                isRedirect={isRedirect}
              />
            </div>
          )}
        </div>
      ),
    });
    return result;
  };
  const curUtil = addUtil ?? editUtil ?? delUtil;
  const modalType = addUtil ? 'create' : editUtil ? 'update' : 'delete';

  return (
    <>
      <div className='d-flex mb-16 align-items-center justify-content-between'>
        <Title
          className='mb-16'
          size={6}
          color='black-3'
          align='default'
          fontWeight={500}
        >
          {translate(lang, label)}
        </Title>
      </div>
      <div className='d-flex white-card pt-12 pl-16 mb-8'>
        <div className='d-flex align-items-center'>
          <Button
            color='blue'
            size={3}
            width='small'
            type='fill'
            onClick={() => setAddUtil({ id: 0, type, name: '' })}
          >
            {!btnLabel
              ? `${translate(lang, 'Add')} ${translate(lang, label)}`
              : translate(lang, btnLabel)}
          </Button>
        </div>
      </div>
      <Table
        scroll={{}}
        className={'table table--units table--is__cursor'}
        rowKey='id'
        pagination={false}
        columns={cols()}
        dataSource={data}
      />
      {!!curUtil && (
        <ModalUtil
          visible={!!curUtil}
          data={{
            name: curUtil.name,
            flag: curUtil.flag ?? undefined,
            num_val: curUtil.num_val,
          }}
          type={modalType}
          onCancel={onCancel}
          onConfirm={onModalConfirm}
          title={label}
          disabled={disabled}
          flagLabel={flagLabel}
          flagPlaceholder={flagPlaceholder}
          numLabel={numLabel}
          numUnit={numUnit}
        />
      )}
    </>
  );
};

export default UtilsTable;
