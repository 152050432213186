import { useState } from 'react';
import { Modal } from 'antd';
import { ILineGroup } from '../../entities/farms.entities';
import {
  Button,
  CloseIcon,
  Dropdown,
  Input,
  Subtitle,
} from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IFormData {
  farm_id: number | null;
  name: string;
  description?: string | null;
}

interface Props {
  visible: boolean;
  onClose: (d?: any) => void;
  lineGroup?: ILineGroup;
  className?: string;
}

const LineGroupModal = ({ visible, onClose, lineGroup, className }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);

  const [formData, setFormData] = useState<IFormData>({
    farm_id: lineGroup?.farm.id ?? null,
    name: lineGroup?.name ?? '',
    description: lineGroup?.description,
  });
  const [disabled, setDisabled] = useState(false);

  const updateForm = (key: keyof IFormData, value: any) =>
    setFormData(prv => ({ ...prv, [key]: value }));

  const confirmClick = async () => {
    if (!formData.farm_id) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          message: translate(lang, 'Please select a farm'),
          type: 'error',
        }),
      );
      return;
    }
    if (!formData.name) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          message: translate(lang, 'Please input line group name'),
          type: 'error',
        }),
      );
      return;
    }
    setDisabled(true);
    const response = lineGroup
      ? await sendSingleRequest(
          formData,
          'PUT',
          `api/line-group/${lineGroup.id}`,
          true,
        )
      : await sendSingleRequest(formData, 'POST', 'api/line-group', true);
    setDisabled(false);
    if (response.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(
            lang,
            lineGroup ? 'Updated successfully' : 'Created successfully',
          ),
          type: 'success',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(lang, response.data?.message ?? 'Error'),
          type: 'error',
        }),
      );
    }
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(
              lang,
              lineGroup ? 'Update Line Group' : 'Add New Line Group',
            )}
          </Subtitle>
        </div>
      </div>
      <div className='mb-17 mt-17'>
        <div className='mb-17'>
          <Dropdown
            label={translate(lang, 'Farm')}
            placeholder={translate(lang, 'Please select a farm')}
            value={formData.farm_id?.toString()}
            options={farmsData.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            onChange={v => updateForm('farm_id', Number(v))}
          />
        </div>
        <div className='mb-17'>
          <Input
            type='text'
            label={translate(lang, 'Name')}
            value={formData.name}
            onChange={e => updateForm('name', e.target.value)}
          />
        </div>
        <div className='mb-17'>
          <Input
            type='text'
            label={translate(lang, 'Description')}
            value={formData.description ?? ''}
            onChange={e =>
              updateForm(
                'description',
                e.target.value.length <= 0 ? null : e.target.value,
              )
            }
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='red'
          className='rsp-btn ml-16'
          onClick={onClose}
          disabled={disabled}
        >
          {translate(lang, 'Close')}
        </Button>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default LineGroupModal;
