import { FC, useEffect, useState } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../store/auth/auth.actions';
import { selectToken } from '../store/auth/auth.selector';

const unAuthRoutes = [
  '/sign-in',
  '/sign-up',
  '/recover-password/',
  '/message-registration',
  '/message-password',
  '/onboarding',
];

const ProtectedRoute: FC<{
  component: FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact }) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const token = useSelector(selectToken);
  const query = new URLSearchParams(useLocation().search);
  const [condition, setCondition] = useState<boolean>();

  const checkLogin = async () => {
    if (!!token) {
      setCondition(true);
    } else if (localStorage.getItem('marine-farm-refresh') !== null) {
      setCondition(true);
    } else {
      setCondition(false);
    }
  };

  useEffect(() => {
    const backUrl =
      window.location.pathname === '/' ||
      unAuthRoutes.some(x => window.location.href.includes(x))
        ? ''
        : window.location.href;

    if (
      (query.get('email') && query.get('token')) ||
      path.includes('checked') ||
      path.includes('recover-password/password')
    ) {
      dispatch(logOut());
      setCondition(false);
    } else {
      checkLogin();
    }
    if (!unAuthRoutes.some(x => history.location.pathname.includes(x))) {
      history.replace(
        backUrl
          ? `/sign-in?redirect=${encodeURIComponent(backUrl)}`
          : '/sign-in',
      );
    }
  }, [component, history.location.pathname]);

  return (
    <>
      {condition === true && <Redirect to='/' />}
      {condition === false && (
        <Route path={path} exact={exact} component={component} />
      )}
    </>
  );
};
export default ProtectedRoute;
