import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
} from '../../components/shared';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { selectUtils } from '../../store/utils/utils.selector';
import moment from 'moment';
import { downloadPDF } from '../../apis';
import {
  selectMusselFarms,
  selectOysterFarms,
} from '../../store/farms/farms.selector';
import { IHarvestResource } from '../../entities/growing.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IField {
  key: string;
  type: 'select' | 'bool' | 'string' | 'date';
  label: string;
}

const musselFields: IField[] = [
    {
      key: 'season',
      type: 'select',
      label: 'Season',
    },
    {
      key: 'complete_date',
      type: 'date',
      label: 'Date of harvest',
    },
    {
      key: 'is_full',
      type: 'bool',
      label: 'Is Full ?',
    },
    {
      key: 'is_final',
      type: 'bool',
      label: 'Is Final ?',
    },
    {
      key: 'amount',
      type: 'string',
      label: 'Amount (kg)',
    },
    {
      key: 'bags_quantity',
      type: 'string',
      label: 'Number of bags',
    },
    {
      key: 'id',
      type: 'string',
      label: 'I DECLARE THAT THE FOLLOWING WAS HARVESTED:',
    },
    {
      key: 'tag_color',
      type: 'string',
      label: 'Tag color',
    },
    {
      key: 'port_of_unload',
      type: 'select',
      label: 'Port of unload',
    },
    {
      key: 'crop_owner',
      type: 'string',
      label: 'Crop owner',
    },
    {
      key: 'farm_name',
      type: 'string',
      label: 'Farm name',
    },
    {
      key: 'line_name',
      type: 'string',
      label: 'Line name',
    },
    {
      key: 'growing_area',
      type: 'select',
      label: 'Growing area',
    },
    {
      key: 'delivered_to',
      type: 'select',
      label: 'Delivered to',
    },
    {
      key: 'packhouse',
      type: 'select',
      label: 'Packhouse',
    },
    {
      key: 'shell_size',
      type: 'string',
      label: 'Shell size',
    },
    {
      key: 'shell_condition',
      type: 'string',
      label: 'Shell condition',
    },
    {
      key: 'mussel_type',
      type: 'string',
      label: 'Mussel type',
    },
    {
      key: 'mussels',
      type: 'string',
      label: 'Performance',
    },
    {
      key: 'meat_yield',
      type: 'string',
      label: 'Meat yield',
    },
    {
      key: 'blues',
      type: 'string',
      label: 'Biofouling (e.g. Blues/Oysters)',
    },
    {
      key: 'marine_waste',
      type: 'string',
      label: 'Marine waste',
    },
    {
      key: 'backbone_ok',
      type: 'bool',
      label: 'Backbone OK',
    },
    {
      key: 'backbone_replace',
      type: 'bool',
      label: 'Backbone replace',
    },
    {
      key: 'lights_ids_in_place',
      type: 'bool',
      label: 'Lights IDs in place',
    },
    {
      key: 'flotation_on_farm',
      type: 'bool',
      label: 'Flotation on farm',
    },
    {
      key: 'rope_bags_quantity',
      type: 'string',
      label: 'Number of rope bags',
    },
    {
      key: 'product_left_on_line',
      type: 'string',
      label: 'Product left on line',
    },
    {
      key: 'temperature',
      type: 'string',
      label: 'Temperature (°C)',
    },
    {
      key: 'windy',
      type: 'string',
      label: 'Windy (m/s)',
    },
    {
      key: 'harvester_name',
      type: 'string',
      label: 'Harvester name',
    },
    {
      key: 'comment',
      type: 'string',
      label: 'Comment',
    },
  ],
  oysterFields: IField[] = [
    {
      key: 'batch_no',
      type: 'string',
      label: 'Batch No',
    },
    {
      key: 'harvest_number',
      type: 'string',
      label: 'Harvest Number',
    },
    {
      key: 'growing_area',
      type: 'select',
      label: 'Growing Area',
    },
    {
      key: 'start_time',
      type: 'string',
      label: 'Start time',
    },
    {
      key: 'finish_time',
      type: 'string',
      label: 'Finish time',
    },
    {
      key: 'complete_date',
      type: 'date',
      label: 'Date of harvest',
    },
  ];

interface ViewProps {
  form: any;
  setForm: (d: any) => void;
  onNextClick: () => void;
  lastHarvest?: IHarvestResource | null;
}

const MusselFormView = ({ form, setForm, onNextClick }: ViewProps) => {
  const lang = useSelector(selectLang);
  const farms = useSelector(selectMusselFarms);
  const utils = useSelector(selectUtils);
  const options = [
    ...utils,
    ...farms.map(x => ({
      name: `${x.name} (${x.farm_number})`,
      type: 'growing_area',
    })),
  ];

  const updateForm = (key: string, val: any) => {
    setForm({ ...form, [key]: val });
  };

  useEffect(() => {
    setForm((prv: any) => ({
      ...prv,
      growing_area:
        prv.growing_area || farms.length <= 0
          ? prv.growing_area
          : `${farms[0].name} (${farms[0].farm_number})`,
    }));
  }, [farms]);

  return (
    <div className='harvest-modal'>
      {musselFields.map((field, idx) => (
        <div key={idx}>
          {field.type === 'select' ? (
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, field.label)}
                options={[
                  {
                    id: 'empty',
                    value: '',
                    label: '(Empty)',
                  },
                  ...options
                    .filter(x => x.type === field.key)
                    .map(x => ({ id: x.name, value: x.name, label: x.name })),
                ]}
                value={form[field.key] ?? undefined}
                onChange={v => updateForm(field.key, v)}
              />
            </div>
          ) : field.type === 'bool' ? (
            <div className='mb-17'>
              <CheckboxButton
                label={translate(lang, field.label)}
                checked={form[field.key] ?? undefined}
                onChange={e => updateForm(field.key, e.target.checked)}
              />
            </div>
          ) : field.type === 'date' ? (
            <div className='mb-17'>
              <Datepicker
                label={translate(lang, field.label)}
                defaultValue={
                  form[field.key]
                    ? moment(form[field.key], 'DD/MM/YYYY').toDate().getTime()
                    : undefined
                }
                onChange={e =>
                  e &&
                  updateForm(
                    field.key,
                    moment(e.toDate().getTime()).format('DD/MM/YYYY'),
                  )
                }
              />
            </div>
          ) : (
            <div className='mb-17'>
              <Input
                type='text'
                label={translate(lang, field.label)}
                value={form[field.key] ?? undefined}
                onChange={e => updateForm(field.key, e.target.value)}
              />
            </div>
          )}
        </div>
      ))}
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          color='blue'
          size={2}
          width='full'
          type='fill'
          onClick={onNextClick}
        >
          {translate(lang, 'Next')}
        </Button>
      </div>
    </div>
  );
};

const OysterFormView = ({
  form,
  setForm,
  onNextClick,
  lastHarvest,
}: ViewProps) => {
  const lang = useSelector(selectLang);
  const farms = useSelector(selectOysterFarms);
  const utils = useSelector(selectUtils);
  const options = [
    ...utils,
    ...farms.map(x => ({
      name: `${x.name} (${x.farm_number})`,
      type: 'growing_area',
    })),
  ];
  const linesOption = useMemo(() => {
    let res: Array<{
      farm_name: string;
      line_id: string;
      line_name: string;
      spat_num: string;
    }> = [];
    for (let farm of farms) {
      for (let line of farm.lines) {
        if (!line.growing_cycle || !line.growing_cycle.main_seed.spat_storage)
          continue;
        res.push({
          farm_name: farm.name,
          line_id: line.id.toString(),
          line_name: `${farm.name} - ${line.line_name}`,
          spat_num: line.growing_cycle.main_seed.spat_storage.source,
        });
      }
    }
    return res;
  }, [farms]);

  const updateForm = (key: string, val: any) => {
    setForm({ ...form, [key]: val });
  };
  const selectLines = (lines: string[]) => {
    const batch = linesOption
      .filter(x => lines.includes(x.line_id))
      .map(x => x.spat_num)
      .filter((v, i, a) => a.indexOf(v) === i)
      .join(',');
    const growingArea = linesOption
      .filter(x => lines.includes(x.line_id))
      .map(x => x.farm_name)
      .filter((v, i, a) => a.indexOf(v) === i)
      .join(',');
    setForm((prv: any) => ({
      ...prv,
      batch_no: batch,
      lines,
      growing_area: growingArea ? growingArea : prv.growing_area,
    }));
  };

  useEffect(() => {
    setForm((prv: any) => ({
      ...prv,
      growing_area:
        prv.growing_area || farms.length <= 0
          ? prv.growing_area
          : `${farms[0].name} (${farms[0].farm_number})`,
    }));
  }, [farms]);

  return (
    <div className='harvest-modal'>
      <div className='mb-17'>
        <Dropdown
          mode='multiple'
          label={translate(lang, 'Select lines')}
          options={linesOption.map(x => ({
            id: x.line_id,
            value: x.line_id,
            label: x.line_name,
          }))}
          value={form?.lines}
          onChange={(v: any) => selectLines(v)}
        />
      </div>
      {oysterFields.map((field, idx) => (
        <div key={idx}>
          {field.key === 'harvest_number' ? (
            <div className='mb-17'>
              <Input
                type='text'
                label={`Harvest number${
                  lastHarvest?.harvest_number
                    ? ` (Previous ${lastHarvest.harvest_number})`
                    : ''
                }`}
                value={form.harvest_number ?? undefined}
                onChange={e => updateForm('harvest_number', e.target.value)}
              />
            </div>
          ) : field.type === 'select' ? (
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, field.label)}
                options={[
                  {
                    id: 'empty',
                    value: '',
                    label: '(Empty)',
                  },
                  ...options
                    .filter(x => x.type === field.key)
                    .map(x => ({ id: x.name, value: x.name, label: x.name })),
                ]}
                value={form[field.key] ?? undefined}
                onChange={v => updateForm(field.key, v)}
              />
            </div>
          ) : field.type === 'bool' ? (
            <div className='mb-17'>
              <CheckboxButton
                label={translate(lang, field.label)}
                checked={form[field.key] ?? undefined}
                onChange={e => updateForm(field.key, e.target.checked)}
              />
            </div>
          ) : field.type === 'date' ? (
            <div className='mb-17'>
              <Datepicker
                label={translate(lang, field.label)}
                defaultValue={
                  form[field.key]
                    ? moment(form[field.key], 'DD/MM/YYYY').toDate().getTime()
                    : undefined
                }
                onChange={e =>
                  e &&
                  updateForm(
                    field.key,
                    moment(e.toDate().getTime()).format('DD/MM/YYYY'),
                  )
                }
              />
            </div>
          ) : (
            <div className='mb-17'>
              <Input
                type='text'
                label={field.label}
                value={form[field.key] ?? undefined}
                onChange={e => updateForm(field.key, e.target.value)}
              />
            </div>
          )}
        </div>
      ))}
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          color='blue'
          size={2}
          width='full'
          type='fill'
          onClick={onNextClick}
        >
          {translate(lang, 'Next')}
        </Button>
      </div>
    </div>
  );
};

const openPDF = (file: any) => {
  if (!file) return;
  const blob = new Blob([file], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  window.open(url, '_blank');
};

interface Props {
  lastHarvest?: IHarvestResource | null;
}

const HarvestPDFCodeButton = ({ lastHarvest }: Props) => {
  const lang = useSelector(selectLang);

  const [opened, setOpened] = useState(false);
  const [type, setType] = useState<'mussel' | 'oyster'>();
  const [form, setForm] = useState<any>({});
  const [paperQuantity, setPaperQuantity] = useState(1);
  const [showFinal, setShowFinal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const title =
    type === 'mussel'
      ? 'Mussel harvest PDF form'
      : type === 'oyster'
      ? 'Oyster harvest PDF form'
      : 'What type of PDF form do you want to print?';

  const modalClose = () => {
    setOpened(false);
    setType(undefined);
    setPaperQuantity(1);
    setForm({});
    setShowFinal(false);
    setDisabled(false);
  };
  const confirmClick = async () => {
    if (!type || !paperQuantity || paperQuantity <= 0) return;

    setDisabled(true);
    const res = await downloadPDF(
      { ...form, type, paper_quantity: paperQuantity },
      'POST',
      'api/harvest-qr-form',
    );
    setDisabled(false);
    if (res.data) {
      openPDF(res.data);
      modalClose();
    } else {
      window.alert(res.data?.message ?? 'Failed to print the form');
    }
  };

  return (
    <div>
      <Button
        color='green'
        size={3}
        width='middle'
        type='fill'
        onClick={() => setOpened(true)}
      >
        {translate(lang, 'Print Empty Declaration Forms')}
      </Button>
      {opened && (
        <Modal
          visible={true}
          onCancel={modalClose}
          footer={null}
          closable
          closeIcon={<CloseIcon />}
        >
          <div className='wrap'>
            <div className='d-flex align-items-center mb-32'>
              <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                {title}
              </Subtitle>
            </div>
            {showFinal ? (
              <div className='mt-32 mb-16'>
                <Input
                  type='number'
                  label='How many copies do you want to print?'
                  value={paperQuantity.toString()}
                  onChange={e => setPaperQuantity(Number(e.target.value))}
                />
                <div className='modal-button d-flex justify-content-end mt-32'>
                  <Button
                    width={'small'}
                    size={2}
                    type='fill'
                    color='green'
                    className='rsp-btn ml-16'
                    onClick={confirmClick}
                    disabled={disabled}
                  >
                    {translate(lang, 'Confirm')}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                {type === 'mussel' ? (
                  <MusselFormView
                    form={form}
                    setForm={setForm}
                    onNextClick={() => setShowFinal(true)}
                  />
                ) : type === 'oyster' ? (
                  <OysterFormView
                    form={form}
                    setForm={setForm}
                    onNextClick={() => setShowFinal(true)}
                    lastHarvest={lastHarvest}
                  />
                ) : (
                  <div className='harvest-modal'>
                    <div className='mt-32 mb-32 text-center'>
                      <Button
                        color='blue'
                        size={1}
                        width='full'
                        type='fill'
                        onClick={() => setType('mussel')}
                      >
                        {translate(lang, 'Mussel harvest PDF form')}
                      </Button>
                    </div>
                    <div className='mb-32 text-center'>
                      <Button
                        color='blue'
                        size={1}
                        width='full'
                        type='fill'
                        onClick={() => setType('oyster')}
                      >
                        {translate(lang, 'Oyster harvest PDF form')}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HarvestPDFCodeButton;
