import moment from 'moment';

const toggleSecondMillisecond = (date: string | number): number => {
  if (date) {
    if (date.toString().length <= 10) {
      const millisecond = (Number(date) * 1000).toFixed();
      return Number(millisecond);
    }

    if (date.toString().length === 13) {
      const second = (Number(date) / 1000).toFixed();
      return Number(second);
    }
  }

  return moment().toDate().getTime();
};

export default toggleSecondMillisecond;

export const defaultDateFormat = (
  date: string | number | null | undefined,
  eChar: string | null = null,
): string | null => {
  if (date) {
    let ms = date;
    if (date.toString().length === 10) ms = (Number(date) * 1000).toFixed();
    return moment(Number(ms)).format('DD.MM.YYYY');
  } else {
    return eChar;
  }
};

export const diffDays = (
  from: number | null | undefined,
  to = moment().toDate().getTime(),
) => {
  if (!from) return '-';
  if (from.toString().length <= 10) from = 1000 * from;
  if (to.toString().length <= 10) to = 1000 * to;

  return `${moment(to).diff(from, 'days')} days`;
};

export const toMillisecond = (
  date: string | number | undefined | null,
): number => {
  if (date) {
    if (date.toString().length <= 10) {
      const millisecond = (Number(date) * 1000).toFixed();
      return Number(millisecond);
    } else {
      return Number(date);
    }
  }
  return moment().toDate().getTime();
};

export const toSecond = (date: string | number | null | undefined): number => {
  if (date) {
    if (date.toString().length <= 10) {
      return Number(date);
    } else {
      return Math.round(Number(date) / 1000);
    }
  }
  return Math.round(moment().toDate().getTime() / 1000);
};
