import {
  IMusselLineDetail,
  IOysterLineDetail,
  ISeaweedLineDetail,
} from '../../entities/farms.entities';
import { TBusinessType } from '../../entities/general.entities';
import {
  IMusselCycle,
  IOysterCycle,
  ISeaweedCycle,
} from '../../entities/growing.entities';
import MusselLineDesktop from './MusselLineDesktop';
import OysterLineDesktop from './OysterLineDesktop';
import SeaweedLineDesktop from './SeaweedLineDesktop';

interface Props {
  type: TBusinessType;
  lineData: IMusselLineDetail | IOysterLineDetail | ISeaweedLineDetail;
  loadLine: () => void; // only for MUSSEL
  cyclePart: IMusselCycle | IOysterCycle | ISeaweedCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup: () => void;
  onClickNextGroup: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onAssessmentClick: () => void;
  onCatchSpatClick: () => void;
  onHarvestClick: () => void;
  onMaintenanceClick: () => void;
  onFloatingManageClick: () => void; // only for MUSSEL
  onExtraSeedClick: () => void;
  checkedHistoryView: number; // only for MUSSEL
  onHistoryViewClick: () => void; // only for MUSSEL
}

const LineTemplateDesktop = ({
  type,
  lineData,
  loadLine,
  cyclePart,
  isPrevEnabled,
  isNextEnabled,
  onClickPrevGroup,
  onClickNextGroup,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onAssessmentClick,
  onCatchSpatClick,
  onHarvestClick,
  onMaintenanceClick,
  onFloatingManageClick,
  onExtraSeedClick,
  checkedHistoryView,
  onHistoryViewClick,
}: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselLineDesktop
        lineData={lineData as IMusselLineDetail}
        loadLine={loadLine}
        cyclePart={cyclePart as IMusselCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onFloatingManageClick={onFloatingManageClick}
        onExtraSeedClick={onExtraSeedClick}
        checkedHistoryView={checkedHistoryView}
        onHistoryViewClick={onHistoryViewClick}
      />
    ) : type === 'OYSTER' ? (
      <OysterLineDesktop
        lineData={lineData as IOysterLineDetail}
        cyclePart={cyclePart as IOysterCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onExtraSeedClick={onExtraSeedClick}
        onFloatingManageClick={onFloatingManageClick}
        checkedHistoryView={checkedHistoryView}
        onHistoryViewClick={onHistoryViewClick}
        loadLine={loadLine}
      />
    ) : (
      <SeaweedLineDesktop
        lineData={lineData as ISeaweedLineDetail}
        cyclePart={cyclePart as ISeaweedCycle}
        isPrevEnabled={isPrevEnabled}
        isNextEnabled={isNextEnabled}
        onClickPrevGroup={onClickPrevGroup}
        onClickNextGroup={onClickNextGroup}
        showEditLineModal={showEditLineModal}
        showEditSeedModal={showEditSeedModal}
        onSeedingClick={onSeedingClick}
        onAssessmentClick={onAssessmentClick}
        onCatchSpatClick={onCatchSpatClick}
        onHarvestClick={onHarvestClick}
        onMaintenanceClick={onMaintenanceClick}
        onExtraSeedClick={onExtraSeedClick}
        loadLine={loadLine}
      />
    )}
  </>
);

export default LineTemplateDesktop;
