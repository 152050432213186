import { useMemo } from 'react';
import { Subtitle } from '../../components/shared';
import { IFarmProperty, ILineProperty } from '../../entities/farms.entities';
import { IMusselHarvest } from '../../entities/growing.entities';
import { formatNumber } from '../../entities/util-functions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { amountDays } from '../../lib/farm.helpers';
import { labelNumber } from '../../lib/common.helpers';

interface Props {
  farm: IFarmProperty;
  line: ILineProperty;
  harvests: IMusselHarvest[];
  harvestID: number;
  firstStageLength: number | null;
  prevStageLength: number | null;
  lastStageLength: number | null;
  stageAmountSum: number;
  stageLengthSum: number;
  grownDays: number;
}

const MusselHarvestHeader = ({
  farm,
  line,
  harvests,
  harvestID,
  firstStageLength,
  prevStageLength,
  lastStageLength,
  stageAmountSum,
  stageLengthSum,
  grownDays,
}: Props) => {
  const lang = useSelector(selectLang);

  const { firstRatio, lastRatio } = useMemo(() => {
    const fr =
      firstStageLength === null || lastStageLength === null
        ? null
        : lastStageLength / firstStageLength;
    const lr =
      prevStageLength === null || lastStageLength === null
        ? null
        : lastStageLength / prevStageLength;
    return {
      firstRatio:
        fr === null ? (
          '- : -'
        ) : (
          <>
            <span className='mr-12'>{`1 : ${formatNumber(fr)}`}</span>
            <span>{`${firstStageLength}m : ${lastStageLength}m`}</span>
          </>
        ),
      lastRatio:
        lr === null ? (
          '- : -'
        ) : (
          <>
            <span className='mr-12'>{`1 : ${formatNumber(lr)}`}</span>
            <span>{`${prevStageLength}m : ${lastStageLength}m`}</span>
          </>
        ),
    };
  }, [firstStageLength, prevStageLength, lastStageLength]);
  const harvest = harvests.find(x => x.id === harvestID);

  return (
    <div className='d-flex justify-content-between white-card pt-28 pr-16 pb-28 pl-24 mb-16 overflow-auto'>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.name}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Line name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {line.line_name}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm number')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.farm_number}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Harvested Line Length')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {labelNumber(stageLengthSum, 'm', '-')}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm area')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {labelNumber(
            farm.area.length <= 0 ? undefined : Number(farm.area),
            'ha',
            '-',
          )}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Backbone')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {labelNumber(line.backbone ?? undefined, 'm', '-')}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'This harvest amount')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${formatNumber(harvest?.amount ?? 0)} kg`}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Total harvested this stage')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${formatNumber(stageAmountSum)} kg`}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'First seed ratio')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {firstRatio}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Last seed ratio')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {lastRatio}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Age of growth')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {amountDays(grownDays)}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Mussels per meters of line this stage')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {(stageAmountSum / stageLengthSum).toFixed(2)} kg/m
        </Subtitle>
      </div>
    </div>
  );
};

export default MusselHarvestHeader;
