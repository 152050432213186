import { RootState, IThunkType } from '../rootReducer';
import { IAutomationAction } from './automation.type';
import { sendSingleRequest } from '../../apis';

const setAutomation = (auto: IAutomationAction): IAutomationAction => auto;

let loadingAutomations = false;

export const loadAutomationData = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingAutomations) return;
    loadingAutomations = true;

    const response = await sendSingleRequest(
      {},
      'GET',
      'api/automation/automations',
      true,
    );
    loadingAutomations = false;
    if (response.status) {
      dispatch(
        setAutomation({
          type: 'AUTOMATIONS/SET_AUTOMATIONS_DATA',
          payload: response.data,
        }),
      );
      return true;
    } else {
      return response.data?.message ?? 'Failed to load';
    }
  };
};

export const removeAutomation = (automationId: number) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/automation/automations/${automationId}`,
      true,
    );
    if (response.status) {
      await dispatch(loadAutomationData());

      return true;
    } else {
      return response.data?.message ?? 'Failed to delete';
    }
  };
};

let loadingCustomFields = false;

export const loadCustomFields = () => {
  return async (dispatch: IThunkType) => {
    if (loadingCustomFields) return;
    loadingCustomFields = true;

    const response = await sendSingleRequest(
      {},
      'GET',
      'api/custom-field',
      true,
    );
    loadingCustomFields = false;

    if (response.status) {
      dispatch(
        setAutomation({
          type: 'AUTOMATIONS/SET_CUSTOM_FIELDS',
          payload: response.data,
        }),
      );
      return true;
    } else {
      return response.data?.message ?? 'Unknown error';
    }
  };
};

let loadingTags = false;

export const loadTags = () => {
  return async (dispatch: IThunkType) => {
    if (loadingTags) return;
    loadingTags = true;

    const response = await sendSingleRequest({}, 'GET', 'api/tag', true);
    loadingTags = false;

    if (response.status) {
      dispatch(
        setAutomation({ type: 'AUTOMATIONS/SET_TAGS', payload: response.data }),
      );
      return true;
    } else {
      return response.data?.message ?? 'Unknown error';
    }
  };
};
