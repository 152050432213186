import {
  IInventorySum,
  IPendingInventory,
} from '../entities/inventory.entities';

export const LineInventoriesLabel = (inventories: IPendingInventory[]) =>
  inventories.map(x => (
    <span key={`ivt-${x.id}`}>
      <span>
        {x.name} - {x.pending_quantity}
      </span>
      <br />
    </span>
  ));

export const SeedInventoriesLabel = (ivt_seeds: IInventorySum[]) =>
  ivt_seeds.map(x => (
    <span key={`seed-${x.inventory_id}`}>
      <span>
        {x.inventory_name} - {x.quantity}
      </span>
      <br />
    </span>
  ));
