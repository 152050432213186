import { FC } from 'react';
import { Modal } from 'antd';
import { CloseIcon, Map, Subtitle } from '../shared';
import arrow_png from '../../images/right-arrow.png';
import { formatNumber } from '../../entities/util-functions';
import { IWeatherConditionResource } from '../../entities/general.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const oneKnot = 1.94384;

const WindyIcon: FC<{
  wu: string | null;
  wv: string | null;
}> = ({ wu, wv }) => {
  const x = Number(wu),
    y = Number(wv);
  const s = Math.sqrt(x * x + y * y),
    a = (-Math.atan2(y, x) * 180) / Math.PI;

  return s < 1e-9 ? (
    <div>{`${(s * oneKnot).toFixed(2)} knots`}</div>
  ) : (
    <div className='d-flex align-items-center'>
      <div>{`${(s * oneKnot).toFixed(2)} knots`}</div>
      <div className='ml-7 wind-icon'>
        <img src={arrow_png} style={{ transform: `rotate(${a}deg)` }} />
      </div>
    </div>
  );
};

interface Props {
  data: IWeatherConditionResource;
  onClose: () => void;
  title?: string;
  visible: boolean;
  className?: string;
}

const WeatherInfoModal = ({
  data,
  onClose,
  title,
  visible,
  className,
}: Props) => {
  const lang = useSelector(selectLang);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? '')}
          </Subtitle>
        </div>
        <div className='pt-16'>
          <div className='d-flex pb-23'>
            <div className='flex-basis-50'>
              <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
                {translate(lang, 'Location Latitude')}
              </Subtitle>
              <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
                {data.latitude} °
              </Subtitle>
            </div>
            <div className='flex-basis-50 ml-24'>
              <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
                {translate(lang, 'Location Longitude')}
              </Subtitle>
              <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
                {data.longitude} °
              </Subtitle>
            </div>
          </div>
          <div className='d-flex pb-23'>
            <div className='flex-basis-50'>
              <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
                {translate(lang, 'Sunset Time')}
              </Subtitle>
              <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
                {data.sunset_time}
              </Subtitle>
            </div>
            <div className='flex-basis-50 ml-24'>
              <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
                {translate(lang, 'Temperature')}
              </Subtitle>
              <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
                {Number(data.temperature).toFixed(1)} °C
              </Subtitle>
            </div>
          </div>
          <div className='d-flex pb-23'>
            <div className='flex-basis-50'>
              <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
                {translate(lang, 'Windy Speed')}
              </Subtitle>
              <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
                <WindyIcon wu={data.wind_u} wv={data.wind_v} />
              </Subtitle>
            </div>
            <div className='flex-basis-50 ml-24'>
              <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
                {translate(lang, 'Precip')}
              </Subtitle>
              <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
                {Number(data.precip).toFixed(1)} mm
              </Subtitle>
            </div>
          </div>
          {!!data.pressure && (
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Pressure')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {data.pressure
                    ? `${formatNumber(data.pressure / 1000)} KPa`
                    : ''}
                </Subtitle>
              </div>
              <div className='flex-basis-50'></div>
            </div>
          )}
          {data.latitude && data.longitude && (
            <div>
              <Subtitle color='black-1' align='left' size={3} fontWeight={600}>
                {translate(lang, 'The data entry location')}
              </Subtitle>
              <div style={{ height: '300px' }}>
                <Map
                  zoom={10}
                  position={{
                    lat: Number(data.latitude),
                    lng: Number(data.longitude),
                  }}
                  defaultPosition={{
                    lat: Number(data.latitude),
                    lng: Number(data.longitude),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WeatherInfoModal;
