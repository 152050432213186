import { useCallback, useEffect, useState } from 'react';
import { Spinner, Title } from '../../components/shared';
import { sendSingleRequest } from '../../apis';
import { useParams } from 'react-router-dom';
import NotFound from '../static/NotFound';
import PaperSeedingForm from './PaperSeedingForm';
import { useDispatch } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import PaperAssessForm from './PaperAssessForm';
import PaperFloatingForm from './PaperFloatingForm';
import PaperHarvestForm from './PaperHarvestForm';
import { IMusselLineResource } from '../../entities/farms.entities';
import './styles.scss';

interface IForm {
  id: number;
  _type:
    | 'paper_seeding'
    | 'paper_assessment'
    | 'paper_floating'
    | 'paper_harvest';
  lineData: IMusselLineResource;
  image?: string;

  [key: string]: any;
}

const PaperFormPage = () => {
  const dispatch = useDispatch<any>();
  const params = useParams<{ id: string }>();

  const [data, setData] = useState<IForm>();
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    const res = await sendSingleRequest(
      {},
      'GET',
      `api/queue/detail/${params.id}`,
      true,
    );
    setLoading(false);
    if (res.status) {
      setData(res.data);
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          message: res.data?.message ?? 'Unknown error',
          type: 'error',
        }),
      );
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className='bg-secondary min-height'>
      <div className='container'>
        <div className='queued-page-header d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {`Paper ${data?._type.slice(6) ?? ''} form`}
          </Title>
        </div>
        {loading ? (
          <Spinner position='local' />
        ) : data ? (
          <div className='paper-form-page'>
            <div className='paper-form'>
              {data._type === 'paper_seeding' ? (
                <PaperSeedingForm
                  formID={data.id}
                  lineData={data.lineData}
                  data={data}
                />
              ) : data._type === 'paper_assessment' ? (
                <PaperAssessForm
                  formID={data.id}
                  lineData={data.lineData}
                  data={data}
                />
              ) : data._type === 'paper_floating' ? (
                <PaperFloatingForm
                  formID={data.id}
                  lineData={data.lineData}
                  data={data}
                />
              ) : data._type === 'paper_harvest' ? (
                <PaperHarvestForm
                  formID={data.id}
                  lineData={data.lineData}
                  data={data}
                />
              ) : null}
            </div>
            <div className='paper-image'>
              {data.image && (
                <img
                  src={data.image}
                  alt='paper form'
                  style={{ width: '100%' }}
                />
              )}
            </div>
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};

export default PaperFormPage;
