import { hashColor } from '../../entities/util-functions';
import { CheckIcon, PenIcon, TrashIcon } from '../../components/shared';
import { ITaskData } from '../../entities/task.entities';
import boat_png from '../../images/boat.png';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  task: ITaskData;
  onShowTask: (t: ITaskData) => void;
  onCompleteTask: (t: ITaskData) => void;
  onEditTask: (t: ITaskData) => void;
  onDeleteTask: (t: ITaskData) => void;
}

const TaskCell = ({
  task,
  onShowTask,
  onCompleteTask,
  onEditTask,
  onDeleteTask,
}: Props) => {
  const lang = useSelector(selectLang);

  const handleCompleteClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onCompleteTask(task);
  };
  const handleEditClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onEditTask(task);
  };
  const handleShowClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onShowTask(task);
  };
  const handleDeleteClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onDeleteTask(task);
  };

  return (
    <div
      className={`--entity --task ${task.is_completed ? '--completed' : ''}`}
      onClick={handleShowClick.bind(this)}
    >
      <div className='--header'>
        <span>{translate(lang, 'task')}</span>
      </div>
      <div className='--title --task-title'>{task.title}</div>
      <div className='d-flex justify-content-between mt-7'>
        {task.boat ? (
          <div
            className='--boat-img'
            style={{
              backgroundColor: hashColor(task.boat.reg_number),
            }}
          >
            <img src={boat_png} alt='boat' />
          </div>
        ) : (
          <div></div>
        )}
        <div className='d-flex'>
          {!task.is_completed && (
            <button
              className='complete-btn'
              onClick={handleCompleteClick.bind(this)}
            >
              <CheckIcon />
            </button>
          )}
          <button className='edit-btn' onClick={handleEditClick.bind(this)}>
            <PenIcon />
          </button>
          <button className='delete-btn' onClick={handleDeleteClick.bind(this)}>
            <TrashIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskCell;
