import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import { Button, CloseIcon, Input, Subtitle } from '../../shared';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { selectOysterFarms } from '../../../store/farms/farms.selector';
import { ISeaweedSeeding } from '../../../entities/growing.entities';
import { translate } from '../../../lib/lang.helper';
import { selectLang } from '../../../store/ui/ui.selector';

interface Props {
  visible: boolean;
  seeding: ISeaweedSeeding;
  onClose: () => void;
}

const SeaweedSeedViewModal = ({ visible, seeding, onClose }: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectOysterFarms);
  const farm = farmsData.find(x => x.id === seeding.line.farm_id);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap view-modal'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Seeding Details')}:
          </Subtitle>
          <Subtitle
            className='ml-7'
            color='black-3'
            align='left'
            size={1}
            fontWeight={500}
          >
            {`${farm?.name} - ${seeding.line.line_name}`}
          </Subtitle>
        </div>
        <div className='pt-16 pb-24'>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Season')}</div>
              <div className='info-value'>{seeding.season_name}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Seeded tank area')}
              </div>
              <div className='info-value'>{`${seeding.tank_area} m²`}</div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>
                {translate(lang, 'Line seeding date')}
              </div>
              <div className='info-value'>
                {defaultDateFormat(seeding.planned_date_seed)}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Planned harvest date')}
              </div>
              <div className='info-value'>
                {defaultDateFormat(seeding.planned_date_harvest)}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Spat source')}</div>
              <div className='info-value'>
                {!!seeding.is_catch_spat
                  ? 'Catch Spat'
                  : seeding.spat_storage
                  ? `${seeding.spat_storage.source} - ${seeding.spat_amount} kg`
                  : '-'}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>{translate(lang, 'Spat size')}</div>
              <div className='info-value'>
                {!seeding.spat_size_max
                  ? `${seeding.spat_size} mm`
                  : `${seeding.spat_size} - ${seeding.spat_size_max} mm`}
              </div>
            </div>
          </div>
          <div className='mt-7 mb-7'>
            <Input
              label={translate(lang, 'Comment')}
              type='textarea'
              value={seeding.comment ?? ''}
              disabled={true}
            />
          </div>
          {!!seeding.images && seeding.images.length > 0 && (
            <div className='mt-7 mb-7'>
              <PicturesWall
                label={translate(lang, 'Images')}
                fileList={
                  seeding.images.map(x => parseImageUrlToFileItem(x)) as any
                }
                handleChangeImages={() => {}}
                disabled={true}
              />
            </div>
          )}
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SeaweedSeedViewModal;
