import { useSelector } from 'react-redux';
import { selectTasks } from '../../store/tasks/tasks.selector';
import { useState } from 'react';
import { ITaskResource } from '../../entities/task.entities';
import ModalTask from '../../components/todo/ModalTask';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

interface RowProps {
  tasks: ITaskResource[];
  label: string;
  color: string;
  onTaskClick: (t: ITaskResource) => void;
  onAddClick: () => void;
  lang: TLang | undefined;
}

const Row = ({
  tasks,
  label,
  color,
  onTaskClick,
  onAddClick,
  lang,
}: RowProps) => (
  <div style={{ flex: 1 }}>
    <div className='mb-24' style={{ borderBottom: `2px solid ${color}` }}>
      {`${label} | ${tasks.length}`}
    </div>
    <div className='mt-16'>
      {tasks.map(task => (
        <div
          key={task.id}
          className={`task-button${
            task.is_completed ? ' task-button-completed' : ''
          }`}
          onClick={() => onTaskClick(task)}
        >
          {task.title}
        </div>
      ))}
      <button className='--add-task' onClick={onAddClick}>
        {translate(lang, 'Add task')}
      </button>
    </div>
  </div>
);

const todayDate = new Date().getDate();
const todayTimestamp = new Date().getTime();

const CalendarView = () => {
  const lang = useSelector(selectLang);
  const tasks: ITaskResource[] = useSelector(selectTasks) as any;
  const yesterday = tasks
    ?.filter(x => new Date(x.due_date).getDate() === todayDate - 1)
    .slice(0, 5);
  const today = tasks
    ?.filter(x => new Date(x.due_date).getDate() === todayDate)
    .slice(0, 5);
  const tomorrow = tasks
    ?.filter(x => new Date(x.due_date).getDate() === todayDate + 1)
    .slice(0, 5);

  const [newTaskDate, setNewTaskDate] = useState<number>();
  const [viewTask, setViewTask] = useState<ITaskResource>();

  return (
    <div className='mb-32'>
      <div style={{ display: 'flex' }}>
        <Row
          tasks={yesterday}
          label={translate(lang, 'Yesterday')}
          color='black'
          onTaskClick={t => setViewTask(t)}
          onAddClick={() => setNewTaskDate(todayTimestamp - 86400000)}
          lang={lang}
        />
        <div className='mr-17 ml-17' style={{ flex: 1 }}>
          <Row
            tasks={today}
            label={translate(lang, 'Today')}
            color='#2d9bf0'
            onTaskClick={t => setViewTask(t)}
            onAddClick={() => setNewTaskDate(todayTimestamp)}
            lang={lang}
          />
        </div>
        <Row
          tasks={tomorrow}
          label={translate(lang, 'Tomorrow')}
          color='#8fd14f'
          onTaskClick={t => setViewTask(t)}
          onAddClick={() => setNewTaskDate(todayTimestamp + 86400000)}
          lang={lang}
        />
        {!!newTaskDate && (
          <ModalTask
            visible={true}
            title='Create task'
            onCancel={() => setNewTaskDate(undefined)}
            data={{ due_date: newTaskDate }}
            onConfirm={() => setNewTaskDate(undefined)}
          />
        )}
        {!!viewTask && (
          <ModalTask
            visible={true}
            title='View task'
            data={viewTask}
            viewOnly
            onConfirm={() => setViewTask(undefined)}
            onCancel={() => setViewTask(undefined)}
          />
        )}
      </div>
      <Link className='mt-16' to='/calendar'>
        <Button type='primary'>{translate(lang, 'View Calendar')}</Button>
      </Link>
    </div>
  );
};

export default CalendarView;
