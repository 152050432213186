import { useState, useMemo, useEffect } from 'react';
import { Modal } from 'antd';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
} from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';
import FluspySectionsModal from '../../components/view-modals/FluspySectionsModal';
import { parse } from 'path';

const basketsPerSectionsOptions = [
  { id: '4', label: '4', value: '4' },
  { id: '6', label: '6', value: '6' },
  { id: '8', label: '8', value: '8' },
]

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const FluspyModal = ({
  visible,
  onCancel,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const [basketketsPerSection, setBasketketsPerSection] = useState(0);
  const [backetWidth, setBacketWidth] = useState(0);
  const [backetHeight, setBacketHeight] = useState(0);
  const [showFluspySectionsModal, setShowFluspySectionsModal] = useState(false);
  const [errors, setErrors] = useState<{
    source: string | null;
    condition: string | null;
    weight: string | null;
  }>({ source: null, condition: null, weight: null });
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(
              lang,
              'Bay Spat Storage',
            )}
          </Subtitle>
        </div>
        <div className='spat-storage-modal'>
            <div className={`mb-17 ${errors.condition ? 'invalid-form' : ''}`}>
              <p>
                <b>Section</b> is a section of your flupsy containint multiple bucket. How many buckets you have per section?
              </p>
              <Dropdown
                onChange={v => setBasketketsPerSection(parseInt(v))}
                label={translate(lang, 'Select buckets')}
                options={basketsPerSectionsOptions}
                value={basketketsPerSection.toString()}
              />
              {errors.condition && (
                <div className='invalid-feedback'>{errors.condition}</div>
              )}
            </div>
            <div className={`mb-17`}>
              <p>
                How big is your raft in terms of sections where X is height and Y is length. For instance 4x by 4y would give you 16 sections (remember each section also has multiple baskets)
              </p>
            </div>
            <div className={`mb-17 ${errors.weight ? 'invalid-form' : ''}`}>
              <Input
                type='number'
                label={translate(
                  lang,
                  'Height',
                )}
                value={backetHeight.toString() ?? ''}
                onChange={e => setBacketHeight(parseInt(e.target.value))}
              />
              {errors.weight && (
                <div className='invalid-feedback'>{errors.weight}</div>
              )}
            </div>
            <div className={`mb-17 ${errors.weight ? 'invalid-form' : ''}`}>
              <Input
                type='number'
                label={translate(
                  lang,
                  'Length',
                )}
                value={backetWidth.toString() ?? ''}
                onChange={e => setBacketWidth(parseInt(e.target.value))}
              />
              {errors.weight && (
                <div className='invalid-feedback'>{errors.weight}</div>
              )}
            </div>
            <button
              className='btn btn-primary'
              onClick={() => setShowFluspySectionsModal(true)}
            >
              {translate(lang, 'Next')}
            </button>
          </div>
      </div>
      {showFluspySectionsModal && (
        <FluspySectionsModal
          bucketsX={backetWidth}
          bucketsY={backetHeight}
          divisions={basketketsPerSection}
          onCancel={() => setShowFluspySectionsModal(false)}
        />  
      )}
    </Modal>
  );
};

export default FluspyModal;
