import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CheckboxButton,
  Dropdown,
  ModalComponent,
  PlusIcon,
  Search,
  SearchBigIcon,
  SortIcon,
  Spinner,
  TagComponent,
  Title,
} from '../../components/shared';
import { getAllUsers } from '../../store/users/users.actions';
import { useWidth } from '../../util/useWidth';
import { Modal, Table, Button as AntBtn } from 'antd';
import { sendSingleRequest } from '../../apis';
import UserPermissionsModal from '../../components/users/UserPermissionsModal';
import { IUserResource } from '../../entities/user.entities';
import UpdateUserModal from '../../components/users/UpdateUserModal';
import InvitedUsers from './InvitedUsers';
import { checkRolePermission } from '../../entities/util-functions';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectUsers } from '../../store/users/users.selector';
import { QRCode } from 'react-qrcode-logo';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

interface IQRToken {
  id: number;
  token: string;
}

const QRImageModal: FC<{
  qrCode: IQRToken;
  onClose: () => void;
  lang: TLang | undefined;
}> = ({ qrCode, onClose, lang }) => {
  const copyQRImage = () => {
    const canvas: any = document.getElementById('login-qr-canvas');
    canvas?.toBlob(
      async (blob: any) => {
        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        } catch (error) {
          window.alert(error);
        }
      },
      'image/png',
      1,
    );
  };

  return (
    <Modal
      title={`Login QR Code`}
      onCancel={onClose}
      centered={true}
      footer={null}
      visible={true}
      width={380}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <QRCode
          id='login-qr-canvas'
          value={JSON.stringify(qrCode)}
          size={300}
        />
        <div style={{ marginTop: 20 }}>
          <Button
            size={3}
            type='fill'
            color='green'
            width='large'
            onClick={copyQRImage}
          >
            {translate(lang, 'Copy QR Image')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ViewQRButton = ({ onClick }: { onClick: () => void }) => {
  const [disabled, setDisabled] = useState(false);

  const handleClick = async () => {
    setDisabled(true);
    await onClick();
    setDisabled(false);
  };

  return (
    <AntBtn
      color='green'
      type='primary'
      size='small'
      disabled={disabled}
      onClick={handleClick}
    >
      View Login
    </AntBtn>
  );
};

const defaultColumns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    key: 'status',
    className: 'user-status',
    render: (x: IUserResource) => {
      return (
        <div>
          <TagComponent
            color={
              x.status === 'active'
                ? 'green'
                : x.status === 'pending'
                ? 'orange'
                : 'gray'
            }
          >
            {x.status}
          </TagComponent>
        </div>
      );
    },
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (x: any) => <span>{roles.find(y => y.id === x)?.label}</span>,
  },
];

const roles = [
  { value: '3', label: 'All', id: '' },
  { value: '0', label: 'Admin', id: 'admin' },
  { value: '1', label: 'User', id: 'user' },
  { value: '2', label: 'Owner', id: 'owner' },
  { value: '4', label: 'Processing plant', id: 'processing_plant' },
];

const Users = () => {
  const dispatch = useDispatch<any>();
  const width = useWidth();
  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const usersStore = useSelector(selectUsers);
  const isEditable = profile?.edit_permission;

  const [value, setValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [roleSelected, setRoleSelected] = useState();
  const [users, setUsers] = useState<IUserResource[]>([]);
  const [delUser, setDelUser] = useState<IUserResource>();
  const [closeUser, setCloseUser] = useState<IUserResource>();
  const [isSpinner, setIsSpinner] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserResource>();
  const [ownerSelUser, setOwnerSelUser] = useState<IUserResource>();
  const [userQR, setUserQR] = useState<IQRToken>();
  const [disabled, setDisabled] = useState(false);

  const handleFilter = () => {
    setIsSpinner(true);
    let defaultUser = usersStore;
    if (roleSelected) {
      defaultUser = defaultUser.filter(user => user.role === roleSelected);
    }
    if (value) {
      defaultUser = defaultUser.filter(
        user => user.email?.includes(value) || user.name?.includes(value),
      );
    }
    setUsers(defaultUser);
    setIsSpinner(false);
  };

  const handleOnRole = (event: any) => {
    setRoleSelected(event.key);
  };
  const handleOnActiveSearch = () => {
    setIsSearch(!isSearch);
    if (isSort) {
      setIsSort(false);
    }
  };
  const handleOnActiveSort = () => {
    setIsSort(!isSort);
    if (isSearch) {
      setIsSearch(false);
    }
  };
  const handleConfirmDelete = async () => {
    if (!delUser) return;
    setDisabled(true);
    const res = await sendSingleRequest(
      { user_id: delUser.id },
      'DELETE',
      'api/user/destroy-user',
      true,
    );
    if (res.status) {
      await dispatch(getAllUsers());
      setDisabled(false);
      setDelUser(undefined);
    } else {
      setDisabled(false);
      alert(res.data?.message ?? 'Failed to delete');
    }
  };
  const updateUserStatus = async () => {
    if (!closeUser) return;

    setDisabled(true);
    const res = await sendSingleRequest(
      { user_id: closeUser.id, status: closeUser.status === 'active' ? -1 : 1 },
      'PUT',
      'api/user/status',
      true,
    );
    if (res.status) {
      await dispatch(getAllUsers());
      setDisabled(false);
      setCloseUser(undefined);
    } else {
      setDisabled(false);
      alert(res.data?.message ?? 'Failed to update');
    }
  };
  const toggleUserVerified = async (
    user_id: string | undefined,
    is_verified: boolean,
  ) => {
    const res = await sendSingleRequest(
      { user_id, is_verified },
      'PUT',
      'api/user/user-verify',
      true,
    );
    if (res.status) {
      setUsers(prv =>
        prv.map(x =>
          x?.id.toString() === user_id ? { ...x, is_verified } : x,
        ),
      );
    }
  };
  const loginQRGenerate = async (user: IUserResource) => {
    const res = await sendSingleRequest(
      {},
      'GET',
      `api/user/onetime-token/${user.id}`,
      true,
    );
    if (res.status) {
      setUserQR(res.data);
    } else {
      window.alert(res.data?.message ?? 'Failed to generate QR code');
    }
  };

  const columns = useMemo(() => {
    let cols = [...defaultColumns];
    if (isEditable) {
      cols.push({
        title: 'Data validation required',
        key: 'is_verified',
        dataIndex: '',
        render: (u: IUserResource) => (
          <CheckboxButton
            label={translate(lang, u.is_verified ? 'Not required' : 'Required')}
            className={u.is_verified ? 'blue' : 'red'}
            checked={!u.is_verified}
            onChange={e =>
              toggleUserVerified(u.id.toString(), !e.target.checked)
            }
          />
        ),
      });
    }
    if (profile?.role !== 'user') {
      cols.push({
        title: 'Permissions',
        key: 'permissions',
        dataIndex: '',
        render: (u: IUserResource) =>
          u.role !== 'owner' ? (
            <AntBtn
              type='primary'
              color='blue'
              onClick={() => setSelectedUser(u)}
            >
              {translate(lang, 'View')}
            </AntBtn>
          ) : (
            <></>
          ),
      });
    }
    if (profile?.role === 'owner') {
      cols.push(
        {
          title: 'Password',
          key: 'update',
          dataIndex: '',
          render: (u: IUserResource) => (
            <AntBtn
              type='primary'
              color='blue'
              onClick={() => setOwnerSelUser(u)}
            >
              {translate(lang, 'Change')}
            </AntBtn>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          dataIndex: '',
          render: (u: IUserResource) => (
            <div>
              {profile.role === 'owner' &&
                checkRolePermission(
                  {
                    allowedRoles: ['user', 'processing_plant'],
                  },
                  u.role,
                ) && (
                  <AntBtn
                    type='primary'
                    size='small'
                    danger={true}
                    onClick={() => setDelUser(u)}
                  >
                    {translate(lang, 'Delete')}
                  </AntBtn>
                )}
              <ViewQRButton onClick={() => loginQRGenerate(u)} />
              {profile.role === 'owner' && u.role !== 'owner' && (
                <AntBtn
                  type='default'
                  size='small'
                  danger={u.status === 'active'}
                  onClick={() => setCloseUser(u)}
                >
                  {translate(
                    lang,
                    u.status === 'active' ? 'Archive' : 'Activate',
                  )}
                </AntBtn>
              )}
            </div>
          ),
        },
      );
    }
    return cols.map(x => ({ ...x, title: translate(lang, x.title) }));
  }, [defaultColumns, profile]);

  useEffect(() => handleFilter(), [roleSelected]);
  useEffect(() => handleFilter(), [usersStore]);

  useEffect(() => {
    setIsSpinner(true);
    dispatch(getAllUsers()).then(() => setIsSpinner(false));
  }, [dispatch]);

  return (
    <>
      <div className='bg-secondary min-height'>
        <div className='container'>
          <div className='users d-flex justify-content-between align-items-center'>
            <Title size={5} color='black' align='default' fontWeight={700}>
              {translate(lang, 'Users')}
            </Title>
            {profile?.role !== 'user' && (
              <Link to='/users/add-user'>
                {width > 768 ? (
                  <Button color='blue' size={1} width='middle' type='fill'>
                    {translate(lang, 'Add new user')}
                  </Button>
                ) : (
                  <Button
                    color='blue'
                    size={0}
                    width='default'
                    type='fill'
                    iconOnly
                  >
                    <PlusIcon />
                  </Button>
                )}
              </Link>
            )}
          </div>
          {width < 769 ? (
            <>
              <div className='filter mobile-sort '>
                <span
                  className='filter__icon'
                  onClick={handleOnActiveSort}
                  onKeyDown={handleOnActiveSort}
                  role='button'
                  tabIndex={0}
                >
                  <SortIcon active={isSort} />
                </span>
                <span
                  className='filter__icon'
                  onClick={handleOnActiveSearch}
                  onKeyDown={handleOnActiveSearch}
                  role='button'
                  tabIndex={0}
                >
                  <SearchBigIcon active={isSearch} />
                </span>
              </div>
              {(isSearch || isSort) && (
                <div className='filter'>
                  {isSort && (
                    <div className='d-flex'>
                      <Dropdown
                        label=''
                        placeholder={translate(lang, 'Role')}
                        onChange={(select, event) => handleOnRole(event)}
                        options={roles}
                      />
                      <span className='horizontal-line' />
                    </div>
                  )}
                  {isSearch && (
                    <Search
                      onSearch={handleFilter}
                      onChange={e => setValue(e.target.value)}
                      value={value}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <div className='filter'>
              <div className='d-flex'>
                <Dropdown
                  label=''
                  placeholder={translate(lang, 'Role')}
                  onChange={(select, event) => handleOnRole(event)}
                  options={roles}
                />
                <span className='horizontal-line' />
              </div>
              <Search
                onSearch={handleFilter}
                onChange={e => setValue(e.target.value)}
                value={value}
              />
            </div>
          )}
          {isSpinner ? (
            <div className='mt-20'>
              <Spinner />
            </div>
          ) : (
            <div className='users__content'>
              <Table
                rowKey='id'
                className='table table--isUsers'
                pagination={false}
                columns={columns}
                dataSource={users}
              />
            </div>
          )}
        </div>
        <InvitedUsers />
      </div>
      {!!delUser && (
        <ModalComponent
          visible={true}
          onCancel={() => setDelUser(undefined)}
          type='delete'
          title={translate(lang, 'Delete')}
          text={translate(lang, 'Are you sure to delete this user?')}
          onConfirm={() => handleConfirmDelete()}
          disabled={disabled}
        />
      )}
      {!!closeUser && (
        <ModalComponent
          visible={true}
          onCancel={() => setCloseUser(undefined)}
          type='warning'
          title={translate(
            lang,
            closeUser.status === 'active' ? 'Archive' : 'Activate',
          )}
          text={translate(
            lang,
            `Are you sure to ${
              closeUser.status === 'active' ? 'archive' : 'activate'
            } this user?`,
          )}
          onConfirm={updateUserStatus}
          disabled={disabled}
        />
      )}
      {!!selectedUser && (
        <UserPermissionsModal
          visible={true}
          title={translate(lang, 'Permissions')}
          onClose={() => setSelectedUser(undefined)}
          onConfirm={() => {
            setSelectedUser(undefined);
            dispatch(getAllUsers());
          }}
          data={selectedUser}
        />
      )}
      {!!ownerSelUser && (
        <UpdateUserModal
          visible={!!ownerSelUser}
          user={ownerSelUser}
          onClose={() => setOwnerSelUser(undefined)}
        />
      )}
      {!!userQR && (
        <QRImageModal
          qrCode={userQR}
          onClose={() => setUserQR(undefined)}
          lang={lang}
        />
      )}
    </>
  );
};

export default Users;
