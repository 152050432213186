import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Router from './pages/Router';
import { useEffect } from 'react';

const App = () => {
  const loadUsetifulScript = () => {
    const script = document.createElement('script');
    script.id = 'usetifulScript';
    script.src = 'https://www.usetiful.com/dist/usetiful.js';
    script.async = true;
    script.dataset.token = 'ca1abd4beb2720b298e72bfe75d09cf4';

    document.head.appendChild(script);
  };

  useEffect(() => {
    loadUsetifulScript();
  }, []);

  return (
    <div className='app'>
      <BrowserRouter>
        <Switch>
          <Route path='/'>
            <Router />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
