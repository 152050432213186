import OysterSeedModal from './OysterSeedModal';
import MusselSeedModal from './MusselSeedModal';
import { TBusinessType } from '../../../entities/general.entities';
import {
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
  ISeaweedLineResource,
} from '../../../entities/farms.entities';
import { Link } from 'react-router-dom';
import SeaweedSeedModal from './SeaweedSeedModal';
import '../styles.scss';

export const renderCustomError = (msg: string) => {
  if (msg.startsWith('CUSTOM')) {
    const d = JSON.parse(msg.substring('CUSTOM'.length));
    return (
      <div>
        <div>{`Selected spat has 0kg available left which means it's been fully seeded somewhere else. If you'd like to correct it, here is the list of lines where it has been seeded, you can fix it directly there:`}</div>
        <ul>
          {d.map((x: any, i: number) => (
            <li key={i}>
              <Link to={`/farms/${x.farm_id}/${x.line_id}`}>{x.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return msg;
  }
};

interface Props {
  type: TBusinessType;
  visible: boolean;
  className?: string; // only MUSSEL
  title?: string;
  width?: number; // only MUSSEL
  lineData: ILineResource;
  data?: any;
  updateID?: number;
  parentID?: number;
  onlyView?: boolean;
  onConfirm: (data: any) => void;
  onCancel: () => void;
  overConfirm?: (data: any) => Promise<any>; // only MUSSEL
  confirmBtnLabel?: string; // only MUSSEL
  lineChangeable?: boolean; // only MUSSEL
  warningMessage?: string | React.ReactNode; // only MUSSEL
}

const SeedingModal = ({
  type,
  visible,
  className,
  title,
  width,
  lineData,
  data,
  updateID,
  parentID,
  onlyView,
  onConfirm,
  onCancel,
  overConfirm,
  confirmBtnLabel,
  lineChangeable,
  warningMessage,
}: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselSeedModal
        visible={visible}
        className={className}
        title={title}
        width={width}
        lineData={lineData as IMusselLineResource}
        data={data}
        updateID={updateID}
        parentID={parentID}
        onlyView={onlyView}
        onConfirm={onConfirm}
        onCancel={onCancel}
        overConfirm={overConfirm}
        confirmBtnLabel={confirmBtnLabel}
        lineChangeable={lineChangeable}
        warningMessage={warningMessage}
      />
    ) : type === 'OYSTER' ? (
      <OysterSeedModal
        visible={visible}
        title={title}
        lineData={lineData as IOysterLineResource}
        data={data}
        updateID={updateID}
        parentID={parentID}
        onConfirm={onConfirm}
        overConfirm={overConfirm}
        onCancel={onCancel}
        onlyView={onlyView}
      />
    ) : (
      <SeaweedSeedModal
        visible={visible}
        title={title}
        lineData={lineData as ISeaweedLineResource}
        data={data}
        updateID={updateID}
        parentID={parentID}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onlyView={onlyView}
      />
    )}
  </>
);

export default SeedingModal;
