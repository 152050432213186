import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountUsers,
  getUserMessages,
  IUserMessageResource,
  sendMessage,
  setReadAction,
} from './UserMessagesFunctions';
import { Button, Input, Spinner } from '../../components/shared';
import moment from 'moment';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import toggleSecondMillisecond from '../../util/toggleSecondMillisecond';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectNewUserMessage } from '../../store/users/users.selector';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import './styles.scss';

interface IUserProp {
  id: number;
  name: string;
  company_name: string | null;
  company_address: string | null;
  avatar: string | null;
  unread_msg_count: number;
}

const UserMessagesPage = () => {
  const dispatch = useDispatch();

  const lang = useSelector(selectLang);
  const newUserMessage = useSelector(selectNewUserMessage);
  const profile = useSelector(selectProfile);

  const [users, setUsers] = useState<IUserProp[]>([]);
  const [suId, setSuId] = useState<number | null>(null);
  const [limit, setLimit] = useState(10);
  const [messages, setMessages] = useState<IUserMessageResource[]>([]);
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [content, setContent] = useState('');
  const [disable, setDisable] = useState(false);

  const readMsgHandle = (uId: any, msg: any) => {
    if (
      uId === null ||
      uId === undefined ||
      msg === null ||
      msg === undefined ||
      msg.length <= 0
    )
      return;
    const i = users.findIndex(x => x.id === uId);
    if (i < 0 || users[i].unread_msg_count <= 0) return;
    setReadAction(dispatch, uId, msg[0].id).then(r => {
      if (!r) return;
      const tmp = [...users];
      tmp[i].unread_msg_count = 0;
      setUsers(tmp);
    });
  };
  const handleClickUser = (userId: number) => {
    setSuId(userId);
  };
  const inputContentHandle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    readMsgHandle(suId, messages);
    setContent(event.target.value);
  };
  const sendClickHandle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (content.length <= 0 || suId === null) return;
    setDisable(true);
    sendMessage(dispatch, suId, content).then(r => {
      setDisable(false);
      setMessages([r, ...messages]);
      setContent('');
    });
  };
  const onScrollEvent = (event: React.UIEvent<HTMLDivElement>) => {
    const target: any = event.target;
    if (
      target.scrollHeight + target.scrollTop <= target.clientHeight + 1 &&
      suId
    ) {
      getUserMessages(dispatch, {
        user_id: suId,
        limit: limit + 10,
        offset: limit,
      }).then(m => {
        if (m !== undefined && m !== null && m.length > 0) {
          setMessages([...messages, ...m]);
          setLimit(limit + m.length);
        }
      });
    }
  };

  useEffect(() => {
    if (suId) {
      setLoadingMsg(true);
      getUserMessages(dispatch, { user_id: suId, limit }).then(m => {
        if (m !== undefined && m !== null) {
          setMessages(m);
          readMsgHandle(suId, m);
        }
        setLoadingMsg(false);
      });
    }
  }, [dispatch, suId]);

  useEffect(() => {
    getAccountUsers(dispatch).then(d => {
      if (d !== undefined && d !== null) setUsers(d);
    });
  }, [dispatch]);

  useEffect(() => {
    if (suId && newUserMessage && newUserMessage.sender_id === suId) {
      getUserMessages(dispatch, { user_id: suId, limit }).then(m => {
        if (m !== undefined && m !== null) setMessages(m);
      });
      getAccountUsers(dispatch).then(d => {
        if (d !== undefined && d !== null) setUsers(d);
      });
    }
  }, [newUserMessage, suId, limit]);

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container'>
        <div className='user-messages-page'>
          <div className='--users-list'>
            {users.map(user => (
              <div
                key={user.id}
                className={`--user-row ${
                  suId === user.id ? '--selected-user' : ''
                }`}
                onClick={handleClickUser.bind(this, user.id)}
              >
                <div className='--user-avatar'>
                  <Avatar
                    shape='circle'
                    size={32}
                    src={user.avatar}
                    icon={<UserOutlined />}
                  />
                </div>
                <div className='--user-profile'>
                  <div className='--user-name'>{user.name}</div>
                  <div className='--user-company'>
                    {translate(lang, 'Company')}: {user.company_name ?? '---'}
                  </div>
                </div>
                {user.unread_msg_count > 0 && (
                  <div className='--user-unread-count'>
                    {user.unread_msg_count}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='--inbox'>
            {loadingMsg ? (
              <Spinner />
            ) : (
              suId && (
                <>
                  {messages.length > 0 && (
                    <div
                      className='white-card --messages'
                      onScroll={onScrollEvent}
                    >
                      {messages.map(msg => (
                        <div
                          key={msg.id}
                          className={`--message ${
                            msg.sender?.id === profile?.id
                              ? '--sender'
                              : '--receiver'
                          }`}
                        >
                          <div className='text-left'>
                            <pre className='--content'>{msg.content}</pre>
                            <div className='--created_at'>
                              {moment(
                                toggleSecondMillisecond(msg.created_at),
                              ).format('dddd hh:mm a')}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className='white-card'>
                    <div className='card-body'>
                      <Input
                        type='textarea'
                        label=''
                        placeholder={translate(
                          lang,
                          'Please put your text here',
                        )}
                        value={content}
                        onChange={inputContentHandle}
                      />
                    </div>
                    <div className='card-footer d-flex justify-content-end'>
                      <Button
                        className='align-right'
                        type='bordered'
                        color='green'
                        size={2}
                        width='100px'
                        disabled={disable}
                        onClick={sendClickHandle}
                      >
                        {translate(lang, 'Send')}
                      </Button>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessagesPage;
