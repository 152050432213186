import { IFeedback, TLang } from '../../entities/ui.entities';
import { randomKey } from '../../lib/common.helpers';
import { RootState, IThunkType } from '../rootReducer';
import { ISetUIAction } from './ui.type';

const setUI = (ui: ISetUIAction): ISetUIAction => ui;

export const isSpinner = (spinner: boolean) => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setUI({ type: 'UI/IS_SPINNER', payload: spinner }));
  };
};

export const clearFeedback = () => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setUI({ type: 'UI/CLEAR_FEEDBACK' }));
  };
};

export const showFeedback = (data: IFeedback) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const feedback = {
      ...data,
      id: randomKey(),
    };
    await dispatch(setUI({ type: 'UI/ADD_FEEDBACK', payload: feedback }));

    setTimeout(
      () =>
        dispatch(setUI({ type: 'UI/REMOVE_FEEDBACK', payload: feedback.id })),
      3000,
    );
  };
};

export const hideFeedback = (id: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setUI({ type: 'UI/REMOVE_FEEDBACK', payload: id }));
  };
};

export const setLangUI = (lang: TLang) => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setUI({ type: 'UI/SET_LANG', payload: lang }));
  };
};

export const showGuideModal = () => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setUI({ type: 'UI/SET_VISIBLE_GUIDE', payload: true }));
  };
};

export const hideGuideModal = () => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setUI({ type: 'UI/SET_VISIBLE_GUIDE', payload: false }));
  };
};

export const saveMapType = (type: string) => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setUI({ type: 'UI/SET_MAP_TYPE', payload: type }));
  };
};
