import { ISubscriptionAction, ISubscriptionState } from './subscription.type';

const initialState: ISubscriptionState = {
  status: 'not_subscribe',
  plan_data: null,
  history: null,
  payment_method: null,
  xero_accounts: [],
  xero_contacts: [],
};

const subscriptReducer = (
  state = initialState,
  action: ISubscriptionAction,
): ISubscriptionState => {
  switch (action.type) {
    case 'SUBSCRIPT/SET_STATUS': {
      return { ...state, ...action.payload };
    }
    case 'SUBSCRIPT/SET_XERO_ACCOUNTS': {
      return { ...state, xero_accounts: action.payload };
    }
    case 'SUBSCRIPT/SET_XERO_CONTACTS': {
      return { ...state, xero_contacts: action.payload };
    }
    case 'AUTH/LOGOUT': {
      return initialState;
    }
    default:
      return state;
  }
};

export default subscriptReducer;
