import { sendSingleRequest } from '../../apis';
import { IThunkType } from '../../store/rootReducer';
import { showFeedback } from '../../store/ui/ui.actions';

interface IUser {
  id: number;
  name: string;
  avatar: string | null;
}

export interface IUserMessageResource {
  id: number;
  content: string;
  sender: IUser | null;
  receiver: IUser | null;
  is_read: boolean;
  created_at: number;
}

export const getAccountUsers = async (dispatch: IThunkType) => {
  const response = await sendSingleRequest(
    {},
    'GET',
    `api/user-message/users`,
    true,
  );
  if (response.status) {
    return response.data;
  } else {
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'error',
        message: response.data?.message ?? 'Something went wrong',
      }),
    );
    return null;
  }
};

interface IUMProps {
  user_id: number;
  limit: number;
  offset?: number;
}

export const getUserMessages = async (dispatch: IThunkType, data: IUMProps) => {
  const response = await sendSingleRequest(
    data,
    'POST',
    `api/user-message/messages`,
    true,
  );
  if (response.status) {
    return response.data;
  } else {
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'error',
        message: response.data?.message ?? 'Something went wrong',
      }),
    );
    return null;
  }
};

export const sendMessage = async (
  dispatch: IThunkType,
  userId: number,
  content: string,
) => {
  const response = await sendSingleRequest(
    { receiver_id: userId, content },
    'POST',
    `api/user-message/message`,
    true,
  );
  if (response.status) {
    return response.data;
  } else {
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'error',
        message: response.data?.message ?? 'Something went wrong',
      }),
    );
    return null;
  }
};

export const setReadAction = async (
  dispatch: IThunkType,
  userId: number,
  lastMsgId: number,
) => {
  const response = await sendSingleRequest(
    { sender_id: userId, last_message_id: lastMsgId },
    'POST',
    `api/user-message/read-message`,
    true,
  );
  if (response.status) {
    return true;
  } else {
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'error',
        message: response.data?.message ?? 'Something went wrong!',
      }),
    );
    return false;
  }
};
