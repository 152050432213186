import { useState } from 'react';
import { Modal } from 'antd';
import { Button, CloseIcon, Input, Subtitle } from '../shared';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  visible: boolean;
  title?: string;
  onClose: () => void;
}

const AccountXeroModal = ({ visible, title, onClose }: Props) => {
  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const callbackURL = `${process.env.REACT_APP_API_URL}xero/callback/${btoa(
    profile?.id.toString() ?? '',
  )}`;

  const [cid, setCID] = useState('');
  const [cSec, setCSec] = useState('');

  const confirmClick = () => {
    if (!cid || !cSec) {
      window.alert(translate(lang, 'Please enter Client ID and Client Secret'));
      return;
    }
    const params = {
      user_id: profile?.id.toString() ?? '',
      client_id: cid,
      client_secret: cSec,
      redirect_url: callbackURL,
    };
    const url = `${
      process.env.REACT_APP_API_URL
    }xero/connect?${new URLSearchParams(params).toString()}`;

    window.location.replace(url);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Connect Xero Account')}
          </Subtitle>
        </div>
        <div className='mb-16'>
          <Input
            label={translate(lang, 'Client ID')}
            type='text'
            className='mb-16'
            onChange={e => setCID(e.target.value)}
            value={cid}
            required
          />
          <Input
            label={translate(lang, 'Client Secret')}
            type='text'
            className='mb-16'
            onChange={e => setCSec(e.target.value)}
            value={cSec}
            required
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='red'
          className='rsp-btn ml-16'
          onClick={onClose}
        >
          {translate(lang, 'Cancel')}
        </Button>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default AccountXeroModal;
