import { RootState, IThunkType } from '../rootReducer';
import { isSpinner } from '../ui/ui.actions';
import { sendSingleRequest } from '../../apis';
import { ITaskAction } from './tasks.type';
import {
  ITaskData,
  ITaskRequest,
  ITaskResource,
} from '../../entities/task.entities';
import { loadTags } from '../automation/automation.actions';

const setTask = (task: ITaskAction): ITaskAction => task;

let loadingTasks = false;

export const getTaskData = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingTasks) return;
    loadingTasks = true;

    dispatch(isSpinner(true));
    const response = await sendSingleRequest({}, 'GET', 'api/task/tasks', true);
    if (response.status) {
      dispatch(
        setTask({ type: 'TASKS/SET_TASK_DATA', payload: response.data.data }),
      );
    }

    dispatch(isSpinner(false));
    loadingTasks = false;
  };
};

export const createTask = (newTask: ITaskRequest) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(isSpinner(true));

    const response = await sendSingleRequest(
      newTask,
      'POST',
      'api/task/tasks',
      true,
    );
    if (response.status) {
      await dispatch(getTaskData());
      await sendSingleRequest(
        { task_id: response.data.id },
        'POST',
        'api/user/notification/task-created',
        true,
      );
    }
    dispatch(isSpinner(false));
  };
};

export const removeTask = (taskId: number, history: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(isSpinner(true));

    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/task/tasks/${taskId}`,
      true,
    );
    if (response.status) {
      await dispatch(getTaskData());
    }
    dispatch(isSpinner(false));
  };
};

export const updateTask = (task: ITaskData | ITaskResource, history: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(isSpinner(true));

    const response = await sendSingleRequest(
      task,
      'PUT',
      `api/task/tasks/${task.id}`,
      true,
    );
    if (response.status) {
      await dispatch(getTaskData());
      if (task.type === 'TAGGING') {
        await dispatch(loadTags());
      }
    }
    dispatch(isSpinner(false));
  };
};
