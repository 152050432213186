import { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { sendSingleRequest } from '../../apis';
import { Button, Input, Spinner, Title } from '../../components/shared';
import mussel_logo from '../../images/mussel-app.png';
import './styles.scss';

const QRImage = ({ code }: { code: ICode }) => (
  <div
    className='qr-code-div'
    id={code.track_id}
    style={{ margin: 10 }}
    key={code.track_id}
  >
    <QRCode id={`canvas-${code.track_id}`} value={code.track_id} size={300} />
    <img src={mussel_logo} style={{ height: 30, marginTop: 5 }} />
  </div>
);

function setPrint(this: any) {
  this.contentWindow.__container__ = this;
  this.contentWindow.onbeforeunload = () =>
    document.body.removeChild(this.__container__);
  this.contentWindow.onafterprint = () =>
    document.body.removeChild(this.__container__);
  this.contentWindow.focus();
  this.contentWindow.print();
}

interface ICode {
  type: string;
  track_id: string;
}

const QRGeneratePage = () => {
  const [loading, setLoading] = useState(false);
  const [qrVal, setQrVal] = useState<ICode[]>();
  const [cnt, setCnt] = useState('1');

  const generateClick = () => {
    setLoading(true);
    sendSingleRequest({ count: Number(cnt) }, 'GET', 'api/qr-code', true).then(
      res => {
        setLoading(false);
        if (res.status) {
          setQrVal(res.data);
        } else {
          alert(res.data?.message ?? 'Failed to generate new track code');
        }
      },
    );
  };
  const downloadClick = () => {
    const codes = document.getElementsByClassName('qr-code-div');
    if (!codes || codes.length <= 0) return;
    for (let div of codes) {
      const trackID = div.getAttribute('id');
      const canvas: any = div.getElementsByTagName('canvas');
      if (canvas && canvas.length > 0) {
        let newCanvas = document.createElement('canvas');
        let ctx = newCanvas.getContext('2d');

        let logoImg = new Image();
        logoImg.src = mussel_logo;
        logoImg.onload = function () {
          if (ctx && newCanvas) {
            const logoWidth = logoImg.width * (35 / logoImg.height);

            newCanvas.width = Math.max(canvas[0].width, logoWidth);
            newCanvas.height = canvas[0].height + 35 + 20 + 10;

            ctx.drawImage(canvas[0], 0, 0);

            const logoX = (newCanvas.width - logoWidth) / 2;
            ctx.drawImage(logoImg, logoX, canvas[0].height + 20, logoWidth, 35);

            const pngUrl = newCanvas
              .toDataURL('image/png')
              .replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `track-code-${trackID}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        };
      }
    }
  };
  const printClick = () => {
    const frames = document.getElementsByTagName('iframe');
    for (let f of frames) f.remove();

    const codes = document.getElementsByClassName('qr-code-div');
    if (!codes || codes.length <= 0) return;

    const frame = document.createElement('iframe');
    frame.onload = setPrint;
    frame.style.visibility = 'hidden';
    frame.style.position = 'absolute';
    frame.style.right = '0';
    frame.style.bottom = '0';
    frame.style.width = '100%';
    frame.style.height = '100%';
    frame.srcdoc =
      '<html><body><div style="position:absolute;left:0;top:0;bottom:0;right:0;margin:0;padding:0;display:block;">';
    for (let div of codes) {
      const canvas: any = div.getElementsByTagName('canvas');
      if (canvas && canvas.length > 0) {
        const pngUrl = canvas[0].toDataURL('image/png');
        frame.srcdoc += `<div style="display:flex;align-items:center;justify-content:center;width:100%;height:100%;margin:0;padding:0;flex-direction:column;"><img src="${pngUrl}" style="width:50mm;height:50mm;"><image src="${mussel_logo}" style="height:10mm;margin-top:5mm;" /></div>`;
      }
    }
    frame.srcdoc += '</div></body></html>';
    document.body.appendChild(frame);
  };
  const csvClick = () => {
    if (!qrVal || qrVal.length <= 0) return;
    const content = qrVal.map(x => x.track_id).join('\n');
    let downloadLink = document.createElement('a');
    const file = new Blob([content], { type: 'text/csv' });
    downloadLink.href = URL.createObjectURL(file);
    downloadLink.download = `qr-codes.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className='container w-100 pb-32'>
      <div className='d-flex align-items-center mt-28'>
        <div className='notifications w-100'>
          <div className='mt-28 mb-28 d-flex flex-wrap align-items-center justify-content-between'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              QR Code Generate
            </Title>
          </div>
        </div>
      </div>
      <div className='pb-10 text-center'>
        <div className='mt-32 mb-32 d-flex align-items-end justify-content-center'>
          <div className='mr-17'>
            <Input
              type='number'
              label='Code count'
              value={cnt}
              onChange={e => setCnt(e.target.value)}
            />
          </div>
          <div className='ml-17'>
            <Button
              size={1}
              type='fill'
              width='small'
              color='green'
              onClick={generateClick}
            >
              Generate New
            </Button>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          !!qrVal &&
          qrVal.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexWrap: 'wrap',
                }}
              >
                {qrVal.map(x => (
                  <QRImage key={x.track_id} code={x} />
                ))}
              </div>
              <div className='mt-32 mb-32 d-flex justify-content-center'>
                <div className='mr-32'>
                  <Button
                    size={1}
                    type='fill'
                    width='small'
                    color='green'
                    onClick={printClick}
                  >
                    Print
                  </Button>
                </div>
                <div className='ml-32 mr-32'>
                  <Button
                    size={1}
                    type='fill'
                    width='small'
                    color='blue'
                    onClick={downloadClick}
                  >
                    Download Image
                  </Button>
                </div>
                <div className='ml-32'>
                  <Button
                    size={1}
                    type='fill'
                    width='small'
                    color='green'
                    onClick={csvClick}
                  >
                    Download CSV
                  </Button>
                </div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default QRGeneratePage;
