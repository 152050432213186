import { useCallback, useEffect, useState } from 'react';
import { Tabs, message } from 'antd';
import { Spinner, Subtitle, Title } from '../../components/shared';
import { sendSingleRequest } from '../../apis';
import AssessmentSuggest from './AssessmentSuggest';
import SeedingSuggest from './SeedingSuggest';
import HarvestSuggest from './HarvestSuggest';
import { useHistory, useLocation } from 'react-router-dom';
import FloatingSuggest from './FloatingSuggest';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import OrderSuggest from './OrderSuggest';
import './styles.scss';

const MaintenanceSuggest = () => {
  const lang = useSelector(selectLang);

  return (
    <div id='maintenance-suggest' className='pt-28 pb-28'>
      <div className='mb-18'>
        <Subtitle size={1} color='black' align='left' fontWeight={600}>
          {translate(lang, 'Maintenance suggestions')}
        </Subtitle>
      </div>
      <div className='ml-17'>
        <div className='suggest-sentence'>
          You have 10 line requiring assessments
        </div>
        <div className='suggest-sentence'>
          Last assessment was on farm Osman line 1A on day 12.12.2022
        </div>
      </div>
    </div>
  );
};

const SuggestPage = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(false);
  const [baseAssess, setBaseAssess] = useState<any>();
  const [baseSeed, setBaseSeed] = useState<any>();
  const [baseHarvest, setBaseHarvest] = useState<any>();
  const [baseFloat, setBaseFloat] = useState<any>();
  const [tabKey, setTabKey] = useState(query.get('tab') ?? 'assessment');

  const loadAssess = useCallback(async () => {
    setLoading(true);
    const res = await sendSingleRequest(
      {},
      'GET',
      'api/suggest/assessment',
      true,
    );
    setLoading(false);
    if (res.status) {
      setBaseAssess(res.data);
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'), 5);
    }
  }, []);

  const loadSeed = useCallback(async () => {
    setLoading(true);
    const res = await sendSingleRequest({}, 'GET', 'api/suggest/seeding', true);
    setLoading(false);
    if (res.status) {
      setBaseSeed(res.data);
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'), 5);
    }
  }, []);

  const loadHarvest = useCallback(async () => {
    setLoading(true);
    const res = await sendSingleRequest({}, 'GET', 'api/suggest/harvest', true);
    setLoading(false);
    if (res.status) {
      setBaseHarvest(res.data);
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'), 5);
    }
  }, []);

  const loadFloating = useCallback(async () => {
    setLoading(true);
    const res = await sendSingleRequest(
      {},
      'GET',
      'api/suggest/floating',
      true,
    );
    setLoading(false);
    if (res.status) {
      setBaseFloat(res.data);
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'), 5);
    }
  }, []);

  const changeTab = (key: string) => {
    query.set('tab', key);
    history.replace({ search: `?${query.toString()}` });
    setTabKey(key);
  };

  useEffect(() => {
    if (tabKey === 'assessment') {
      loadAssess();
    } else if (tabKey === 'seeding') {
      loadSeed();
    } else if (tabKey === 'harvest') {
      loadHarvest();
    } else if (tabKey === 'floating') {
      loadFloating();
    }
  }, [tabKey]);

  return (
    <div className='h-calc-80'>
      <div className={`suggest-page ${tabKey}-suggest`}>
        <div className='mt-18 mb-18'>
          <Title size={5} color='black-3' align='default' fontWeight={700}>
            {translate(lang, 'What can you do today')}
          </Title>
        </div>
        {loading && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        <Tabs type='line' activeKey={tabKey} onChange={t => changeTab(t)}>
          <Tabs.TabPane key='assessment' tab='Assessment'>
            {baseAssess && (
              <AssessmentSuggest baseData={baseAssess} loadData={loadAssess} />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key='seeding' tab='Seeding'>
            {baseSeed && (
              <SeedingSuggest baseData={baseSeed} loadData={loadSeed} />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key='harvest' tab='Harvests'>
            {baseHarvest && (
              <HarvestSuggest baseData={baseHarvest} loadData={loadHarvest} />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key='maintenance' tab='Maintenance'>
            <MaintenanceSuggest />
          </Tabs.TabPane>
          <Tabs.TabPane key='floating' tab='Floating'>
            {baseFloat && (
              <FloatingSuggest baseData={baseFloat} loadData={loadFloating} />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key='order' tab='Order'>
            <OrderSuggest />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SuggestPage;
