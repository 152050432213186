import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectBoats = createSelector(
  (state: RootState) => state.users.boats,
  boats => boats,
);

export const selectNewAccountMessage = createSelector(
  (state: RootState) => state.users.newAccountMessage,
  newAccountMessage => newAccountMessage,
);

export const selectNewBoatMessage = createSelector(
  (state: RootState) => state.users.newBoatMessage,
  newBoatMessage => newBoatMessage,
);

export const selectNewUserMessage = createSelector(
  (state: RootState) => state.users.newUserMessage,
  newUserMessage => newUserMessage,
);

export const selectUsers = createSelector(
  (state: RootState) => state.users.users,
  users => users,
);
