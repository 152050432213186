import { useEffect } from 'react';
import UtilsTable from '../../components/farm-util/UtilsTable';
import { useDispatch, useSelector } from 'react-redux';
import { loadUtilsData } from '../../store/utils/utils.actions';
import { defaultBagMusselTypes } from '../../entities/inventory.entities';
import { selectUtils } from '../../store/utils/utils.selector';
import './styles.scss';

const ProcessBagSettings = () => {
  const dispatch = useDispatch<any>();
  const process_bags = useSelector(selectUtils).filter(
    x => x.type === 'process_bag',
  );

  const items = [
    ...defaultBagMusselTypes.map((x, i) => ({
      id: -i - 1,
      name: x,
      type: 'process_bag' as any,
      disabled: true,
    })),
    ...process_bags,
  ];

  useEffect(() => {
    dispatch(loadUtilsData());
  }, [dispatch]);

  return (
    <div className='content pb-32'>
      <UtilsTable
        data={items}
        type='process_bag'
        label={'Manage Processed Mussels Options'}
        btnLabel={'Add Processed Option'}
      />
    </div>
  );
};

export default ProcessBagSettings;
