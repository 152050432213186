import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { IAssessmentResource } from '../../entities/farms.entities';
import { parseImageUrlToFileItem } from '../../util/getBase64';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { Button, CloseIcon, Input, Subtitle } from '../shared';
import PicturesWall from '../shared/pictures-wall/PicturesWall';
import { customFieldsValues } from '../../entities/util-functions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  selectAllFarms,
  SelectAssessFields,
} from '../../store/extra/extra.selector';
import './styles.scss';

interface Props {
  visible: boolean;
  assessment: IAssessmentResource;
  lineId: number;
  onClose: () => void;
}

const AssessmentViewModal = ({
  visible,
  assessment,
  lineId,
  onClose,
}: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectAllFarms);
  const farm = farmsData.find(x => x.lines.some(y => y.id === lineId));
  const line = farm?.lines.find(x => x.id === lineId);

  const customFields = SelectAssessFields(farm?.id);
  const customValues = customFieldsValues(
    customFields,
    assessment.custom_values,
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap view-modal'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Assessment Details')}:
          </Subtitle>
          <Subtitle
            className='ml-7'
            color='black-3'
            align='left'
            size={1}
            fontWeight={500}
          >
            {`${farm?.name} - ${line?.line_name}`}
          </Subtitle>
        </div>
        <div className='pt-16 pb-24'>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Size')}</div>
              <div className='info-value'>{`${assessment.shell_size.min} ~ ${assessment.shell_size.max}, ${assessment.shell_size.avg} mm`}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Assessment date')}
              </div>
              <div className='info-value'>
                {defaultDateFormat(assessment.assessment_date)}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>
                {translate(lang, 'Planned harvest date')}
              </div>
              <div className='info-value'>
                {defaultDateFormat(assessment.planned_date_harvest)}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Ready to harvest')}
              </div>
              <div className='info-value'>
                {!assessment.ready_harvest
                  ? 'No'
                  : defaultDateFormat(assessment.ready_harvest)}
              </div>
            </div>
          </div>
          <div
            className='d-flex justify-content-between'
            style={{ flexWrap: 'wrap' }}
          >
            {customValues?.map(x => (
              <div
                key={x.id}
                className='info-card'
                style={{ flexBasis: '47%', marginLeft: 5, marginRight: 5 }}
              >
                <div className='info-label'>{x.label}</div>
                <div className='info-value'>
                  {`${x.value ?? ''} ${x.unit ?? ''}`}
                </div>
              </div>
            ))}
          </div>
          <div className='mt-7 mb-7'>
            <Input
              label={translate(lang, 'Comment')}
              type='textarea'
              value={assessment.comment ?? ''}
              disabled={true}
            />
          </div>
          {!!assessment.images && assessment.images.length > 0 && (
            <div className='mt-7 mb-7'>
              <PicturesWall
                label={translate(lang, 'Images')}
                fileList={
                  assessment.images.map(x => parseImageUrlToFileItem(x)) as any
                }
                handleChangeImages={() => {}}
                disabled={true}
              />
            </div>
          )}
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AssessmentViewModal;
