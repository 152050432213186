import { sendSingleRequest } from '../../apis';

export const CreateAccountMessage = async (
  content: string,
  success: (param: any) => void,
  failed: (param: any) => void,
) => {
  const res = await sendSingleRequest(
    { content },
    'POST',
    `api/account-message/message`,
    true,
  );
  if (res.status) {
    sendSingleRequest(
      { message_id: res.data.id },
      'POST',
      `api/user/notification/message-created`,
      true,
    ).then(res => console.log(res));
    success(res.data);
  } else {
    failed(res.data);
  }
};

export const DeleteAccountMessage = async (
  message_id: number | string,
  success: (param: any) => void,
  failed: (param: any) => void,
) => {
  const response = await sendSingleRequest(
    { message_id },
    'DELETE',
    `api/account-message/message`,
    true,
  );
  if (response.status) {
    success(response.data);
  } else {
    failed(response.data);
  }
};

export const ReadAccountMessages = async (
  offset: number,
  limit: number,
  success: (params: any) => void,
  failed: (params: any) => void,
) => {
  const res = await sendSingleRequest(
    { offset, limit },
    'GET',
    `api/account-message/messages`,
    true,
  );
  if (res.status) {
    success(res.data);
  } else {
    failed(res.data);
  }
};

export const MessageActionReadAll = async (
  message_ids: number[],
  success: (params: any) => void,
  failed: (params: any) => void,
) => {
  const response = await sendSingleRequest(
    { message_ids },
    'POST',
    'api/account-message/bulk-action',
    true,
  );
  if (response.status) {
    success(response.data);
  } else {
    failed(response.data);
  }
};
