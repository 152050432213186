import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectTasks = createSelector(
  (state: RootState) => state.tasks.tasks,
  tasks => tasks,
);

export const selectCustomTasks = createSelector(
  (state: RootState) => state.auth.settings.customTasks,
  customTasks => customTasks,
);
