import { ICustomField, ICustomValue } from './automation.entities';
import { IProfileResource } from './user.entities';

export const hashColor = (str: string): string => {
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var color = '#';
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

export const calcInterestRate = (planned: number, actual: number) => {
  if (planned <= 0) return 0;

  return ((actual - planned) / planned) * 100.0;
};

const roundNumber = (v: number, p = 2) =>
  v % 1 === 0 ? v : Number(v.toFixed(p));
export const formatNumber = (v: number, p = 2) =>
  roundNumber(v, p)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const numberToMoneyStr = (v: number) =>
  v.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export type TModuleName =
  | 'home'
  | 'profile'
  | 'user'
  | 'farm'
  | 'budget'
  | 'line'
  | 'spat-storage'
  | 'message'
  | 'automation'
  | 'inventory'
  | 'report'
  | 'import'
  | 'validation'
  | 'task'
  | 'calendar'
  | 'boat';
const modules = {
  operator: [
    'home',
    'profile',
    'user',
    'farm',
    'budget',
    'line',
    'spat-storage',
    'message',
    'automation',
    'inventory',
    'report',
    'validation',
    'task',
    'calendar',
    'boat',
    'import',
  ],
  grower: [
    'home',
    'profile',
    'farm',
    'budget',
    'line',
    'message',
    'automation',
    'report',
    'import',
    'task',
    'calendar',
    'user',
    'inventory',
    'spat-storage',
    'validation',
  ],
};

export const is_user_allowed = (
  type: 'operator' | 'grower' | undefined,
  module: TModuleName,
) => {
  if (!type) return false;

  return modules[type].includes(module);
};

const processingPlantRoutes = ['/summary/processing', '/farms', '/qr-scan'];

export const is_role_allowed = (
  role: IProfileResource['role'] | undefined,
  link: string,
) => {
  if (!role) {
    return false;
  } else if (role === 'processing_plant') {
    return processingPlantRoutes.includes(link);
  } else {
    return true;
  }
};

export const checkRolePermission = (
  data: {
    allowedRoles?: Array<IProfileResource['role']>;
    deniedRoles?: Array<IProfileResource['role']>;
  },
  role?: IProfileResource['role'],
) => {
  const { allowedRoles, deniedRoles } = data;

  if (!role) {
    return true;
  } else if (allowedRoles) {
    return allowedRoles.includes(role);
  } else if (deniedRoles) {
    return !deniedRoles.includes(role);
  } else {
    return true;
  }
};

export const customFieldsValues = (
  fields: ICustomField[] | null | undefined,
  values: ICustomValue[] | null | undefined,
) => {
  return fields?.map(f => ({
    id: f.id,
    label: f.name,
    value: values?.find(x => x.field_id === f.id)?.field_val,
    unit: f.unit,
  }));
};

export const customSpecialValue = (
  fields: ICustomField[] | undefined | null,
  values: ICustomValue[] | undefined | null,
  key: string,
) => {
  const fd = fields?.find(x => x.name === key);
  if (!fd) return null;

  return values?.find(x => x.field_id === fd.id)?.field_val;
};
