import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Title, Button, Paragrapgh } from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import { updateEmail, updatePassword } from '../../store/auth/auth.actions';
import { IChangePassword } from '../../entities/auth.entities';
import { selectProfile } from '../../store/auth/auth.selector';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

const Security = () => {
  const dispatch = useDispatch<any>();
  const width = useWidth();

  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);

  const [emailFields, setEmailFields] = useState({ email: '', password: '' });
  const [passwordFields, setPasswordFields] = useState({
    password: '',
    new_password: '',
  });
  const [isClear, setIsClear] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isClear) {
      setIsClear(false);
    }
  }, [isClear]);

  const handleOnChangePassword = async () => {
    setDisabled(true);
    const changeData: IChangePassword = {
      password: passwordFields.password,
      new_password: passwordFields.new_password,
    };
    await dispatch(updatePassword(changeData));
    setPasswordFields({ password: '', new_password: '' });
    setDisabled(false);
  };

  const handleOnChangeEmail = async () => {
    setDisabled(true);
    await dispatch(updateEmail(emailFields));
    setDisabled(false);
  };

  return (
    <div className='content pb-32'>
      {width > 768 && (
        <Title
          className='mb-24'
          size={5}
          color='black-3'
          align='default'
          fontWeight={700}
        >
          {translate(lang, 'Security')}
        </Title>
      )}
      <Title
        className='mb-16'
        size={6}
        color='black-3'
        align='default'
        fontWeight={500}
      >
        {translate(lang, 'Change email')}
      </Title>
      <Paragrapgh
        className='mb-16'
        size={1}
        color='black'
        align='default'
        fontWeight={400}
      >
        {translate(lang, 'Current email')} -
        <span className='font-weight-600'>{profile?.email}</span>
      </Paragrapgh>
      <Input
        label={translate(lang, 'New email')}
        className='mb-24'
        type='email'
        onChange={e =>
          setEmailFields({ ...emailFields, email: e.target.value })
        }
        value={emailFields.email}
        placeholder=''
      />
      <Button
        className={width < 769 ? 'mt-12' : 'h-max'}
        color='blue'
        size={2}
        width={width < 769 ? 'wide' : 'small'}
        type='bordered'
        disabled={disabled}
        onClick={handleOnChangeEmail}
      >
        <span>{translate(lang, 'Change email')}</span>
      </Button>
      <Title
        className='mb-16 mt-32'
        size={6}
        color='black-3'
        align='default'
        fontWeight={500}
      >
        {translate(lang, 'Change password')}
      </Title>
      <Input
        className='mb-24'
        label={translate(lang, 'New password')}
        type='password'
        value={passwordFields.new_password}
        onChange={e =>
          setPasswordFields({ ...passwordFields, new_password: e.target.value })
        }
        placeholder=''
        isClear={isClear}
      />
      <Button
        className={width < 769 ? 'mt-12' : 'h-max'}
        color='blue'
        size={2}
        width={width < 769 ? 'wide' : 'small'}
        type='bordered'
        disabled={disabled}
        onClick={handleOnChangePassword}
      >
        <span>{translate(lang, 'Change password')}</span>
      </Button>
    </div>
  );
};

export default Security;
