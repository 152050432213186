import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { hideFeedback } from '../../../store/ui/ui.actions';
import Feedback from './Feedback';

const ModalFeedbackView = () => {
  const dispatch = useDispatch<any>();

  const messages = useSelector(
    (state: RootState) => state.ui.allFeedbacks,
  )?.filter(x => x.isMessageModal);

  return (
    <>
      {messages?.map((x, i) => (
        <Feedback
          message={x.message}
          type={x.type}
          theme='light'
          position={i ? i * 86 : 16}
          isGlobal
          key={x.id}
          onClose={() => dispatch(hideFeedback(x.id))}
        />
      ))}
    </>
  );
};

export default ModalFeedbackView;
