import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectAccount = createSelector(
  (state: RootState) => state.auth.profile?.account,
  account => account,
);

export const selectToken = createSelector(
  (state: RootState) => state.auth.token,
  token => token,
);

export const selectProfile = createSelector(
  (state: RootState) => state.auth.profile,
  profile => profile,
);

export const selectSettings = createSelector(
  (state: RootState) => state.auth.settings,
  settings => settings,
);

export const selectUserMeta = createSelector(
  (state: RootState) => state.auth.userMeta,
  meta => meta,
);

export const selectIsDemo = createSelector(
  (state: RootState) => state.auth.isDemo,
  isDemo => isDemo,
);

export const selectXeroIsConnected = createSelector(
  (state: RootState) => state.auth.profile?.xero_connects,
  connects => connects && connects?.length > 0,
);
