import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from '../../components/shared';
import { useDispatch } from 'react-redux';
import { authLoadMe } from '../../store/auth/auth.actions';

const Loading = () => {
  const params = useParams<{ type: string }>();
  const history = useHistory();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (params.type === 'xero') {
      dispatch(authLoadMe(true)).then(() => history.replace('/profile/xero'));
    } else {
      history.push('/');
    }
  }, []);

  return (
    <div className='d-flex h-80-vh align-items-center justify-content-center'>
      <Spinner />
    </div>
  );
};

export default Loading;
