import { Pie } from 'react-chartjs-2';
import { IFarmResource } from '../../entities/farms.entities';
import { useMemo } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  farms: IFarmResource[];
}

const FarmsOverviewChart = ({ farms }: Props) => {
  const lang = useSelector(selectLang);

  const data = useMemo(() => {
    let emptyCnt = 0,
      growingCnt = 0;
    for (let farm of farms) {
      const emp = farm.lines.filter(x => !x.growing_cycle).length;
      emptyCnt += emp;
      growingCnt += farm.lines.length - emp;
    }
    return {
      labels: ['Growing lines', 'Empty lines'],
      datasets: [
        {
          label: 'All Farms Overview',
          data: [growingCnt, emptyCnt],
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
          borderWidth: 1,
        },
      ],
    };
  }, [farms]);

  return (
    <div className='pb-32'>
      <div className='square-300'>
        <Pie data={data} />
      </div>
      <Link className='mt-24' to='/farms'>
        <Button type='primary'>{translate(lang, 'View Farms')}</Button>
      </Link>
    </div>
  );
};

export default FarmsOverviewChart;
