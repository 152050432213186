import { TBusinessType } from '../../../entities/general.entities';
import {
  IMusselSeeding,
  IOysterSeeding,
  ISeaweedSeeding,
  ISeedingResource,
} from '../../../entities/growing.entities';
import MusselSeedViewModal from './MusselSeedViewModal';
import OysterSeedViewModal from './OysterSeedViewModal';
import SeaweedSeedViewModal from './SeaweedSeedViewModal';

interface Props {
  type: TBusinessType;
  visible: boolean;
  seeding: ISeedingResource;
  onClose: () => void;
}

const SeedViewModal = ({ type, visible, seeding, onClose }: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselSeedViewModal
        visible={visible}
        seeding={seeding as IMusselSeeding}
        onClose={onClose}
      />
    ) : type === 'OYSTER' ? (
      <OysterSeedViewModal
        visible={visible}
        seeding={seeding as IOysterSeeding}
        onClose={onClose}
      />
    ) : type === 'SEAWEED' ? (
      <SeaweedSeedViewModal
        visible={visible}
        seeding={seeding as ISeaweedSeeding}
        onClose={onClose}
      />
    ) : (
      <></>
    )}
  </>
);

export default SeedViewModal;
