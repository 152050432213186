import { Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { ILineResource } from '../../entities/farms.entities';
import { showFeedback } from '../../store/ui/ui.actions';
import { parseImageUrlToFileItem } from '../../util/getBase64';
import toggleSecondMillisecond from '../../util/toggleSecondMillisecond';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
} from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../shared/input/TimeTrackInput';
import PicturesWall from '../shared/pictures-wall/PicturesWall';
import moment from 'moment';
import { ITimeRange } from '../../entities/general.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectFarmsByFarmID,
  SelectIsBudgetVisible,
} from '../../store/extra/extra.selector';
import './styles.scss';

const typeOptions = [
  {
    id: 'VISUAL_ASSESSMENT',
    value: 'VISUAL_ASSESSMENT',
    label: 'Visual Assessment',
  },
  { id: 'LINE_CLEANING', value: 'LINE_CLEANING', label: 'Line Cleaning' },
  {
    id: 'GENERAL_MAINTENANCE',
    value: 'GENERAL_MAINTENANCE',
    label: 'General Maintenance',
  },
];

type typeField = 'VISUAL_ASSESSMENT' | 'LINE_CLEANING' | 'GENERAL_MAINTENANCE';

interface IForm {
  maintain_date: number;
  type: typeField;
  comment: string | null;
  images: UploadFile<any>[] | null;
  cost?: number;
  time_tracks?: ITimeRange[];
}

const defaultForm: IForm = {
  maintain_date: moment().toDate().getTime(),
  type: 'VISUAL_ASSESSMENT' as typeField,
  comment: '',
  images: [],
  time_tracks: [],
};

interface Props {
  visible: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  data?: any;
  lineData: ILineResource;
  onlyView?: boolean;
  updateId?: number;
  confirmBtnLabel?: string;
  overConfirm?: (data: any) => void;
  lineChangeable?: boolean;
}

const MaintenanceModal = ({
  visible,
  title,
  onCancel,
  onConfirm,
  data,
  lineData,
  onlyView,
  updateId,
  confirmBtnLabel,
  overConfirm,
  lineChangeable,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const farmsData = SelectFarmsByFarmID(lineData.farm_id);
  const visibleCost = SelectIsBudgetVisible(lineData.farm_id);

  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IForm>(defaultForm);
  const [focFarmId, setFocFarmId] = useState(lineData.farm_id);
  const [curLine, setCurLine] = useState(lineData);

  const updateForm = (key: string, val: any) => {
    setForm({ ...form, [key]: val });
  };
  const handleLineChange = (v: string) => {
    const farm = farmsData.find(x => x.id === focFarmId);
    if (!farm) return;
    const line = farm.lines.find(x => x.id === Number(v));
    if (!line?.growing_cycle) return;
    setCurLine(line);
  };
  const validData = () => {
    if (
      form.images &&
      form.images.length <= 0 &&
      (form.comment?.length ?? 0) <= 0
    ) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(
            lang,
            'No empty comment and images are allowed at the same time.',
          ),
        }),
      );
      return null;
    }
    let res: any = { ...form };
    if (res.time_tracks) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (tt === false) {
        dispatch(
          showFeedback({
            isMessageModal: true,
            type: 'error',
            message: translate(lang, '_invalid_time_range'),
          }),
        );
        return null;
      }
      res.time_tracks = tt;
    }
    res.maintain_date = Math.floor(form.maintain_date / 1000);
    res.line_id = lineData.id;
    res.seeding_id = curLine.growing_cycle?.main_seed.id;
    return res;
  };
  const handleConfirmClick = async () => {
    const form = validData();
    if (!form) return;

    setDisabled(true);
    if (!!overConfirm) {
      overConfirm(form);
      return;
    }
    const res = !updateId
      ? await sendSingleRequest(
          form,
          'POST',
          'api/farm/line/seed/maintenance',
          true,
        )
      : await sendSingleRequest(
          form,
          'PUT',
          `api/farm/line/seed/maintenance/${updateId}`,
          true,
        );
    if (res.status) {
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, res.data?.message ?? 'Invalid request'),
        }),
      );
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (data) {
      let tmp: any = {
        maintain_date: 1000 * data.maintain_date,
        type: data.type,
        comment: data.comment,
        images: [],
      };
      if (data.time_tracks) {
        tmp.time_tracks = data.time_tracks.map((x: any) => ({
          start_time: toggleSecondMillisecond(x.start_time),
          finish_time: toggleSecondMillisecond(x.finish_time),
        }));
      }
      if (visibleCost && data.expense_items && data.expense_items.length > 0) {
        tmp.cost = data.expense_items.reduce(
          (p: any, c: any) => p + c.amount,
          0,
        );
      } else if (visibleCost && data.cost) {
        tmp.cost = data.cost;
      }
      if (data.images && data.images.length > 0) {
        tmp.images = [];
        for (let x of data.images) {
          if (typeof x === 'object' && x !== null) {
            tmp.images.push(x);
          } else {
            tmp.images.push(parseImageUrlToFileItem(x));
          }
        }
      }
      setForm(tmp);
    }
  }, [data]);

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = farmsData
    .find(x => x.id === focFarmId)
    ?.lines.filter(x => !!x.growing_cycle)
    .map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.line_name,
    }));

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        <div className='assessment-modal'>
          {lineChangeable && (
            <div className='mb-17 d-flex'>
              <div className='w-100 mr-7'>
                <Dropdown
                  label={translate(lang, 'Select farm')}
                  options={farmOptions}
                  value={focFarmId.toString()}
                  onChange={v => setFocFarmId(Number(v))}
                />
              </div>
              <div className='w-100 ml-7'>
                <Dropdown
                  label={translate(lang, 'Select line')}
                  options={lineOptions ?? []}
                  value={
                    !!lineOptions &&
                    lineOptions.some(x => Number(x.id) === curLine.id)
                      ? curLine.id.toString()
                      : undefined
                  }
                  onChange={handleLineChange}
                />
              </div>
            </div>
          )}
          <div className='mb-17 mt-17'>
            <Datepicker
              label={translate(lang, 'Maintained date')}
              defaultValue={form.maintain_date}
              onChange={e =>
                e && updateForm('maintain_date', e.toDate().getTime())
              }
            />
          </div>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Type')}
              placeholder={translate(lang, 'Type')}
              options={typeOptions}
              value={form.type}
              onChange={(val, evt) => updateForm('type', val)}
              disabled={onlyView}
            />
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Comment')}
              placeholder={translate(lang, 'Please write description here')}
              type='textarea'
              value={form.comment ?? ''}
              dataType='comment'
              onChange={e => updateForm('comment', e.target.value)}
              disabled={onlyView}
            />
          </div>
          {form.time_tracks && (
            <TimeTrackInput
              data={form.time_tracks}
              onChange={t => updateForm('time_tracks', t)}
              disabled={onlyView}
            />
          )}
          {form.images && (
            <div className='mb-17'>
              <PicturesWall
                label={translate(lang, 'Images')}
                fileList={form.images}
                handleChangeImages={img => updateForm('images', img)}
              />
            </div>
          )}
          {!!visibleCost && (
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Cost')}
                type='number'
                value={form.cost?.toString() ?? ''}
                unit='$'
                dataType='cost'
                onChange={e => updateForm('cost', e.target.value)}
                disabled={onlyView}
              />
            </div>
          )}
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled || onlyView}
        >
          {translate(lang, confirmBtnLabel ?? 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default MaintenanceModal;
