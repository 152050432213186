import { sendSingleRequest } from '../../apis';
import { RootState, IThunkType } from '../rootReducer';
import { ISubscriptionAction, ISubscriptionState } from './subscription.type';

const setSubscription = (sub: ISubscriptionAction): ISubscriptionAction => sub;

export const setSubscriptionStatus = (payload: ISubscriptionState) => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setSubscription({ type: 'SUBSCRIPT/SET_STATUS', payload }));
  };
};

let loadingXeroContacts = false;

export const loadXeroContacts = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const profile = getState().auth.profile;
    if (!profile?.xero_connects || profile.xero_connects.length <= 0) {
      return 'Xero is not connected';
    }
    if (loadingXeroContacts) return true;
    loadingXeroContacts = true;

    const response = await sendSingleRequest(
      {},
      'GET',
      'api/xero-data/contacts',
      true,
    );
    if (!response.status) {
      loadingXeroContacts = false;
      return response.data?.message;
    }
    dispatch(
      setSubscription({
        type: 'SUBSCRIPT/SET_XERO_CONTACTS',
        payload: response.data,
      }),
    );
    loadingXeroContacts = false;
    return true;
  };
};

let loadingXeroAccounts = false;

export const loadXeroAccounts = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const profile = getState().auth.profile;
    if (!profile?.xero_connects || profile.xero_connects.length <= 0) {
      return 'Xero is not connected';
    }
    if (loadingXeroAccounts) return true;
    loadingXeroAccounts = true;

    const response = await sendSingleRequest(
      {},
      'GET',
      'api/xero-data/accounts',
      true,
    );
    if (!response.status) {
      loadingXeroAccounts = false;
      return response.data?.message;
    }
    dispatch(
      setSubscription({
        type: 'SUBSCRIPT/SET_XERO_ACCOUNTS',
        payload: response.data,
      }),
    );
    loadingXeroAccounts = false;
    return true;
  };
};
