import { IThunkType, RootState } from '../rootReducer';
import { TExtraAction } from './extra.type';

const setExtra = (extra: TExtraAction): TExtraAction => extra;

export const loadExtraData = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setExtra({ type: 'EXTRA/SET_EXTRA_FARMS', payload: [] }));
    dispatch(
      setExtra({
        type: 'EXTRA/SET_EXTRA_SPAT_STORAGES',
        payload: [],
      }),
    );
    dispatch(
      setExtra({
        type: 'EXTRA/SET_EXTRA_INVENTORIES',
        payload: [],
      }),
    );
    dispatch(setExtra({ type: 'EXTRA/SET_EXTRA_UTILS', payload: [] }));
    dispatch(
      setExtra({
        type: 'EXTRA/SET_CUSTOM_FIELDS',
        payload: [],
      }),
    );
    dispatch(setExtra({ type: 'EXTRA/SET_TAGS', payload: [] }));
    dispatch(setExtra({ type: 'EXTRA/SET_ACCOUNTS', payload: [] }));

    return true;
  };
};
