import { useSelector } from 'react-redux';
import { selectServerEnv } from '../../store/utils/utils.selector';
import { useMemo } from 'react';
import { selectLang, selectVersion } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectAllFarms } from '../../store/extra/extra.selector';

const HeaderWarning = () => {
  const serverEnv = useSelector(selectServerEnv);
  const version = useSelector(selectVersion);
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectAllFarms);

  const urgentLines = useMemo(() => {
    let result: Array<{ id: number; name: string }> = [];
    for (let farm of farmsData) {
      for (let line of farm.lines) {
        if (line.is_urgent) {
          result.push({
            id: line.id,
            name: `${farm.name} - ${line.line_name}`,
          });
        }
      }
    }
    return result;
  }, [farmsData]);

  return (
    <>
      {serverEnv === 'local' && (
        <div className='header-alert'>
          {translate(lang, '_is_testing_env', version)}
        </div>
      )}
      {urgentLines.length > 0 && (
        <div className='header-alert text-left'>
          {translate(
            lang,
            '%s line(s) are required to urgent attention',
            urgentLines.map(x => x.name).join(', '),
          )}
        </div>
      )}
    </>
  );
};

export default HeaderWarning;
