import { useRef, useState } from 'react';
import { Modal } from 'antd';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { Button, CloseIcon, Dropdown, Subtitle } from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMusselFarms,
  selectMusselSpats,
} from '../../store/farms/farms.selector';
import { IFarmResource, ILineResource } from '../../entities/farms.entities';
import placeholder from '../../images/placeholder.png';
import { showFeedback } from '../../store/ui/ui.actions';
import { sendSingleRequest } from '../../apis';
import { loadQueueData } from '../../store/farms/farms.actions';
import {
  convertAssessImportForm,
  convertFloatingImportForm,
  convertHarvestImportForm,
  convertSeedImportForm,
} from '../../lib/form.helpers';
import { selectAssessmentFields } from '../../store/automation/automation.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectInventories } from '../../store/inventories/inventories.selector';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const PaperFormModal = ({ visible, onClose }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectMusselFarms);
  const spatStorages = useSelector(selectMusselSpats);
  const inventories = useSelector(selectInventories);
  const assessmentFields = useSelector(selectAssessmentFields);

  const fileInputRef = useRef<any>(null);

  const [disabled, setDisabled] = useState(false);
  const [curFarm, setCurFarm] = useState<IFarmResource>();
  const [curLine, setCurLine] = useState<ILineResource>();
  const [image, setImage] = useState<string>();
  const [scanned, setScanned] = useState<any>();

  const selectFarm = (v: string) => {
    const farm = farmsData.find(x => x.id === Number(v));
    setCurFarm(farm);
    setCurLine(undefined);
  };
  const selectLine = (v: string) => {
    const line = curFarm?.lines.find(x => x.id === Number(v));
    setCurLine(line);
  };

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = curFarm?.lines.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: x.line_name,
  }));

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        message: translate(lang, message),
        isMessageModal: true,
      }),
    );
  const handleImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        setImage(event.target?.result as string);
      };
      reader.readAsDataURL(file);

      setScanned(undefined);
    }
  };
  const parseImage = async () => {
    if (!image) {
      showError('Please select paper form image');
      return null;
    }
    setDisabled(true);
    const base64Image = image.split(',')[1];
    const response = await sendSingleRequest(
      { image: base64Image },
      'POST',
      'api/textract',
      true,
    );
    setDisabled(false);
    if (!response.status) {
      showError(response.data?.message ?? 'Unknown error');
      return null;
    }
    const { type, data } = response.data;
    let result: any = {};
    if (type === 'seeding') {
      if (data.length <= 0) {
        showError('_no_data_recognized');
        return null;
      }
      result = convertSeedImportForm(
        data[0],
        farmsData,
        spatStorages,
        inventories,
        image,
      );
    } else if (type === 'assessment') {
      if (data.length <= 0) {
        showError('_no_data_recognized');
        return null;
      }
      result = convertAssessImportForm(
        data[0],
        assessmentFields,
        farmsData,
        image,
      );
    } else if (type === 'floating') {
      if (data.length <= 0) {
        showError('_no_data_recognized');
        return null;
      }
      result = convertFloatingImportForm(
        data[0],
        farmsData,
        inventories,
        image,
      );
    } else {
      result = convertHarvestImportForm(data, farmsData, inventories, image);
    }
    if (result.farm_id) {
      const farm = farmsData.find(x => x.id === result.farm_id);
      setCurFarm(farm);
      if (result.line_id && farm) {
        const line = farm.lines.find(x => x.id === result.line_id);
        setCurLine(line);
      }
    }
    return { type: `paper_${type}`, data: result };
  };
  const handleConfirm = async () => {
    const parsed = scanned ?? (await parseImage());
    if (!parsed) return;

    if (!scanned) {
      setScanned(parsed);
    }
    let { type, data } = parsed;
    if (curFarm) {
      data.farm_id = curFarm.id;
    } else if (!data.farm_id) {
      return showError('Please select farm');
    }
    if (curLine) {
      data.line_id = curLine.id;
      data.seeding_id = curLine.growing_cycle?.main_seed.id;
    } else if (!data.line_id) {
      return showError('Please select line');
    }
    if (type !== 'paper_seeding' && !data.seeding_id) {
      return showError('_line_empty');
    }
    setDisabled(true);
    const response = await sendSingleRequest(
      { type, data },
      'POST',
      'api/queue/entity',
      true,
    );
    if (response.status) {
      await dispatch(loadQueueData());
      onClose();
    } else {
      setDisabled(false);
      showError(response.data?.message ?? 'Unknown error');
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Upload paper form image')}
          </Subtitle>
        </div>
        <div className='mt-32 mb-32'>
          <div
            onClick={() => fileInputRef.current?.click()}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <img
              style={{ maxWidth: '100%' }}
              src={image ?? placeholder}
              alt='Click here'
            />
          </div>
          <input
            type='file'
            accept='image/*'
            onChange={handleImageChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
        </div>
        <div className='mb-32'>
          <div className='mb-17 d-flex'>
            <div className='w-100 mr-7'>
              <Dropdown
                label={translate(lang, 'Select farm')}
                options={farmOptions}
                value={curFarm?.id.toString() ?? ''}
                onChange={selectFarm}
                disabled={disabled}
              />
            </div>
            <div className='w-100 ml-7'>
              <Dropdown
                label={translate(lang, 'Select line')}
                options={lineOptions ?? []}
                value={curLine?.id.toString() ?? ''}
                onChange={selectLine}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirm}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default PaperFormModal;
