import { useSelector } from 'react-redux';
import { selectAllTags } from '../../store/extra/extra.selector';
import {
  selectFarmsData,
  selectSpatStorages,
} from '../../store/farms/farms.selector';
import { Dropdown } from '../shared';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import { ILineResource } from '../../entities/farms.entities';
import { calcLineLastSize } from '../../lib/farm.helpers';
import { ITag } from '../../entities/automation.entities';

const sizeSteps = [2, 3, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80];
const getSizeOption = (i: number) => {
  let label = '';
  if (i === 0) {
    label = `1 - ${sizeSteps[0]} mm`;
  } else if (i >= sizeSteps.length) {
    label = `80+ mm`;
  } else {
    label = `${sizeSteps[i - 1]} - ${sizeSteps[i]} mm`;
  }
  return { id: `${i}`, label, value: `${i}` };
};

interface IFilter {
  spatSource?: string;
  tagName?: string;
  sizeIndexes: number[];
}

export const checkLineFilter = (
  line: ILineResource,
  filter: IFilter,
  lineTags: ITag[],
) => {
  if (
    filter.spatSource &&
    line.growing_cycle?.main_seed.spat_storage?.source !== filter.spatSource
  ) {
    return false;
  }
  if (
    filter.tagName &&
    !lineTags.some(
      x =>
        x.name === filter.tagName &&
        x.tag_lines.some(y => y.line_id === line.id),
    )
  ) {
    return false;
  }
  if (filter.sizeIndexes.length > 0) {
    const shellLen = calcLineLastSize(line)?.avg;
    if (shellLen === undefined) return false;
    for (let i of filter.sizeIndexes) {
      if (i === 0) {
        if (shellLen < sizeSteps[0]) return true;
      } else if (i >= sizeSteps.length) {
        if (shellLen >= sizeSteps[sizeSteps.length - 1]) return true;
      } else {
        if (shellLen >= sizeSteps[i - 1] && shellLen < sizeSteps[i])
          return true;
      }
    }
    return false;
  }
  return true;
};

interface Props {
  filter: IFilter;
  onChange: (filter: IFilter) => void;
}

const LinesFilterView = ({ filter, onChange }: Props) => {
  const allFarms = useSelector(selectFarmsData);
  const lineTags = useSelector(selectAllTags);
  const spatBatches = useSelector(selectSpatStorages).map(x => x.source);
  const uniqueBatches = Array.from(new Set(spatBatches)).filter(x =>
    allFarms.some(farm =>
      farm.lines.some(
        line => line.growing_cycle?.main_seed.spat_storage?.source === x,
      ),
    ),
  );
  const tagNames = lineTags
    .filter(x => x.tag_lines.length > 0)
    .map(x => x.name);
  const lang = useSelector(selectLang);

  const changeFilter = (key: keyof IFilter, value: any) => {
    let tmp = { ...filter };
    tmp[key] = value;
    if (key === 'spatSource' || key === 'tagName') {
      tmp[key] = value === undefined || value.length <= 0 ? undefined : value;
    }
    onChange(tmp);
  };

  const tagsOption =
    tagNames.length > 0
      ? [
          {
            id: '___',
            label: `-- (${translate(lang, 'All')}) --`,
            value: '',
          },
          ...tagNames.map(x => ({ id: x, label: x, value: x })),
        ]
      : [];
  const batchesOption =
    uniqueBatches.length > 0
      ? [
          {
            id: '___',
            label: `-- (${translate(lang, 'All')}) --`,
            value: '',
          },
          ...uniqueBatches.map(x => ({ id: x, label: x, value: x })),
        ]
      : [];

  return (
    <div className='d-flex'>
      <Dropdown
        className='filter-option'
        label={translate(lang, 'Batch number')}
        value={filter.spatSource}
        options={batchesOption}
        onChange={v => changeFilter('spatSource', v)}
        showSearch={true}
        allowClear={true}
      />
      <div className='ml-12'>
        <Dropdown
          className='filter-option'
          label={translate(lang, 'Tag')}
          value={filter.tagName}
          options={tagsOption}
          onChange={v => changeFilter('tagName', v)}
          allowClear={true}
        />
      </div>
      <div className='ml-12'>
        <Dropdown
          mode='multiple'
          className='filter-option'
          label={translate(lang, 'Size')}
          value={filter.sizeIndexes.map(String) as any}
          options={Array.from({ length: sizeSteps.length + 1 }, (_, i) =>
            getSizeOption(i),
          )}
          onChange={(v: any) => changeFilter('sizeIndexes', v.map(Number))}
          allowClear={true}
        />
      </div>
    </div>
  );
};

export default LinesFilterView;
