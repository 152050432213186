import { useState } from 'react';
import { Modal } from 'antd';
import { IUserResource } from '../../entities/user.entities';
import { Button, CloseIcon, Input, Subtitle } from '../shared';
import { sendSingleRequest } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  visible: boolean;
  user: IUserResource;
  onClose: () => void;
}

const UpdateUserModal = ({ visible, user, onClose }: Props) => {
  const lang = useSelector(selectLang);
  const dispatch = useDispatch<any>();

  const [password, setPassword] = useState('');
  const [psConfirm, setPsConfirm] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState<string>();

  const handleChange = (type: 'p' | 'c', value: string) => {
    setError(undefined);
    if (type === 'p') {
      setPassword(value);
    } else {
      setPsConfirm(value);
    }
  };
  const confirmClick = () => {
    if (password.length < 3) {
      setError(translate(lang, 'Minimum allowed password length is %s', 3));
      return;
    }
    if (password !== psConfirm) {
      setError('Password confirmation mismatch');
      return;
    }
    setDisabled(true);
    sendSingleRequest(
      { user_id: user.id, password },
      'POST',
      'api/user/owner-update-user',
      true,
    ).then(res => {
      if (res.status) {
        dispatch(
          showFeedback({
            type: 'success',
            isMessage: true,
            message: translate(lang, 'Password updated successfully'),
          }),
        );
        onClose();
      } else {
        setDisabled(false);
        setError(res.data?.message ?? 'Unknown error');
      }
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, "Change %s's password", user.name)}
          </Subtitle>
        </div>
        {!!error && (
          <div className='header-alert'>{translate(lang, error)}</div>
        )}
        <div className='mt-32 mb-32'>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'New password')}
              type='password'
              value={password}
              onChange={e => handleChange('p', e.target.value)}
            />
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Confirm password')}
              type='password'
              value={psConfirm}
              onChange={e => handleChange('c', e.target.value)}
            />
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='red'
            onClick={() => onClose()}
            disabled={disabled}
          >
            {translate(lang, 'Cancel')}
          </Button>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='ml-16'
            onClick={confirmClick}
            disabled={disabled}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateUserModal;
