import { IAuthAction, ILoginData, INextView } from './auth.type';
import { RootState, IThunkType } from '../rootReducer';
import { sendMultipart, sendRequest, sendSingleRequest } from '../../apis';
import { showFeedback } from '../ui/ui.actions';
import { loadUtilsData } from '../utils/utils.actions';
import { loadInventories } from '../inventories/inventories.actions';
import { loadFarmsData, loadSpatStorages } from '../farms/farms.actions';
import { loadCustomFields, loadTags } from '../automation/automation.actions';
import { loadUserBoats } from '../users/users.actions';
import { IRegistration } from '../../entities/auth.entities';
import { getTaskData } from '../tasks/tasks.actions';
import { loadExtraData } from '../extra/extra.actions';
import { ITaskSetting } from '../../entities/user.entities';

const setAuth = (auth: IAuthAction): IAuthAction => auth;

const FixedStorage = ['assessment_import_headers'];

export const logOut = () => {
  return (dispatch: IThunkType) => {
    dispatch(
      setAuth({
        type: 'AUTH/LOGOUT',
      }),
    );
    let tmp: any = {};
    for (let k of FixedStorage) {
      const x = localStorage.getItem(k);
      if (x) tmp[k] = x;
    }
    localStorage.clear();
    localStorage.setItem(
      'redux-local-tab-sync',
      JSON.stringify({ source: 'another tab', type: 'AUTH/LOGOUT' }),
    );
    for (let k in tmp) {
      localStorage.setItem(k, tmp[k]);
    }
  };
};

export const nextView = (value: INextView) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    dispatch(setAuth({ type: 'AUTH/SET_NEXT_VIEW', payload: value }));
  };
};

export const signUp = (data: IRegistration, type: string) => {
  return async (dispatch: IThunkType) => {
    let res;
    if (type === 'signUp') {
      res = await sendRequest(data, 'POST', 'api/auth/signup');
    }
    if (type === 'invite') {
      res = await sendRequest(data, 'POST', 'api/auth/signup-by-invitation');
    }
    if (type === 'forgotPassword') {
      res = await sendRequest(data, 'POST', 'api/password/create');
    }

    if (res?.status === 'Success') {
      dispatch(
        nextView({
          isSuccess: true,
          email: data?.email,
        }),
      );
    } else if (type === 'forgotPassword' && res?.status !== 'Error') {
      dispatch(
        nextView({
          isSuccess: true,
          email: data?.email,
        }),
      );
    } else if (type === 'invite' && res?.status === 'success') {
      dispatch(
        nextView({
          isSuccess: false,
          message: 'Success',
        }),
      );
    } else {
      dispatch(
        nextView({
          isSuccess: false,
          message: res?.message || res?.status,
        }),
      );
    }
  };
};

export const authLogin = (data: ILoginData) => {
  return async (dispatch: IThunkType) => {
    const res = await sendRequest(data, 'POST', 'api/auth/login');
    if (res?.status === 'Success') {
      localStorage.setItem('marine-farm', res?.data.access_token);
      localStorage.setItem('marine-farm-refresh', res?.data.refresh_token);
      localStorage.setItem('marine-farm-user_id', res?.user_id);
      await dispatch(
        setAuth({ type: 'AUTH/SET_TOKEN', payload: res?.data.access_token }),
      );

      return true;
    } else {
      dispatch(logOut());
      dispatch(
        showFeedback({
          type: 'error',
          isMessage: true,
          message: res?.message ?? 'Failed to login',
        }),
      );
      return false;
    }
  };
};

let loadingMe = false;

export const authLoadMe = (simple = false) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const token = localStorage.getItem('marine-farm');
    if (!token) {
      dispatch(logOut());
      return false;
    }
    if (loadingMe) return true;
    loadingMe = true;

    let response = await sendSingleRequest({}, 'POST', 'api/auth/me', true);
    if (!response.status) {
      dispatch(logOut());
      dispatch(
        showFeedback({
          type: 'error',
          isMessage: true,
          message: response.data?.message ?? 'Failed to login',
        }),
      );
      loadingMe = false;
      return false;
    }
    dispatch(setAuth({ type: 'AUTH/SET_PROFILE', payload: response.data }));

    if (simple) {
      loadingMe = false;
      return true;
    }

    const type = response.data.type;
    if (type !== 'admin') {
      response = await sendSingleRequest({}, 'GET', 'api/user-meta', true);
      if (response.status) {
        dispatch(
          setAuth({ type: 'AUTH/SET_USER_META', payload: response.data }),
        );
      }
      dispatch(loadFarmsData());
      (async () => {
        await dispatch(loadUtilsData());
        await dispatch(loadCustomFields());
        await dispatch(loadInventories());
      })();
      (async () => {
        await dispatch(loadSpatStorages());
        await dispatch(loadUserBoats());
        await dispatch(loadTags());
      })();
      (async () => {
        await dispatch(getTaskData());
        await dispatch(loadExtraData());
      })();
      response = await sendSingleRequest({}, 'GET', 'api/user/settings', true);
      if (response.status) {
        const harvestTypes = response.data.HARVEST_TYPE ?? [],
          customTasks = response.data.CUSTOM_TASKS ?? [],
          friendEmails = response.data.FRIEND_EMAILS ?? [],
          showGpsWarning = !!response.data.SHOW_GPS_WARNING;
        dispatch(
          setAuth({
            type: 'AUTH/SET_SETTINGS',
            payload: {
              ...getState().auth.settings,
              harvestTypes,
              customTasks,
              friendEmails,
              showGpsWarning,
            },
          }),
        );
      }

      loadingMe = false;
      return true;
    } else {
      loadingMe = false;

      return true;
    }
  };
};

export const updateShowGpsWarning = (data: boolean) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      { type: 'SHOW_GPS_WARNING', data },
      'POST',
      'api/user/settings',
      true,
    );
    if (res.status) {
      dispatch(
        setAuth({
          type: 'AUTH/SET_SETTINGS',
          payload: { ...getState().auth.settings, showGpsWarning: data },
        }),
      );
    } else {
      return res.data?.message ?? 'Failed to update setting';
    }
  };
};

export const updateTaskSettings = (data: ITaskSetting[]) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      { type: 'CUSTOM_TASKS', data },
      'POST',
      'api/user/settings',
      true,
    );
    if (res.status) {
      dispatch(
        setAuth({
          type: 'AUTH/SET_SETTINGS',
          payload: { ...getState().auth.settings, customTasks: data },
        }),
      );
      return true;
    } else {
      return res.data?.message ?? 'Failed to update';
    }
  };
};

export const updateFriendEmailSetting = (data: string[]) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      { type: 'FRIEND_EMAILS', data },
      'POST',
      'api/user/settings',
      true,
    );
    if (res.status) {
      dispatch(
        setAuth({
          type: 'AUTH/SET_SETTINGS',
          payload: { ...getState().auth.settings, friendEmails: data },
        }),
      );
      return true;
    } else {
      return res.data?.message ?? 'Failed to update';
    }
  };
};

export const adminLoginAsUser = (data: any) => {
  return async (dispatch: IThunkType) => {
    const res = await sendRequest(
      data,
      'POST',
      'api/admin/login-as-user',
      true,
    );
    if (res?.status === 'Success') {
      localStorage.setItem('marine-farm', res?.data.access_token);
      localStorage.setItem('marine-farm-refresh', res?.data.refresh_token);

      await dispatch(
        setAuth({ type: 'AUTH/SET_TOKEN', payload: res?.data.access_token }),
      );
      await dispatch(authLoadMe());

      return true;
    } else {
      return res?.data?.message ?? 'Failed to login';
    }
  };
};

export const updateUserMeta = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest({}, 'GET', 'api/user-meta', true);
    if (res.status) {
      dispatch(setAuth({ type: 'AUTH/SET_USER_META', payload: res.data }));

      return true;
    } else {
      return res.data?.message ?? 'Something went wrong';
    }
  };
};

export const updateAvatar = (data: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendMultipart(
      data,
      'POST',
      `api/user/update-avatar`,
      true,
    );
    if (res.status) {
      await dispatch(authLoadMe(true));

      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Updated successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: res.data?.message ?? 'Error',
        }),
      );
    }
  };
};

export const updateProfile = (data: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      data,
      'POST',
      `api/user/update-profile`,
      true,
    );
    if (res.status) {
      await dispatch(authLoadMe(true));

      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Updated successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: res.data?.message || 'Error',
        }),
      );
    }
  };
};

export const updateEmail = (data: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(data, 'POST', 'api/user/email', true);
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message:
            'Your request has been submitted successfully. Please check your email.',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: res.data?.message || 'Error',
        }),
      );
    }
  };
};

export const updatePassword = (data: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      data,
      'POST',
      'api/user/password',
      true,
    );
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Updated successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: res.data?.message || 'Error',
        }),
      );
    }
  };
};

export const updateAccountSetting = (data: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const response = await sendSingleRequest(
      data,
      'POST',
      'api/user/account-settings',
      true,
    );
    if (response.status) {
      const profile = getState().auth.profile;
      if (!profile) return false;

      dispatch(
        setAuth({
          type: 'AUTH/SET_PROFILE',
          payload: {
            ...profile,
            account: !profile.account ? data : { ...profile.account, ...data },
          },
        }),
      );

      return true;
    } else {
      return response.data;
    }
  };
};

export const demoAccountLogin = () => {
  return async (dispatch: IThunkType) => {
    const res = await sendRequest({}, 'POST', 'api/auth/demo-sign');
    if (res?.status === 'Success') {
      const currentAccessToken = JSON.stringify({
        token: localStorage.getItem('marine-farm'),
        refresh: localStorage.getItem('marine-farm-refresh'),
        user_id: localStorage.getItem('marine-farm-user_id'),
      });
      localStorage.clear();

      if (currentAccessToken) {
        localStorage.setItem('mussel-app-premium-token', currentAccessToken);
      }

      localStorage.setItem('marine-farm', res?.data.access_token);
      localStorage.setItem('marine-farm-refresh', res?.data.refresh_token);
      localStorage.setItem('marine-farm-user_id', res?.user_id);

      await dispatch(
        setAuth({ type: 'AUTH/SET_TOKEN', payload: res?.data.access_token }),
      );
      await dispatch(setAuth({ type: 'AUTH/SET_DEMO_LOGIN', payload: true }));
      await dispatch(authLoadMe());

      return true;
    } else {
      dispatch(
        showFeedback({
          type: 'error',
          isMessage: true,
          message: res?.message ?? 'Failed to view demo',
        }),
      );
      return false;
    }
  };
};

export const exitDemoAccount = () => {
  return async (dispatch: IThunkType) => {
    const premiumToken = localStorage.getItem('mussel-app-premium-token');
    if (!premiumToken) {
      dispatch(logOut());

      return false;
    }
    localStorage.clear();

    const token = JSON.parse(premiumToken);
    localStorage.setItem('marine-farm', token.token);
    localStorage.setItem('marine-farm-refresh', token.refresh);
    localStorage.setItem('marine-farm-user_id', token.user_id);

    await dispatch(setAuth({ type: 'AUTH/SET_TOKEN', payload: token.token }));
    await dispatch(setAuth({ type: 'AUTH/SET_DEMO_LOGIN', payload: false }));
    await dispatch(authLoadMe());

    return true;
  };
};
