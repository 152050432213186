import MusselGrowerSeedModal from './MusselGrowerSeedModal';
import OysterGrowerSeedModal from './OysterGrowerSeedModal';
import { TBusinessType } from '../../../entities/general.entities';
import {
  IMusselSeeding,
  IOysterSeeding,
  ISeedingResource,
} from '../../../entities/growing.entities';
import {
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
} from '../../../entities/farms.entities';
import '../styles.scss';

interface Props {
  type: TBusinessType;
  visible: boolean;
  lineData?: ILineResource;
  seedData?: ISeedingResource;
  onClose: (d: any) => void;
  onConfirm: (d: any) => void;
  overConfirm?: (d: any) => Promise<any>;
  confirmID?: number;
  className?: string;
  onlyView?: boolean;
  data?: any;
  warningMessage?: string;
}

const GrowerSeedModal = ({
  type,
  visible,
  lineData,
  seedData,
  onClose,
  onConfirm,
  className,
  onlyView,
  overConfirm,
  data,
  warningMessage,
}: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselGrowerSeedModal
        visible={visible}
        lineData={lineData as IMusselLineResource}
        seedData={seedData as IMusselSeeding}
        onClose={onClose}
        onConfirm={onConfirm}
        className={className}
        onlyView={onlyView}
        overConfirm={overConfirm}
        data={data}
        warningMessage={warningMessage}
      />
    ) : type === 'OYSTER' ? (
      <OysterGrowerSeedModal
        visible={visible}
        lineData={lineData as IOysterLineResource}
        seedData={seedData as IOysterSeeding}
        onClose={onClose}
        onConfirm={onConfirm}
        className={className}
        onlyView={onlyView}
      />
    ) : (
      <></>
    )}
  </>
);

export default GrowerSeedModal;
