import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import {
  IFarmResource,
  ILineGroup,
  ILineResource,
} from '../../entities/farms.entities';
import { Button, CloseIcon, Dropdown, Input, Subtitle } from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { sendSingleRequest } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { selectFarmingMethods } from '../../store/utils/utils.selector';
import LineLocationModal from '../shared/modal/LineLocationModal';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IFormData {
  line_name: string;
  length: number | null;
  backbone?: number | null;
  points: ILineResource['points'];
  line_group_id: number | null;
  basket_count?: number | null;
  tank_area?: number | null;
  farming_method?: string;
}

const defaultForm: IFormData = {
  line_name: '',
  length: null,
  points: null,
  line_group_id: null,
};

interface Props {
  visible: boolean;
  lineData: ILineResource;
  onClose: (d?: any) => void;
  title?: string;
  farmData: IFarmResource;
}

const LineFormModal = ({
  visible,
  lineData,
  onClose,
  title,
  farmData,
}: Props) => {
  const dispatch = useDispatch<any>();
  const farmingMethods = useSelector(selectFarmingMethods);
  const lang = useSelector(selectLang);

  const [groups, setGroups] = useState<ILineGroup[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IFormData>({
    ...defaultForm,
    line_name: lineData.line_name,
    backbone: (lineData as any)?.backbone ?? null,
    points: lineData.points ?? null,
    line_group_id:
      (lineData as any)?.line_group?.id ?? defaultForm.line_group_id,
    length: (lineData as any).length ?? null,
    basket_count: (lineData as any)?.basket_count ?? null,
    tank_area: (lineData as any)?.tank_area ?? null,
    farming_method: (lineData as any)?.farming_method ?? null,
  });
  const [visibleMap, setVisibleMap] = useState(false);

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        message: translate(lang, message),
        type: 'error',
      }),
    );

  const updateForm = (key: keyof IFormData, val: any) =>
    setForm({ ...form, [key]: val });

  const handleChangePoints = (i: number, x: 'lat' | 'lng', v: string) => {
    let points: any = form.points ? [...form.points] : [];
    while (points.length <= i) points.push({ lat: null, lng: null });
    points[i][x] = Number(v);
    setForm(prv => ({ ...prv, points }));
  };

  const confirmClick = async () => {
    if (form.line_name.length <= 0) {
      showError('Please enter valid line name');
      return;
    }
    if (farmData.type === 'MUSSEL') {
      if (!form.length) {
        showError('Please enter valid line length');
        return;
      }
      if (!form.backbone) {
        showError('Please enter valid backbone');
        return;
      }
    } else if (farmData.type === 'OYSTER') {
      if (!form.length) {
        showError('Please enter valid line length');
        return;
      }
      if (!form.farming_method) {
        showError('Please select farming method');
        return;
      }
      if (!form.basket_count) {
        showError('Please enter valid number of baskets');
        return;
      }
    } else {
      if (!form.tank_area) {
        showError('Please enter valid tank area');
        return;
      }
    }
    setDisabled(true);
    const response = await sendSingleRequest(
      { line_id: lineData.id, ...form },
      'PUT',
      `api/farm/line/lines/${lineData.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      setDisabled(false);
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(lang, 'Line updated successfully'),
          type: 'success',
        }),
      );
      onClose(true);
    } else {
      setDisabled(false);
      showError(response.data?.message ?? 'Failed to update the line');
    }
  };

  useEffect(() => {
    sendSingleRequest(
      { farm_id: lineData.farm_id },
      'GET',
      'api/line-group',
      true,
    ).then(res => {
      if (res.status) {
        setGroups(res.data);
      } else {
        showError(res.data?.message ?? 'Failed to load line groups');
      }
    });
  }, [lineData]);

  const groupsOption = [
    { id: '-1', value: '', label: '(None)' },
    ...groups.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.name,
    })),
  ];

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      footer={null}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Edit line details')}
          </Subtitle>
        </div>
        <div className='mt-17 mb-17'>
          <Input
            label={translate(lang, 'Line number')}
            type='text'
            value={form.line_name}
            onChange={e => updateForm('line_name', e.target.value)}
          />
        </div>
        {['MUSSEL', 'OYSTER'].includes(farmData.type) && (
          <div className='mb-17'>
            <Input
              type='number'
              label={translate(lang, 'Length')}
              unit='m'
              value={form.length?.toString() ?? ''}
              onChange={e =>
                updateForm(
                  'length',
                  e.target.value.length <= 0 ? null : Number(e.target.value),
                )
              }
            />
          </div>
        )}
        {farmData.type === 'MUSSEL' ? (
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Backbone')}
              type='number'
              unit='m'
              value={form.backbone?.toString() ?? ''}
              onChange={e =>
                updateForm(
                  'backbone',
                  e.target.value.length <= 0 ? null : Number(e.target.value),
                )
              }
            />
          </div>
        ) : farmData.type === 'OYSTER' ? (
          <>
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, 'Farming method')}
                options={farmingMethods.map(x => ({
                  id: x.name,
                  value: x.name,
                  label: x.name,
                }))}
                value={form.farming_method}
                onChange={v => updateForm('farming_method', v)}
              />
            </div>
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Baskets capacity')}
                type='number'
                value={form.basket_count?.toString() ?? ''}
                onChange={e =>
                  updateForm(
                    'basket_count',
                    e.target.value.length <= 0 ? null : Number(e.target.value),
                  )
                }
              />
            </div>
          </>
        ) : (
          <div className='mb-17'>
            <Input
              type='number'
              label={translate(lang, 'Tank area')}
              unit='m²'
              value={form.tank_area?.toString() ?? ''}
              onChange={e =>
                updateForm(
                  'tank_area',
                  e.target.value.length <= 0 ? null : Number(e.target.value),
                )
              }
            />
          </div>
        )}
        <div className='mb-17'>
          {[0, 1].map(x => (
            <div key={x} className='pb-17 w-100 d-flex justify-content-between'>
              <div className='mr-17'>
                <Input
                  label={translate(
                    lang,
                    `${x === 0 ? 'Start' : 'End'} location Latitude`,
                  )}
                  placeholder={translate(lang, 'Latitude')}
                  type='number'
                  value={
                    form.points && form.points.length > x
                      ? form.points[x].lat?.toString() ?? ''
                      : ''
                  }
                  onChange={e => handleChangePoints(x, 'lat', e.target.value)}
                />
              </div>
              <div className='ml-17 mr-17'>
                <Input
                  label={translate(lang, 'Longitude')}
                  placeholder={translate(lang, 'Longitude')}
                  className='mr-17'
                  type='number'
                  value={
                    form.points && form.points.length > x
                      ? form.points[x].lng?.toString() ?? ''
                      : ''
                  }
                  onChange={e => handleChangePoints(x, 'lng', e.target.value)}
                />
              </div>
              <div className='ml-17 d-flex align-items-end'>
                <Button
                  width='wide'
                  size={1}
                  type='fill'
                  color='blue'
                  onClick={() => setVisibleMap(true)}
                >
                  {translate(lang, 'Pickup')}
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className='mb-24'>
          <Dropdown
            label={translate(lang, 'Line group')}
            value={form.line_group_id?.toString() ?? ''}
            options={groupsOption}
            onChange={v =>
              updateForm('line_group_id', v.length <= 0 ? null : Number(v))
            }
          />
        </div>
      </div>
      {visibleMap && (
        <LineLocationModal
          visible={true}
          farmID={lineData.farm_id}
          points={form.points ?? undefined}
          onConfirm={points => {
            updateForm('points', points);
            setVisibleMap(false);
          }}
          onCancel={() => setVisibleMap(false)}
          lineData={lineData}
        />
      )}
      <div className='modal-button d-flex justify-content-end align-items-center mt-32'>
        <Button
          width='small'
          size={2}
          type='fill'
          color='red'
          className='rsp-btn'
          onClick={onClose}
          disabled={disabled}
        >
          {translate(lang, 'Close')}
        </Button>
        <Button
          width='small'
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default LineFormModal;
